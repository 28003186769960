import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { searchNodeByPoint } from "../_helpers/utils";
import useReplaceDuplicateShapeId from "./useReplaceDuplicateShapeId";
import { EditorWrapperContext } from "../containers/editor/EditorWrapper";

const useSortableList = () => {
  const { metadata, pageNodes, blockNodes, widgets, updatePageNodes, updateBlockNodes, updateMetadata, updateWidgets } =
    useContext(EditorContext);
  const { updateScrollEvent } = useContext(EditorWrapperContext);
  
  const { replaceDuplicateIdFromSVG } = useReplaceDuplicateShapeId();

  const sortable = {
    meta: false,

    reIndexListItems: ({ client }) => {
      // To prevent auto scroll
      updateScrollEvent(true);
      const items = document.querySelectorAll(".sortable-item");

      let activeObj = {};
      let sortedPageNodes = [];
      let sortedBlockNodes = [];
      let linkedWidgetList = [];
      let newArray = Object.assign([...widgets]);

      [...items].forEach((item, newIdx) => {
        const oldIdx = item.getAttribute("data-index");
        sortedPageNodes.push({ ...pageNodes[oldIdx], pageIdx: newIdx });
        sortedBlockNodes.push({ ...blockNodes[oldIdx], blockIdx: newIdx });

        if (item.getAttribute("data-canvas-id") === client.pageId) {
          activeObj = {
            activePageId: client.pageId,
            activePageIdx: newIdx,
            activeBlockId: client.blockId,
            activeBlockIdx: newIdx,
            disableAutoScroll: false,
          };
        }
      });

      // get the widget list which are linked
      document.querySelectorAll(".dhp-widget-hyperlinked").forEach(element => {
        if (element.getAttribute("data-hyperlink-type") === "Page" && element.getAttribute("id"))
          linkedWidgetList.push(element.getAttribute("id"));
      });

      //update links in widget array
      linkedWidgetList.forEach(id => {
        let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
        let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
        let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
        let getPageNode = sortedPageNodes.filter(
          sortedPageNode => sortedPageNode.pageId === document.getElementById(id).getAttribute("data-hyperlink-pageid")
        );
        let getPageIdx = getPageNode[0].pageIdx;

        if (isGroupWidget) {
          document.getElementById(id).setAttribute("data-hyperlink-url", getPageIdx);

          newArray = Object.assign([...newArray], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              innerHTML: document.getElementById(targetId).innerHTML,
            },
          });
        } else {
          newArray = Object.assign([...newArray], {
            [targetWidgetIndex]: {
              ...widgets[targetWidgetIndex],
              data: {
                ...widgets[targetWidgetIndex].data,
                "data-hyperlink-url": getPageIdx,
              },
            },
          });
        }
      });

      // updatePageNodes(sortedPageNodes);
      // updateBlockNodes(sortedBlockNodes, sortedPageNodes);
      updateWidgets(newArray, sortedPageNodes, sortedBlockNodes, false, false, false);
      updateMetadata({
        ...metadata,
        ...activeObj,
        pageController: {
          ...metadata.pageController,
          style: {
            ...metadata.pageController.style,
            top: metadata.activeBlockIdx === 0 ? 0 : document.getElementById(client.pageId).offsetTop + "px",
          },
        },
      });
    },

    sort: ({ container, client, scrollContainer, mouse, scrollType, lastIntersectedIndex }) => {
      const y = {
        DEFAULT: mouse.current.y,
        FORCED_UP: scrollContainer.rect.top + 1,
        FORCED_DOWN: scrollContainer.rect.bottom - 1,
      };

      const { node: isItemIntersected } = searchNodeByPoint({
        className: "sortable-item",
        x: client.center.x,
        y: y[scrollType],
        exclude: client.node,
      });
      const intersectedIndex = isItemIntersected ? parseInt(isItemIntersected.getAttribute("data-index")) : false;

      if (isItemIntersected && intersectedIndex !== lastIntersectedIndex) {
        sortable.meta.lastIntersectedIndex = intersectedIndex;

        const items = [...document.querySelectorAll(".sortable-item")];
        const domIndexes = {
          client: -1,
          intersectedItem: -1,
        };

        items.forEach((item, i) => {
          const dataIndex = parseInt(item.getAttribute("data-index"));
          if (dataIndex === client.idx) domIndexes.client = i;
          if (dataIndex === intersectedIndex) domIndexes.intersectedItem = i;
        });

        // console.log(domIndexes);

        const isIntersectedItemAdjacentToClient = Math.abs(domIndexes.client - domIndexes.intersectedItem) === 1;

        if (isIntersectedItemAdjacentToClient) {
          const newNode = mouse.initial.directionY === "DOWN" ? isItemIntersected : client.node;
          const refNode = mouse.initial.directionY === "DOWN" ? client.node : isItemIntersected;
          container.node.insertBefore(newNode, refNode);
        } else {
          // NOTE:::: When mouse moves extremely fast, some of the items skip intersecting and they remain unsorted.
          // Example 1 (mouse movement = DOWN):::: At critical situation, a five page document may look like (1, 2, 5, 3, 4). i.e. After intersecting item (1 and 2), the mouse pointer jumps directly into item (5) by skipping item (3 and 4).
          // Example 2 (mouse movement = UP):::: The similar issue may appear for opposite direction (5, 4, 1, 3, 2)
          // Solution:::: For thsese kind of scenarios, sorting needs to be done within a range of unsortedIndexes (according to direction UP / DOWN)

          if (mouse.initial.directionY === "DOWN") {
            const unsortedIndexes = {
              start: domIndexes.client + 1,
              end: domIndexes.intersectedItem,
            };
            // console.log("unsortedIndexes", unsortedIndexes);

            for (let i = unsortedIndexes.start; i <= unsortedIndexes.end; i++) {
              container.node.insertBefore(items[i], client.node);
            }
          }

          if (mouse.initial.directionY === "UP") {
            const unsortedIndexes = {
              start: domIndexes.client - 1,
              end: domIndexes.intersectedItem,
            };
            // console.log("unsortedIndexes", unsortedIndexes);

            for (let i = unsortedIndexes.start; i >= unsortedIndexes.end; i--) {
              container.node.insertBefore(client.node, items[i]);
            }
          }
        }

        // NOTE:::: when doing insertBefore operation, sometimes scrollContainer's scrollHeight is changing for a moment and as a result,
        // the scrollbar is automatically moving from it's position. To prevent this, last known scrollTop is re-assigning to the scrollContainer.
        if (mouse.initial.directionY === "UP" && scrollContainer.node.scrollTop !== scrollContainer.current.scrollTop) {
          scrollContainer.node.scrollTop = scrollContainer.current.scrollTop;
        }
      }
    },

    dragToScroll: ({ scrollContainer, mouse }) => {
      const dataScroll = sortable.meta.scrollContainer.node.getAttribute("data-scroll");

      if (dataScroll === "enabled" && mouse.initial.directionY) {
        // NOTE:::: mouse.initial.directionY is IMPORTANT because all conditions requires to know the INITIAL directionY so that later, the scrollType can be decided.
        if (
          mouse.current.y >= scrollContainer.rect.top &&
          mouse.current.y <= scrollContainer.rect.bottom &&
          mouse.current.directionY
        ) {
          // Condition-1: mouse INSIDE scrollContainer area (default scroll)
          // NOTE:::: mouse.current.directionY IS NOT FALSE (because INSIDE scrollContainer area CURRENT directionY can be interchanged)
          const {
            scrollFactor,
            initial: { scrollTop: scrollTopInitial },
          } = scrollContainer;

          const dy = Math.abs(mouse.current.y - mouse.initial.y);

          const scrollAmount =
            mouse.initial.directionY === "DOWN"
              ? scrollTopInitial + dy * scrollFactor
              : scrollTopInitial - dy * scrollFactor;

          const newScrollTop = scrollAmount >= 0 ? scrollAmount : 0;

          scrollContainer.node.scrollTop = newScrollTop;
          sortable.meta.scrollContainer.current.scrollTop = newScrollTop;
          sortable.meta.scrollContainer.scrollType = "DEFAULT";

          sortable.sort({ ...sortable.meta, scrollType: "DEFAULT" });
        } else {
          // Condition-2: mouse OUTSIDE scrollContainer area (force scroll)
          // NOTE:::: mouse.current.directionY IS NOT CONSIDERED (because OUTSIDE scrollContainer area CURRENT directionY is not important as once it's OUTSIDE, INITIAL directionY is referenced in further nested conditions as follows)
          setTimeout(() => {
            if (mouse.initial.directionY === "DOWN" && mouse.current.y > scrollContainer.rect.bottom) {
              const scrollAmount = scrollContainer.node.scrollTop + 3;
              const newScrollTop = scrollAmount >= 0 ? scrollAmount : 0;

              scrollContainer.node.scrollTop = newScrollTop;
              sortable.meta.scrollContainer.current.scrollTop = newScrollTop;
              sortable.meta.scrollContainer.scrollType = "FORCED_DOWN";

              sortable.sort({ ...sortable.meta, scrollType: "FORCED_DOWN" });

              if (scrollContainer.node.scrollTop < scrollContainer.scrollTopMax) {
                sortable.dragToScroll({ ...sortable.meta });
              }
            }

            if (mouse.initial.directionY === "UP" && mouse.current.y < scrollContainer.rect.top) {
              const scrollAmount = scrollContainer.node.scrollTop - 3;
              const newScrollTop = scrollAmount >= 0 ? scrollAmount : 0;

              scrollContainer.node.scrollTop = newScrollTop;
              sortable.meta.scrollContainer.current.scrollTop = newScrollTop;
              sortable.meta.scrollContainer.scrollType = "FORCED_UP";

              sortable.sort({ ...sortable.meta, scrollType: "FORCED_UP" });

              if (scrollContainer.node.scrollTop > 0) {
                sortable.dragToScroll({ ...sortable.meta });
              }
            }
          }, 100);
        }
      }
    },

    updateDragPathCss: ({ client, dragPath, scrollContainer, mouse }) => {
      // bounding dragPath to scrollContainer area
      if (mouse.current.y >= scrollContainer.rect.top && mouse.current.y <= scrollContainer.rect.bottom) {
        /*<<< BACKUP:::: in-case the item need to be displayed as vertically center aligned with respect to mouse pointer, enable the below line and disable the line after.
        const posTop = mouse.current.y - scrollContainer.rect.top - dragPath.node.clientHeight / 2;
        >>>*/
        const posTop = mouse.current.y - scrollContainer.rect.top - client.pointerOffset.y;
        dragPath.node.style.transform = `translate(0px, ${`${posTop}px`})`;
      }
    },

    toggleDragPath: ({ action, client, dragPath }) => {
      if (action === "SHOW") {
        dragPath.node.classList.remove("d-none");
        client.node.classList.add("disable-pointer");
        document.getElementsByTagName("body")[0].classList.add("grabbing");
      }
      if (action === "HIDE") {
        dragPath.node.innerHTML = "";
        dragPath.node.style.cssText = "";
        dragPath.node.classList.add("d-none");
        client.node.classList.remove("disable-pointer");
        document.getElementsByTagName("body")[0].classList.remove("grabbing");
      }
    },

    togglePlaceholder: ({ action, client }) => {
      if (action === "SHOW") {
        client.contentNode.classList.add("d-none");
        client.placeholderNode.classList.remove("d-none");
      }
      if (action === "HIDE") {
        client.placeholderNode.classList.add("d-none");
        client.contentNode.classList.remove("d-none");
      }
    },

    isMouseDirectionChanged: ({ scrollContainer, mouse }) => {
      if (
        mouse.initial.directionY &&
        mouse.current.directionY &&
        mouse.initial.directionY !== mouse.current.directionY
      ) {
        const scrollTop = scrollContainer.node.scrollTop;
        sortable.meta.scrollContainer.initial.scrollTop = scrollTop;
        sortable.meta.scrollContainer.current.scrollTop = scrollTop;
        sortable.meta.mouse.initial.y = mouse.current.y;
        sortable.meta.lastIntersectedIndex = false;

        // NOTE:::: `scrollContainer.scrollType = DEFAULT` (IS EQUALS TO) `INSIDE scrollContainer area` and CURRENT directionY can be interchanged, hence CURRENT directionY is updated.
        // So for other scrollTypes (FORCED_UP & FORCED_DOWN) / `OUTSIDE scrollContainer area` if CURRENT directionY is updated then the scroll behaviour will be malfunctioning as once it's OUTSIDE, CURRENT directionY is not important.
        if (scrollContainer.scrollType === "DEFAULT") sortable.meta.mouse.initial.directionY = mouse.current.directionY;
      }
    },

    generateDragPathHTML: ({ client, dragPath }) => {
      dragPath.node.innerHTML = client.node.innerHTML;
      replaceDuplicateIdFromSVG(dragPath.node, false, "nav-widget"); // Replace duplicate id for shape use in shape border
    },

    start: (data, e) => {
      e.preventDefault();
      const client = document.getElementById(data.id);
      const clientContent = client.querySelector(".content");
      const clientPlaceholder = client.querySelector(".placeholder");
      const dragPath = document.getElementById("sort-path-display");
      const scrollContainer = document.getElementById("editor-page-list-scroll");

      const { top: clientTop, left: clientLeft, width: clientWidth } = client.getBoundingClientRect();
      const {
        height: scrollContainerHeight,
        top: scrollContainerTop,
        bottom: scrollContainerBottom,
      } = scrollContainer.getBoundingClientRect();

      let props = {
        isDragging: true,
        isFirstEvent: true,
        container: {
          node: client.parentNode,
        },
        client: {
          node: client,
          idx: parseInt(data.idx),
          pageId: data.pageId,
          blockId: data.blockId,
          contentNode: clientContent,
          placeholderNode: clientPlaceholder,
          center: {
            x: clientLeft + clientWidth / 2,
          },
          pointerOffset: {
            y: e.pageY - clientTop + 22, // NOTE:::: here "22" is constant as "clientTop" is somehow lacking by 22px due to some unknown reason.
          },
        },
        dragPath: {
          node: dragPath,
        },
        scrollContainer: {
          node: scrollContainer,
          rect: { top: scrollContainerTop, bottom: scrollContainerBottom },
          scrollFactor: scrollContainer.scrollHeight / scrollContainerHeight,
          scrollTopMax: scrollContainer.scrollHeight - scrollContainerHeight,
          scrollType: false,
          initial: {
            scrollTop: scrollContainer.scrollTop,
          },
          current: {
            scrollTop: false,
          },
        },
        mouse: {
          initial: {
            y: e.pageY,
            directionY: false,
          },
          current: {
            y: false,
            directionY: false,
          },
        },
        lastIntersectedIndex: false,
      };

      sortable.meta = props;
      sortable.generateDragPathHTML({ ...props });
      scrollContainer.setAttribute("data-scroll", "enabled");
      document.addEventListener("mousemove", sortable.drag);
      document.addEventListener("mouseup", sortable.stop);
    },

    drag: e => {
      let meta = sortable.meta;
      if (meta?.isDragging) {
        e.preventDefault();
        meta.mouse.current.y = e.pageY;

        if (!meta.mouse.initial.directionY)
          meta.mouse.initial.directionY = e.movementY === 0 ? false : e.movementY > 0 ? "DOWN" : "UP";
        if (meta.mouse.initial.directionY)
          meta.mouse.current.directionY = e.movementY === 0 ? false : e.movementY > 0 ? "DOWN" : "UP";

        sortable.isMouseDirectionChanged({ ...meta });
        sortable.togglePlaceholder({ action: "SHOW", ...meta });
        sortable.toggleDragPath({ action: "SHOW", ...meta });
        sortable.updateDragPathCss({ ...meta });
        sortable.dragToScroll({ ...meta });

        if (meta.isFirstEvent) {
          meta.isFirstEvent = false;
        }
      }
    },

    stop: e => {
      let meta = sortable.meta;
      if (meta?.isDragging) {
        e.preventDefault();
        sortable.togglePlaceholder({ action: "HIDE", ...meta });
        sortable.toggleDragPath({ action: "HIDE", ...meta });
        if (!meta.isFirstEvent) sortable.reIndexListItems({ ...meta });
        meta.scrollContainer.node.removeAttribute("data-scroll");
        document.removeEventListener("mousemove", sortable.drag);
        document.removeEventListener("mouseup", sortable.stop);
        meta = false;
      }
    },
  };

  return { start: sortable.start };
};

export default useSortableList;
