import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import cx from "classnames";
import { Icon } from "../ui/icon";
import { Input } from "../ui/input";
import { fetchDocumentDetails, updateDocument } from "../../store/actions/documentActions";
import { convertNonAsciiToClosestAsciiEquivalent as convertToAscii } from "../../_helpers/utils";

import global from "../../scss/dhp.scss";
import { EditorPanelContext } from "../../containers/editor/EditorPanel";

let style = Object.assign({}, global);

const DocumentName = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { documentName, updateDocumentName } = useContext(EditorPanelContext);

  const [name, setName] = useState(documentName);
  const [editable, setEditable] = useState(false);

  const { type } = useSelector(state => state?.document);

  const onBlur = e => {
    const updatedName = convertToAscii(e.target.innerText.trim());
    if (updatedName !== "" && updatedName !== name) {
      setName(updatedName);
      dispatch(updateDocument(id, { document_name: updatedName }, false))
        .catch((e) => {
          setName(documentName);
          setEditable(false);
        });
    } else setEditable(false);
  };

  const onKeyDown = e => {
    if (([66, 73, 85].includes(e.which) && e.ctrlKey) || e.which === 13) {
      // disable ctrl+U, ctrl+B, ctrl+I and Enter
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (name !== documentName) setName(documentName);
    setEditable(false);
  }, [documentName]);

  useEffect(() => {
    if (documentName !== name) updateDocumentName(name)
  }, [name])

  useEffect(() => {
    if (!documentName) dispatch(fetchDocumentDetails(id));
  }, []);

  return (
    <>
      <h2 className={cx(style["fw-7"], style["mb-0"], style["mr-5"], style["edit-title"], style["custom-tooltip"])} id="document-name">
        {editable && (
          <Input
            type="contentEditable"
            returnType="noGroup"
            onBlur={e => onBlur(e)}
            onKeyDown={e => onKeyDown(e)}
            value={name}
            className={style["document-rename"]}
          />
        )}
        {!editable && (
          <>
            <span>{name}</span>
            <Link to="#" onClick={e => e.preventDefault()}>
              <Icon icon="ui-edit" onClick={() => setEditable(!editable)} />
            </Link>
            <div className={cx(style["custom-tooltip-content"], style["bottom"])}>{name}</div>
          </>
        )}

      </h2>
    </>
  );
};

export default DocumentName;
