import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { getZoomedValue } from "../_helpers/utils";

const useGuideLine = () => {
  let { metadata, dimension } = useContext(EditorContext);

  let safeAreaDefaultWidth = 1546;
  let safeAreaDefaultHeight = 423;

  const activeGuideLine = setGuideLine => {
    let activeDomId = document.getElementById(metadata.activePageId);
    if (activeDomId) {
      let activeDomPos = activeDomId.getBoundingClientRect();
      // let zoomvalue = zoomval ? zoomval : dimension.zoom;
      let zoomvalue = dimension.zoom;

      let activeBlockHoraizental = activeDomPos.height / 2;
      let activeBlockVertical = activeDomPos.width / 2;
      let safeAreaWidth = getZoomedValue(safeAreaDefaultWidth, zoomvalue);
      let safeAreaHeight = getZoomedValue(safeAreaDefaultHeight, zoomvalue);
      let brochureGuidelineRatio = activeDomPos.width / 3;

      setGuideLine({
        activeBlockHoraizental: activeBlockHoraizental,
        activeBlockVertical: activeBlockVertical,
        safeAreaWidth: safeAreaWidth,
        safeAreaHeight: safeAreaHeight,
        brochureGuidelineRatio: brochureGuidelineRatio,
      });
    }
  };

  return activeGuideLine;
};

export default useGuideLine;
