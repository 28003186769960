import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import cx from "classnames";
import { DropdownItem, TabPane } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import SliderControll from "../SliderControll";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import Flip from "./Flip";
import { getCssTransformObj, getCssTransformObjToString, getUnScaledValue, normalizeAngle, rotatePoint } from "../../../_helpers/utils";
import useCollaborativeSelector from "../../../hooks/useCollaborativeSelector";
import useFindHighlighter from "../../../hooks/useFindHighlighter";
import useWidgetHandler from "../../../hooks/useWidgetHandler";

let style = Object.assign({}, global);

const Rotate = props => {
  let { metadata, widgets, updateWidgets, dimension, setActionOnRotateModal} = useContext(EditorContext);
  const updateCollaborativeWidgetSelectionStyle = useCollaborativeSelector();
  const updateFindHighlighter = useFindHighlighter();
  const { updateEventCursors } = useWidgetHandler();
  const shiftSelctionTheta = useRef(0);

  let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
  let isShistSelected = metadata.activeWidgetId?.length >= 2;
  let isChildWidgetSelectd = isGroupWidget && metadata.activeWidgetId.some(widgetId => widgetId.includes("dhp-widget"));

  const [rotation, setRotation] = useState();
  const [rotationInputValue, setRotationInputValue] = useState();
  const [updateContext, setUpdateContext] = useState(false);
  const [loadCurrentFormating, setLoadCurrentFormating] = useState();

  // on applyRightRotation, calculate respective widgets rotation values
  const applyRightRotation = () => {
    props.setDropdownOpen(false);
    setUpdateContext(true);

    if (rotation + 90 > 360) setRotation(rotation + 90 - 360);
    else if (rotation + 90 === 360) setRotation(0);
    else setRotation(rotation + 90);

    setLoadCurrentFormating(false);
  };

  // on applyLeftRotation, calculate respective widgets rotation values
  const applyLeftRotation = () => {
    props.setDropdownOpen(false);
    setUpdateContext(true);

    if (rotation - 90 < 0) setRotation(360 + (rotation - 90));
    else setRotation(rotation - 90);

    setLoadCurrentFormating(false);
  };

  const rotateShiftSelectedWidgets = (wz, theta) => {
    const zoom = dimension.zoom;
    const handlerStyle = document.getElementById("dhp-widget-handler")?.style;

    const {
      translate: { x: handlerTransX, y: handlerTransY },
    } = getCssTransformObj({
      transform: handlerStyle.transform,
    });

    const {
      translate: { x: groupTransX, y: groupTransY },
    } = getCssTransformObj({
      translateX: `${getUnScaledValue(handlerTransX, zoom)}px`,
      translateY: `${getUnScaledValue(handlerTransY, zoom)}px`,
      transform: handlerStyle.transform,
      returnHybrid: true,
    });
    let newStyleInPx = [];
    wz.forEach(widgetInDOM => {
      const {
        rotate: { theta: widgetTheta },
        translate: { x: widgetTransX, y: widgetTransY },
      } = getCssTransformObj({
        transform: widgetInDOM.style.transform,
      });
      let applicableTheta = normalizeAngle(parseInt(widgetTheta) + parseInt(theta));
      const widgetCenterX = parseFloat(widgetTransX) + parseFloat(widgetInDOM.style.width) / 2;
      const widgetCenterY = parseFloat(widgetTransY) + parseFloat(widgetInDOM.style.height) / 2;
      const groupCenterX = parseFloat(groupTransX) + getUnScaledValue(handlerStyle.width, zoom) / 2;
      const groupCenterY = parseFloat(groupTransY) + getUnScaledValue(handlerStyle.height, zoom) / 2;
      const rotatedCenter = rotatePoint(
        widgetCenterX,
        widgetCenterY,
        groupCenterX,
        groupCenterY,
        parseInt(theta)
      );
      const newLeft = rotatedCenter.x - parseFloat(widgetInDOM.style.width) / 2;
      const newTop = rotatedCenter.y - parseFloat(widgetInDOM.style.height) / 2;

      let styleInPx = {
        id: widgetInDOM.getAttribute("id"),
        transform: `translate(${newLeft}px, ${newTop}px) scale(1, 1) rotate(${applicableTheta}deg)`,
        rotateAngle: applicableTheta
      };
      newStyleInPx.push(styleInPx);
    })
    return { newStyleInPx }
  }

  // on update rotation values
  useEffect(() => {
    if (!isShistSelected && !isChildWidgetSelectd) {
      let targetWidgetIndex = widgets.findIndex(widget => widget.id === metadata.activeWidgetId[0]);
      let widgetTransform = widgets[targetWidgetIndex].style.transform;
      let widgetTransformObj = getCssTransformObj({ transform: widgetTransform, rotateAngle: `${rotation}deg` });
      let widgetTransformString = getCssTransformObjToString({ transformObj: widgetTransformObj });

      // update dom
      if (!loadCurrentFormating) {
        document.getElementById(metadata.activeWidgetId[0]).style.transform = widgetTransformString;
        document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-value", rotation);

        // update handler
        document.getElementById("dhp-widget-handler").style.transform = getCssTransformObj({
          rotateAngle: `${rotation}deg`,
          transform: document.getElementById("dhp-widget-handler").style.transform,
          returnStr: true,
        });
      }

      // create array for upadte widget context
      let newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          style: {
            ...widgets[targetWidgetIndex].style,
            transform: widgetTransformString,
          },
          data: {
            ...widgets[targetWidgetIndex].data,
            "data-value": rotation,
          },
        },
      });

      updateFindHighlighter() // update find heighlighter
      updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom })  // update collaboration selection
      updateContext && updateWidgets(newArray);
      setUpdateContext(false);
    }
    if (isShistSelected && document.getElementById("dhp-widget-handler")?.style?.transform && rotation >= 0) {
      let newStyleInPx;
      const wz = Array.from(document.querySelectorAll(".dhp-root-widget"))
        .filter(w => metadata.activeWidgetId.includes(w.id));
      ({ newStyleInPx } = rotateShiftSelectedWidgets(wz, (rotation - shiftSelctionTheta?.current)));
      
      if (!loadCurrentFormating) {
        metadata.activeWidgetId?.forEach(wzId => {
          let curNewStyle = newStyleInPx.find(w => w.id === wzId);
          document.getElementById(wzId).style.transform = curNewStyle.transform;
          document.getElementById(wzId).setAttribute("data-value", curNewStyle.rotateAngle);
        })
        // update handler
        document.getElementById("dhp-widget-handler").style.transform = getCssTransformObj({
          rotateAngle: `${rotation}deg`,
          transform: document.getElementById("dhp-widget-handler").style.transform,
          returnStr: true,
        });
      }

      updateEventCursors({ theta: rotation })

      let newArray = JSON.parse(JSON.stringify(widgets));
      newArray = newArray.map(w => {
        if (metadata.activeWidgetId?.includes(w.id)) {
          let curNewStyle = newStyleInPx.find(wz => wz.id === w.id);
          return {
            ...w,
            style: { ...w.style, transform: curNewStyle.transform },
            data: { ...w.data, "data-value": curNewStyle.rotateAngle }
          };
        } else {
          return w;
        }
      });
      shiftSelctionTheta.current = rotation;

      updateFindHighlighter() // update find heighlighter
      updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom })  // update collaboration selection
      if (updateContext) updateWidgets(newArray);
      setUpdateContext(false);
    }
  }, [rotation, updateContext]);

  // 1st time dropdown open, store rotation values for widgets
  useLayoutEffect(() => {
    if (!isShistSelected && !isChildWidgetSelectd) { // single widget
      let targetWidgetIndex = widgets.findIndex(widget => widget.id === metadata.activeWidgetId[0]);
      let widgetTransform = widgets[targetWidgetIndex].style.transform;
      let widgetRotationValue = parseInt(
        getCssTransformObj({ transform: widgetTransform, exclude: ["translate", "scale"] }).rotate.theta
      );
      setLoadCurrentFormating(true);
      setRotation(widgetRotationValue);
      document.getElementById(metadata.activeWidgetId[0]).setAttribute("data-value", widgetRotationValue);
    } if (isShistSelected && document.getElementById("dhp-widget-handler")?.style?.transform) { // shift select
      const {
        rotate: { theta: handlerTheta }
      } = getCssTransformObj({
        transform: document.getElementById("dhp-widget-handler").style.transform,
      });
      shiftSelctionTheta.current = parseInt(handlerTheta);
      setLoadCurrentFormating(true);
      setRotation(parseInt(handlerTheta));
    }
    if (isGroupWidget && isChildWidgetSelectd && !isShistSelected) { //child inside group
      let widgetRotationValue = parseInt(
        getCssTransformObj({ transform: document.getElementById(metadata.activeWidgetId[0]).style.transform, exclude: ["translate", "scale"] }).rotate.theta
      );
      setRotation(widgetRotationValue);
    }


    // Retain widget handler
    const element = document.querySelector('#context-action-rotate');

    if (element) {
      const handleMouseEnter = () => sessionStorage.setItem("actionOnRotateModal", true);
      const handleMouseLeave = () => sessionStorage.setItem("actionOnRotateModal", false);

      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);

      // Cleanup event listeners on unmount
      return () => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);


  return (
    <TabPane tabId={props.idx} id="context-action-rotate">
      <div className={cx(style["line-controls-wrap"], style["with-input"],{ [style["disabled"]]: !isShistSelected && isChildWidgetSelectd })}>
        <SliderControll
          Slider={rotation}
          setSlider={setRotation}
          SliderInputValue={rotationInputValue}
          setSliderInputValue={setRotationInputValue}
          sliderMin={0}
          sliderMax={359}
          sliderLabel={"Rotate"}
          setUpdateContext={setUpdateContext}
          setLoadCurrentFormating={setLoadCurrentFormating}
        />
      </div>

      <ul className={cx(style["row"], style["row-cols-2"])}>
        <li className={cx(style["col"], { [style["disabled"]]: !isShistSelected && isChildWidgetSelectd })}>
          <DropdownItem tag="a" onClick={applyRightRotation}>
            <Icon icon="ui-rotate-right" />
            Rotate Right 90°
          </DropdownItem>
        </li>

        <li className={cx(style["col"], { [style["disabled"]]:!isShistSelected && isChildWidgetSelectd })}>
          <DropdownItem tag="a" onClick={applyLeftRotation}>
            <Icon icon="ui-rotate-left" />
            Rotate Left 90°
          </DropdownItem>
        </li>

        <Flip setDropdownOpen={props.setDropdownOpen} dropdownOpen={props.dropdownOpen} />
      </ul>
    </TabPane>
  );
};
Rotate.propTypes = {
  idx: PropTypes.number,
  setDropdownOpen: PropTypes.func,
  dropdownOpen: PropTypes.bool,
};

export default Rotate;
