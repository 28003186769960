import {
  DOCUMENT_PERMISSION_OWNER as OWNER,
  DOCUMENT_PERMISSION_FULL as FULL,
  DOCUMENT_PERMISSION_RW as RW,
} from "../../constants/document";

export const permission = (action, document, _helpers) => {
  const {
    DOC_PERMISSION,
    DOC_IS_SHARED,
    DOC_IS_PUBLISHED,
    DOC_IS_PUBLISHED_AS_DOCHIPO_TEMPLATE,
    DOC_IS_PUBLISHED_AS_COMPANY_TEMPLATE,
  } = document;
  const { isManagerSuperadmin, isCompanySuperuser, isActiveUserPresent, isActiveCompanyPresent, hasTransferAccess, hasCompanyTemplateAccess } =
    _helpers;

  switch (action) {
    case "go_to_editor":
      return [OWNER, FULL, RW].includes(DOC_PERMISSION);

    case "preview":
      return true;

    case "edit":
      return [OWNER, FULL, RW].includes(DOC_PERMISSION);

    case "rename":
      return [OWNER, FULL, RW].includes(DOC_PERMISSION);

    case "clone":
      return [OWNER, FULL, RW].includes(DOC_PERMISSION);

    case "share":
      return [OWNER].includes(DOC_PERMISSION) && isActiveUserPresent && !DOC_IS_SHARED;

    case "reshare":
      return [OWNER].includes(DOC_PERMISSION) && isActiveUserPresent && DOC_IS_SHARED;

    case "publish":
      return [OWNER, FULL, RW].includes(DOC_PERMISSION) && !DOC_IS_PUBLISHED;

    case "unpublish":
      return [OWNER, FULL, RW].includes(DOC_PERMISSION) && DOC_IS_PUBLISHED;

    case "publish_as_company_template":
      return (isCompanySuperuser || hasCompanyTemplateAccess) && !DOC_IS_PUBLISHED_AS_COMPANY_TEMPLATE;

    case "republish_as_company_template":
      return (isCompanySuperuser || hasCompanyTemplateAccess) && DOC_IS_PUBLISHED_AS_COMPANY_TEMPLATE;

    case "publish_to_template_library":
      return isManagerSuperadmin && !DOC_IS_PUBLISHED_AS_DOCHIPO_TEMPLATE;

    case "republish_to_template_library":
      return isManagerSuperadmin && DOC_IS_PUBLISHED_AS_DOCHIPO_TEMPLATE;

    case "transfer":
      return (isCompanySuperuser || hasTransferAccess) && isActiveCompanyPresent;

    case "present":
      return true;

    case "download":
      return true;

    case "delete":
      return (
        [OWNER].includes(DOC_PERMISSION) &&
        !DOC_IS_PUBLISHED_AS_DOCHIPO_TEMPLATE &&
        !DOC_IS_PUBLISHED_AS_COMPANY_TEMPLATE &&
        !DOC_IS_PUBLISHED
      );

    default:
      return false;
  }
};
