import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import { Icon } from "../../ui/icon";

import global from "../../../scss/dhp.scss";
import { Button, Form } from "reactstrap";
import RoleHierarchy from "../RoleProfile/RoleHierarchy";

let style = Object.assign({}, global);

const CompanyRoleModal = ({ role, actionButton, title, toggle }) => {
  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>{title}</h4>
      <Link to="#" className={cx(style["cross-modal"], style["rounded"])} onClick={toggle}>
        <Icon icon="ui-close" />
      </Link>

      <Form className={style["mt-1"]} onKeyDown={e => preventSubmit(e)} onSubmit={e => handleSubmit(e)}>
        <div className={cx(style["company-role-container"], style["pt-2"], style["pb-5"])}>
          <RoleHierarchy role={role} actionButton={false} />
        </div>
        <div className={cx(style["align-items-center"], style["d-flex"])}>
          <Button type="submit" className={style["mt-2"]} color="primary" cssModule={style}>
            Save
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default CompanyRoleModal;
