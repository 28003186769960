import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { EditorWrapperContext } from "../../../containers/editor/EditorWrapper";

let style = Object.assign({}, global);

const Transform = props => {
  let { metadata, widgets } = useContext(EditorContext);
  let { changeBySocket } = useContext(EditorWrapperContext);

  const [isActiveTransform, setIsActiveTransform] = useState();
  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const fetchTransformFromActiveWidget = () => {
    if (document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.textTransform !== "inherit")
      setIsActiveTransform(true);
    else setIsActiveTransform(false);
  }

  useEffect(() => {
    fetchTransformFromActiveWidget();
  }, []);

  //check Transform is applied or not for collaboration
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]?.innerHTML) fetchTransformFromActiveWidget();
  }, [changeBySocket, widgets[targetWidgetIndex]?.innerHTML])

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: isActiveTransform,
      })}>
      <UncontrolledDropdown className={cx(style["toolset-item"], style["transform-wrap"])}>
        <DropdownToggle
          tag="span"
          className={cx(style["toolset-action"], style["custom-tooltip"])}
          onClick={() => props?.setmoreSecondaryOptionClicked("Transform")}>
          <Icon icon="ui-lowercase" />
          Transform
        </DropdownToggle>
      </UncontrolledDropdown>
    </li>
  );
};
Transform.propTypes = {
  setmoreSecondaryOptionClicked: PropTypes.func,
};

export default Transform;
