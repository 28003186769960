import { useContext, useEffect, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { EditorPanelContext } from "../containers/editor/EditorPanel";
import { resetEditorMapTooltip } from "../_helpers/utils";

const useDeleteWidget = () => {
  let { metadata, widgets, updateMetadata, updateWidgets, rightContextMenu, updateRightContextMenu } =
    useContext(EditorContext);

  const [deleteWidget, setDeleteWidget] = useState(false);

  useEffect(() => {
    if (!deleteWidget || !metadata.activeWidgetId) return;

    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });

    let targetWidgetIdArary = [];

    metadata.activeWidgetId.forEach(id => {
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      targetWidgetIdArary.push(targetId);
    });

    let newWidget = widgets.filter(widget => !targetWidgetIdArary.includes(widget.id));

    updateWidgets(newWidget);
    updateMetadata({ ...metadata, activeWidgetId: false, activeWidgetType: false });
    setDeleteWidget(false);

    //Remove tootlip while deleting map
    resetEditorMapTooltip();
  }, [deleteWidget]);

  return setDeleteWidget;
};

export default useDeleteWidget;
