import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import ColorPicker from "../../ui/colorPicker";
import { contextualConfig } from "../editor_config";

let style = Object.assign({}, global);

const MulticolorBucket = props => {
  const [count, setCount] = useState();

  useEffect(() => {
    setCount(props.multiColors.length > 4 ? 4 : props.multiColors.length);
  }, [props.multiColors.length]);

  return (
    <>
      {props.multiColors.length > 1 && (
        <UncontrolledDropdown
          setActiveFromChild
          className={cx(style["color-section-block"], style["color-picker-dropdown"], style["multicolor"])}>
          <DropdownToggle
            tag="a"
            className={cx(style["dropdown-toggle"], style["custom-tooltip"])}
            onClick={() => props.setLoadCurrentFormatting(false)}>
            <Icon additionalclass={props.iconBucketColor} icon="ui-fill-color2"></Icon>
            <div className={cx(style["custom-tooltip-content"], style["top"])}>Color</div>
          </DropdownToggle>

          <ul className={cx(style["multi-palette"], style[`count-${count}`])}>
            {props.multiColorDatas?.map((multiColorData, idx) => (
              <li
                style={
                  multiColorData.color === "transparent"
                    ? {
                        backgroundImage: `url(${contextualConfig.colorUrl.small})`,
                        backgroundSize: "cover",
                      }
                    : { backgroundColor: multiColorData.color }
                }
                key={idx}></li>
            ))}
          </ul>

          <DropdownMenu
            className={cx(
              style["border-0"],
              style["p-0"],
              style["shadow"],
              style["multy-color-dropdown"],
              style[`count-${count}`]
            )}>
            <ul className={cx(style["multy-color-list"])}>
              {props.multiColorDatas?.map(
                (multiColorData, idx) =>
                  idx < 32 && (
                    <UncontrolledDropdown tag="li" direction="right" key={idx}>
                      <DropdownToggle
                        tag="a"
                        style={
                          multiColorData.color === "transparent"
                            ? {
                                backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                                backgroundSize: "cover",
                              }
                            : { backgroundColor: multiColorData.color }
                        }></DropdownToggle>
                      <DropdownMenu className={cx(style["border-0"], style["p-0"], style["shadow"])}>
                        <ColorPicker
                          color={multiColorData.color}
                          setColor={props.setMultiColorDatas}
                          colorClass={multiColorData.className}
                          multiColorDatas={props.multiColorDatas}
                          multiColors={props.multiColors}
                          setMultiColors={props.setMultiColors}
                          setPreviousColor={props.setPreviousColor}
                          setCurrentColor={props.setCurrentColor}
                          idx={idx}
                          setUpdateContext={props.setUpdateContext}
                          setTextSelectedAreaColor={props.setTextSelectedAreaColor}
                        />
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )
              )}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}

      {props.multiColors.length === 1 && (
        <UncontrolledDropdown
          setActiveFromChild
          className={cx(style["color-section-block"], style["color-picker-dropdown"])}>
          <DropdownToggle
            tag="a"
            className={cx(style["dropdown-toggle"])}
            onClick={() => props.setLoadCurrentFormatting(false)}>
            <Icon
              additionalclass={props.iconBucketColor}
              icon="ui-fill-color2"
              style={{ backgroundColor: props.multiColorDatas[0].color }}></Icon>
          </DropdownToggle>

          <DropdownMenu className={cx(style["border-0"], style["p-0"], style["shadow"])}>
            <ColorPicker
              color={props.multiColorDatas[0].color}
              setColor={props.setMultiColorDatas}
              colorClass={props.multiColorDatas[0].className}
              multiColorDatas={props.multiColorDatas}
              multiColors={props.multiColors}
              setMultiColors={props.setMultiColors}
              setPreviousColor={props.setPreviousColor}
              setCurrentColor={props.setCurrentColor}
              idx={0}
              setUpdateContext={props.setUpdateContext}
              setTextSelectedAreaColor={props.setTextSelectedAreaColor}
            />
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
};
//Props type validation
MulticolorBucket.propTypes = {
  multiColors: PropTypes.array,
  setMultiColors: PropTypes.func,
  multiColorDatas: PropTypes.array,
  setMultiColorDatas: PropTypes.func,
  setPreviousColor: PropTypes.func,
  setCurrentColor: PropTypes.func,
  iconBucketColor: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  setLoadCurrentFormatting: PropTypes.func,
  setUpdateContext: PropTypes.func,
};

export default MulticolorBucket;
