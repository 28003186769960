import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import AssetLists from "./Common/AssetLists";
import SliderControll from "../SliderControll";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { widgetConfig } from "../editor_config";
import { changeBucketColorAccordingBackground, colorHexCodeValidation, hexToRGB } from "../../../_helpers/utils";
import ColorBucket from "../ContextualMenu/ColorBucket";
import AssetSchemeTypeNav from "./Common/AssetSchemeTypeNav";

let style = Object.assign({}, global);

const BackgroundColor = props => {
  return (
    <div className={cx(style["mb-3"], style["pb-1"], style["color-edit"])}>
      <ColorBucket
        color={props.color}
        setColor={props.setColor}
        iconBucketColor={props.iconBucketColor}
        position={"leftPane"}
        setUpdateContext={props.setUpdateContext}
        eyedropperColor={props.eyedropperColor}
        setEyedropperColor={props.setEyedropperColor}
        isHideTransparentOpt={true}
      />
    </div>
  );
};
//Props type validation
BackgroundColor.propTypes = {
  color: PropTypes.string,
  setColor: PropTypes.func.isRequired,
  iconBucketColor: PropTypes.string.isRequired,
  setUpdateContext: PropTypes.func,
  eyedropperColor: PropTypes.string,
  setEyedropperColor: PropTypes.func,
};

const Background = props => {
  let {
    metadata,
    backgroundImages,
    updateBackgroundImages,
    backgroundColors,
    updateBackgroundColors,
    currentPointerPos,
  } = useContext(EditorContext);
  let i = backgroundImages.findIndex(backgroundImage => backgroundImage.blockId === metadata.activeBlockId);
  let index = backgroundColors.findIndex(backgroundColor => backgroundColor.blockId === metadata.activeBlockId);

  const backgroundSchemes = ["color", "gradient", "pattern"];

  const [schemeType, setSchemeType] = useState("color");
  const [pageNumber, setPageNumber] = useState(1);
  const [isAssetFetched, setIsAssetFetched] = useState(false);
  const [color, setColor] = useState("");
  const [opacity, setOpacity] = useState();
  const [opacityInputValue, setOpacityInputValue] = useState();
  const [iconBucketColor, setIconBucketColor] = useState("light");
  const [isShownOpacityControll, setIsShownOpacityControll] = useState();
  const [updateContext, setUpdateContext] = useState(false);
  const [updateBcImageContext, setUpdateBcImageContext] = useState(false);
  const [eyedropperColor, setEyedropperColor] = useState();
  const [asstesLoading, setAsstesLoading] = useState(true);

  const availableColors = [
    "#7f5539",
    "#b08968",
    "#ddb892",
    "#ecdbd0",
    "#014f86",
    "#2c7da0",
    "#61a5c2",
    "#a9d6e5",
    "#ff0a54",
    "#ff5c8a",
    "#ff85a1",
    "#fbb1bd",
    "#2d6a4f",
    "#52b788",
    "#95d5b2",
    "#d8f3dc",
    "#595959",
    "#7f7f7f",
    "#a5a5a5",
    "#cccccc",
    "#fbd722",
    "#ffe566",
    "#ffee99",
    "#fff6cc",
    "#b28dff",
    "#c5a3ff",
    "#e3c7ff",
    "#f2daff",
    "#d32f2f",
    "#f44336",
    "#f9837a",
    "#fee1df",
    "#f57c00",
    "#ff9800",
    "#ffbc59",
    "#ffefd5",
    "#0288d1",
    "#03a9f4",
    "#4fc3f7",
    "#b3e5fc",
    "#7b1fa2",
    "#9c27b0",
    "#ba68c8",
    "#efcaf5",
    "#388e3c",
    "#4caf50",
    "#81c784",
    "#c8e6c9",
    "#512da8",
    "#673ab7",
    "#9575cd",
    "#d1c4e9",
    "#00796b",
    "#009688",
    "#4db6ac",
    "#b2dfdb",
    "#c2185b",
    "#e91e63",
    "#f06292",
    "#f8bbd0",
    "#424242",
    "#757575",
    "#bdbdbd",
    "#eeeeee",
  ];

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (eyedropperColor) {
      setUpdateContext(true);
      setColor(eyedropperColor);
      setEyedropperColor();
    }
  }, [eyedropperColor]);

  //To add or update background color in active block on change
  useEffect(() => {
    let isHex = colorHexCodeValidation(color);
    // isHex = color === "transparent" ? true : isHex; // Note: optimise later
    if (color && isHex) {
      let updatedBackgroundColorObj = {
        blockId: metadata.activeBlockId,
        pageId: metadata.activePageId,
        style: { ...widgetConfig.backgrounds.color.style, backgroundColor: color },
      };

      //for update
      if (index > -1) {
        let newArray = Object.assign([...backgroundColors], {
          [index]: { ...backgroundColors[index], style: { ...backgroundColors[index].style, backgroundColor: color } },
        });
        updateContext && updateBackgroundColors(newArray);
      } else {
        updateContext && updateBackgroundColors([...backgroundColors, updatedBackgroundColorObj]);
      }

      setUpdateContext(false);
      setIconBucketColor(changeBucketColorAccordingBackground(hexToRGB(color)));
    }
  }, [color, updateContext]);

  //To update opacity in active block on change opacity value
  useEffect(() => {
    if (i !== -1) {
      document.querySelector(`#${metadata.activeBlockId} .dhp-page-overlay`).style.opacity = opacity / 100;

      let newArray = Object.assign([...backgroundImages], {
        [i]: { ...backgroundImages[i], style: { ...backgroundImages[i].style, opacity: opacity / 100 } },
      });

      updateBcImageContext && updateBackgroundImages(newArray);
      setUpdateBcImageContext(false);
    }
  }, [opacity, updateBcImageContext]);

  //To update opacity and color field values on change active block
  useEffect(() => {
    if (i !== -1) {
      setOpacity(Math.round(backgroundImages[i].style.opacity * 100));
      setOpacityInputValue(Math.round(backgroundImages[i].style.opacity * 100));
      setIsShownOpacityControll(true);
    } else {
      setOpacity(100);
      setOpacityInputValue(100);
      setIsShownOpacityControll(false);
    }
    if (index !== -1) {
      setColor(backgroundColors[index].style.backgroundColor);
    } else {
      setColor("#ffffff");
    }
  }, [index, i, currentPointerPos]);

  return (
    <div
      className={cx(style["editor-asset-inner"], style["background-asset"], {
        [style["show-opacity"]]: isShownOpacityControll && schemeType !== "color",
      })}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />

      <div className={style["pr-4"]}>
        <BackgroundColor
          color={color}
          setColor={setColor}
          iconBucketColor={iconBucketColor}
          setUpdateContext={setUpdateContext}
          eyedropperColor={eyedropperColor}
          setEyedropperColor={setEyedropperColor}
        />

        <AssetSchemeTypeNav
          schemeTypeArray={backgroundSchemes}
          setSchemeType={setSchemeType}
          schemeType={schemeType}
          setPageNumber={setPageNumber}
          setIsAssetFetched={setIsAssetFetched}
          assetType={props.assetType}
          className="mr-0"
          setAsstesLoading={setAsstesLoading}
        />
      </div>

      {isShownOpacityControll && schemeType !== "color" && (
        <div className={style["pr-4"]}>
          <div className={cx(style["opacity-wrap"], style["mb-3"], style["rounded"])}>
            <h6 className={style["mb-none"]}>Opacity</h6>
            <SliderControll
              Slider={opacity}
              setSlider={setOpacity}
              SliderInputValue={opacityInputValue}
              setSliderInputValue={setOpacityInputValue}
              sliderMax={100}
              sliderMin={0}
              setUpdateContext={setUpdateBcImageContext}
            />
          </div>
        </div>
      )}

      <AssetLists
        assetName={props.assetName}
        assetType={props.assetType}
        assetSchemeType={schemeType}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        isAssetFetched={isAssetFetched}
        setIsAssetFetched={setIsAssetFetched}
        setOpacity={setOpacity}
        setOpacityInputValue={setOpacityInputValue}
        setColor={setColor}
        availableColors={availableColors}
        setUpdateContext={setUpdateContext}
        asstesLoading={asstesLoading}
        setAsstesLoading={setAsstesLoading}
      />
    </div>
  );
};

//Props type validation
Background.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};
export default Background;
