import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";

import global from "../../../scss/dhp.scss";
import Crown from "../../../assets/images/ui-crown.svg";
import AssetsLoader from "../../ui/loader/assetsLoader";

import AssetName from "./Common/AssetName";
import useDragDropWidget from "../../../hooks/useDragDropWidget";
import useTextFocusOut from "../../../hooks/useTextFocusOut";
import useAddWidget from "../../../hooks/useAddWidget";
import useAlignment from "../../../hooks/useAlignment";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { widgetConfig } from "../editor_config";
import { fetchAssetsList } from "../../../store/actions/editorActions";
import { stopAllResourceBuffering } from "../../../_helpers/utils";
import { DotLoader } from "../../ui/loader/dotLoader";
import { useContextualUpgrade } from "../../../hooks/useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../../constants/company";
import { Tooltip } from "reactstrap";

let style = Object.assign({}, global);

const CollageAsset = props => {
  const { metadata, dimension } = useContext(EditorContext);
  const showUpgrade = useContextualUpgrade();
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);
  const { start: initDragDropWidget } = useDragDropWidget();

  const [isAssetLoad, setIsAssetLoad] = useState(false);
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  const [dataParam, setDataParam] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const setTextFocusOut = useTextFocusOut();
  const { postion: dropPosition } = useAlignment(addWidgetClicked, widgetWidth, widgetHeight, "middle-center");
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    props.assetType,
    dataParam,
    dropPosition,
    widgetWidth,
    widgetHeight
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    props.assetType,
    assetInnerContent,
    dataParam,
    getNewWidgetObject
  );

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const scrollLeftPane = () => {
    setTooltipOpen(false);
  };

  const insertElem = () => {
    let paramObj = {};

    let { width: activeBlockWidth, height: activeBlockHeight } = document
      .getElementById(`${metadata.activeBlockId}`)
      ?.getBoundingClientRect();
    activeBlockWidth = (activeBlockWidth * 100) / parseFloat(dimension.zoom);
    activeBlockHeight = (activeBlockHeight * 100) / parseFloat(dimension.zoom);

    const node = document.createElement("div");
    node.innerHTML = props.filedata;
    node.querySelector(".main-content").style.minHeight = `${activeBlockHeight}px`;
    node.querySelector(".collage-container").style.gap = "10px";

    setAssetInnerContent(node);
    setWidgetWidth(activeBlockWidth);
    setWidgetHeight(activeBlockHeight);
    setDataParam({ ...widgetConfig[props.assetType].dataAttr, ...paramObj });
    setAddWidgetClicked(true);
  };

  const widgetAddHandler = (action, e) => {
    const isLeftClick = e.button === 0;

    // NOTE:::: Only when left mouse button is pressed i.e. MouseEvent.button = 0
    if (isLeftClick) {
      if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

      if (action === "CLICK") {
        if (props.premium && paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
          showUpgrade("premiumAsset", props.thumb, { assetType: props.assetType });
        } else insertElem();
      }
      if (action === "DND") {
        initDragDropWidget(
          {
            asset: props.assetType,
            value: props.filedata,
            thumb: props.thumb,
            premium: props.premium,
          },
          e
        );
      }
    }
  };

  useEffect(() => {
    document.querySelector(".assets-wrapper").addEventListener("scroll", scrollLeftPane);

    return () => {
      document.querySelector(".assets-wrapper")?.removeEventListener("scroll", scrollLeftPane);
    };
  }, []);

  return (
    <li className={cx(style["col"], style["skeleton-loader-area"])}>
      <div
        id={`asset-image-${props._id}`}
        className={cx(style["asset-item"])}
        onClick={e => widgetAddHandler("CLICK", e)}
        onMouseDown={e => widgetAddHandler("DND", e)}>
        <div className={style["item-holder"]}>
          {!isAssetLoad && <span className={style["loader-item"]}></span>}

          <img
            src={props.thumb}
            onLoad={() => setIsAssetLoad(true)}
            className={cx({ [style["d-none"]]: !isAssetLoad }, style["icon"])}
            onError={e => {
              e.target.onerror = null;
              e.target.src = props.thumb;
            }}
          />

          {props.premium && (
            <span className={style["crown-icon"]}>
              <img
                src={Crown}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = Crown;
                }}
                className={style["img-fluid"]}
                alt="crown"
              />
            </span>
          )}
        </div>
      </div>

      {isAssetLoad && (
        <Tooltip
          className="asset-hover-tooltip"
          placement="right"
          isOpen={tooltipOpen}
          target={`asset-image-${props._id}`}
          toggle={toggle}
          boundariesElement={document.getElementById("app")}>
          <div className="image-preview collage-preview">
            <img src={props.thumb} alt="" className={style["img-fluid"]} />
          </div>
        </Tooltip>
      )}
    </li>
  );
};
//Props type validation
CollageAsset.propTypes = {
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

const CollageList = props => {
  let hasMore = false;
  const dispatch = useDispatch();
  const loadMoreRef = useRef();
  const collageListRef = useRef();

  const [availableCollageList, setAvailableCollageList] = useState([]);
  const [payload, setPayload] = useState({
    assetType: props.assetType,
    pageNumber: 1,
    limit: 50,
  });
  const [loading, setLoading] = useState();
  const [totalCount, setTotalCount] = useState();
  const [availableassetLists, setAvailableassetLists] = useState([]);

  if (totalCount > 0) {
    const lastPage = Math.floor(
      totalCount % payload.limit === 0 ? totalCount / payload.limit : totalCount / payload.limit + 1
    );
    hasMore = payload.pageNumber < lastPage;
  }

  useEffect(() => {
    if (availableassetLists && payload.pageNumber === 1) {
      setAvailableCollageList(availableassetLists);
    }
    if (availableassetLists && payload.pageNumber > 1)
      setAvailableCollageList([...availableCollageList, ...availableassetLists]);

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore) setPayload({ ...payload, pageNumber: payload.pageNumber + 1 });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);

    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [availableassetLists]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAssetsList(payload))
      .then(resp => {
        if (resp.status === 200) {
          setLoading(false);
          setTotalCount(resp.data.total);
          setAvailableassetLists(resp.data.data.value);
        }
      })
      .catch(() => setLoading(false));
  }, [payload]);

  useEffect(() => {
    return () => {
      stopAllResourceBuffering(collageListRef.current, "img");
    };
  }, []);

  return (
    <div
      className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}
      ref={collageListRef}
      onContextMenu={e => e.preventDefault()}>
      <ul className={cx(style["row"], style["row-cols-2"])}>
        {loading && payload.pageNumber === 1 && <AssetsLoader count={40} />}

        {availableCollageList.map((e, i) => (
          <CollageAsset key={i} {...{ ...props, ...e }} />
        ))}
      </ul>

      {!loading && <div ref={loadMoreRef} style={{ height: "20px" }}></div>}

      {loading && payload.pageNumber > 1 && <DotLoader />}
    </div>
  );
};
//Props type validation
CollageList.propTypes = {
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

const Collage = props => {
  return (
    <div className={cx(style["editor-asset-inner"], style["collage-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />
      <CollageList assetName={props.assetName} assetType={props.assetType} />
    </div>
  );
};
//Props type validation
Collage.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default Collage;
