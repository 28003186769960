import { useContext, useState } from "react";
import {
  // DRAG,
  TEXT,
  TEXT_FRAME,
  GROUP_WIDGET,
  TYPE_INFOGRAPHIC,
  TYPE_PROPOSAL,
} from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  checkChildOutsideParent,
  getCssTransformObj,
  getUnScaledValue,
  getWidgetAndParentsDomReference,
  getZoomedValue,
  searchNodeByPoint,
} from "../_helpers/utils";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import useCheckWidgetPosition from "./useCheckWidgetPosition";
import useDeleteWidget from "./useDeleteWidget";
import useSetActivePageBlock from "./useSetActivePageBlock";
import useSnaptoGrid from "./useSnaptoGrid";
import useWidgetHandler from "./useWidgetHandler";
import useCollaborativeSelector from "./useCollaborativeSelector";
import useWidgetHighlighter from "./useWidgetHighlighter";
import useFindHighlighter from "./useFindHighlighter";

const useDraggable = () => {
  const {
    dimension,
    widgets,
    metadata,
    documentType,
    updateWidgets,
    updateMetadata,
    isTimeLineViewOpen,
  } = useContext(EditorContext);

  const { hide: hide_WHT } = useWidgetHighlighter();
  const {
    toggleEventHandlers: toggleWidgetEventHandlers,
    // toggleTooltip: toggleWidgetEventTooltip
  } = useWidgetHandler();

  const [activeContainerInfo, setActiveContainerInfo] = useState();
  useSetActivePageBlock(activeContainerInfo);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const { showSnappedGridLines, hideGrideLines } = useSnaptoGrid();
  const deleteWidgetIfOutside = useCheckWidgetPosition();
  const setDeleteWidget = useDeleteWidget();
  const updateCollaborativeWidgetSelectionStyle = useCollaborativeSelector();
  const updateFindHighlighter = useFindHighlighter();

  const draggable = {
    meta: false,

    detachHyperLinkData: ({ widget, isHyperLinkedToDestination }) => {
      const widgetData = { ...widget.data };
      const { valid, type, ...rest } = isHyperLinkedToDestination;

      if (valid && type === "single") {
        delete widgetData["data-hyperlink-type"];
        delete widgetData["data-hyperlink-url"];
        delete widgetData["data-hyperlink-pageid"];
        const widgetclassLists = [...widget.classLists].filter(e => e !== "dhp-widget-hyperlinked");

        return {
          widgetData,
          updatedClassAndInnerHTML: { classLists: widgetclassLists },
        };
      } else if (valid && type === "group-child") {
        return {
          widgetData,
          updatedClassAndInnerHTML: { innerHTML: rest.innerHTML },
        };
      } else if (!valid) {
        return {
          widgetData,
          updatedClassAndInnerHTML: {},
        };
      }
    },

    getAcrossBlockData: ({ client, mouse, isMovedAcrossBlock }) => {
      const isHyperLinkedToDestination = {};

      if (isMovedAcrossBlock) {
        const dX = mouse.current.clientX - isMovedAcrossBlock.blockRect.left - client.pointerOffset.x;
        const dY = mouse.current.clientY - isMovedAcrossBlock.blockRect.top - client.pointerOffset.y;

        const widgetTransformStr = getCssTransformObj({
          translateX: getUnScaledValue(dX, dimension.zoom) + "px",
          translateY: getUnScaledValue(dY, dimension.zoom) + "px",
          transform: client.initial.transform,
          returnStr: true,
        });

        const isgroupWidget =
          client?.node?.classList?.contains("dhp-page-group") || client?.node?.dataset?.assetType === GROUP_WIDGET;

        if (isgroupWidget) {
          let valid = false;
          const groupWidget = client?.node;

          groupWidget?.querySelectorAll(".dhp-page-widget").forEach(w => {
            if (isMovedAcrossBlock.blockIdx === parseInt(w?.dataset?.hyperlinkUrl)) {
              if (!valid) valid = true;
              w.removeAttribute("data-hyperlink-type");
              w.removeAttribute("data-hyperlink-url");
              w.removeAttribute("data-hyperlink-pageid");
              w.classList.remove("dhp-widget-hyperlinked");
            }
          });

          isHyperLinkedToDestination.type = "group-child";
          isHyperLinkedToDestination.valid = valid;
          isHyperLinkedToDestination.innerHTML = groupWidget.innerHTML;
        } else {
          isHyperLinkedToDestination.type = "single";
          isHyperLinkedToDestination.valid =
            isMovedAcrossBlock.blockIdx === parseInt(client?.node?.dataset?.hyperlinkUrl);
        }

        return {
          newParentIds: { pageId: isMovedAcrossBlock.pageId, blockId: isMovedAcrossBlock.blockId },
          newTransform: widgetTransformStr,
          isHyperLinkedToDestination,
        };
      } else {
        return {
          newParentIds: {},
          newTransform: client.current.transform,
          isHyperLinkedToDestination,
        };
      }
    },

    moveAcrossBlock: ({ isMultiOperation, blockId, mouse, isMovedAcrossBlock = false }) => {
      const { node: isDroppedOnBlock } = searchNodeByPoint({
        className: "dhp-page-block",
        x: mouse.current.pageX,
        y: mouse.current.pageY,
      });
      const isDroppedOnDifferentBlock = isDroppedOnBlock && isDroppedOnBlock.id !== blockId && isDroppedOnBlock;

      if (isDroppedOnDifferentBlock) {
        isMovedAcrossBlock = {
          pageId: isDroppedOnDifferentBlock?.closest(".dhp-page-canvas")?.id,
          pageIdx: parseInt(isDroppedOnDifferentBlock?.closest(".dhp-page-canvas")?.dataset?.pageIdx),
          pageNode: isDroppedOnDifferentBlock?.closest(".dhp-page-canvas"),
          blockId: isDroppedOnDifferentBlock?.id,
          blockIdx: parseInt(isDroppedOnDifferentBlock?.dataset.blockIdx),
          blockNode: isDroppedOnDifferentBlock,
          blockRect: isDroppedOnDifferentBlock.getBoundingClientRect(),
        };

        const correctedMeta = !isMultiOperation
          ? {
              activeWidgetId: false,
              activeWidgetType: false,
            }
          : {};

        updateMetadata({
          ...metadata,
          activePageId: isMovedAcrossBlock.pageId,
          activePageIdx: isMovedAcrossBlock.pageIdx,
          activeBlockId: isMovedAcrossBlock.blockId,
          activeBlockIdx: isMovedAcrossBlock.blockIdx,
          ...correctedMeta,
        });
      }

      return isMovedAcrossBlock;
    },

    updateReactDom: ({ isMultiOperation, clients, blockId, mouse, handler }) => {
      const isMovedAcrossBlock = draggable.moveAcrossBlock({ isMultiOperation, blockId, mouse });

      if (!isMultiOperation) {
        const client = clients[0];
        const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(false, client.current.transform, false, client.id);
        const isDeletable = !isMovedAcrossBlock && deleteWidgetIfOutside(client.id, blockId);

        // Check single widget position during drag, if it is outside canvas, delete the widget else upadte new position
        if (isDeletable) setDeleteWidget(true);
        else {
          const { newParentIds, newTransform, isHyperLinkedToDestination } = draggable.getAcrossBlockData({
            client,
            mouse,
            isMovedAcrossBlock,
          });

          updateWidgets(
            widgets.map((widget, idx) => {
              if (idx === client.idx) {
                const { widgetData, updatedClassAndInnerHTML } = draggable.detachHyperLinkData({
                  widget,
                  isHyperLinkedToDestination,
                });

                return {
                  ...widget,
                  style: { ...widget.style, transform: newTransform },
                  data: {
                    ...widgetData,
                    "data-x-allignment": x_al,
                    "data-y-allignment": y_al,
                  },
                  ...updatedClassAndInnerHTML,
                  ...newParentIds,
                };
              } else {
                const assetType = widget.data["data-asset-type"];

                if ([TEXT, TEXT_FRAME].includes(assetType)) {
                  if (isTimeLineViewOpen && blockId !== widget.blockId) {
                    return widget;
                  } else {
                    // bugfix ~ In edit mode, after drag STOP (other widget), old innerHTML and height is showing for text & text frame
                    const widgetNode = document.getElementById(widget.id);
                    const offsetHeight = widgetNode?.querySelector(".dhp-widget-inner")?.offsetHeight;
                    const updatedStyle = assetType === TEXT ? { style: { ...widget.style, height: offsetHeight } } : {};

                    return {
                      ...widget,
                      innerHTML: widgetNode.innerHTML,
                      ...updatedStyle,
                    };
                  }
                } else {
                  return widget;
                }
              }
            })
          );

          if (isMovedAcrossBlock) {
            const intervalId = setInterval(() => {
              const isMovedAcrossBlockDone = document.querySelector(`#${isMovedAcrossBlock.blockId} #${client.id}`);
              if (isMovedAcrossBlockDone) {
                clearTimeout(intervalId);
                updateMetadata({
                  ...metadata,
                  pageController: {
                    ...metadata.pageController,
                    style: {
                      ...metadata.pageController.style,
                      top:
                        isMovedAcrossBlock.blockIdx === 0
                          ? 0
                          : [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
                          ? getZoomedValue(isMovedAcrossBlock.blockNode.offsetTop, dimension.zoom) + "px"
                          : isMovedAcrossBlock.pageNode.offsetTop + "px",
                    },
                  },
                  activePageId: isMovedAcrossBlock.pageId,
                  activePageIdx: isMovedAcrossBlock.pageIdx,
                  activeBlockId: isMovedAcrossBlock.blockId,
                  activeBlockIdx: isMovedAcrossBlock.blockIdx,
                  activeWidgetId: [client.id],
                  activeWidgetType: [client.assetType],
                });
              }
            }, 300);
          }
        }
      } else if (isMultiOperation) {
        const activeWidgetIds = [];
        const activeWidgetTypes = [];
        const isWidgetsOutsideCanvas =
          !isMovedAcrossBlock && checkChildOutsideParent({ parent: `#${blockId}`, child: handler.node });

        // Check multi widget position during drag, if all are outside canvas, delete them else upadte their new positions
        if (isWidgetsOutsideCanvas) setDeleteWidget(true);
        else {
          let newArray = Object.assign([...widgets], [...widgets]);
          clients.forEach(client => {
            const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
              false,
              client.current.transform,
              false,
              client.id
            );

            const { newParentIds, newTransform, isHyperLinkedToDestination } = draggable.getAcrossBlockData({
              client,
              mouse,
              isMovedAcrossBlock,
            });

            const { widgetData, updatedClassAndInnerHTML } = draggable.detachHyperLinkData({
              widget: widgets[client.idx],
              isHyperLinkedToDestination,
            });

            if (isMovedAcrossBlock) {
              activeWidgetIds.push(client.id);
              activeWidgetTypes.push(client.assetType);
            }

            newArray = Object.assign([...newArray], {
              [client.idx]: {
                ...widgets[client.idx],
                style: {
                  ...widgets[client.idx].style,
                  transform: newTransform,
                },
                data: {
                  ...widgetData,
                  "data-x-allignment": x_al,
                  "data-y-allignment": y_al,
                },
                ...updatedClassAndInnerHTML,
                ...newParentIds,
              },
            });
          });

          updateWidgets(newArray);

          if (isMovedAcrossBlock) {
            const client = clients[0];
            const intervalId = setInterval(() => {
              const isMovedAcrossBlockDone = document.querySelector(`#${isMovedAcrossBlock.blockId} #${client.id}`);
              if (isMovedAcrossBlockDone) {
                clearTimeout(intervalId);
                updateMetadata({
                  ...metadata,
                  pageController: {
                    ...metadata.pageController,
                    style: {
                      ...metadata.pageController.style,
                      top:
                        isMovedAcrossBlock.blockIdx === 0
                          ? 0
                          : [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
                          ? getZoomedValue(isMovedAcrossBlock.blockNode.offsetTop, dimension.zoom) + "px"
                          : isMovedAcrossBlock.pageNode.offsetTop + "px",
                    },
                  },
                  activePageId: isMovedAcrossBlock.pageId,
                  activePageIdx: isMovedAcrossBlock.pageIdx,
                  activeBlockId: isMovedAcrossBlock.blockId,
                  activeBlockIdx: isMovedAcrossBlock.blockIdx,
                  activeWidgetId: activeWidgetIds,
                  activeWidgetType: activeWidgetTypes,
                });
              }
            }, 300);
          }
        }
      }
    },

    start: (data, e) => {
      const {
        widget: { id: widgetId, node: widgetNode, isLocked: isWidgetLocked, isSingleSelected, isGroupSelected },
        block: { id: blockId, data: blockData, rect: activeBlockRect },
        page: { data: pageData },
        editorOuterWrapTop,
      } = getWidgetAndParentsDomReference(data.widgetId);

      toggleWidgetEventHandlers({ action: isWidgetLocked ? "HIDE" : "SHOW" });

      if (!isWidgetLocked) {
        // isSingleSelected => for single and group widgets that are selected single
        // isGroupSelected => for single and group widgets that are selected multiple

        sessionStorage.setItem("retainRotation", true)// to keep handler rotation for shift selectd widgets
        const clients = [];
        const selectedWidgetIds = [];
        const selectedWidgetTypes = [];
        const handler = document.getElementById("dhp-widget-handler");
        const query = isSingleSelected ? `#${widgetId}` : isGroupSelected ? `.group-selected` : "";

        const {
          translate: { x: handlerTransX, y: handlerTransY },
        } = getCssTransformObj({
          transform: handler.style.transform,
        });

        document.querySelectorAll(query).forEach(widget => {
          const widgetIdx = widgets.findIndex(w => w.id === widget.id);
          const {
            translate: { x: widgetTransX, y: widgetTransY },
          } = getCssTransformObj({
            transform: widget.style.transform,
          });

          clients.push({
            id: widget.id,
            idx: widgetIdx,
            node: widget,
            assetType: widget.dataset.assetType,
            pointerOffset: {
              x: e.clientX - activeBlockRect.left - getZoomedValue(widgetTransX, dimension.zoom),
              y: e.clientY - activeBlockRect.top - getZoomedValue(widgetTransY, dimension.zoom),
            },
            initial: {
              transform: widget.style.transform,
            },
            current: {
              transform: false,
            },
          });

          selectedWidgetIds.push(widget.id);
          selectedWidgetTypes.push(widget.dataset.assetType);
        });

        let props = {
          isDragging: true,
          isFirstEvent: true,
          isMultiOperation: isGroupSelected,
          clients: clients,
          blockId: blockId,
          blockRect: activeBlockRect,
          editorOuterWrapTop: editorOuterWrapTop,
          mouse: {
            current: false,
          },
          handler: {
            node: handler,
            pointerOffset: {
              x: e.clientX - activeBlockRect.left - parseFloat(handlerTransX),
              y: e.clientY - activeBlockRect.top - parseFloat(handlerTransY),
            },
            initial: {
              transform: handler.style.transform,
            },
          },
        };

        draggable.meta = props;
        document.addEventListener("mousemove", draggable.drag);
        document.addEventListener("mouseup", draggable.stop);

        setActiveContainerInfo({
          targetPageIdx: parseInt(pageData.pageIdx),
          targetBlockIdx: parseInt(blockData.blockIdx),
          activeWidgetId: selectedWidgetIds,
          activeWidgetType: selectedWidgetTypes,
          disableAutoScroll: true,
        });
      }
    },

    drag: e => {
      let meta = draggable.meta;
      if (meta?.isDragging) {
        // determine new drag position of widget(s)
        meta.clients.forEach(client => {
          const dX = e.clientX - meta.blockRect.left - client.pointerOffset.x;
          const dY = e.clientY - meta.blockRect.top - client.pointerOffset.y;

          const widgetTransformStr = getCssTransformObj({
            translateX: getUnScaledValue(dX, dimension.zoom) + "px",
            translateY: getUnScaledValue(dY, dimension.zoom) + "px",
            transform: client.initial.transform,
            returnStr: true,
          });

          client.node.style.transform = widgetTransformStr;
          client.current.transform = widgetTransformStr;
          client.node.style.willChange = "transform"; // resolved background line issue in canvas
        });

        // determine new drag position of handler
        const dX = e.clientX - meta.blockRect.left - meta.handler.pointerOffset.x;
        const dY = e.clientY - meta.blockRect.top - meta.handler.pointerOffset.y;

        const handlerTransformStr = getCssTransformObj({
          translateX: dX + "px",
          translateY: dY + "px",
          transform: meta.handler.initial.transform,
          returnStr: true,
        });

        meta.handler.node.style.transform = handlerTransformStr;
        meta.mouse.current = e;

        // other drag operations like (detecting isFirstEvent, toggleWidgetEventHandlers, showSnappedGridLines, toggleWidgetEventTooltip etc.)
        if (meta.isFirstEvent) {
          toggleWidgetEventHandlers({ action: "HIDE" });
          meta.isFirstEvent = false;
          localStorage.setItem("widget.event.isActive", "true");
        }

        updateFindHighlighter();
        updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom });
        showSnappedGridLines(meta.handler.node);
        hide_WHT();

        /*<<< BACKUP:::: enable code below to show drag tooltip with displacement from (0,0) origin
        toggleWidgetEventTooltip({
          action: "SHOW",
          event: DRAG,
          tooltip: `X: ${Math.floor(getUnScaledValue(dX, dimension.zoom))}  Y: ${Math.floor(
            Math.abs(meta.blockRect.top - meta.editorOuterWrapTop) + getUnScaledValue(dY, dimension.zoom)
          )}`,
        });
        >>>*/
      }
    },

    stop: () => {
      let meta = draggable.meta;
      if (meta?.isDragging) {
        toggleWidgetEventHandlers({ action: "SHOW" });
        /*<<< BACKUP:::: enable line below to hide drag tooltip
        toggleWidgetEventTooltip({ action: "HIDE", event: DRAG });
        >>>*/

        // resolved background line issue in canvas
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        meta.clients.forEach(client => {
          if (!isSafari) client.node.style.willChange = "";
        });

        if (!meta.isFirstEvent) draggable.updateReactDom({ ...meta });

        document.removeEventListener("mousemove", draggable.drag);
        document.removeEventListener("mouseup", draggable.stop);
        meta = false;
        hideGrideLines();
        localStorage.setItem("widget.event.isActive", "false");
      }
    },
  };

  return { start: draggable.start };
};

export default useDraggable;
