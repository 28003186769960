// for authActions
export const USER_LOGIN_START = "USER_LOGIN_START";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export const USER_LOGOUT_START = "USER_LOGOUT_START";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_FAILED = "USER_LOGOUT_FAILED";

// FOR Company info and update
export const GET_COMPANY_INFO_START = "GET_COMPANY_INFO_START";
export const GET_COMPANY_INFO_SUCCESS = "GET_COMPANY_INFO_SUCCESS";
export const GET_COMPANY_INFO_FAILED = "GET_COMPANY_INFO_FAILED";
export const UPDATE_COMPANY_INFO_START = "UPDATE_COMPANY_INFO_START";
export const UPDATE_COMPANY_INFO_SUCCESS = "UPDATE_COMPANY_INFO_SUCCESS";
export const UPDATE_COMPANY_INFO_FAILED = "UPDATE_COMPANY_INFO_FAILED";

// For Resources action types
export const COUNTRIES_FETCH_START = "COUNTRIES_FETCH_START";
export const COUNTRIES_FETCH_SUCCESS = "COUNTRIES_FETCH_SUCCESS";
export const COUNTRIES_FETCH_FAILED = "COUNTRIES_FETCH_FAILED";

export const STATES_FETCH_START = "STATES_FETCH_START";
export const STATES_FETCH_SUCCESS = "STATES_FETCH_SUCCESS";
export const STATES_FETCH_FAILED = "STATES_FETCH_FAILED";

// FOR user info
export const GET_USER_INFO_START = "GET_USER_INFO_START";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED";
export const UPDATE_USER_INFO_START = "UPDATE_USER_INFO_START";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_FAILED = "UPDATE_USER_INFO_FAILED";

// For signup
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

// For Signup
export const USER_SIGNUP_START = "USER_SIGNUP_START";
export const USER_SIGNUP_FAILED = "USER_SIGNUP_FAILED";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";

// For Signup verification
export const SIGNUP_VERIFICATION_START = "SIGNUP_VERIFICATION_START";
export const SIGNUP_VERIFICATION_FAILED = "SIGNUP_VERIFICATION_FAILED";

export const SIGNUP_VERIFICATION_SUCCESS = "SIGNUP_VERIFICATION_SUCCESS";

// For Resources action types
export const RESOURCES_FETCH_START = "RESOURCES_FETCH_START";
export const RESOURCES_FETCH_SUCCESS = "RESOURCES_FETCH_SUCCESS";
export const RESOURCES_FETCH_FAILED = "RESOURCES_FETCH_FAILED";

// For resend verification otp
export const RESEND_VERIFICATION_START = "RESEND_VERIFICATION_START";
export const RESEND_VERIFICATION_SUCCESS = "RESEND_VERIFICATION_SUCCESS";
export const RESEND_VERIFICATION_FAILED = "RESEND_VERIFICATION_FAILED";
//For Widget List
export const WIDGET_FETCH_START = "WIDGET_FETCH_START";
export const WIDGET_FETCH_SUCCESS = "WIDGET_FETCH_SUCCESS";
export const WIDGET_FETCH_FAILED = "WIDGET_FETCH_FAILED";

//For Asset List
export const ASSET_FETCH_START = "ASSET_FETCH_START";
export const ASSET_FETCH_SUCCESS = "ASSET_FETCH_SUCCESS";
export const ASSET_FETCH_FAILED = "ASSET_FETCH_FAILED";

//For Asset List after applied filter
export const ASSET_FILTER_START = "ASSET_FILTER_START";
export const ASSET_FILTER_SUCCESS = "ASSET_FILTER_SUCCESS";
export const ASSET_FILTER_FAILED = "ASSET_FILTER_FAILED";

//For Video List
export const VIDEO_FETCH_START = "VIDEO_FETCH_START";
export const VIDEO_FETCH_SUCCESS = "VIDEO_FETCH_SUCCESS";
export const VIDEO_FETCH_FAILED = "VIDEO_FETCH_FAILED";

//For Upload Asset
export const UPLOAD_ASSET_START = "UPLOAD_ASSET_START";
export const UPLOAD_ASSET_SUCCESS = "UPLOAD_ASSET_SUCCESS";
export const UPLOAD_ASSET_FAILED = "UPLOAD_ASSET_FAILED";

//For Uploaded Asset List
export const UPLOAD_ASSET_FETCH_START = "UPLOAD_ASSET_FETCH_START";
export const UPLOAD_ASSET_FETCH_SUCCESS = "UPLOAD_ASSET_FETCH_SUCCESS";
export const UPLOAD_ASSET_FETCH_FAILED = "UPLOAD_ASSET_FETCH_FAILED";

//For Delete Upload Asset
export const UPLOAD_ASSET_DELETE_START = "UPLOAD_ASSET_DELETE_START";
export const UPLOAD_ASSET_DELETE_SUCCESS = "UPLOAD_ASSET_DELETE_SUCCESS";
export const UPLOAD_ASSET_DELETE_FAILED = "UPLOAD_ASSET_DELETE_FAILED";

// For TWO-step action types
export const TWO_STEP_OTP_SEND_START = "TWO_STEP_OTP_SEND_START";
export const TWO_STEP_OTP_SEND_SUCCESS = "TWO_STEP_OTP_SEND_SUCCESS";
export const TWO_STEP_OTP_SEND_FAILED = "TWO_STEP_OTP_SEND_FAILED";
export const TWO_STEP_OTP_VALIDATION_START = "TWO_STEP_OTP_VALIDATION_START";
export const TWO_STEP_OTP_VALIDATION_SUCCESS = "TWO_STEP_OTP_VALIDATION_SUCCESS";
export const TWO_STEP_OTP_VALIDATION_FAILED = "TWO_STEP_OTP_VALIDATION_FAILED";
export const ACTIVATE_TWO_STEP_START = "ACTIVATE_TWO_STEP_START";
export const ACTIVATE_TWO_STEP_SUCCESS = "ACTIVATE_TWO_STEP_SUCCESS";
export const ACTIVATE_TWO_STEP_FAILED = "ACTIVATE_TWO_STEP_FAILED";
export const DEACTIVATE_TWO_STEP_START = "DEACTIVATE_TWO_STEP_START";
export const DEACTIVATE_TWO_STEP_SUCCESS = "DEACTIVATE_TWO_STEP_SUCCESS";
export const DEACTIVATE_TWO_STEP_FAILED = "DEACTIVATE_TWO_STEP_FAILED";

export const GET_BACKUP_CODE_START = "GET_BACKUP_CODE_START";
export const GET_BACKUP_CODE_SUCCESS = "GET_BACKUP_CODE_SUCCESS";
export const GET_BACKUP_CODE_FAILED = "GET_BACKUP_CODE_FAILED";

export const GENERATE_BACKUP_CODE_START = "GENERATE_BACKUP_CODE_START";
export const GENERATE_BACKUP_CODE_SUCCESS = "GENERATE_BACKUP_CODE_SUCCESS";
export const GENERATE_BACKUP_CODE_FAILED = "GENERATE_BACKUP_CODE_FAILED";

export const SEND_BACKUP_CODE_START = "SEND_BACKUP_CODE_START";
export const SEND_BACKUP_CODE_SUCCESS = "SEND_BACKUP_CODE_SUCCESS";
export const SEND_BACKUP_CODE_FAILED = "SEND_BACKUP_CODE_FAILED";

export const DELETE_TWO_STEP_MODE_START = "DELETE_TWO_STEP_MODE_START";
export const DELETE_TWO_STEP_MODE_SUCCESS = "DELETE_TWO_STEP_MODE_SUCCESS";
export const DELETE_TWO_STEP_MODE_FAILED = "DELETE_TWO_STEP_MODE_FAILED";

// FOR fetch user info
export const UPDATE_USER_COMPANY_INFO_START = "UPDATE_USER_COMPANY_INFO_START";
export const UPDATE_USER_COMPANY_INFO_SUCCESS = "UPDATE_USER_COMPANY_INFO_SUCCESS";
export const UPDATE_USER_COMPANY_INFO_FAILED = "UPDATE_USER_COMPANY_INFO_FAILED";

//For forgot-password
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

// For reset-password
export const RESET_LINK_VERIFY_START = "RESET_LINK_VERIFY_START";
export const RESET_LINK_VERIFY_SUCCESS = "RESET_LINK_VERIFY_SUCCESS";
export const RESET_LINK_VERIFY_FAILED = "RESET_LINK_VERIFY_FAILED";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

// For ip Location
export const GET_IP_LOCATION_START = "GET_IP_LOCATION_START";
export const GET_IP_LOCATION_SUCCESS = "GET_IP_LOCATION_SUCCESS";
export const GET_IP_LOCATION_FAILED = "GET_IP_LOCATION_FAILED";

// For template group
export const TEMPLATE_GROUP_START = "TEMPLATE_GROUP_START";
export const TEMPLATE_GROUP_SUCCESS = "TEMPLATE_GROUP_SUCCESS";
export const TEMPLATE_GROUP_FAILED = "TEMPLATE_GROUP_FAILED";

// For template page size
export const TEMPLATE_PAGE_SIZE_START = "TEMPLATE_PAGE_SIZE_START";
export const TEMPLATE_PAGE_SIZE_SUCCESS = "TEMPLATE_PAGE_SIZE_SUCCESS";
export const TEMPLATE_PAGE_SIZE_FAILED = "TEMPLATE_PAGE_SIZE_FAILED";

//For template search
export const TEMPLATE_SEARCH_START = "TEMPLATE_SEARCH_START";
export const TEMPLATE_SEARCH_SUCCESS = "TEMPLATE_SEARCH_SUCCESS";
export const TEMPLATE_SEARCH_FAILED = "TEMPLATE_SEARCH_FAILED";

// For 2 step authentication
export const TWO_STEP_SIGNIN_AUTHENTICATION_START = "TWO_STEP_SIGNIN_AUTHENTICATION_START";
export const TWO_STEP_SIGNIN_AUTHENTICATION_SUCCESS = "TWO_STEP_SIGNIN_AUTHENTICATION_SUCCESS";
export const TWO_STEP_SIGNIN_AUTHENTICATION_FAILED = "TWO_STEP_SIGNIN_AUTHENTICATION_FAILED";

// For 2 step OTP token send
export const SEND_2_STEP_SIGNIN_OTP_START = "SEND_2_STEP_SIGNIN_OTP_START";
export const SEND_2_STEP_SIGNIN_OTP_SUCCESS = "SEND_2_STEP_SIGNIN_OTP_SUCCESS";
export const SEND_2_STEP_SIGNIN_OTP_FAILED = "SEND_2_STEP_SIGNIN_OTP_FAILED";

// for companyActions
export const COMPANY_FETCH_START = "COMPANY_FETCH_START";
export const COMPANY_FETCH_SUCCESS = "COMPANY_FETCH_SUCCESS";
export const COMPANY_FETCH_FAILED = "COMPANY_FETCH_FAILED";

export const COMPANY_SWITCH_START = "COMPANY_SWITCH_START";
export const COMPANY_SWITCH_SUCCESS = "COMPANY_SWITCH_SUCCESS";
export const COMPANY_SWITCH_FAILED = "COMPANY_SWITCH_FAILED";

// for searchActions
export const GLOBAL_SEARCH_START = "GLOBAL_SEARCH_START";
export const GLOBAL_SEARCH_SUCCESS = "GLOBAL_SEARCH_SUCCESS";
export const GLOBAL_SEARCH_FAILED = "GLOBAL_SEARCH_FAILED";

// for trashActions
export const TRASH_FETCH_START = "TRASH_FETCH_START";
export const TRASH_FETCH_SUCCESS = "TRASH_FETCH_SUCCESS";
export const TRASH_FETCH_FAILED = "TRASH_FETCH_FAILED";

export const TRASH_RESTORE_START = "TRASH_RESTORE_START";
export const TRASH_RESTORE_SUCCESS = "TRASH_RESTORE_SUCCESS";
export const TRASH_RESTORE_FAILED = "TRASH_RESTORE_FAILED";

// For get users list
export const GET_USER_LIST_START = "GET_USER_LIST_START";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILED = "GET_USER_LIST_FAILED";

// For get users activate
export const ACTIVATE_USERS_START = "ACTIVATE_USERS_START";
export const ACTIVATE_USERS_SUCCESS = "ACTIVATE_USERS_SUCCESS";
export const ACTIVATE_USERS_FAILED = "ACTIVATE_USERS_FAILED";

// For get users deactivate
export const DEACTIVATE_USERS_START = "DEACTIVATE_USERS_START";
export const DEACTIVATE_USERS_SUCCESS = "DEACTIVATE_USERS_SUCCESS";
export const DEACTIVATE_USERS_FAILED = "DEACTIVATE_USERS_FAILED";

// For get users reinvite
export const REINVITE_USERS_START = "REINVITE_USERS_START";
export const REINVITE_USERS_SUCCESS = "REINVITE_USERS_SUCCESS";
export const REINVITE_USERS_FAILED = "REINVITE_USERS_FAILED";

// For delete users
export const DELETE_USERS_START = "DELETE_USERS_START";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_FAILED = "DELETE_USERS_FAILED";

// For company users
export const FETCH_COMPANY_USER_INFO_START = "FETCH_COMPANY_USER_INFO_START";
export const FETCH_COMPANY_USER_INFO_SUCCESS = "FETCH_COMPANY_USER_INFO_SUCCESS";
export const FETCH_COMPANY_USER_INFO_FAILED = "FETCH_COMPANY_USER_INFO_FAILED";

// For company invitation accept
export const ACCEPT_COMPANY_INVITATION_START = "ACCEPT_COMPANY_INVITATION_START";
export const ACCEPT_COMPANY_INVITATION_SUCCESS = "ACCEPT_COMPANY_INVITATION_SUCCESS";
export const ACCEPT_COMPANY_INVITATION_FAILED = "ACCEPT_COMPANY_INVITATION_FAILED";

// For Recycle bin
export const TRASH_DELETE_START = "TRASH_DELETE_START";
export const TRASH_DELETE_SUCCESS = "TRASH_DELETE_SUCCESS";
export const TRASH_DELETE_FAILED = "TRASH_DELETE_FAILED";

//For Asset List
export const MANAGER_ASSET_FETCH_START = "MANAGER_ASSET_FETCH_START";
export const MANAGER_ASSET_FETCH_SUCCESS = "MANAGER_ASSET_FETCH_SUCCESS";
export const MANAGER_ASSET_FETCH_FAILED = "MANAGER_ASSET_FETCH_FAILED";

export const MANAGER_ASSET_UPLOAD_START = "MANAGER_ASSET_UPLOAD_START";
export const MANAGER_ASSET_UPLOAD_SUCCESS = "MANAGER_ASSET_UPLOAD_SUCCESS";
export const MANAGER_ASSET_UPLOAD_FAILED = "MANAGER_ASSET_UPLOAD_FAILED";

export const MANAGER_ASSET_UPDATE_START = "MANAGER_ASSET_UPDATE_START";
export const MANAGER_ASSET_UPDATE_SUCCESS = "MANAGER_ASSET_UPDATE_SUCCESS";
export const MANAGER_ASSET_UPDATE_FAILED = "MANAGER_ASSET_UPDATE_FAILED";

export const FETCH_MANAGER_ASSET_CATEGORY_START = "FETCH_MANAGER_ASSET_CATEGORY_START";
export const FETCH_MANAGER_ASSET_CATEGORY_SUCCESS = "FETCH_MANAGER_ASSET_CATEGORY_SUCCESS";
export const FETCH_MANAGER_ASSET_CATEGORY_FAILED = "FETCH_MANAGER_ASSET_CATEGORY_FAILED";

// For paddle subscription
export const FETCH_SUBSCRIPTION_PLAN_START = "FETCH_SUBSCRIPTION_PLAN_START";
export const FETCH_SUBSCRIPTION_PLAN_SUCCESS = "FETCH_SUBSCRIPTION_PLAN_SUCCESS";
export const FETCH_SUBSCRIPTION_PLAN_FAILED = "FETCH_SUBSCRIPTION_PLAN_FAILED";

// For paddle subscription
export const FETCH_SUBSCRIPTION_PRICE_DETAILS_START = "FETCH_SUBSCRIPTION_PRICE_DETAILS_START";
export const FETCH_SUBSCRIPTION_PRICE_DETAILS_SUCCESS = "FETCH_SUBSCRIPTION_PRICE_DETAILS_SUCCESS";
export const FETCH_SUBSCRIPTION_PRICE_DETAILS_FAILED = "FETCH_SUBSCRIPTION_PRICE_DETAILS_FAILED";

// For Subscription order
export const FETCH_SUBSCRIPTION_DETAILS_START = "FETCH_SUBSCRIPTION_DETAILS_START";
export const FETCH_SUBSCRIPTION_DETAILS_SUCCESS = "FETCH_SUBSCRIPTION_DETAILS_SUCCESS";
export const FETCH_SUBSCRIPTION_DETAILS_FAILED = "FETCH_SUBSCRIPTION_DETAILS_FAILED";

// For Subscription invoices
export const FETCH_SUBSCRIPTION_INVOICES_START = "FETCH_SUBSCRIPTION_INVOICES_START";
export const FETCH_SUBSCRIPTION_INVOICES_SUCCESS = "FETCH_SUBSCRIPTION_INVOICES_SUCCESS";
export const FETCH_SUBSCRIPTION_INVOICES_FAILED = "FETCH_SUBSCRIPTION_INVOICES_FAILED";

// For Subscription Currency
export const FETCH_SUBSCRIPTION_CURRENCY_START = "FETCH_SUBSCRIPTION_CURRENCY_START";
export const FETCH_SUBSCRIPTION_CURRENCY_SUCCESS = "FETCH_SUBSCRIPTION_CURRENCY_SUCCESS";
export const FETCH_SUBSCRIPTION_CURRENCY_FAILED = "FETCH_SUBSCRIPTION_CURRENCY_FAILED";

// For Subscription cancel
export const CANCEL_SUBSCRIPTION_START = "CANCEL_SUBSCRIPTION_START";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILED = "CANCEL_SUBSCRIPTION_FAILED";

// for documentActions
export const DOCUMENT_FETCH_START = "DOCUMENT_FETCH_START";
export const DOCUMENT_FETCH_SUCCESS = "DOCUMENT_FETCH_SUCCESS";
export const DOCUMENT_FETCH_FAILED = "DOCUMENT_FETCH_FAILED";

export const DOCUMENT_UPDATE_START = "DOCUMENT_UPDATE_START";
export const DOCUMENT_UPDATE_SUCCESS = "DOCUMENT_UPDATE_SUCCESS";
export const DOCUMENT_UPDATE_FAILED = "DOCUMENT_UPDATE_FAILED";

export const DOCUMENT_CLONE_START = "DOCUMENT_CLONE_START";
export const DOCUMENT_CLONE_SUCCESS = "DOCUMENT_CLONE_SUCCESS";
export const DOCUMENT_CLONE_FAILED = "DOCUMENT_CLONE_FAILED";

export const DOCUMENT_SHARE_START = "DOCUMENT_SHARE_START";
export const DOCUMENT_SHARE_SUCCESS = "DOCUMENT_SHARE_SUCCESS";
export const DOCUMENT_SHARE_FAILED = "DOCUMENT_SHARE_FAILED";

export const DOCUMENT_SHARE_UPDATE_PERMISSION_START = "DOCUMENT_SHARE_UPDATE_PERMISSION_START";
export const DOCUMENT_SHARE_UPDATE_PERMISSION_SUCCESS = "DOCUMENT_SHARE_UPDATE_PERMISSION_SUCCESS";
export const DOCUMENT_SHARE_UPDATE_PERMISSION_FAILED = "DOCUMENT_SHARE_UPDATE_PERMISSION_FAILED";

export const DOCUMENT_SHARE_DELETE_USER_START = "DOCUMENT_SHARE_DELETE_USER_START";
export const DOCUMENT_SHARE_DELETE_USER_SUCCESS = "DOCUMENT_SHARE_DELETE_USER_SUCCESS";
export const DOCUMENT_SHARE_DELETE_USER_FAILED = "DOCUMENT_SHARE_DELETE_USER_FAILED";

export const DOCUMENT_SHARE_INFO_START = "DOCUMENT_SHARE_INFO_START";
export const DOCUMENT_SHARE_INFO_SUCCESS = "DOCUMENT_SHARE_INFO_SUCCESS";
export const DOCUMENT_SHARE_INFO_FAILED = "DOCUMENT_SHARE_INFO_FAILED";

export const DOCUMENT_PUBLISH_START = "DOCUMENT_PUBLISH_START";
export const DOCUMENT_PUBLISH_SUCCESS = "DOCUMENT_PUBLISH_SUCCESS";
export const DOCUMENT_PUBLISH_FAILED = "DOCUMENT_PUBLISH_FAILED";
// For Send Company Delete email
export const SEND_DELETE_EMAIL_START = "SEND_DELETE_EMAIL_START";
export const SEND_DELETE_EMAIL_SUCCESS = "SEND_DELETE_EMAIL_SUCCESS";
export const SEND_DELETE_EMAIL_FAILED = "SEND_DELETE_EMAIL_FAILED";

// For delete company
export const DELETE_COMPANY_START = "DELETE_COMPANY_START";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILED = "DELETE_COMPANY_FAILED";

export const DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_START = "DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_START";
export const DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS = "DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS";
export const DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED = "DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED";

export const DOCUMENT_PUBLISH_SET_PASSWORD_START = "DOCUMENT_PUBLISH_SET_PASSWORD_START";
export const DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS = "DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS";
export const DOCUMENT_PUBLISH_SET_PASSWORD_FAILED = "DOCUMENT_PUBLISH_SET_PASSWORD_FAILED";

export const DOCUMENT_PUBLISH_UNSET_PASSWORD_START = "DOCUMENT_PUBLISH_UNSET_PASSWORD_START";
export const DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS = "DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS";
export const DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED = "DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED";

export const DOCUMENT_UNPUBLISH_START = "DOCUMENT_UNPUBLISH_START";
export const DOCUMENT_UNPUBLISH_SUCCESS = "DOCUMENT_UNPUBLISH_SUCCESS";
export const DOCUMENT_UNPUBLISH_FAILED = "DOCUMENT_UNPUBLISH_FAILED";

export const DOCUMENT_PUBLISH_HANDLE_BADGE_START = "DOCUMENT_PUBLISH_HANDLE_BADGE_START";
export const DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS = "DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS";
export const DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED = "DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED";

export const DOCUMENT_PUBLISH_EMAIL_VERIFICATION_START = "DOCUMENT_PUBLISH_EMAIL_VERIFICATION_START";
export const DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS = "DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS";
export const DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED = "DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED";

export const DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_START = "DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_START";
export const DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_SUCCESS = "DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_SUCCESS";
export const DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_FAILED = "DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_FAILED";

export const DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_START = "DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_START";
export const DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_SUCCESS = "DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_SUCCESS";
export const DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_FAILED = "DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_FAILED";

export const DOCUMENT_TRANSFER_START = "DOCUMENT_TRANSFER_START";
export const DOCUMENT_TRANSFER_SUCCESS = "DOCUMENT_TRANSFER_SUCCESS";
export const DOCUMENT_TRANSFER_FAILED = "DOCUMENT_TRANSFER_FAILED";

export const DOCUMENT_DELETE_START = "DOCUMENT_DELETE_START";
export const DOCUMENT_DELETE_SUCCESS = "DOCUMENT_DELETE_SUCCESS";
export const DOCUMENT_DELETE_FAILED = "DOCUMENT_DELETE_FAILED";

export const DOCUMENT_DETAILS_FETCH_START = "DOCUMENT_DETAILS_FETCH_START";
export const DOCUMENT_DETAILS_FETCH_SUCCESS = "DOCUMENT_DETAILS_FETCH_SUCCESS";
export const DOCUMENT_DETAILS_FETCH_FAILED = "DOCUMENT_DETAILS_FETCH_FAILED";

export const DOCUMENT_COMMENTS_START = "DOCUMENT_COMMENTS_START";
export const DOCUMENT_COMMENTS_SUCCESS = "DOCUMENT_COMMENTS_SUCCESS";
export const DOCUMENT_COMMENTS_FAILED = "DOCUMENT_COMMENTS_FAILED";

export const INSERT_COMMENTS_START = "INSERT_COMMENTS_START";
export const INSERT_COMMENTS_SUCCESS = "INSERT_COMMENTS_SUCCESS";
export const INSERT_COMMENTS_FAILED = "INSERT_COMMENTS_FAILED";

export const UPDATE_COMMENT_START = "UPDATE_COMMENT_START";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED";

export const DELETE_COMMENT_START = "DELETE_COMMENT_START";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED";

export const APPLY_LIKE_START = "APPLY_LIKE_START";
export const APPLY_LIKE_SUCCESS = "APPLY_LIKE_SUCCESS";
export const APPLY_LIKE_FAILED = "APPLY_LIKE_FAILED";

// For update payment method
export const UPDATE_PAYMENT_METHOD_START = "UPDATE_PAYMENT_METHOD_START";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_FAILED = "UPDATE_PAYMENT_METHOD_FAILED";

// For updatesubscription
export const UPDATE_SUBSCRIPTION_START = "UPDATE_SUBSCRIPTION_START";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAILED = "UPDATE_SUBSCRIPTION_FAILED";

// For Generate pay link
export const GENERATE_PAYLINK_START = "GENERATE_PAYLINK_START";
export const GENERATE_PAYLINK_SUCCESS = "GENERATE_PAYLINK_SUCCESS";
export const GENERATE_PAYLINK_FAILED = "GENERATE_PAYLINK_FAILED";

// For Document version fetch
export const FETCH_DOCUMENT_VERSION_START = "FETCH_DOCUMENT_VERSION_START";
export const FETCH_DOCUMENT_VERSION_SUCCESS = "FETCH_DOCUMENT_VERSION_SUCCESS";
export const FETCH_DOCUMENT_VERSION_FAILED = "FETCH_DOCUMENT_VERSION_FAILED";

// For Document restore
export const RESTORE_DOCUMENT_VERSION_START = "RESTORE_DOCUMENT_VERSION_START";
export const RESTORE_DOCUMENT_VERSION_SUCCESS = "RESTORE_DOCUMENT_VERSION_SUCCESS";
export const RESTORE_DOCUMENT_VERSION_FAILED = "RESTORE_DOCUMENT_VERSION_FAILED";

// For Document version update
export const UPDATE_DOCUMENT_VERSION_START = "UPDATE_DOCUMENT_VERSION_START";
export const UPDATE_DOCUMENT_VERSION_SUCCESS = "UPDATE_DOCUMENT_VERSION_SUCCESS";
export const UPDATE_DOCUMENT_VERSION_FAILED = "UPDATE_DOCUMENT_VERSION_FAILED";

// For template category

export const TEMPLATE_CATEGORIES_START = "TEMPLATE_CATEGORIES_START";
export const TEMPLATE_CATEGORIES_SUCCESS = "TEMPLATE_CATEGORIES_SUCCESS";
export const TEMPLATE_CATEGORIES_FAILED = "TEMPLATE_CATEGORIES_FAILED";

// For published document fetch
export const PUBLISHED_DOCUMENT_FETCH_START = "PUBLISHED_DOCUMENT_FETCH_START";
export const PUBLISHED_DOCUMENT_FETCH_SUCCESS = "PUBLISHED_DOCUMENT_FETCH_SUCCESS";
export const PUBLISHED_DOCUMENT_FETCH_FAILED = "PUBLISHED_DOCUMENT_FETCH_FAILED";

//For published otp verified document fetch
export const PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_START = "PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_START";
export const PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_SUCCESS = "PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_SUCCESS";
export const PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_FAILED = "PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_FAILED";

// for Template Details
export const TEMPLATE_DETAILS_FETCH_START = "TEMPLATE_DETAILS_FETCH_START";
export const TEMPLATE_DETAILS_FETCH_SUCCESS = "TEMPLATE_DETAILS_FETCH_SUCCESS";
export const TEMPLATE_DETAILS_FETCH_FAILED = "TEMPLATE_DETAILS_FETCH_FAILED";

// For Profile Image Uplod
export const PROFILE_IMAGE_UPLOAD_START = "PROFILE_IMAGE_UPLOAD_START";
export const PROFILE_IMAGE_UPLOAD_SUCCESS = "PROFILE_IMAGE_UPLOAD_SUCCESS";
export const PROFILE_IMAGE_UPLOAD_FAILED = "PROFILE_IMAGE_UPLOAD_FAILED";

// for documentActions
export const DOCUMENT_INSERT_START = "DOCUMENT_INSERT_START";
export const DOCUMENT_INSERT_SUCCESS = "DOCUMENT_INSERT_SUCCESS";
export const DOCUMENT_INSERT_FAILED = "DOCUMENT_INSERT_FAILED";

// for create user company
export const CREATE_COMPANY_START = "CREATE_COMPANY_START";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILED = "CREATE_COMPANY_FAILED";

//For Stock Photos
export const STOCK_PHOTO_FETCH_START = "STOCK_PHOTO_FETCH_START";
export const STOCK_PHOTO_FETCH_SUCCESS = "STOCK_PHOTO_FETCH_SUCCESS";
export const STOCK_PHOTO_FETCH_FAILED = "STOCK_PHOTO_FETCH_FAILED";

// For Specific Version Info
export const FETCH_DOCUMENT_VERSION_INFO_START = "FETCH_DOCUMENT_VERSION_INFO_START";
export const FETCH_DOCUMENT_VERSION_INFO_SUCCESS = "FETCH_DOCUMENT_VERSION_INFO_SUCCESS";
export const FETCH_DOCUMENT_VERSION_INFO_FAILED = "FETCH_DOCUMENT_VERSION_INFO_FAILED";

// For Active Company Users
export const FETCH_ACTIVE_COMPANY_USERS_START = "FETCH_ACTIVE_COMPANY_USERS_START";
export const FETCH_ACTIVE_COMPANY_USERS_SUCCESS = "FETCH_ACTIVE_COMPANY_USERS_SUCCESS";
export const FETCH_ACTIVE_COMPANY_USERS_FAILED = "FETCH_ACTIVE_COMPANY_USERS_FAILED";

// For fonts fetch in published document
export const FONTS_FETCH_START = "FONTS_FETCH_START";
export const FONTS_FETCH_SUCCESS = "FONTS_FETCH_SUCCESS";
export const FONTS_FETCH_FAILED = "FONTS_FETCH_FAILED";

// For Third Party accounts
export const GET_CONNECTED_ACCOUNTS_START = "GET_CONNECTED_ACCOUNTS_START";
export const GET_CONNECTED_ACCOUNTS_SUCCESS = "GET_CONNECTED_ACCOUNTS_SUCCESS";
export const GET_CONNECTED_ACCOUNTS_FAILED = "GET_CONNECTED_ACCOUNTS_FAILED";

export const DELETE_CONNECTED_ACCOUNT_START = "DELETE_CONNECTED_ACCOUNT_START";
export const DELETE_CONNECTED_ACCOUNT_SUCCESS = "DELETE_CONNECTED_ACCOUNT_SUCCESS";
export const DELETE_CONNECTED_ACCOUNT_FAILED = "DELETE_CONNECTED_ACCOUNT_FAILED";

// for templateActions
export const TEMPLATES_START = "TEMPLATES_START";
export const TEMPLATES_SUCCESS = "TEMPLATES_SUCCESS";
export const TEMPLATES_FAILED = "TEMPLATES_FAILED";

export const TEMPLATE_TYPE_START = "TEMPLATES_TYPE_START";
export const TEMPLATE_TYPE_SUCCESS = "TEMPLATES_TYPE_SUCCESS";
export const TEMPLATE_TYPE_FAILED = "TEMPLATES_TYPE_FAILED";

export const TEMPLATE_UNPUBLISH_START = "TEMPLATE_UNPUBLISH_START";
export const TEMPLATE_UNPUBLISH_SUCCESS = "TEMPLATE_UNPUBLISH_SUCCESS";
export const TEMPLATE_UNPUBLISH_FAILED = "TEMPLATE_UNPUBLISH_FAILED";

export const FETCH_BLANK_TEMPLATE_SIZE_START = "FETCH_BLANK_TEMPLATE_SIZE_START";
export const FETCH_BLANK_TEMPLATE_SIZE_SUCCESS = "FETCH_BLANK_TEMPLATE_SIZE_SUCCESS";
export const FETCH_BLANK_TEMPLATE_SIZE_FAILED = "FETCH_BLANK_TEMPLATE_SIZE_FAILED";

// For remove image background
export const REMOVE_BACKGROUND_START = "REMOVE_BACKGROUND_START";
export const REMOVE_BACKGROUND_SUCCESS = "REMOVE_BACKGROUND_SUCCESS";
export const REMOVE_BACKGROUND_FAILED = "REMOVE_BACKGROUND_FAILED";

// Chage Email otp
export const SEND_CHANGE_EMAIL_OTP_START = "SEND_CHANGE_EMAIL_OTP_START";
export const SEND_CHANGE_EMAIL_OTP_SUCCESS = "SEND_CHANGE_EMAIL_OTP_SUCCESS";
export const SEND_CHANGE_EMAIL_OTP_FAILED = "SEND_CHANGE_EMAIL_OTP_FAILED";

// Chage Email
export const CHANGE_ACCOUNT_EMAIL_START = "CHANGE_ACCOUNT_EMAIL_START";
export const CHANGE_ACCOUNT_EMAIL_SUCCESS = "CHANGE_ACCOUNT_EMAIL_SUCCESS";
export const CHANGE_ACCOUNT_EMAIL_FAILED = "CHANGE_ACCOUNT_EMAIL_FAILED";

// For Send EmaiL
export const SEND_DOCUMENT_EMAIL_START = "SEND_DOCUMENT_EMAIL_START";
export const SEND_DOCUMENT_EMAIL_SUCCESS = "SEND_DOCUMENT_EMAIL_SUCCESS";
export const SEND_DOCUMENT_EMAIL_FAILED = "SEND_DOCUMENT_EMAIL_FAILED";

export const FETCH_SENT_EMAIL_LIST_START = "FETCH_SENT_EMAIL_LIST_START";
export const FETCH_SENT_EMAIL_LIST_SUCCESS = "FETCH_SENT_EMAIL_LIST_SUCCESS";
export const FETCH_SENT_EMAIL_LIST_FAILED = "FETCH_SENT_EMAIL_LIST_FAILED";

// Audit log
export const FETCH_AUDIT_LOG_START = "FETCH_AUDIT_LOG_START";
export const FETCH_AUDIT_LOG_SUCCESS = "FETCH_AUDIT_LOG_SUCCESS";
export const FETCH_AUDIT_LOG_FAILED = "FETCH_AUDIT_LOG_FAILED";

// Fetch User Config
export const FETCH_COMPANY_WISE_CONFIG_USER_START = "FETCH_COMPANY_WISE_CONFIG_USER_START";
export const FETCH_COMPANY_WISE_CONFIG_USER_SUCCESS = "FETCH_COMPANY_WISE_CONFIG_USER_SUCCESS";
export const FETCH_COMPANY_WISE_CONFIG_USER_FAILED = "FETCH_COMPANY_WISE_CONFIG_USER_FAILED";

// Update User Config
export const UPDATE_COMPANY_WISE_CONFIG_USER_START = "UPDATE_COMPANY_WISE_CONFIG_USER_START";
export const UPDATE_COMPANY_WISE_CONFIG_USER_SUCCESS = "UPDATE_COMPANY_WISE_CONFIG_USER_SUCCESS";
export const UPDATE_COMPANY_WISE_CONFIG_USER_FAILED = "UPDATE_COMPANY_WISE_CONFIG_USER_FAILED";

// Document create permission
export const CHECK_DOCUMENT_CREATE_PERMISSION_START = "CHECK_DOCUMENT_CREATE_PERMISSION_START";
export const CHECK_DOCUMENT_CREATE_PERMISSION_SUCCESS = "CHECK_DOCUMENT_CREATE_PERMISSION_SUCCESS";
export const CHECK_DOCUMENT_CREATE_PERMISSION_FAILED = "CHECK_DOCUMENT_CREATE_PERMISSION_FAILED";

// User Tour Data
export const UPDATE_USER_TOUR_CONFIG_START = "UPDATE_USER_TOUR_CONFIG_START";
export const UPDATE_USER_TOUR_CONFIG_SUCCESS = "UPDATE_USER_TOUR_CONFIG_SUCCESS";
export const UPDATE_USER_TOUR_CONFIG_FAILED = "UPDATE_USER_TOUR_CONFIG_FAILED";

// DELETE ACCOUNT
export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILED = "DELETE_ACCOUNT_FAILED";

// MANAGER USER
export const FETCH_MANAGER_USERS_START = "FETCH_MANAGER_USERS_START";
export const FETCH_MANAGER_USERS_SUCCESS = "FETCH_MANAGER_USERS_SUCCESS";
export const FETCH_MANAGER_USERS_FAILED = "FETCH_MANAGER_USERS_FAILED";

// Document version
export const CREATE_DOCUMENT_VERSION_START = "CREATE_DOCUMENT_VERSION_START";
export const CREATE_DOCUMENT_VERSION_SUCCESS = "CREATE_DOCUMENT_VERSION_SUCCESS";
export const CREATE_DOCUMENT_VERSION_FAILED = "CREATE_DOCUMENT_VERSION_FAILED";

// Export All users
export const FETCH_ALL_USER_LIST_START = "FETCH_ALL_USER_LIST_START";
export const FETCH_ALL_USER_LIST_SUCCESS = "FETCH_ALL_USER_LIST_SUCCESS";
export const FETCH_ALL_USER_LIST_FAILED = "FETCH_ALL_USER_LIST_FAILED";
// Document version
export const GENERATE_DOCUMENT_THUMB_START = "GENERATE_DOCUMENT_THUMB_START";
export const GENERATE_DOCUMENT_THUMB_SUCCESS = "GENERATE_DOCUMENT_THUMB_SUCCESS";
export const GENERATE_DOCUMENT_THUMB_FAILED = "GENERATE_DOCUMENT_THUMB_FAILED";

// trigger Unsplash Download
export const UNSPLASH_DOWNLOAD_START = "UNSPLASH_DOWNLOAD_START";
export const UNSPLASH_DOWNLOAD_SUCCESS = "UNSPLASH_DOWNLOAD_SUCCESS";
export const UNSPLASH_DOWNLOAD_FAILED = "UNSPLASH_DOWNLOAD_FAILED";

// update document reducer
export const UPDATE_DOCUMENT_REDUCER = "UPDATE_DOCUMENT_REDUCER";

// Typeform
export const FETCH_TYPEFORM_FORMS_START = "FETCH_TYPEFORM_FORMS_START";
export const FETCH_TYPEFORM_FORMS_SUCCESS = "FETCH_TYPEFORM_FORMS_SUCCESS";
export const FETCH_TYPEFORM_FORMS_FAILED = "FETCH_TYPEFORM_FORMS_FAILED";

//JotForm
export const FETCH_JOTFORM_FORMS_START = "FETCH_JOTFORM_FORMS_START";
export const FETCH_JOTFORM_FORMS_SUCCESS = "FETCH_JOTFORM_FORMS_SUCCESS";
export const FETCH_JOTFORM_FORMS_FAILED = "FETCH_JOTFORM_FORMS_FAILED";

export const CREATE_JOTFORM_FORMS_START = "CREATE_JOTFORM_FORMS_START";
export const CREATE_JOTFORM_FORMS_SUCCESS = "CREATE_JOTFORM_FORMS_SUCCESS";
export const CREATE_JOTFORM_FORMS_FAILED = "CREATE_JOTFORM_FORMS_FAILED";

//Sign Up Last Step
export const SIGNUP_LAST_STEP_START = "SIGNUP_LAST_STEP_START";
export const SIGNUP_LAST_STEP_SUCCESS = "SIGNUP_LAST_STEP_SUCCESS";
export const SIGNUP_LAST_STEP_FAILED = "SIGNUP_LAST_STEP_FAILED";

// template categories by type for manager superadmin
export const TEMPLATE_CATEGORIES_BY_TYPE_START = "TEMPLATE_CATEGORIES_BY_TYPE_START";
export const TEMPLATE_CATEGORIES_BY_TYPE_SUCCESS = "TEMPLATE_CATEGORIES_BY_TYPE_SUCCESS";
export const TEMPLATE_CATEGORIES_BY_TYPE_FAILED = "TEMPLATE_CATEGORIES_BY_TYPE_FAILED";

//Change Sign In Method to Email
export const CHANGE_SIGNIN_METHOD_TO_EMAIL_START = "CHANGE_SIGNIN_METHOD_TO_EMAIL_START";
export const CHANGE_SIGNIN_METHOD_TO_EMAIL_SUCCESS = "CHANGE_SIGNIN_METHOD_TO_EMAIL_SUCCESS";
export const CHANGE_SIGNIN_METHOD_TO_EMAIL_FAILED = "CHANGE_SIGNIN_METHOD_TO_EMAIL_FAILED";

// for text to image AI
export const FETCH_TEXT_TO_IMAGE_STYLE_START = "FETCH_TEXT_TO_IMAGE_STYLE_START";
export const FETCH_TEXT_TO_IMAGE_STYLE_SUCCESS = "FETCH_TEXT_TO_IMAGE_STYLE_SUCCESS";
export const FETCH_TEXT_TO_IMAGE_STYLE_FAILED = "FETCH_TEXT_TO_IMAGE_STYLE_FAILED";

export const FETCH_TEXT_TO_IMAGE_LIST_START = "FETCH_TEXT_TO_IMAGE_LIST_START";
export const FETCH_TEXT_TO_IMAGE_LIST_SUCCESS = "FETCH_TEXT_TO_IMAGE_LIST_SUCCESS";
export const FETCH_TEXT_TO_IMAGE_LIST_FAILED = "FETCH_TEXT_TO_IMAGE_LIST_FAILED";

export const FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_START = "FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_START";
export const FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_SUCCESS = "FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_SUCCESS";
export const FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_FAILED = "FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_FAILED";

export const CREATE_TEXT_TO_IMAGE_COLLECTION_START = "CREATE_TEXT_TO_IMAGE_COLLECTION_START";
export const CREATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS = "CREATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS";
export const CREATE_TEXT_TO_IMAGE_COLLECTION_FAILED = "CREATE_TEXT_TO_IMAGE_COLLECTION_FAILED";

export const UPDATE_TEXT_TO_IMAGE_COLLECTION_START = "UPDATE_TEXT_TO_IMAGE_COLLECTION_START";
export const UPDATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS = "UPDATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS";
export const UPDATE_TEXT_TO_IMAGE_COLLECTION_FAILED = "UPDATE_TEXT_TO_IMAGE_COLLECTION_FAILED";

export const DELETE_TEXT_TO_IMAGE_COLLECTION_START = "DELETE_TEXT_TO_IMAGE_COLLECTION_START";
export const DELETE_TEXT_TO_IMAGE_COLLECTION_SUCCESS = "DELETE_TEXT_TO_IMAGE_COLLECTION_SUCCESS";
export const DELETE_TEXT_TO_IMAGE_COLLECTION_FAILED = "DELETE_TEXT_TO_IMAGE_COLLECTION_FAILED";

export const CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_START = "CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_START";
export const CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS = "CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS";
export const CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED = "CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED";

export const UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_START = "UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_START";
export const UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS = "UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS";
export const UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED = "UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED";

//for AI writer
export const FETCH_AI_WRITER_TONE_START = "FETCH_AI_WRITER_TONE_START ";
export const FETCH_AI_WRITER_TONE_SUCCESS = "FETCH_AI_WRITER_TONE_SUCCESS";
export const FETCH_AI_WRITER_TONE_FAILED = "FETCH_AI_WRITER_TONE_FAILED";

export const CREATE_AI_WRITER_TEXT_COMPLETION_START = "CREATE_AI_WRITER_TEXT_COMPLETION_START ";
export const CREATE_AI_WRITER_TEXT_COMPLETION_SUCCESS = "CREATE_AI_WRITER_TEXT_COMPLETION_SUCCESS";
export const CREATE_AI_WRITER_TEXT_COMPLETION_FAILED = "CREATE_AI_WRITER_TEXT_COMPLETION_FAILED";

export const UPDATE_AI_WRITER_TEXT_COMPLETION_START = "UPDATE_AI_WRITER_TEXT_COMPLETION_START ";
export const UPDATE_AI_WRITER_TEXT_COMPLETION_SUCCESS = "UPDATE_AI_WRITER_TEXT_COMPLETION_SUCCESS";
export const UPDATE_AI_WRITER_TEXT_COMPLETION_FAILED = "UPDATE_AI_WRITER_TEXT_COMPLETION_FAILED";

export const FETCH_AI_WRITER_TEXT_COMPLETION_LIST_START = "FETCH_AI_WRITER_TEXT_COMPLETION_LIST_START ";
export const FETCH_AI_WRITER_TEXT_COMPLETION_LIST_SUCCESS = "FETCH_AI_WRITER_TEXT_COMPLETION_LIST_SUCCESS";
export const FETCH_AI_WRITER_TEXT_COMPLETION_LIST_FAILED = "FETCH_AI_WRITER_TEXT_COMPLETION_LIST_FAILED";

export const FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_START = "FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_START ";
export const FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_SUCCESS = "FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_SUCCESS";
export const FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_FAILED = "FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_FAILED";

export const DELETE_AI_WRITER_TEXT_COMPLETION_START = "DELETE_AI_WRITER_TEXT_COMPLETION_START ";
export const DELETE_AI_WRITER_TEXT_COMPLETION_SUCCESS = "DELETE_AI_WRITER_TEXT_COMPLETION_SUCCESS";
export const DELETE_AI_WRITER_TEXT_COMPLETION_FAILED = "DELETE_AI_WRITER_TEXT_COMPLETION_FAILED";

export const FETCH_AI_WRITER_EXTEND_START = "FETCH_AI_WRITER_EXTEND_START";
export const FETCH_AI_WRITER_EXTEND_SUCCESS = "FETCH_AI_WRITER_EXTEND_SUCCESS";
export const FETCH_AI_WRITER_EXTEND_FAILED = "FETCH_AI_WRITER_EXTEND_FAILED";

// FOR COMPANY BRAND
export const FETCH_BRAND_LIST_START = "FETCH_BRAND_LIST_START";
export const FETCH_BRAND_LIST_SUCCESS = "FETCH_BRAND_LIST_SUCCESS";
export const FETCH_BRAND_LIST_FAILED = "FETCH_BRAND_LIST_FAILED";

export const CREATE_BRAND_START = "CREATE_BRAND_START";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_FAILED = "CREATE_BRAND_FAILED";

export const UPDATE_BRAND_START = "UPDATE_BRAND_START";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAILED = "UPDATE_BRAND_FAILED";

export const DELETE_BRAND_START = "DELETE_BRAND_START";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILED = "DELETE_BRAND_FAILED";

export const DELETE_BRAND_ASSET_START = "DELETE_BRAND_ASSET_START";
export const DELETE_BRAND_ASSET_SUCCESS = "DELETE_BRAND_ASSET_SUCCESS";
export const DELETE_BRAND_ASSET_FAILED = "DELETE_BRAND_ASSET_FAILED";

export const FETCH_BRAND_DETAILS_START = "FETCH_BRAND_DETAILS_START";
export const FETCH_BRAND_DETAILS_SUCCESS = "FETCH_BRAND_DETAILS_SUCCESS";
export const FETCH_BRAND_DETAILS_FAILED = "FETCH_BRAND_DETAILS_FAILED";

// Custom Fonts
export const FETCH_CUSTOM_FONTS_START = "FETCH_CUSTOM_FONTS_START";
export const FETCH_CUSTOM_FONTS_SUCCESS = "FETCH_CUSTOM_FONTS_SUCCESS";
export const FETCH_CUSTOM_FONTS_FAILED = "FETCH_CUSTOM_FONTS_FAILED";

export const UPLOAD_CUSTOM_FONTS_START = "UPLOAD_CUSTOM_FONTS_START";
export const UPLOAD_CUSTOM_FONTS_SUCCESS = "UPLOAD_CUSTOM_FONTS_SUCCESS";
export const UPLOAD_CUSTOM_FONTS_FAILED = "UPLOAD_CUSTOM_FONTS_FAILED";

export const DELETE_CUSTOM_FONTS_START = "DELETE_CUSTOM_FONTS_START";
export const DELETE_CUSTOM_FONTS_SUCCESS = "DELETE_CUSTOM_FONTS_SUCCESS";
export const DELETE_CUSTOM_FONTS_FAILED = "DELETE_CUSTOM_FONTS_FAILED";

export const FETCH_ALL_CUSTOM_FONTS_START = "FETCH_ALL_CUSTOM_FONTS_START";
export const FETCH_ALL_CUSTOM_FONTS_SUCCESS = "FETCH_ALL_CUSTOM_FONTS_SUCCESS";
export const FETCH_ALL_CUSTOM_FONTS_FAILED = "FETCH_ALL_CUSTOM_FONTS_FAILED";

export const UPDATE_CUSTOM_FONTS = "UPDATE_CUSTOM_FONTS";

// AI Translate
export const FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_START = "FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_START";
export const FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_SUCCESS = "FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_SUCCESS";
export const FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_FAILED = "FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_FAILED";

export const FETCH_AI_TRANSLATE_USAGE_START = "FETCH_AI_TRANSLATE_USAGE_START";
export const FETCH_AI_TRANSLATE_USAGE_SUCCESS = "FETCH_AI_TRANSLATE_USAGE_SUCCESS";
export const FETCH_AI_TRANSLATE_USAGE_FAILED = "FETCH_AI_TRANSLATE_USAGE_FAILED";

export const AI_TRANSLATE_START = "AI_TRANSLATE_START";
export const AI_TRANSLATE_SUCCESS = "AI_TRANSLATE_SUCCESS";
export const AI_TRANSLATE_FAILED = "AI_TRANSLATE_FAILED";

//For Region List
export const REGION_FETCH_START = "REGION_FETCH_START";
export const REGION_FETCH_SUCCESS = "REGION_FETCH_SUCCESS";
export const REGION_FETCH_FAILED = "REGION_FETCH_FAILED";

//For Country List
export const COUNTRY_FETCH_START = "COUNTRY_FETCH_START";
export const COUNTRY_FETCH_SUCCESS = "COUNTRY_FETCH_SUCCESS";
export const COUNTRY_FETCH_FAILED = "COUNTRY_FETCH_FAILED";

// update editor reducer
export const UPDATE_EDITOR_REDUCER = "UPDATE_EDITOR_REDUCER";

// For Asset details
export const FETCH_ASSET_DETAILS_START = "FETCH_ASSET_DETAILS_START";
export const FETCH_ASSET_DETAILS_SUCCESS = "FETCH_ASSET_DETAILS_SUCCESS";
export const FETCH_ASSET_DETAILS_FAILED = "FETCH_ASSET_DETAILS_FAILED";

// Facebook Album & Images
export const FETCH_FACEBOOK_ALBUMS_START = "FETCH_FACEBOOK_ALBUMS_START";
export const FETCH_FACEBOOK_ALBUMS_SUCCESS = "FETCH_FACEBOOK_ALBUMS_SUCCESS";
export const FETCH_FACEBOOK_ALBUMS_FAILED = "FETCH_FACEBOOK_ALBUMS_FAILED";

export const FETCH_FACEBOOK_ALBUM_IMAGES_START = "FETCH_FACEBOOK_ALBUM_IMAGES_START";
export const FETCH_FACEBOOK_ALBUM_IMAGES_SUCCESS = "FETCH_FACEBOOK_ALBUM_IMAGES_SUCCESS";
export const FETCH_FACEBOOK_ALBUM_IMAGES_FAILED = "FETCH_FACEBOOK_ALBUM_IMAGES_FAILED";

// Upload Facebook Image
export const UPLOAD_FACEBOOK_IMAGE_START = "UPLOAD_FACEBOOK_IMAGE_START";
export const UPLOAD_FACEBOOK_IMAGE_SUCCESS = "UPLOAD_FACEBOOK_IMAGE_SUCCESS";
export const UPLOAD_FACEBOOK_IMAGE_FAILED = "UPLOAD_FACEBOOK_IMAGE_FAILED";

// Company Templates
export const PUBLISH_COMPANY_TEMPLATE_START = "PUBLISH_COMPANY_TEMPLATE_START";
export const PUBLISH_COMPANY_TEMPLATE_SUCCESS = "PUBLISH_COMPANY_TEMPLATE_SUCCESS";
export const PUBLISH_COMPANY_TEMPLATE_FAILED = "PUBLISH_COMPANY_TEMPLATE_FAILED";

export const PUBLISH_COMPANY_TEMPLATE_INFO_START = "PUBLISH_COMPANY_TEMPLATE_INFO_START";
export const PUBLISH_COMPANY_TEMPLATE_INFO_SUCCESS = "PUBLISH_COMPANY_TEMPLATE_INFO_SUCCESS";
export const PUBLISH_COMPANY_TEMPLATE_INFO_FAILED = "PUBLISH_COMPANY_TEMPLATE_INFO_FAILED";

export const UNPUBLISH_COMPANY_TEMPLATE_START = "UNPUBLISH_COMPANY_TEMPLATE_START";
export const UNPUBLISH_COMPANY_TEMPLATE_SUCCESS = "UNPUBLISH_COMPANY_TEMPLATE_SUCCESS";
export const UNPUBLISH_COMPANY_TEMPLATE_FAILED = "UNPUBLISH_COMPANY_TEMPLATE_FAILED";

export const FETCH_COMPANY_TEMPLATES_START = "FETCH_COMPANY_TEMPLATES_START";
export const FETCH_COMPANY_TEMPLATES_SUCCESS = "FETCH_COMPANY_TEMPLATES_SUCCESS";
export const FETCH_COMPANY_TEMPLATES_FAILED = "FETCH_COMPANY_TEMPLATES_FAILED";

export const FETCH_COMPANY_TEMPLATE_DETAILS_START = "FETCH_COMPANY_TEMPLATE_DETAILS_START";
export const FETCH_COMPANY_TEMPLATE_DETAILS_SUCCESS = "FETCH_COMPANY_TEMPLATE_DETAILS_SUCCESS";
export const FETCH_COMPANY_TEMPLATE_DETAILS_FAILED = "FETCH_COMPANY_TEMPLATE_DETAILS_FAILED";

export const FETCH_COMPANY_TEMPLATE_BRANDS_START = "FETCH_COMPANY_TEMPLATE_BRANDS_START";
export const FETCH_COMPANY_TEMPLATE_BRANDS_SUCCESS = "FETCH_COMPANY_TEMPLATE_BRANDS_SUCCESS";
export const FETCH_COMPANY_TEMPLATE_BRANDS_FAILED = "FETCH_COMPANY_TEMPLATE_BRANDS_FAILED";

//Filter
export const FETCH_SAVED_FILTER_START = "FETCH_SAVED_FILTER_START";
export const FETCH_SAVED_FILTER_SUCCESS = "FETCH_SAVED_FILTER_SUCCESS";
export const FETCH_SAVED_FILTER_FAILED = "FETCH_SAVED_FILTER_FAILED";

export const DELETE_SAVED_FILTER_START = "DELETE_SAVED_FILTER_START";
export const DELETE_SAVED_FILTER_SUCCESS = "DELETE_SAVED_FILTER_SUCCESS";
export const DELETE_SAVED_FILTER_FAILED = "DELETE_SAVED_FILTER_FAILED";

export const RENAME_SAVED_FILTER_START = "RENAME_SAVED_FILTER_START";
export const RENAME_SAVED_FILTER_SUCCESS = "RENAME_SAVED_FILTER_SUCCESS";
export const RENAME_SAVED_FILTER_FAILED = "RENAME_SAVED_FILTER_FAILED";

export const FETCH_APPLIED_FILTER_DOCUMENT_LIST_START = "FETCH_APPLIED_FILTER_DOCUMENT_LIST_START";
export const FETCH_APPLIED_FILTER_DOCUMENT_LIST_SUCCESS = "FETCH_APPLIED_FILTER_DOCUMENT_LIST_SUCCESS";
export const FETCH_APPLIED_FILTER_DOCUMENT_LIST_FAILED = "FETCH_APPLIED_FILTER_DOCUMENT_LIST_FAILED";

export const FETCH_APPLY_FILTER_DOCUMENT_START = "FETCH_APPLY_FILTER_DOCUMENT_START";
export const FETCH_APPLY_FILTER_DOCUMENT_SUCCESS = "FETCH_APPLY_FILTER_DOCUMENT_SUCCESS";
export const FETCH_APPLY_FILTER_DOCUMENT_FAILED = "FETCH_APPLY_FILTER_DOCUMENT_FAILED";

export const FETCH_FILTER_RECORDS_START = "FETCH_FILTER_RECORDS_START";
export const FETCH_FILTER_RECORDS_SUCCESS = "FETCH_FILTER_RECORDS_SUCCESS";
export const FETCH_FILTER_RECORDS_FAILED = "FETCH_FILTER_RECORDS_FAILED";

export const CREATE_SAVE_FILTER_START = "CREATE_SAVE_FILTER_START";
export const CREATE_SAVE_FILTER_SUCCESS = "CREATE_SAVE_FILTER_SUCCESS";
export const CREATE_SAVE_FILTER_FAILED = "CREATE_SAVE_FILTER_FAILED";

export const UPDATE_SAVED_FILTER_START = "UPDATE_SAVED_FILTER_START";
export const UPDATE_SAVED_FILTER_SUCCESS = "UPDATE_SAVED_FILTER_SUCCESS";
export const UPDATE_SAVED_FILTER_FAILED = "UPDATE_SAVED_FILTER_FAILED";

export const UPDATE_SAVED_FILTER_COLOR_START = "UPDATE_SAVED_FILTER_COLOR_START";
export const UPDATE_SAVED_FILTER_COLOR_SUCCESS = "UPDATE_SAVED_FILTER_COLOR_SUCCESS";
export const UPDATE_SAVED_FILTER_COLOR_FAILED = "UPDATE_SAVED_FILTER_COLOR_FAILED";

export const VERIFY_EMAIL_PUBLISHED_DOCUMENT_START = "VERIFY_EMAIL_PUBLISHED_DOCUMENT_START";
export const VERIFY_EMAIL_PUBLISHED_DOCUMENT_SUCCESS = "VERIFY_EMAIL_PUBLISHED_DOCUMENT_SUCCESS";
export const VERIFY_EMAIL_PUBLISHED_DOCUMENT_FAILED = "VERIFY_EMAIL_PUBLISHED_DOCUMENT_FAILED";

// Downgrade Subscription
export const DOWNGRADE_ACCOUNT_START = "DOWNGRADE_ACCOUNT_START";
export const DOWNGRADE_ACCOUNT_SUCCESS = "DOWNGRADE_ACCOUNT_SUCCESS";
export const DOWNGRADE_ACCOUNT_FAILED = "DOWNGRADE_ACCOUNT_FAILED";
export const FETCH_BILLING_PRODUCTS_START = "FETCH_BILLING_PRODUCTS_START";
export const FETCH_BILLING_PRODUCTS_SUCCESS = "FETCH_BILLING_PRODUCTS_SUCCESS";
export const FETCH_BILLING_PRODUCTS_FAILED = "FETCH_BILLING_PRODUCTS_FAILED";

//Manger bulk asset upload
export const MANAGER_BULK_ASSET_UPLOAD_START = "MANAGER_BULK_ASSET_UPLOAD_START";
export const MANAGER_BULK_ASSET_UPLOAD_SUCCESS = "MANAGER_BULK_ASSET_UPLOAD_SUCCESS";
export const MANAGER_BULK_ASSET_UPLOAD_FAILED = "MANAGER_BULK_ASSET_UPLOAD_FAILED";

//Company Badge Update
export const UPDATE_COMPANY_BADGE_STATUS_START = "UPDATE_COMPANY_BADGE_STATUS_START";
export const UPDATE_COMPANY_BADGE_STATUS_SUCCESS = "UPDATE_COMPANY_BADGE_STATUS_SUCCESS";
export const UPDATE_COMPANY_BADGE_STATUS_FAILED = "UPDATE_COMPANY_BADGE_STATUS_FAILED";

//Company public config
export const GET_COMPANY_PUBLIC_CONFIG_START = "GET_COMPANY_PUBLIC_CONFIG_START";
export const GET_COMPANY_PUBLIC_CONFIG_SUCCESS = "GET_COMPANY_PUBLIC_CONFIG_SUCCESS";
export const GET_COMPANY_PUBLIC_CONFIG_FAILED = "GET_COMPANY_PUBLIC_CONFIG_FAILED";

// Redeem subscription
export const REDEEM_SUBSCRIPTION_START = "REDEEM_SUBSCRIPTION_START";
export const REDEEM_SUBSCRIPTION_SUCCESS = "REDEEM_SUBSCRIPTION_SUCCESS";
export const REDEEM_SUBSCRIPTION_FAILED = "REDEEM_SUBSCRIPTION_FAILED";

//Manager Pro Once Deals
export const GET_PRO_ONCE_DEALS_START = "GET_PRO_ONCE_DEALS_START";
export const GET_PRO_ONCE_DEALS_SUCCESS = "GET_PRO_ONCE_DEALS_SUCCESS";
export const GET_PRO_ONCE_DEALS_FAILED = "GET_PRO_ONCE_DEALS_FAILED";

//Manager Pro Once Deals
export const UPDATE_PROMO_CODE_STATUS_START = "UPDATE_PROMO_CODE_STATUS_START";
export const UPDATE_PROMO_CODE_STATUS_SUCCESS = "UPDATE_PROMO_CODE_STATUS_SUCCESS";
export const UPDATE_PROMO_CODE_STATUS_FAILED = "UPDATE_PROMO_CODE_STATUS_FAILED";

//Queue in Download
export const ADD_TO_QUEUE_LIST = "ADD_TO_QUEUE_LIST";
export const UPDATE_QUEUE_LIST = "UPDATE_QUEUE_LIST"
export const QUEUE_FETCH_START = "QUEUE_FETCH_START";
export const QUEUE_FETCH_SUCCESS = "QUEUE_FETCH_SUCCESS";
export const QUEUE_FETCH_FAILED = "QUEUE_FETCH_FAILED";
export const QUEUE_READ_START = "QUEUE_READ_START";
export const QUEUE_READ_SUCCESS = "QUEUE_READ_SUCCESS";
export const QUEUE_READ_FAILED = "QUEUE_READ_FAILED";
export const READ_QUEUE_SYNC = "READ_QUEUE_SYNC";

// Company Profile
export const FETCH_COMPANY_PROFILE_LIST_START = "FETCH_COMPANY_PROFILE_LIST_START";
export const FETCH_COMPANY_PROFILE_LIST_SUCCESS = "FETCH_COMPANY_PROFILE_LIST_SUCCESS";
export const FETCH_COMPANY_PROFILE_LIST_FAILED = "FETCH_COMPANY_PROFILE_LIST_FAILED";

export const FETCH_COMPANY_PROFILE_INFO_START = "FETCH_COMPANY_PROFILE_INFO_START";
export const FETCH_COMPANY_PROFILE_INFO_SUCCESS = "FETCH_COMPANY_PROFILE_INFO_SUCCESS";
export const FETCH_COMPANY_PROFILE_INFO_FAILED = "FETCH_COMPANY_PROFILE_INFO_FAILED";
