import React, { useContext, useState, useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import useGroupUngroup from "../../../hooks/useGroupUngroup";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { isUnsuportedWidgetsInSelection } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const UnGroup = props => {
  const { ungroup: initWidgetUngroup } = useGroupUngroup();
  const { metadata } = useContext(EditorContext);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(isUnsuportedWidgetsInSelection(metadata.activeWidgetId));
  });

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["disabled"]]: isDisabled,
      })}>
      <div className={cx(style["toolset-item"], style["ungroup-wrap"])}>
        <span
          className={cx(style["toolset-action"], style["custom-tooltip"])}
          onClick={() => !isDisabled && (sessionStorage.setItem("retainRotation", true), initWidgetUngroup())}>
          <Icon icon="ui-ungroup" />
          {props.rightContext && <> Ungroup {props.rightContext && <span>Ctrl+Shift+G</span>}</>}
          {!props.rightContext && <div className={cx(style["custom-tooltip-content"], style["top"])}>Ungroup</div>}
        </span>
      </div>
    </li>
  );
};
UnGroup.propTypes = {
  rightContext: PropTypes.bool,
};

export default UnGroup;
