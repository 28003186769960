import React, { useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { Icon } from "../../ui/icon";
import {
  ANIMATION,
  CROP_TO_SHAPE_LEFT_RIGHT_PADDING,
  CROP_TO_SHAPE_PANEL_HEIGHT,
  CROP_TO_SHAPE_PANEL_WIDTH,
  CROP_TO_SHAPE_TOP_BOTTOM_PADDING,
  NE,
  NW,
  PICTURE,
  SE,
  SW,
  UPLOAD,
  VIDEO,
} from "../../../constants/editor";

import global from "../../../scss/dhp.scss";
import { getCssTransformObj } from "../../../_helpers/utils";
import UseCheckWidgetAllignment from "../../../hooks/UseCheckWidgetAllignment";
import useCheckWidgetPosition from "../../../hooks/useCheckWidgetPosition";
import { EditorPanelContext } from "../../../containers/editor/EditorPanel";

let style = Object.assign({}, global);

const CropToShapeModal = ({ ...props }) => {
  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const deleteWidgetIfOutside = useCheckWidgetPosition();

  const availableCropStyles = [
    {
      name: "Pentagon",
      id: "pentagon",
      style: "polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)",
    },
    {
      name: "Square",
      id: "square",
      style: "inset(0% 0% 0% 0%)",
    },
    {
      name: "Square Radius",
      id: "square-radius",
      style: "inset(0% 0% 0% 0% round 10px)",
    },
    {
      name: "Rectangle Horizontal",
      id: "rectangle-horizontal",
      style: "inset(20% 0% 20% 0%)",
    },
    {
      name: "Rectangle Vertical",
      id: "rectangle-vertical",
      style: "inset(0% 20% 0% 20%)",
    },
    {
      name: "Trapeziod",
      id: "trapeziod",
      style: "polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)",
    },
    {
      name: "Triangle",
      id: "triangle",
      style: "polygon(50% 0%, 0% 100%, 100% 100%)",
    },
    {
      name: "Triangle Invert",
      id: "triangle-invert",
      style: "polygon(50% 100%, 0 0, 100% 0)",
    },
    {
      name: "Parallelogram",
      id: "parallelogram",
      style: "polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)",
    },
    {
      name: "Circle",
      id: "circle",
      style: "circle(39% at 50% 50%)",
    },
    {
      name: "Star",
      id: "star",
      style: "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
    },
    {
      name: "Rhombus",
      id: "rhombus",
      style: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
    },
    {
      name: "Hexagon",
      id: "hexagon",
      style: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
    },
    {
      name: "Heptagon",
      id: "heptagon",
      style: "polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)",
    },
    {
      name: "Octagon",
      id: "octagon",
      style: "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
    },
    {
      name: "Bevel",
      id: "bevel",
      style: "polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)",
    },
    {
      name: "Rabbet",
      id: "rabbet",
      style:
        "polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%)",
    },
    {
      name: "Left Arrow",
      id: "left-arrow",
      style: "polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)",
    },
    {
      name: "Right Arrow",
      id: "right-arrow",
      style: "polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)",
    },
    {
      name: "Left Point",
      id: "left-point",
      style: "polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%)",
    },
    {
      name: "Right Point",
      id: "right-point",
      style: "polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)",
    },
    {
      name: "Left Chevron",
      id: "left-chevron",
      style: "polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)",
    },
    {
      name: "Right Chevron",
      id: "right-chevron",
      style: "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
    },
    {
      name: "Cross",
      id: "cross",
      style:
        "polygon(10% 25%, 35% 25%, 35% 0%, 65% 0%, 65% 25%, 90% 25%, 90% 50%, 65% 50%, 65% 100%, 35% 100%, 35% 50%, 10% 50%)",
    },
    {
      name: "Message",
      id: "message",
      style: "polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%)",
    },
    {
      name: "Close",
      id: "close",
      style:
        "polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%)",
    },
    {
      name: "Frame",
      id: "frame",
      style: "polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%)",
    },
  ];

  const { metadata, updateMetadata, widgets, updateWidgets, rightContextMenu, updateRightContextMenu } =
    useContext(EditorContext);
  const { togglePlayButton, updateTogglePlayButton } = useContext(EditorPanelContext);

  const [state, setState] = useState();
  const [appliedFilterValues, setAppliedFilterValues] = useState();
  const [appliedShadowValues, setAppliedShadowValues] = useState();
  const resizer = useRef(null);
  const targetWrap = useRef(null);

  let targetWidgetIndex = widgets.findIndex(widget => widget.id === metadata.activeWidgetId[0]);

  const applyEffect = (style = "") => {
    setState({ ...state, style });
  };
  let offset = [0, 0];

  const mouseDownResizer = (e, handler) => {
    resizeEvent.start(e, handler);
  };

  const mouseDownSubscribers = e => {
    e.preventDefault();
    dragEvent.start(e);
  };

  const dragEvent = {
    meta: false,
    start: e => {
      dragEvent.meta = { isDragging: true };
      offset = [resizer.current.offsetLeft - e.clientX, resizer.current.offsetTop - e.clientY];
      document.addEventListener("mousemove", dragEvent.drag);
      document.addEventListener("mouseup", dragEvent.stop);
    },
    drag: e => {
      if (dragEvent.meta.isDragging && state?.style) {
        resizer.current.style.left = e.clientX + offset[0] + "px";
        resizer.current.style.top = e.clientY + offset[1] + "px";
      }
    },
    stop: () => {
      dragEvent.meta = false;
      document.removeEventListener("mousemove", dragEvent.drag);
      document.removeEventListener("mouseup", dragEvent.stop);
    },
  };

  const resizeEvent = {
    meta: false,
    start: (e, handler) => {
      let imageRect = resizer.current.getBoundingClientRect();
      resizeEvent.meta = {
        handler,
        client: {
          minWidth: 16,
          minHeight: 16 / (state.imageOriginalWidth / state.imageOriginalHeight),
          aspectRatio: state.imageOriginalWidth / state.imageOriginalHeight,
          initial: {
            width: imageRect.width,
            height: imageRect.height,
            left: parseFloat(resizer.current.style.left),
            top: parseFloat(resizer.current.style.top),
          },
          current: {
            width: false,
            height: false,
            transform: false,
          },
        },
        mouse: {
          initial: {
            x: e.clientX,
            y: e.clientY,
          },
        },
      };
      document.addEventListener("mousemove", resizeEvent.resize);
      document.addEventListener("mouseup", resizeEvent.stop);
    },
    resize: e => {
      dragEvent.stop();
      let left, top, width, height;
      let resizeData = { ...resizeEvent.meta.client.initial };
      const dX = e.clientX - resizeEvent.meta.mouse.initial.x;
      const dY = e.clientY - resizeEvent.meta.mouse.initial.y;
      // North-West Resizer | Top-Left
      if (resizeEvent.meta.handler === NW) {
        const delta = {
          left: dX,
          top: dY,
          width: -dX,
          height: -dY,
        };
        width = resizeEvent.meta.client.initial.width + delta.width;
        height = resizeEvent.meta.client.aspectRatio
          ? width / resizeEvent.meta.client.aspectRatio
          : resizeEvent.meta.client.initial.height + delta.height;

        if (width >= resizeEvent.meta.client.minWidth) {
          if (
            (resizeEvent.meta.client.aspectRatio && height >= resizeEvent.meta.client.minHeight) ||
            !resizeEvent.meta.client.aspectRatio
          ) {
            left = resizeEvent.meta.client.initial.left + delta.left;
            resizeData = { ...resizeData, width: width, left: left };
          }
        } else {
          left =
            resizeEvent.meta.client.initial.left +
            (resizeEvent.meta.client.initial.width - resizeEvent.meta.client.minWidth);
          resizeData = { ...resizeData, width: resizeEvent.meta.client.minWidth, left: left };
        }

        if (height >= resizeEvent.meta.client.minHeight) {
          top = resizeEvent.meta.client.aspectRatio
            ? resizeEvent.meta.client.initial.top - (height - resizeEvent.meta.client.initial.height)
            : resizeEvent.meta.client.initial.top + delta.top;

          resizeData = { ...resizeData, height: height, top: top };
        } else {
          top =
            resizeEvent.meta.client.initial.top +
            (resizeEvent.meta.client.initial.height - resizeEvent.meta.client.minHeight);
          resizeData = { ...resizeData, height: resizeEvent.meta.client.minHeight, top: top };
        }
      }
      // North-East Resizer | Top-Right
      else if (resizeEvent.meta.handler === NE) {
        const delta = {
          top: dY,
          width: dX,
          height: -dY,
        };
        width = resizeEvent.meta.client.initial.width + delta.width;
        height = resizeEvent.meta.client.aspectRatio
          ? width / resizeEvent.meta.client.aspectRatio
          : resizeEvent.meta.client.initial.height + delta.height;

        if (width >= resizeEvent.meta.client.minWidth) {
          if (
            (resizeEvent.meta.client.aspectRatio && height >= resizeEvent.meta.client.minHeight) ||
            !resizeEvent.meta.client.aspectRatio
          ) {
            resizeData = { ...resizeData, width: width };
          }
        } else {
          resizeData = { ...resizeData, width: resizeEvent.meta.client.minWidth };
        }

        if (height >= resizeEvent.meta.client.minHeight) {
          top = resizeEvent.meta.client.aspectRatio
            ? resizeEvent.meta.client.initial.top - (height - resizeEvent.meta.client.initial.height)
            : resizeEvent.meta.client.initial.top + delta.top;

          resizeData = { ...resizeData, height: height, top: top };
        } else {
          top =
            resizeEvent.meta.client.initial.top +
            (resizeEvent.meta.client.initial.height - resizeEvent.meta.client.minHeight);
          resizeData = { ...resizeData, height: resizeEvent.meta.client.minHeight, top: top };
        }
      }

      // South-West Resizer | Bottom-Left
      else if (resizeEvent.meta.handler === SW) {
        const delta = {
          left: dX,
          width: -dX,
          height: dY,
        };
        width = resizeEvent.meta.client.initial.width + delta.width;
        height = resizeEvent.meta.client.aspectRatio
          ? width / resizeEvent.meta.client.aspectRatio
          : resizeEvent.meta.client.initial.height + delta.height;

        if (width >= resizeEvent.meta.client.minWidth) {
          if (
            (resizeEvent.meta.client.aspectRatio && height >= resizeEvent.meta.client.minHeight) ||
            !resizeEvent.meta.client.aspectRatio
          ) {
            left = resizeEvent.meta.client.initial.left + delta.left;
            resizeData = { ...resizeData, width: width, left: left };
          }
        } else {
          left =
            resizeEvent.meta.client.initial.left +
            (resizeEvent.meta.client.initial.width - resizeEvent.meta.client.minWidth);
          resizeData = { ...resizeData, width: resizeEvent.meta.client.minWidth, left: left };
        }

        if (height >= resizeEvent.meta.client.minHeight) {
          resizeData = { ...resizeData, height: height };
        } else {
          resizeData = { ...resizeData, height: resizeEvent.meta.client.minHeight };
        }
      }

      // South-East Resizer | Bottom-Right
      else if (resizeEvent.meta.handler === SE) {
        const delta = {
          width: dX,
          height: dY,
        };
        width = resizeEvent.meta.client.initial.width + delta.width;
        height = resizeEvent.meta.client.aspectRatio
          ? width / resizeEvent.meta.client.aspectRatio
          : resizeEvent.meta.client.initial.height + delta.height;

        if (width >= resizeEvent.meta.client.minWidth) {
          if (
            (resizeEvent.meta.client.aspectRatio && height >= resizeEvent.meta.client.minHeight) ||
            !resizeEvent.meta.client.aspectRatio
          ) {
            resizeData = { ...resizeData, width: width };
          }
        } else {
          resizeData = { ...resizeData, width: resizeEvent.meta.client.minWidth };
        }

        if (height >= resizeEvent.meta.client.minHeight) {
          resizeData = { ...resizeData, height: height };
        } else {
          resizeData = { ...resizeData, height: resizeEvent.meta.client.minHeight };
        }
      }

      // apply resize
      if (resizeData.left) {
        resizer.current.style.left = resizeData.left + "px";
      }
      if (resizeData.top) {
        resizer.current.style.top = resizeData.top + "px";
      }
      if (resizeData.width) {
        resizer.current.style.width = resizeData.width + "px";
      }
      if (resizeData.height) {
        resizer.current.style.height = resizeData.height + "px";
      }
    },
    stop: () => {
      resizeEvent.meta = false;
      document.removeEventListener("mousemove", resizeEvent.resize);
      document.removeEventListener("mouseup", resizeEvent.stop);
    },
  };

  const insertCroppedImage = () => {
    let activeWidgetId = metadata.activeWidgetId[0];
    let activeObject = document.getElementById(activeWidgetId);
    let activeInnerWidget = activeObject.querySelector(".dhp-widget-inner");
    let flippable = activeObject.querySelector(".flippable");
    let activeWidget =
      metadata.activeWidgetType[0] === VIDEO
        ? activeObject.getElementsByTagName("video")[0]
        : activeObject.getElementsByTagName("img")[0];

    //pull applied filter
    let dataFilterType = activeWidget.getAttribute("data-filter-type");
    let dataFilter = activeWidget.getAttribute("data-filter");

    //pull applied Filp
    let flipValue =
      activeObject.dataset?.cropToShape === "true" ? flippable.style.transform : activeWidget.style.transform;

    //pull applied innerScale
    const { scale } = getCssTransformObj({
      transform: activeInnerWidget.style.transform,
    });

    let initialWidgetHeight = activeInnerWidget.getAttribute("initial-data-height");
    let initialWidgetWidth = activeInnerWidget.getAttribute("initial-data-width");

    let resizerBoundry = resizer.current.getBoundingClientRect();
    let patternBoundry = document.querySelector(".crop-canvas-wrap .crop-resize-wrap").getBoundingClientRect();
    let applicableHeight = !state.style
      ? initialWidgetHeight ?? activeInnerWidget.style.height
      : `${patternBoundry.height}px`;
    let applicableWidth = !state.style
      ? initialWidgetWidth ?? activeInnerWidget.style.width
      : `${patternBoundry.width}px`;

    let targetImgHtml =
      metadata.activeWidgetType[0] === VIDEO ? document.createElement("video") : document.createElement("img");

    if (metadata.activeWidgetType[0] === VIDEO) {
      targetImgHtml.setAttribute("src", activeObject.getAttribute("data-url"));
      targetImgHtml.setAttribute("poster", activeObject.getAttribute("data-poster"));
      targetImgHtml.setAttribute("muted", true);
      targetImgHtml.setAttribute("playsinline", true);
      targetImgHtml.setAttribute("disablepictureinpicture", true);
      targetImgHtml.setAttribute("preload", "auto");
    } else targetImgHtml.setAttribute("src", state.url);

    //inject applied filter
    if (dataFilterType) targetImgHtml.setAttribute("data-filter-type", dataFilterType);
    if (dataFilter) targetImgHtml.setAttribute("data-filter", dataFilter);

    if (state.style) {
      targetImgHtml.style.cssText = `position:relative; left: ${resizerBoundry.left - patternBoundry.left}px; top: ${
        resizerBoundry.top - patternBoundry.top
      }px; width: ${resizerBoundry.width}px; height: ${resizerBoundry.height}px; filter: ${appliedFilterValues}`;
    } else {
      targetImgHtml.style.cssText = `width: 100%; height: 100%; filter: ${appliedFilterValues}`;
    }

    let targetDOM = document.querySelector("#cropTargetWrap .resize-wrap .target-image-wrap");
    targetDOM.innerHTML = targetImgHtml.outerHTML;

    let innerElementClipPath = document.querySelector("#cropTargetWrap .resize-wrap .target-image-wrap").style
      ?.clipPath;
    let innerElemetStyle =
      metadata.activeWidgetType[0] === VIDEO
        ? document.querySelector("#cropTargetWrap .resize-wrap video").style.cssText
        : document.querySelector("#cropTargetWrap .resize-wrap img").style.cssText;
    let widgetInner = activeObject.querySelector(".dhp-widget-inner");
    widgetInner.classList.add("target-image-wrap");
    if (state.style) {
      widgetInner.style.clipPath = innerElementClipPath;
      widgetInner.style.transform = `translate(0px, 0px) scale(${scale.x}, ${scale.y})`;
      widgetInner.querySelector(metadata.activeWidgetType[0] === VIDEO ? "video" : "img").style.cssText =
        innerElemetStyle;
      // set flippable div height & width
      flippable.style.height = `${parseFloat(applicableHeight) * scale.y}px`;
      flippable.style.width = `${parseFloat(applicableWidth) * scale.x}px`;
    }
    if (appliedShadowValues) {
      flippable.style.filter = appliedShadowValues;
    }
    let curTargetWrap = activeObject.querySelector(".target-image-wrap");
    if (!initialWidgetHeight && !initialWidgetWidth) {
      curTargetWrap.setAttribute("initial-data-width", widgetInner.style.width);
      curTargetWrap.setAttribute("initial-data-height", widgetInner.style.height);
    }
    if (state.style) {
      curTargetWrap.style.height = applicableHeight;
      curTargetWrap.style.width = applicableWidth;
      activeObject.style.height = `${parseFloat(applicableHeight) * scale.y}px`;
      activeObject.style.width = `${parseFloat(applicableWidth) * scale.x}px`;
      curTargetWrap.setAttribute("data-clip-path", state.style);

      // remove border from cropped image
      if (flippable?.style?.borderStyle && flippable?.style.borderStyle !== "none") {
        flippable.style.borderStyle = "none";
        flippable.style.borderRadius = "0px";
        widgetInner.style.left = "";
        widgetInner.style.top = "";
        widgetInner.style.position = "";
      }

      if (flipValue) {
        flippable.style.transform = flipValue;
        activeInnerWidget.childNodes[0].style.transform = "";
      }
    } else {
      curTargetWrap?.removeAttribute("data-clip-path");
      curTargetWrap.style.clipPath = "";
      curTargetWrap.innerHTML = targetImgHtml.outerHTML;
      curTargetWrap.style.transform = "translate(0px, 0px) scale(1, 1)";
      curTargetWrap.style.height = initialWidgetHeight ?? activeInnerWidget.style.height;
      curTargetWrap.style.width = initialWidgetWidth ?? activeInnerWidget.style.width;
      activeObject.style.height = initialWidgetHeight ?? activeInnerWidget.style.height;
      activeObject.style.width = initialWidgetWidth ?? activeInnerWidget.style.width;
      flippable.style.overflow = "hidden";
      // set flippable div height & width
      flippable.style.height = initialWidgetHeight ?? activeInnerWidget.style.height;
      flippable.style.width = initialWidgetWidth ?? activeInnerWidget.style.width;
      if (flipValue) {
        activeInnerWidget.childNodes[0].style.transform = flipValue;
        flippable.style.transform = "";
      }
    }
    curTargetWrap?.classList?.add("dhp-widget-inner");
    curTargetWrap?.setAttribute("data-width", resizerBoundry.width);
    curTargetWrap?.setAttribute("data-height", resizerBoundry.height);
    curTargetWrap?.setAttribute("data-left", resizer.current.style.left);
    curTargetWrap?.setAttribute("data-top", resizer.current.style.top);
    curTargetWrap?.removeAttribute("data-crop");
    curTargetWrap?.removeAttribute("data-scale");
    activeObject.setAttribute("data-version", "2.0");

    if (metadata.activeWidgetType[0] === VIDEO) {
      activeObject.getElementsByTagName("video")[0].style.objectFit = "cover";
    }

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if ([PICTURE, UPLOAD, VIDEO, ANIMATION].includes(metadata.activeWidgetType[0])) {
      let innerWidget = document.querySelector(".dhp-widget-inner");
      if (isSafari) innerWidget.style.willChange = "filter";
      else innerWidget.style.willChange = "";
    }

    const isDletable = deleteWidgetIfOutside(activeWidgetId);

    // Check widget position during crop to shape, if it is outside canvas area delete the widget
    if (isDletable) {
      updateMetadata({ ...metadata, activeWidgetId: false, activeWidgetType: false });
      let newArray = Object.assign([...widgets]).filter(wz => wz.id !== activeWidgetId);
      updateWidgets(newArray);
    } else {
      const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(activeObject.style.height);

      let newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          data: {
            ...widgets[targetWidgetIndex].data,
            "data-crop-to-shape": state.style ? true : false,
            "data-x-allignment": x_al,
            "data-y-allignment": y_al,
            "data-version": "2.0",
          },
          style: {
            ...widgets[targetWidgetIndex].style,
            height: parseFloat(activeObject.style.height),
            width: parseFloat(activeObject.style.width),
          },
          innerHTML: document.getElementById(activeWidgetId).innerHTML,
        },
      });
      updateWidgets(newArray);
    }
    props.setShowModal(!props.showModal);

    // Close right context menubar if open
    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
  };

  const closeCropModal = () => {
    props.setShowModal(!props.showModal);

    // Close right context menubar if open
    if (rightContextMenu.enable)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
  };

  useEffect(() => {
    if (metadata.activeWidgetId) {
      let curDOMElement = document.getElementById(`${metadata.activeWidgetId[0]}`);

      let curDOMElementBoundry = curDOMElement.getBoundingClientRect();
      let imageFilterVal =
        metadata.activeWidgetType[0] === VIDEO
          ? curDOMElement.getElementsByTagName("video")[0].style.filter
          : curDOMElement.getElementsByTagName("img")[0].style.filter;

      let imageShadowVal = curDOMElement.querySelector(".flippable").style?.filter ?? "none";
      let filters = [];
      filters.push(imageFilterVal);
      setAppliedShadowValues(imageShadowVal);
      setAppliedFilterValues(imageFilterVal);
      let curDataSource = curDOMElement.getAttribute("data-source");
      let cropStyle = curDOMElement.querySelector(".dhp-widget-inner").getAttribute("data-clip-path") ?? false;
      let curInnerWidget =
        ["STOCK", "BRAND", "UPLOADVIDEO"].includes(curDataSource) && metadata.activeWidgetType[0] === VIDEO
          ? curDOMElement.getElementsByTagName("video")[0]
          : curDOMElement.getElementsByTagName("img")[0];

      let url =
        ["STOCK", "BRAND", "UPLOADVIDEO"].includes(curDataSource) && metadata.activeWidgetType[0] === VIDEO
          ? curDOMElement.getAttribute("data-poster")
          : curInnerWidget.src;

      const imageOriginalHeight =
        metadata.activeWidgetType[0] === VIDEO
          ? curInnerWidget.videoHeight > 0
            ? curInnerWidget.videoHeight
            : curDOMElementBoundry.height
          : curInnerWidget.naturalHeight;
      const imageOriginalWidth =
        metadata.activeWidgetType[0] === VIDEO
          ? curInnerWidget.videoWidth > 0
            ? curInnerWidget.videoWidth
            : curDOMElementBoundry.width
          : curInnerWidget.naturalWidth;

      resizer.current.getElementsByTagName("img")[0].style.filter = filters[0];

      if (
        metadata.activeWidgetType[0] === VIDEO &&
        document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-playing") === "true"
      )
        updateTogglePlayButton(!togglePlayButton);

      let targetImageWrap = curDOMElement.querySelector(".target-image-wrap");
      if (!cropStyle || curDOMElement?.dataset?.version !== "2.0") {
        const applicableMaxAspect = Math.max(
          CROP_TO_SHAPE_PANEL_WIDTH / imageOriginalWidth,
          CROP_TO_SHAPE_PANEL_HEIGHT / imageOriginalHeight
        );
        const applicableMinAspect = Math.min(
          CROP_TO_SHAPE_PANEL_WIDTH / imageOriginalWidth,
          CROP_TO_SHAPE_PANEL_HEIGHT / imageOriginalHeight
        );

        let resizedHeight, resizedWidth;
        if (
          targetImageWrap?.getAttribute("data-height") &&
          targetImageWrap?.getAttribute("data-width") &&
          curDOMElement.dataset.version === "1.0"
        ) {
          resizedHeight = targetImageWrap.getAttribute("data-height");
          resizedWidth = targetImageWrap.getAttribute("data-width");
        } else {
          if (
            applicableMinAspect * imageOriginalHeight < CROP_TO_SHAPE_PANEL_HEIGHT ||
            applicableMinAspect * imageOriginalWidth < CROP_TO_SHAPE_PANEL_WIDTH
          ) {
            resizedHeight = imageOriginalHeight * applicableMaxAspect;
            resizedWidth = imageOriginalWidth * applicableMaxAspect;
          } else {
            resizedHeight = imageOriginalHeight * applicableMinAspect;
            resizedWidth = imageOriginalWidth * applicableMinAspect;
          }
        }
        resizer.current.style.height = `${resizedHeight}px`;
        resizer.current.style.width = `${resizedWidth}px`;
        // Auto align image center in crop to shape modal
        resizer.current.style.left = `${
          (CROP_TO_SHAPE_PANEL_WIDTH - resizedWidth + CROP_TO_SHAPE_LEFT_RIGHT_PADDING) / 2
        }px`;
        resizer.current.style.top = `${
          (CROP_TO_SHAPE_PANEL_HEIGHT - resizedHeight + CROP_TO_SHAPE_TOP_BOTTOM_PADDING) / 2
        }px`;
        cropStyle = cropStyle ? cropStyle : availableCropStyles[0].style;
      } else {
        resizer.current.style.width = `${targetImageWrap.getAttribute("data-width")}px`;
        resizer.current.style.height = `${targetImageWrap.getAttribute("data-height")}px`;
        resizer.current.style.left = targetImageWrap.getAttribute("data-left");
        resizer.current.style.top = targetImageWrap.getAttribute("data-top");

        // scroll to selectd shape
        setTimeout(() => {
          let cropList = document.querySelector(".crop-style-list");
          let activeStyle = document.querySelector(".crop-shape-cont.active");
          if (activeStyle && cropList) {
            cropList.scrollTop = activeStyle.offsetTop - 20;
          }
        }, 300);
      }
      let imageDia = resizer.current.getBoundingClientRect();
      setState({
        ...state,
        url,
        style: cropStyle,
        imageDia,
        imageOriginalHeight,
        imageOriginalWidth,
      });
    }
  }, []);

  return (
    <>
      <div className={style["modal-header"]}>
        <h4 className={cx(style["fw-7"], style["mb-0"])}>Crop to Shape</h4>
        <span className={cx(style["cross-modal"], style["rounded"])} onClick={closeCropModal}>
          <Icon icon="ui-close" />
        </span>
      </div>
      <div className={cx(style["row"], style["m-0"])}>
        <div className={cx(style["crop-left-bg"], style["col"])}>
          <div className={cx(style["crop-style-list"], style["customScroll"], style["scroll-Y"])}>
            <div
              className={cx(style["crop-shape-cont"], { [style["active"]]: !state?.style })}
              onClick={() => applyEffect()}>
              <span className={style["custom-tooltip"]}>
                <Icon icon="ui-no-shape" />
                <div className={style["custom-tooltip-content"]}>No Shape</div>
              </span>
            </div>
            {availableCropStyles.map(cs => (
              <div
                className={cx(style["crop-shape-cont"], { [style["active"]]: state?.style === cs.style })}
                key={cs.id}
                data-style={cs.id}
                data-name={cs.style}
                onClick={() => applyEffect(cs.style)}>
                <div style={{ WebkitClipPath: cs.style, clipPath: cs.style }} className={style["shape"]}></div>
              </div>
            ))}
          </div>
        </div>

        <div className={cx(style["crop-right-bg"], style["col"], style["pt-3"])}>
          <div className={style["crop-canvas-wrap"]}>
            <div className={style["crop-img-container"]}>
              <div className={style["crop-image-wrap"]}>
                <div
                  className={cx(style["resizable"])}
                  ref={resizer}
                  onMouseDown={e => mouseDownSubscribers(e)}
                  onDragStart={e => e.preventDefault()}
                  style={{ cursor: !state?.style ? "default" : "move" }}>
                  <img src={state?.url} />
                  <div className={cx(style["resizers"], { [style["d-none"]]: !state?.style })}>
                    <div
                      className={cx(style["resizer"], style["top-left"])}
                      onMouseDown={e => mouseDownResizer(e, NW)}
                      style={{
                        opacity: 1,
                        cursor: 'url("/assets/images/11bfc294e1af89f94f5aecfbe9cf829b.png") 15 15, auto',
                      }}></div>
                    <div
                      className={cx(style["resizer"], style["top-right"])}
                      onMouseDown={e => mouseDownResizer(e, NE)}
                      style={{
                        opacity: 1,
                        cursor: 'url("/assets/images/a8b59cdc7030ef3b36f11c601b19f017.png") 15 15, auto',
                      }}></div>
                    <div
                      className={cx(style["resizer"], style["bottom-left"])}
                      onMouseDown={e => mouseDownResizer(e, SW)}
                      style={{
                        opacity: 1,
                        cursor: 'url("/assets/images/a8b59cdc7030ef3b36f11c601b19f017.png") 15 15, auto',
                      }}></div>
                    <div
                      className={cx(style["resizer"], style["bottom-right"])}
                      onMouseDown={e => mouseDownResizer(e, SE)}
                      style={{
                        opacity: 1,
                        cursor: 'url("/assets/images/11bfc294e1af89f94f5aecfbe9cf829b.png") 15 15, auto',
                      }}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx(style["crop-resize-wrap"], { [style["d-none"]]: !state?.style })} id="cropTargetWrap">
              {/* "resize-wrap" is a static class */}
              <div className="resize-wrap">
                <div className={style["target-image-wrap"]} style={{ clipPath: state?.style }} ref={targetWrap}></div>
              </div>
            </div>
          </div>
          <div className={cx(style["modal-footer"], style["justify-content-start"], style["border-0"])}>
            <Button type="button" color="primary" className={style["m-0"]} onClick={insertCroppedImage}>
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

CropToShapeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default CropToShapeModal;
