import { USER_LOGOUT, COMPANY_SWITCH_SUCCESS } from "../actions/actionTypes";
import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";

import { authReducer } from "./authReducer";
import { resourcesReducer } from "./resourcesReducer";
import { companyReducer } from "./companyReducer";
import { searchReducer } from "./searchReducer";
import { trashReducer } from "./trashReducer";
import { managerReducer } from "./managerReducer";
import { documentReducer } from "./documentReducer";
import { templateReducer } from "./templateReducer";
import { editorReducer } from "./editorReducer";
import { auditLogReducer } from "./auditLogReducer";
import { subscriptionReducer } from "./subscriptionReducer";
import { aiReducer } from "./aiReducer";
import { downloadQueueReducer } from "./downloadQueueReducer";

const appReducer = combineReducers({
  loadingBar: loadingBarReducer,
  auth: authReducer,
  resources: resourcesReducer,
  company: companyReducer,
  search: searchReducer,
  trash: trashReducer,
  manager: managerReducer,
  document: documentReducer,
  template: templateReducer,
  editor: editorReducer,
  auditLog: auditLogReducer,
  subscription: subscriptionReducer,
  ai: aiReducer,
  downloadQueue: downloadQueueReducer,
});

const rootReducer = (state, action) => {
  let appState;

  switch (action.type) {
    case USER_LOGOUT:
      appState = {
        company: null,
      };
      break;

    case COMPANY_SWITCH_SUCCESS:
      appState = {
        auth: state.auth,
        resources: state.resources,
        template: { ...state.template, templateSearch: null },
        document: state.document,
        editor: state.editor,
      };
      break;

    default:
      appState = state;
      break;
  }

  return appReducer(appState, action);
};

export default rootReducer;
