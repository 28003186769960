import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import EditorTopbar from "./EditorTopbar";
import EditorCanvasPanel from "./EditorCanvasPanel";
import EditorMapInfoTooltip from "./EditorMapInfoTooltip";
import { EditorContext } from "./EditorLayout";
import RightContextMenubar from "./RightContextMenubar";

export const EditorPanelContext = React.createContext({});

const EditorPanel = props => {
  let { rightContextMenu } = useContext(EditorContext);

  const editorKeyboardEvent = {
    cut: false,
    copy: false,
    paste: false,
    clone: false,
  };
  const editorCopyStyle = {
    enable: false,
    widgetType: false,
    style: {},
    cssText: false,
    opacity: false,
    dataset: {},
    lineThicknessFlag: false,
    changeList: false,
  };
  const collageEventData = {
    isActive: false,
    widgetId: false,
    prevState: false,
    curState: false,
  };

  const documentDetails = useSelector(state => state?.document?.documentDetails?.data);

  const [keyBoardEvent, setKeyBoardEvent] = useState(editorKeyboardEvent);
  const [doubleClickActive, setDoubleClickActive] = useState(false);
  const [copyStyle, setCopyStyle] = useState(editorCopyStyle);
  const [togglePlayButton, setTogglePlayButton] = useState();
  const [selectionRange, setSelectionRange] = useState(false);
  const [textSelectedAreaColor, setTextSelectedAreaColor] = useState(false);
  const [doubleClickSelctedareaColor, setDoubleClickSelctedareaColor] = useState();
  const [previousSelection, setPreviousSelection] = useState();
  const [collageData, setCollageData] = useState(collageEventData);
  const [documentName, setDocumentName] = useState();
  const [isDocumentSave, setIsDocumentSave] = useState(false);

  useEffect(() => {
    if (documentDetails?.document_name) setDocumentName(documentDetails?.document_name);
  }, [documentDetails]);

  return (
    <EditorPanelContext.Provider
      value={{
        keyBoardEvent,
        updateKeyBoardEvent: data => {
          if (!_.isEqual(keyBoardEvent, data)) setKeyBoardEvent(data);
        },
        doubleClickActive,
        updateDoubleClickActive: data => {
          if (!_.isEqual(doubleClickActive, data)) setDoubleClickActive(data);
        },
        copyStyle,
        updateCopyStyle: data => {
          if (!_.isEqual(copyStyle, data)) setCopyStyle(data);
        },
        togglePlayButton,
        updateTogglePlayButton: data => setTogglePlayButton(data),
        
        selectionRange,
        setSelectionRange,
        textSelectedAreaColor,
        setTextSelectedAreaColor,
        doubleClickSelctedareaColor,
        setDoubleClickSelctedareaColor,
        previousSelection,
        setPreviousSelection,
        collageData,
        updateCollageData: data => {
          if (!_.isEqual(collageData, data)) setCollageData(data);
        },
        documentName,
        updateDocumentName: data => {
          if (documentName !== data) setDocumentName(data);
        },
        isDocumentSave,
        setIsDocumentSave: data => {
          if (isDocumentSave !== data) setIsDocumentSave(data);
        },
      }}>
      <EditorTopbar toggle={props.toggle} />

      <EditorCanvasPanel toggle={props.toggle} />
      {/* Display map info tooltip on mouseMove */}
      <EditorMapInfoTooltip />

      {/* display when Right Click on editor */}
      {rightContextMenu.enable && <RightContextMenubar />}
    </EditorPanelContext.Provider>
  );
};

//Props type validation
EditorPanel.propTypes = {
  pageSorterList: PropTypes.bool,
  activePageListHover: PropTypes.bool,
  toggle: PropTypes.func,
};

export default EditorPanel;
