import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { TABLE, TEXT } from "../constants/editor";
import { getCssTransformObj } from "../_helpers/utils";
import { EditorSidebarContext } from "../containers/editor/EditorSidebar";

const useBrandFont = () => {
  let { metadata, pageNodes, widgets, updateWidgets, updateMetadata } = useContext(EditorContext);
  let { activeColorThemePageIdx } = useContext(EditorSidebarContext);

  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const handleFontStylesAccordingFontFamily = (fontFamily, currentelem, assetType) => {
    let index = fonts.findIndex(font => font.name.toLowerCase() === fontFamily.replace(/["]+/g, "").toLowerCase());

    if (parseInt(currentelem.style.fontWeight) !== 700) {
      currentelem.style.fontWeight = parseInt(fonts[index].normal_weight);
    }

    if (assetType === TEXT) {
      currentelem.querySelectorAll("*").forEach(node => {
        if (node.style?.fontWeight && parseInt(node.style?.fontWeight) !== 700) {
          node.style.fontWeight = parseInt(fonts[index].normal_weight);
        }
      });
    }

    if (fonts[index].bold_weight === "") {
      currentelem.style.fontWeight = parseInt(fonts[index].normal_weight);

      // remove font weight from all child node if font family dosen't support it
      if (assetType === TEXT) {
        currentelem.querySelectorAll("*").forEach(node => {
          if (node.style?.fontWeight) {
            node.style.fontWeight = parseInt(fonts[index].normal_weight);
          }
        });
      }
    }
    if (
      ((parseInt(currentelem.style.fontWeight) === 700 && fonts[index].bold_italic_weight === "") ||
        (parseInt(currentelem.style.fontWeight) === parseInt(fonts[index].normal_weight) &&
          fonts[index].normal_italic_weight === "")) &&
      fonts[index]?.source !== "Custom"
    ) {
      currentelem.style.fontStyle = "normal";

      // remove fontStyle from all child node if font family dosen't support it
      if (assetType === TEXT) {
        currentelem.querySelectorAll("*").forEach(node => {
          if (node.style?.fontStyle) {
            node.style.fontStyle = "normal";
          }
        });
      }
    }

    if (fonts[index]?.source === "Custom" && fonts[index]?.style) {
      currentelem.style.fontStyle = fonts[index].style;
      currentelem.querySelectorAll("*").forEach(node => {
        if (node.style?.fontStyle) {
          node.style.fontStyle = fonts[index].style;
        }
      });
    }
  };

  const changeFontTheme = (brandId, themeDetails) => {
    let currentBlockWidgetIndex = [];
    let currentBlockWidgetList = [];
    let targetPageIds = [];
    let newPageArray = Object.assign([...pageNodes]);
    let newArray = Object.assign([...widgets]);

    // set target Page
    if (themeDetails) {
      targetPageIds.push(metadata.activePageId);
    } else {
      themeDetails = pageNodes[activeColorThemePageIdx].themearray; // fetch applied color array from active page
      pageNodes.forEach(pageNode => {
        targetPageIds.push(pageNode.pageId);
      });
    }

    // Loop through target Page or all pages
    targetPageIds.forEach(targetPageId => {
      let pageIdx = pageNodes.findIndex(pageNode => pageNode.pageId === targetPageId);

      // create new pagenode array for context
      newPageArray = Object.assign([...newPageArray], {
        [pageIdx]: {
          ...pageNodes[pageIdx],
          themeId: brandId ? brandId : pageNodes[activeColorThemePageIdx].themeId,
          themearray: themeDetails,
        },
      });

      document.querySelectorAll(`#${targetPageId} .dhp-page-widget`).forEach(element => {
        let assetType = element.getAttribute("data-asset-type");

        // aplly font family all table in doc
        if (assetType === TABLE) {
          element.querySelectorAll(`th`).forEach(tableHead => {
            let fontFam;
            let tableHeadFontSize = parseFloat(tableHead.childNodes[0].style.fontSize);

            if (tableHeadFontSize < 24) {
              fontFam = themeDetails.body_text ? themeDetails.body_text.font_family : "Open Sans";
            } else if (tableHeadFontSize > 23 && tableHeadFontSize < 32) {
              fontFam = themeDetails.subheading ? themeDetails.subheading.font_family : "Open Sans";
            } else if (tableHeadFontSize >= 32) {
              fontFam = themeDetails.heading ? themeDetails.heading.font_family : "Open Sans";
            }
            tableHead.childNodes[0].style.fontFamily = fontFam;
            handleFontStylesAccordingFontFamily(fontFam, tableHead.childNodes[0], assetType);
          });

          element.querySelectorAll(`td`).forEach(tablebody => {
            let fontFam;
            let tableBodyFontSize = parseFloat(tablebody.childNodes[0].style.fontSize);

            if (tableBodyFontSize < 24) {
              fontFam = themeDetails.body_text ? themeDetails.body_text.font_family : "Open Sans";
            } else if (tableBodyFontSize > 23 && tableBodyFontSize < 32) {
              fontFam = themeDetails.subheading ? themeDetails.subheading.font_family : "Open Sans";
            } else if (tableBodyFontSize >= 32) {
              fontFam = themeDetails.heading ? themeDetails.heading.font_family : "Open Sans";
            }

            tablebody.childNodes[0].style.fontFamily = fontFam;
            handleFontStylesAccordingFontFamily(fontFam, tablebody.childNodes[0], assetType);
          });
        }

        // aplly font family all text in doc
        if (assetType === TEXT) {
          let fontFam;
          let targetElemStyle = element.querySelector(".dhp-widget-inner").style;

          if (parseFloat(targetElemStyle.fontSize) < 24) {
            fontFam = themeDetails.body_text ? themeDetails.body_text.font_family : "Open Sans";
          } else if (parseFloat(targetElemStyle.fontSize) > 23 && parseFloat(targetElemStyle.fontSize) < 32) {
            fontFam = themeDetails.subheading ? themeDetails.subheading.font_family : "Open Sans";
          } else if (parseFloat(targetElemStyle.fontSize) >= 32) {
            fontFam = themeDetails.heading ? themeDetails.heading.font_family : "Open Sans";
          }

          targetElemStyle.fontFamily = fontFam;
          handleFontStylesAccordingFontFamily(fontFam, element.querySelector(".dhp-widget-inner"), assetType);
        }
      });
    });

    // Collect widget list and index which are in target pages
    if (brandId) {
      currentBlockWidgetList = widgets.filter(widget => widget.pageId === metadata.activePageId);
      currentBlockWidgetList.forEach(element => {
        currentBlockWidgetIndex.push(widgets.findIndex(widget => widget.id === element.id));
      });
    } else {
      currentBlockWidgetList = [...widgets];
      currentBlockWidgetList.forEach(element => {
        currentBlockWidgetIndex.push(widgets.findIndex(widget => widget.id === element.id));
      });
    }

    // create new widgets array for context
    currentBlockWidgetIndex.map(targetWidgetIndex => {
      let isGroupWidget = document.getElementById(widgets[targetWidgetIndex].id)?.closest(".dhp-page-group");
      let currentelem = document.querySelector("#" + widgets[targetWidgetIndex].id + " .dhp-widget-inner");
      let scaleFactor;

      if (!isGroupWidget) {
        if (widgets[targetWidgetIndex].data["data-asset-type"] === TABLE) {
          let { scale } = getCssTransformObj({
            transform: currentelem.style.transform,
          });
          scaleFactor = parseFloat(scale.x);
          currentelem.style.width = `${currentelem.querySelector("table").offsetWidth}px`;
          currentelem.style.height = `${currentelem.querySelector("table").offsetHeight}px`;
        }

        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...newArray[targetWidgetIndex],
            style: {
              ...newArray[targetWidgetIndex].style,
              height:
                newArray[targetWidgetIndex].data["data-asset-type"] === TEXT
                  ? `${currentelem.offsetHeight}px`
                  : newArray[targetWidgetIndex].data["data-asset-type"] === TABLE
                  ? `${
                      document.querySelector("#" + widgets[targetWidgetIndex].id + " .dhp-widget-inner table")
                        .offsetHeight * scaleFactor
                    }px`
                  : newArray[targetWidgetIndex].style.height,
              width:
                newArray[targetWidgetIndex].data["data-asset-type"] === TABLE
                  ? `${
                      document.querySelector("#" + widgets[targetWidgetIndex].id + " .dhp-widget-inner table")
                        .offsetWidth * scaleFactor
                    }px`
                  : newArray[targetWidgetIndex].style.width,
            },
            innerHTML: document.getElementById(widgets[targetWidgetIndex].id).innerHTML,
          },
        });
      } else {
        newArray = Object.assign([...newArray], {
          [targetWidgetIndex]: {
            ...newArray[targetWidgetIndex],
            innerHTML: document.getElementById(widgets[targetWidgetIndex].id).innerHTML,
          },
        });
      }
    });

    //update final context
    updateWidgets(newArray, newPageArray);

    // update metadata
    updateMetadata({
      ...metadata,
      activeAudioId: false,
      activeWidgetId: false,
      activeWidgetType: false,
      activeOutSideCanvasArea: false,
      disableAutoScroll: false,
    });
  };

  return { changeFontTheme };
};

export default useBrandFont;
