import {
    ADD_TO_QUEUE_LIST,
    QUEUE_FETCH_FAILED,
    QUEUE_FETCH_START,
    QUEUE_FETCH_SUCCESS,
    QUEUE_READ_FAILED,
    QUEUE_READ_START,
    QUEUE_READ_SUCCESS,
    READ_QUEUE_SYNC,
    UPDATE_QUEUE_LIST
} from "./actionTypes";
import { documentRequestManager as API } from "../../_helpers/requestManager";

const fetchQueueStart = () => {
    return { type: QUEUE_FETCH_START };
};
const fetchQueueSuccess = result => {
    return { type: QUEUE_FETCH_SUCCESS, result };
};
const fetchQueueFailed = error => {
    return { type: QUEUE_FETCH_FAILED, error };
};

const readQueueStart = () => {
    return { type: QUEUE_READ_START };
};
const readQueueSuccess = (result, eventId) => {
    return { type: QUEUE_READ_SUCCESS, result, eventId };
};
const readQueueFailed = error => {
    return { type: QUEUE_READ_FAILED, error };
};

export const fetchQueue = () => {
    let endPoint = `download/list`;

    return dispatch => {
        dispatch(fetchQueueStart());

        return API.get(endPoint)
            .then(r => dispatch(fetchQueueSuccess(r.data.data)))
            .catch(e => dispatch(fetchQueueFailed(e)));
    };
};

export const readQueue = eventId => {
    let endPoint = `/download/mark/read`;

    return dispatch => {
        dispatch(readQueueStart());
        return API.put(endPoint, eventId)
            .then(r => dispatch(readQueueSuccess(r, eventId)))
            .catch(e => dispatch(readQueueFailed(e)));
    };
};

export const addToQueue = payload => {
    return dispatch => {
        dispatch({ type: ADD_TO_QUEUE_LIST, payload });
    };
};

// sync to collaborative user
export const syncReadQueue = payload => {
    return dispatch => {
        dispatch({ type: READ_QUEUE_SYNC, payload });
    };
};

export const updateQueue = payload => {
    return dispatch => {
        dispatch({ type: UPDATE_QUEUE_LIST, payload });
    };
}