import { useContext, useEffect } from "react";
import useSelectable from "./useSelectable";
import useWidgetHandler from "./useWidgetHandler";
import { EditorContext } from "../containers/editor/EditorLayout";
import { EditorPanelContext } from "../containers/editor/EditorPanel";
import {
  getCssTransformObj,
  getStringToValidJSON,
  getUnScaledValue,
  getWidgetAndParentsDomReference,
  getZoomedValue,
} from "../_helpers/utils";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import useCheckWidgetPosition from "./useCheckWidgetPosition";
import useDeleteWidget from "./useDeleteWidget";
import { NW, NE, SW, SE } from "../constants/editor";

const useCollage = ({ restrictHooks } = {}) => {
  const { start: initSelectable } = !restrictHooks && useSelectable();
  const {
    hide: hide_WH,
    groupSelectionShow: show_GWH,
    toggleEventHandlers: toggleWidgetEventHandlers,
    toggleEventCursorToDefault: toggleWidgetEventCursorToDefault,
  } = useWidgetHandler();
  const { widgets, updateWidgets, dimension, metadata, updateMetadata } = useContext(EditorContext);
  const { collageData, updateCollageData } = useContext(EditorPanelContext);
  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const deleteWidgetIfOutside = useCheckWidgetPosition();
  const setDeleteWidget = useDeleteWidget();

  const collage = {
    misc: {
      generateCropFrame: ({ frameWidth, frameHeight, pictureHeight }) => {
        const patternLength = Math.max(frameWidth, frameHeight) / 9;
        const cropFrame = document.createElement("div");
        cropFrame.classList.add("crop-frame");
        cropFrame.style.top = `-${pictureHeight}px`;
        cropFrame.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
            <defs>
              <pattern id="crop_frame_diagonal_hatching" patternUnits="userSpaceOnUse" width="${patternLength}" height="${patternLength}" patternTransform="rotate(45)">
                <line x1="0" y="0" x2="0" y2="${patternLength}" stroke="#FFFFFF" stroke-width="2" />
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#crop_frame_diagonal_hatching)" opacity="1" />
            <rect width="100%" height="100%" fill="#ffffff" opacity="0.3" />
          </svg>
        `;

        return cropFrame;
      },

      deactivateActiveCollageItem: ({ widgetId }) => {
        const { activeCollageItem } = collage.common.getCollageNodeAndDataRef({ widgetId });
        const { mediaGrandParent, cropFrame } = collage.common.getCollageNodeAndDataRef({
          collageChild: activeCollageItem,
        });
        activeCollageItem.classList.remove("active");
        mediaGrandParent.style.overflow = "hidden";
        cropFrame.remove();
      },
    },

    common: {
      getHandlerNodeAndDataRef: () => {
        const handler = document.getElementById("dhp-widget-handler");
        const cropPreview = handler.querySelector(".crop-preview");
        const cropThumb = handler.querySelector(".crop-preview .thumb");
        const { transform = "" } = handler.style;
        const { translate: { x = "", y = "" } = {} } = getCssTransformObj({ transform });

        return {
          handler,
          cropPreview,
          cropThumb,
          handlerProps: {
            handlerTransX: parseFloat(x),
            handlerTransY: parseFloat(y),
            handlerTransform: transform,
          },
        };
      },

      getCollageNodeAndDataRef: ({ widgetId, collageChild }) => {
        if (widgetId) {
          const widget = document.getElementById(widgetId);
          const collageContainer = widget.querySelector(".collage-container");

          return {
            collage: widget.querySelector(".collage"),
            mainContent: widget.querySelector(".main-content"),
            collageContainer,
            collageItems: widget.querySelectorAll(".collage-item"),
            activeCollageItem: widget.querySelector(".collage-item.active"),
            gridGap: parseInt(collageContainer.style.gap),
            widgetProps: {
              widgetWidth: parseFloat(widget.style.width),
              widgetHeight: parseFloat(widget.style.height),
            },
          };
        }

        if (collageChild) {
          const mediaGrandParent = collageChild.querySelector(".media-grand-parent");
          const mediaParent = collageChild.querySelector(".media-parent");
          const mediaNode = collageChild.querySelector(".media-node");
          const cropFrame = collageChild.querySelector(".crop-frame");
          const { width: mgpW = 1, height: mgpH = 1 } = mediaGrandParent.style;
          const { width: mpW = 1, height: mpH = 1, transform: mpTransform = "" } = mediaParent.style;
          const { translate: { x: mpTransX = "", y: mpTransY = "" } = {} } = getCssTransformObj({
            transform: mpTransform,
          });

          return {
            mediaGrandParent,
            mediaParent,
            // mediaNode,
            cropFrame,
            mediaProps: {
              frameWidth: parseFloat(mgpW),
              frameHeight: parseFloat(mgpH),
              pictureWidth: parseFloat(mpW),
              pictureHeight: parseFloat(mpH),
              pictureTransX: parseFloat(mpTransX),
              pictureTransY: parseFloat(mpTransY),
              pictureTransform: mpTransform,
              pictureAr: parseFloat(mpW) / parseFloat(mpH),
              cropData: JSON.parse(mediaParent.getAttribute("data-crop")),
              previewThumb: mediaNode.poster ?? mediaNode.src,
              previewStyle: `filter: ${collageChild.closest(".collage").style.filter};`,
            },
          };
        }
      },

      sanitizeInnerHtml: ({ html }) => {
        const sandbox = document.createElement("div");
        sandbox.innerHTML = html;
        const activeCollageItem = sandbox.querySelector(".collage-item.active");

        if (activeCollageItem) {
          const mediaGrandParent = activeCollageItem.querySelector(".media-grand-parent");
          const cropFrame = activeCollageItem.querySelector(".crop-frame");

          activeCollageItem.classList.remove("active");
          mediaGrandParent.style.overflow = "hidden";
          cropFrame.remove();
        }

        return sandbox.innerHTML;
      },

      updateReactDom: ({ actionName, widgetId, newCss = {}, newData = {}, innerHtml = null }) => {
        if (actionName === "CANCEL") {
          // update html DOM - reset html to previous state on cancel event
          document.getElementById(widgetId).innerHTML = innerHtml;
        } else {
          // update react DOM - modify shadow DOM on save event
          updateWidgets(
            widgets.map(widget => {
              if (widget.id === widgetId) {
                return {
                  ...widget,
                  style: {
                    ...widget.style,
                    ...newCss,
                  },
                  data: {
                    ...widget.data,
                    ...newData,
                  },
                  // innerHTML: innerHtml || document.getElementById(widgetId).innerHTML,
                  innerHTML: document.getElementById(widgetId).innerHTML,
                };
              } else {
                return widget;
              }
            })
          );
        }
      },
    },

    event: {
      fetch: () => {
        const { isActive = false, widgetId = false } = getStringToValidJSON(
          localStorage.getItem("collage.event.isActive")
        ) ?? { isActive: false, widgetId: false };

        return { isActive, widgetId };
      },

      activate: ({ e, widgetNode }) => {
        const collageItem = e.target.closest(".collage-item.filled");

        if (collageItem) {
          const sanitizedInnerHtml = collage.common.sanitizeInnerHtml({ html: widgetNode.innerHTML });
          const {
            mediaGrandParent,
            mediaParent,
            cropFrame: isCropFrameExist,
            mediaProps: { frameWidth, frameHeight, pictureHeight, previewThumb, previewStyle },
          } = collage.common.getCollageNodeAndDataRef({ collageChild: collageItem });

          initSelectable({ activeWidgetId: false, finalUpdate: true });
          updateMetadata({
            ...metadata,
            activeWidgetId: false,
            activeWidgetType: false,
          });
          updateCollageData({
            ...collageData,
            isActive: true,
            widgetId: widgetNode.id,
            prevState: sanitizedInnerHtml,
            curState: sanitizedInnerHtml,
          });

          collageItem.classList.add("active");
          mediaGrandParent.style.overflow = "visible";

          if (!isCropFrameExist) {
            const cropFrame = collage.misc.generateCropFrame({ frameWidth, frameHeight, pictureHeight });
            mediaGrandParent.appendChild(cropFrame);
          }

          collage.handler.show({ widgetNode, collageItem, mediaParent });
          collage.preview.show({ thumb: previewThumb, style: previewStyle });
          localStorage.setItem("collage.event.isActive", JSON.stringify({ isActive: true, widgetId: widgetNode.id }));
          localStorage.setItem("widget.event.isActive", "true");
        }
      },

      deactivate: ({ e, actionName }) => {
        const { isActive, widgetId } = collage.event.fetch();
        const isRestrictedArea =
          e?.target?.closest(".dhp-widget-handler") ||
          e?.target?.closest(`#${widgetId} .collage-item.active`) ||
          e?.target?.closest(".collage-action-save") ||
          e?.target?.closest(".collage-action-cancel");

        if (isActive && !isRestrictedArea) {
          collage.misc.deactivateActiveCollageItem({ widgetId });
          collage.handler.hide();
          collage.preview.hide();
          localStorage.setItem("collage.event.isActive", "false");
          localStorage.setItem("widget.event.isActive", "false");

          // update react dom before collage event deactivation: based on "actionName" it will save current state (latest changes) or revert to previous state
          collage.common.updateReactDom({
            actionName,
            widgetId,
            innerHtml: actionName === "CANCEL" ? collageData.prevState : collageData.curState,
          });

          // disable collage event in context
          updateCollageData({
            ...collageData,
            isActive: false,
            widgetId: false,
            prevState: false,
            curState: false,
          });
        }
      },
    },

    handler: {
      show: ({ widgetNode, collageItem, mediaParent }) => {
        const activeBlock = widgetNode.closest(".dhp-page-block");
        const widgetRect = widgetNode.getBoundingClientRect();
        const ciRect = collageItem.getBoundingClientRect();

        const deltaOffset = {
          x: ciRect.left - widgetRect.left,
          y: ciRect.top - widgetRect.top,
        };

        const {
          translate: { x: widgetTransX, y: widgetTransY },
          rotate: { theta: widgetTheta },
        } = getCssTransformObj({
          transform: widgetNode.style.transform,
        });

        const {
          translate: { x: mpTransX, y: mpTransY },
        } = getCssTransformObj({
          transform: mediaParent.style.transform,
        });

        const handlerX =
          getZoomedValue(parseFloat(widgetTransX) + parseFloat(mpTransX), dimension.zoom) + deltaOffset.x;
        const handlerY =
          getZoomedValue(parseFloat(widgetTransY) + parseFloat(mpTransY), dimension.zoom) + deltaOffset.y;

        // future scope::: attempt to determine relative position (transX, transY) of handler if rotation is applicable to collage (enable from editor_config)
        // const angleInRad = parseFloat(widgetTheta) * (Math.PI / 180);
        // const nX = handlerY - Math.cos(angleInRad) * parseFloat(mediaParent.style.height);
        // const nY = handlerX - Math.sin(angleInRad) * parseFloat(mediaParent.style.height);

        const { transform: handlerTransformStr } = getCssTransformObj({
          translateX: handlerX + "px",
          translateY: handlerY + "px",
          rotateAngle: widgetTheta,
          returnHybrid: true,
        });

        const selectionCss = {
          width: getZoomedValue(mediaParent.style.width, dimension.zoom) + "px",
          height: getZoomedValue(mediaParent.style.height, dimension.zoom) + "px",
          transform: handlerTransformStr,
        };

        show_GWH({
          activeBlock: activeBlock,
          css: selectionCss,
          finalUpdate: true,
        });
      },

      hide: () => {
        hide_WH();
      },
    },

    preview: {
      show: ({ thumb, style }) => {
        const { cropPreview, cropThumb } = collage.common.getHandlerNodeAndDataRef();
        cropPreview.classList.remove("d-none");
        cropPreview.style.cssText = `
          width: 100%;
          height: 100%;
          opacity: 0.3;
          position: absolute;
          z-index: -999;
          ${style}
        `;
        cropThumb.src = thumb;
      },

      hide: () => {
        const { cropPreview, cropThumb } = collage.common.getHandlerNodeAndDataRef();
        cropPreview.classList.add("d-none");
        cropPreview.style.cssText = "";
        cropThumb.src = "";
      },
    },

    resize: {
      collage: {
        generateScaledDim: ({ wrapperDimOld, wrapperDimNew, pictureWidth, pictureHeight, pictureAr, cropData }) => {
          let scaledWidth, scaledHeight;

          if (pictureWidth > wrapperDimNew.w && pictureHeight > wrapperDimNew.h) {
            if (pictureWidth - cropData.w > wrapperDimNew.w || pictureWidth - cropData.e > wrapperDimNew.w) {
              scaledWidth = pictureWidth;
              scaledHeight = pictureHeight;
            } else {
              const factor = pictureWidth / wrapperDimOld.w;
              scaledWidth = wrapperDimNew.w * factor;
              scaledHeight = scaledWidth / pictureAr;
            }
          } else {
            const factor = Math.max(wrapperDimNew.w / pictureWidth, wrapperDimNew.h / pictureHeight);
            scaledWidth = pictureWidth * factor;
            scaledHeight = pictureHeight * factor;
          }

          return { scaledWidth, scaledHeight };
        },

        generateCropData: ({ wrapperDimNew, scaledWidth, scaledHeight, cropData }) => {
          let translateX,
            translateY,
            dataCrop = { n: 0, s: 0, w: 0, e: 0 };

          if (cropData.w === cropData.e) {
            translateX = (wrapperDimNew.w - scaledWidth) / 2;
            dataCrop = {
              ...dataCrop,
              w: Math.abs(translateX),
              e: Math.abs(translateX),
            };
          } else {
            const overflown_width_old = cropData.w + cropData.e;
            const overflown_width_new = wrapperDimNew.w - scaledWidth;
            const crop_factor_w = cropData.w / overflown_width_old;
            const crop_factor_e = cropData.e / overflown_width_old;

            translateX = overflown_width_new * crop_factor_w;
            dataCrop = {
              ...dataCrop,
              w: Math.abs(overflown_width_new * crop_factor_w),
              e: Math.abs(overflown_width_new * crop_factor_e),
            };
          }

          if (cropData.n === cropData.s) {
            translateY = (wrapperDimNew.h - scaledHeight) / 2;
            dataCrop = { ...dataCrop, n: Math.abs(translateY), s: Math.abs(translateY) };
          } else {
            const overflown_height_old = cropData.n + cropData.s;
            const overflown_height_new = wrapperDimNew.h - scaledHeight;
            const crop_factor_n = cropData.n / overflown_height_old;
            const crop_factor_s = cropData.s / overflown_height_old;

            translateY = overflown_height_new * crop_factor_n;
            dataCrop = {
              ...dataCrop,
              n: Math.abs(overflown_height_new * crop_factor_n),
              s: Math.abs(overflown_height_new * crop_factor_s),
            };
          }

          return { translateX, translateY, dataCrop: JSON.stringify(dataCrop) };
        },

        resizing: ({ meta, widgetWidth, widgetHeight }) => {
          const { mainContent, collageItems, gridGap } = collage.common.getCollageNodeAndDataRef({
            widgetId: meta.client.id,
          });

          mainContent.style.minHeight = `${widgetHeight}px`;

          collageItems.forEach(item => {
            const mediaGrandParent = item.querySelector(".media-grand-parent");

            if (mediaGrandParent) {
              // setting wrapper dimension
              const {
                wf = null,
                hf = null,
                gfw = null,
                gfh = null,
              } = getStringToValidJSON(item.dataset.props) ?? { wf: null, hf: null, gfw: null, gfh: null };

              if ([wf, hf, gfw, gfh].includes(null)) return;

              const wrapperDimOld = {
                w: parseFloat(mediaGrandParent.style.width),
                h: parseFloat(mediaGrandParent.style.height),
              };

              const wrapperDimNew = {
                w: widgetWidth * parseFloat(wf) - gridGap * parseFloat(gfw),
                h: widgetHeight * parseFloat(hf) - gridGap * parseFloat(gfh),
              };

              mediaGrandParent.style.width = wrapperDimNew.w + "px";
              mediaGrandParent.style.height = wrapperDimNew.h + "px";

              // setting media dimension
              const {
                mediaParent,
                mediaProps: { pictureWidth, pictureHeight, pictureAr, cropData },
              } = collage.common.getCollageNodeAndDataRef({
                collageChild: item,
              });

              const { scaledWidth, scaledHeight } = collage.resize.collage.generateScaledDim({
                wrapperDimOld,
                wrapperDimNew,
                pictureWidth,
                pictureHeight,
                pictureAr,
                cropData,
              });

              const { translateX, translateY, dataCrop } = collage.resize.collage.generateCropData({
                wrapperDimNew,
                scaledWidth,
                scaledHeight,
                cropData,
              });

              mediaParent.style.width = scaledWidth + "px";
              mediaParent.style.height = scaledHeight + "px";
              mediaParent.style.transform = `translate(${translateX}px, ${translateY}px) scale(1, 1)`;
              mediaParent.setAttribute("data-crop", dataCrop);
            }
          });
        },

        stop: meta => {
          const updatedCss = meta.client.current;
          const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
            updatedCss.height,
            updatedCss.transform,
            updatedCss.width
          );
          const isDletable = deleteWidgetIfOutside(meta.client.id);

          // Check widget position during resize, if it is outside canvas area delete the widget else upadte new position
          if (isDletable) setDeleteWidget(true);
          else {
            collage.common.updateReactDom({
              widgetId: meta.client.id,
              newCss: updatedCss,
              newData: {
                "data-x-allignment": x_al,
                "data-y-allignment": y_al,
              },
            });
          }
        },
      },
      item: {
        meta: false,

        generateMinSize: ({ resizer, pictureWidth, pictureHeight, pictureAr, cropData }) => {
          let minWidth, minHeight;
          const uncroppableLength = {};

          // North-West Resizer | Top-Left
          if (resizer === NW) {
            uncroppableLength.w = pictureWidth - cropData.w;
            uncroppableLength.h = pictureHeight - cropData.n;
          }

          // North-East Resizer | Top-Right
          else if (resizer === NE) {
            uncroppableLength.w = pictureWidth - cropData.e;
            uncroppableLength.h = pictureHeight - cropData.n;
          }

          // South-West Resizer | Bottom-Left
          else if (resizer === SW) {
            uncroppableLength.w = pictureWidth - cropData.w;
            uncroppableLength.h = pictureHeight - cropData.s;
          }

          // South-East Resizer | Bottom-Right
          else if (resizer === SE) {
            uncroppableLength.w = pictureWidth - cropData.e;
            uncroppableLength.h = pictureHeight - cropData.s;
          }

          uncroppableLength.ar = uncroppableLength.w / uncroppableLength.h;

          if (uncroppableLength.ar > pictureAr) {
            // picture width will touch the crop frame first, if minimized
            minWidth = uncroppableLength.w;
            minHeight = minWidth / pictureAr;
          } else {
            // picture height will touch the crop frame first, if minimized
            minHeight = uncroppableLength.h;
            minWidth = minHeight * pictureAr;
          }

          return { minWidth, minHeight };
        },

        start: ({ widgetId, resizer, domEvent: e }) => {
          const {
            handler,
            handlerProps: { handlerTransform },
          } = collage.common.getHandlerNodeAndDataRef();

          const {
            mediaGrandParent,
            mediaParent,
            cropFrame,
            mediaProps: {
              pictureWidth,
              pictureHeight,
              pictureTransX,
              pictureTransY,
              pictureTransform,
              pictureAr,
              cropData,
            },
          } = collage.common.getCollageNodeAndDataRef({
            collageChild: document.querySelector(`#${widgetId} .collage-item.active`),
          });

          const { minWidth, minHeight } = collage.resize.item.generateMinSize({
            resizer,
            pictureWidth,
            pictureHeight,
            pictureAr,
            cropData,
          });

          const props = {
            isResizing: true,
            isFirstEvent: true,
            widgetId,
            client: {
              node: mediaParent,
              parent: mediaGrandParent,
              cropFrame,
              minWidth,
              minHeight,
              aspectRatio: pictureAr,
              initial: {
                top: pictureTransY,
                left: pictureTransX,
                width: pictureWidth,
                height: pictureHeight,
                transform: pictureTransform,
              },
            },
            resizer: {
              node: e.target,
              type: resizer,
            },
            mouse: {
              initial: {
                x: e.clientX,
                y: e.clientY,
              },
            },
            handler: {
              node: handler,
              initial: {
                transform: handlerTransform,
              },
            },
          };

          collage.resize.item.meta = props;
          document.addEventListener("mousemove", collage.resize.item.resizing);
          document.addEventListener("mouseup", collage.resize.item.stop);
        },

        resizing: e => {
          const meta = collage.resize.item.meta;
          if (meta?.isResizing) {
            const {
              client: {
                node: client,
                parent: mediaGrandParent,
                cropFrame,
                minWidth: cmw,
                minHeight: cmh,
                aspectRatio: car,
                initial: { top: cit, left: cil, width: ciw, height: cih, transform: clientInitTrans },
              },
              resizer: { node: resizerNode, type: resizerType },
              mouse: {
                initial: { x: mix, y: miy },
              },
              handler: {
                node: handler,
                initial: { transform: handlerInitTrans },
              },
            } = meta;

            if (meta.isFirstEvent) {
              toggleWidgetEventHandlers({ action: "HIDE" });
              toggleWidgetEventCursorToDefault({ action: "SHOW", node: resizerNode });
              meta.isFirstEvent = false;
            }

            const dX = e.clientX - mix;
            const dY = e.clientY - miy;

            let width, height;
            let resizeData = { top: cit, left: cil, width: ciw, height: cih };

            // North-West Resizer | Top-Left
            if (resizerType === NW) {
              const delta = { l: dX, t: dY, w: -dX, h: -dY };
              width = ciw + delta.w;
              height = width / car;

              if (width >= cmw) {
                if (height >= cmh) resizeData = { ...resizeData, left: cil + delta.l, width };
              } else resizeData = { ...resizeData, left: cil + (ciw - cmw), width: cmw };

              if (height >= cmh) resizeData = { ...resizeData, top: cit - (height - cih), height };
              else resizeData = { ...resizeData, top: cit + (cih - cmh), height: cmh };
            }

            // North-East Resizer | Top-Right
            else if (resizerType === NE) {
              const delta = { t: dY, w: dX, h: -dY };
              width = ciw + delta.w;
              height = width / car;

              if (width >= cmw) {
                if (height >= cmh) resizeData = { ...resizeData, width };
              } else resizeData = { ...resizeData, width: cmw };

              if (height >= cmh) resizeData = { ...resizeData, height, top: cit - (height - cih) };
              else resizeData = { ...resizeData, height: cmh, top: cit + (cih - cmh) };
            }

            // South-West Resizer | Bottom-Left
            else if (resizerType === SW) {
              const delta = { l: dX, w: -dX, h: dY };
              width = ciw + delta.w;
              height = width / car;

              if (width >= cmw) {
                if (height >= cmh) resizeData = { ...resizeData, left: cil + delta.l, width };
              } else resizeData = { ...resizeData, left: cil + (ciw - cmw), width: cmw };

              if (height >= cmh) resizeData = { ...resizeData, height };
              else resizeData = { ...resizeData, height: cmh };
            }

            // South-East Resizer | Bottom-Right
            else if (resizerType === SE) {
              const delta = { w: dX, h: dY };
              width = ciw + delta.w;
              height = width / car;

              if (width >= cmw) {
                if (height >= cmh) resizeData = { ...resizeData, width };
              } else resizeData = { ...resizeData, width: cmw };

              if (height >= cmh) resizeData = { ...resizeData, height };
              else resizeData = { ...resizeData, height: cmh };
            }

            // determine size and position of client after resize
            cropFrame.style.top = `-${resizeData.height}px`;
            client.style.width = resizeData.width + "px";
            client.style.height = resizeData.height + "px";
            client.style.transform = getCssTransformObj({
              translateX: resizeData.left + "px",
              translateY: resizeData.top + "px",
              transform: clientInitTrans,
              exclude: ["rotate"],
              returnStr: true,
            });

            const dataCrop = collage.drag.generateCropData({
              mediaParent: client,
              mediaGrandParent: mediaGrandParent,
              transX: Math.abs(resizeData.left),
              transY: Math.abs(resizeData.top),
            });

            client.setAttribute("data-crop", dataCrop);

            // determine size and position of handler after resize
            const {
              translate: { x: handlerInitTransX, y: handlerInitTransY },
            } = getCssTransformObj({
              transform: handlerInitTrans,
            });

            const {
              translate: { x: clientInitTransX, y: clientInitTransY },
            } = getCssTransformObj({
              transform: clientInitTrans,
            });

            handler.style.width = getZoomedValue(resizeData.width, dimension.zoom) + "px";
            handler.style.height = getZoomedValue(resizeData.height, dimension.zoom) + "px";
            handler.style.transform = getCssTransformObj({
              translateX:
                parseFloat(handlerInitTransX) +
                getZoomedValue(Math.abs(parseFloat(clientInitTransX)) - Math.abs(resizeData.left), dimension.zoom) +
                "px",
              translateY:
                parseFloat(handlerInitTransY) +
                getZoomedValue(Math.abs(parseFloat(clientInitTransY)) - Math.abs(resizeData.top), dimension.zoom) +
                "px",
              transform: handlerInitTrans,
              returnStr: true,
            });
          }
        },

        stop: () => {
          let meta = collage.resize.item.meta;
          if (meta?.isResizing) {
            toggleWidgetEventHandlers({ action: "SHOW" });
            toggleWidgetEventCursorToDefault({ action: "HIDE" });

            if (!meta.isFirstEvent) {
              // collage.common.updateReactDom({ widgetId: meta.widgetId });
              updateCollageData({
                ...collageData,
                curState: collage.common.sanitizeInnerHtml({ html: document.getElementById(meta.widgetId).innerHTML }),
              });
            }

            document.removeEventListener("mousemove", collage.resize.item.resizing);
            document.removeEventListener("mouseup", collage.resize.item.stop);
            meta = false;
          }
        },
      },
    },

    drag: {
      meta: false,

      generateDragRange: ({ mediaParent, mediaGrandParent, handlerTransX, handlerTransY, cropData }) => {
        const frameWidth = parseFloat(mediaGrandParent.style.width);
        const frameHeight = parseFloat(mediaGrandParent.style.height);
        const pictureWidth = parseFloat(mediaParent.style.width);
        const pictureHeight = parseFloat(mediaParent.style.height);

        return {
          client: {
            xMax: 0,
            xMin: frameWidth - pictureWidth,
            yMax: 0,
            yMin: frameHeight - pictureHeight,
          },
          handler: {
            xMax: parseFloat(handlerTransX) + getZoomedValue(cropData.w, dimension.zoom),
            xMin: parseFloat(handlerTransX) - getZoomedValue(cropData.e, dimension.zoom),
            yMax: parseFloat(handlerTransY) + getZoomedValue(cropData.n, dimension.zoom),
            yMin: parseFloat(handlerTransY) - getZoomedValue(cropData.s, dimension.zoom),
          },
        };
      },

      generateCropData: ({ mediaParent, mediaGrandParent, transX, transY }) => {
        const cropWidth = parseFloat(mediaParent.style.width) - parseFloat(mediaGrandParent.style.width);
        const cropHeight = parseFloat(mediaParent.style.height) - parseFloat(mediaGrandParent.style.height);

        return JSON.stringify({
          n: Math.abs(transY),
          s: Math.abs(cropHeight - transY),
          w: Math.abs(transX),
          e: Math.abs(cropWidth - transX),
        });
      },

      validateDragZone: ({ dragRange, transX, transY }) => {
        const { xMax, xMin, yMax, yMin } = dragRange;

        return {
          transX: transX > xMax ? xMax : transX < xMin ? xMin : transX,
          transY: transY > yMax ? yMax : transY < yMin ? yMin : transY,
        };
      },

      start: ({ widgetId, domEvent: e }) => {
        const {
          handler,
          handlerProps: { handlerTransX, handlerTransY, handlerTransform },
        } = collage.common.getHandlerNodeAndDataRef();

        const {
          mediaGrandParent,
          mediaParent,
          mediaProps: { pictureTransX: clientTransX, pictureTransY: clientTransY, cropData },
        } = collage.common.getCollageNodeAndDataRef({
          collageChild: document.querySelector(`#${widgetId} .collage-item.active`),
        });

        const {
          block: { id: blockId, rect: blockRect },
        } = getWidgetAndParentsDomReference(widgetId);

        const { client: clientRange, handler: handlerRange } = collage.drag.generateDragRange({
          mediaParent,
          mediaGrandParent,
          handlerTransX,
          handlerTransY,
          cropData,
        });

        const props = {
          isDragging: true,
          isFirstEvent: true,
          widgetId,
          blockId,
          blockRect,
          client: {
            node: mediaParent,
            parent: mediaGrandParent,
            pointerOffset: {
              x: e.clientX - blockRect.left - getZoomedValue(clientTransX, dimension.zoom),
              y: e.clientY - blockRect.top - getZoomedValue(clientTransY, dimension.zoom),
            },
            initial: {
              transform: mediaParent.style.transform,
            },
            dragRange: clientRange,
          },
          handler: {
            node: handler,
            pointerOffset: {
              x: e.clientX - blockRect.left - parseFloat(handlerTransX),
              y: e.clientY - blockRect.top - parseFloat(handlerTransY),
            },
            initial: {
              transform: handlerTransform,
            },
            dragRange: handlerRange,
          },
        };

        collage.drag.meta = props;
        document.addEventListener("mousemove", collage.drag.dragging);
        document.addEventListener("mouseup", collage.drag.stop);
      },

      dragging: e => {
        const meta = collage.drag.meta;
        if (meta?.isDragging) {
          if (meta.isFirstEvent) {
            toggleWidgetEventHandlers({ action: "HIDE" });
            meta.isFirstEvent = false;
          }

          // determine new drag position of client
          const client_x = e.clientX - meta.blockRect.left - meta.client.pointerOffset.x;
          const client_y = e.clientY - meta.blockRect.top - meta.client.pointerOffset.y;

          const { transX: clientTransX, transY: clientTransY } = collage.drag.validateDragZone({
            dragRange: meta.client.dragRange,
            transX: getUnScaledValue(client_x, dimension.zoom),
            transY: getUnScaledValue(client_y, dimension.zoom),
          });

          const clientTransformStr = getCssTransformObj({
            translateX: clientTransX + "px",
            translateY: clientTransY + "px",
            transform: meta.client.initial.transform,
            exclude: ["rotate"],
            returnStr: true,
          });

          const dataCrop = collage.drag.generateCropData({
            mediaParent: meta.client.node,
            mediaGrandParent: meta.client.parent,
            transX: Math.abs(clientTransX),
            transY: Math.abs(clientTransY),
          });

          // determine new drag position of handler
          const handler_x = e.clientX - meta.blockRect.left - meta.handler.pointerOffset.x;
          const handler_y = e.clientY - meta.blockRect.top - meta.handler.pointerOffset.y;

          const { transX: handlerTransX, transY: handlerTransY } = collage.drag.validateDragZone({
            dragRange: meta.handler.dragRange,
            transX: handler_x,
            transY: handler_y,
          });

          const handlerTransformStr = getCssTransformObj({
            translateX: handlerTransX + "px",
            translateY: handlerTransY + "px",
            transform: meta.handler.initial.transform,
            returnStr: true,
          });

          // update DOM
          // meta.client.node.style.willChange = "transform"; // resolved background line issue in canvas
          meta.client.node.setAttribute("data-crop", dataCrop);
          meta.client.node.style.transform = clientTransformStr;
          meta.handler.node.style.transform = handlerTransformStr;
        }
      },

      stop: () => {
        let meta = collage.drag.meta;
        if (meta?.isDragging) {
          toggleWidgetEventHandlers({ action: "SHOW" });

          // resolved background line issue in canvas
          // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          // if (!isSafari) meta.client.node.style.willChange = "";

          if (!meta.isFirstEvent) {
            // collage.common.updateReactDom({ widgetId: meta.widgetId });
            updateCollageData({
              ...collageData,
              curState: collage.common.sanitizeInnerHtml({ html: document.getElementById(meta.widgetId).innerHTML }),
            });
          }

          document.removeEventListener("mousemove", collage.drag.dragging);
          document.removeEventListener("mouseup", collage.drag.stop);
          meta = false;
        }
      },
    },

    gridGap: {
      fetch: ({ widgetId }) => {
        const { gridGap } = collage.common.getCollageNodeAndDataRef({ widgetId });
        return { gridGap };
      },

      apply: ({ widgetId, gridSpacing, finalUpdate }) => {
        const {
          collageContainer,
          widgetProps: { widgetWidth, widgetHeight },
        } = collage.common.getCollageNodeAndDataRef({ widgetId });

        // apply grid gap and resize collage
        collageContainer.style.gap = `${gridSpacing}px`;

        // resize collage with new grid gap
        collage.resize.collage.resizing({
          meta: { client: { id: widgetId } },
          widgetWidth,
          widgetHeight,
        });

        // update context
        if (finalUpdate) collage.common.updateReactDom({ widgetId });
      },
    },
  };

  const handleOutsideClick = e => {
    if (collageData?.isActive) {
      // save unsaved collage changes when clicked anywhere outside the collage widget (exceptions: done and cancel button from toolbar, widget handler itself)
      collage.event.deactivate({ e, actionName: "SAVE" });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return {
    fetchCollageEvent: collage.event.fetch,
    activateCollageEvent: collage.event.activate,
    deactivateCollageEvent: collage.event.deactivate,
    collageResize: collage.resize.collage.resizing,
    collageResizeStop: collage.resize.collage.stop,
    collageItemResize: collage.resize.item.start,
    collageItemDrag: collage.drag.start,
    fetchGridGap: collage.gridGap.fetch,
    applyGridGap: collage.gridGap.apply,
  };
};

export default useCollage;
