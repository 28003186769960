import { useContext, useEffect, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  ANIMATION,
  GIF,
  ICON,
  ILLUSTRATION,
  LINE,
  PICTURE,
  QR_CODE,
  SHAPE,
  STICKER,
  STOCK,
  SVG,
  TABLE,
  TEXT,
  TEXT_FRAME,
  TYPE_FORM,
  JOT_FORM,
  UPLOAD,
  VIDEO,
  YOUTUBE,
  COLLAGE,
  MAP,
  BRAND,
  UPLOAD_VIDEO,
} from "../constants/editor";
import { RGBToHex, getRandomString } from "../_helpers/utils";
import { EditorPanelContext } from "../containers/editor/EditorPanel";

const useCopyStyleWidget = () => {
  let { widgets, updateWidgets } = useContext(EditorContext);
  let { copyStyle, updateCopyStyle } = useContext(EditorPanelContext);

  const [copyStyleWidget, setCopyStyleWidget] = useState(false);

  const getNode = currentActiveWidgetId => {
    let node;
    if (document.querySelector(`#${currentActiveWidgetId} svg rect`)) {
      node = document.querySelector(`#${currentActiveWidgetId} svg rect`);
    } else if (document.querySelector(`#${currentActiveWidgetId} svg circle`)) {
      node = document.querySelector(`#${currentActiveWidgetId} svg circle`);
    } else if (document.querySelector(`#${currentActiveWidgetId} svg polygon`)) {
      node = document.querySelector(`#${currentActiveWidgetId} svg polygon`);
    } else if (document.querySelector(`#${currentActiveWidgetId} svg path`)) {
      node = document.querySelector(`#${currentActiveWidgetId} svg path`);
    }
    return node;
  };

  useEffect(() => {
    if (copyStyleWidget) {
      let currentActiveWidget = copyStyleWidget;
      let currentActiveWidgetId = currentActiveWidget.current.id;
      let currentActiveWidgetIdx = widgets.findIndex(widget => widget.id === currentActiveWidgetId);
      let currentActiveElem = document.getElementById(currentActiveWidgetId);
      let currentActiveInnerElem = currentActiveElem.querySelector(" .dhp-widget-inner");
      let cropped = currentActiveInnerElem.style.clipPath === "" ? false : true;
      let transparentImage = currentActiveElem.getElementsByTagName("img")[0]?.src.includes("transparent-bg");
      let borderInculdedWidgetType = [TEXT, PICTURE, VIDEO, UPLOAD, TYPE_FORM, JOT_FORM, QR_CODE, SHAPE];
      let colorExcludedWidgetType = [PICTURE, VIDEO, ANIMATION, TABLE, TYPE_FORM, JOT_FORM, MAP];
      let filterInculdedWidgetType = [PICTURE, VIDEO, ANIMATION, COLLAGE];
      let changeLineThickness = false;
      let changeHeading = false;
      let removeGradColor = false;
      let changeList = false;
      let newDataAttr = {};
      let headingValue, node, newArray;

      document.getElementById("canvas-panel-area").classList.remove("cursor-copy-style");

      // NOTE:::: Once copy-style is applied ~ below code restores widget cursor style
      [...document.querySelectorAll(".dhp-page-widget")].forEach(widget => {
        widget.classList.remove("cursor-copy-style");
      });

      // Copy style for same type widget
      if (
        currentActiveWidget.current.dataset.assetType === copyStyle.widgetType &&
        copyStyle.widgetType !== VIDEO &&
        copyStyle.widgetType !== UPLOAD &&
        copyStyle.widgetType !== PICTURE &&
        copyStyle.widgetType !== ANIMATION &&
        copyStyle.widgetType !== TYPE_FORM &&
        copyStyle.widgetType !== JOT_FORM &&
        copyStyle.widgetType !== SHAPE &&
        copyStyle.widgetType !== COLLAGE
      ) {
        // Copy line thickness if both line widget thickness value is different
        if (
          copyStyle.widgetType === LINE &&
          copyStyle.dataset.linethickness !== currentActiveInnerElem.dataset.linethickness
        ) {
          changeLineThickness = parseInt(copyStyle.dataset.linethickness);
        }

        // Copy heading if both text widget heading value is different
        if (copyStyle.widgetType === TEXT && copyStyle.dataset["data-heading"] !== currentActiveElem.dataset.heading) {
          changeHeading = true;
          headingValue = copyStyle.dataset["data-heading"];
          let dataAttribute = currentActiveInnerElem.dataset;

          node = document.createElement(headingValue === "normal" ? "div" : headingValue);
          node.innerHTML = currentActiveInnerElem.innerHTML;
          node.classList = currentActiveInnerElem.classList;
          node.style.cssText = copyStyle.cssText;
          if (dataAttribute) {
            for (const [key, value] of Object.entries(dataAttribute)) {
              node.setAttribute(`data-${key}`, value);
            }
          }

          // remove formatting from all child div and apply copy style formatting
          node.querySelectorAll("*").forEach(elm => {
            if (elm.style?.textDecoration) {
              elm.style.textDecoration =
                copyStyle.style.textDecoration === "line-through" || !copyStyle.style.textDecoration
                  ? "none"
                  : copyStyle.style.textDecoration;
            }

            elm.style.fontWeight = copyStyle.style.fontWeight;
            elm.style.fontStyle = copyStyle.style.fontStyle;
          });
        }

        // remove formatting from all child div and apply copy style formatting
        if (copyStyle.widgetType === TEXT) {
          currentActiveInnerElem.querySelectorAll("*").forEach(node => {
            if (node.style?.textDecoration) {
              node.style.textDecoration =
                copyStyle.style.textDecoration === "line-through" || !copyStyle.style.textDecoration
                  ? "none"
                  : copyStyle.style.textDecoration;
            }

            node.style.fontWeight = copyStyle.style.fontWeight;
            node.style.fontStyle = copyStyle.style.fontStyle;
          });
        }

        // Copy list
        if (
          copyStyle.widgetType === TEXT &&
          (copyStyle.dataset["data-subwidget"] || currentActiveElem.dataset.subwidget)
        ) {
          let listValue;

          // Remove list from target widget as source widget has no list value
          if (!copyStyle.dataset["data-subwidget"]) {
            listValue = currentActiveElem.dataset.subwidget;
          }
          // Apply list from source widget
          else {
            if (copyStyle.dataset["data-subwidget"] === currentActiveElem.dataset.subwidget) {
              // If both source and target widget list value is same
              listValue = false;
            } else {
              listValue = copyStyle.dataset["data-subwidget"];
            }
          }
          changeList = listValue;
        }

        // Manaage transform property for widget inner div
        let transformProperty = currentActiveInnerElem.style.transform;
        let widgetInnerWidth = currentActiveInnerElem.style.width;
        let widgetInnerHeight = currentActiveInnerElem.style.height;

        currentActiveInnerElem.style.cssText = copyStyle.cssText;
        currentActiveInnerElem.style.transform = transformProperty;

        if (copyStyle.widgetType === TABLE) {
          currentActiveInnerElem.style.width = widgetInnerWidth;
          currentActiveInnerElem.style.height = widgetInnerHeight;
        }
        // -----------------------end Manaage transform property for widget inner div--------------------

        // set data attributre for flip, for all widget
        // currentActiveInnerElem.setAttribute("data-horizontal", copyStyle.dataset.horizontal ?? 1);
        // currentActiveInnerElem.setAttribute("data-vertical", copyStyle.dataset.vertical ?? 1);
      }
      // Copy style for The widgets which are not same type, but has border and shadow property common
      else {
        //Copy shadow property
        let sourceWidgetShadowProperty, sourceWidgetImageFilterProperty;
        let sourceWidgetFlipableAvailable =
          copyStyle.widgetType === PICTURE ||
          copyStyle.widgetType === ANIMATION ||
          (copyStyle.widgetType === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(copyStyle.dataset["data-source"])) ||
          (copyStyle.widgetType === UPLOAD && copyStyle.dataset["data-file-type"] !== SVG);
        let TargetWidgetFlipAvailable =
          currentActiveWidget.current.dataset.assetType === PICTURE ||
          currentActiveWidget.current.dataset.assetType === ANIMATION ||
          (currentActiveWidget.current.dataset.assetType === VIDEO &&
            [STOCK, BRAND, UPLOAD_VIDEO].includes(currentActiveWidget.current.dataset.source)) ||
          (currentActiveWidget.current.dataset.assetType === UPLOAD &&
            currentActiveWidget.current.dataset.fileType !== SVG);

        // ********** Fetch source and target widgets filter and shadow values ********* //
        // Fetch shadow, flip and filter value  If source widget has flippable class
        if (sourceWidgetFlipableAvailable) {
          sourceWidgetShadowProperty = copyStyle.style?.shadow !== "" ? copyStyle.style?.shadow : "none";
          sourceWidgetImageFilterProperty = copyStyle.style?.filter !== "" ? copyStyle.style?.filter : "none";
        } else if (copyStyle.widgetType === TYPE_FORM || copyStyle.widgetType === JOT_FORM) {
          sourceWidgetShadowProperty = copyStyle.style?.shadow !== "" ? copyStyle.style?.shadow : "none";
        } else if (
          (copyStyle.widgetType === SHAPE &&
            ["Circles", "Triangles", "Basic"].includes(copyStyle.dataset["data-category"])) ||
          (copyStyle.widgetType === SHAPE &&
            ["Rectangles"].includes(copyStyle.dataset["data-category"]) &&
            copyStyle.dataset["data-version"] < 3)
        ) {
          sourceWidgetShadowProperty = copyStyle.style?.shadow !== "" ? copyStyle.style?.shadow : "none";
        } else if (copyStyle.widgetType === COLLAGE) {
          sourceWidgetShadowProperty = copyStyle.style?.shadow !== "" ? copyStyle.style?.shadow : "none";
          sourceWidgetImageFilterProperty = copyStyle.style?.filter !== "" ? copyStyle.style?.filter : "none";
        }
        // Fetch shadow value  If source widget has no flippable class
        else {
          sourceWidgetShadowProperty = copyStyle.style.filter ? copyStyle.style.filter : "none";
        }
        // ********** End Fetch section ********* //

        if (!TargetWidgetFlipAvailable && currentActiveWidget.current.dataset.assetType !== COLLAGE) {
          currentActiveInnerElem.style.filter = sourceWidgetShadowProperty;
        } else if (currentActiveWidget.current.dataset.assetType === COLLAGE) {
          currentActiveInnerElem.style.filter = sourceWidgetShadowProperty;
          currentActiveInnerElem.querySelector(".collage").style.filter = sourceWidgetImageFilterProperty;

          let telem = currentActiveInnerElem.querySelector(".collage");

          // remove or update data filter attribitues if only the source widget also have filter property
          if (
            filterInculdedWidgetType.includes(copyStyle.dataset["data-asset-type"]) ||
            (copyStyle.dataset["data-asset-type"] === UPLOAD && copyStyle.dataset["data-file-type"] !== SVG)
          ) {
            if (!copyStyle.dataset.filterType) {
              telem.removeAttribute("data-filter-type");
              telem.removeAttribute("data-filter");
            } else {
              telem.setAttribute("data-filter-type", copyStyle.dataset.filterType);
              telem.setAttribute("data-filter", copyStyle.dataset?.filter);
            }
          }
        } else {
          currentActiveElem.querySelector(".flippable").style.filter = sourceWidgetShadowProperty;
          currentActiveInnerElem.childNodes[0].style.filter = sourceWidgetImageFilterProperty;

          let telem = currentActiveInnerElem.childNodes[0];

          // remove or update data filter attribitues if only the source widget also have filter property
          if (
            filterInculdedWidgetType.includes(copyStyle.dataset["data-asset-type"]) ||
            (copyStyle.dataset["data-asset-type"] === UPLOAD && copyStyle.dataset["data-file-type"] !== SVG)
          ) {
            if (!copyStyle.dataset.filterType) {
              telem.removeAttribute("data-filter-type");
              telem.removeAttribute("data-filter");
            } else {
              telem.setAttribute("data-filter-type", copyStyle.dataset.filterType);
              telem.setAttribute("data-filter", copyStyle.dataset?.filter);
            }
          }
        }

        // set data attributre for flip, for all widget
        // currentActiveInnerElem.setAttribute("data-horizontal", copyStyle.dataset.horizontal ?? 1);
        // currentActiveInnerElem.setAttribute("data-vertical", copyStyle.dataset.vertical ?? 1);

        //Copy Border property
        if (
          borderInculdedWidgetType.includes(currentActiveWidget.current.dataset.assetType) &&
          borderInculdedWidgetType.includes(copyStyle.widgetType) &&
          !cropped &&
          !transparentImage
        ) {
          if (
            (currentActiveWidget.current.dataset.assetType === VIDEO &&
              currentActiveWidget.current.dataset.source === YOUTUBE) ||
            (currentActiveWidget.current.dataset.assetType === UPLOAD &&
              (currentActiveWidget.current.dataset.fileType === SVG ||
                currentActiveWidget.current.dataset.fileType === GIF)) ||
            (copyStyle.widgetType === VIDEO && copyStyle.dataset["data-source"] === YOUTUBE) ||
            (copyStyle.widgetType === UPLOAD &&
              (copyStyle.dataset["data-file-type"] === SVG || copyStyle.dataset["data-file-type"] === GIF))
          ) {
            console.log(
              "***** DO Nothing, Keep this console.log for now have to optimise the  condition later, as the condition is too big *****"
            );
          } else {
            let targetElem =
              currentActiveWidget.current.dataset.assetType === TEXT ||
              currentActiveWidget.current.dataset.assetType === QR_CODE ||
              currentActiveWidget.current.dataset.assetType === SHAPE
                ? currentActiveInnerElem.style
                : [TYPE_FORM, JOT_FORM].includes(currentActiveWidget.current.dataset.assetType)
                ? currentActiveElem.querySelector(".customapp-frame").style
                : currentActiveElem.querySelector(".flippable").style;

            //Apply Border for shape (Circles, Triangles, Basic)
            if (
              currentActiveWidget.current.dataset.assetType === SHAPE &&
              ["Circles", "Triangles", "Basic"].includes(currentActiveWidget.current.dataset.category) &&
              parseInt(currentActiveWidget.current.dataset.version) >= 3
            ) {
              let pathId = getRandomString(8);
              let insidePathId = `inside_${getRandomString(8)}`;
              let node = getNode(currentActiveWidgetId);
              node.setAttribute("id", pathId);
              let newSvgNode = document.createElement("defs");

              // remove border
              if (copyStyle.style.borderStyle === "none" || !copyStyle.style.borderStyle) {
                newSvgNode.innerHTML = `${
                  node.outerHTML
                } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${0} stroke="${
                  copyStyle.style.borderColor
                }" fill="none" clip-path="url(#${insidePathId})"></use>`;

                newDataAttr = {
                  "data-border-style": "none",
                  "data-border-width": 0,
                  "data-border-color": copyStyle.style.borderColor,
                };
              }
              // apply border
              else if (copyStyle.style.borderStyle) {
                newSvgNode.innerHTML = `${
                  node.outerHTML
                } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${
                  parseInt(copyStyle.style.borderWidth) * 5
                } stroke="${copyStyle.style.borderColor}" fill="none" clip-path="url(#${insidePathId})"></use>`;
                newDataAttr = {
                  "data-border-style": "solid",
                  "data-border-width": copyStyle.style.borderWidth,
                  "data-border-color":
                    copyStyle.widgetType === SHAPE
                      ? copyStyle.style.borderColor
                      : RGBToHex(copyStyle.style.borderColor),
                };
              }

              if (copyStyle.style.borderStyle) currentActiveElem.querySelector(` svg`).innerHTML = newSvgNode.innerHTML;
            }
            //Apply Border for other widget and Rectangle Shapes
            else if (
              currentActiveWidget.current.dataset.assetType !== SHAPE ||
              (currentActiveWidget.current.dataset.assetType === SHAPE &&
                currentActiveWidget.current.dataset.category === "Rectangles" &&
                currentActiveWidget.current.dataset.version >= 3)
            ) {
              let border_style;
              if (
                currentActiveWidget.current.dataset.assetType === SHAPE &&
                currentActiveWidget.current.dataset.category === "Rectangles"
              ) {
                if (
                  copyStyle.style.borderStyle &&
                  copyStyle.style.borderStyle !== "none" &&
                  copyStyle.style.borderStyle !== "solid"
                )
                  border_style = "solid";
              }
              targetElem.borderStyle = border_style ? border_style : copyStyle.style.borderStyle;
              targetElem.borderWidth = `${parseInt(copyStyle.style.borderWidth)}px`;
              targetElem.borderRadius = copyStyle.style.borderRadius;
              targetElem.borderColor = copyStyle.style.borderColor;

              // fix shifting related issue when copy style border in any picture or video widget
              if (
                currentActiveWidget.current.dataset.assetType === PICTURE ||
                (currentActiveWidget.current.dataset.assetType === VIDEO &&
                  [STOCK, BRAND, UPLOAD_VIDEO].includes(currentActiveWidget.current.dataset.source)) ||
                (currentActiveWidget.current.dataset.assetType === UPLOAD &&
                  currentActiveWidget.current.dataset.fileType !== SVG)
              ) {
                if (copyStyle.style.borderStyle === "none" || !copyStyle.style.borderStyle) {
                  currentActiveElem.querySelector(".dhp-widget-inner").style.left = "";
                  currentActiveElem.querySelector(".dhp-widget-inner").style.top = "";
                  currentActiveElem.querySelector(".dhp-widget-inner").style.position = "";
                } else {
                  currentActiveElem.querySelector(".dhp-widget-inner").style.left = `-${parseInt(
                    copyStyle.style.borderWidth
                  )}px`;
                  currentActiveElem.querySelector(".dhp-widget-inner").style.top = `-${parseInt(
                    copyStyle.style.borderWidth
                  )}px`;
                  currentActiveElem.querySelector(".dhp-widget-inner").style.position = `relative`;
                }
              }
            }
          }
        }
      }

      // Copy color
      if (copyStyle.color.length > 0 && !colorExcludedWidgetType.includes(currentActiveElem.dataset.assetType)) {
        if (
          !copyStyle.dataset["data-grad-scolor"] &&
          (currentActiveElem.dataset.assetType === ILLUSTRATION ||
            currentActiveElem.dataset.assetType === STICKER ||
            currentActiveElem.dataset.assetType === ICON ||
            (currentActiveElem.dataset.assetType === TEXT_FRAME && currentActiveElem.dataset.version >= 3) ||
            (currentActiveElem.dataset.assetType === UPLOAD && currentActiveElem?.dataset?.fileType === SVG))
        ) {
          let colorClass = [];
          let newArray = [];

          currentActiveElem.querySelectorAll(` svg *`).forEach(function (node) {
            if (node.nodeName !== "g" && node.style && RGBToHex(node.style.fill) !== "") {
              let dataClassName = node.dataset.class;
              if (!colorClass.includes(dataClassName)) {
                colorClass.push(dataClassName);
              }
            }
          });

          colorClass.map((className, idx) => {
            newArray.push({ class: className, color: copyStyle.color[idx] });
          });

          newArray.map(value => {
            currentActiveElem.querySelectorAll(` svg *`).forEach(function (node) {
              if (value.class === node.dataset.class) {
                node.style.fill = value.color;
              }
            });
          });

          //Apply color for mono svg in upload widget
          if (
            colorClass.length === 0 &&
            (currentActiveElem.dataset.assetType === UPLOAD || currentActiveElem.dataset.assetType === ICON)
          ) {
            currentActiveElem.querySelector(` svg`).style.fill = copyStyle.color[0];
          }
        }
        //Apply color for text widget
        else if (currentActiveElem.dataset.assetType === TEXT) {
          //copy gradient color from shape to text
          if (copyStyle.dataset["data-widget"] === "SHAPE" && copyStyle.dataset["data-grad-scolor"]) {
            currentActiveInnerElem.style.backgroundImage = `linear-gradient(${copyStyle.dataset["data-grad-degree"]}deg, ${copyStyle.dataset["data-grad-scolor"]} ${copyStyle.dataset["data-grad-spercen"]}, ${copyStyle.dataset["data-grad-ecolor"]} ${copyStyle.dataset["data-grad-epercen"]})`;
            currentActiveInnerElem.style.webkitTextFillColor = "transparent";
            currentActiveInnerElem.style.backgroundColor = "transparent";
          } else {
            currentActiveInnerElem.style.color = copyStyle.color[0];

            // remove formatting from all child div and apply main formatting
            currentActiveInnerElem.querySelectorAll("*").forEach(node => {
              if (node.style?.color) {
                node.style.color = copyStyle.color[0];
              }
            });

            // remove formatting from all child div and apply main formatting if heading is applied
            if (changeHeading) {
              node.querySelectorAll("*").forEach(elm => {
                if (elm.style?.color) {
                  elm.style.color = copyStyle.color[0];
                }
              });
            }

            // Remove Gradient
            if (!copyStyle.dataset["data-grad-scolor"] && currentActiveElem.dataset.gradScolor) {
              removeGradColor = true;
              currentActiveInnerElem.style.backgroundImage = "";
              currentActiveInnerElem.style.webkitTextFillColor = "";

              if (changeHeading) {
                node.style.backgroundImage = "";
                node.style.webkitTextFillColor = "";
              }
            }
          }

          // copy data attribute
          if (copyStyle.dataset["data-grad-scolor"]) {
            if (currentActiveInnerElem.classList.contains("text-background-active")) {
              currentActiveInnerElem.classList.remove("text-background-active");
              if (changeHeading) node.classList.remove("text-background-active");
            }

            newDataAttr = {
              ...newDataAttr,
              "data-grad-degree": copyStyle.dataset["data-grad-degree"],
              "data-grad-scolor": copyStyle.dataset["data-grad-scolor"],
              "data-grad-spercen": copyStyle.dataset["data-grad-spercen"],
              "data-grad-ecolor": copyStyle.dataset["data-grad-ecolor"],
              "data-grad-epercen": copyStyle.dataset["data-grad-epercen"],
            };
          }
          // remove gradient data attribute
          else if (currentActiveElem.dataset.gradScolor) {
            if (
              copyStyle.style.backgroundColor &&
              copyStyle.style.backgroundColor !== "transparent" &&
              !currentActiveInnerElem.classList.contains("text-background-active")
            ) {
              currentActiveInnerElem.classList.add("text-background-active");
              if (changeHeading) node.classList.add("text-background-active");
            }

            let modifiedArray = JSON.parse(JSON.stringify(widgets));

            delete modifiedArray[currentActiveWidgetIdx].data["data-grad-degree"];
            delete modifiedArray[currentActiveWidgetIdx].data["data-grad-scolor"];
            delete modifiedArray[currentActiveWidgetIdx].data["data-grad-spercen"];
            delete modifiedArray[currentActiveWidgetIdx].data["data-grad-ecolor"];
            delete modifiedArray[currentActiveWidgetIdx].data["data-grad-epercen"];

            newDataAttr = modifiedArray[currentActiveWidgetIdx].data;
          } else {
            if (
              copyStyle.style.backgroundColor &&
              copyStyle.style.backgroundColor !== "transparent" &&
              !currentActiveInnerElem.classList.contains("text-background-active")
            ) {
              currentActiveInnerElem.classList.add("text-background-active");
              if (changeHeading) node.classList.add("text-background-active");
            } else if (
              copyStyle.style.backgroundColor === "transparent" &&
              currentActiveInnerElem.classList.contains("text-background-active")
            ) {
              currentActiveInnerElem.classList.remove("text-background-active");
              if (changeHeading) node.classList.remove("text-background-active");
            }
          }
        }
        //Apply gradient color for new shape selective category if copy widget has gradient color
        else if (
          copyStyle.dataset["data-grad-scolor"] &&
          currentActiveElem.dataset.assetType === SHAPE &&
          ["Basic", "Circles", "Triangles", "Rectangles"].includes(currentActiveElem.dataset.category) &&
          parseInt(currentActiveElem.dataset.version) >= 3
        ) {
          let newSvgNode = document.createElement("defs");
          let gradId = `grad_${getRandomString(8)}`;
          let node = document.querySelector(`#${currentActiveWidgetId} svg`);
          if (node.querySelector("linearGradient")) node.querySelector("linearGradient").remove();
          let svgPath = getNode(currentActiveWidgetId);
          svgPath.style.fill = `url(#${gradId})`;

          newSvgNode.innerHTML = `<linearGradient id="${gradId}" x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(${copyStyle.dataset["data-grad-degree"]})"><stop offset="${copyStyle.dataset["data-grad-spercen"]}" style="stop-color:${copyStyle.dataset["data-grad-scolor"]};stop-opacity:1"></stop><stop offset="${copyStyle.dataset["data-grad-epercen"]}" style="stop-color:${copyStyle.dataset["data-grad-ecolor"]};stop-opacity:1"></stop></linearGradient> ${node.innerHTML}`;

          currentActiveElem.querySelector(` svg`).innerHTML = newSvgNode.innerHTML;

          // copy data attribute
          if (copyStyle.dataset["data-grad-scolor"]) {
            newDataAttr = {
              ...newDataAttr,
              "data-grad-degree": copyStyle.dataset["data-grad-degree"],
              "data-grad-scolor": copyStyle.dataset["data-grad-scolor"],
              "data-grad-spercen": copyStyle.dataset["data-grad-spercen"],
              "data-grad-ecolor": copyStyle.dataset["data-grad-ecolor"],
              "data-grad-epercen": copyStyle.dataset["data-grad-epercen"],
            };
          }
        }
        //Apply color for QR Code
        else if (!copyStyle.dataset["data-grad-scolor"] && currentActiveElem.dataset.assetType === QR_CODE) {
          currentActiveElem.querySelectorAll(` svg *`).forEach(function (node, index) {
            if (node.nodeName !== "title") {
              node.style.fill = copyStyle.color[index];
            }
          });
          if (copyStyle.widgetType !== QR_CODE) {
            currentActiveInnerElem.style.backgroundColor = copyStyle.color[0]; // set margin area background if style is not copied from any qr-code widget
          }
        }
        //Apply color for mono svg widget
        else if (
          !copyStyle.dataset["data-grad-scolor"] &&
          currentActiveElem.dataset.assetType !== UPLOAD &&
          currentActiveElem.querySelector(` svg`)
        ) {
          currentActiveElem.querySelector(` svg`).style.fill = copyStyle.color[0];

          // remove gradient color and data attribute
          if (currentActiveElem.dataset.gradScolor) {
            removeGradColor = true;
            let node = document.querySelector(`#${currentActiveWidgetId} svg`);
            if (node.querySelector("linearGradient")) node.querySelector("linearGradient").remove();
            let svgPath = getNode(currentActiveWidgetId);
            svgPath.style.fill = "";

            let modifiedArray = JSON.parse(JSON.stringify(widgets));

            delete modifiedArray[currentActiveWidgetIdx].data["data-grad-degree"];
            delete modifiedArray[currentActiveWidgetIdx].data["data-grad-scolor"];
            delete modifiedArray[currentActiveWidgetIdx].data["data-grad-spercen"];
            delete modifiedArray[currentActiveWidgetIdx].data["data-grad-ecolor"];
            delete modifiedArray[currentActiveWidgetIdx].data["data-grad-epercen"];

            newDataAttr = modifiedArray[currentActiveWidgetIdx].data;
          }
        }
      }

      // Copy opacity
      currentActiveInnerElem.style.opacity = copyStyle.opacity;

      if (!changeHeading) {
        let eightHandler =
          currentActiveWidget.current.dataset.assetType === PICTURE ||
          (currentActiveWidget.current.dataset.assetType === VIDEO &&
            [STOCK, BRAND, UPLOAD_VIDEO].includes(currentActiveWidget.current.dataset.source)) ||
          (currentActiveWidget.current.dataset.assetType === UPLOAD &&
            currentActiveWidget.current.dataset.fileType !== SVG);
        let TableWidget = currentActiveWidget.current.dataset.assetType === TABLE;
        let MapWidget = currentActiveWidget.current.dataset.assetType === MAP;

        newArray = Object.assign([...widgets], {
          [currentActiveWidgetIdx]: {
            ...widgets[currentActiveWidgetIdx],
            style: {
              ...widgets[currentActiveWidgetIdx].style,
              width:
                !currentActiveInnerElem.offsetWidth || eightHandler
                  ? widgets[currentActiveWidgetIdx].style.width
                  : TableWidget || MapWidget
                  ? window.getComputedStyle(currentActiveElem).width
                  : `${currentActiveInnerElem.offsetWidth}px`,
              height:
                !currentActiveInnerElem.offsetHeight || eightHandler
                  ? widgets[currentActiveWidgetIdx].style.height
                  : TableWidget || MapWidget
                  ? window.getComputedStyle(currentActiveElem).height
                  : `${currentActiveInnerElem.offsetHeight}px`,
            },
            data: removeGradColor
              ? { ...newDataAttr }
              : newDataAttr
              ? { ...widgets[currentActiveWidgetIdx].data, ...newDataAttr }
              : { ...widgets[currentActiveWidgetIdx].data },
            innerHTML: currentActiveElem.innerHTML,
          },
        });
      } else {
        newArray = Object.assign([...widgets], {
          [currentActiveWidgetIdx]: {
            ...widgets[currentActiveWidgetIdx],
            style: {
              ...widgets[currentActiveWidgetIdx].style,
              width: `${currentActiveInnerElem.offsetWidth}px`,
              height: `${currentActiveInnerElem.offsetHeight}px`,
            },
            data: removeGradColor
              ? { ...newDataAttr, "data-heading": headingValue }
              : newDataAttr
              ? { ...widgets[currentActiveWidgetIdx].data, ...newDataAttr, "data-heading": headingValue }
              : { ...widgets[currentActiveWidgetIdx].data, "data-heading": headingValue },
            innerHTML: !changeHeading ? currentActiveElem.innerHTML : node.outerHTML,
          },
        });
      }
      !changeLineThickness && updateWidgets(newArray);
      updateCopyStyle({ ...copyStyle, enable: false, lineThicknessFlag: changeLineThickness, changeList: changeList });
      setCopyStyleWidget(false);
    }
  }, [copyStyleWidget]);

  return setCopyStyleWidget;
};

export default useCopyStyleWidget;
