import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

import global from "../../scss/dhp.scss";

import { Icon as UiIcon } from "../../components/ui/icon";
import { generateTableObj, getSiblings } from "../../_helpers/utils";
import { fetchWidgetList } from "../../store/actions/editorActions";
import WidgetComponent from "../../components/Editor/Widgets/handleWidgetRedirection";
import Text from "../../components/Editor/Widgets/Text";
import AiWriter from "../../components/Editor/Widgets/AiWriter";
import AiTranslate from "../../components/Editor/Widgets/AiTranslate";
import TextFrame from "../../components/Editor/Widgets/TextFrame";
import Background from "../../components/Editor/Widgets/Background";
import Line from "../../components/Editor/Widgets/Line";
import Shape from "../../components/Editor/Widgets/Shape";
import Icon from "../../components/Editor/Widgets/Icon";
import Illustration from "../../components/Editor/Widgets/Illustration";
import Picture from "../../components/Editor/Widgets/Picture";
import Upload from "../../components/Editor/Widgets/Upload";
import Video from "../../components/Editor/Widgets/Video";
import PageTransition from "../../components/Editor/Widgets/PageTransition";
import PageMargin from "../../components/Editor/Widgets/PageMargin";
import Version from "../../components/Editor/Widgets/Version";
import Sticker from "../../components/Editor/Widgets/Sticker";
import Animation from "../../components/Editor/Widgets/Animation";
import Audio from "../../components/Editor/Widgets/Audio";
import Form from "../../components/Editor/Widgets/Form";
import QrCode from "../../components/Editor/Widgets/QrCode";
import * as constant from "../../constants/editor";
import { EditorContext } from "./EditorLayout";
import Tour from "../../components/User/Tour";
import { TR_EDITOR_STEP_1 } from "../../constants/user";
import TypeformSvg from "../../assets/images/Typeform-Symbol.svg";
import BrandKit from "../../components/Editor/Widgets/BrandKit";
import Theme from "../../components/Editor/Widgets/Theme";
import JotformSvg from "../../assets/images/Jotform-Symbol.svg";
import Collage from "../../components/Editor/Widgets/Collage";
import WidgetAnimation from "../../components/Editor/Widgets/WidgetAnimation";
import useHandleWidgetAction from "../../hooks/useHandleWidgetAction";
import Modal from "../../components/ui/modal";
import TableModalComponent from "../../components/Editor/Widgets/TableV2";
import Map from "../../components/Editor/Widgets/Map";
import PageResizeComponent from "../../components/Editor/PageResizeComponent";

let style = Object.assign({}, global);

export const EditorSidebarContext = React.createContext({});

const svgIconList = {
  typeforms: TypeformSvg,
  jotforms: JotformSvg,
};

const WidgetList = ({
  slug,
  icon,
  name,
  items,
  sidebarGroupToggleHandler,
  handleWidgetAction,
  index,
  availableWidgetLists,
  toggleModal,
  setTableConfig,
  toggleMapModal,
  setMapConfig,
  togglePageResizeModal,
}) => {
  let { documentType } = useContext(EditorContext);

  const userInfo = useSelector(state => state?.auth?.user);

  const filterItem =
    ![constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType) && userInfo.video_enabled
      ? items
      : items.filter(item => item.slug !== "audio");

  return (
    <li key={slug} className={style["template-group-item"]} id={index}>
      <Link to="#" onClick={e => sidebarGroupToggleHandler(e)}>
        <UiIcon icon={icon} />
        {name}
        <UiIcon additionalclass="ml-auto mr-0" icon="ui-arrow-right" />
      </Link>
      <ul className={cx(style["groupType"], style["widget-group"], style["clearfix"])} id={`widget-${index}`}>
        {filterItem.map(widgetitem => (
          <li key={widgetitem.name} className={cx(style["widget-group-item"], style["asset-" + widgetitem.slug])}>
            <Link
              to="#"
              onMouseDown={e => (
                e.preventDefault(),
                setTableConfig(),
                setMapConfig(),
                handleWidgetAction(
                  widgetitem.slug,
                  widgetitem.name,
                  widgetitem.asset_type,
                  availableWidgetLists.findIndex(
                    availableWidgetList => availableWidgetList.slug.toLowerCase() === slug.toLowerCase()
                  ),
                  false,
                  slug,
                  false,
                  toggleModal,
                  toggleMapModal,
                  togglePageResizeModal
                )
              )}>
              {constant.THIRD_PARTY_APPS.includes(widgetitem.asset_type) && (
                <img src={svgIconList[widgetitem.asset_type]} width="30" alt="" />
              )}
              {!constant.THIRD_PARTY_APPS.includes(widgetitem.asset_type) && (
                <UiIcon icon={widgetitem.icon.split("icon-")[1]} />
              )}

              <span className={style["widget-text"]}>{widgetitem.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};
//Props type validation
WidgetList.propTypes = {
  slug: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  sidebarGroupToggleHandler: PropTypes.func.isRequired,
  handleWidgetAction: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  availableWidgetLists: PropTypes.array,
};

const EditorSidebar = props => {
  let { documentType, templateGroupName, isDocumentReady, metadata, uploadState, setUploadState, widgets } =
    useContext(EditorContext);
    
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    handleWidgetAction,
    widgetActionClicked,
    returnFromAsset,
    activeAssetName,
    activeAssetSchemeType,
    activeAssetType,
    groupIndex,
  } = useHandleWidgetAction();

  const exceptRedirectionWidgets = ["Table", "PageSize", "Map"];
  const availableWidgetLists = useSelector(state => state?.editor?.availableWidgetLists);
  const isCompSuperAdmin = useSelector(state => state.auth?.user?.company?.superadmin_company);

  const [searchWidget, setSearchWidget] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalFullScreen, setModalFullScreen] = useState(false);
  const [tableConfig, setTableConfig] = useState();
  const [showMapModal, setShowMapModal] = useState(false);
  const [mapConfig, setMapConfig] = useState();
  const [showPageResizeModal, setShowPageResizeModal] = useState(false);
  const [activeColorThemePageIdx, setActiveColorThemePageIdx] = useState();

  const moduleSubTitle =
    templateGroupName === constant.TYPE_BUSINESS_ESSENTIALS ? documentType : templateGroupName.replace("-", " ");
  const components = {
    Text: Text,
    AiWriter: AiWriter,
    AiTranslate: AiTranslate,
    TextFrame: TextFrame,
    Background: Background,
    Line: Line,
    Shape: Shape,
    Icon: Icon,
    Sticker: Sticker,
    Illustration: Illustration,
    Picture: Picture,
    Upload: Upload,
    Video: Video,
    Animation: Animation,
    Audio: Audio,
    QrCode: QrCode,
    Collage: Collage,
    PageTransition: PageTransition,
    PageMargin: PageMargin,
    Version: Version,
    Typeform: Form,
    BrandKit: BrandKit,
    Theme: Theme,
    Jotform: Form,
    WidgetAnimation: WidgetAnimation,
  };

  let w_id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(w_id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(w_id).closest(".dhp-root-widget").getAttribute("id") : w_id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let widgetLockStatus = widgets[targetWidgetIndex]?.data["data-layer-locked"];

  // toggle menu function
  const sidebarGroupToggleHandler = event => {
    event.preventDefault();

    let closestSibiling = event.target.closest(".template-group-item");
    let siblings = getSiblings(closestSibiling);
    let dataObj = {
      documentId: id,
      index: closestSibiling.id,
    };

    Array.from(siblings).forEach(el => el.classList.remove("active"));
    closestSibiling.classList.toggle("active");
    sessionStorage.setItem("dataObj", JSON.stringify(dataObj));
  };

  // Toggle modal func
  const toggleModal = () => {
    setShowModal(prevState => !prevState);
  };

  // Toggle Map modal func
  const toggleMapModal = () => {
    setShowMapModal(prevState => !prevState);
  };

  // Toggle PageResize modal func
  const togglePageResizeModal = () => {
    setShowPageResizeModal(prevState => !prevState);
  };

  const handleBackgroundClick = () => {
    handleWidgetAction("background", "Background", "backgrounds", 1, false, "graphics-media");
  };

  const handleTransitionClick = () => {
    handleWidgetAction("page-transition", "Page Transition", "page_transition", 5, false, "page-setup");
  };

  const handleAnimationClick = animationSchemeType => {
    handleWidgetAction(
      "widget-animation",
      "Widget Animation",
      "widget_animation",
      1,
      false,
      "graphics-media",
      animationSchemeType
    );
  };

  const handleQrIconClick = () => {
    handleWidgetAction("qr-code", "QR Code", "qr_code", 1, false, "graphics-media");
  };

  const handleDoubleClick = () => {
    if (
      constant.TABLE === metadata.activeWidgetType[0] &&
      !document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group")
    ) {
      setTableConfig(generateTableObj(metadata.activeWidgetId[0]));
      toggleModal();
    }
    if (
      constant.MAP === metadata.activeWidgetType[0] &&
      !document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group")
    ) {
      setMapConfig(document.getElementById(metadata.activeWidgetId[0]));
      toggleMapModal();
    }
  };

  const handleOutsideAssetUpload = () => {
    handleWidgetAction("upload", "Upload", "uploads", 1, false, "graphics-media");
  };

  const handleVideoEditorPlayClick = () => {
    handleWidgetAction("*", false, false, 0, "returnToWidgetList");
  };

  useEffect(() => {
    // back to main left pain from widget animation if locked widget is active
    if (
      document.querySelector(".editor-asset-inner.widgetanimation-asset") &&
      metadata.activeWidgetId.length === 1 &&
      widgetLockStatus
    ) {
      handleWidgetAction("*", false, false, false, "returnToWidgetList");
    }
  }, [metadata.activeWidgetId, widgetLockStatus]);

  // redirect to sidebar from outside widgets click like background, qr, page transition, animation etc
  useEffect(() => {
    document.getElementById("toolbar_background")?.addEventListener("click", handleBackgroundClick);
    document
      .getElementById(`doc-timeline-transition-icon${metadata.activePageIdx}`)
      ?.addEventListener("click", handleTransitionClick);
    document
      .getElementById(`page-timeline-entrance-animation-icon-${metadata.activeWidgetId[0]}`)
      ?.addEventListener("click", e => {
        handleAnimationClick("entrance");
      });
    document
      .getElementById(`page-timeline-exit-animation-icon-${metadata.activeWidgetId[0]}`)
      ?.addEventListener("click", e => {
        handleAnimationClick("exit");
      });
    document.getElementById("qr-toolbar")?.addEventListener("click", handleQrIconClick);

    document.getElementById(metadata.activeWidgetId[0])?.addEventListener("dblclick", handleDoubleClick);
    document
      .getElementById(`page-top-transition-icon-${metadata.activePageIdx}`)
      ?.addEventListener("click", handleTransitionClick);
    document.querySelector(".page-timeline-player")?.addEventListener("click", handleVideoEditorPlayClick);
    document.querySelector(".document-timeline-player")?.addEventListener("click", handleVideoEditorPlayClick);
    document
      .querySelector(`#page-top-play-icon-${metadata.activePageIdx}`)
      ?.addEventListener("click", handleVideoEditorPlayClick);
    document.getElementById("animate-toolbar")?.addEventListener("click", e => {
      handleAnimationClick("entrance");
    });
    window?.addEventListener("click", e => {
      if (e.target.getAttribute("id") === "animate-toolbar" || e.target.closest("#animate-toolbar")) {
        handleAnimationClick("entrance");
      }
    });

    return () => {
      document.getElementById("toolbar_background")?.removeEventListener("click", handleBackgroundClick);
      document
        .getElementById(`doc-timeline-transition-icon${metadata.activePageIdx}`)
        ?.removeEventListener("click", handleTransitionClick);
      document.getElementById("qr-toolbar")?.removeEventListener("click", handleQrIconClick);
      document.getElementById(metadata.activeWidgetId[0])?.removeEventListener("dblclick", handleDoubleClick);
      document
        .getElementById(`page-top-transition-icon-${metadata.activePageIdx}`)
        ?.removeEventListener("click", handleTransitionClick);
      document.querySelector(".page-timeline-player")?.removeEventListener("click", handleVideoEditorPlayClick);
      document.querySelector(".document-timeline-player")?.addEventListener("click", handleVideoEditorPlayClick);
      document
        .querySelector(`#page-top-play-icon-${metadata.activePageIdx}`)
        ?.removeEventListener("click", handleVideoEditorPlayClick);
      document.getElementById("animate-toolbar")?.removeEventListener("click", e => {
        handleAnimationClick("entrance");
      });
      window?.removeEventListener("click", e => {
        handleAnimationClick("entrance");
      });
    };
  });

  //Redirects outside asset upload to sidebar
  useEffect(() => {
    if (uploadState?.isOutSideAssetUpload) {
      handleOutsideAssetUpload();
      setUploadState({ ...uploadState, isOutSideAssetUpload: false });
    }
  }, [uploadState?.isOutSideAssetUpload]);

  //onload selected group will open
  useEffect(() => {
    let dataObj,
      index = 0;

    if (sessionStorage.dataObj) {
      dataObj = JSON.parse(sessionStorage.getItem("dataObj"));

      if (dataObj.documentId !== id) {
        sessionStorage.removeItem("dataObj");
        dataObj = false;
      } else index = dataObj.index;
    }

    if (dataObj && dataObj.slug) {
      let i = availableWidgetLists?.find(availableWidgetList =>
        availableWidgetList.items.find(item => item.slug === dataObj.slug)
      );
      handleWidgetAction(dataObj.slug, dataObj.name, dataObj.type, dataObj.index, false, i?.slug);
    } else {
      Array.from(document.getElementsByClassName("template-group-item")).forEach(el => el.classList.remove("active"));
      document.getElementsByClassName("template-group-item")[index]?.classList.add("active");
    }
  }, []);

  // on search widget every time 1st group remain open
  useEffect(() => {
    if (searchedWidgetLists && searchWidget) {
      let dataObj = {
        documentId: id,
        index: 0,
      };
      Array.from(document.getElementsByClassName("template-group-item")).forEach(el => el.classList.remove("active"));
      document.getElementsByClassName("template-group-item")[0]?.classList.add("active");
      sessionStorage.setItem("dataObj", JSON.stringify(dataObj));
    }
  }, [searchWidget]);

  // Every time we back from any widget, respective parent group remain open
  useEffect(() => {
    if (returnFromAsset) {
      setSearchWidget("");

      var dataObj = {
        documentId: id,
        index: groupIndex,
      };

      sessionStorage.setItem("dataObj", JSON.stringify(dataObj));

      setTimeout(() => {
        document.getElementsByClassName("template-group-item")[groupIndex].classList.add("active");
      }, 10);
    }
  }, [widgetActionClicked]);

  // Fix company switch issue from uploads
  useEffect(() => {
    if (!availableWidgetLists) {
      dispatch(fetchWidgetList(isCompSuperAdmin));
    }
  }, [availableWidgetLists]);

  // Available widget list modification based on widget search result
  const searchedWidgetLists = availableWidgetLists
    ?.map(group => ({
      ...group,
      items: group?.items?.filter(
        child =>
          child.name.toLowerCase().includes(searchWidget.toLowerCase()) && !child?.excluded?.includes(documentType)
      ),
    }))
    .filter(group => group?.items?.length > 0);

  return (
    <EditorSidebarContext.Provider value={{ activeColorThemePageIdx, setActiveColorThemePageIdx }}>
      <div
        className={cx(
          style["dhp-secondAside"],
          { [style["pe-none"]]: !isDocumentReady },
          "disable-while-video-editor-playing"
        )}>
        <div className={style["module-title"]}>
          <h2 className={cx(style["fw-7"], style["m-0"])}>Documents</h2>
          <span className={style["moduleSub-title"]}>{moduleSubTitle}</span>
        </div>

        {(widgetActionClicked === "*" || exceptRedirectionWidgets.includes(widgetActionClicked)) && (
          <div className={style["editor-aside-content"]}>
            <div className={cx(style["search-field"], style["pb-3"])}>
              <div className={cx(style["search-area"], style["clearfix"])}>
                <span className={style["searchicon"]}>
                  <UiIcon icon="ui-search" />
                </span>
                <Input
                  type="text"
                  id="widgetSearch"
                  className={style["form-control"]}
                  placeholder="Search Widgets"
                  onChange={e => setSearchWidget(e.target.value)}
                  autoComplete="off" //need to discard this autocomplete once ui input field is marged
                />
              </div>
            </div>
            <div className={cx(style["customScroll"], style["scroll-Y"])}>
              <nav className={cx(style["template-groupNav"], style["pt-0"])}>
                <ul
                  className={cx(style["nav"], style["flex-column"], style["h-100"], style["editor-sidenav-initmenu"])}>
                  {searchedWidgetLists?.length > 0 &&
                    searchedWidgetLists?.map((searchedWidgetList, index) => (
                      <WidgetList
                        key={searchedWidgetList.slug}
                        slug={searchedWidgetList.slug}
                        icon={searchedWidgetList.icon.split("icon-")[1]}
                        name={searchedWidgetList.name}
                        items={searchedWidgetList.items}
                        sidebarGroupToggleHandler={sidebarGroupToggleHandler}
                        handleWidgetAction={handleWidgetAction}
                        index={index}
                        availableWidgetLists={availableWidgetLists}
                        toggleModal={toggleModal}
                        setTableConfig={setTableConfig}
                        toggleMapModal={toggleMapModal}
                        setMapConfig={setMapConfig}
                        togglePageResizeModal={togglePageResizeModal}
                      />
                    ))}
                  {searchedWidgetLists?.length === 0 && (
                    <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>
                      No results found
                    </div>
                  )}
                </ul>
              </nav>
              <Tour targetId="widget-0" tourType={TR_EDITOR_STEP_1} />
            </div>
          </div>
        )}
        {widgetActionClicked !== "*" && !exceptRedirectionWidgets.includes(widgetActionClicked) && (
          <WidgetComponent
            component={components[widgetActionClicked]}
            assetName={activeAssetName}
            assetType={activeAssetType}
            handleWidgetAction={handleWidgetAction}
            assetSchemeType={activeAssetSchemeType}
          />
        )}
      </div>

      {/* Modals */}
      {showModal && (
        <Modal
          size="xl"
          toggle={toggleModal}
          showModal={showModal}
          component={TableModalComponent}
          setShowModal={setShowModal}
          closeModal={toggleModal}
          backdrop="static"
          modalClassName={
            modalFullScreen ? "tabel-modal modal-with-leftpane full-screen-modal" : "tabel-modal modal-with-leftpane"
          }
          modalFullScreen={modalFullScreen}
          setModalFullScreen={setModalFullScreen}
          editTable={tableConfig}
        />
      )}

      {showMapModal && (
        <Modal
          size="xl"
          toggle={toggleMapModal}
          showModal={showMapModal}
          component={Map}
          setShowModal={setShowMapModal}
          closeModal={toggleMapModal}
          backdrop="static"
          modalClassName={
            modalFullScreen
              ? "tabel-modal modal-with-leftpane full-screen-modal map-modal"
              : "tabel-modal modal-with-leftpane map-modal"
          }
          modalFullScreen={modalFullScreen}
          setModalFullScreen={setModalFullScreen}
          editable={mapConfig}
        />
      )}

      {showPageResizeModal && (
        <PageResizeComponent showModal={showPageResizeModal} setShowModal={setShowPageResizeModal} />
      )}
    </EditorSidebarContext.Provider>
  );
};
//Props type validation
EditorSidebar.propTypes = {
  isOpen: PropTypes.bool,
};

export default EditorSidebar;
