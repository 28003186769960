import { useContext } from 'react'
import { calculateNewPositionOnRotatedObjectResize, getCssTransformObj } from '../_helpers/utils';
import { EditorContext } from '../containers/editor/EditorLayout';
import { TABLE } from '../constants/editor';
import useFind from './useFind';
import useGroupUngroup from './useGroupUngroup';
import { EditorWrapperContext } from '../containers/editor/EditorWrapper';

const useReplace = ({ state }) => {
    const { widgets, updateWidgets, metadata, updateMetadata} = useContext(EditorContext);
    const { showFindAndReplace, setShowFindAndReplace } = useContext(EditorWrapperContext);
    const { removeHeighlighter } = useFind({ state });
    const { adjustChildWidgetsForGroupRotation } = useGroupUngroup();

    const replaceHelper = {
        customSelectionRange: document.createRange(),
        widgetHandler: document.getElementById("dhp-widget-handler"),
        replaceCurrentMatch: () => {
            if (!state.findKey || !state.intFind || !state.allMatches) return;
            const wz = state.allMatches[state.activeIndex];
            if (wz) replaceHelper.replaceMatchingWidget({ wz });
        },
        generateUpdatedWidget: ({ widgets, targetWidgetIndex, height, width, transform, innerHTML, innerText, widgetId, isGroup }) => {
            let newArray;
            if (isGroup || innerText?.length > 0) {
                newArray = Object.assign([...widgets], {
                    [targetWidgetIndex]: {
                        ...widgets[targetWidgetIndex],
                        style: {
                            ...widgets[targetWidgetIndex]?.style,
                            height,
                            width,
                            transform,
                        },
                        innerHTML,
                    },
                });
            }
            else {
                newArray = Object.assign([...widgets]).filter(wz => wz.id !== widgetId);
                updateMetadata({ ...metadata, activeWidgetType: false, activeWidgetId: false })
                setShowFindAndReplace({ ...showFindAndReplace, curState: { ...showFindAndReplace.curState, widgetRef: false } })
            }
            return newArray;
        },
        replaceMatchingWidget: ({ wz, operatonMulti, updatedWidgets }) => {
            //Update new node element
            let id = operatonMulti ? operatonMulti.id : wz.widgetId;
            let activeWidget = document.getElementById(id);
            let isGroupWidget = activeWidget?.closest(".dhp-page-group");
            let targetId = isGroupWidget ? activeWidget.closest(".dhp-root-widget").getAttribute("id") : id;
            let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
            let assetType = wz.widgetMeta.widgetData.assetType;

            let widgetScale = 1;
            if (assetType === TABLE) {
                let {
                    scale: { x }
                } = getCssTransformObj({
                    transform: document.querySelector("#" + id + " .dhp-widget-inner").style.transform
                })
                widgetScale = parseFloat(x)
            }

            if (!isGroupWidget) {
                const prevHeight = assetType === TABLE ? document.querySelector("#" + id + " .dhp-widget-inner table").offsetHeight * widgetScale : document.querySelector("#" + id + " .dhp-widget-inner").offsetHeight;
                const prevWidth = assetType === TABLE ? document.querySelector("#" + id + " .dhp-widget-inner table").offsetWidth * widgetScale : document.querySelector("#" + id + " .dhp-widget-inner").offsetWidth;
                if (!operatonMulti) {
                    try {
                        replaceHelper.customSelectionRange.setStart(wz.startNode, wz.startOffset);
                        replaceHelper.customSelectionRange.setEnd(wz.endNode, wz.endOffset);
                        replaceHelper.customSelectionRange.deleteContents();
                        replaceHelper.customSelectionRange.insertNode(document.createTextNode(state.replaceKey));
                    }
                    catch { return }
                }
                else {
                    operatonMulti.matches.reverse().forEach(match => {
                        try {
                            replaceHelper.customSelectionRange.setStart(match.startNode, match.startOffset);
                            replaceHelper.customSelectionRange.setEnd(match.endNode, match.endOffset);
                            replaceHelper.customSelectionRange.deleteContents();
                            replaceHelper.customSelectionRange.insertNode(document.createTextNode(state.replaceKey));
                        }
                        catch { return }
                    })
                }
                const newHeight = `${assetType === TABLE ? document.querySelector("#" + id + " .dhp-widget-inner table").offsetHeight * widgetScale : document.querySelector("#" + id + " .dhp-widget-inner").offsetHeight}px`;
                const newWidth = `${assetType === TABLE ? document.querySelector("#" + id + " .dhp-widget-inner table").offsetWidth * widgetScale : document.querySelector("#" + id + " .dhp-widget-inner").offsetWidth}px`;
                wz.widgetMeta.widgetNode.style.height = newHeight;
                wz.widgetMeta.widgetNode.style.width = newWidth;
                const widgetTransform = activeWidget.style.transform
                const {
                    translate: { x: widgetTransX, y: widgetTransY },
                    rotate: { theta: widgetTheta },
                } = getCssTransformObj({
                    transform: widgetTransform,
                });

                const { left, top } = calculateNewPositionOnRotatedObjectResize(parseFloat(widgetTransX), parseFloat(widgetTransY), parseFloat(newWidth), parseFloat(newHeight), prevWidth, prevHeight, parseFloat(widgetTheta))
                const widgetTransformStr = getCssTransformObj({
                    translateX: `${left}px`,
                    translateY: `${top}px`,
                    transform: widgetTransform,
                    returnStr: true,
                });
                if (!operatonMulti) {
                    updateWidgets(replaceHelper.generateUpdatedWidget({ widgets, targetWidgetIndex, height: newHeight, width: newWidth, transform: widgetTransformStr, innerHTML: activeWidget.innerHTML, innerText: activeWidget.innerText, widgetId: id }));
                }
                else {
                    return replaceHelper.generateUpdatedWidget({ widgets: updatedWidgets, targetWidgetIndex, height: newHeight, width: newWidth, transform: widgetTransformStr, innerHTML: activeWidget.innerHTML, innerText: activeWidget.innerText, widgetId: id });
                }
            }
            else {
                if (!operatonMulti) {
                    try {
                        replaceHelper.customSelectionRange.setStart(wz.startNode, wz.startOffset);
                        replaceHelper.customSelectionRange.setEnd(wz.endNode, wz.endOffset);
                        replaceHelper.customSelectionRange.deleteContents();
                        replaceHelper.customSelectionRange.insertNode(document.createTextNode(state.replaceKey));
                    }
                    catch { return }
                }
                else {
                    operatonMulti.matches.reverse().forEach(match => {
                        try {
                            replaceHelper.customSelectionRange.setStart(match.startNode, match.startOffset);
                            replaceHelper.customSelectionRange.setEnd(match.endNode, match.endOffset);
                            replaceHelper.customSelectionRange.deleteContents();
                            replaceHelper.customSelectionRange.insertNode(document.createTextNode(state.replaceKey));
                        }
                        catch { return }
                    })
                }
                let { widgetStyle: groupWidgetNewStyle, newHtml, newStyle } = replaceHelper.getGroupUpdatedDiaAndPosition(wz);
                // update DOM and group handler
                wz.widgetMeta.parentNode.style.height = groupWidgetNewStyle.height;
                wz.widgetMeta.parentNode.style.width = groupWidgetNewStyle.width;
                wz.widgetMeta.parentNode.style.transform = groupWidgetNewStyle.transform;
                if (!operatonMulti) {
                    // update handler
                    replaceHelper.widgetHandler.style.height = groupWidgetNewStyle.handlerHeight;
                    replaceHelper.widgetHandler.style.width = groupWidgetNewStyle.handlerWidth;
                    replaceHelper.widgetHandler.style.transform = groupWidgetNewStyle.handlerTransform;

                    updateWidgets(replaceHelper.generateUpdatedWidget({ widgets, targetWidgetIndex, height: groupWidgetNewStyle.height, width: groupWidgetNewStyle.width, transform: groupWidgetNewStyle.transform, innerHTML: newHtml, isGroup: true }));
                }
                else {
                    replaceHelper.updateAllChildWithNewPositionAndDia(targetId, newStyle)
                    return replaceHelper.generateUpdatedWidget({ widgets: updatedWidgets, targetWidgetIndex, height: groupWidgetNewStyle.height, width: groupWidgetNewStyle.width, transform: groupWidgetNewStyle.transform, innerHTML: document.getElementById(targetId).innerHTML, isGroup: true });
                }
            }
        },

        updateAllChildWithNewPositionAndDia: (groupId, newStyle) => {
            document.querySelectorAll(`#${groupId} .dhp-page-widget`).forEach(child => {
                let childId = child.getAttribute("id");
                let sandBoxChild = newStyle.find(rec => rec.id === childId);
                child.style.height = sandBoxChild.height;
                child.style.width = sandBoxChild.width;
                child.style.left = sandBoxChild.left;
                child.style.top = sandBoxChild.top;
            })
        },

        getGroupUpdatedDiaAndPosition: (wz) => {
            const { handlerSelection, groupSelection, newHtml, newStyle } = adjustChildWidgetsForGroupRotation(wz.widgetMeta.parentNode);
            let widgetStyle = {
                handlerTransform: handlerSelection.transform,
                handlerWidth: handlerSelection.width,
                handlerHeight: handlerSelection.height,
                handlerLeft: handlerSelection.left,
                handlerTop: handlerSelection.top,
                transform: groupSelection.transform,
                width: groupSelection.width,
                height: groupSelection.height,
                left: groupSelection.left,
                top: groupSelection.top
            };

            return { widgetStyle, newHtml, newStyle };
        },

        replaceAllMatches: () => {
            removeHeighlighter();
            let updatedWidgets = Object.assign([...widgets])
            state.widgetMatchesById.forEach((matchRec) => {
                if (!matchRec?.isLocked)
                    updatedWidgets = Object.assign([...replaceHelper.replaceMatchingWidget({ wz: matchRec.matches[0], operatonMulti: matchRec, updatedWidgets })])
            })
            updateWidgets(updatedWidgets);
        }
    }

    return { replaceCurrentMatch: replaceHelper.replaceCurrentMatch, replaceAllMatches: replaceHelper.replaceAllMatches }
}

export default useReplace