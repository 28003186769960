import React, { useContext, useEffect, useState } from "react";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import WidgetOpacity from "./WidgetOpacity";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { VIDEO } from "../../../constants/editor";
import { EditorPanelContext } from "../../../containers/editor/EditorPanel";

let style = Object.assign({}, global);

const Opacity = props => {
  let { metadata } = useContext(EditorContext);
  const { updateTogglePlayButton } = useContext(EditorPanelContext);

  const [isOpacityApplied, setIsOpacityApplied] = useState();

  const setDropdownMenu = () => {
    if (props.more) props?.setmoreSecondaryOptionClicked("Opacity");
    if (metadata.activeWidgetType[0] === VIDEO) updateTogglePlayButton(false); // stop video on opacity click
  };

  // check opacity is applied or not on active widget change
  useEffect(() => {
    let opacity = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.opacity;
    if (opacity < 1) setIsOpacityApplied(true);
    else setIsOpacityApplied(false);
  }, [metadata.activeWidgetId[0]]);

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: isOpacityApplied,
      })}>
      <UncontrolledDropdown className={cx(style["toolset-item"], style["opacity-wrap"])}>
        <DropdownToggle
          tag="span"
          onClick={setDropdownMenu}
          className={cx(style["toolset-action"], style["custom-tooltip"])}>
          <Icon icon="ui-opacity" />

          {!props.more && <div className={cx(style["custom-tooltip-content"], style["top"])}>Opacity</div>}

          {props.more && "Opacity"}
        </DropdownToggle>

        {!props.more && <WidgetOpacity setIsOpacityApplied={setIsOpacityApplied} isFromMore={props.more} />}
      </UncontrolledDropdown>
    </li>
  );
};
Opacity.propTypes = {
  more: PropTypes.bool,
  setmoreSecondaryOptionClicked: PropTypes.func,
};

export default Opacity;
