import cx from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import global from "../../scss/dhp.scss";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Form, Media, UncontrolledDropdown } from "reactstrap";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import {
  applyLike,
  deleteComment,
  fetchDocumentComments,
  insertComment,
  updateComment,
} from "../../store/actions/documentActions";
import Modal from "../ui/modal";
import DeleteElemModal from "./Modals/DeleteElemModal";
import { Icon } from "../ui/icon";
import avatar from "../../assets/images/ui-user.svg";
import { Input } from "../ui/input";
import { DataFormatter } from "../ui/dataFormatter";
import { getUserList } from "../../store/actions/companyActions";
import { COMPANY_SUPERADMIN } from "../../constants/company";
import { useContextualUpgrade } from "../../hooks/useContextualUpgrade";
import { EditorContext } from "../../containers/editor/EditorLayout";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import _ from "lodash";

const style = Object.assign({}, global);

// Add comment
const AddComment = ({ actionType, msg, onAdd: submitComment, ...props }) => {
  const actionTypes = ["edit-reply", "edit-comment"];

  const [comment, setComment] = useState(actionTypes.includes(actionType) ? msg : "");
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const textareaRef = useRef(null);

  const activeUser = useSelector(state => state?.auth?.user);

  const onSubmit = e => {
    e.preventDefault();
    let commentId = props?.commentId;
    if (actionType !== "comment") props.cancelAction();
    submitComment({ comment, commentId });
    setComment("");
    setSubmitEnabled(!submitEnabled);
  };

  const cancelSubmit = () => {
    if (actionType !== "comment") props.cancelAction();
    setComment("");
    setSubmitEnabled(!submitEnabled);
  };

  const validateComment = c => {
    let trimmedComment = c.trim();
    trimmedComment !== "" ? setComment(c) : setComment("");
    if (actionType.startsWith("edit")) setSubmitEnabled(!(c !== msg && trimmedComment.length > 0));
    else setSubmitEnabled(!trimmedComment);
  };

  const handleEmojiSelect = (emoji) => {
    if (textareaRef.current) {
      const cursorPosition = textareaRef.current.selectionStart;
      const textBeforeCursor = comment.substring(0, cursorPosition);
      const textAfterCursor = comment.substring(cursorPosition);
      const updatedComment = textBeforeCursor + emoji.native + textAfterCursor;

      const trimmedComment = updatedComment.trim();
      setComment(trimmedComment);
  
      // Validation
      validateComment(trimmedComment);
  
      // for cursor position after the inserted emoji
      const newCursorPosition = cursorPosition + emoji.native.length;
  
      // Update the textarea value and set selection
      textareaRef.current.value = trimmedComment;
      textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
  
      textareaRef.current.focus();
  
      setShowEmojiPicker(false);
    }
  };
  
  useOnClickOutside(emojiPickerRef, () => {
    setShowEmojiPicker(false);
  });

  return (
    <Media className={cx(style["right-side"], style["flex-fill"])}>
      {(actionType === "reply" || actionType === "comment") && (
        <Media left>
          <figure className={style["rounded-circle"]}>
            <Media
              object
              className={cx({ [style["default"]]: !activeUser?.image })}
              src={activeUser?.image || avatar}
            />
          </figure>
        </Media>
      )}
      <Media body>
        <Form
          name={`${actionType}Form`}
          className={cx(style["d-flex"], style["flex-column"], { [style["mb-3"]]: actionType.startsWith("edit") })}
          onSubmit={onSubmit}>
          <Input
            innerRef={textareaRef}
            type="textarea"
            name="comment"
            value={comment}
            onChange={e => validateComment(e.currentTarget.value)}
            className={cx({ [style["reply-textarea"]]: actionType === "reply" })}
            placeholder={actionType.endsWith("reply") ? "Reply here" : "Add a comment"}
            returnType="noGroup"
            autoFocus
          />
          <div className={cx(style["d-flex"], style["flex-row-reverse"], style["align-items-center"])}>
            <Button type="submit" className={cx(style["ml-2"])} color="primary" disabled={submitEnabled}>
              {actionType === "reply" && "Reply"}
              {actionType === "comment" && "Add"}
              {(actionType === "edit-reply" || actionType === "edit-comment") && "Update"}
            </Button>
            {(comment || (!comment && actionType !== "comment")) && (
                <Button
                  type="reset"
                  className={cx(style["btn-border"], style["ml-2"])}
                  outline
                  color="secondary"
                  onClick={() => cancelSubmit()}>
                  Cancel
                </Button>
            )}
            <div ref={emojiPickerRef}>
                  <span className={cx(style["d-flex"], style["cursor-pointer"])} onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                    <Icon icon="ui-smile" />
                  </span>
                  {showEmojiPicker &&
                    <div className={cx(style["shadow"], style["rounded-md"], style["emoji-box"])}>
                      <Picker data={data} onEmojiSelect={handleEmojiSelect} emojiSize={24} emojiButtonSize={30} theme={"light"} previewPosition={"none"} maxFrequentRows={1} perLine={9} />
                    </div>
                  }
                </div>
          </div>
        </Form>
      </Media>
    </Media>
  );
};

//all comments
const ShowComment = ({ comments, activeUser, ...props }) => {
  const dispatch = useDispatch();
  const userList = useSelector(state => state.company?.users);
  const userInfo = useSelector(state => state.auth?.user);
  let userRef = useRef();

  const companyUsers = {};
  let isSuperUser = false;
  userList?.forEach(user => {
    companyUsers[user.email] = user?.image;
    if (activeUser?.email === user.email) isSuperUser = user?.superuser;
  });

  useEffect(() => {
    if (userRef?.current && !_.isEqual(userRef.current, userInfo))
      dispatch(getUserList());
    userRef.current = userInfo;
  }, [userInfo]);

  useEffect(() => {
    if (userList?.length <= 0) dispatch(getUserList());
  }, [userList]);

  return (
    <Media list className={cx(style["customScroll"], style["scroll-Y"])}>
      {comments?.map((comment, index) => (
        <Media tag="li" key={index}>
          <Media left>
            <figure className={style["rounded-circle"]}>
              <Media
                object
                className={cx({ [style["default"]]: !companyUsers[comment?.owner?.email] })}
                src={companyUsers[comment?.owner?.email] || avatar}
              />
            </figure>
          </Media>
          <Media body>
            <Comment
              {...props}
              comment={comment}
              actionType="comment"
              isSuperUser={isSuperUser}
              activeUser={activeUser}
            />
            {comment.replies?.map((replyInfo, i) => (
              <Comment
                {...props}
                comment={replyInfo}
                actionType="reply"
                key={i}
                companyUsers={companyUsers}
                isSuperUser={isSuperUser}
                activeUser={activeUser}
              />
            ))}
          </Media>
        </Media>
      ))}
    </Media>
  );
};

// each comment
const Comment = ({ actionType, comment, companyUsers, isSuperUser, activeUser, documentOwner, ...props }) => {
  const [addReply, setAddReply] = useState(false);
  const [showUpdateReply, setShowUpdateReply] = useState(false);

  const toggleReply = () => {
    setAddReply(!addReply);
  };

  const toggleUpdateSection = () => {
    setShowUpdateReply(!showUpdateReply);
  };

  const applyLikeAction = (id, like) => {
    props.applyLike(id, like);
  };

  const toggleDeleteModal = commentId => {
    props.toggle();
    props.setDeleteRecId(commentId);
  };

  return (
    <Media>
      {actionType === "reply" && (
        <Media left>
          <figure className={style["rounded-circle"]}>
            <Media
              object
              className={cx({ [style["default"]]: !companyUsers[comment?.owner?.email] })}
              src={companyUsers[comment?.owner?.email] || avatar}
            />
          </figure>
        </Media>
      )}
      <Media body className={cx(style["posted-comment"], style["mt-2"], style["flex-fill"])}>
        {!showUpdateReply && (
          <UncontrolledDropdown direction="right">
            {(comment.owner.email === activeUser?.email ||
              isSuperUser ||
              documentOwner === "OWNER") && (
                <DropdownToggle tag="a" className={cx(style["posted-control"], style["dropdown-toggle"])} />
              )}
            <DropdownMenu
              className={cx(
                style["shadow-sm"],
                style["border-0"],
                style["rounded"],
                style["mr-2"],
                style["posted-control-menu"]
              )}>
              {comment.owner.email === activeUser?.email && (
                <DropdownItem tag="a" onClick={() => toggleUpdateSection()}>
                  <Icon icon="ui-edit" /> Edit
                </DropdownItem>
              )}
              {(comment.owner.email === activeUser?.email ||
                isSuperUser ||
                documentOwner === "OWNER") && (
                  <DropdownItem tag="a" onClick={() => toggleDeleteModal(comment?.id)}>
                    <Icon icon="ui-trash" /> Delete
                  </DropdownItem>
                )}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        <Media heading tag="h6" className={cx(style["fw-6"], style["pr-5"])}>
          {comment?.owner?.firstname} {comment?.owner?.lastname}
        </Media>
        {!showUpdateReply && (
          <React.Fragment>
            <p className={cx(style["comment-description"], style["mb-1"], style["pr-4"])}>{comment?.description}</p>
            <div className={style["comment-footer"]}>
              <span className={style["posted"]}>
                <DataFormatter type="date-time" slug="created_on" format="default" data={comment} />
              </span>
              {actionType !== "reply" && (
                <span className={style["link"]} onClick={() => setAddReply(!addReply)}>
                  Reply
                </span>
              )}
              <span className={style["link"]} onClick={() => applyLikeAction(comment?.id, !comment?.like)}>
                {comment.like ? "Liked" : "Like"}
              </span>
              {comment?.like_count > 0 && (
                <React.Fragment>
                  <span className={style["like"]}>
                    <Icon icon="thumb-fill" />
                  </span>
                  <span className={style["comment-reply-like-count"]}>{comment?.like_count}</span>
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        )}
        {showUpdateReply && (
          <AddComment
            actionType={`edit-${actionType}`}
            cancelAction={toggleUpdateSection}
            onAdd={props?.onUpdate}
            commentId={comment?.id}
            msg={comment?.description}
          />
        )}
        {addReply && (
          <div className={cx(style["d-flex"], style["mb-3"])}>
            <AddComment actionType="reply" cancelAction={toggleReply} onAdd={props?.onAdd} commentId={comment?.id} />
          </div>
        )}
      </Media>
    </Media>
  );
};

// Entire Comment Section
const DocumentComments = () => {
  const dispatch = useDispatch();
  const { id: documentId } = useParams();
  let { pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType } = useContext(EditorContext);
  const modalData = {
    modalTitle: "Delete this Comment",
    modalBody: "Are you sure to delete this comment? This action can't be reversed.",
    modalButtonLabel: "Delete",
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteRecId, setDeleteRecId] = useState();
  const [comments, setComments] = useState([]);
  const [fetchComments, setFetchComments] = useState(false);

  const { user: activeUser, company: companyInfo } = useSelector(state => state.auth);

  const {
    comments: cmt,
    newCommentReply,
    updateCommentReply,
    deleteCommentReply,
    likeCommentReply,
    documentDetails,
  } = useSelector(state => state?.document);

  // useContextualUpgrade Hook to show update modal
  const showUpgrade = useContextualUpgrade();

  let totalComments = 0;
  comments.forEach(comment => (totalComments += 1 + (comment?.replies?.length ?? 0)));

  const toggle = () => {
    if (activeUser?.role === COMPANY_SUPERADMIN && !companyInfo?.plan_id) {
      // Call showUpgrade function to show contexttual upgrade modal based on your condition
      showUpgrade("documentCmnt", { docPagesMeta: { pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType } });
    } else {
      setDropdownOpen(prevState => !prevState);
      setFetchComments(!dropdownOpen)
    }
  };

  const closeModal = () => {
    setDropdownOpen(!dropdownOpen);
    setShowModal(prevState => !prevState);
  };

  const toggleModal = () => {
    setShowModal(prevState => !prevState);
  };

  const createComment = d => {
    dispatch(insertComment(documentId, d));
  };

  const createReply = d => {
    dispatch(insertComment(documentId, { comment_id: d.commentId, comment: d.comment }));
  };

  const modifyComment = data => {
    dispatch(updateComment(data));
  };

  const createLike = d => {
    dispatch(applyLike(d));
  };

  const removeComment = () => {
    setDropdownOpen(!dropdownOpen);
    dispatch(deleteComment(deleteRecId));
  };

  const createData = newCommentReply => {
    let { commentId, comment } = newCommentReply;
    if (commentId) {
      let updatedComments = comments.map(c => {
        if (c.id === commentId) {
          let rply = c.replies || [];
          return { ...c, replies: [...rply, comment] };
        } else return c;
      });
      setComments([...updatedComments]);
    } else {
      setComments([comment, ...comments]);
    }
  };

  const updateData = (commentId, data) => {
    let isCmt = comments.findIndex(c => c.id === commentId);
    if (isCmt > -1) {
      setComments([...comments.slice(0, isCmt), { ...comments[isCmt], ...data }, ...comments.slice(isCmt + 1)]);
    } else {
      let updatedComments = comments.map(c => {
        let replies = c.replies || [];
        return {
          ...c,
          replies: replies.map(r => {
            if (r.id === commentId) {
              return { ...r, ...data };
            } else return r;
          }),
        };
      });
      setComments([...updatedComments]);
    }
  };

  const deleteData = () => {
    let { commentId } = deleteCommentReply;
    let filteredComments = comments
      .filter(c => c.id !== commentId)
      .map(element => {
        let newElt = { ...element };
        newElt.replies = newElt.replies?.filter(r => r.id !== commentId);
        return newElt;
      });
    setComments([...filteredComments]);
  };

  useEffect(() => {
    dispatch(fetchDocumentComments(documentId));
  }, []);

  useEffect(() => {
    if (fetchComments) dispatch(fetchDocumentComments(documentId));
  }, [fetchComments]);

  useEffect(() => {
    if (cmt) setComments(cmt);
  }, [cmt]);

  useEffect(() => {
    if (newCommentReply?.comment) {
      createData(newCommentReply);
    }
  }, [newCommentReply]);

  useEffect(() => {
    if (updateCommentReply?.commentId) {
      let { commentId, comment } = updateCommentReply;
      let data = { comment_name: comment, description: comment };
      updateData(commentId, data);
    }
  }, [updateCommentReply]);

  useEffect(() => {
    if (likeCommentReply?.commentId) {
      let { liked, count, commentId } = likeCommentReply;
      let data = { like_count: count, like: liked };
      updateData(commentId, data);
    }
  }, [likeCommentReply]);

  useEffect(() => {
    if (deleteCommentReply?.commentId) {
      deleteData(deleteCommentReply);
    }
  }, [deleteCommentReply]);

  return (
    <React.Fragment>
      <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle tag="a" className={cx(style["custom-tooltip"], style["comment-trigger"])} caret>
          <Icon icon="ui-comments" />
          {/* <span className={cx(style["d-none"], style["d-xl-block"])}>Comments</span> */}
          {totalComments > 0 && <div className={style["comment-alert-bg"]}>{totalComments}</div>}
          <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Comments</div>
        </DropdownToggle>
        <DropdownMenu className={cx(style["comment-drop"], style["shadow"], style["border-0"], style["rounded-md"])}>
          <div className={style["primary-comment-wrap"]}>
            <AddComment actionType="comment" onAdd={createComment} />
          </div>
          {comments && (
            <>
              <ShowComment
                comments={comments}
                onAdd={createReply}
                onUpdate={modifyComment}
                applyLike={createLike}
                closeModal={toggleModal}
                toggle={toggleModal}
                setDeleteRecId={setDeleteRecId}
                userIcon={activeUser?.image || avatar}
                activeUser={activeUser}
                documentOwner={documentDetails?.data?.permission}
              />
            </>
          )}
        </DropdownMenu>
        {showModal && (
          <Modal
            toggle={toggleModal}
            showModal={showModal}
            component={DeleteElemModal}
            setShowModal={setShowModal}
            closeModal={closeModal}
            {...modalData}
            deleteElem={removeComment}
          />
        )}
      </UncontrolledDropdown>
    </React.Fragment>
  );
};

export default DocumentComments;

ShowComment.propTypes = {
  comments: PropTypes.array.isRequired,
  activeUser: PropTypes.object,
};

AddComment.propTypes = {
  commentId: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  cancelAction: PropTypes.func,
  actionType: PropTypes.string.isRequired,
  msg: PropTypes.string,
};

Comment.propTypes = {
  applyLike: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  setDeleteRecId: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  companyUsers: PropTypes.object,
  isSuperUser: PropTypes.bool,
  activeUser: PropTypes.object,
  documentOwner: PropTypes.string.isRequired,
};
