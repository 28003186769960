import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import global from "../../scss/dhp.scss";

import EditorTitlebar from "./EditorTitlebar";
import EditorToolbar from "./EditorToolbar";
import { EditorWrapperContext } from "./EditorWrapper";

let style = Object.assign({}, global);

const EditorTopbar = props => {
  const { playingPageIdx } = useContext(EditorWrapperContext);

  return (
    <div className={cx(style["editor-topbar"], { [style["disable"]]: playingPageIdx !== null })}>
      <EditorTitlebar />
      <EditorToolbar toggle={props.toggle} />
    </div>
  );
};

EditorTopbar.propTypes = {
  toggle: PropTypes.func,
};

export default EditorTopbar;
