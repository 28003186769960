import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import Paste from "./Paste";
import Cut from "./Cut";
import Copy from "./Copy";
import Clone from "./Clone";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { useSelector } from "react-redux";
import useClipboard from "../../../hooks/useClipboard";
import { EditorCanvasPanelContext } from "../../../containers/editor/EditorCanvasPanel";

let style = Object.assign({}, global);

const ClipBoard = () => {
  let { clipboardObj } = useContext(EditorCanvasPanelContext);

  const [isClipBoardExist, setIsClipBoardExist] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const companyInfo = useSelector(state => state?.auth?.company);

  const { read: readFromClipboard } = useClipboard();

  const checkClipBoardData = async () => {
    const { dataSource, dataType, data, isValidWidget } = await readFromClipboard();

    setIsClipBoardExist(
      isValidWidget &&
        ((dataSource === "dhp" && data?.companyId === companyInfo?._id) ||
          (dataSource === "non_dhp" && dataType === "image/png"))
    );
  };

  useEffect(() => {
    checkClipBoardData();
  }, [clipboardObj]);

  return (
    <li className={style["toolset-group"]}>
      <div
        className={cx(style["toolset-item"], style["clipboard-wrap"])}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])}>
          <Icon icon="ui-clipboard" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Clipboard</div>
        </span>

        <ul
          className={cx(
            style["toolbar-dropdown"],
            style["rounded"],
            isClipBoardExist ? style["four-item"] : style["three-item"]
          )}>
          {isClipBoardExist && isHover && <Paste />}
          <Cut />
          <Copy />
          <Clone />
        </ul>
      </div>
    </li>
  );
};

export default ClipBoard;
