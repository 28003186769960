import React from "react";
import PropTypes from "prop-types";

const WidgetComponent = props => {
  return (
    <props.component
      handleWidgetAction={props.handleWidgetAction}
      assetName={props.assetName}
      assetType={props.assetType}
      assetSchemeType={props.assetSchemeType}
    />
  );
};

WidgetComponent.propTypes = {
  component: PropTypes.func.isRequired,
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string,
  assetSchemeType: PropTypes.string,
};
export default WidgetComponent;
