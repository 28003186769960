import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import AssetSchemeTypeNav from "./Common/AssetSchemeTypeNav";
import AssetCategory from "./Common/AssetCategory";
import AssetLists from "./Common/AssetLists";
import { Icon } from "../../ui/icon";
import useThemeColor from "../../../hooks/useThemeColor";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import BrandSetup from "./BrandSetup";
import BrandCompanyList from "./BrandCompanyList";
import { AppContext } from "../../../contexts";
import BrandkitColours from "./BrandkitColours";
import useFitToScreen from "../../../hooks/useFitToScreen";

let style = Object.assign({}, global);

const Theme = props => {
  let timeOut;
  let { pageNodes, setIsTimeLineViewOpen, isTimeLineViewOpen } = useContext(EditorContext);
  let { brandInfo } = useContext(AppContext);

  const themeSchemes = ["Preset", "Brand"];

  const [schemeType, setSchemeType] = useState("Preset");
  const [category, setCategory] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isAssetFetched, setIsAssetFetched] = useState(false);
  const [activeThemeIndex, setActiveThemeIndex] = useState();
  const [isShownAppliedSuccessfully, setIsShownAppliedSuccessfully] = useState();
  const [isBrandkit, setIsBrandkit] = useState();
  const [isFetchDone, setIsFetchDone] = useState(false);
  const [availableAssetCategories, setAvailableAssetCategories] = useState([]);
  const [asstesLoading, setAsstesLoading] = useState();

  const { changeColorTheme } = useThemeColor();
  const { fitOnScreenMethod } = useFitToScreen();

  const disableMessageAfterSomeSec = () => {
    timeOut = setTimeout(() => {
      setIsShownAppliedSuccessfully(false);
    }, 2000);
  };

  useEffect(() => {
    if (brandInfo?.brands.length >= 0) setIsFetchDone(true);
    if (brandInfo?.brands.length > 0) setIsBrandkit(true);
  }, [brandInfo]);

  useEffect(() => {
    if (isShownAppliedSuccessfully) {
      disableMessageAfterSomeSec();
    }
  }, [isShownAppliedSuccessfully]);

  useEffect(() => {
    setActiveThemeIndex();
    setIsShownAppliedSuccessfully();
  }, [schemeType]);

  useEffect(() => {
    return () => {
      setActiveThemeIndex(false);
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <div
      className={cx(style["editor-asset-inner"], style["theme-asset"], {
        [style["visible-applied-button"]]:
          pageNodes.length > 1 && (activeThemeIndex || activeThemeIndex === 0 || isShownAppliedSuccessfully),
      })}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />

      <div className={style["pr-20"]}>
        <AssetSchemeTypeNav
          schemeTypeArray={themeSchemes}
          setSchemeType={setSchemeType}
          schemeType={schemeType}
          setPageNumber={setPageNumber}
          setIsAssetFetched={setIsAssetFetched}
          assetType={props.assetType}
          setCategory={setCategory}
        />

        {schemeType === "Preset" && (
          <AssetCategory
            category={category}
            setCategory={setCategory}
            setPageNumber={setPageNumber}
            setIsAssetFetched={setIsAssetFetched}
            availableAssetCategories={availableAssetCategories}
            asstesLoading={asstesLoading}
          />
        )}
      </div>

      {schemeType === "Preset" && (
        <>
          <AssetLists
            assetName={props.assetName}
            assetType={props.assetType}
            assetCategory={category}
            assetSchemeType={schemeType}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            isAssetFetched={isAssetFetched}
            setIsAssetFetched={setIsAssetFetched}
            activeThemeIndex={activeThemeIndex}
            setActiveThemeIndex={setActiveThemeIndex}
            setAvailableAssetCategories={setAvailableAssetCategories}
            asstesLoading={asstesLoading}
            setAsstesLoading={setAsstesLoading}
          />

          {pageNodes.length > 1 && (activeThemeIndex || activeThemeIndex === 0 || isShownAppliedSuccessfully) && (
            <div className={cx(style["mt-auto"], style["text-center"], style["fixed-bottom"])}>
              {isShownAppliedSuccessfully && (
                <span className={cx(style["btn"], style["btn-border"])}>
                  <Icon icon="tick-success" /> Applied Successfully
                </span>
              )}

              {(activeThemeIndex || activeThemeIndex === 0) && (
                <span
                  className={cx(style["btn"], style["btn-border"])}
                  onClick={() => {
                    setIsTimeLineViewOpen(false),
                      changeColorTheme(),
                      setActiveThemeIndex(false),
                      setIsShownAppliedSuccessfully(true);
                    setTimeout(() => {
                      if (isTimeLineViewOpen) fitOnScreenMethod(false, false, false);
                    }, 100);
                  }}>
                  Apply to All Pages
                </span>
              )}
            </div>
          )}
        </>
      )}

      {schemeType === "Brand" && (
        <>
          {isFetchDone && !isBrandkit && (
            <BrandSetup
              icon="ui-brand-color"
              heading="Use Brand Color Themes"
              content="Keep your design consistent
              with your brand colors."
              buttonLabel="Set up Brand Colors"
            />
          )}

          {isFetchDone && isBrandkit && (
            <>
              <div className={cx(style["pr-20"], style["assets-wrapper"])}>
                <BrandCompanyList />
                <BrandkitColours />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

//Props type validation
Theme.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default Theme;
