import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EditorContext } from "../containers/editor/EditorLayout";
import { useSocket } from "./useSocket";
import { DOCUMENT_SAVE, CONN_DOC_SAVE } from "../constants/socket";
import { AppContext } from "../contexts";
import {
  ANIMATION,
  DOCUMENT_SAVE_TIME_COUNT,
  GENERATE_SCREENSHOT_TIME_COUNT,
  GENERATE_VERSION_TIME_COUNT,
  PAGE_TRANSITION,
  TABLE,
  TEXT,
  UPLOAD,
} from "../constants/editor";
import { documentMetadata } from "../components/Document/_config";
import { widgetConfig } from "../components/Editor/editor_config";
import { createDocumentThumb, createDocumentVersion } from "../store/actions/documentActions";
import { RGBToHex, durationInSec } from "../_helpers/utils";
import { EditorPanelContext } from "../containers/editor/EditorPanel";
import { EditorWrapperContext } from "../containers/editor/EditorWrapper";

const speedObj = {};
widgetConfig[PAGE_TRANSITION]?.speeds?.forEach(elm => {
  speedObj[elm.name] = elm.value;
});

const useEditorDocumentProcessing = pageSorterList => {
  const {
    blockNodes,
    pageNodes,
    backgroundColors,
    backgroundImages,
    widgets,
    documentType,
    dimension,
    pageMargin,
    templateGroupName,
    isDocumentReady,
    audios,
    grid,
  } = useContext(EditorContext);
  const { isDocumentSave, setIsDocumentSave } = useContext(EditorPanelContext);
  const { changeBySocket } = useContext(EditorWrapperContext);

  const {
    setIsSaveInProgress,
    isSaveInProgress,
    restartSave,
    setRestartSave,
    isStopSaveProgress,
    setIsStopSaveProgress,
  } = useContext(AppContext);
  const dispatch = useDispatch();

  // Socket Connection for save document
  const saveDocSocket = useSocket(CONN_DOC_SAVE);

  const companyId = useSelector(state => state.auth?.user?.company?.id);
  const {
    id: documentId,
    template_info: { type: templateType, type_id: template_type_id } = {},
    version: versionData,
  } = useSelector(state => state.document?.documentDetails?.data) ?? {
    id: null,
    template_info: { type: null, type_id: null },
    version: null,
  };

  const [generateScreenshot, setGenerateScreenshot] = useState(false);
  const [savingStart, setSavingStart] = useState(false);
  const [isWaitSave, setIsWaitSave] = useState(false);
  const [isFirstLoadDone, setisFirstLoadDone] = useState(versionData?.rawdata?.contexts ? false : true);
  const [changesMade, setChangesMade] = useState(false);

  const generateVersion = useCallback(() => {
    // Stop document create version if it's from page sorter
    if (pageSorterList) return;

    dispatch(createDocumentVersion(documentId));
  }, []);

  const canvasWrapDomId = "generic-editor-wrap";

  // Generate RAWDATA for document save
  const generateRawData = canvasHtml => {
    if (canvasHtml.length < 1) return;
    // Update metadata for document save
    let metaData = { ...documentMetadata };
    metaData.version = process.env.APP_VERSION;
    metaData.dimension = JSON.parse(JSON.stringify(dimension));
    metaData.dimension = {
      ...metaData.dimension,
      width:
        metaData.dimension?.displayUnit === "px"
          ? parseInt(metaData.dimension?.width)
          : parseFloat(metaData.dimension?.width),
      height:
        metaData.dimension?.displayUnit === "px"
          ? parseInt(metaData.dimension?.height)
          : parseFloat(metaData.dimension?.height),
    };
    metaData.metaInfo = {
      template_type_id,
      template_type: templateType,
    };

    // modify grid
    metaData.grid = { ...metaData.grid, ...JSON.parse(JSON.stringify(grid)) };
    delete metaData.grid["strokeWidth"];

    // modify Margin
    let marginData = JSON.parse(JSON.stringify(pageMargin));
    let newMargin = {};
    if (marginData.style?.border) {
      let borderData = marginData.style?.border?.split(/^(\S+)\s(\S+)\s(.*)/);
      newMargin.width = parseFloat(borderData[1]);
      newMargin.stype = borderData[2];
      newMargin.color = RGBToHex(borderData[3]);
      newMargin.enabled = marginData.enabled;
      newMargin.value = marginData.value;
      newMargin.unit = "px";
    }
    metaData.margin = newMargin;

    // Page transition update
    let pageTransitionData = JSON.parse(JSON.stringify(pageNodes));
    metaData.transition = [];
    pageTransitionData?.forEach(element => {
      if (element?.pageTransition?.enabled) {
        metaData.transition.push({
          pageIdx: element?.pageIdx,
          effect: element?.pageTransition?.type,
          speed: speedObj[element?.pageTransition?.speed],
        });
      }
    });

    /**
     * Update metadata from html
     * Reset font list then update
     * Reser durtion list then update
     */
    metaData.fonts = [];
    metaData.durations = [];
    canvasHtml?.forEach(elem => {
      let allVideoLength = [0];

      // Generate fontlist
      elem?.querySelectorAll(`[data-asset-type='${TEXT}'] *, [data-asset-type='${TABLE}'] *`)?.forEach(elem => {
        let fontFamily = elem.style.fontFamily.replace(/(^"|"$)/g, "");
        if (fontFamily !== "") {
          if (!metaData.fonts.includes(fontFamily)) {
            metaData.fonts.push(fontFamily);
          }
        }
      });

      // Update duration for video, animation and uploaded file
      elem.querySelectorAll("video")?.forEach(videoElem => {
        let duration =
          durationInSec(videoElem?.getAttribute("data-trimmed-duration")) ||
          durationInSec(
            videoElem?.parentElement?.parentElement?.parentElement?.getAttribute("data-trimmed-duration")
          ) ||
          durationInSec(videoElem?.getAttribute("data-duration")) ||
          durationInSec(videoElem?.parentElement?.parentElement?.parentElement?.getAttribute("data-duration")) ||
          videoElem?.duration ||
          0;
        allVideoLength.push(!duration ? 0 : duration);
      });

      // Calculate duratioin for each video node
      elem.querySelectorAll(`[data-asset-type='${ANIMATION}'], [data-asset-type='${UPLOAD}']`)?.forEach(mediaElem => {
        let duration = mediaElem.getAttribute("data-duration") || 0;
        allVideoLength.push(!duration ? 0 : duration);
      });

      if (allVideoLength.length > 0) {
        metaData.durations.push(Math.max(...allVideoLength));
      }
    });

    // Remove class from widget list
    let formattedWidgets = JSON.parse(JSON.stringify(widgets));
    formattedWidgets?.forEach(widget => {
      // Remove "child-selected" from classLists
      const index = widget?.classLists?.indexOf("child-selected");
      if (index !== -1) {
        widget.classLists.splice(index, 1);
      }

      // Remove "child-selected" from innerHTML
      if (widget?.innerHTML?.includes("child-selected")) {
        widget.innerHTML = widget.innerHTML.replace(/(\s*)child-selected(\s*)/g, "");
      }
      
    });

    // Generate context to save
    const contexts = {
      templateGroupName: templateGroupName,
      documentType: documentType,
      pageNodes: pageNodes,
      blockNodes: blockNodes,
      widgets: formattedWidgets,
      backgroundColors: backgroundColors,
      backgroundImages: backgroundImages,
      grid: grid,
      pageMargin: pageMargin,
      dimension: dimension,
      durations: metaData.durations,
      fonts: metaData.fonts,
      audios,
    };

    return {
      metadata: metaData,
      contexts: contexts,
    };
  };

  // Save document by socket
  const saveDocument = canvasHtml => {
    if (!canvasHtml) canvasHtml = document.querySelectorAll(`#${canvasWrapDomId} .dhp-page-canvas`);

    if (canvasHtml.length > 0 && saveDocSocket.readyState === 1) {
      const subscribe = {
        type: DOCUMENT_SAVE,
        companyId: companyId,
        entryId: documentId,
        rawdata: JSON.stringify(generateRawData(canvasHtml)),
      };
      saveDocSocket.send(JSON.stringify(subscribe));
    }

    // Enable for screenshot generate
    if (!generateScreenshot) setGenerateScreenshot(true);
    if (savingStart) setSavingStart(false);
  };

  // Generate screenshot
  const generateThumb = () => {
    // Stop document screenshot if it's from page sorter
    if (pageSorterList) return;

    let payload = {
      companyId: companyId,
      entryId: documentId,
      slug: "documents",
    };
    dispatch(createDocumentThumb(payload));
    // Disable screenshot generate after trigger
    setGenerateScreenshot(false);
  };

  // Set saving changes action true
  useEffect(() => {
    if (!savingStart && isDocumentReady && blockNodes?.length > 0 && !changeBySocket && isFirstLoadDone) {
      if (!changesMade) setChangesMade(true);
      setSavingStart(true);
    }

    /**
     * condition for
     * save document if any widget added onload
     */

    if (savingStart && isDocumentReady && widgets?.length === 1 && !changeBySocket && isFirstLoadDone) {
      setIsWaitSave(true);
    }

    /**
     * set first load done on the below points
     * - If this is first time created first time
     * - Or document is ready and document is completely loaded
     */
    if (isDocumentReady && !isFirstLoadDone) {
      setisFirstLoadDone(true);
    }
  }, [
    blockNodes,
    pageNodes,
    backgroundColors,
    backgroundImages,
    widgets,
    dimension?.height,
    dimension?.width,
    pageMargin,
    grid,
    isDocumentReady,
    audios,
  ]);

  // Saving changes trigger after change
  useEffect(() => {
    if (savingStart && !isWaitSave) {
      if (isSaveInProgress) setIsWaitSave(true);
      else {
        setIsSaveInProgress(true);
        setTimeout(() => saveDocument(), DOCUMENT_SAVE_TIME_COUNT);
      }
    }
  }, [savingStart]);

  //  Auto-generate Screenshot: Generate every 30 seconds if any changes made
  useEffect(() => {
    let screenshotTimer;
    const cleanup = () => clearTimeout(screenshotTimer);

    if (generateScreenshot) {
      screenshotTimer = setTimeout(() => {
        generateThumb();
      }, GENERATE_SCREENSHOT_TIME_COUNT);
    }

    // Cleanup function will be executed when the component unmounts
    return cleanup;
  }, [generateScreenshot]);

  // Version Autosave: Save Version every 60 minutes
  useEffect(() => {
    const generateVersionInterval = setInterval(() => {
      generateVersion();
    }, GENERATE_VERSION_TIME_COUNT);

    return () => {
      if (changesMade) {
        // generate version on leave canvas
        generateVersion();
        // Generate Thumb on leave canvas
        generateThumb();
      }

      clearInterval(generateVersionInterval);
      if (isStopSaveProgress) setIsStopSaveProgress(false);
    };
  }, [changesMade]);

  /**
   *  Below section is for saving start if click on more option
   *  saving start if any changes happen
   */
  useEffect(() => {
    if (isDocumentSave) {
      if (savingStart) saveDocument();
      setIsDocumentSave(false);
    }
  }, [isDocumentSave]);

  /**
   *  Below code to check is next saving waiting
   *  If waiting then trigger save to save latest modification
   */
  useEffect(() => {
    if (!isSaveInProgress && isWaitSave && !isStopSaveProgress) {
      setIsWaitSave(false);
      saveDocument();
      setIsSaveInProgress(true);
    }
  }, [isSaveInProgress]);

  /**
   *  Restart saving if reconnect socket and previously saving in progress
   */
  useEffect(() => {
    if (restartSave) {
      setRestartSave(false);
      if (isSaveInProgress) {
        saveDocument();
      }
    }
  }, [restartSave]);
};

export default useEditorDocumentProcessing;
