import cx from "classnames";
import React from "react";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

import { Icon } from "../ui/icon";
import useFitToScreen from "../../hooks/useFitToScreen";

let style = Object.assign({}, global);

const FitOnScreen = props => {
  const { fitOnScreenMethod } = useFitToScreen();

  return (
    <>
      <li className={style["custom-tooltip"]}>
        <div
          onClick={e => (e.preventDefault(), fitOnScreenMethod())}
          className={cx(style["custom-tooltip"], style["link-white"])}
          id="fitOnScreen">
          <Icon icon="ui-expand" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Fit on Screen</div>
        </div>
      </li>
    </>
  );
};

export default FitOnScreen;
