import React, { useState, useEffect, useRef, useCallback, useContext, useLayoutEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input as RInput,
  Tooltip,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";


import global from "../../../scss/dhp.scss";

import AssetsLoader from "../../ui/loader/assetsLoader";
import { MasonryLoader } from "../../ui/loader/masonryLoader";
import AssetName from "./Common/AssetName";
import AssetSchemeTypeNav from "./Common/AssetSchemeTypeNav";
import AssetSearch from "./Common/AssetSearch";
import { fetchVideos } from "../../../store/actions/editorActions";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import useAddWidget from "../../../hooks/useAddWidget";
import useAlignment from "../../../hooks/useAlignment";
import { widgetConfig } from "../editor_config";
import { stopAllResourceBuffering } from "../../../_helpers/utils";
import { STOCK, YOUTUBE } from "../../../constants/editor";
import useDragDropWidget from "../../../hooks/useDragDropWidget";
import { DotLoader } from "../../ui/loader/dotLoader";
import useTextFocusOut from "../../../hooks/useTextFocusOut";
import { Icon } from "../../ui/icon";
import StockSPs from "./Common/StockSPs";
import { RMasonry as Masonry } from "../../ui/masonry";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import NoPreview from "../../../assets/images/no-preview.jpg";

let style = Object.assign({}, global);

const ActiveStockVideo = props => {
  return (
    <div className={cx(style["preview-pen"], style["stock-video"], {[style["horizontal-dimension"]] : !props.videoDimensionOrientation}, {[style["vertical-dimension"]] : props.videoDimensionOrientation}, {[style["square-dimension"]] : props.squareDimension})} id="video-preview">
      <video id="video-pen" autoPlay muted loop poster={props.thumb}>
        <source src={props.url} />
      </video>
      <div className={style["overlay"]} id="video-overlay">
        <div className={style["loader"]}></div>
      </div>
    </div>
  );
};
//Props type validation
ActiveStockVideo.propTypes = {
  thumb: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const UploadAsset = props => {
  let { setUploadState, uploadState } = useContext(EditorContext);

  const handleAddYourVideoClick = () => {
    props.handleWidgetAction("upload", "Uploads", "uploads", 1, false, "graphics-media", "Videos");
    setUploadState({...uploadState, uploadSchemeType : "Videos"});
  };

  useEffect(() => {
    return () => {
      setUploadState({...uploadState, uploadSchemeType : null});
    };
  }, []);

  return (
    <div className={cx(style["asset-item"], style["asset-item-icon"])} onClick={() => handleAddYourVideoClick()}>
      <div className={style["item-holder"]}>
        <Icon icon="ui-plus" additionalclass="custom-tooltip">
          <div className={style["custom-tooltip-content"]}>Add Your Video</div>
        </Icon>
      </div>
    </div>
  );
};
//Props type validation
UploadAsset.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
};

const StockVideoList = props => {
  const playedVideoElement = useRef();
   const imgRef = useRef();
  const { metadata, dimension } = useContext(EditorContext);

  const { start: initDragDropWidget } = useDragDropWidget();

  let lowQualityUrl;
  let videoDimensionOrientation = false;
  let squareDimension = false;
  let pexelsWidth;
  let pexelsHeight;
  const { video_files, source } = props.availableVideoList;
  if (source === "pixabay") {
    lowQualityUrl = video_files?.tiny?.url;
    let { width, height } = video_files?.tiny;

    if (height > width) videoDimensionOrientation = true;
    else if (height == width) squareDimension = true;
  } else {
    let videoData;

    for (const { width, height, quality, link } of Object.values(video_files ?? {})) {
      if (width <= 640 && quality === "sd") {
        videoData = { width, height, quality, link };
        break;
      }

      if (!videoData && width <= 640) {
        videoData = { width, height, quality, link };
      }
    }

    if (videoData) {
      const { link, width, height } = videoData;
      lowQualityUrl = link;
      pexelsWidth = width;
      pexelsHeight = height;

      videoDimensionOrientation = pexelsHeight > pexelsWidth;
      squareDimension = pexelsHeight === pexelsWidth;
    }
  }

  const [isAssetLoad, setIsAssetLoad] = useState(false);
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [param, setParam] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const setTextFocusOut = useTextFocusOut();
  const { postion: getPosition } = useAlignment(
    addWidgetClicked,
    widgetWidth,
    widgetHeight,
    "middle-center"
  );
  const getNewWidgetObject = useAddWidget(addWidgetClicked, props.assetType, param, getPosition,  widgetWidth,
    widgetHeight);
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    props.assetType,
    assetInnerContent,
    param,
    getNewWidgetObject
  );

  const insertElem = (url, source, poster, duration, assetLoadComplete, availableVideoList) => {
    if (!assetLoadComplete) return;

    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

    const { video_files } = availableVideoList;
    let realWidth;
    let realHeight;
    let fixedHeight = widgetConfig[props.assetType].height;
    if (source === "pixabay") {
      let { width, height } = video_files?.tiny;
      realWidth = width;
      realHeight = height;
    } else {
      realWidth = pexelsWidth;
      realHeight = pexelsHeight;
    }

    let videoAspectRatio = realHeight / fixedHeight;

    let { width: activeBlockWidth } = document.getElementById(`${metadata.activeBlockId}`)?.getBoundingClientRect();
    activeBlockWidth = (activeBlockWidth * 100) / parseFloat(dimension.zoom);
    let virtualWidth = realWidth / videoAspectRatio;
    setWidgetWidth(virtualWidth);
    setWidgetHeight(fixedHeight);

    setTimeout(() => {
      let paramObj = {
        "data-origin": source,
        "data-poster": poster,
        "data-url": url,
        "data-duration": duration,
      };
      setAssetInnerContent(url);
      setParam({ ...widgetConfig[props.assetType].STOCK.dataAttr, ...paramObj });
      // setAddWidgetClicked(true);
    }, 1);
  };

  const widgetAddOnDNDHandler = e => {
      const isLeftClick = e.button === 0;
  
      // NOTE:::: Only when left mouse button is pressed i.e. MouseEvent.button = 0
      if (isLeftClick) {
        initDragDropWidget(
          {
            asset: props.assetType,
            value: props.availableVideoList.url,
            source: STOCK,
            origin: props.availableVideoList.source,
            poster: props.availableVideoList.poster,
            duration: props.availableVideoList.duration,
          },
          e
        );
      }
    };

  const playVideoOnHover = assetLoadComplete => {
    if (!props.playVideo[playedVideoElement.current.dataset.idx] && assetLoadComplete) {
      props.setPlayVideo({ [playedVideoElement.current.dataset.idx]: true });

      // removing preview loader
      let intervalId = setInterval(() => {
        let runTimeVideo = document.getElementById("video-pen");
        let overlayLoader = document.getElementById("video-overlay");
        if (runTimeVideo && runTimeVideo.readyState == 4) {
          clearInterval(intervalId);
          overlayLoader.style.display = "none";
        }
      }, 300);
    }
  };

  const scrollLeftPane = () => {
    setTooltipOpen(false);
  };

  const stopVideoOnMouseLeave = () => {
    props.setPlayVideo({ [playedVideoElement.current.dataset.idx]: false });
  };

  useEffect(() => {
    if (assetInnerContent && widgetWidth && widgetHeight && param) {
      setAddWidgetClicked(true);
    }
  }, [assetInnerContent, widgetWidth, widgetHeight, param]);

  useEffect(() => {
    if (imgRef.current.complete) setIsAssetLoad(true);
  }, []);

  useEffect(() => {
    document.querySelector(".assets-wrapper")?.addEventListener("scroll", scrollLeftPane);

    return () => {
      document.querySelector(".assets-wrapper")?.removeEventListener("scroll", scrollLeftPane);
    };
  }, []);

  return (
    <React.Fragment key={props.availableVideoList.poster}>
      {!isAssetLoad && <MasonryLoader module="editor_assets" type="item" />}
      {props.length === props.index + 1 && (
        <div
          id={`asset-image-${props.id}`}
          className={cx(
            style["asset-item"],
            {
              [style["d-none"]]: !isAssetLoad,
              [style["loader-overlay"]]: !isAssetLoad,
            },
            { [style["horizontal-asset-dimension"]]: !videoDimensionOrientation },
            { [style["vertical-asset-dimension"]]: videoDimensionOrientation },
            { [style["square-asset-dimension"]]: squareDimension }
          )}
          ref={props.lastStockVideoElement}>
          <div
            className={cx(style["item-holder"], style["main-heading"])}
            data-idx={props.index}
            ref={playedVideoElement}
            onMouseEnter={() => playVideoOnHover(isAssetLoad)}
            onMouseLeave={stopVideoOnMouseLeave}
            onClick={() =>
              insertElem(
                props.availableVideoList.url,
                props.availableVideoList.source,
                props.availableVideoList.poster,
                props.availableVideoList.duration,
                isAssetLoad,
                props.availableVideoList
              )
            }
            onMouseDown={e => {
              isAssetLoad && widgetAddOnDNDHandler(e);
            }}>
            {!props.playVideo[props.index] && !isAssetLoad && <span className={style["loader-item"]}></span>}

            <img
              ref={imgRef}
              src={props.availableVideoList.thumb ?? NoPreview}
              alt="preview thumbnail"
              onLoad={() => setIsAssetLoad(true)}
              className={style["img-fluid"]}
              onError={e => {
                e.target.onerror = null;
                e.target.src = props.availableVideoList.thumb;
              }}
            />
          </div>

          {isAssetLoad && <span className={style["duration"]}>{props.availableVideoList.duration}</span>}
        </div>
      )}

      {props.length !== props.index + 1 && (
        <div
          id={`asset-image-${props.id}`}
          className={cx(
            style["asset-item"],
            {
              [style["d-none"]]: !isAssetLoad,
              [style["loader-overlay"]]: !isAssetLoad,
            },
            { [style["horizontal-asset-dimension"]]: !videoDimensionOrientation },
            { [style["vertical-asset-dimension"]]: videoDimensionOrientation },
            { [style["square-asset-dimension"]]: squareDimension }
          )}>
          <div
            className={cx(style["item-holder"], style["main-heading"])}
            data-idx={props.index}
            ref={playedVideoElement}
            onMouseEnter={() => playVideoOnHover(isAssetLoad)}
            onMouseLeave={stopVideoOnMouseLeave}
            onClick={() =>
              insertElem(
                props.availableVideoList.url,
                props.availableVideoList.source,
                props.availableVideoList.poster,
                props.availableVideoList.duration,
                isAssetLoad,
                props.availableVideoList
              )
            }
            onMouseDown={e => {
              isAssetLoad && widgetAddOnDNDHandler(e);
            }}>
            {!props.playVideo[props.index] && !isAssetLoad && <span className={style["loader-item"]}></span>}

            <img
              ref={imgRef}
              src={props.availableVideoList.thumb}
              onLoad={() => setIsAssetLoad(true)}
              className={style["img-fluid"]}
              onError={e => {
                e.target.onerror = null;
                e.target.src = props.availableVideoList.thumb;
              }}
            />
          </div>

          {isAssetLoad && <span className={style["duration"]}>{props.availableVideoList.duration}</span>}
        </div>
      )}

      {isAssetLoad && (
        <Tooltip
          className="asset-hover-tooltip"
          placement="right"
          isOpen={tooltipOpen}
          target={`asset-image-${props.id}`}
          toggle={toggle}
          boundariesElement={document.getElementById("app")}>
          <div className="image-preview preview-picture">
            <ActiveStockVideo
              squareDimension={squareDimension}
              videoDimensionOrientation={videoDimensionOrientation}
              thumb={props.availableVideoList.thumb}
              url={lowQualityUrl}
            />
          </div>
        </Tooltip>
      )}
    </React.Fragment>
  );
};
//Props type validation
StockVideoList.propTypes = {
  availableVideoList: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  lastStockVideoElement: PropTypes.func.isRequired,
  playVideo: PropTypes.object.isRequired,
  setPlayVideo: PropTypes.func.isRequired,
  assetType: PropTypes.string.isRequired,
};

export const StockVideoFilters = props => {
  const orientations = ["Square", "Horizontal", "Vertical"];

  const [payload, setPayload] = useState({});
  const [showClear, setShowClear] = useState(false);
  const [disableForm, setDisableForm] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const updatePayload = (type, data) => {
    setPayload({ ...payload, [type]: data });
    setShowClear(true);
    setDisableForm(false);
  };

  const clearFilter = () => {
    setPayload({ orientation: false });
    setShowClear(false);
    ["stockVideFilterOrientation"].forEach(radio => {
      if (document.querySelector(`input[name="${radio}"]:checked`)) {
        document.querySelector(`input[name="${radio}"]:checked`).checked = false;
      }
    });
  };

  const applyFilter = () => {
    props.setFilters(payload);
    props.setPage(1);
    setDisableForm(true);
    toggle();
  };

  useEffect(() => {
    setDisableForm(props.filters?.orientation === payload.orientation);
  }, [payload]);

  useEffect(() => {
    setFilterCount(Object.keys(props.filters).filter(f => props.filters[f])?.length);
  }, [props.filters]);

  useEffect(() => {
    setPayload(props.filters);
    setShowClear(Object.keys(props.filters).some(f => props.filters[f]));
  }, [dropdownOpen]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={cx(style["more-action"])}>
      <DropdownToggle>{filterCount > 0 && <span className={style["filter-count"]}>{filterCount}</span>}</DropdownToggle>
      <DropdownMenu className={cx(style["dropdown-menu-right"], style["shadow-sm"], style["border-0"], style["p-3"])}>
        <div className={cx(style["fw-6"], style["color-black"])}>Videos from</div>
        <div className={cx(style["d-flex"], style["font-15"])}>
          <StockSPs fileType="video" excludeList={["unsplash"]} />
        </div>
        <>
          <DropdownItem divider />
          <div className={cx(style["form-group"], style["mr-0"])}>
            <div
              className={cx(
                style["d-flex"],
                style["justify-content-between"],
                style["align-items-center"],
                style["mb-2"]
              )}>
              <label className={style["m-0"]}>Orientation</label>
              {showClear && (
                <div className={cx(style["clear"], style["custom-tooltip"])} onClick={() => clearFilter()}>
                  <Icon icon="ui-reset" />
                  <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Clear</div>
                </div>
              )}
            </div>
            {orientations.map((orientation, i) => (
              <div key={i} className={cx(style["custom-control"], style["custom-radio"], style["mb-2"])}>
                <RInput
                  type="radio"
                  id={`stockVideFilterOrientation${i}`}
                  name="stockVideFilterOrientation"
                  className={style["custom-control-input"]}
                  defaultChecked={orientation === props.filters.orientation}
                  value={orientation}
                  onChange={() => updatePayload("orientation", orientation)}
                />
                <label className={style["custom-control-label"]} htmlFor={`stockVideFilterOrientation${i}`}>
                  {orientation}
                </label>
              </div>
            ))}
          </div>
          <Button
            type="submit"
            color="primary"
            className={style["btn-block"]}
            cssModule={style}
            disabled={disableForm}
            onClick={() => applyFilter()}>
            Apply Filters
          </Button>
        </>
      </DropdownMenu>
    </Dropdown>
  );
};
//Props type validation
StockVideoFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};

const YoutubeVideoList = props => {
  const [isAssetLoad, setIsAssetLoad] = useState(false);
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [param, setParam] = useState();

  const setTextFocusOut = useTextFocusOut();
  const { postion: getPosition } = useAlignment(
    addWidgetClicked,
    widgetConfig[props.assetType].width,
    widgetConfig[props.assetType].height,
    "middle-center"
  );
  const getNewWidgetObject = useAddWidget(addWidgetClicked, props.assetType, param, getPosition);
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    props.assetType,
    assetInnerContent,
    param,
    getNewWidgetObject
  );

  const insertElem = (thumb, url, key) => {
    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

    setTimeout(() => {
      let paramObj = {
        "data-url": url,
        "data-key": key,
      };
      setAssetInnerContent(thumb);
      setParam({ ...widgetConfig[props.assetType].YOUTUBE.dataAttr, ...paramObj });
      setAddWidgetClicked(true);
    }, 1);
  };

  return (
    <React.Fragment key={props.availableVideoList.id}>
      {props.length === props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])} ref={props.lastYouTubeVideoElement}>
          <div
            className={cx(style["asset-item"], style["flex-column"], style["main-heading"])}
            data-idx={props.index}
            onClick={() =>
              insertElem(props.availableVideoList.hq_thumb, props.availableVideoList.url, props.availableVideoList.id)
            }
            onMouseDown={e =>
              props.initDragDropWidget(
                {
                  asset: props.assetType,
                  value: props.availableVideoList.hq_thumb,
                  source: YOUTUBE,
                  url: props.availableVideoList.url,
                  key: props.availableVideoList.id,
                },
                e
              )
            }>
            {!isAssetLoad && <span className={style["loader-item"]}></span>}

            <img
              src={props.availableVideoList.hq_thumb}
              alt={props.availableVideoList.channel_title}
              onLoad={() => setIsAssetLoad(true)}
              className={cx({ [style["d-none"]]: !isAssetLoad })}
            />

            {isAssetLoad && (
              <>
                <span className={style["video-title"]} title={props.availableVideoList.title}>
                  {props.availableVideoList.title}
                </span>
                <span className={style["video-channel-name"]} title={props.availableVideoList.channel_title}>
                  {props.availableVideoList.channel_title}
                </span>
              </>
            )}
          </div>
        </li>
      )}
      {props.length !== props.index + 1 && (
        <li className={cx(style["col"], style["skeleton-loader-area"])}>
          <div
            className={cx(style["asset-item"], style["flex-column"], style["main-heading"])}
            data-idx={props.index}
            onClick={() =>
              insertElem(props.availableVideoList.hq_thumb, props.availableVideoList.url, props.availableVideoList.id)
            }
            onMouseDown={e =>
              props.initDragDropWidget(
                {
                  asset: props.assetType,
                  value: props.availableVideoList.hq_thumb,
                  source: YOUTUBE,
                  url: props.availableVideoList.url,
                  key: props.availableVideoList.id,
                },
                e
              )
            }>
            {!isAssetLoad && <span className={style["loader-item"]}></span>}

            <img
              src={props.availableVideoList.hq_thumb}
              alt={props.availableVideoList.channel_title}
              onLoad={() => setIsAssetLoad(true)}
              className={cx({ [style["d-none"]]: !isAssetLoad })}
            />

            {isAssetLoad && (
              <>
                <span className={style["video-title"]} title={props.availableVideoList.title}>
                  {props.availableVideoList.title}
                </span>
                <span className={style["video-channel-name"]} title={props.availableVideoList.channel_title}>
                  {props.availableVideoList.channel_title}
                </span>
              </>
            )}
          </div>
        </li>
      )}
    </React.Fragment>
  );
};
//Props type validation
YoutubeVideoList.propTypes = {
  availableVideoList: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  lastYouTubeVideoElement: PropTypes.func.isRequired,
  assetType: PropTypes.string.isRequired,
  initDragDropWidget: PropTypes.func.isRequired,
};

const VideoWrapper = props => {
  const dispatch = useDispatch();
  const observer = useRef();
  const videoListRef = useRef();

  const [playVideo, setPlayVideo] = useState({});
  const [youTubeAvailableVideoLists, setYouTubeAvailableVideoLists] = useState([]);
  const [stockAvailableVideoLists, setStockAvailableVideoLists] = useState(null);

  const youtubeAvailableVideos = useSelector(state => state?.editor?.youtubeAvailableVideos);
  const stockAvailableVideos = useSelector(state => state?.editor?.stockAvailableVideos);
  const nextPageToken = useSelector(state => state?.editor?.pageToken);
  const videoLoading = useSelector(state => state?.editor?.loading);
  const hasMoreVideos = useSelector(state => state?.editor?.hasMoreVideos);
  const [videoLoader, setVideoLeader] = useState(true);

  const { start: initDragDropWidget } = useDragDropWidget();

  //Observe the last node for pagination and update page number on scroll for Stock Videos
  const lastStockVideoElement = useCallback(
    node => {
      if (videoLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreVideos) {
          props.setStockVideoPageNumber(props.stockVideoPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [videoLoading, hasMoreVideos]
  );

  //Observe the last node for pagination and update page number on scroll for YoutubeVideos
  const lastYouTubeVideoElement = useCallback(
    node => {
      if (videoLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreVideos) {
          props.setYouTubeVideoPageNumber(props.youTubeVideoPageNumber + 1);
          props.setYouTubePageToken(nextPageToken);
        }
      });

      if (node) observer.current.observe(node);
    },
    [videoLoading, hasMoreVideos]
  );

  // Fetch videos api(pagination, keword, scheme type change)
  useEffect(() => {
    let payLoad = {
      limit: 25,
      source: props.schemeType === "Stock Videos" ? "Stock" : props.schemeType,
      video_id: false,
      pageNumber: props.schemeType === "Stock Videos" ? props.stockVideoPageNumber : props.youTubeVideoPageNumber,
    };

    if (props?.filters?.orientation &&  props.schemeType === "Stock Videos") {
      switch (props.filters.orientation) {
        case "Horizontal":
          payLoad.orientation = "landscape";
          break;
        case "Vertical":
          payLoad.orientation = "portrait";
          break;
        case "Square":
          payLoad.orientation = "square";
          break;
        default:
          break;
      }
    }
    if (props.keyword) payLoad.keyword = props.keyword;
    if (props.youTubePageToken) payLoad.page_token = props.youTubePageToken;

    if (props.stockVideoPageNumber === 1 || props.youTubeVideoPageNumber === 1) {
      if (videoListRef.current) videoListRef.current.scrollTop = 0;
      stopAllResourceBuffering(videoListRef.current, "img");
    }

    dispatch(fetchVideos(payLoad));
  }, [props.keyword, props.schemeType, props.stockVideoPageNumber, props.youTubePageToken, props?.filters?.orientation]);
  
  useLayoutEffect(() => {
    setYouTubeAvailableVideoLists([]);
    setStockAvailableVideoLists(null);
  }, [props.schemeType]);
  
  useLayoutEffect(() => {
    setStockAvailableVideoLists(null);
  }, [props.filter]);

  useLayoutEffect(() => {
    if (props.schemeType === "Stock Videos" && stockAvailableVideos) {
      setStockAvailableVideoLists(stockAvailableVideos);
    } else if (props.schemeType === "YouTube" && youtubeAvailableVideos)
      setYouTubeAvailableVideoLists(youtubeAvailableVideos);
  }, [youtubeAvailableVideos, stockAvailableVideos]);

  useEffect(() => {
    if (stockAvailableVideoLists && !videoLoading) setVideoLeader(false);
  }, [stockAvailableVideoLists]);

  useEffect(() => {
    setVideoLeader(true);
    return () => stopAllResourceBuffering(videoListRef.current, "img");
  }, []);

  return (
    <div
      className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}
      onContextMenu={e => e.preventDefault()}
      ref={videoListRef}>
      {props.schemeType === "Stock Videos" && (
        <div className={cx(style["StockVideos"], style["p-0"])}>
          {videoLoading && props.stockVideoPageNumber === 1 && (
            <MasonryLoader module="editor_assets" type="masonry" count={25} />
          )}
          <Masonry module="editor_assets">
            {stockAvailableVideoLists?.length > 0 && <UploadAsset handleWidgetAction={props.handleWidgetAction} />}
            {stockAvailableVideoLists?.length > 0 &&
              stockAvailableVideoLists?.map((availableVideoList, index) => (
                <StockVideoList
                  id={index}
                  key={index}
                  availableVideoList={availableVideoList}
                  index={index}
                  length={stockAvailableVideoLists.length}
                  lastStockVideoElement={lastStockVideoElement}
                  playVideo={playVideo}
                  setPlayVideo={setPlayVideo}
                  assetType={props.assetType}
                />
              ))}
          </Masonry>

          {!videoLoader && stockAvailableVideos?.length == 0 && (
            <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>
              No matching videos found
            </div>
          )}
        </div>
      )}

      {props.schemeType === "YouTube" && (
        <ul className={cx(style["row"], style["row-cols-1"])}>
          {videoLoading && props.youTubeVideoPageNumber === 1 && <AssetsLoader count={20} />}

          {youTubeAvailableVideoLists?.length > 0 &&
            youTubeAvailableVideoLists?.map((availableVideoList, index) => (
              <YoutubeVideoList
                key={index}
                availableVideoList={availableVideoList}
                index={index}
                length={youTubeAvailableVideoLists.length}
                lastYouTubeVideoElement={lastYouTubeVideoElement}
                assetType={props.assetType}
                initDragDropWidget={initDragDropWidget}
              />
            ))}

          {!videoLoading && youTubeAvailableVideoLists?.length === 0 && (
            <li className={cx(style["col"])}>
              <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>
                No matching videos found
              </div>
            </li>
          )}
        </ul>
      )}

      {videoLoading && (props.stockVideoPageNumber > 1 || props.youTubeVideoPageNumber > 1) && <DotLoader />}
    </div>
  );
};
//Props type validation
VideoWrapper.propTypes = {
  keyword: PropTypes.string.isRequired,
  schemeType: PropTypes.string.isRequired,
  stockVideoPageNumber: PropTypes.number,
  setStockVideoPageNumber: PropTypes.func,
  youTubePageToken: PropTypes.string,
  setYouTubePageToken: PropTypes.func,
  youTubeVideoPageNumber: PropTypes.number,
  setYouTubeVideoPageNumber: PropTypes.func,
  assetType: PropTypes.string.isRequired,
};

const StockVideo = props => {
  const [stockKeyword, setStockKeyword] = useState("");
  const [stockVideoPageNumber, setStockVideoPageNumber] = useState(1);
  const [category, setCategory] = useState("");
  const [filters, setFilters] = useState({
    orientation: false,
  });

  return (
    <TabPane tabId="1" className={style["active"]}>
      <AssetSearch
        assetName={props.assetName}
        setKeyword={setStockKeyword}
        setPageNumber={setStockVideoPageNumber}
        schemeType={props.schemeType}
        setCategory={setCategory}
        setFilters={setFilters}
        filters={filters}
      />
      <VideoWrapper
        keyword={stockKeyword}
        schemeType={props.schemeType}
        stockVideoPageNumber={stockVideoPageNumber}
        setStockVideoPageNumber={setStockVideoPageNumber}
        assetType={props.assetType}
        handleWidgetAction={props.handleWidgetAction}
        filters={filters}
      />
    </TabPane>
  );
};
//Props type validation
StockVideo.propTypes = {
  assetName: PropTypes.string.isRequired,
  schemeType: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

const YoutubeVideo = props => {
  const [youTubeKeyword, setYouTubeKeyword] = useState("");
  const [youTubePageToken, setYouTubePageToken] = useState("");
  const [youTubeVideoPageNumber, setYouTubeVideoPageNumber] = useState(1);

  return (
    <TabPane tabId="2" className={style["active"]}>
      <AssetSearch
        assetName={props.assetName}
        setKeyword={setYouTubeKeyword}
        setPageNumber={setYouTubeVideoPageNumber}
        setYouTubePageToken={setYouTubePageToken}
      />
      <VideoWrapper
        keyword={youTubeKeyword}
        schemeType={props.schemeType}
        youTubePageToken={youTubePageToken}
        setYouTubePageToken={setYouTubePageToken}
        youTubeVideoPageNumber={youTubeVideoPageNumber}
        setYouTubeVideoPageNumber={setYouTubeVideoPageNumber}
        assetType={props.assetType}
      />
    </TabPane>
  );
};
//Props type validation
YoutubeVideo.propTypes = {
  assetName: PropTypes.string.isRequired,
  schemeType: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

const Video = props => {
  const videoSchemes = ["Stock Videos", "YouTube"];
  const [schemeType, setSchemeType] = useState("Stock Videos");

  return (
    <div className={cx(style["editor-asset-inner"], {[style["video-asset"]] : schemeType === "YouTube" },  {[style["stock-video-asset"]] : schemeType === "Stock Videos" })}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />
      <div className={style["pr-20"]}>
        <AssetSchemeTypeNav schemeTypeArray={videoSchemes} setSchemeType={setSchemeType} />
      </div>
      <TabContent>
        {schemeType === "Stock Videos" && (
          <StockVideo assetName={props.assetName} schemeType={schemeType} assetType={props.assetType} handleWidgetAction={props.handleWidgetAction} />
        )}
        {schemeType === "YouTube" && (
          <YoutubeVideo assetName={props.assetName} schemeType={schemeType} assetType={props.assetType} />
        )}
      </TabContent>
    </div>
  );
};
//Props type validation
Video.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default Video;
