import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input as RInput,
  UncontrolledDropdown,
} from "reactstrap";

import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import ColorPicker from "../../ui/colorPicker";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { Input } from "../../ui/input";
import { Icon } from "../../ui/icon";
import { changeBucketColorAccordingBackground, hexToRGB, RGBToHex, validateNumberInput } from "../../../_helpers/utils";
import { contextualConfig, widgetConfig } from "../editor_config";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../../../constants/editor";
import { LENGTH_UNITS_CONVERSION_FACTORS } from "../../../constants/pageSizeModal";

let style = Object.assign({}, global);

const PageMargin = props => {
  let { pageMargin, updatePageMargin, documentType, dimension } = useContext(EditorContext);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [pageMarginSize, setPageMarginSize] = useState(widgetConfig.page_margin.defaultSize);
  const [pageMarginColor, setPageMarginColor] = useState(widgetConfig.page_margin.defaultColor);
  const [pageMarginType, setPageMarginType] = useState(widgetConfig.page_margin.defaultType);
  const [pageMarginWidth, setPageMarginWidth] = useState(widgetConfig.page_margin.defaultWidth);
  const [iconBucketColor, setIconBucketColor] = useState("light");
  const [eyedropperColor, setEyedropperColor] = useState();
  const [uom, setUom] = useState();
  const [pageMarginCurrentUOM, setPageMarginCurrentUOM] = useState();
  const [loadCutrrentFormatting, setLoadCutrrentFormatting] = useState(true);

  const availableMarginTypes = ["solid", "dashed", "dotted"];

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (eyedropperColor) {
      setLoadCutrrentFormatting(false);
      setPageMarginColor(eyedropperColor);
      setEyedropperColor();
    }
  }, [eyedropperColor]);

  //First time set Page Margin values from context if it exist
  useEffect(() => {
    if (!pageMargin.style.border || !pageMargin.style.top) return;

    let borderArray = pageMargin.style.border.split(" ");

    setLoadCutrrentFormatting(true);
    setPageMarginSize(pageMargin.enabled ? pageMargin.value : widgetConfig.page_margin.defaultSize);
    setPageMarginWidth(parseFloat(borderArray[0]));
    setPageMarginType(borderArray[1]);
    setPageMarginColor(
      borderArray[2] === "transparent" ? borderArray[2] : RGBToHex(borderArray[2] + borderArray[3] + borderArray[4])
    );
  }, []);

  //Update Page Margin values (size, style, color, width) onchange
  useEffect(() => {
    if (!loadCutrrentFormatting) {
      let insetValue = [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
        ? pageMarginSize
        : pageMarginSize * (dimension.zoom / 100);
      updatePageMargin({
        ...pageMargin,
        value: pageMarginSize,
        style: {
          ...pageMargin.style,
          left: insetValue,
          top: insetValue,
          right: insetValue,
          bottom: insetValue,
          border: `${pageMarginWidth}px ${pageMarginType} ${
            pageMarginColor === "transparent" ? pageMarginColor : hexToRGB(pageMarginColor)
          }`,
          height: [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
            ? `${dimension.height - pageMarginSize * 2}px`
            : "auto",
        },
      });
    }
  }, [pageMarginSize, pageMarginColor, pageMarginType, pageMarginWidth, pageMargin.enabled]);

  //Change Colorpicker icon bucket color, based on selected color
  useEffect(() => {
    setIconBucketColor(
      changeBucketColorAccordingBackground(
        pageMarginColor === "transparent" ? pageMarginColor : hexToRGB(pageMarginColor)
      )
    );
  }, [pageMarginColor]);

  useEffect(() => {
    setUom(LENGTH_UNITS_CONVERSION_FACTORS?.find(mu => mu.name === (dimension?.displayUnit ?? dimension.unit)));
  }, [dimension]);

  useEffect(() => {
    if (uom?.factor) {
      let margin = uom.name === "px" ? parseInt(pageMarginSize) : parseFloat((pageMarginSize * uom.factor).toFixed(2));
      margin =
        margin >= widgetConfig.page_margin[uom.name].maxSize
          ? widgetConfig.page_margin[uom.name].maxSize
          : margin <= widgetConfig.page_margin[uom.name].minSize
          ? widgetConfig.page_margin[uom.name].minSize
          : margin;
      setPageMarginCurrentUOM(margin);
      let newMarginInPx = margin / uom.factor;
      setPageMarginSize(newMarginInPx);
    }
  }, [uom]);

  const onDecrease = () => {
    let newMargin =
      pageMarginCurrentUOM - 1 > widgetConfig.page_margin[uom.name].minSize
        ? uom.factor === 1
          ? pageMarginCurrentUOM - 1
          : parseFloat((pageMarginCurrentUOM - 1).toFixed(2))
        : widgetConfig.page_margin[uom.name].minSize;
    setPageMarginCurrentUOM(newMargin);
    let newMarginInPx = newMargin / uom.factor;

    setLoadCutrrentFormatting(false);
    setPageMarginSize(newMarginInPx);
  };

  const onIncrease = () => {
    let newMargin =
      pageMarginCurrentUOM + 1 < widgetConfig.page_margin[uom.name].maxSize
        ? uom.factor === 1
          ? pageMarginCurrentUOM + 1
          : parseFloat((pageMarginCurrentUOM + 1).toFixed(2))
        : widgetConfig.page_margin[uom.name].maxSize;
    setPageMarginCurrentUOM(newMargin);
    let newMarginInPx = newMargin / uom.factor;

    setLoadCutrrentFormatting(false);
    setPageMarginSize(newMarginInPx);
  };

  const toggle2 = () => setDropdownOpen(prevState => !prevState);

  const updatePageMarginSize = e => {
    if (e.type === "change") {
      let curValue =
        uom.name === "px" ? parseInt(e.target.value ?? 0) : parseFloat(parseFloat(e.target.value ?? 0).toFixed(2));
      setPageMarginCurrentUOM(curValue);
      let curMax = widgetConfig.page_margin[uom.name].maxSize;
      let curMin = widgetConfig.page_margin[uom.name].minSize;
      let newMargin = curValue >= curMax ? curMax : curValue <= curMin ? curMin : curValue;
      let newMarginInPx = newMargin / uom.factor;

      setLoadCutrrentFormatting(false);
      setPageMarginSize(newMarginInPx);
    }
    if (e.type === "blur") {
      let curValue =
        uom.name === "px"
          ? parseInt(e.target.value ? e.target.value : 0)
          : parseFloat(parseFloat(e.target.value ? e.target.value : 0).toFixed(2));
      let curMax = widgetConfig.page_margin[uom.name].maxSize;
      let curMin = widgetConfig.page_margin[uom.name].minSize;
      let newMargin = curValue >= curMax ? curMax : curValue <= curMin ? curMin : curValue;
      setPageMarginCurrentUOM(newMargin);
      let newMarginInPx = newMargin / uom.factor;

      setLoadCutrrentFormatting(false);
      setPageMarginSize(newMarginInPx);
    }
  };

  return (
    <div className={cx(style["editor-asset-inner"], style["pageMargin-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />

      <div className={cx(style["assets-wrapper"], style["customScroll"], style["scroll-Y"])}>
        <div className={cx(style["custom-control"], style["custom-switch"], style["mb-3"])}>
          <RInput
            type="checkbox"
            className={style["custom-control-input"]}
            defaultChecked={pageMargin.enabled}
            id="customSwitchForPageMargin"
            value={pageMargin.enabled}
            onChange={() => {
              setLoadCutrrentFormatting(false), updatePageMargin({ ...pageMargin, enabled: !pageMargin.enabled });
            }}
          />

          <label className={style["custom-control-label"]} htmlFor="customSwitchForPageMargin">
            Show Margin
          </label>
        </div>

        {pageMargin.enabled && (
          <>
            <div className={style["line-controls-wrap"]}>
              <div className={style["slidelabel"]}>Size</div>
              <Input
                returnType="formGroup"
                id="pageMarginSize"
                type="spinner"
                className="custom-width"
                spinnerClass="input-spinner"
                value={pageMarginCurrentUOM}
                max={widgetConfig.page_margin?.[dimension?.displayUnit ?? dimension.unit].maxSize}
                min={widgetConfig.page_margin?.[dimension?.displayUnit ?? dimension.unit].minSize}
                required={false}
                onKeyDown={e => validateNumberInput(e)}
                onDecrease={onDecrease}
                onIncrease={onIncrease}
                onChange={updatePageMarginSize}
                onBlur={updatePageMarginSize}
              />
              <div className={style["ml-3"]}>{dimension.displayUnit ?? dimension.unit}</div>
            </div>

            <div className={cx(style["line-controls-wrap"], style["margin-color"])}>
              <div className={style["slidelabel"]}>Color</div>
              <UncontrolledDropdown
                setActiveFromChild
                className={cx(style["color-section-block"], style["color-picker-dropdown"])}>
                <DropdownToggle tag="a" className={style["p-0"]}>
                  <Icon
                    additionalclass={iconBucketColor}
                    icon="ui-fill-color2"
                    style={
                      pageMarginColor === "transparent"
                        ? {
                            backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                            backgroundSize: "cover",
                          }
                        : { backgroundColor: pageMarginColor }
                    }
                  />
                </DropdownToggle>
                <DropdownMenu className={cx(style["border-0"], style["p-0"])}>
                  <ColorPicker
                    color={pageMarginColor}
                    setColor={setPageMarginColor}
                    eyedropperColor={eyedropperColor}
                    setEyedropperColor={setEyedropperColor}
                  />
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <div className={style["line-controls-wrap"]}>
              <div className={style["slidelabel"]}>Style</div>
              <Dropdown isOpen={dropdownOpen} toggle={toggle2} className={style["border-style"]}>
                <DropdownToggle className={style["text-capitalize"]}>
                  {pageMarginType}
                  <Icon icon="ui-arrow-down" />
                </DropdownToggle>

                <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                  {availableMarginTypes.map(availableMarginType => (
                    <React.Fragment key={availableMarginType}>
                      <DropdownItem
                        className={style["text-capitalize"]}
                        tag="a"
                        onClick={() => {
                          setLoadCutrrentFormatting(false), setPageMarginType(availableMarginType);
                        }}>
                        <span className={availableMarginType}></span>
                        {availableMarginType}
                      </DropdownItem>
                    </React.Fragment>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>

            <div className={style["line-controls-wrap"]}>
              <div className={style["slidelabel"]}>Width</div>
              <div className={cx(style["slidecontainer"], style["flex-fill"])}>
                <RInput
                  type="range"
                  min={widgetConfig.page_margin.minWidth}
                  max={widgetConfig.page_margin.maxWidth}
                  step={0.1}
                  className="slider"
                  value={pageMarginWidth}
                  id="pageMarginRange"
                  onChange={e => {
                    setLoadCutrrentFormatting(false), setPageMarginWidth(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={cx(style["special-note"], style["rounded"])}>
              Page margin is shown only during document editing. It would not be shown during Preview, Present or
              Download.
            </div>
          </>
        )}
      </div>
    </div>
  );
};

//Props type validation
PageMargin.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string,
};

export default PageMargin;
