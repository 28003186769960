import { useContext, useEffect, useState } from "react";
import { GROUP_WIDGET, TEXT, TEXT_FRAME } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  calculateNewPositionOnRotatedObjectResize,
  getCssTextObjToString,
  getCssTransformObj,
  getUnScaledValue,
  getUpdatedGroupInnerWidgetPos,
  updateHeightAndTopForInactiveGroupChildren,
} from "../_helpers/utils";
import { contextualConfig } from "../components/Editor/editor_config";

const useTextFocusOut = () => {
  let { widgets, updateWidgets, dimension } = useContext(EditorContext);

  const [textFocusOut, setTextFocusOut] = useState(false);
  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const checkisAllBold = originalElementInnerHtml => {
    let isAllBold = true;
    let focusOutObj = originalElementInnerHtml;
    let currentFontFamily = focusOutObj.style.fontFamily;
    let index = fonts.findIndex(
      font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
    );
    let normalWiight = parseInt(fonts[index].normal_weight);
    let focusedOutWidgetId = document.querySelector(".dhp-content-editable-true-text")?.getAttribute("data-widget-id");

    if (focusOutObj.querySelectorAll("*").length !== 0) {
      focusOutObj.querySelectorAll("*").forEach(node => {
        if (!node.style.fontWeight && node.tagName === "DIV") {
          node.childNodes.forEach(element => {
            if (
              element.nodeName === "#text" &&
              element.textContent.replace(/[\n\r]+|[\s]{2,}/g, " ").trim() !== "" &&
              parseInt(focusOutObj.style.fontWeight) !== 700
            ) {
              isAllBold = false;
            }
          });
        } else {
          if (!node.style.fontWeight || parseInt(node.style.fontWeight) === normalWiight) {
            if (
              !node.style.fontWeight &&
              (!node.parentNode.style.fontWeight ||
                (node.parentNode.style?.fontWeight && parseInt(node.parentNode.style.fontWeight) !== 700)) &&
              parseInt(focusOutObj.style.fontWeight) !== 700
            ) {
              isAllBold = false;
            }
            if (parseInt(node.style.fontWeight) === normalWiight && node.textContent !== "") {
              isAllBold = false;
            }
          }
        }
      });
    } else {
      isAllBold = parseInt(focusOutObj.style.fontWeight) === 700 ? true : false;
    }

    // For some structure whwre there is only text without any node
    let allNodeObj = $(`#${focusedOutWidgetId} .dhp-widget-inner`).contents().filter("[nodeType=3]");
    const arr = Array.from(allNodeObj.prevObject);
    arr.forEach(alObj => {
      if (alObj.nodeName === "#text" && parseInt(focusOutObj.style.fontWeight) !== 700) {
        isAllBold = false;
      }
    });

    return isAllBold;
  };

  const checkisAllItalic = originalElementInnerHtml => {
    let isAllItalic = true;
    let focusOutObj = originalElementInnerHtml;

    if (focusOutObj.querySelectorAll("*").length !== 0) {
      focusOutObj.querySelectorAll("*").forEach(node => {
        if (!node.style.fontStyle && node.tagName === "DIV") {
          node.childNodes.forEach(element => {
            if (
              element.nodeName === "#text" &&
              element.textContent.replace(/[\n\r]+|[\s]{2,}/g, " ").trim() !== "" &&
              focusOutObj.style.fontStyle !== "italic"
            ) {
              isAllItalic = false;
            }
          });
        } else {
          if (!node.style.fontStyle || node.style.fontStyle === "normal") {
            if (
              !node.style.fontStyle &&
              (!node.parentNode.style.fontStyle ||
                (node.parentNode.style?.fontStyle && node.parentNode.style.fontStyle !== "italic")) &&
              focusOutObj.style.fontStyle !== "italic"
            ) {
              isAllItalic = false;
            }
            if (node.style.fontStyle === "normal" && node.textContent !== "") {
              isAllItalic = false;
            }
          }
        }
      });
    } else {
      isAllItalic = focusOutObj.style.fontStyle === "italic" ? ture : false;
    }

    // For some structure whwre there is only text without any node
    let focusedOutWidgetId = document.querySelector(".dhp-content-editable-true-text")?.getAttribute("data-widget-id");
    let allNodeObj = $(`#${focusedOutWidgetId} .dhp-widget-inner`).contents().filter("[nodeType=3]");
    const arr = Array.from(allNodeObj.prevObject);
    arr.forEach(alObj => {
      if (alObj.nodeName === "#text" && focusOutObj.style.fontStyle !== "italic") {
        isAllItalic = false;
      }
    });

    return isAllItalic;
  };

  const checkisAllUnderline = originalElementInnerHtml => {
    let isAllUnderline = true;
    let focusOutObj = originalElementInnerHtml;

    if (focusOutObj.querySelectorAll("*").length !== 0) {
      focusOutObj.querySelectorAll("*").forEach(node => {
        if (!node.style.textDecoration && node.tagName === "DIV") {
          node.childNodes.forEach(element => {
            if (element.nodeName === "#text" && element.textContent.replace(/[\n\r]+|[\s]{2,}/g, " ").trim() !== "") {
              isAllUnderline = false;
            }
          });
        } else {
          if (!node.style.textDecoration || node.style.textDecoration === "none") {
            if (
              !node.style.textDecoration &&
              (!node.parentNode.style.textDecoration ||
                (node.parentNode.style?.textDecoration && node.parentNode.style.textDecoration !== "underline"))
            ) {
              isAllUnderline = false;
            }
            if (node.style.textDecoration === "none" && node.textContent !== "") {
              isAllUnderline = false;
            }
          }
        }
      });
    } else {
      isAllUnderline = focusOutObj.style.textDecoration === "underline" ? true : false;
    }

    // For some structure whwre there is only text without any node
    let focusedOutWidgetId = document.querySelector(".dhp-content-editable-true-text")?.getAttribute("data-widget-id");
    let allNodeObj = $(`#${focusedOutWidgetId} .dhp-widget-inner`).contents().filter("[nodeType=3]");
    const arr = Array.from(allNodeObj.prevObject);
    arr.forEach(alObj => {
      if (alObj.nodeName === "#text") {
        isAllUnderline = false;
      }
    });

    return isAllUnderline;
  };

  const checkisAllColorSame = originalElementInnerHtml => {
    let isAlloColorSame = true;
    let nodeColor;
    let focusOutObj = originalElementInnerHtml;

    focusOutObj.querySelectorAll("*").forEach(node => {
      node.childNodes.forEach(element => {
        if (element.nodeName === "#text" && element.textContent.replace(/[\n\r]+|[\s]{2,}/g, " ").trim() !== "") {
          if (!nodeColor && element.parentNode.style?.color) nodeColor = element.parentNode.style.color;
          if (nodeColor !== element.parentNode.style.color) {
            isAlloColorSame = false;
          }
        }
      });
    });

    if (isAlloColorSame) return nodeColor;
    else return false;
  };

  const handleFocusOut = updateDoubleClickActive => {
    let newArray;
    let focusOutObj = document.querySelector(".dhp-content-editable-true-text");
    let isGroupWidget = focusOutObj?.getAttribute("data-asset-type") === GROUP_WIDGET ? true : false;
    let focusedOutWidgetId = focusOutObj?.getAttribute("data-widget-id");
    let focusedOutAssetType = isGroupWidget
      ? focusOutObj?.querySelector(".focusout-child-selected").getAttribute("data-asset-type")
      : focusOutObj?.getAttribute("data-asset-type");
    let targetElem = isGroupWidget
      ? focusOutObj?.querySelector(".focusout-child-selected .dhp-widget-inner")
      : focusOutObj?.querySelector(".dhp-widget-inner");

    if (!focusedOutWidgetId) return;

    let originalElem = document.getElementById(focusedOutWidgetId);
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === focusedOutWidgetId);
    let textLength =
      focusedOutAssetType === TEXT_FRAME
        ? targetElem?.querySelector(".active-text").innerText.trim().length
        : targetElem?.innerText.trim().length;

    // set content editable false on foucus out for text-farme widget
    if (focusedOutAssetType === TEXT_FRAME)
      targetElem?.querySelector(".active-text").setAttribute("contenteditable", false);

    // step 1 : copy inner content of outside text widget
    if (textLength > 0) {
      let originalElementInnerHtml, originalTextIsBullet;

      if (isGroupWidget) {
        let mainChildWidgetId = focusOutObj?.querySelector(".focusout-child-selected").getAttribute("id");
        originalTextIsBullet = document.getElementById(mainChildWidgetId).getAttribute("data-subwidget");
        originalElementInnerHtml = originalElem.querySelector(`#${mainChildWidgetId} .dhp-widget-inner`);
      } else {
        let originalTextId = focusOutObj.getAttribute("data-widget-id");
        originalElementInnerHtml = originalElem.querySelector(`.dhp-widget-inner`);
        originalTextIsBullet = document.getElementById(originalTextId).getAttribute("data-subwidget");

        if (focusedOutAssetType === TEXT) {
          targetElem.querySelectorAll("*").forEach(node => {
            if (node.innerHTML === "" && node.style.color) node.remove();
            if (node.style.fontSize) node.style.fontSize = "";
            if (node.style.letterSpacing) node.style.letterSpacing = "";
          });
        }
      }

      // Handle the usecase if the text is bullet appiled and someone backspace the bulet nd keep writting on focusout bullet must reappear
      if (originalTextIsBullet && (targetElem.childNodes[0].tagName === "DIV" || !targetElem.childNodes[0].tagName)) {
        let listIndex = originalTextIsBullet === "UL" ? 0 : 1;
        let newDiv = document.createElement(contextualConfig.List[listIndex].tag);
        newDiv.classList = contextualConfig.List[listIndex].class;
        newDiv.style.cssText = getCssTextObjToString(contextualConfig.List[listIndex].style);
        newDiv.innerHTML = `<li>${targetElem?.innerHTML}</li>`;
        originalElementInnerHtml.innerHTML = newDiv.outerHTML;
      } else {
        originalElementInnerHtml.innerHTML = targetElem?.innerHTML;
      }

      // Handle the usecase if someone press ctrl+a -> backspace then on focus out main div should appear
      if (focusedOutAssetType === TEXT && !originalTextIsBullet && !originalElementInnerHtml.firstChild.tagName) {
        let updatedInnerHtml = document.createElement("div");
        updatedInnerHtml.innerHTML = originalElementInnerHtml.innerHTML;
        originalElementInnerHtml.innerHTML = updatedInnerHtml.outerHTML;
      }

      if (focusedOutAssetType === TEXT) {
        // If double click edit and press ctrl+A and format all text, then apply that formatting in main div
        let isAllBold = checkisAllBold(originalElementInnerHtml);
        let isAllItalic = checkisAllItalic(originalElementInnerHtml);
        let isAllUnderline = checkisAllUnderline(originalElementInnerHtml);
        let isAllColorSame = checkisAllColorSame(originalElementInnerHtml);
        let originalTextId = focusOutObj.getAttribute("data-widget-id");
        let gradActive = document.getElementById(originalTextId)?.getAttribute("data-grad-scolor");

        // If double click edit and press ctrl+A and format all text, then apply that formatting in main div
        if (originalElementInnerHtml.style?.textDecoration)
          originalElementInnerHtml.style.textDecoration = targetElem.style.textDecoration;
        if (isAllBold) originalElementInnerHtml.style.fontWeight = 700;
        else {
          let currentFontFamily = originalElementInnerHtml.style.fontFamily;
          let index = fonts.findIndex(
            font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
          );
          let normalWiight = parseInt(fonts[index].normal_weight);
          originalElementInnerHtml.style.fontWeight = normalWiight;
        }
        if (isAllItalic) originalElementInnerHtml.style.fontStyle = "italic";
        else originalElementInnerHtml.style.fontStyle = "normal";
        if (isAllUnderline && !gradActive) originalElementInnerHtml.style.textDecoration = "underline";
        if (isAllColorSame) {
          originalElementInnerHtml.style.color = isAllColorSame;
          originalElem.querySelectorAll(`.dhp-widget-inner *`).forEach(node => {
            if (node.style.color) node.style.color = isAllColorSame;
          });
        }
      }
    }

    // step 2 : show actual div
    originalElem.style.visibility = "";

    let x_al = focusedOutAssetType === TEXT_FRAME ? originalElem.getAttribute("data-x-allignment") : false;
    let y_al = focusedOutAssetType === TEXT_FRAME ? originalElem.getAttribute("data-y-allignment") : false;

    // update new content into context
    if (isGroupWidget) {
      if (focusedOutAssetType === TEXT_FRAME) {
        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            data: {
              ...widgets[targetWidgetIndex].data,
              "data-x-allignment": x_al,
              "data-y-allignment": y_al,
            },
            innerHTML: originalElem.innerHTML,
          },
        });
      } else {
        let groupId = focusedOutWidgetId;
        let targetWidgetId = focusOutObj?.querySelector(".focusout-child-selected").getAttribute("id");
        let groupWidgetInitHeight =
          isGroupWidget && parseFloat(document.getElementById(targetWidgetId)?.closest(".dhp-page-group").style.height);

        // Get group updated height and inner widget updated top and height according group
        let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
          groupId,
          targetWidgetId,
          document.querySelector(`.dhp-content-editable-true-text #${targetWidgetId} .dhp-widget-inner`).offsetHeight,
          dimension.zoom
        );

        // update DOM and group handler
        document.getElementById(groupId).style.height = `${getUnScaledValue(groupDivHeight, dimension.zoom)}px`;
        document.getElementById(targetWidgetId).style.height = parcentHeight;
        document.getElementById(targetWidgetId).style.top = parcentTop;
        document.getElementById("dhp-widget-handler").style.height = `${groupDivHeight}px`;

        // update height and top for inactive child widgets
        updateHeightAndTopForInactiveGroupChildren({
          group: document.getElementById(targetWidgetId)?.closest(".dhp-page-group"),
          groupOldHeight: groupWidgetInitHeight,
          groupNewHeight: groupDivHeight,
          activeChildId: targetWidgetId,
          zoom: dimension.zoom,
        });

        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            data: {
              ...widgets[targetWidgetIndex].data,
              "data-x-allignment": x_al,
              "data-y-allignment": y_al,
            },
            style: {
              ...widgets[targetWidgetIndex].style,
              height: `${getUnScaledValue(groupDivHeight, dimension.zoom)}px`,
            },
            innerHTML: originalElem.innerHTML,
          },
        });
      }
    } else {
      const widgetObject = widgets[targetWidgetIndex];
      // adjust left top for rotated TABLE update
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: widgetObject.style.transform,
      });
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(widgetObject.style.width),
        parseFloat(
          focusedOutAssetType === TEXT_FRAME
            ? originalElem.offsetHeight
            : originalElem.querySelector(`.dhp-widget-inner`).offsetHeight
        ),
        parseFloat(widgetObject.style.width),
        parseFloat(widgetObject.style.height),
        parseFloat(widgetTheta)
      );
      const widgetTransformStr = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          style: {
            ...widgets[targetWidgetIndex].style,
            height:
              focusedOutAssetType === TEXT_FRAME
                ? `${originalElem.offsetHeight}px`
                : `${originalElem.querySelector(`.dhp-widget-inner`).offsetHeight}px`,
            transform: widgetTransformStr,
          },
          data: {
            ...widgets[targetWidgetIndex].data,
            "data-x-allignment": x_al,
            "data-y-allignment": y_al,
            "data-version": focusedOutAssetType === TEXT_FRAME ? 2.0 : 3.0,
          },
          innerHTML: originalElem.innerHTML,
        },
      });
    }

    updateWidgets(newArray);
    updateDoubleClickActive(false);
    if (document.getElementById("contenteditableStyle")) document.getElementById("contenteditableStyle").remove();
  };

  useEffect(() => {
    if (textFocusOut) {
      handleFocusOut();
      setTextFocusOut(false);
    }
  }, [textFocusOut]);

  return {
    setTextFocusOut,
    handleFocusOut,
  };
};

export default useTextFocusOut;
