import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import global from "../../../../scss/dhp.scss";

import { Icon } from "../../../ui/icon";
import { Tooltip } from "reactstrap";
import { EditorContext } from "../../../../containers/editor/EditorLayout";
import * as constant from "../../../../constants/editor";

let style = Object.assign({}, global);

const AssetName = props => {
  const { documentType } = useContext(EditorContext);

  const userInfo = useSelector(state => state?.auth?.user);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div className={cx(style["d-flex"], style["justify-content-between"], style["pr-20"])}>
      <h6 className={cx(style["mb-3"], style["fw-6"], style["d-flex"], style["align-items-center"])}>
        <Link
          to="#"
          onClick={e => (e.preventDefault(), props.handleWidgetAction("*", false, false, false, "returnToWidgetList"))}>
          <Icon icon="ui-arrow-left" additionalclass="mr-2" />
        </Link>
        {props.assetName}
      </h6>

      {props.showTooltip && (
        <div>
          <span className={cx(style["cursor-pointer"])} id={`tooltip-${props.assetName}`}>
            <Icon icon="ui-info" additionalclass="font-base" />
          </span>
          <Tooltip
            placement="bottom-end"
            isOpen={tooltipOpen}
            target={`tooltip-${props.assetName}`}
            toggle={toggle}
            className={cx(style["tooltip-upload"])}
            innerClassName="text-left p-3">
            <h6 className={cx(style["fw-6"], style["font-15"], style["mb-0"])}>Images</h6>
            <ul className={cx(style["mb-3"])}>
              <li>▪ JPG, PNG, WebP: Up to 5 MB</li>
              <li>▪ SVG: Up to 2 MB</li>
              <li>▪ GIF: Up to 1 MB</li>
            </ul>
            <h6 className={cx(style["fw-6"], style["font-15"], style["mb-0"])}>Videos</h6>
            <ul
              className={cx({
                [style["mb-3"]]: ![constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType),
              })}>
              <li>▪ MP4, MOV, WebM</li>
              <li>▪ Up to 100 MB</li>
            </ul>
            {![constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType) && userInfo.video_enabled && (
              <>
                <h6 className={cx(style["fw-6"], style["font-15"], style["mb-0"])}>Audio</h6>
                <ul>
                  <li>▪ MP3, MP4A, WAV, OGG</li>
                  <li>▪ Up to 10 MB</li>
                </ul>
              </>
            )}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

//Props type validation
AssetName.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool,
};
export default AssetName;
