import React, { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import useCloneBackground from "./useCloneBackground";
import useCloneWidget from "./useCloneWidget";
import useSetDimension from "./useSetDimension";
import * as constant from "../constants/editor";
import { getRandomString } from "../_helpers/utils";
import { EditorWrapperContext } from "../containers/editor/EditorWrapper";

const useClonePage = () => {
  let { metadata, blockNodes, pageNodes, dimension, documentType } = useContext(EditorContext);
  const { updateScrollEvent } = useContext(EditorWrapperContext);

  const { cloneBackground } = useCloneBackground();
  const { cloneBlockWidgets } = useCloneWidget();
  const { setTotalHeight } = useSetDimension();

  const clone = () => {
    // To prevent auto scroll
    updateScrollEvent(true);

    let reindexedPageArray, newPageIdx;
    let newPageId = metadata.activePageId;

    if (![constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType)) {
      newPageId = "dhp-page-" + getRandomString(8);
      newPageIdx = metadata.activePageIdx + 1;
      let targetPageObj = { ...pageNodes[metadata.activePageIdx] };
      targetPageObj = { ...targetPageObj, pageId: newPageId, pageIdx: newPageIdx };
      reindexedPageArray = [...pageNodes.slice(0, newPageIdx), targetPageObj];

      pageNodes.slice(newPageIdx).forEach(element => {
        reindexedPageArray.push({ ...element });
      });

      reindexedPageArray.map((item, idx) => (item["pageIdx"] = idx));
    }

    let newBlockId = "dhp-block-" + getRandomString(8);
    let newBlockIdx = metadata.activeBlockIdx + 1;
    let targetBlockId = metadata.activeBlockId;
    let targetBlockObj = { ...blockNodes[metadata.activeBlockIdx] };
    targetBlockObj = {
      ...targetBlockObj,
      pageId: newPageId,
      blockId: newBlockId,
      blockIdx: newBlockIdx,
    };

    if ([constant.TYPE_PROPOSAL].includes(documentType)) {
      targetBlockObj = {
        ...targetBlockObj,
        blockName: "",
      };
    }
    let reindexedBlockArray = [...blockNodes.slice(0, newBlockIdx), targetBlockObj];

    blockNodes.slice(newBlockIdx).forEach(element => {
      reindexedBlockArray.push({ ...element });
    });

    reindexedBlockArray.map((item, idx) => (item["blockIdx"] = idx));

    const { updatedBackgroundColorObj, updatedBackgroundImageObj } = cloneBackground(
      targetBlockId,
      newBlockId,
      newPageId
    );

    if (reindexedPageArray) {
      cloneBlockWidgets(
        targetBlockId,
        newBlockId,
        newBlockIdx,
        newPageId,
        newPageIdx,
        reindexedPageArray,
        reindexedBlockArray,
        updatedBackgroundColorObj,
        updatedBackgroundImageObj
      );
      setTotalHeight(reindexedBlockArray, dimension.label);
    } else {
      cloneBlockWidgets(
        targetBlockId,
        newBlockId,
        newBlockIdx,
        newPageId,
        newPageIdx,
        false,
        reindexedBlockArray,
        updatedBackgroundColorObj,
        updatedBackgroundImageObj
      );
      setTotalHeight(reindexedBlockArray);
    }
  };

  return { clone };
};

export default useClonePage;
