import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { COMPANY_PROFILE_PERMISSIONS } from "../constants/profile";
import useCompanyProfiles from "./useCompanyProfiles";

const useAccessControl = () => {
  const profileInfo = useSelector(state => state.auth?.user?.profile);
  const [profileData, setProfileData] = useState({});
  const { profilePermissions } = useCompanyProfiles(profileData);

  const [userProfile, setUserProfile] = useState(); // Default profile

  const setProfile = profile => {
    setUserProfile(profile);
  };

  useEffect(() => {
    if (profileInfo) {
      let profileName = profileInfo?.name?.toLowerCase().replace(/\s+/g, "_");
      setUserProfile(profileName);

      setProfileData({ profileId: profileInfo._id, profileName: profileName });
    }
  }, [profileInfo]);

  const checkAccess = useCallback(
    (module, action) => {
      if (!profilePermissions) return undefined;
      
      // Get the permissions for the current profile
      const profilePermissionList = profilePermissions?.flatMap(profile => profile?.permissions);
      if (!profilePermissionList) return undefined;

      // Check if the given module exists in the permissions
      const modulePermission = profilePermissionList?.find(permission => permission?.module === module);
      if (!modulePermission || modulePermission?.enabled === false) {
        return false; // Module not found
      }

      // If action is provided, check if it exists in the actions
      if (action) {
        return modulePermission.actions?.includes(action);
      }

      // If no action is provided, just check the module existence
      return true;
    },
    [profilePermissions]
  );

  const getPermissionsForProfile = profile => {
    switch (profile) {
      case "super_admin":
        return COMPANY_PROFILE_PERMISSIONS.flatMap(profile => profile.permissions);
      case "admin":
        return COMPANY_PROFILE_PERMISSIONS.filter(profile => profile.profileSlug !== "super_admin_permissions").flatMap(
          profile => profile.permissions
        );
      case "standards":
        return COMPANY_PROFILE_PERMISSIONS.filter(profile => profile.profileSlug === "general_permissions").flatMap(
          profile => profile.permissions
        );
      default:
        return [];
    }
  };

  return { setProfile, checkAccess, userProfile };
};

export default useAccessControl;
