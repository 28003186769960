import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

import { EditorContext } from "../../containers/editor/EditorLayout";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../../constants/editor";
import { getClassListToString } from "../../_helpers/utils";
import useReplaceDuplicateShapeId from "../../hooks/useReplaceDuplicateShapeId";

let style = Object.assign({}, global);

const Block = props => {
  let { blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType } = props?.docPagesMeta
    ? { ...props.docPagesMeta }
    : useContext(EditorContext);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const safariBugfixCss = isSafari ? { willChange: "filter" } : {};
  const { replaceDuplicateIdFromSVG } = useReplaceDuplicateShapeId();

  // Replace duplicate id after widget modification
  useEffect(() => {
    replaceDuplicateIdFromSVG();
  }, [widgets]);

  // Replace duplicate id for shape use in shape border
  useEffect(() => {
    replaceDuplicateIdFromSVG();
  }, []);

  return (
    <div
      style={
        [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
          ? { transformOrigin: "0px 0px", transform: `scale(${props.scaleFactor})` }
          : { height: `${(props.figureWidth / dimension.width) * dimension.height}px` }
      }>
      {blockNodes.map(
        blockNode =>
          blockNode.pageId === props.pageNode.pageId && (
            <div
              key={blockNode.blockId}
              {...(props?.isPublishedPage && documentType === TYPE_PROPOSAL && { id: blockNode?.blockId })}
              style={
                [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
                  ? blockNode.style
                  : { ...blockNode.style, transformOrigin: "0px 0px", transform: `scale(${props.scaleFactor})` }
              }>
              {backgroundColors.map(
                (backgroundColor, i) =>
                  backgroundColor.blockId === blockNode.blockId && <div key={i} style={backgroundColor.style}></div>
              )}

              {backgroundImages.map(
                (backgroundImage, i) =>
                  backgroundImage.blockId === blockNode.blockId && <div key={i} style={backgroundImage.style}></div>
              )}

              {widgets.map(
                widget =>
                  widget.blockId === blockNode.blockId && (
                    <div
                      key={widget.id}
                      {...(props?.isPreviewPresentMode && widget.data)}
                      {...(props?.isPreviewPresentMode && { className: getClassListToString(widget.classLists) })}
                      {...(!props?.isPreviewPresentMode && { className: "dhp-widgets" })}
                      {...(!props?.isPreviewPresentMode &&
                        widget.classLists.includes("dhp-widget-hyperlinked") && {
                          className: "dhp-widgets dhp-widget-hyperlinked",
                        })}
                      {...(!props?.isPreviewPresentMode && widget.data)}
                      style={{ ...widget.style, ...safariBugfixCss }}
                      dangerouslySetInnerHTML={{ __html: widget.innerHTML }}
                      {...(props.isPresent ? { id: widget.id } : {})}></div>
                  )
              )}
            </div>
          )
      )}
    </div>
  );
};
Block.propTypes = {
  scaleFactor: PropTypes.number.isRequired,
  figureWidth: PropTypes.number.isRequired,
  pageNode: PropTypes.object,
  docPagesMeta: PropTypes.object,
  isPreviewPresentMode: PropTypes.bool,
};

const CanvasPageHtmlGenaretor = props => {
  let { dimension, documentType } = props?.docPagesMeta?.dimension
    ? { ...props.docPagesMeta }
    : useContext(EditorContext);

  let scaleFactor = props?.origin === "notification"?Math.max(props.figureWidth / dimension.width,props.figureWidth / dimension.height) :props.figureWidth / dimension.width;
  let figureHeight = props?.figureHeight
    ? props?.figureHeight
    : (props.figureWidth / dimension.width) * dimension.height;

  return (
    <React.Fragment key={props.pageNode.pageIdx}>
      <div className={props.className} id={props?.id} style={props?.style}>
        <figure
          className={style["mb-0"]}
          style={{
            height: `${figureHeight}px`,
            width: `${props.figureWidth}px`,
          }}>
          {[TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && (
            <div>
              <Block
                scaleFactor={scaleFactor}
                pageNode={props.pageNode}
                figureWidth={props.figureWidth}
                docPagesMeta={props.docPagesMeta}
                isPreviewPresentMode={props?.isPreviewPresentMode}
                isPresent={props?.isPresent}
                isPublishedPage={props?.isPublishedPage}
              />
            </div>
          )}

          {![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && (
            <Block
              scaleFactor={scaleFactor}
              pageNode={props.pageNode}
              figureWidth={props.figureWidth}
              docPagesMeta={props.docPagesMeta}
              isPreviewPresentMode={props?.isPreviewPresentMode}
              isPresent={props?.isPresent}
            />
          )}
        </figure>

        {props.pageType && (
          <span>
            {props.pageType} {props.pageNode.pageIdx + 1}
          </span>
        )}
      </div>
    </React.Fragment>
  );
};
CanvasPageHtmlGenaretor.propTypes = {
  className: PropTypes.string,
  figureWidth: PropTypes.number.isRequired,
  figureHeight: PropTypes.number,
  pageNode: PropTypes.object,
  pageType: PropTypes.string,
  docPagesMeta: PropTypes.object,
  id: PropTypes.string,
  style: PropTypes.object,
  isPreviewPresentMode: PropTypes.bool,
};

export default CanvasPageHtmlGenaretor;
