import { useContext, useEffect, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  floatToFloatSec,
  getCssTransformObj,
  getHMSToSec,
  getSecToHMS,
  getWidgetAndParentsDomReference,
  isMediaPlaying,
  playMedia,
  removeWhiteSpaceBetweenTags,
} from "../_helpers/utils";
import { COLLAGE } from "../constants/editor";
import { EditorPanelContext } from "../containers/editor/EditorPanel";

const useVideo = () => {
  const { widgets, updateWidgets, metadata } = useContext(EditorContext);
  const { togglePlayButton, updateTogglePlayButton } = useContext(EditorPanelContext);

  const [unmutedFirstTime, setUnmutedFirstTime] = useState(false);

  useEffect(() => {
    // play video after unmute, if was in palying mode (applicable for existing videos)
    if (widgets && togglePlayButton && unmutedFirstTime) {
      setUnmutedFirstTime(false);
      VDO.common.videoPlayer({ event: "PLAY", widgetId: metadata.activeWidgetId[0] });
    }
  }, [widgets, togglePlayButton, unmutedFirstTime]);

  const VDO = {
    common: {
      generateMediaPlayer: ({ widget, playerArea }) => {
        let mediaPlayerCss;
        const isChildWidget = widget?.parentElement?.dataset?.assetType === "groups";

        if (widget.dataset.assetType === COLLAGE) {
          const { width: frameWidth, height: frameHeight } = playerArea.closest(".media-grand-parent")?.style;
          const {
            translate: { x: mediaTransX, y: mediaTransY },
          } = getCssTransformObj({
            transform: playerArea.style.transform,
          });

          mediaPlayerCss = `
          width: ${frameWidth}; 
          height: ${frameHeight}; 
          left: ${Math.abs(parseFloat(mediaTransX))}px; 
          top: ${Math.abs(parseFloat(mediaTransY))}px;
          `;
        } else if (isChildWidget) {
          const flippableNode = widget.querySelector(".flippable");
          mediaPlayerCss = `
          width: ${flippableNode.style.width}; 
          height: ${flippableNode.style.height}; 
          top: 0;
          `;
        } else {
          mediaPlayerCss = `
          width: ${playerArea.style.width}; 
          height: ${playerArea.style.height}; 
          top: 0;
          `;
        }

        const smallWidget = parseFloat(widget.style.width) < 200;
        const spinnerClass = smallWidget ? "spinner-border-sm" : "";
        const mediaPlayer = document.createElement("div");
        mediaPlayer.innerHTML = removeWhiteSpaceBetweenTags(`
          <div class="media-player" style="cursor: pointer; position: absolute; ${mediaPlayerCss}">
              <div class="media-controls" style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
                <i class="media-status icon-ui-pause" style="padding: 4%; border-radius: 50%; background: rgba(0, 0, 0, 0.5); color: #ffffff; cursor: pointer; display: none;"></i>
                <div class="media-loader spinner-border ${spinnerClass} text-light" role="status" style="display: block;">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="media-timeline" style="left: 5%; bottom: 8%; position: absolute; width: 90%; background: #e6e6e6; display: none;">
                  <div class="media-progress" style="background: #ed7d31; pointer-events: none; width: 0%; height: 5px;"></div>
              </div>
          </div>
        `);
        playerArea.appendChild(mediaPlayer.childNodes[0]);
      },

      generateLoaderInEditor: ({ widgetId, loader }) => {
        const {
          widget: {
            node: widget,
            isChildWidget,
            parent: { node: rootWidget },
          },
        } = getWidgetAndParentsDomReference(widgetId);

        if (isChildWidget) {
          // in-case of group widget, resetting loader size and position for child video widget
          let i = 0,
            stop,
            dynamicGroup,
            parentGroup = widget.closest(".dhp-page-group");

          do {
            const wrapper = document.createElement("div");
            wrapper.className = `group-${i}`;

            if (i === 0) {
              wrapper.style.cssText = parentGroup.style.cssText;
              wrapper.innerHTML = removeWhiteSpaceBetweenTags(`
                <div class="loader-position" style="${widget.style.cssText}">
                    <div class="loader-wrapper">
                        <div class="loader-spinner spinner-border text-light" role="status"><span class="sr-only">Loading...</span></div>
                    </div>
                </div>
              `);
              dynamicGroup = wrapper;
            } else {
              const nextParent = parentGroup.parentElement;
              if ([parentGroup.id, nextParent?.id].includes(rootWidget.id)) stop = true;
              else {
                parentGroup = nextParent;
                wrapper.style.cssText = parentGroup.style.cssText;
                wrapper.appendChild(dynamicGroup);
                dynamicGroup = wrapper;
              }
            }
            if (i === 100) stop = true; // in-case of failure break the infinite loop
            i++;
          } while (!stop);

          loader.innerHTML = dynamicGroup.outerHTML;
        } else {
          // in-case of single video widgets
          loader.innerHTML = removeWhiteSpaceBetweenTags(`
            <div class="loader-position" style="width: 100%; height: 100%">
                <div class="loader-wrapper">
                    <div class="loader-spinner spinner-border text-light" role="status"><span class="sr-only">Loading...</span></div>
                </div>
            </div>
          `);
        }
      },

      videoPlayer: ({ event, widgetId, widgetNode, videoNode, muted }) => {
        if (!["PLAY", "PAUSE"].includes(event) && (!widgetId || !videoNode)) return;

        const {
          widget,
          video,
          volume,
          trimStart: startTime,
          trimEnd: stopTime,
          isLooped,
        } = VDO.common.getVideoNodeAndDataRef({ widgetId, widgetNode, videoNode });

        video.dataset.loop = isLooped; // bugfix: storing loop data in video element itself as isLooped data sometimes coming wrong within 'timeupdate' event (this loop data will only be calculated after play/pause button is clicked)

        const isPlaying = isMediaPlaying({ mediaElm: video, startTime, stopTime });

        if (widgetNode) {
          // applicable for preview/present/published page
          let timeoutPause = 0;
          let hoveringOnPlayerArea;
          const playerArea =
            widget.dataset.assetType === COLLAGE ? video.closest(".media-parent") : video.closest(".dhp-widgets");
          const mediaPlayerPresent = playerArea.querySelector(".media-player");

          if (event === "PLAY" && !isPlaying) {
            // play video with volume muted as we have to auto-play (play event triggers only once after landing into preview/present/published page)
            video.volume = volume / 100;
            if (muted !== undefined) video.muted = muted;
            else video.muted = true;
            playMedia(video, startTime, stopTime, 0);
          }

          if (event === "PAUSE" && isPlaying) {
            video.pause();
          }

          // event handlers (if media player present, not required to add it again because event handlers were already added too)
          if (mediaPlayerPresent) return;

          // append media player
          VDO.common.generateMediaPlayer({ widget, playerArea });

          const { mediaPlayer, mediaStatus, mediaLoader, mediaTimeline, mediaProgress } =
            VDO.common.getMediaPlayerNodeAndDataRef({ playerArea });

          ["canplay", "pause", "play", "timeupdate", "waiting", "suspend", "stalled"].forEach(event => {
            video.addEventListener(event, () => {
              const curTime = floatToFloatSec(video.currentTime);

              if (["canplay"].includes(event)) {
                mediaLoader.style.display = "none";
                mediaStatus.style.display = "block";
                mediaTimeline.style.display = "block";
                mediaPlayer.style.display = "none";
              }

              if (["pause"].includes(event)) {
                setTimeout(() => {
                  mediaPlayer.style.display = "block";
                  mediaStatus.classList.remove("icon-ui-pause");
                  mediaStatus.classList.add("icon-ui-play");
                  mediaTimeline.style.display = "none";
                  if (timeoutPause > 0) timeoutPause = 0;
                }, timeoutPause);
              }

              if (["play"].includes(event)) {
                mediaStatus.classList.remove("icon-ui-play");
                mediaStatus.classList.add("icon-ui-pause");
                mediaPlayer.style.display = "none";
              }

              if (["timeupdate"].includes(event)) {
                if (video.paused) return; // stop un-necessary executions as timeupdate event triggers even after video is paused

                if (!video.paused && !hoveringOnPlayerArea) {
                  mediaLoader.style.display = "none";
                  mediaStatus.style.display = "block";
                  mediaTimeline.style.display = "block";
                  mediaPlayer.style.display = "none";
                }
                if (curTime >= startTime && curTime <= stopTime) {
                  // when video is within playable time range
                  const offsetTime = curTime - startTime;
                  const trimmedDuration = stopTime - startTime;
                  const progress = (100 / trimmedDuration) * offsetTime;
                  mediaProgress.style.width = `${progress}%`;
                  mediaTimeline.style.display = "block";
                } else {
                  // when video is outside playable time range
                  video.currentTime = startTime;
                  mediaProgress.style.width = "0%";
                  if (isLooped) {
                    if (video.paused) playMedia(video);
                  } else {
                    video.pause();
                    timeoutPause = 500;
                  }
                }
              }

              if (["waiting", "suspend", "stalled"].includes(event) && !video.paused && curTime < stopTime) {
                mediaLoader.style.display = "block";
                mediaStatus.style.display = "none";
                mediaTimeline.style.display = "none";
                mediaPlayer.style.display = "block";
              }
            });
          });

          ["mouseenter", "mousemove", "mouseleave", "click"].forEach(event => {
            playerArea.addEventListener(event, () => {
              if (["mouseenter", "mousemove"].includes(event)) {
                hoveringOnPlayerArea = true;
                mediaPlayer.style.display = "block";
              }
              if (["mouseleave"].includes(event) && !video.paused && video.readyState > 0) {
                hoveringOnPlayerArea = null;
                mediaPlayer.style.display = "none"; // if video is playing and have some data
              }
              if (["click"].includes(event)) {
                const isPlaying = isMediaPlaying({ mediaElm: video, startTime, stopTime });
                if (!isPlaying) playMedia(video, startTime, stopTime);
                else video.pause();
              }
            });
          });
        }

        if (widgetId) {
          // applicable for editor
          const loader = document.querySelector("#dhp-widget-handler .media-loader");

          if (event === "PLAY" && !isPlaying) {
            // append loader
            VDO.common.generateLoaderInEditor({ widgetId, loader });

            // play video with volume
            video.volume = volume / 100;
            playMedia(video, startTime, stopTime);
          }

          if (event === "PAUSE" && isPlaying) {
            video.pause();
          }

          // event handlers
          ["canplay", "pause", "play", "timeupdate", "waiting", "suspend", "stalled"].forEach(event => {
            video.addEventListener(event, () => {
              const curTime = floatToFloatSec(video.currentTime);
              if (["canplay", "pause"].includes(event)) loader.classList.add("d-none");
              if (["pause", "play"].includes(event)) {
                updateTogglePlayButton(event === "play");
                widget.setAttribute("data-playing", event === "play");
              }
              if (["timeupdate"].includes(event)) {
                if (video.paused) return; // stop un-necessary executions as timeupdate event triggers even after video is paused

                if (!video.paused) loader.classList.add("d-none");
                if (!(curTime >= startTime && curTime <= stopTime)) {
                  // when video is outside playable time range
                  video.currentTime = startTime;
                  if (isLooped && video.dataset.loop === "true") {
                    if (video.paused) playMedia(video);
                  } else {
                    video.pause();
                  }
                }
              }
              if (["waiting", "suspend", "stalled"].includes(event) && !video.paused && curTime < stopTime) {
                loader.classList.remove("d-none");
              }
            });
          });
        }
      },

      getMediaPlayerNodeAndDataRef: ({ playerArea }) => {
        const mediaPlayer = playerArea.querySelector(".media-player");
        const mediaStatus = mediaPlayer.querySelector(".media-controls .media-status");
        const mediaLoader = mediaPlayer.querySelector(".media-controls .media-loader");
        const mediaTimeline = mediaPlayer.querySelector(".media-timeline");
        const mediaProgress = mediaPlayer.querySelector(".media-timeline .media-progress");
        return { mediaPlayer, mediaStatus, mediaLoader, mediaTimeline, mediaProgress };
      },

      getVideoNodeAndDataRef: ({ widgetId, widgetNode, videoNode }) => {
        const widget = widgetId ? document.getElementById(widgetId) : widgetNode;
        const assetTypeCollage = widget.dataset.assetType === COLLAGE;
        const video = assetTypeCollage ? videoNode : widget?.querySelector("video");
        const { duration: durationInHMS, volume, trim, loop } = assetTypeCollage ? video?.dataset : widget?.dataset;
        const durationInSec = getHMSToSec({ hms: durationInHMS });
        const trimArray = trim ? trim.split(",")?.map(e => parseFloat(e)) : null;
        const isTrimmed = Boolean(trimArray?.length === 2);
        const trimStart = isTrimmed ? trimArray?.[0] : 0;
        const trimEnd = isTrimmed ? trimArray?.[1] : durationInSec;

        return {
          widget,
          video,
          src: video.src,
          poster: video.poster,
          volume: volume ? parseInt(volume) : 100,
          durationInSec,
          durationInHMS,
          trimStart,
          trimEnd,
          isMuted: Boolean(video.getAttribute("muted")),
          isLooped: Boolean(loop === "true"),
          isTrimmed: Boolean(trimStart > 0 || trimEnd < durationInSec),
        };
      },

      updateReactDom: ({ widgetId, newData = {}, durationData = {}, innerHTML }) => {
        // update react DOM - modify shadow DOM on save event
        let updatedData = {};
        const updatedInnerHtml = innerHTML ? { innerHTML } : {};
        const {
          widget: {
            node: widgetNode,
            isChildWidget,
            parent: { id: widgetParentId },
          },
        } = getWidgetAndParentsDomReference(widgetId);

        if (isChildWidget) {
          widgetId = widgetParentId;
          Object.keys(newData).forEach(attr => widgetNode.setAttribute(attr, newData[attr]));
          updatedInnerHtml.innerHTML = document.getElementById(widgetParentId).innerHTML;
        } else {
          updatedData = newData;
        }

        updateWidgets(
          widgets.map(widget => {
            if (widget.id === widgetId) {
              return {
                ...widget,
                duration: {
                  ...widget.duration,
                  ...durationData,
                },
                data: {
                  ...widget.data,
                  ...updatedData,
                },
                ...updatedInnerHtml,
              };
            } else {
              return widget;
            }
          })
        );
      },
    },

    volume: {
      fetch: ({ widgetId }) => {
        const data = VDO.common.getVideoNodeAndDataRef({ widgetId });
        return data;
      },

      apply: ({ widgetId, volume, finalUpdate }) => {
        const { video, isMuted } = VDO.common.getVideoNodeAndDataRef({ widgetId });

        // apply volume
        video.volume = volume / 100;

        // update context
        if (finalUpdate) {
          if (isMuted) {
            video.removeAttribute("muted");
            setUnmutedFirstTime(true);
          }
          VDO.common.updateReactDom({
            widgetId,
            newData: { "data-volume": volume },
          });
        }
      },
    },

    trim: {
      fetch: ({ widgetId }) => {
        const data = VDO.common.getVideoNodeAndDataRef({ widgetId });
        return data;
      },

      apply: ({ widgetId, trimData, finalUpdate }) => {
        // update context
        if (finalUpdate) {
          let targetWidgetIndex = widgets.findIndex(widget => widget.id === widgetId);
          let isInLoop = widgets[targetWidgetIndex].data["data-loop"];
          let widgetTotalDutaion =
            widgets[targetWidgetIndex].duration?.totalTime ??
            getHMSToSec({ hms: widgets[targetWidgetIndex].data["data-duration"] });
          let widgetDurationStartTime = widgets[targetWidgetIndex].duration?.startTime
            ? widgets[targetWidgetIndex].duration.startTime
            : 0;

          // if video is not in loop or if in loop but new trimmed duration in more than page timeline video end duration, then update video widget end duration which is new trimmed duration of that video
          if (!isInLoop || (isInLoop && trimData.trimmedDuration > parseFloat(widgetTotalDutaion))) {
            const { video } = VDO.common.getVideoNodeAndDataRef({ widgetId });
            document.querySelector(`#${widgetId} video`).src = `${video.src}#t=${trimData.trimStart}`;

            VDO.common.updateReactDom({
              widgetId,
              newData: {
                "data-trim": `${trimData.trimStart},${trimData.trimEnd}`,
                "data-trimmed-duration": getSecToHMS({ sec: trimData.trimmedDuration }),
              },
              durationData: {
                endTime: `${parseFloat(widgetDurationStartTime) + parseFloat(trimData.trimmedDuration)}s`,
                totalTime: `${
                  parseFloat(widgetDurationStartTime) +
                  parseFloat(trimData.trimmedDuration) -
                  parseFloat(widgetDurationStartTime)
                }s`,
              },
              innerHTML: document.getElementById(widgetId).innerHTML,
            });
          }
          // else no need to update end time duration of the widget update only new video trimmed duration
          else {
            VDO.common.updateReactDom({
              widgetId,
              newData: {
                "data-trim": `${trimData.trimStart},${trimData.trimEnd}`,
                "data-trimmed-duration": getSecToHMS({ sec: trimData.trimmedDuration }),
              },
            });
          }
        }
      },
    },
  };

  return {
    fetchVolume: VDO.volume.fetch,
    applyVolume: VDO.volume.apply,
    fetchTrim: VDO.trim.fetch,
    applyTrim: VDO.trim.apply,
    videoPlayer: VDO.common.videoPlayer,
  };
};

export default useVideo;
