import { useContext } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { RGBToHex, changeWidgetColorAccordingBackground, getRandomString, hexToRGB, shuffle } from "../_helpers/utils";
import { widgetConfig } from "../components/Editor/editor_config";
import {
  ANIMATION,
  BRAND,
  COLLAGE,
  ICON,
  ILLUSTRATION,
  LINE,
  MONO,
  PICTURE,
  QR_CODE,
  SHAPE,
  STICKER,
  STOCK,
  SVG,
  TEXT,
  TEXT_FRAME,
  TYPE_FORM,
  UPLOAD,
  UPLOAD_VIDEO,
  VIDEO,
} from "../constants/editor";
import { EditorSidebarContext } from "../containers/editor/EditorSidebar";

const useThemeColor = () => {
  let {
    metadata,
    backgroundColors,
    backgroundImages,
    pageNodes,
    blockNodes,
    widgets,
    updateWidgets,
    updateMetadata,
    dimension,
  } = useContext(EditorContext);
  let { activeColorThemePageIdx } = useContext(EditorSidebarContext);

  let newDataAttr = {};

  const getNode = currentActiveWidgetId => {
    let node;
    if (document.querySelector(`#${currentActiveWidgetId} svg rect`)) {
      node = document.querySelector(`#${currentActiveWidgetId} svg rect`);
    } else if (document.querySelector(`#${currentActiveWidgetId} svg circle`)) {
      node = document.querySelector(`#${currentActiveWidgetId} svg circle`);
    } else if (document.querySelector(`#${currentActiveWidgetId} svg polygon`)) {
      node = document.querySelector(`#${currentActiveWidgetId} svg polygon`);
    } else if (document.querySelector(`#${currentActiveWidgetId} svg path`)) {
      node = document.querySelector(`#${currentActiveWidgetId} svg path`);
    }
    return node;
  };

  const getDarkColorsArray = colorArray => {
    let darkcolorArray = [];
    let match, range;

    colorArray.forEach(color => {
      let rgbColor = hexToRGB(color);
      match = rgbColor.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
      range = Math.round((parseInt(match[1]) * 299 + parseInt(match[2]) * 587 + parseInt(match[3]) * 114) / 1000);

      if (range < 125) {
        darkcolorArray.push(color);
      }
    });

    return darkcolorArray;
  };

  const getLightColorsArray = colorArray => {
    let lightcolorArray = [];
    let match, range;

    colorArray.forEach(color => {
      let newRgbColor = hexToRGB(color);
      match = newRgbColor.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
      range = Math.round((parseInt(match[1]) * 299 + parseInt(match[2]) * 587 + parseInt(match[3]) * 114) / 1000);

      if (range > 125) {
        lightcolorArray.push(color);
      }
    });

    return lightcolorArray;
  };

  const getpreviousWidgetListFromSameBlock = (widgetId, targetBlockId, targetPageId) => {
    let allWidgetIdList = [];
    let targetWidgetList = [];

    document.querySelectorAll(`#${targetBlockId} .dhp-page-widget`).forEach(element => {
      allWidgetIdList.push(element.getAttribute("id"));
    });

    // remove all id after target widget id from array
    let targetIndex = allWidgetIdList.findIndex(widget => widget === widgetId);
    allWidgetIdList.length = targetIndex;

    // kept only sahpe and uploaded mono type widget in array
    allWidgetIdList.forEach(item => {
      let colorClass = [];
      let element = document.getElementById(item);

      if (element.getAttribute("data-asset-type") === UPLOAD) {
        element.querySelectorAll(` svg *`).forEach(function (node) {
          if (node.nodeName !== "g" && node.style && RGBToHex(node.style.fill) !== "") {
            let dataClassName = node.dataset.class;
            if (!colorClass.includes(dataClassName)) {
              colorClass.push(dataClassName);
            }
          }
        });
      }

      if (
        element.getAttribute("data-asset-type") === SHAPE ||
        (element.getAttribute("data-asset-type") === UPLOAD &&
          (colorClass.length === 0 || element.getAttribute("data-color-scheme") === "mono") &&
          element.getAttribute("data-file-type") === "svg")
      ) {
        targetWidgetList.push(item);
      }

      if (
        (element.getAttribute("data-asset-type") === PICTURE &&
          parseFloat(element.querySelector(".dhp-widget-inner").style.opacity) > 0.5) ||
        (element.getAttribute("data-asset-type") === COLLAGE &&
          parseFloat(element.querySelector(".dhp-widget-inner").style.opacity) > 0.5) ||
        element.getAttribute("data-asset-type") === VIDEO ||
        (element.getAttribute("data-asset-type") === UPLOAD && element.getAttribute("data-file-type") !== "svg")
      ) {
        targetWidgetList.push(item);
      }

      if (
        element.getAttribute("data-asset-type") === TEXT_FRAME &&
        parseInt(element.getAttribute("data-version")) >= 3
      ) {
        targetWidgetList.push(item);
      }
    });

    return targetWidgetList;
  };

  const checkRange = (widgetId, bColor, targetBlockId, targetPageId) => {
    let behindWidgetId, behindWidgetColor, behindAssetUploadedSvg;
    let behindWidgetColorRange;
    let zoomval = 100 / parseFloat(dimension.zoom);
    let parentElem = document.getElementById(targetBlockId);
    let parentPos = parentElem.getBoundingClientRect();

    let childPos = document.getElementById(widgetId).getBoundingClientRect();
    let widgetLeft = parseFloat(childPos.left - parentPos.left) * zoomval;
    let widgetCenter = parseFloat(widgetLeft + Math.round((childPos.width * zoomval) / 2));
    let widgetRight = parseFloat(parentElem.style.width) + (childPos.right - parentPos.right) * zoomval;
    let widgetTop = parseFloat(childPos.top - parentPos.top) * zoomval;
    let widgetMiddle = parseFloat(widgetTop + parseFloat((childPos.height * zoomval) / 2));
    let widgetBottom = parseFloat(parentElem.style.height) + (childPos.bottom - parentPos.bottom) * zoomval;
    let targetWidgetList = getpreviousWidgetListFromSameBlock(widgetId, targetBlockId, targetPageId);

    // check all the elem from the array (active widget's previous widgets by layer), in active block if there is any widget behind active widget
    targetWidgetList.forEach(item => {
      let elemPos = document.getElementById(item).getBoundingClientRect();
      let elemLeft = parseFloat(elemPos.left - parentPos.left) * zoomval;
      let elemRight = parseFloat(parentElem.style.width) + (elemPos.right - parentPos.right) * zoomval;
      let elemTop = parseFloat(elemPos.top - parentPos.top) * zoomval;
      let elemBottom = parseFloat(parentElem.style.height) + (elemPos.bottom - parentPos.bottom) * zoomval;

      if (
        (widgetLeft > elemLeft && widgetRight < elemRight && widgetBottom < elemBottom && widgetTop > elemTop) ||
        (widgetCenter > elemLeft && widgetCenter < elemRight && widgetMiddle > elemTop && widgetMiddle < elemBottom)
      ) {
        behindWidgetId = item;
      }
    });

    // if there is no widget behind, active widget color will chose according background color
    if (!behindWidgetId) {
      behindWidgetColor = bColor;
      behindWidgetColorRange = changeWidgetColorAccordingBackground(hexToRGB(behindWidgetColor));

      let backgroundImageActiveIndex = backgroundImages.findIndex(
        backgroundImage => backgroundImage.blockId === metadata.activeBlockId
      );

      //If there is any background image behind the widget
      if (backgroundImageActiveIndex > -1) behindAssetUploadedSvg = true;
      else behindAssetUploadedSvg = false;
    }
    // if there is picture or video widget behind, active widget color will chose according background color
    else if (
      document.getElementById(behindWidgetId).getAttribute("data-asset-type") === PICTURE ||
      document.getElementById(behindWidgetId).getAttribute("data-asset-type") === COLLAGE ||
      document.getElementById(behindWidgetId).getAttribute("data-asset-type") === VIDEO ||
      (document.getElementById(behindWidgetId).getAttribute("data-asset-type") === UPLOAD &&
        document.getElementById(behindWidgetId).getAttribute("data-file-type") !== "svg")
    ) {
      behindWidgetColor = bColor;
      behindWidgetColorRange = changeWidgetColorAccordingBackground(hexToRGB(behindWidgetColor));
      behindAssetUploadedSvg = true;
    }
    // if there is widget behind, active widget color will chose according that widget color
    else {
      let multiColorDataClassSet = [];
      let multiColorSet = [];
      let multiColorDataSet = [];
      let multicolorClassSet = [];
      let element = document.getElementById(behindWidgetId);

      element.querySelectorAll(` svg *`).forEach(function (node) {
        let dataClassName = node.dataset.class;

        if (node.classList.value !== "") multicolorClassSet.push(node.classList.value);

        if (dataClassName) {
          let currentIconColor =
            window.getComputedStyle(node).fill === "rgba(0, 0, 0, 0)"
              ? "transparent"
              : window.getComputedStyle(node).fill;
          currentIconColor = RGBToHex(currentIconColor);

          if (!multiColorDataClassSet?.includes(dataClassName) && currentIconColor !== "") {
            multiColorDataClassSet.push(dataClassName);

            multiColorDataSet.push({
              color: currentIconColor,
              className: dataClassName,
            });

            multiColorSet.push(hexToRGB(currentIconColor));
          }
        }
      });

      if (multiColorSet.length === 0) {
        behindWidgetColor = RGBToHex(document.querySelector(`#${behindWidgetId} svg`).style.fill);
      } else {
        behindWidgetColor = RGBToHex(multiColorSet[0]);
      }

      if (!behindWidgetColor) behindWidgetColor = bColor; // for some uploaded svg, from which color can not be fetched

      behindWidgetColorRange = changeWidgetColorAccordingBackground(hexToRGB(behindWidgetColor));

      if (
        (element.getAttribute("data-asset-type") === UPLOAD && element.getAttribute("data-file-type") === SVG) ||
        (element.getAttribute("data-asset-type") === SHAPE &&
          parseInt(element.getAttribute("data-version")) < 3 &&
          element.getAttribute("data-scheme") === "linear") ||
        (element.getAttribute("data-asset-type") === SHAPE &&
          parseInt(element.getAttribute("data-version")) >= 3 &&
          element.querySelector(` svg`).style.fill === "transparent") ||
        (element.getAttribute("data-asset-type") === TEXT_FRAME && parseInt(element.getAttribute("data-version")) >= 3)
      )
        behindAssetUploadedSvg = true;
      else behindAssetUploadedSvg = false;
    }

    if (behindWidgetColorRange === "rgb(0, 0, 0)") {
      behindWidgetColorRange = "light";
    } else {
      behindWidgetColorRange = "dark";
    }

    return { behindWidgetColor, behindWidgetColorRange, behindAssetUploadedSvg, behindWidgetId };
  };

  const listOfWidgetBySameColor = targetBlockId => {
    let widgetColorRange;
    let filteredObjSVG = {};
    let filteredObjText = {};
    let darkarrayWidgets = [];
    let lightArrayWidgets = [];

    document.querySelectorAll(`#${targetBlockId} .dhp-page-widget`).forEach(element => {
      let assetType = element.getAttribute("data-asset-type");
      let widgetId = element.getAttribute("id");
      let widgetColor;

      // sagregate widget by same color
      if (assetType === TEXT) {
        widgetColor = element.querySelector(".dhp-widget-inner").style.color;
        widgetColorRange = changeWidgetColorAccordingBackground(widgetColor);

        if (widgetColor in filteredObjText) {
          filteredObjText[widgetColor].push(widgetId);
        } else {
          filteredObjText[widgetColor] = [widgetId];
        }
      } else if (
        assetType == SHAPE ||
        assetType == LINE ||
        (assetType === ICON && element.getAttribute("data-scheme") === MONO) ||
        (assetType === UPLOAD && element.getAttribute("data-file-type") === SVG)
      ) {
        let multiColorDataClassSet = [];
        let multiColorSet = [];
        let multiColorDataSet = [];
        let multicolorClassSet = [];

        element.querySelectorAll(` svg *`).forEach(function (node) {
          let dataClassName = node.dataset.class;

          if (node.classList.value !== "") multicolorClassSet.push(node.classList.value);

          if (dataClassName) {
            let currentIconColor =
              window.getComputedStyle(node).fill === "rgba(0, 0, 0, 0)"
                ? "transparent"
                : window.getComputedStyle(node).fill;
            currentIconColor = RGBToHex(currentIconColor);

            if (!multiColorDataClassSet?.includes(dataClassName) && currentIconColor !== "") {
              multiColorDataClassSet.push(dataClassName);

              multiColorDataSet.push({
                color: currentIconColor,
                className: dataClassName,
              });

              multiColorSet.push(hexToRGB(currentIconColor));
            }
          }
        });

        //fetch color for mono svg for single path
        if (multiColorSet.length === 0) widgetColor = element.querySelector(` svg`).style.fill;
        else widgetColor = multiColorSet[0];

        widgetColorRange = changeWidgetColorAccordingBackground(widgetColor);

        if (widgetColor in filteredObjSVG) {
          filteredObjSVG[widgetColor].push(widgetId);
        } else {
          filteredObjSVG[widgetColor] = [widgetId];
        }
      }

      // segregate widgets by dark color and light color array
      if (widgetColorRange) {
        if (widgetColorRange === "rgb(0, 0, 0)") lightArrayWidgets.push(widgetId);
        else darkarrayWidgets.push(widgetId);
      }
    });

    return { filteredObjSVG, filteredObjText, darkarrayWidgets, lightArrayWidgets };
  };

  const listOfTextWidgetBySameBackgroundColor = targetBlockId => {
    let filteredObjBgText = {};

    document.querySelectorAll(`#${targetBlockId} .dhp-page-widget`).forEach(element => {
      let assetType = element.getAttribute("data-asset-type");
      let widgetId = element.getAttribute("id");

      // sagregate text widget by same background color
      if (assetType === TEXT) {
        let TextBgColor = element.querySelector(".dhp-widget-inner").style.backgroundColor;

        if (TextBgColor) {
          if (TextBgColor in filteredObjBgText) {
            filteredObjBgText[TextBgColor].push(widgetId);
          } else {
            filteredObjBgText[TextBgColor] = [widgetId];
          }
        }
      }
    });

    return { filteredObjBgText };
  };

  const getFirstSvgColorFromSet = id => {
    let element = document.getElementById(id);
    let wColor;
    let multiColorDataClassSet = [];
    let multiColorSet = [];
    let multiColorDataSet = [];
    let multicolorClassSet = [];

    element.querySelectorAll(` svg *`).forEach(function (node) {
      let dataClassName = node.dataset.class;

      if (node.classList.value !== "") multicolorClassSet.push(node.classList.value);

      if (dataClassName) {
        let currentIconColor =
          window.getComputedStyle(node).fill === "rgba(0, 0, 0, 0)"
            ? "transparent"
            : window.getComputedStyle(node).fill;
        currentIconColor = RGBToHex(currentIconColor);

        if (!multiColorDataClassSet?.includes(dataClassName) && currentIconColor !== "") {
          multiColorDataClassSet.push(dataClassName);

          multiColorDataSet.push({
            color: currentIconColor,
            className: dataClassName,
          });

          multiColorSet.push(hexToRGB(currentIconColor));
        }
      }
    });

    //fetch color for mono svg for single path
    if (multiColorSet.length === 0) wColor = element.querySelector(` svg`).style.fill;
    else wColor = multiColorSet[0];

    return RGBToHex(wColor);
  };

  const getTextStrokeValues = element => {
    let outlineWidth, outlineColor;
    let strokeValue = element.querySelector(".dhp-widget-inner").style?.webkitTextStroke;

    //Load Current Formatting if outline previously applied
    if (strokeValue) {
      let strokeArray = strokeValue.split(" ");
      let rgbValue =
        strokeArray[1] === "transparent"
          ? strokeArray[1]
          : RGBToHex(`${strokeArray[1]} ${strokeArray[2]} ${strokeArray[3]}`);

      outlineWidth = parseInt(strokeArray[0]);
      outlineColor = rgbValue;
    }

    return { outlineWidth, outlineColor };
  };

  const applyMonoSvgColor = (wlist, wColor, isGroupWidget) => {
    let element = document.getElementById(wlist);
    let assetType = element.getAttribute("data-asset-type");

    //Apply color for mono svg widget shape and line
    if (assetType == SHAPE || assetType == LINE) {
      element.querySelector(` svg`).style.fill = wColor;

      // remove gradient color and data attribute
      if (element.getAttribute("data-grad-scolor")) {
        if (element.querySelector("linearGradient")) element.querySelector("linearGradient").remove();
        let svgPath = getNode(element.getAttribute("id"));
        svgPath.style.fill = "";

        //update data attributs
        if (isGroupWidget) {
          element.removeAttribute("data-grad-degree");
          element.removeAttribute("data-grad-scolor");
          element.removeAttribute("data-grad-spercen");
          element.removeAttribute("data-grad-ecolor");
          element.removeAttribute("data-grad-epercen");
        } else {
          let targetWidgetIndex = widgets.findIndex(widget => widget.id === wlist);
          let modifiedArray = JSON.parse(JSON.stringify(widgets));

          delete modifiedArray[targetWidgetIndex].data["data-grad-degree"];
          delete modifiedArray[targetWidgetIndex].data["data-grad-scolor"];
          delete modifiedArray[targetWidgetIndex].data["data-grad-spercen"];
          delete modifiedArray[targetWidgetIndex].data["data-grad-ecolor"];
          delete modifiedArray[targetWidgetIndex].data["data-grad-epercen"];

          newDataAttr[wlist] = modifiedArray[targetWidgetIndex].data;
        }
      }
    }
    //Apply color for ICON, UPLOADED SVG widgets with single path
    else if (assetType === ICON || (assetType === UPLOAD && element.getAttribute("data-file-type") === SVG)) {
      let colorClass = [];
      let newArray = [];

      element.querySelectorAll(` svg *`).forEach(function (node) {
        if (node.nodeName !== "g" && node.style && RGBToHex(node.style.fill) !== "") {
          let dataClassName = node.dataset.class;
          if (!colorClass.includes(dataClassName)) {
            colorClass.push(dataClassName);
          }
        }
      });

      if (colorClass.length === 1) {
        colorClass.map(className => {
          newArray.push({ class: className, color: wColor });
        });
      }

      newArray.map(value => {
        element.querySelectorAll(` svg *`).forEach(function (node) {
          if (value.class === node.dataset.class) {
            node.style.fill = value.color;
          }
        });
      });

      //Apply color for mono svg in upload widget
      if (colorClass.length === 0 && (assetType === UPLOAD || assetType === ICON)) {
        element.querySelector(` svg`).style.fill = wColor;
      }
    }
  };

  const applyTextColor = (wlist, wColor, isGroupWidget) => {
    let element = document.getElementById(wlist);
    element.querySelector(`.dhp-widget-inner`).style.color = wColor;

    // remove formatting from all child div and apply main formatting
    element.querySelectorAll("*").forEach(node => {
      if (node.style?.color) {
        node.style.color = wColor;
      }
    });

    // Remove Gradient
    if (element.getAttribute("data-grad-scolor")) {
      element.querySelector(".dhp-widget-inner").style.backgroundImage = "";
      element.querySelector(".dhp-widget-inner").style.webkitTextFillColor = "";

      //update data attributs
      if (isGroupWidget) {
        element.removeAttribute("data-grad-degree");
        element.removeAttribute("data-grad-scolor");
        element.removeAttribute("data-grad-spercen");
        element.removeAttribute("data-grad-ecolor");
        element.removeAttribute("data-grad-epercen");
      } else {
        let targetWidgetIndex = widgets.findIndex(widget => widget.id === wlist);
        let modifiedArray = JSON.parse(JSON.stringify(widgets));

        delete modifiedArray[targetWidgetIndex].data["data-grad-degree"];
        delete modifiedArray[targetWidgetIndex].data["data-grad-scolor"];
        delete modifiedArray[targetWidgetIndex].data["data-grad-spercen"];
        delete modifiedArray[targetWidgetIndex].data["data-grad-ecolor"];
        delete modifiedArray[targetWidgetIndex].data["data-grad-epercen"];

        newDataAttr[wlist] = modifiedArray[targetWidgetIndex].data;
      }
    }
  };

  const changeShadowColorAccordingTheme = (
    assetType,
    element,
    bColor,
    darkColorArray,
    lightColorArray,
    targetBlockId,
    targetPageId
  ) => {
    let { behindWidgetColor, behindWidgetColorRange } = checkRange(
      element.getAttribute("id"),
      bColor,
      targetBlockId,
      targetPageId
    ); // check target widgets behind elemnt color and color range
    let shadowColor;
    let flipableAvailable =
      assetType === PICTURE ||
      assetType === ANIMATION ||
      (assetType === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(element.getAttribute("data-source"))) ||
      (assetType === UPLOAD && element.getAttribute("data-file-type") !== SVG);
    let targetElem = flipableAvailable
      ? element.querySelector(".flippable")
      : element.querySelector(".dhp-widget-inner");
    let filterValue = targetElem.style.filter;

    if (filterValue && filterValue !== "none" && filterValue.includes("drop-shadow")) {
      let filterArray = filterValue.split("drop-shadow")[1].split(" ");
      let rgbValue = filterArray[0].split("(");
      rgbValue =
        rgbValue[1] === "transparent"
          ? rgbValue[1]
          : RGBToHex(`${rgbValue[1]}(${rgbValue[2]} ${filterArray[1]} ${filterArray[2]}`);
      let horaizentalShadow = parseInt(filterArray[rgbValue === "transparent" ? 1 : 3]);
      let verticalShadow = parseInt(filterArray[rgbValue === "transparent" ? 2 : 4]);
      let blur = parseInt(filterArray[rgbValue === "transparent" ? 3 : 5]);

      if (behindWidgetColorRange === "dark") {
        if (lightColorArray.length !== 0) {
          let randIdx = Math.floor(Math.random() * lightColorArray.length);
          shadowColor = lightColorArray[randIdx];
        }
        // if there is no light color (for brand)
        else {
          let targetArray = darkColorArray.filter(darkcolor => darkcolor !== behindWidgetColor);
          let randIdx = Math.floor(Math.random() * targetArray.length);
          shadowColor = targetArray[randIdx];
        }
      } else {
        if (darkColorArray.length !== 0) {
          let randIdx = Math.floor(Math.random() * darkColorArray.length);
          shadowColor = darkColorArray[randIdx];
        }
        // if there is no dark color (for brand)
        else {
          let targetArray = lightColorArray.filter(lightColor => lightColor !== behindWidgetColor);
          let randIdx = Math.floor(Math.random() * targetArray.length);
          shadowColor = targetArray[randIdx];
        }
      }

      targetElem.style.filter = `drop-shadow(${horaizentalShadow}px ${verticalShadow}px ${blur}px ${shadowColor})`;
    }
  };

  const changeBorderColorAccordingTheme = (
    assetType,
    element,
    bColor,
    darkColorArray,
    lightColorArray,
    currentTextColor,
    isGroupWidget,
    targetBlockId,
    targetPageId
  ) => {
    let borderColor, shapeColor, textColor, textBorderColor, newBorderColor, textBackgroundColor;
    let { behindWidgetColor, behindWidgetColorRange } = checkRange(
      element.getAttribute("id"),
      bColor,
      targetBlockId,
      targetPageId
    ); // check target widgets behind elemnt color and color range
    let targetElem = [TEXT, QR_CODE, SHAPE].includes(assetType)
      ? element.querySelector(".dhp-widget-inner").style
      : assetType === TYPE_FORM
      ? element.querySelector(".customapp-frame")?.style
      : element.querySelector(".flippable")?.style;

    if (targetElem) {
      if (assetType === SHAPE) {
        shapeColor =
          element.querySelector(`svg`).style.fill === "transparent"
            ? "transparent"
            : RGBToHex(element.querySelector(`svg`).style.fill);
      }

      if (assetType === TEXT) {
        textColor =
          element.querySelector(`.dhp-widget-inner`).style.color === "transparent"
            ? "transparent"
            : RGBToHex(element.querySelector(`.dhp-widget-inner`).style.color);
        textBackgroundColor = element.querySelector(`.dhp-widget-inner`).style.backgroundColor;
        textBorderColor = RGBToHex(targetElem.borderColor);
        newBorderColor =
          textColor !== "transparent" && RGBToHex(currentTextColor) === textBorderColor ? textColor : false;
      }

      // Calculate border color according backgrounds
      if (behindWidgetColorRange === "dark") {
        if (lightColorArray.length !== 0) {
          let randIdx = Math.floor(Math.random() * lightColorArray.length);
          borderColor = newBorderColor ? newBorderColor : lightColorArray[randIdx];
        }
        // if there is no light color (for brand)
        else {
          let targetArray = darkColorArray.filter(darkcolor => darkcolor !== behindWidgetColor);
          let randIdx = Math.floor(Math.random() * targetArray.length);
          borderColor = newBorderColor ? newBorderColor : targetArray[randIdx];
        }

        //for shape
        if (borderColor === shapeColor) {
          let randIdx = randIdx + 1 < lightColorArray.length ? randIdx + 1 : randIdx - 1;
          borderColor = lightColorArray[randIdx];
        }
        //for text with background
        if (
          textBackgroundColor &&
          textBackgroundColor !== "transparent" &&
          borderColor === RGBToHex(textBackgroundColor)
        ) {
          let randIdx = randIdx + 1 < lightColorArray.length ? randIdx + 1 : randIdx - 1;
          borderColor = lightColorArray[randIdx];
        }
      } else {
        if (darkColorArray.length !== 0) {
          let randIdx = Math.floor(Math.random() * darkColorArray.length);
          borderColor = newBorderColor ? newBorderColor : darkColorArray[randIdx];
        }
        // if there is no dark color (for brand)
        else {
          let targetArray = lightColorArray.filter(lightColor => lightColor !== behindWidgetColor);
          let randIdx = Math.floor(Math.random() * targetArray.length);
          borderColor = newBorderColor ? newBorderColor : targetArray[randIdx];
        }

        //for shape
        if (borderColor === shapeColor) {
          let randIdx = randIdx + 1 < darkColorArray.length ? randIdx + 1 : randIdx - 1;
          borderColor = darkColorArray[randIdx];
        }
        //for text with background
        if (
          textBackgroundColor &&
          textBackgroundColor !== "transparent" &&
          borderColor === RGBToHex(textBackgroundColor)
        ) {
          let randIdx = randIdx + 1 < darkColorArray.length ? randIdx + 1 : randIdx - 1;
          borderColor = darkColorArray[randIdx];
        }
      }

      // Apply border color
      if (targetElem?.borderStyle && targetElem.borderStyle !== "none" && targetElem.borderColor !== "transparent") {
        targetElem.borderColor = borderColor;
      }
      // Apply border color for shape
      else if (element.getAttribute("data-border-style") && element.getAttribute("data-border-style") !== "none") {
        let borderStyle = element.getAttribute("data-border-style");
        let borderWidth = parseInt(element.getAttribute("data-border-width"));

        let pathId = getRandomString(8);
        let insidePathId = `inside_${getRandomString(8)}`;
        let node = getNode(element.getAttribute("id"));
        node.setAttribute("id", pathId);
        let newSvgNode = document.createElement("defs");

        if (borderStyle === "solid") {
          newSvgNode.innerHTML = `${
            node.outerHTML
          } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${
            parseInt(borderWidth) * 5
          } stroke="${borderColor}" fill="none" clip-path="url(#${insidePathId})"></use>`;
        }

        element.querySelector(` svg`).innerHTML = newSvgNode.innerHTML;

        //update data Attribute
        if (isGroupWidget) {
          element.setAttribute("data-border-color", borderColor);
        } else {
          let wlist = element.getAttribute("id");
          let targetWIdx = widgets.findIndex(widget => widget.id === wlist);

          if (wlist in newDataAttr) newDataAttr[wlist] = { ...newDataAttr[wlist], "data-border-color": borderColor };
          else newDataAttr[wlist] = { ...widgets[targetWIdx].data, "data-border-color": borderColor };
        }
      }
    }
  };

  const changeColorTheme = (themeId, themeColors) => {
    let currentBlockWidgetIndex = [];
    let currentBlockWidgetList = [];
    let targetPageIds = [];
    let colorArray;

    if (themeColors) {
      colorArray = structuredClone(themeColors);

      // if there is only one color in color pallet (For Brand)
      if (colorArray.length === 1) {
        let colorrange = changeWidgetColorAccordingBackground(hexToRGB(colorArray[0]));
        let extraColor = colorrange === "rgb(0, 0, 0)" ? "#000000" : "#ffffff";
        colorArray.push(extraColor);
      }

      colorArray = shuffle(colorArray); // shuffle main color array
    } else {
      colorArray = pageNodes[activeColorThemePageIdx].themearray; // fetch applied color array from active page
    }

    let darkColorArray = getDarkColorsArray(colorArray); // get all dark shades under main color array
    let lightColorArray = getLightColorsArray(colorArray); // get all light shades under main color array
    let newBackgroundArray = Object.assign([...backgroundColors]);
    let newPageArray = Object.assign([...pageNodes]);
    let newArray = Object.assign([...widgets]);

    // set target Page
    if (themeColors) {
      targetPageIds.push(metadata.activePageId);
    } else {
      pageNodes.forEach(pageNode => {
        targetPageIds.push(pageNode.pageId);
      });
    }

    // Loop through target Page or all pages
    targetPageIds.forEach(targetPageId => {
      let pageIdx = pageNodes.findIndex(pageNode => pageNode.pageId === targetPageId);

      // create new pagenode array for context
      newPageArray = Object.assign([...newPageArray], {
        [pageIdx]: {
          ...pageNodes[pageIdx],
          themeId: themeId ? themeId : pageNodes[activeColorThemePageIdx].themeId,
          themearray: colorArray,
        },
      });

      document.querySelectorAll(`#${targetPageId} .dhp-page-block`).forEach(targetBlock => {
        // ---------------------- Apply theme  colors on Background ---------------------
        let bColor;
        let targetBlockId = targetBlock.getAttribute("id");
        let backgroundColorIndex = backgroundColors.findIndex(
          backgroundColor => backgroundColor.blockId === targetBlockId
        );
        let backgroundColor = backgroundColors[backgroundColorIndex]?.style.backgroundColor
          ? backgroundColors[backgroundColorIndex]?.style.backgroundColor
          : "#ffffff";
        let backgroundColorRange = changeWidgetColorAccordingBackground(hexToRGB(backgroundColor));

        //For appling theme color
        if (lightColorArray.length > 1 && darkColorArray.length > 1) {
          if (backgroundColorRange === "rgb(0, 0, 0)") {
            bColor = lightColorArray[0];
          } else {
            bColor = darkColorArray[0];
          }
        }
        // for applying brand color
        else {
          bColor = colorArray[0];
        }

        let updatedBackgroundColorObj = {
          blockId: targetBlockId,
          pageId: targetPageId,
          style: { ...widgetConfig.backgrounds.color.style, backgroundColor: bColor },
        };

        //for update
        if (backgroundColorIndex > -1) {
          newBackgroundArray = Object.assign([...newBackgroundArray], {
            [backgroundColorIndex]: {
              ...backgroundColors[backgroundColorIndex],
              style: { ...backgroundColors[backgroundColorIndex].style, backgroundColor: bColor },
            },
          });
        }
        //for add
        else newBackgroundArray = [...newBackgroundArray, updatedBackgroundColorObj];
        // -------------------------------------------

        // ------------------- Apply theme  colors on widgets -----------------------
        let currentTextColor;
        let { filteredObjSVG, filteredObjText, darkarrayWidgets, lightArrayWidgets } =
          listOfWidgetBySameColor(targetBlockId); // get all same type of color widget array
        let { filteredObjBgText } = listOfTextWidgetBySameBackgroundColor(targetBlockId); // get all text widget which hs same background color

        document.querySelectorAll(`#${targetBlockId} .dhp-page-widget`).forEach((element, index) => {
          let assetType = element.getAttribute("data-asset-type");
          let targetId = element.getAttribute("id");
          let isGroupWidget = document.getElementById(targetId)?.closest(".dhp-page-group");

          // apply color for Shape, Line, Mono Icon, Mono Uploaded svg widgets
          if (
            assetType === SHAPE ||
            assetType === LINE ||
            (assetType === ICON && element.getAttribute("data-scheme") === MONO) ||
            (assetType === UPLOAD && element.getAttribute("data-file-type") === SVG)
          ) {
            let assetIndexInSameColorArray;
            let assetCurrentColor = hexToRGB(getFirstSvgColorFromSet(targetId)); // fetch current svg color

            if (assetCurrentColor && assetCurrentColor != "rgb(NaN, NaN, NaN)" && assetCurrentColor !== "transparent") {
              // get the index of that svg from same color array
              if (assetCurrentColor in filteredObjSVG) {
                assetIndexInSameColorArray = filteredObjSVG[assetCurrentColor].findIndex(
                  assetId => assetId === targetId
                );
              }

              let wColor =
                assetIndexInSameColorArray === 0 ? "" : getFirstSvgColorFromSet(filteredObjSVG[assetCurrentColor][0]); // if index is not 0 fetch the color of the 1st widget from that same color array
              let { behindWidgetColor, behindWidgetColorRange, behindAssetUploadedSvg, behindWidgetId } = checkRange(
                targetId,
                bColor,
                targetBlockId,
                targetPageId
              ); // check target widgets behind elemnt color and color range

              // if the target widget belogs as 1st index from the same color array widget
              if (assetIndexInSameColorArray === 0) {
                // for appling theme color
                if (lightColorArray.length !== 0 && darkColorArray.length !== 0) {
                  // if the widget belongs from dark color array then chose any dark color from theme
                  if (darkarrayWidgets.includes(targetId)) {
                    let randIdx = index < darkColorArray.length ? index : index % darkColorArray.length;
                    wColor = darkColorArray[randIdx];

                    // if random dark color is same as it's background color chose diff dark shade
                    if (behindWidgetColor === wColor && !behindAssetUploadedSvg && darkColorArray.length > 1) {
                      let randIdx = index + 1 < darkColorArray.length ? index + 1 : (index - 1) % darkColorArray.length;
                      wColor = darkColorArray[randIdx];
                    }
                    // if only one dark color is there for brand and random dark color is same as it's background color
                    else if (behindWidgetColor === wColor && darkColorArray.length === 1) {
                      let altIdx =
                        index + 1 < lightColorArray.length ? index + 1 : (index - 1) % lightColorArray.length;
                      altIdx = altIdx ? altIdx : 0;
                      wColor = lightColorArray[altIdx];
                    }
                  }
                  // if the widget belongs from light color array then chose any light color from theme
                  else {
                    let randIdx = index < lightColorArray.length ? index : index % lightColorArray.length;
                    wColor = lightColorArray[randIdx];

                    // if random light color is same as it's background color chose diff light shade
                    if (behindWidgetColor === wColor && !behindAssetUploadedSvg && lightColorArray.length > 1) {
                      let randIdx =
                        index + 1 < lightColorArray.length ? index + 1 : (index - 1) % lightColorArray.length;
                      wColor = lightColorArray[randIdx];
                    }
                    // if only one light color is there for brand
                    else if (behindWidgetColor === wColor && lightColorArray.length === 1) {
                      let altIdx = index + 1 < darkColorArray.length ? index + 1 : (index - 1) % darkColorArray.length;
                      wColor = darkColorArray[altIdx];
                    }
                  }
                }
                // for appling brand color
                else {
                  let randIdx = index < colorArray.length ? index : index % colorArray.length;
                  wColor = colorArray[randIdx];

                  // if random dark color is same as it's background color chose diff dark shade
                  if (behindWidgetColor === wColor && !behindAssetUploadedSvg) {
                    let randIdx = index + 1 < colorArray.length ? index + 1 : (index - 1) % colorArray.length;
                    wColor = colorArray[randIdx];
                  }
                }
              }
              // if the target widget not belogs as 1st index from the same color array widget
              else {
                // 1st widget color from same color array where target widget belongs, if the color is not conflicted with it's background color then apply the same
                if (behindWidgetColor !== wColor || (behindWidgetColor === wColor && behindAssetUploadedSvg)) {
                  wColor = wColor;
                }
                // 1st widget color from same color array, where target widget belongs, if the color is conflicted with it's background color then apply the diff color (i.e : if bg is dark apply light Vice versa)
                else {
                  if (
                    behindWidgetId &&
                    ((document.getElementById(behindWidgetId).getAttribute("data-asset-type") === SHAPE &&
                      parseInt(document.getElementById(behindWidgetId).getAttribute("data-version")) < 3 &&
                      document.getElementById(behindWidgetId).getAttribute("data-scheme") === "linear") ||
                      (document.getElementById(behindWidgetId).getAttribute("data-asset-type") === SHAPE &&
                        parseInt(document.getElementById(behindWidgetId).getAttribute("data-version")) >= 3 &&
                        document.getElementById(behindWidgetId).querySelector(` svg`).style.fill === "transparent"))
                  ) {
                    wColor = wColor;
                  } else if (behindWidgetColorRange === "dark") {
                    let randIdx = Math.floor(Math.random() * lightColorArray.length);
                    let altIdx = Math.floor(Math.random() * darkColorArray.length); // if there is no lightcolor (for brand)

                    wColor = lightColorArray.length !== 0 ? lightColorArray[randIdx] : darkColorArray[altIdx];
                  } else {
                    let randIdx = Math.floor(Math.random() * darkColorArray.length);
                    let altIdx = Math.floor(Math.random() * lightColorArray.length); // if there is no darkcolor (for brand)

                    wColor = darkColorArray.length !== 0 ? darkColorArray[randIdx] : lightColorArray[altIdx];
                  }
                }
              }

              applyMonoSvgColor(targetId, wColor, isGroupWidget); // apply the chossen color on target svg
            }
          }
          //Apply color for QR Code
          else if (assetType === QR_CODE) {
            element.querySelectorAll(` svg *`).forEach(function (node, index) {
              if (node.nodeName !== "title") {
                node.style.fill = colorArray[index];
              }
            });
            // set margin area background qr-code widget
            element.querySelector(" .dhp-widget-inner").style.backgroundColor = colorArray[0];
          }
          // apply color for single text widgets
          else if (assetType === TEXT) {
            let assetIndexInSameColorArray;
            let assetCurrentColor = element.querySelector(".dhp-widget-inner").style.color; // fetch current text color
            currentTextColor = assetCurrentColor;
            let currentTextBackgroundColor = element.querySelector(".dhp-widget-inner").style.backgroundColor; // fetch current text background color
            let { outlineWidth, outlineColor } = getTextStrokeValues(element); // fetch current text outline color

            // get the index of that text from same color array
            if (assetCurrentColor in filteredObjText) {
              assetIndexInSameColorArray = filteredObjText[assetCurrentColor].findIndex(
                assetId => assetId === targetId
              );
            }

            let wColor =
              assetIndexInSameColorArray === 0
                ? ""
                : RGBToHex(
                    document.querySelector(`#${filteredObjText[assetCurrentColor][0]} .dhp-widget-inner`).style.color
                  ); // if index is not 0 fetch the color of the 1st widget from that same color array
            let { behindWidgetColor, behindWidgetColorRange, behindAssetUploadedSvg } = checkRange(
              targetId,
              bColor,
              targetBlockId,
              targetPageId
            ); // check target widgets behind elemnt color and color range

            // If text has background color
            if (currentTextBackgroundColor && currentTextBackgroundColor !== "transparent") {
              let newBackgroundColor, textBgIndexInSameColorArray;
              let bgColorRange = changeWidgetColorAccordingBackground(currentTextBackgroundColor);

              // get the index of that text from same background color array
              if (currentTextBackgroundColor in filteredObjBgText) {
                textBgIndexInSameColorArray = filteredObjBgText[currentTextBackgroundColor].findIndex(
                  assetId => assetId === targetId
                );
              }

              if (textBgIndexInSameColorArray === 0) {
                // check previous background range
                if (bgColorRange === "rgb(0, 0, 0)") {
                  bgColorRange = "light";
                } else {
                  bgColorRange = "dark";
                }

                // apply light background if previously it was on light range or vise varsa
                if (bgColorRange === "light") {
                  if (lightColorArray.length !== 0) {
                    let targetArray = lightColorArray.filter(lightColor => lightColor !== behindWidgetColor);

                    if (targetArray.length !== 0) {
                      let randIdx = Math.floor(Math.random() * targetArray.length);
                      newBackgroundColor = targetArray[randIdx];
                      behindWidgetColorRange = "light";
                    }
                    // if back color and target color is same and there is only one light color for brand
                    else {
                      let randIdx = Math.floor(Math.random() * darkColorArray.length);
                      newBackgroundColor = darkColorArray[randIdx];
                      behindWidgetColorRange = "dark";
                    }
                  }
                  // if there is no light color (for brand)
                  else {
                    let targetArray = darkColorArray.filter(darkcolor => darkcolor !== behindWidgetColor);
                    let randIdx = Math.floor(Math.random() * targetArray.length);
                    newBackgroundColor = targetArray[randIdx];
                    behindWidgetColorRange = "dark";
                  }
                } else {
                  if (darkColorArray.length !== 0) {
                    let targetArray = darkColorArray.filter(darkcolor => darkcolor !== behindWidgetColor);

                    if (targetArray.length !== 0) {
                      let randIdx = Math.floor(Math.random() * targetArray.length);
                      newBackgroundColor = targetArray[randIdx];
                      behindWidgetColorRange = "dark";
                    }
                    // if back color and target color is same and there is only one dark color for brand
                    else {
                      let randIdx = Math.floor(Math.random() * lightColorArray.length);
                      newBackgroundColor = lightColorArray[randIdx];
                      behindWidgetColorRange = "light";
                    }
                  }
                  // if there is no dark color (for brand)
                  else {
                    let targetArray = lightColorArray.filter(lightColor => lightColor !== behindWidgetColor);
                    let randIdx = Math.floor(Math.random() * targetArray.length);
                    newBackgroundColor = targetArray[randIdx];
                    behindWidgetColorRange = "light";
                  }
                }
              } else {
                newBackgroundColor = document.querySelector(
                  `#${filteredObjBgText[currentTextBackgroundColor][0]} .dhp-widget-inner`
                ).style.backgroundColor;
                let newBgColorRnge = changeWidgetColorAccordingBackground(newBackgroundColor);
                newBackgroundColor = RGBToHex(newBackgroundColor);

                // check background range
                if (newBgColorRnge === "rgb(0, 0, 0)") {
                  behindWidgetColorRange = "light";
                } else {
                  behindWidgetColorRange = "dark";
                }
              }

              behindAssetUploadedSvg = false; // when text background is applied behind widhet dose not matter (that is why keep is false)
              behindWidgetColor = newBackgroundColor;
              element.querySelector(".dhp-widget-inner").style.backgroundColor = hexToRGB(newBackgroundColor);
            }

            // If text has outline
            if (outlineColor) {
              let newOutlineColor;

              if (behindWidgetColorRange === "dark") {
                if (lightColorArray.length !== 0) {
                  let randIdx = Math.floor(Math.random() * lightColorArray.length);
                  newOutlineColor = lightColorArray[randIdx];
                }
                // if there is no light color (for brand)
                else {
                  let targetArray = darkColorArray.filter(darkcolor => darkcolor !== behindWidgetColor);
                  let randIdx = Math.floor(Math.random() * targetArray.length);
                  newOutlineColor = targetArray[randIdx];
                }
              } else {
                if (darkColorArray.length !== 0) {
                  let randIdx = Math.floor(Math.random() * darkColorArray.length);
                  newOutlineColor = darkColorArray[randIdx];
                }
                // if there is no dark color (for brand)
                else {
                  let targetArray = lightColorArray.filter(lightColor => lightColor !== behindWidgetColor);
                  let randIdx = Math.floor(Math.random() * targetArray.length);
                  newOutlineColor = targetArray[randIdx];
                }
              }

              behindWidgetColor = newOutlineColor;
              element.querySelector(".dhp-widget-inner").style.webkitTextStroke = `${outlineWidth}px ${hexToRGB(
                newOutlineColor
              )}`;
            }

            // if the target widget belongs as 1st index from the same color array widget
            if (assetIndexInSameColorArray === 0) {
              // if text background is not any uploaded svg, (i.e : normal background color or dochipo mono svg)
              if (!behindAssetUploadedSvg) {
                if (behindWidgetColorRange === "dark") {
                  let randIdx = index < lightColorArray.length ? index : index % lightColorArray.length;

                  // if there is no lightcolor (for brand)
                  let targetArray = darkColorArray.filter(darkcolor => darkcolor !== behindWidgetColor);
                  let altIdx = index < targetArray.length ? index : index % targetArray.length;

                  wColor = lightColorArray.length !== 0 ? lightColorArray[randIdx] : targetArray[altIdx];
                } else {
                  let randIdx = index < darkColorArray.length ? index : index % darkColorArray.length;

                  // if there is no darkcolor (for brand)
                  let targetArray = lightColorArray.filter(lightcolor => lightcolor !== behindWidgetColor);
                  let altIdx = index < targetArray.length ? index : index % targetArray.length;

                  wColor = darkColorArray.length !== 0 ? darkColorArray[randIdx] : targetArray[altIdx];
                }
              }
              // if text is background is any uploaded svg
              else {
                // if the widget belongs from dark color array then chose any dark color from theme
                if (darkarrayWidgets.includes(targetId)) {
                  let randIdx = index < darkColorArray.length ? index : index % darkColorArray.length;

                  // if there is no darkcolor (for brand)
                  let targetArray = lightColorArray.filter(lightcolor => lightcolor !== behindWidgetColor);
                  let altIdx = index < targetArray.length ? index : index % targetArray.length;

                  wColor = darkColorArray.length !== 0 ? darkColorArray[randIdx] : targetArray[altIdx];

                  // if random dark color is same as it's background color chose diff dark shade
                  if (behindWidgetColor === wColor) {
                    let randIdx = index + 1 < darkColorArray.length ? index + 1 : (index - 1) % darkColorArray.length;
                    let altIdx = index + 1 < lightColorArray.length ? index + 1 : (index - 1) % lightColorArray.length; // if there is no darkcolor (for brand)
                    altIdx = altIdx ? altIdx : 0;

                    wColor = darkColorArray.length > 1 ? darkColorArray[randIdx] : lightColorArray[altIdx];
                  }
                }
                // if the widget belongs from light color array then chose any light color from theme
                else {
                  let randIdx = index < lightColorArray.length ? index : index % lightColorArray.length;

                  // if there is no light (for brand)
                  let targetArray = darkColorArray.filter(darkcolor => darkcolor !== behindWidgetColor);
                  let altIdx = index < targetArray.length ? index : index % targetArray.length;

                  wColor = lightColorArray.length !== 0 ? lightColorArray[randIdx] : targetArray[altIdx];

                  // if random light color is same as it's background color chose diff light shade
                  if (behindWidgetColor === wColor) {
                    let randIdx = index + 1 < lightColorArray.length ? index + 1 : (index - 1) % lightColorArray.length;
                    let altIdx = index + 1 < darkColorArray.length ? index + 1 : (index - 1) % darkColorArray.length; // if there is no lightcolor (for brand)
                    altIdx = altIdx ? altIdx : 0;

                    wColor = lightColorArray.length > 1 ? lightColorArray[randIdx] : darkColorArray[altIdx];
                  }
                }
              }
            }
            // if the target widget not belogs as 1st index from the same color array widget
            else {
              // 1st widget color from same color array where target widget belongs, if the color is not conflicted with it's background color then apply the same
              if (behindWidgetColor !== wColor) {
                wColor = wColor;
              }
              // 1st widget color from same color array, where target widget belongs, if the color is conflicted with it's background color then apply the diff color (i.e : if bg is dark apply light Vice versa)
              else {
                if (behindWidgetColorRange === "dark") {
                  let randIdx = Math.floor(Math.random() * lightColorArray.length);

                  // if there is no light color for brand
                  let targetArray = darkColorArray.filter(darkcolor => darkcolor !== behindWidgetColor);
                  let altIdx = Math.floor(Math.random() * targetArray.length);

                  wColor = lightColorArray.length !== 0 ? lightColorArray[randIdx] : targetArray[altIdx];
                } else {
                  let randIdx = Math.floor(Math.random() * darkColorArray.length);

                  // if there is no dark color for brand
                  let targetArray = lightColorArray.filter(lightcolor => lightcolor !== behindWidgetColor);
                  let altIdx = Math.floor(Math.random() * targetArray.length);

                  wColor = darkColorArray.length !== 0 ? darkColorArray[randIdx] : targetArray[altIdx];
                }
              }
            }
            if (assetCurrentColor !== "transparent") applyTextColor(targetId, wColor, isGroupWidget); // apply the chossen color on target text
          }

          changeShadowColorAccordingTheme(
            assetType,
            element,
            bColor,
            darkColorArray,
            lightColorArray,
            targetBlockId,
            targetPageId
          ); // apply color on shadow
          changeBorderColorAccordingTheme(
            assetType,
            element,
            bColor,
            darkColorArray,
            lightColorArray,
            currentTextColor,
            isGroupWidget,
            targetBlockId,
            targetPageId
          ); // apply color on border
        });
        // -------------------------------------------
      });
    });

    // Collect widget list and index which are in target pages
    if (themeColors) {
      currentBlockWidgetList = widgets.filter(widget => widget.pageId === metadata.activePageId);
      currentBlockWidgetList.forEach(element => {
        currentBlockWidgetIndex.push(widgets.findIndex(widget => widget.id === element.id));
      });
    } else {
      currentBlockWidgetList = [...widgets];
      currentBlockWidgetList.forEach(element => {
        currentBlockWidgetIndex.push(widgets.findIndex(widget => widget.id === element.id));
      });
    }

    // create new widgets array for context
    currentBlockWidgetIndex.map(targetWidgetIndex => {
      let targetWId = widgets[targetWidgetIndex].id;

      newArray = Object.assign([...newArray], {
        [targetWidgetIndex]: {
          ...newArray[targetWidgetIndex],
          data: targetWId in newDataAttr ? { ...newDataAttr[targetWId] } : { ...widgets[targetWidgetIndex].data },
          innerHTML: document.getElementById(widgets[targetWidgetIndex].id).innerHTML,
        },
      });
    });

    //update final context
    updateWidgets(newArray, newPageArray, blockNodes, newBackgroundArray, false, false);

    // update metadata
    updateMetadata({
      ...metadata,
      activeAudioId: false,
      activeWidgetId: false,
      activeWidgetType: false,
      activeOutSideCanvasArea: false,
      disableAutoScroll: false,
    });
  };

  return { changeColorTheme };
};

export default useThemeColor;
