import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { widgetConfig } from "../editor_config";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { WHITE_COLOR } from "../../../constants/editor";

let style = Object.assign({}, global);

const BackgroundColor = props => {
  let { metadata, backgroundImages, backgroundColors, isDocumentReady } = useContext(EditorContext);
  let backgroundImageActiveIndex = backgroundImages.findIndex(
    backgroundImage => backgroundImage.blockId === metadata.activeBlockId
  );
  let activeBackgroundImage = backgroundImages[backgroundImageActiveIndex]?.style.backgroundImage;
  let backgroundColorActiveIndex = backgroundColors.findIndex(
    backgroundColor => backgroundColor.blockId === metadata.activeBlockId
  );
  let activeBackgroundColor =
    backgroundColors[backgroundColorActiveIndex]?.style.backgroundColor === WHITE_COLOR ||
    backgroundColors[backgroundColorActiveIndex]?.style.backgroundColor === "transparent" ||
    backgroundColorActiveIndex === -1
      ? false
      : backgroundColors[backgroundColorActiveIndex]?.style.backgroundColor;
  let activeBackgroundOpacity = backgroundImages[backgroundImageActiveIndex]?.style.opacity;

  const [backgroundMenuStyle, setBackgroundMenuStyle] = useState();
  const [backgroundMenuColor, setBackgroundMenuColor] = useState();

  const onClickBackgroundColor = e => {
    e.preventDefault();
    if (document.getElementById("editorBody").classList.contains("hide-secondAside")) props.toggle();
  };

  useEffect(() => {
    // if (backgroundColorActiveIndex > -1) {
    if (!activeBackgroundColor && !activeBackgroundImage) {
      setBackgroundMenuStyle({ ...widgetConfig.backgrounds.contextualMenu.style });
    }

    if (activeBackgroundColor) {
      setBackgroundMenuColor(activeBackgroundColor);
    }

    if (!activeBackgroundColor) {
      setBackgroundMenuColor(false);
    }

    if (activeBackgroundImage) {
      setBackgroundMenuStyle({
        ...widgetConfig.backgrounds.contextualMenu.style,
        backgroundImage: activeBackgroundImage,
        opacity: activeBackgroundOpacity,
      });
    }

    if (activeBackgroundColor && !activeBackgroundImage) {
      setBackgroundMenuStyle({
        ...widgetConfig.backgrounds.contextualMenu.style,
        backgroundImage: false,
      });
    }
    // }
  }, [activeBackgroundImage, activeBackgroundColor, activeBackgroundOpacity]);

  return (
    <li
      id="toolbar_background"
      className={cx(style["toolset-group"], style["canvas-background-toolset"], style["custom-tooltip"], {
        [style["d-none"]]: !isDocumentReady,
      })}
      style={{ backgroundColor: backgroundMenuColor }}
      onClick={e => onClickBackgroundColor(e)}>
      <span style={backgroundMenuStyle}></span>
      <div className={cx(style["custom-tooltip-content"], style["top"])}>Background</div>
    </li>
  );
};

BackgroundColor.propTypes = {
  toggle: PropTypes.func,
};

export default BackgroundColor;
