import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";

import global from "../../../scss/dhp.scss";

import { EditorContext } from "../../../containers/editor/EditorLayout";
import { TYPE_BROCHURE, TYPE_YOUTUBE_BANNER } from "../../../constants/editor";
import useGuideLine from "../../../hooks/useGuideLine";

let style = Object.assign({}, global);

const PageGuideLine = () => {
  let { metadata, dimension, documentType, pageNodes } = useContext(EditorContext);

  const activeGuideLine = useGuideLine();

  const [guideLine, setGuideLine] = useState();

  useEffect(() => {
    if (
      (documentType.toLowerCase() === TYPE_YOUTUBE_BANNER && dimension.size === "youtube-banner") ||
      (documentType.toLowerCase() === TYPE_BROCHURE && dimension.size === "a4" && dimension.orientation === "landscape")
    ) {
      activeGuideLine(setGuideLine);
    }
  }, [pageNodes[metadata.activePageIdx]?.style.width, dimension.zoom]);

  return (
    <>
      {documentType.toLowerCase() === TYPE_YOUTUBE_BANNER && guideLine && (
        <>
          <div
            className={cx(style["dhp-page-guideLine"], style["g-1"], style["horaizontal"])}
            id="g-1"
            style={{
              top: `${guideLine.activeBlockHoraizental - guideLine.safeAreaHeight / 2}px`,
            }}></div>

          <div
            className={cx(style["dhp-page-guideLine"], style["g-2"], style["horaizontal"])}
            id="g-2"
            style={{
              top: `${guideLine.activeBlockHoraizental + guideLine.safeAreaHeight / 2}px`,
            }}></div>

          <div
            className={cx(style["dhp-page-guideLine"], style["g-3"], style["vertical"])}
            id="g-3"
            style={{
              height: `${guideLine.safeAreaHeight}px`,
              left: `${guideLine.activeBlockVertical - guideLine.safeAreaWidth / 2}px`,
              top: `${guideLine.activeBlockHoraizental - guideLine.safeAreaHeight / 2}px`,
            }}></div>

          <div
            className={cx(style["dhp-page-guideLine"], style["g-4"], style["vertical"])}
            id="g-4"
            style={{
              height: `${guideLine.safeAreaHeight}px`,
              left: `${guideLine.activeBlockVertical + guideLine.safeAreaWidth / 2}px`,
              top: `${guideLine.activeBlockHoraizental - guideLine.safeAreaHeight / 2}px`,
            }}></div>
        </>
      )}

      {documentType.toLowerCase() === TYPE_BROCHURE && guideLine && (
        <>
          <div
            className={cx(style["dhp-page-guideLine"], style["g-1"], style["vertical"])}
            id="g-1"
            style={{
              height: "100%",
              left: `${guideLine.brochureGuidelineRatio}px`,
            }}></div>

          <div
            className={cx(style["dhp-page-guideLine"], style["g-2"], style["vertical"])}
            id="g-2"
            style={{
              height: "100%",
              left: `${guideLine.brochureGuidelineRatio * 2}px`,
            }}></div>
        </>
      )}
    </>
  );
};

export default PageGuideLine;
