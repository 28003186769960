import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";
import { useSelector } from "react-redux";

import global from "../../../scss/dhp.scss";
import AssetName from "./Common/AssetName";
import { Icon } from "../../../components/ui/icon";
import AssetSchemeTypeNav from "./Common/AssetSchemeTypeNav";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import BrandSetup from "./BrandSetup";
import { AppContext } from "../../../contexts";
import BrandCompanyList from "./BrandCompanyList";
import useBrandFont from "../../../hooks/useBrandFont";
import SingleBrandkitFont from "./SingleBrandkitFont";
import BrandkitAssests from "./BrandkitAssests";
import BrandkitTabPaneSubTitle from "./BrandkitTabPaneSubTitle";
import BrandkitColours from "./BrandkitColours";
import AssetsLoader from "../../ui/loader/assetsLoader";
import { EditorSidebarContext } from "../../../containers/editor/EditorSidebar";
import useFitToScreen from "../../../hooks/useFitToScreen";

let style = Object.assign({}, global);

const BrandkitFonts = props => {
  let timeOut;
  let { metadata, pageNodes, setIsTimeLineViewOpen, isTimeLineViewOpen } = useContext(EditorContext);
  let { setActiveColorThemePageIdx } = useContext(EditorSidebarContext);

  let { brandInfo } = useContext(AppContext);

  const { loading } = useSelector(state => state.company);

  const { changeFontTheme } = useBrandFont();
  const { fitOnScreenMethod } = useFitToScreen();

  const [activeFontIndex, setActiveFontIndex] = useState();
  const [isShownAppliedSuccessfully, setIsShownAppliedSuccessfully] = useState();
  const [headingSize, setHeadingSize] = useState();
  const [subHeadingSize, setSubHeadingSize] = useState();

  const textAssests = [
    {
      name: "Add Heading",
      value: "h1",
      class: "main-heading",
      type: "heading",
    },
    {
      name: "Add Subheading",
      value: "h2",
      class: "sub-heading",
      type: "subheading",
    },
    {
      name: "Add Body Text",
      value: "normal",
      class: "default-text",
      type: "body_text",
    },
  ];

  const applyBrandFont = brandfontDetails => {
    setActiveFontIndex(brandInfo?.brandDetails?.name);
    setActiveColorThemePageIdx(metadata.activePageIdx);
    changeFontTheme(brandInfo?.brandDetails?.name, brandfontDetails);
  };

  const disableMessageAfterSomeSec = () => {
    timeOut = setTimeout(() => {
      setIsShownAppliedSuccessfully(false);
    }, 2000);
  };

  const getRelativeFontSize = (baseFontSize, textWidth) => {
    let adjustedFontSize;
    let padding = 32;
    let conteinerWidth = document.getElementById("brandKitstyleUl")?.getBoundingClientRect().width;
    conteinerWidth = conteinerWidth - padding;

    if (conteinerWidth < textWidth) {
      let widthRatio = conteinerWidth / textWidth; // Calculate the ratio between base width and current width
      adjustedFontSize = baseFontSize * widthRatio; // Calculate the adjusted font size
    } else adjustedFontSize = baseFontSize;

    return adjustedFontSize;
  };

  useEffect(() => {
    if (isShownAppliedSuccessfully) {
      disableMessageAfterSomeSec();
    }
  }, [isShownAppliedSuccessfully]);

  // set Dynamic font size
  useEffect(() => {
    let textHeadingWidth = document.querySelector("#brandKitStyle.main-heading div")?.getBoundingClientRect().width;
    let textHeadingFontSize = getRelativeFontSize(22, textHeadingWidth);
    setHeadingSize(textHeadingFontSize);
    let textSubHeadingWidth = document.querySelector("#brandKitStyle.sub-heading div")?.getBoundingClientRect().width;
    let textSubHeadingFontSize = getRelativeFontSize(18, textSubHeadingWidth);
    setSubHeadingSize(textSubHeadingFontSize);

    document.querySelectorAll(".brandKitStyle").forEach(element => {
      element.style.visibility = "visible";
    });
  }, [brandInfo]);

  useEffect(() => {
    return () => {
      setActiveFontIndex(false);
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <TabPane tabId="2" className={cx(style["brand-font-tab"], style["active"])}>
      {loading && !brandInfo?.brandDetails && (
        <ul className={cx(style["color-list"], style["row"], style["asset-theme-color-loading"], style["row-cols-1"])}>
          <AssetsLoader count={4} />
        </ul>
      )}
      {brandInfo?.brandDetails && (
        <section>
          <div className={cx(style["mb-4"], style["brand-font-style"])} id={brandInfo?.brandDetails?.name}>
            <BrandkitTabPaneSubTitle
              title={"Brand Font Style"}
              tooltipId="brandFontStyle"
              tooltipContent={"Apply brand font style on the entire document"}
            />

            <ul
              id="brandKitstyleUl"
              className={cx(style["rounded"], style["asset-item"])}
              onClick={() => applyBrandFont(brandInfo?.brandDetails)}
              style={{ cursor: "pointer" }}>
              {textAssests.map(textType => (
                <li key={textType.value}>
                  <div
                    key={textType.value}
                    id="brandKitStyle"
                    className={cx(style[textType.class], "brandKitStyle")}
                    style={{
                      visibility: "hidden",
                      whiteSpace: "nowrap",
                      fontFamily: brandInfo?.brandDetails?.[textType.type]?.font_family || "Open Sans",
                      fontWeight: brandInfo?.brandDetails?.[textType.type]?.bold ? 700 : 400,
                      fontStyle: brandInfo?.brandDetails?.[textType.type]?.italic ? "italic" : "normal",
                      fontSize:
                        textType.type === "heading"
                          ? `${headingSize}px`
                          : textType.type === "subheading"
                          ? `${subHeadingSize}px`
                          : "16px",
                    }}>
                    <div>{brandInfo?.brandDetails?.[textType.type]?.font_family || "Open Sans"}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className={style["brand-fonts"]}>
            <BrandkitTabPaneSubTitle
              title={"Brand Fonts"}
              tooltipId="brandFonts"
              tooltipContent={"Click to add or drag-and-drop the brand fonts on your document"}
            />
            <SingleBrandkitFont textAssests={textAssests} />
          </div>

          {pageNodes.length > 1 && (activeFontIndex || isShownAppliedSuccessfully) && (
            <div className={cx(style["mt-auto"], style["text-center"], style["fixed-bottom"])}>
              {isShownAppliedSuccessfully && (
                <span className={cx(style["btn"], style["btn-border"])}>
                  <Icon icon="tick-success" /> Applied Successfully
                </span>
              )}

              {activeFontIndex && (
                <span
                  className={cx(style["btn"], style["btn-border"])}
                  onClick={() => {
                    setIsTimeLineViewOpen(false),
                      changeFontTheme(),
                      setActiveFontIndex(false),
                      setIsShownAppliedSuccessfully(true);
                    setTimeout(() => {
                      if (isTimeLineViewOpen) fitOnScreenMethod(false, false, false);
                    }, 100);
                  }}>
                  Apply to All Pages
                </span>
              )}
            </div>
          )}
        </section>
      )}
    </TabPane>
  );
};

const BrandKit = props => {
  let { brandInfo } = useContext(AppContext);

  const brandSchemes = ["Colors", "Fonts", "Assets"];

  const [schemeType, setSchemeType] = useState("Colors");
  const [isBrandkit, setIsBrandkit] = useState();
  const [isFetchDone, setIsFetchDone] = useState(false);

  useEffect(() => {
    if (brandInfo?.brands.length >= 0) setIsFetchDone(true);
    if (brandInfo?.brands.length > 0) setIsBrandkit(true);
  }, [brandInfo]);

  return (
    <div className={cx(style["mb-2"], style["editor-asset-inner"], style["brand-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />

      {isFetchDone && !isBrandkit && (
        <BrandSetup
          icon="ui-brand"
          heading="Use Brand Kits"
          content="Access all your brand assets to create on-brand designs."
          buttonLabel="Set up Brand Kits"
        />
      )}

      {isFetchDone && isBrandkit && (
        <>
          <div className={style["pr-20"]}>
            <BrandCompanyList />

            <AssetSchemeTypeNav
              schemeTypeArray={brandSchemes}
              setSchemeType={setSchemeType}
              schemeType={schemeType}
              assetType={props.assetType}
            />
          </div>
          <TabContent>
            <div className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}>
              {schemeType === "Colors" && <BrandkitColours />}
              {schemeType === "Fonts" && <BrandkitFonts />}
              {schemeType === "Assets" && <BrandkitAssests />}
            </div>
          </TabContent>
        </>
      )}
    </div>
  );
};

//Props type validation
BrandKit.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default BrandKit;
