import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import AssetCategory from "./Common/AssetCategory";
import AssetLists from "./Common/AssetLists";

let style = Object.assign({}, global);

const Line = props => {
  const [category, setCategory] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isAssetFetched, setIsAssetFetched] = useState(false);
  const [availableAssetCategories, setAvailableAssetCategories] = useState([]);
  const [asstesLoading, setAsstesLoading] = useState();

  return (
    <div className={cx(style["editor-asset-inner"], style["line-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />
      <div className={style["pr-20"]}>
        <AssetCategory
          category={category}
          setCategory={setCategory}
          setPageNumber={setPageNumber}
          setIsAssetFetched={setIsAssetFetched}
          availableAssetCategories={availableAssetCategories}
          asstesLoading={asstesLoading}
        />
      </div>

      <AssetLists
        assetName={props.assetName}
        assetType={props.assetType}
        assetCategory={category}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        isAssetFetched={isAssetFetched}
        setIsAssetFetched={setIsAssetFetched}
        setAvailableAssetCategories={setAvailableAssetCategories}
        asstesLoading={asstesLoading}
        setAsstesLoading={setAsstesLoading}
      />
    </div>
  );
};

//Props type validation
Line.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
};

export default Line;
