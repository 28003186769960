import React, { useContext, useEffect, useRef, useState } from 'react'
import cx from "classnames";
import global from "../../scss/dhp.scss";
import { EditorContext } from '../../containers/editor/EditorLayout';
import { Icon } from '../ui/icon';
import { Input } from '../ui/input';
import { Button } from 'reactstrap';
import useFindAndReplace from '../../hooks/useFindAndReplace';
import useDragFindAndReplace from '../../hooks/useDragFindAndReplace';
import useFind from '../../hooks/useFind';
import { FIND_AND_REPLACE_TOP_PADDING, FIND_AND_REPLACE_WIDTH } from '../../constants/editor';
import { EditorWrapperContext } from '../../containers/editor/EditorWrapper';

const style = Object.assign({}, global);

const FindAndReplace = () => {
    const { isTimeLineViewOpen } = useContext(EditorContext);
    const { showFindAndReplace, setShowFindAndReplace } = useContext(EditorWrapperContext);
    const { findAndReplaceHelper, state, setState, replaceInprogress } = useFindAndReplace()
    const { removeHeighlighter } = useFind({ state });
    const { dragModal } = useDragFindAndReplace();
    const wrapperBoundryRef = useRef();
    const [offset, setOffset] = useState({ x: 99999, y: 99999 });

    const editorWrapper = document.querySelector(".editor-wrapper");

    const setPosition = () => {
        setOffset({ x: document.getElementById("canvas-panel-area")?.getBoundingClientRect()?.width - FIND_AND_REPLACE_WIDTH, y: FIND_AND_REPLACE_TOP_PADDING })
    }

    const setNewPosition = () => {
        setTimeout(() => {
            updateDraggedElementPosition();
        }, 500)
    }

    const updateDraggedElementPosition = () => {
        const target = document.getElementById("find-and-replace");

        if (target && editorWrapper) {
            const boundingRect = editorWrapper.getBoundingClientRect();
            const currentX = parseFloat(target.style.left);
            const currentY = parseFloat(target.style.top);

            const heightDelta = boundingRect.height - wrapperBoundryRef.current.height;
            const widthDelta = boundingRect.width - wrapperBoundryRef.current.width;

            wrapperBoundryRef.current = boundingRect;
            const newX = Math.min(Math.max(currentX + widthDelta, 0), boundingRect.width - target.offsetWidth);
            const newY = Math.min(Math.max(currentY + heightDelta, FIND_AND_REPLACE_TOP_PADDING), boundingRect.height - target.offsetHeight);

            target.style.transition = "left 0.3s, top 0.3s"; // applying smooth transition
            target.style.left = `${newX}px`;
            target.style.top = `${newY}px`;

        }
    };

    const handleKeyDown = (ev) => {
        let key = ev.which || ev.keyCode; // Detecting keyCode
        let ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false; // Detecting Ctrl
        let command = ev.metaKey; // Detecting Command
        if ((key === 70 || key === 114) && (ctrl || command) && ev.target.closest(".find-replace input")) {
            ev.preventDefault();
            setShowFindAndReplace({ isActive: !showFindAndReplace.isActive, origin: "keyboard" });
        }
    }

    const handleHeighlightedWidgetChange = e => {
        if (e?.target?.closest(".dhp-content-editable-true-text")) {
            let findHeighlighter = document.getElementById('dhp-find-highlighter');
            let widgetId = document.querySelector(".dhp-content-editable-true-text")?.getAttribute("data-widget-id");
            let heighlightedWidget = findHeighlighter?.getAttribute("data-widget-id");
            if (heighlightedWidget === widgetId) {
                findHeighlighter.classList.add("d-none");
                // setReplaceInprogress(true);
                // removeHeighlighter();
            }
        }
    }

    useEffect(() => {
        if (showFindAndReplace.isActive && editorWrapper) {
            setPosition();
            wrapperBoundryRef.current = editorWrapper.getBoundingClientRect()
        }
    }, [showFindAndReplace?.isActive])

    useEffect(() => {
      if(isTimeLineViewOpen){
        removeHeighlighter();
        setShowFindAndReplace({ isActive: false, origin: false });
      }
    }, [isTimeLineViewOpen])
    

    useEffect(() => {
        // handle window width resize
        window.addEventListener("resize", updateDraggedElementPosition);

        // handle canvas panel are toggle
        const canvasPanelToggle = document.querySelector("#canvas-panel-area > span");
        canvasPanelToggle.addEventListener("click", setNewPosition);

        // handle CTRL+F click on modal
        document.addEventListener("keydown", handleKeyDown);

        // handle current widget inner text change
        document.addEventListener("input", handleHeighlightedWidgetChange)

        return () => {
            window.removeEventListener("resize", updateDraggedElementPosition);
            canvasPanelToggle.removeEventListener("click", setNewPosition);

            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("input", handleHeighlightedWidgetChange)
        };
    }, [])

    return (
        <div
            className={cx({ [style["d-none"]]: !showFindAndReplace.isActive }, style["rounded"], style["find-replace"], style["shadow-sm"], style["bg-light"], style["movable-div"])}
            id="find-and-replace"
            onMouseDown={e => dragModal(e)}
            style={{
                left: `${offset.x}px`,
                top: `${offset.y}px`,
            }}>
            <h4 className={cx(style["fw-7"], style["mb-4"], style["d-flex"], style["align-items-center"])}>
                Find & Replace
            </h4>
            <span className={cx(style["cross-modal"], style["rounded"])}
                onClick={() => (removeHeighlighter(), setShowFindAndReplace({ isActive: false, origin: false }))} >
                <Icon icon="ui-close" />
            </span>

            <div className={cx(style["position-relative"], style["findWrap"], style["pb-4"])}>
                <div className={cx(style["custom-control"], style["custom-switch"])}>
                    <Input
                        cssModule={style}
                        returnType="noGroup"
                        type="checkbox"
                        className={style["custom-control-input"]}
                        id="match-case"
                        label="Match Case"
                        defaultChecked={state.caseInsensitive}
                        onChange={() => setState({ ...state, caseInsensitive: !state.caseInsensitive })}
                    />
                </div>
                <div className={cx(style["form-group"], style["m-0"])}>
                    <label>Find</label>
                    <Input
                        cssModule={style}
                        type="text"
                        returnType="noGroup"
                        value={state.findKey}
                        onChange={e => setState({ ...state, findKey: e.target.value })}
                    />
                </div>
                {state.allMatches && state?.intFind && (<span className={style["total"]}>Total : {state.activeIndex >= 0 && state.matches ? state.activeIndex + 1 : 0}/{state.matches}</span>)}
            </div>

            <div className={cx(style["form-group"])}>
                <label className="align-items-center d-flex"><div>Replace With</div></label>
                <Input
                    cssModule={style}
                    type="text"
                    returnType="noGroup"
                    value={state.replaceKey}
                    onChange={e => setState({ ...state, replaceKey: e.target.value })}
                />
            </div>
            <div className={cx(style["d-flex"], style["align-items-center"], style["justify-content-between"], style["btnwrap"])}>
                <div className={style["d-flex"]}>
                    <Button color="" onClick={() => !replaceInprogress && findAndReplaceHelper.replaceCurrent()} className={cx(style["mr-2"], style["btn-border"])} disabled={state.matches <= 0 || state.activeIndex < 0 || state.isLocked}>
                        Replace
                    </Button>
                    <Button color="" onClick={findAndReplaceHelper.replaceAll} className={cx(style["mr-2"], style["btn-border"])} disabled={state.matches <= 0 || state.activeIndex < 0 || state.isLocked}>
                        Replace All
                    </Button>
                </div>
                <span className={style["border"]}></span>
                <div className={cx(style["d-flex"], style["small"])}>
                    <Button color="primary" onClick={findAndReplaceHelper.previous} className={cx(style["ml-2"], style["custom-tooltip"])} disabled={!state.findKey || (state.intFind && state.matches <= 0 || (state.matches === 1 && state.activeIndex === 0))} >
                        <Icon icon={"ui-arrow-left"} />
                        <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Previous</div>
                    </Button>
                    <Button color="primary" onClick={findAndReplaceHelper.next} className={cx(style["ml-2"], style["custom-tooltip"])} disabled={!state.findKey || (state.intFind && state.matches <= 0) || (state.matches === 1 && state.activeIndex === 0)}>
                        <Icon icon={"ui-arrow-right"} />
                        <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Next</div>
                    </Button>
                </div>
            </div>
            {state.isLocked && (
                <div className={cx(style["alert"], style["alert-block"], style["alert-danger"], style["mt-3"], style["fw-6"])}>
                    {'"' + state.findKey + '"'} is locked{state.lockedInPages.length > 1 ? " on page " + state.lockedInPages.join(', ') : ""}. Please unlock to replace.
                </div>
            )}
        </div>
    )
}

export default FindAndReplace
