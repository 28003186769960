import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { NavLink, Tooltip } from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AppContext } from "../../../contexts";
import { Input } from "../../ui/input";
import { Icon } from "../../../components/ui/icon";
import { fetchBrandDetils } from "../../../store/actions/companyActions";
import { COMPANY_SUPERADMIN } from "../../../constants/company";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import global from "../../../scss/dhp.scss";
import useAccessControl from "../../../hooks/useAccessControl";

let style = Object.assign({}, global);

const BrandCompanyList = () => {
  let { brandInfo } = useContext(AppContext);

  const dispatch = useDispatch();

  const { checkAccess } = useAccessControl();
  let hasManageBrandAccess = checkAccess("brands");

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [defaultValue, setDefaultValue] = useState();
  const [modifiedBrandList, setModifiedBrandList] = useState();
  const [selectedBrandId, setSelectedBrandId] = useState();
  const [manageBrandEndpoint, setManageBrandEndpoint] = useState();

  // check is this company is paid or not
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);
  const isSuperAdmin = paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN;
  const TooltipText = isSuperAdmin || hasManageBrandAccess ? "Manage Brand" : "Only Admin can manage Brand";

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleChange = evt => {
    const brandId = evt.id;
    setSelectedBrandId(brandId);
  };

  //fetch selected brand details
  useEffect(() => {
    if (selectedBrandId) {
      sessionStorage.setItem('lastSelectedBrandId', selectedBrandId);
      dispatch(fetchBrandDetils(selectedBrandId));
    }
  }, [selectedBrandId]);

  // Set Default Value
  useEffect(() => {
    if (brandInfo && brandInfo.brandDetails) {
      setDefaultValue(brandInfo.brandDetails);
    }
  }, [brandInfo]);

  // Fetch brand info, modify and set to modifiedBrandList
  useEffect(() => {
    if (brandInfo) {
      const modifiedBrands = brandInfo?.brands?.map(item => ({
        ...item,
        name: item.brand_name,
      }));

      setModifiedBrandList(modifiedBrands);
    }
  }, [brandInfo]);

  // set manageBrandEndpoint
  useEffect(() => {
    if (selectedBrandId) {
      setManageBrandEndpoint(selectedBrandId);
    } else {
      setManageBrandEndpoint(brandInfo?.brands[0]?.id);
    }
  }, [selectedBrandId, brandInfo]);

  return (
    <div className={cx(style["d-flex"], style["align-items-center"], style["justify-content-between"], style["manage-brand"])}>
      <div className={cx(style["select-brand"])}>
        <Input
          type="dropdown"
          classNamePrefix="select"
          getOptionValue={modifiedBrandList => modifiedBrandList.name}
          getOptionLabel={modifiedBrandList => <span>{modifiedBrandList.name}</span>}
          options={modifiedBrandList}
          updateState={handleChange}
          defaultValue={defaultValue}
        />
      </div>
      <div className={cx(style["p-2"], style["btn-square-icon"])} id="manageBrandTooltip" >
        {(isSuperAdmin || hasManageBrandAccess) && (
          <NavLink tag={RRNavLink} to={`/companies/settings/brands/${manageBrandEndpoint}`} className={cx(style["d-flex"], style["color-33"], style["p-0"])}>
            <Icon icon="ui-settings" />
          </NavLink>
        )}

        {(!isSuperAdmin && !hasManageBrandAccess) && <Icon icon="ui-info" additionalclass={"font-base"} />}

        <Tooltip placement="bottom" isOpen={tooltipOpen} target="manageBrandTooltip" fade={false} toggle={toggle}>
          {TooltipText}
        </Tooltip>
      </div>
    </div>
  );
};

export default BrandCompanyList;
