import React, { useContext, useEffect } from "react";
import cx from "classnames";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useFontList from "../../../hooks/useFontList";
import { contextualConfig } from "../editor_config";
import { EditorPanelContext } from "../../../containers/editor/EditorPanel";

let style = Object.assign({}, global);

const List = props => {
  let { metadata } = useContext(EditorContext);
  let { copyStyle, updateCopyStyle } = useContext(EditorPanelContext);

  const setListFontWidget = useFontList(-1);

  // for copy style
  useEffect(() => {
    if (!copyStyle.enable && copyStyle.changeList) {
      setListFontWidget(contextualConfig.List.findIndex(item => item.tag === copyStyle.changeList.toLowerCase()));
      updateCopyStyle({ ...copyStyle, changeList: false });
    }
  }, [copyStyle.changeList]);

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: document.getElementById(metadata.activeWidgetId[0])?.getAttribute("data-subwidget"),
      })}>
      <UncontrolledDropdown className={cx(style["toolset-item"], style["list-wrap"])}>
        <DropdownToggle
          tag="span"
          className={cx(style["toolset-action"], style["custom-tooltip"])}
          onClick={() => props?.setmoreSecondaryOptionClicked("List")}>
          <Icon icon="ui-list" />
          List
        </DropdownToggle>
      </UncontrolledDropdown>
    </li>
  );
};
List.propTypes = {
  setmoreSecondaryOptionClicked: PropTypes.func,
};

export default List;
