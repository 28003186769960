import { useContext, useEffect } from "react";
import { widgetConfig as widgetConf, widgetHandlerConfig } from "../components/Editor/editor_config";
import {
  TEXT,
  SHAPE,
  N,
  S,
  W,
  E,
  NW,
  NE,
  SW,
  SE,
  DRAG,
  RESIZE,
  ROTATE,
  WIDGET_HANDLER_MARGIN_LEFT,
  WIDGET_HANDLER_MARGIN_TOP,
  LINE,
  PICTURE,
  VIDEO,
  UPLOAD,
  YOUTUBE,
  GENERIC_EDITOR_WRAP_PADDING,
  TYPE_INFOGRAPHIC,
  COLLAGE,
  TYPE_PROPOSAL,
} from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import { EditorCanvasPanelContext } from "../containers/editor/EditorCanvasPanel";
import {
  getCssTransformObj,
  getZoomedValue,
  getWidgetAndParentsDomReference,
  isUnsuportedWidgetsInSelection,
} from "../_helpers/utils";

import re_0_png from "../assets/images/90.png";
import re_15_png from "../assets/images/195.png";
import re_30_png from "../assets/images/210.png";
import re_45_png from "../assets/images/45.png";
import re_60_png from "../assets/images/60.png";
import re_75_png from "../assets/images/75.png";
import re_90_png from "../assets/images/90a.png";
import re_105_png from "../assets/images/105.png";
import re_120_png from "../assets/images/120.png";
import re_135_png from "../assets/images/135.png";
import re_150_png from "../assets/images/150.png";
import re_165_png from "../assets/images/165.png";
import re_180_png from "../assets/images/180.png";

import ro_0_png from "../assets/images/rotate-bottom.png";
import ro_90_png from "../assets/images/rotate-right.png";
import ro_180_png from "../assets/images/rotate-top.png";
import ro_270_png from "../assets/images/rotate-left.png";

const useWidgetHandler = () => {
  const { dimension, documentType, metadata, isTimeLineViewOpen } = useContext(EditorContext);
  const { widgetHandler, updateWidgetHandler } = useContext(EditorCanvasPanelContext);

  const WH = {
    meta: false,

    config: {
      resizers: {
        defaultAngles: {
          [N]: 0,
          [NE]: 45,
          [E]: 90,
          [SE]: 135,
          [S]: 180,
          [SW]: 225,
          [W]: 270,
          [NW]: 315,
        },
        defaultCursors: {
          0: [354, 8, `url(${re_0_png}) 15 15, auto`],
          15: [9, 23, `url(${re_15_png}) 15 15, auto`],
          30: [24, 38, `url(${re_30_png}) 15 15, auto`],
          45: [39, 53, `url(${re_45_png}) 15 15, auto`],
          60: [54, 68, `url(${re_60_png}) 15 15, auto`],
          75: [69, 83, `url(${re_75_png}) 15 15, auto`],
          90: [84, 98, `url(${re_90_png}) 15 15, auto`],
          105: [99, 113, `url(${re_105_png}) 15 15, auto`],
          120: [114, 128, `url(${re_120_png}) 15 15, auto`],
          135: [129, 143, `url(${re_135_png}) 15 15, auto`],
          150: [144, 158, `url(${re_150_png}) 15 15, auto`],
          165: [159, 173, `url(${re_165_png}) 15 15, auto`],
          180: [174, 188, `url(${re_180_png}) 15 15, auto`],
          195: [189, 203, `url(${re_15_png}) 15 15, auto`],
          210: [204, 218, `url(${re_30_png}) 15 15, auto`],
          225: [219, 233, `url(${re_45_png}) 15 15, auto`],
          240: [234, 248, `url(${re_60_png}) 15 15, auto`],
          255: [249, 263, `url(${re_75_png}) 15 15, auto`],
          270: [264, 278, `url(${re_90_png}) 15 15, auto`],
          285: [279, 293, `url(${re_105_png}) 15 15, auto`],
          300: [294, 308, `url(${re_120_png}) 15 15, auto`],
          315: [309, 323, `url(${re_135_png}) 15 15, auto`],
          330: [324, 338, `url(${re_150_png}) 15 15, auto`],
          345: [339, 353, `url(${re_165_png}) 15 15, auto`],
        },
      },
      rotator: {
        defaultAngles: {
          ["rotator"]: 0,
        },
        defaultCursors: {
          0: [315, 44, `url(${ro_0_png}) 15 15, auto`],
          90: [45, 134, `url(${ro_90_png}) 15 15, auto`],
          180: [135, 224, `url(${ro_180_png}) 15 15, auto`],
          270: [225, 314, `url(${ro_270_png}) 15 15, auto`],
        },
      },
    },

    getHandler: () => {
      return document.getElementById("dhp-widget-handler");
    },

    handleSmallSizeAdjustments: ({ widgetHeight, widgetWidth, re, resizerObj }) => {
      // condition - 1
      if (widgetHeight <= 25) {
        if ([SE].includes(re)) return resizerObj;
        if ([NW, NE, SW].includes(re)) return { ...resizerObj, opacity: "0" };

        if ([N, S].includes(re)) {
          if (widgetWidth > 55 && widgetWidth <= 100) return { ...resizerObj, opacity: "0" };
          else if (widgetWidth > 100) return resizerObj;
        }
      }

      // condition - 2
      else if (widgetHeight > 25 && widgetHeight <= 35) {
        if ([NW, SE].includes(re)) return resizerObj;
        if ([NE, SW].includes(re)) return { ...resizerObj, opacity: "0" };

        if ([N, S].includes(re)) {
          if (widgetWidth > 55 && widgetWidth <= 100) return { ...resizerObj, opacity: "0" };
          else if (widgetWidth > 100) return resizerObj;
        }
      }

      // condition - 3
      else if (widgetHeight > 35 && widgetHeight <= 55) {
        if ([NW, NE, SW, SE].includes(re)) return resizerObj;
        if ([W, E].includes(re)) return { ...resizerObj, opacity: "0" };

        if ([N, S].includes(re)) {
          if (widgetWidth > 35 && widgetWidth <= 55) return { ...resizerObj, opacity: "0" };
          else if (widgetWidth > 55) return resizerObj;
        }
      }

      // condition - 4
      else if (widgetHeight > 55) {
        if ([W, E, NW, NE, SW, SE].includes(re)) return resizerObj;

        if ([N, S].includes(re)) {
          if (widgetWidth > 35 && widgetWidth <= 55) return { ...resizerObj, opacity: "0" };
          else if (widgetWidth > 55) return resizerObj;
        }
      }
    },

    updateReactDom: ({ zoom, theta, groupSelection, activeWidgetType }) => {
      const { style, assetType, fileType, cropToShape, category, scheme, source, layerLocked } = WH.meta;
      const isWidgetLocked = layerLocked === "true";

      const widgetConfig = widgetConf[assetType];
      const resizerConfig = widgetHandlerConfig.resizers;
      const widgetWidth = groupSelection ? parseFloat(style.width) : getZoomedValue(parseFloat(style.width), zoom);
      const widgetHeight = groupSelection ? parseFloat(style.height) : getZoomedValue(parseFloat(style.height), zoom);

      const rotator = {
        enabled: !isWidgetLocked && (widgetConfig?.events?.includes(ROTATE) || groupSelection),
      };

      const resizers = {
        all: Object.keys(resizerConfig),
        applicable: widgetConfig?.resizers || [],
        valid: [],
      };

      if (!isWidgetLocked) {
        resizers.all.forEach(re => {
          const resizerObj = resizerConfig[re];

          // for TEXT
          if (assetType === TEXT) {
            if (widgetHeight <= 40) {
              if ([E, NW].includes(re)) resizers.valid.push({ ...resizerObj, zIndex: "9999" });
              if ([W, NE, SW, SE].includes(re)) resizers.valid.push({ ...resizerObj, opacity: "0" });
            } else {
              if (resizers.applicable.includes(re)) resizers.valid.push(resizerObj);
            }
          }

          // for LINE
          else if (assetType === LINE) {
            if (resizers.applicable.includes(re)) resizers.valid.push(resizerObj);
          }

          // Conditional display of 4 corner-resizers [NW, NE, SW, SE] when SHIFT_SELECTED_WIDGETS(always for groupSelection), SHAPE(apart from Rectangles/Abstract + filled), PICTURE(cropToShape), UPLOAD(cropToShape & svg) and VIDEO(cropToShape & Youtube)
          else if (
            groupSelection ||
            (assetType === SHAPE && !(["Rectangles", "Abstract"].includes(category) && scheme === "filled")) ||
            ([PICTURE, UPLOAD, VIDEO].includes(assetType) &&
              (cropToShape === "true" || fileType === "svg" || source === YOUTUBE))
          ) {
            if ([NW, NE, SW, SE].includes(re))
              resizers.valid.push(WH.handleSmallSizeAdjustments({ widgetHeight, widgetWidth, re, resizerObj }));
          }

          // default (as setup in widget config)
          else if (resizers.applicable.includes(re)) {
            resizers.valid.push(WH.handleSmallSizeAdjustments({ widgetHeight, widgetWidth, re, resizerObj }));
          }
        });
      }

      if (
        (groupSelection && activeWidgetType?.includes(COLLAGE)) ||
        (!activeWidgetType && isUnsuportedWidgetsInSelection(metadata.activeWidgetId))
      )
        resizers.valid = [];

      updateWidgetHandler &&
        updateWidgetHandler({
          widget: {
            theta: parseFloat(theta),
          },
          handler: {
            rotator: resizers.valid.length > 0 ? rotator.enabled : false,
            resizers: resizers.valid,
          },
        });
    },

    toggleTooltip: ({ action, event, tooltip, theta }) => {
      const dragTooltip = document.querySelector("#dhp-widget-handler .draggable .tooltip");
      const rotateTooltip = document.querySelector("#dhp-widget-handler .rotatable .tooltip");
      const resizeTooltip = document.querySelector("#dhp-widget-handler .resizable .tooltip");
      const item =
        event === DRAG ? dragTooltip : event === ROTATE ? rotateTooltip : event === RESIZE ? resizeTooltip : false;

      if (action === "SHOW" && item) {
        item.classList.remove("d-none");
        item.innerHTML = tooltip;
        if (theta) item.style.transform = `rotate(-${theta}deg)`;
      }
      if (action === "HIDE" && item) {
        item.classList.add("d-none");
        item.innerHTML = "";
        item.style.transform = "";
      }
    },

    toggleEventHandlers: ({ action }) => {
      const rotateHandler = document.querySelector("#dhp-widget-handler .rotatable .rotator");
      const resizeHandlers = document.querySelector("#dhp-widget-handler .resizable .resizers")?.childNodes;
      if (rotateHandler && resizeHandlers) {
        [rotateHandler, ...resizeHandlers].forEach(hndlr =>
          hndlr.classList[action === "HIDE" ? "add" : "remove"]("d-none")
        );
      }
    },

    toggleEventCursorToDefault: ({ action, node }) => {
      const config = {
        SHOW: node?.style?.cursor,
        HIDE: "",
      };
      document.getElementsByTagName("body")[0].style.cursor = config[action];
    },

    getDynamicCursor: ({ theta, defaultCursors, firstQuadrantMax, returnStr = "" }) => {
      Object.keys(defaultCursors).every(key => {
        let angle = theta;
        let [r1, r2, cursor] = defaultCursors[key];

        if (r1 > r2) {
          r2 += 360;
          if (angle >= 0 && angle <= firstQuadrantMax) angle += 360;
        }

        if (angle >= r1 && angle <= r2) {
          returnStr = cursor;
          return false;
        }
        return true;
      });
      return returnStr;
    },

    getDynamicCursorSet: ({ theta, returnObj = {} }) => {
      Object.keys(WH.config).forEach(key => {
        const hndlrConf = WH.config[key];

        Object.keys(hndlrConf.defaultAngles).forEach(key => {
          const newAngle = hndlrConf.defaultAngles[key] + theta;
          const relativeAngle = newAngle > 360 ? newAngle - 360 : newAngle;

          returnObj[key] = WH.getDynamicCursor({
            theta: relativeAngle,
            defaultCursors: hndlrConf.defaultCursors,
            firstQuadrantMax: key === "rotator" ? 44 : 8,
          });
        });
      });
      return returnObj;
    },

    iterateThroughEventHandlers: ({ type, dynamicCursors }) => {
      [N, S, W, E, NW, NE, SW, SE, "rotator"].forEach(key => {
        const evtHndlr = document.querySelector(
          key === "rotator"
            ? "#dhp-widget-handler .rotatable .rotator"
            : `#dhp-widget-handler .resizable .resizers .${key}`
        );
        if (evtHndlr) {
          if (type === "reset") evtHndlr.style.cursor = "";
          if (type === "apply") evtHndlr.style.cursor = dynamicCursors[key];
        }
      });
    },

    updateEventCursors: ({ theta }) => {
      const dynamicCursors = WH.getDynamicCursorSet({ theta: theta });

      // bugfix ~ wrong cursor for wrong resizer (catalina | sfari 13.1) | reset style.cursor in first place and then apply new cursor
      WH.iterateThroughEventHandlers({ type: "reset", dynamicCursors });
      WH.iterateThroughEventHandlers({ type: "apply", dynamicCursors });
    },

    groupSelectionShow: ({
      activeBlock,
      zoom = dimension.zoom,
      css,
      transform,
      finalUpdate,
      trimmedSelection,
      theta,
      activeWidgetType,
    }) => {
      const handler = WH.getHandler();

      if (css) {
        WH.meta = {
          style: css,
        };

        const editorOuterWrapTop = document.querySelectorAll(".dhp-page-canvas")[0].getBoundingClientRect().top;
        const activeBlockTop = activeBlock.getBoundingClientRect().top;
        const addedLeft = WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING;
        const addedTop = WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING;
        const activePageTop = isTimeLineViewOpen ? 0 : Math.abs(activeBlockTop - editorOuterWrapTop);

        setTimeout(() => {
          handler.classList.remove("d-none");
          handler.setAttribute("data-widget-id", "false");
          handler.style.cssText = `
            display: block; 
            width: ${css.width}; 
            height: ${css.height};
            transform: ${css.transform};
            left: ${
              [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) ? getZoomedValue(addedLeft, zoom) : addedLeft
            }px;
            top: ${
              activePageTop +
              ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) ? getZoomedValue(addedTop, zoom) : addedTop)
            }px;
          `;
        }, 30);
      }

      if (transform) handler.style.transform = transform;

      if (finalUpdate) WH.updateReactDom({ zoom, groupSelection: true, theta, activeWidgetType });

      if (trimmedSelection) {
        handler.style.transform = trimmedSelection.transform;
        handler.style.height = trimmedSelection.height;
        handler.style.width = trimmedSelection.width;
      }
    },

    show: ({ activeWidgetId, zoom = dimension.zoom, css, finalUpdate }) => {
      const handler = WH.getHandler();
      const {
        widget: { css: widgetStyle, data: widgetData },
        block: {
          rect: { top: activeBlockTop },
        },
        editorOuterWrapTop,
      } = getWidgetAndParentsDomReference(activeWidgetId);

      const cssObj = {
        width: css?.width ?? widgetStyle.width,
        height: css?.height ?? widgetStyle.height,
        transform: css?.transform ?? widgetStyle.transform,
      };

      WH.meta = {
        ...widgetData,
        style: cssObj,
      };

      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: cssObj.transform,
      });

      const widgetTransformStr = getCssTransformObj({
        translateX: getZoomedValue(widgetTransX, zoom) + "px",
        translateY: getZoomedValue(widgetTransY, zoom) + "px",
        transform: cssObj.transform,
        returnStr: true,
      });

      const addedLeft = WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING;
      const addedTop = WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING;
      const activePageTop = isTimeLineViewOpen ? 0 : Math.abs(activeBlockTop - editorOuterWrapTop);

      handler.classList.remove("d-none");
      handler.setAttribute("data-widget-id", activeWidgetId);
      handler.style.cssText = `
        opacity: 1;
        display: block;
        width: ${getZoomedValue(cssObj.width, zoom)}px;
        height: ${getZoomedValue(cssObj.height, zoom)}px;
        transform: ${widgetTransformStr};
        left: ${
          [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) ? getZoomedValue(addedLeft, zoom) : addedLeft
        }px;
        top: ${
          activePageTop +
          ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) ? getZoomedValue(addedTop, zoom) : addedTop)
        }px;
      `;

      // NOTE:::: based on 'finalUpdate' flag resizers on WidgetHandler is getting updated to react DOM
      if (finalUpdate) WH.updateReactDom({ zoom, theta: widgetTheta });
    },

    hide: () => {
      const handler = WH.getHandler();
      handler.classList.add("d-none");
      handler.style.cssText = `
        opacity: 0;
      `;
    },
  };

  useEffect(() => {
    WH.updateEventCursors({ theta: widgetHandler?.widget?.theta ?? 0 });
  }, [widgetHandler]);

  return {
    show: WH.show,
    hide: WH.hide,
    toggleEventHandlers: WH.toggleEventHandlers,
    toggleTooltip: WH.toggleTooltip,
    updateEventCursors: WH.updateEventCursors,
    toggleEventCursorToDefault: WH.toggleEventCursorToDefault,
    groupSelectionShow: WH.groupSelectionShow,
  };
};

export default useWidgetHandler;
