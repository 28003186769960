import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { DropdownItem } from "reactstrap";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import {
  ANIMATION,
  GROUP_WIDGET,
  PICTURE,
  STOCK,
  SVG,
  TABLE,
  TYPE_FORM,
  JOT_FORM,
  UPLOAD,
  VIDEO,
  BRAND,
  UPLOAD_VIDEO,
  MAP,
} from "../../../constants/editor";
import { EditorPanelContext } from "../../../containers/editor/EditorPanel";

let style = Object.assign({}, global);

const Flip = props => {
  let { metadata, widgets, updateWidgets } = useContext(EditorContext);
  const { togglePlayButton, updateTogglePlayButton } = useContext(EditorPanelContext);

  const [horizontaId, setHorizontaId] = useState([]);
  const [verticalId, setVerticalId] = useState([]);
  const [updateContext, setUpdateContext] = useState(false);

  const flipHoraizental = () => {
    props.setDropdownOpen(false);
    setUpdateContext(true);

    for (let i = 0; i < metadata.activeWidgetId.length; i++) {
      horizontaId[i] === 1 || horizontaId[i] === undefined ? (horizontaId[i] = -1) : (horizontaId[i] = 1);
      if (verticalId[i] === undefined) verticalId[i] = 1;
    }

    setHorizontaId([...horizontaId]);
    setVerticalId([...verticalId]);
    stopVideio();
  };

  const flipVertical = () => {
    props.setDropdownOpen(false);
    setUpdateContext(true);

    for (let i = 0; i < metadata.activeWidgetId.length; i++) {
      verticalId[i] === 1 || verticalId[i] === undefined ? (verticalId[i] = -1) : (verticalId[i] = 1);
      if (horizontaId[i] === undefined) horizontaId[i] = 1;
    }

    setHorizontaId([...horizontaId]);
    setVerticalId([...verticalId]);
    stopVideio();
  };

  const stopVideio = () => {
    metadata.activeWidgetId.forEach(id => {
      if (
        document.getElementById(id).getAttribute("data-asset-type") === VIDEO &&
        [STOCK, BRAND, UPLOAD_VIDEO].includes(document.getElementById(id).getAttribute("data-source")) &&
        document.getElementById(id).getAttribute("data-playing") === "true"
      ) {
        setTimeout(() => {
          updateTogglePlayButton(!togglePlayButton);
        }, 10);
      }
    });
  };

  useEffect(() => {
    if (horizontaId.length === 0 || verticalId.length === 0) return;

    let newArray = Object.assign([...widgets]);

    metadata.activeWidgetId.forEach((id, idx) => {
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

      document.querySelector("#" + id + " .dhp-widget-inner").dataset.horizontal = horizontaId[idx];
      document.querySelector("#" + id + " .dhp-widget-inner").dataset.vertical = verticalId[idx];

      if (
        document.getElementById(id).getAttribute("data-asset-type") === PICTURE ||
        document.getElementById(id).getAttribute("data-asset-type") === ANIMATION ||
        (document.getElementById(id).getAttribute("data-asset-type") === UPLOAD &&
          document.getElementById(id).getAttribute("data-file-type") !== SVG) ||
        (document.getElementById(id).getAttribute("data-asset-type") === VIDEO &&
          [STOCK, BRAND, UPLOAD_VIDEO].includes(document.getElementById(id).getAttribute("data-source")))
      ) {
        let isCropped = document.getElementById(id).getAttribute("data-crop-to-shape") === "true" ? true : false;

        if (isCropped)
          document.querySelector(
            `#${id} .flippable`
          ).style.transform = `scaleX(${horizontaId[idx]}) scaleY(${verticalId[idx]})`;
        else {
          document.querySelector(
            `#${id} .dhp-widget-inner`
          ).childNodes[0].style.transform = `scaleX(${horizontaId[idx]}) scaleY(${verticalId[idx]})`;
        }
      } else {
        document.querySelector(
          "#" + id + " .dhp-widget-inner"
        ).style.transform = `scaleX(${horizontaId[idx]}) scaleY(${verticalId[idx]})`;
      }

      newArray = Object.assign([...newArray], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });
    });

    updateContext && updateWidgets(newArray);
    setUpdateContext(false);
  }, [horizontaId, verticalId, updateContext]);

  useEffect(() => {
    if (
      metadata.activeWidgetId &&
      props.dropdownOpen &&
      !document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group")
    ) {
      metadata.activeWidgetId.forEach(id => {
        if (document.querySelector("#" + id + " .dhp-widget-inner").dataset.horizontal !== undefined)
          horizontaId.push(parseInt(document.querySelector("#" + id + " .dhp-widget-inner").dataset.horizontal));

        if (document.querySelector("#" + id + " .dhp-widget-inner").dataset?.vertical !== undefined)
          verticalId.push(parseInt(document.querySelector("#" + id + " .dhp-widget-inner").dataset?.vertical));
      });

      setHorizontaId([...horizontaId]);
      setVerticalId([...verticalId]);
    }
  }, [props.dropdownOpen]);

  return (
    <>
      <li className={style["col"]}>
        <DropdownItem
          tag="a"
          onClick={flipHoraizental}
          className={cx({
            [style["disabled"]]:
              metadata.activeWidgetId.length > 1 ||
              [GROUP_WIDGET, TABLE, TYPE_FORM, JOT_FORM, MAP].includes(metadata.activeWidgetType[0]),
          })}>
          <Icon icon="ui-flip-horizontal" />
          Flip Horizontal
        </DropdownItem>
      </li>

      <li className={style["col"]}>
        <DropdownItem
          tag="a"
          onClick={flipVertical}
          className={cx({
            [style["disabled"]]:
              metadata.activeWidgetId.length > 1 ||
              [GROUP_WIDGET, TABLE, TYPE_FORM, JOT_FORM, MAP].includes(metadata.activeWidgetType[0]),
          })}>
          <Icon icon="ui-flip-vertical" />
          Flip Vertical
        </DropdownItem>
      </li>
    </>
  );
};

Flip.propTypes = {
  setDropdownOpen: PropTypes.func,
  dropdownOpen: PropTypes.bool,
};

export default Flip;
