import { useContext } from "react";

import * as constant from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";

const useGoToPage = () => {
  let { documentType, metadata, blockNodes, dimension, isTimeLineViewOpen } = useContext(EditorContext);

  const goToPage = zoom => {
    if (
      metadata.activeBlockId != "" &&
      ![constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType) &&
      !metadata.disableAutoScroll
    ) {
      setTimeout(() => {
        if (document.getElementById(`page-top-option-${metadata.activePageIdx}`))
          document.getElementById(`page-top-option-${metadata.activePageIdx}`).scrollIntoView();
        else document.getElementById(metadata.activePageId).scrollIntoView();
      }, 100);
      // let currentZoom = zoom ? parseFloat(zoom) / 100 : dimension.zoom / 100;
      // let activePageHeight = parseFloat(blockNodes[metadata.activePageIdx].style.height);
      // let targetOffset = (activePageHeight * currentZoom + 26) * metadata.activePageIdx;

      // if (targetOffset >= 0) {
      //   if (isTimeLineViewOpen) document.querySelector("#canvas-panel-area").scrollTop = 0;
      //   else {
      //     setTimeout(function () {
      //       document.querySelector("#canvas-panel-area").scrollTop = targetOffset;
      //     }, 500);
      //   }
      // }
    }
  };

  return { goToPage };
};

export default useGoToPage;
