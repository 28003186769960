import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { widgetConfig } from "../components/Editor/editor_config";
import { EditorContext } from "../containers/editor/EditorLayout";
import { fetchUploadAssets, uploadAssetFile } from "../store/actions/editorActions";
import { getImgDimensionFromUrl } from "../_helpers/utils";
import useAddWidget from "./useAddWidget";
import useAlignment from "./useAlignment";
import useElementInnerHtml from "./useElementInnerHtml";
import * as constant from "../constants/editor";
import { EditorPanelContext } from "../containers/editor/EditorPanel";

const useUploadInsertOutsideVideo = () => {
  let { metadata, dimension, setisUploadDone, isUploadDone, uploadState, setUploadState } =
    useContext(EditorContext);
  let { keyBoardEvent, updateKeyBoardEvent } = useContext(EditorPanelContext);

  const dispatch = useDispatch();

  // used global states
  const { progressInfo, availableUploadVideos, uploadLocation } = useSelector(state => state.editor);

  // Local states
  const [assetType, setAssetType] = useState();
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  const [dataparam, setDataParam] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);

  // used custom Hooks
  const { postion: getPosition } = useAlignment(addWidgetClicked, widgetWidth, widgetHeight, "middle-center");
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    assetType,
    dataparam,
    getPosition,
    widgetWidth,
    widgetHeight
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataparam,
    getNewWidgetObject
  );

  const uploadOutsideVideo = file => {
    // Return if an upload is already in progress
    if (!isUploadDone) {
      return;
    }
    setAssetType(constant.VIDEO);

    // upload files
    const formData = new FormData();
    formData.append("file", file, file.name);

    dispatch(fetchUploadAssets({ limit: 25, pageNumber: 1, file_type: "video" })).then(resp => {
      dispatch(uploadAssetFile(formData, "video", "videoClipboard", resp.data.data.assets));
      setUploadState({...uploadState, uploadSchemeType: "Videos"});

      setisUploadDone(false);
      if (document.getElementById("videoListWrapper")) document.getElementById("videoListWrapper").scrollTop = 0;
      updateKeyBoardEvent({ ...keyBoardEvent, paste: false });
    });
  };

  const insertUploadedVideo = async ({ url, thumb, duration }) => {
    if (!assetType) return;

    const { width: imgWidth, height: imgHeight } = await getImgDimensionFromUrl(thumb);
    const imgAspectRatio = imgWidth / imgHeight;
    let { width: activeBlockWidth } = document.getElementById(`${metadata.activeBlockId}`)?.getBoundingClientRect();
    activeBlockWidth = (activeBlockWidth * 100) / parseFloat(dimension.zoom);
    const width = imgWidth <= activeBlockWidth ? imgWidth : activeBlockWidth;
    const height = imgWidth <= activeBlockWidth ? imgHeight : width / imgAspectRatio;

    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

    setTimeout(() => {
      let paramObj = {
        "data-poster": thumb,
        "data-url": url,
        "data-duration": duration,
      };

      setWidgetWidth(width);
      setWidgetHeight(height);
      setAssetInnerContent(url);
      setDataParam({ ...widgetConfig[constant.VIDEO].UPLOADVIDEO.dataAttr, ...paramObj });
      setAddWidgetClicked(true);
      setTimeout(() => {
        setAssetType();
      }, 1000);
    }, 1);
  };

  useEffect(() => {
    if (progressInfo === 100 && uploadLocation === "videoClipboard") {
      const lastUploadedVideoFromClipboard = availableUploadVideos[0];

      insertUploadedVideo(lastUploadedVideoFromClipboard);

      setTimeout(() => {
        setisUploadDone(true);
        setUploadState({...uploadState, uploadSchemeType: null})
      }, 1000);
    }
  }, [progressInfo]);

  return { uploadOutsideVideo };
};

export default useUploadInsertOutsideVideo;
