import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEXT, TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  calculateNewPositionOnRotatedObjectResize,
  convertToSlug,
  getCssTransformObj,
  getKeyBySlug,
  getUpdatedGroupInnerWidgetPos,
  getZoomedValue,
  isOnlyTextInGroup,
  isUnsuportedWidgetsInSelection,
} from "../_helpers/utils";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import useCheckWidgetPosition from "./useCheckWidgetPosition";
import useCloneWidget from "./useCloneWidget";
import useCutWidget from "./useCutWidget";
import useDeleteWidget from "./useDeleteWidget";
import useGroupUngroup from "./useGroupUngroup";
import usePasteWidget from "./usePasteWidget";
import useSelectable from "./useSelectable";
import useUploadInsertOutsideImage from "./useUploadInsertOutsideImage";
import useWidgetHandler from "./useWidgetHandler";
import useFontFormatting from "./useFontFormatting";
import useSetDimension from "./useSetDimension";
import useCollaborativeSelector from "./useCollaborativeSelector";
import useWidgetHighlighter from "./useWidgetHighlighter";
import { updateDocument } from "../store/actions/documentActions";
import { useLocalStorage } from "./useStorage";
import { AppContext } from "../contexts";
import useFindHighlighter from "./useFindHighlighter";
import useDeleteAudio from "./useDeleteAudio";
import { EditorPanelContext } from "../containers/editor/EditorPanel";
import useFitToScreen from "./useFitToScreen";
import { EditorWrapperContext } from "../containers/editor/EditorWrapper";

const useHandelKeyBoardEvent = () => {
  let {
    metadata,
    updateMetadata,
    widgets,
    updateWidgets,
    dimension,
    redo,
    undo,
    HistoryList,
    currentPointerPos,
    documentType,
    updateDimension,
    blockNodes,
    pageNodes,
    updateDocumentType,
    uploadState,
    setUploadState
  } = useContext(EditorContext);
  const { isEyedropperActive } = useContext(AppContext);
  let { keyBoardEvent, updateKeyBoardEvent } = useContext(EditorPanelContext);
  const { showFindAndReplace, setShowFindAndReplace } = useContext(EditorWrapperContext);

  let widgetid = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(widgetid)?.closest(".dhp-page-group");
  let targetId = isGroupWidget
    ? document.getElementById(widgetid).closest(".dhp-root-widget").getAttribute("id")
    : widgetid;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
  let isOnlyTextInGroupVar = isGroupWidget ? isOnlyTextInGroup(metadata.activeWidgetType[0], widgetid) : true;

  // used custom hooks
  const setDeleteWidget = useDeleteWidget();
  const { start: initSelectable, shiftSelectStart, selectAllStart } = useSelectable();
  const { groupSelectionShow: show_GWH } = useWidgetHandler();
  const { group: initWidgetGroup, ungroup: initWidgetUngroup } = useGroupUngroup();
  const { cutWidget } = useCutWidget();
  const { pasteObj } = usePasteWidget();
  const { cloneWidget, cloneAudio } = useCloneWidget();
  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const deleteWidgetIfOutside = useCheckWidgetPosition();
  const { uploadOutsideImage } = useUploadInsertOutsideImage();
  const {
    updateFontWeight,
    updateFontStyle,
    updateTextDecoration,
    selectedPartFontFormatting,
    checkSelectedPartFormat,
  } = useFontFormatting();
  const { setTotalHeight } = useSetDimension();
  const updateCollaborativeWidgetSelectionStyle = useCollaborativeSelector();
  const updateFindHighlighter = useFindHighlighter();
  const { hide: hide_WHT } = useWidgetHighlighter();
  const documentId = useSelector(state => state.document?.documentDetails?.data?.id);
  const dispatch = useDispatch();
  const [templateSizes] = useLocalStorage("templateSizes");
  const { deleteAudio } = useDeleteAudio();
  const { fitOnScreenMethod } = useFitToScreen();


  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const handelKeyDown = ev => {
    ev = ev || window.event; // Event object 'ev'
    let key = ev.which || ev.keyCode; // Detecting keyCode
    let ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false; // Detecting Ctrl
    let command = ev.metaKey; // Detecting Command
    let shift = ev.shiftKey; // Detecting Shift
    let innerHtmlflag, currentFontFamily, index;

    // restrict any keyboard event custom function for input field and any content editable div and Text-area in comments
    if (
      ["INPUT", "TEXTAREA"].includes(ev.target.tagName) ||
      ev.target.getAttribute("contentEditable") ||
      localStorage.getItem("widget.event.isActive") === "true"
    ) {
      if ((key === 66 && (ctrl || command)) || (key === 73 && (ctrl || command)) || (key === 85 && (ctrl || command))) {
        ev.preventDefault();

        // Format selected part of text by keyboard when text is contenteditable
        if (metadata.activeWidgetType[0] === TEXT) {
          innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
          currentFontFamily = innerHtmlflag.style.fontFamily;
          index = fonts.findIndex(
            font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
          );

          // Bold selected part of content editable text
          if (key === 66 && (ctrl || command)) {
            let { isaItalicSelPart } = checkSelectedPartFormat();
            if (
              (isaItalicSelPart && fonts[index]?.bold_italic_weight === "") ||
              (!isaItalicSelPart && fonts[index]?.bold_weight === "")
            )
              return; // return if font family dose not support bold
            selectedPartFontFormatting("bold");
          }
          // Italic selected part of content editable text
          else if (key === 73 && (ctrl || command)) {
            let { isBoldSelPart } = checkSelectedPartFormat();
            if (
              (isBoldSelPart && fonts[index]?.bold_italic_weight === "") ||
              (!isBoldSelPart && fonts[index]?.normal_italic_weight === "")
            )
              return; // return if font family dose not support italic
            selectedPartFontFormatting("italic");
          }
          // Underline selected part of content editable text
          else if (key === 85 && (ctrl || command)) selectedPartFontFormatting("underline");
        }
      }
      return;
    }

    switch (true) {
      //CTRL + B
      case key === 66 && (ctrl || command):
        ev.preventDefault();
        if (
          !metadata.activeWidgetId ||
          metadata.activeWidgetId.length > 1 ||
          widgets[targetWidgetIndex].data["data-layer-locked"] === true ||
          metadata.activeWidgetType[0] !== TEXT ||
          !isOnlyTextInGroupVar
        )
          break;

        innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
        currentFontFamily = innerHtmlflag.style.fontFamily;
        index = fonts.findIndex(
          font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
        );

        if (
          (innerHtmlflag.style.fontStyle === "italic" && fonts[index]?.bold_italic_weight === "") ||
          (innerHtmlflag.style.fontStyle === "normal" && fonts[index]?.bold_weight === "")
        )
          break;

        parseInt(innerHtmlflag.style.fontWeight) === parseInt(fonts[index].normal_weight)
          ? updateFontWeight(false)
          : updateFontWeight(true);
        break;

      //CTRL + I
      case key === 73 && (ctrl || command):
        ev.preventDefault();
        if (
          !metadata.activeWidgetId ||
          metadata.activeWidgetId.length > 1 ||
          widgets[targetWidgetIndex].data["data-layer-locked"] === true ||
          metadata.activeWidgetType[0] !== TEXT
        )
          break;

        innerHtmlflag = document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`);
        currentFontFamily = innerHtmlflag.style.fontFamily;
        index = fonts.findIndex(
          font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
        );

        if (
          (parseInt(innerHtmlflag.style.fontWeight) === 700 && fonts[index]?.bold_italic_weight === "") ||
          (parseInt(innerHtmlflag.style.fontWeight) === parseInt(fonts[index].normal_weight) &&
            fonts[index]?.normal_italic_weight === "")
        )
          break;

        innerHtmlflag.style.fontStyle === "italic" ? updateFontStyle(true) : updateFontStyle(false);
        break;

      //CTRL + U
      case key === 85 && (ctrl || command):
        ev.preventDefault();
        if (
          !metadata.activeWidgetId ||
          metadata.activeWidgetId.length > 1 ||
          widgets[targetWidgetIndex].data["data-layer-locked"] === true ||
          metadata.activeWidgetType[0] !== TEXT
        )
          break;

        updateTextDecoration();
        break;

      //CTRL + D
      case key === 68 && (ctrl || command):
        ev.preventDefault();

        if (metadata.activeWidgetId) {
          if (widgets[targetWidgetIndex].data["data-layer-locked"] === true || isEyedropperActive) break;
          updateKeyBoardEvent({ ...keyBoardEvent, clone: true });
          cloneWidget();
        } else if (metadata.activeAudioId) {
          cloneAudio();
        }
        break;

      //CTRL + Z
      case key === 90 && (ctrl || command):
        ev.preventDefault();
        if (currentPointerPos <= 1) break;
        undo();
        undoRedoAction("undo");
        hide_WHT();
        break;

      //CTRL + Y
      case key === 89 && (ctrl || command):
        ev.preventDefault();
        if (HistoryList.length - 1 <= currentPointerPos) break;
        redo();
        undoRedoAction("redo");
        hide_WHT();
        break;

      //CTRL + A
      case key === 65 && (ctrl || command):
        sessionStorage.setItem("retainRotation", false);
        selectAllStart();
        break;

      //CTRL + G
      case key === 71 && (ctrl || command) && !shift:
        ev.preventDefault();
        if (isUnsuportedWidgetsInSelection(metadata.activeWidgetId)) break;
        initWidgetGroup();
        break;

      //CTRL + Shift + G
      case key === 71 && (ctrl || command) && shift:
        ev.preventDefault();
        if (isUnsuportedWidgetsInSelection(metadata.activeWidgetId)) break;
        sessionStorage.setItem("retainRotation", true);
        initWidgetUngroup();
        break;

      //DELETE (linus and windows key code 46 & for mac keycode is 8)
      case key === 46 || key === 8:
        if (metadata.activeWidgetId) {
          if (widgets[targetWidgetIndex].data["data-layer-locked"] === true || isEyedropperActive) break;
          setDeleteWidget(true);
        } else if (metadata.activeAudioId) deleteAudio();

        break;

      // left arrow
      case key === 37:
        updateWidgetTransformProperty(ev, "x", "dec");
        updateFindHighlighter();
        updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom });
        break;

      // right arrow
      case key === 39:
        updateWidgetTransformProperty(ev, "x", "inc");
        updateFindHighlighter();
        updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom });
        break;

      // up arrow
      case key === 38:
        updateWidgetTransformProperty(ev, "y", "dec");
        updateFindHighlighter();
        updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom });
        break;

      // down arrow
      case key === 40:
        updateWidgetTransformProperty(ev, "y", "inc");
        updateFindHighlighter();
        updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom });
        break;

      // CTRL + F
      case (key === 70 || key === 114) && (ctrl || command):
        ev.preventDefault();
        setShowFindAndReplace({ isActive: !showFindAndReplace.isActive, origin: "keyboard" });
        break;
    }
  };

  const handelKeyUp = ev => {
    ev = ev || window.event; // Event object 'ev'
    let key = ev.which || ev.keyCode; // Detecting keyCode

    // restrict any keyboard event custom function for input field and any content editable div
    if (
      ev.target.tagName === "INPUT" ||
      ev.target.getAttribute("contentEditable") ||
      localStorage.getItem("widget.event.isActive") === "true"
    )
      return;

    // up, down, left, right arrow
    if (key === 37 || key === 38 || key === 39 || key === 40) {
      if (!metadata.activeWidgetId || widgets[targetWidgetIndex].data["data-layer-locked"] === true) return;

      let widgetTransformStr, isDletable;
      let newArray = Object.assign([...widgets]);

      metadata.activeWidgetId.forEach(id => {
        let x_al, y_al;
        let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
        let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
        let widgetIndex = widgets.findIndex(widget => widget.id === targetId);
        widgetTransformStr = document.getElementById(targetId).style.transform;

        if (metadata.activeWidgetId.length === 1) {
          let al = checkWidgetAllignmentForSingleWidget(false, widgetTransformStr);
          isDletable = deleteWidgetIfOutside(targetId);

          x_al = al.x_al;
          y_al = al.y_al;
        } else {
          x_al = widgets[targetWidgetIndex].data["data-x-allignment"];
          y_al = widgets[targetWidgetIndex].data["data-y-allignment"];
        }

        // update context value on keyup function
        newArray = Object.assign([...newArray], {
          [widgetIndex]: {
            ...widgets[widgetIndex],
            style: {
              ...widgets[widgetIndex].style,
              transform: widgetTransformStr,
            },
            data: {
              ...widgets[widgetIndex].data,
              "data-x-allignment": x_al,
              "data-y-allignment": y_al,
            },
          },
        });
      });

      // Check widget position during drag, if it is outside canvas area delete the widget else upadte new position
      if (isDletable) setDeleteWidget(true);
      else updateWidgets(newArray);
      initSelectable({ css: { transform: widgetTransformStr }, finalUpdate: true }); // NOTE:::: update handler based on context manipulation
    }
  };

  const handleClick = e => {
    // NOTE:::: indicates whether the shift key was pressed or not when a mouse click event occurs
    if (e.shiftKey) {
      shiftSelectStart(e);
    }
  };

  const updateWidgetTransformProperty = (ev, direction, method) => {
    if (!metadata.activeWidgetId || widgets[targetWidgetIndex].data["data-layer-locked"] === true) return;

    ev.preventDefault(); // if there is active widget default behaviour will prevent

    metadata.activeWidgetId.forEach(targetWidgetId => {
      let isGroupWidget = document.getElementById(targetWidgetId).closest(".dhp-page-group");
      let targetId = isGroupWidget
        ? document.getElementById(targetWidgetId).closest(".dhp-root-widget").getAttribute("id")
        : targetWidgetId;
      const newTransform = getNewTransformStr(targetId, direction, method);
      document.getElementById(targetId).style.transform = newTransform; // update DOM in keydown

      if (metadata.activeWidgetId.length === 1) {
        // NOTE:::: update handler based on dom manipulation (for single widget)
        initSelectable({ css: { transform: newTransform } });
      }
    });

    if (metadata.activeWidgetId.length > 1) {
      // NOTE:::: update handler based on dom manipulation (for multi widget)
      const newTransform = getNewTransformStr("dhp-widget-handler", direction, method, "handler");
      show_GWH({ transform: newTransform });
    }
  };

  const getNewTransformStr = (elemId, direction, method, type) => {
    const transformString = document.getElementById(elemId).style.transform;
    const transformObject = getCssTransformObj({ transform: transformString });
    const oldValue = parseFloat(transformObject.translate[direction]);
    const delta = type === "handler" ? getZoomedValue(1, dimension.zoom) : 1;
    const newValue = method === "inc" ? oldValue + delta : oldValue - delta;

    return getCssTransformObj({
      transform: transformString,
      [direction === "x" ? "translateX" : "translateY"]: `${newValue}px`,
      returnStr: true,
    });
  };

  const handlePaste = e => {
    if (localStorage.getItem("widget.event.isActive") === "true" || document.querySelector(".tabel-modal")) return;

    // when paste in document rename field
    if (e.target.classList.contains("document-rename")) {
      e.preventDefault();
      removeFormatOnPasteDocName(e);
    }

    // restrict this fynction for widget copy paste and paste inside any input field
    if (["INPUT", "TEXTAREA"].includes(e.target.tagName) || e.target.classList.contains("document-rename")) return;

    let targetNode = metadata.activeWidgetType[0] === TEXT ? e.target.closest(".dhp-widget-inner") : e.target;

    //copy for dochipo assets and outside image
    if (!targetNode || targetNode.getAttribute("contenteditable") !== "true") CopyOutsideImage(e);
  };

  const handleCopy = e => {
    // restrict this function for inside any input field
    if (
      ["INPUT", "TEXTAREA"].includes(e.target.tagName) ||
      localStorage.getItem("widget.event.isActive") === "true" ||
      document.querySelector(".tabel-modal")
    )
      return;

    if (!metadata.activeWidgetId || widgets[targetWidgetIndex].data["data-layer-locked"] === true) return;

    let targetNode = metadata.activeWidgetType[0] === TEXT ? e.target.closest(".dhp-widget-inner") : e.target;

    // restrict this function for inside any contenteditable asset
    if (!targetNode || targetNode.getAttribute("contenteditable") !== "true") {
      navigator.clipboard.writeText(" ");
      updateKeyBoardEvent({ ...keyBoardEvent, copy: true });
    }
  };

  const handleCut = e => {
    // restrict this function for inside any input field
    if (
      ["INPUT", "TEXTAREA"].includes(e.target.tagName) ||
      localStorage.getItem("widget.event.isActive") === "true" ||
      document.querySelector(".tabel-modal")
    )
      return;

    if (!metadata.activeWidgetId || widgets[targetWidgetIndex].data["data-layer-locked"] === true) return;

    let targetNode = metadata.activeWidgetType[0] === TEXT ? e.target.closest(".dhp-widget-inner") : e.target;

    // restrict this function for inside any contenteditable asset
    if (!targetNode || targetNode.getAttribute("contenteditable") !== "true") {
      navigator.clipboard.writeText(" ");
      updateKeyBoardEvent({ ...keyBoardEvent, cut: true });
      cutWidget();
    }
  };

  const CopyOutsideImage = e => {
    let items = e.clipboardData.items;
    let imgIndex;

    // fetch index, which have image/png value
    for (let x = 0; x < items.length; x++) {
      if (items[x].type.indexOf("image/png") !== -1) imgIndex = x;
    }

    let i = items.length > 1 ? imgIndex : 0;

    if (!items[i]) return;

    // copy inside assets
    if (items[i].type.indexOf("image") == -1) {
      updateKeyBoardEvent({ ...keyBoardEvent, paste: true });
      pasteObj();
    }
    // copy outside image and upload it
    else {
      let blob = items[i].getAsFile(); // Retrieve image on clipboard as blob
      uploadOutsideImage(blob);
      setUploadState({...uploadState, isOutSideAssetUpload: true})
    }
  };

  const undoRedoAction = status => {
    let isUpdateDimension;
    let pos = status === "undo" ? currentPointerPos - 1 : currentPointerPos + 1;
    let index = HistoryList[pos]?.activeBlockIndex ? HistoryList[pos]?.activeBlockIndex : 0;

    if (blockNodes[index]) {
      const { blockId, blockIdx } = blockNodes[index];
      const { pageId, pageIdx } = [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
        ? pageNodes[0]
        : pageNodes[index];

      if (status === "undo") isUpdateDimension = HistoryList[pos + 1].resizedStatus;
      if (status === "redo") isUpdateDimension = HistoryList[pos].resizedStatus;

      updateMetadata({
        ...metadata,
        activeWidgetId: false,
        activeWidgetType: false,
        activePageId: pageId,
        activePageIdx: pageIdx,
        activeBlockId: blockId,
        activeBlockIdx: blockIdx,
      });

      // set total page height for infograpgic
      if ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)) setTotalHeight(HistoryList[pos].blockNodes);

      if (isUpdateDimension) {
        updateDimension({
          ...dimension,
          ...HistoryList[pos].currentDimension,
        });

        setTimeout(() => {
          if (![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)) {
            // apply adjustment fot firefox and safari start
            let userAgent = navigator.userAgent;
            let isFirefox = userAgent.match(/firefox|fxios/i);
            let isSafari = !userAgent.match(/chrome/i) && userAgent.match(/safari/i);
            let curEditorWrap = document.getElementById("generic-editor-wrap");
            if (curEditorWrap && (isFirefox || isSafari)) {
              curEditorWrap.style.width = `${
                HistoryList[pos].currentDimension.width * (HistoryList[pos].currentDimension.zoom / 100)
              }px`;
              // curEditorWrap.style.overflow = `hidden`;
            }
            fitOnScreenMethod();

            // Update template type in DB
            if (HistoryList[pos].currentDocumentType) {
              let templateTypeId = getKeyBySlug(templateSizes, convertToSlug(HistoryList[pos].currentDimension.label));
              if (templateTypeId)
                dispatch(
                  updateDocument(documentId, {
                    template_type: HistoryList[pos].currentDimension.label,
                    template_type_id: templateTypeId,
                  })
                ).then(() => {
                  updateDocumentType(HistoryList[pos].currentDocumentType);
                });
            }
          } else {
            document.querySelector(".generic-editor-wrap").style.width = `${parseFloat(
              HistoryList[pos].currentDimension.width
            )}px`;
            // document.querySelector(".canvas-block").style.width = `${getZoomedValue(
            //   parseFloat(HistoryList[pos].currentDimension.width),
            //   HistoryList[pos].currentDimension.zoom
            // )}px`;
            let editorouterWrap = document.querySelector(".editor-outer-wrap");
            editorouterWrap.style.width = `${getZoomedValue(
              parseFloat(HistoryList[pos].currentDimension.width),
              HistoryList[pos].currentDimension.zoom
            )}px`;
            editorouterWrap.style.height = `${
              getZoomedValue(
                parseFloat(HistoryList[pos].currentDimension.height),
                HistoryList[pos].currentDimension.zoom
              ) + 80
            }px`;
          }
        }, 1);
      }
    }
  };

  const removeFormatOnPasteDocName = e => {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    const selection = window.getSelection();
    if (!selection.rangeCount) return false;
    selection.deleteFromDocument();

    let newDiv = document.createElement("div");
    newDiv.innerHTML = paste;
    selection.getRangeAt(0).insertNode(document.createTextNode(paste));
  };

  const handlePasteAndRemoveFormat = e => {
    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // Paste data after delete all formatting to any content editable div
    // if (e.clipboardData && e.clipboardData.getData && !isSafari) {
    //   let paste = (e.clipboardData || window.clipboardData).getData("text");
    //   let originalTextId = document.querySelector(".dhp-content-editable-true-text").getAttribute("data-widget-id");
    //   let originalTextIsBullet = document.getElementById(originalTextId).getAttribute("data-subwidget");
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //   const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    //   const selection = window.getSelection();

    //   if (!selection.rangeCount) return false;

    //   selection.deleteFromDocument();

    //   if (selection.anchorNode) {
    //     // Solve firefox issue for text content edit : apply bullet in a text -> double click -> ctrl+A -> copy and paste content -> bullet is removed and for chrome and saafri if anyone remove the bulet and then paste bulet should not remove
    //     if (
    //       isFirefox &&
    //       selection.getRangeAt(0)?.commonAncestorContainer?.classList.contains("primary-text-widget") &&
    //       originalTextIsBullet
    //     ) {
    //       let listIndex = originalTextIsBullet === "UL" ? 0 : 1;
    //       let newDiv = document.createElement(contextualConfig.List[listIndex].tag);
    //       newDiv.classList = contextualConfig.List[listIndex].class;
    //       newDiv.style.cssText = getCssTextObjToString(contextualConfig.List[listIndex].style);
    //       newDiv.innerHTML = `<li>${paste}</li>`;
    //       document.querySelector(".dhp-content-editable-true-text .dhp-widget-inner").innerHTML = newDiv.outerHTML;
    //     } else {
    //       selection.getRangeAt(0).insertNode(document.createTextNode(paste));
    //     }
    //   } else {
    //     // Solve saffari issue for text content edit : apply bullet in a text -> double click -> ctrl+A -> copy and paste content -> bullet is removed
    //     if (originalTextIsBullet && isSafari) {
    //       let listIndex = originalTextIsBullet === "UL" ? 0 : 1;
    //       let newDiv = document.createElement(contextualConfig.List[listIndex].tag);
    //       newDiv.classList = contextualConfig.List[listIndex].class;
    //       newDiv.style.cssText = getCssTextObjToString(contextualConfig.List[listIndex].style);
    //       newDiv.innerHTML = `<li>${paste}</li>`;
    //       document.querySelector(".dhp-content-editable-true-text .dhp-widget-inner").innerHTML = newDiv.outerHTML;
    //     } else {
    //       let newDiv = document.createElement("div");
    //       newDiv.innerHTML = paste;
    //       document.querySelector(".dhp-content-editable-true-text .dhp-widget-inner").innerHTML = newDiv.outerHTML;
    //     }
    //   }

    //   // updatye text handler on copy paste outside text
    //   if (metadata.activeWidgetType[0] === TEXT) {
    //     // for group text
    //     if (isGroupWidget) {
    //       let { groupDivHeight } = getUpdatedGroupInnerWidgetPos(
    //         targetId,
    //         widgetid,
    //         e.target.closest(".dhp-widget-inner").offsetHeight,
    //         dimension.zoom
    //       );

    //       document.querySelector(`.dhp-content-editable-true-text .child-selected`).style.outline = "0px solid #00a4ef";
    //       document.querySelector(`.dhp-content-editable-true-text .child-selected .dhp-widget-inner`).style.outline =
    //         "2px solid #00a4ef";
    //       document.getElementById("dhp-widget-handler").style.height = `${groupDivHeight}px`;
    //     }
    //     // for single text
    //     else {
    //       document.getElementById("dhp-widget-handler").style.height = `${
    //         document.querySelector(".dhp-content-editable-true-text .dhp-widget-inner").offsetHeight
    //       }px`;
    //     }
    //   }

    //   e.preventDefault();
    // }

    // only remove rich text for formatting
    e.preventDefault();
    let text = (e.originalEvent || e).clipboardData.getData("text/plain");
    // document.execCommand("insertText", false, text);
    // Get the current selection
    let selection = window.getSelection();

    // Check if there is a selection
    if (selection.rangeCount > 0) {
      // Get the first range of the selection
      let range = selection.getRangeAt(0);
      let textNode = document.createElement("span");
      textNode.innerText = text;

      // Replace the selected content with your desired text
      range.deleteContents();
      range.insertNode(textNode);
    }

    // updatye text handler on copy paste outside text
    if (metadata.activeWidgetType[0] === TEXT) {
      // for group text
      if (isGroupWidget) {
        let { groupDivHeight } = getUpdatedGroupInnerWidgetPos(
          targetId,
          widgetid,
          e.target.closest(".dhp-widget-inner").offsetHeight,
          dimension.zoom
        );

        document.querySelector(`.dhp-content-editable-true-text .child-selected`).style.outline = "0px solid #00a4ef";
        document.querySelector(`.dhp-content-editable-true-text .child-selected .dhp-widget-inner`).style.outline =
          "2px solid #00a4ef";
        document.getElementById("dhp-widget-handler").style.height = `${groupDivHeight}px`;
      }
      // for single text
      else {
        // handle rotated text widget
        let handler = document.getElementById("dhp-widget-handler");
        let newHeight = document.querySelector(".dhp-content-editable-true-text .dhp-widget-inner").offsetHeight;
        const {
          translate: { x: handlerTransX, y: handlerTransY },
          rotate: { theta: handlerTheta },
        } = getCssTransformObj({
          transform: handler.style.transform,
        });
        const { left, top } = calculateNewPositionOnRotatedObjectResize(
          parseFloat(handlerTransX),
          parseFloat(handlerTransY),
          parseFloat(handler.style.width),
          parseFloat(newHeight),
          parseFloat(handler.style.width),
          parseFloat(handler.style.height),
          parseFloat(handlerTheta)
        );
        const handlerTransformStr = getCssTransformObj({
          translateX: `${left}px`,
          translateY: `${top}px`,
          transform: handler.style.transform,
          returnStr: true,
        });

        handler.style.transform = handlerTransformStr;
        handler.style.height = `${newHeight}px`;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handelKeyDown);
    window.addEventListener("keyup", handelKeyUp);
    document.addEventListener("click", handleClick);
    document.addEventListener("paste", handlePaste);
    document.addEventListener("copy", handleCopy);
    document.addEventListener("cut", handleCut);
    document.querySelector(".dhp-content-editable-true-text")?.addEventListener("paste", handlePasteAndRemoveFormat);

    return () => {
      window.removeEventListener("keydown", handelKeyDown);
      window.removeEventListener("keyup", handelKeyUp);
      document.removeEventListener("click", handleClick);
      document.removeEventListener("paste", handlePaste);
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("cut", handleCut);
      document
        .querySelector(".dhp-content-editable-true-text")
        ?.removeEventListener("paste", handlePasteAndRemoveFormat);
    };
  });
};

export default useHandelKeyBoardEvent;
