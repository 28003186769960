import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import global from "../../scss/dhp.scss";

import { EditorContext } from "../../containers/editor/EditorLayout";
import { fetchDocumentDetails } from "../../store/actions/documentActions";
import { fetchTemplateGroups, fetchTemplatePageSizes } from "../../store/actions/templateActions";
import { formatTemplatePageSizes } from "../../_helpers/utils";
import Modal from "../ui/modal";
import PageSizeModal from "./Modals/PageSizeModal";
import {
  PAGETYPE_CUSTOM_VALUE,
  TEMPLATE_GROUP_ADVERTISEMENT,
  TEMPLATE_GROUP_SOCIAL_GRAPHIC,
  TEMPLATE_GROUP_VALUE_ADVERTISEMENT,
  TEMPLATE_GROUP_VALUE_COMMON,
  TEMPLATE_GROUP_VALUE_SOCIAL_GRAPHIC,
  TEMPLATE_GROUP_VALUE_VIDEO,
  TEMPLATE_GROUP_VALUE_WEB_BANNER,
  TEMPLATE_GROUP_VIDEO,
  TEMPLATE_GROUP_WEB_BANNER,
} from "../../constants/pageSizeModal";

let style = Object.assign({}, global);

const PageResizeComponent = ({ showModal, setShowModal }) => {
  const { dimension, metadata, updateMetadata } = useContext(EditorContext);

  const dispatch = useDispatch();
  const { id } = useParams();

  const documentDetails = useSelector(state => state.document?.documentDetails?.data);
  const { templateGroups: availableTemplateGroups, templatePageSizes } = useSelector(state => state.template);
  const availableTemplatePageSizes = formatTemplatePageSizes(templatePageSizes);

  const currentTemplateGroupId = documentDetails?.template_info?.group_id;
  const currentGroup = availableTemplateGroups?.template_groups?.find(a => a?.id === currentTemplateGroupId);
  const currentGroupName =
    currentGroup?.name === TEMPLATE_GROUP_SOCIAL_GRAPHIC
      ? TEMPLATE_GROUP_VALUE_SOCIAL_GRAPHIC
      : currentGroup?.name === TEMPLATE_GROUP_WEB_BANNER
      ? TEMPLATE_GROUP_VALUE_WEB_BANNER
      : currentGroup?.name === TEMPLATE_GROUP_ADVERTISEMENT
      ? TEMPLATE_GROUP_VALUE_ADVERTISEMENT
      : currentGroup?.name === TEMPLATE_GROUP_VIDEO
      ? TEMPLATE_GROUP_VALUE_VIDEO
      : TEMPLATE_GROUP_VALUE_COMMON;
  const selectedPageType = availableTemplatePageSizes?.metadata?.availablePageSizes[currentGroupName].filter(
    pageType => pageType.value !== PAGETYPE_CUSTOM_VALUE
  );

  useEffect(() => {
    if (id && !documentDetails) dispatch(fetchDocumentDetails(id));
    if (!availableTemplateGroups) dispatch(fetchTemplateGroups());
    if (!templatePageSizes) dispatch(fetchTemplatePageSizes());
  }, []);

  // useEffect(() => {
  //   if (showModal)
  //     updateMetadata({ ...metadata, activeWidgetId: false, activeWidgetType: false })
  // }, [showModal])

  return (
    <>
      {showModal && (
        <Modal
          component={PageSizeModal}
          backdrop="static"
          modalClassName={style["pagesetup-modal"]}
          dimension={dimension}
          selectedPageType={selectedPageType}
          currentGroupName={currentGroupName}
          availableTemplatePageSizes={availableTemplatePageSizes}
          showModal={showModal}
          setShowModal={setShowModal}
          documentId={id}
        />
      )}
    </>
  );
};

export default PageResizeComponent;

PageResizeComponent.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  documentId: PropTypes.string,
};
