import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { updateEditorReducer } from "../store/actions/editorActions";
import { useCheckCompanyPlanInfo } from "./useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../constants/company";
import { useContextualUpgrade } from "./useContextualUpgrade";
import { VERSION } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";

const useHandleWidgetAction = () => {
  let { documentType, dimension, pageNodes, blockNodes, backgroundColors, backgroundImages, widgets } = useContext(EditorContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);
  const showUpgrade = useContextualUpgrade();

  const availableWidgetLists = useSelector(state => state?.editor?.availableWidgetLists);

  const [widgetActionClicked, setWidgetActionClicked] = useState("*");
  const [returnFromAsset, setReturnFromAsset] = useState(false);
  const [activeAssetName, setActiveAssetName] = useState("");
  const [activeAssetSchemeType, setactiveAssetSchemeType] = useState("");
  const [activeAssetType, setActiveAssetType] = useState("");
  const [groupIndex, setGroupIndex] = useState();

  // Widget click action
  const handleWidgetAction = (
    widgetSlug,
    widgetName,
    widgetType,
    index,
    returnFromAsset,
    groupslug,
    schemeType,
    toggleModal,
    toggleMapModal,
    togglePageResizeModal
  ) => {
    if ("*" === widgetSlug) {
      setWidgetActionClicked("*");
      if (returnFromAsset) setReturnFromAsset(true);
      if (index !== false) setGroupIndex(index);
    } else {
      // Show modal for table
      if (widgetSlug === "table") {
        toggleModal();
        return;
      }
      // Show modal for page-size
      if (widgetSlug === "page-size") {
        togglePageResizeModal();
        return;
      }
      // Show modal for map
      if (widgetSlug === "map") {
        toggleMapModal();
        return;
      }
      // Contextual Upgrade modal for Version
      if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && widgetSlug === VERSION && !paidCompanyInfo?.isPaid) {
        // Call showUpgrade function to show contexttual upgrade modal based on your condition
        showUpgrade("versionHistory", {
          docPagesMeta: { pageNodes, dimension, documentType, blockNodes, backgroundColors, backgroundImages, widgets },
        });
        return;
      }

      let i = availableWidgetLists?.findIndex(availableWidgetList => availableWidgetList.slug === groupslug);
      let componentName = "";

      widgetSlug.split("-").forEach(widgetT => {
        componentName += widgetT.charAt(0).toUpperCase() + widgetT.slice(1);
      });

      setReturnFromAsset(false);
      setWidgetActionClicked(componentName);
      setActiveAssetName(widgetName);
      setActiveAssetType(widgetType);
      setGroupIndex(index ?? groupIndex);
      schemeType && setactiveAssetSchemeType(schemeType);

      let dataObj = {
        documentId: id,
        index: i,
        slug: widgetSlug,
        name: widgetName,
        type: widgetType,
        component: componentName,
        schemeType,
      };

      sessionStorage.setItem("dataObj", JSON.stringify(dataObj));
    }
  };

  return {
    handleWidgetAction,
    widgetActionClicked,
    returnFromAsset,
    activeAssetName,
    activeAssetSchemeType,
    activeAssetType,
    groupIndex,
  };
};

export default useHandleWidgetAction;
