import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCompanyProfileInfo, getCompanyProfileList } from "../store/actions/companyActions";
import { COMPANY_SUPERADMIN } from "../constants/company";

const useCompanyProfiles = ({ profileId, profileName } = {}) => {
  const dispatch = useDispatch();
  const { profileList: userProfiles, profilePermissionInfo } = useSelector(state => state?.company);
  const currentUserInfo = useSelector(state => state.auth?.user);

  const [companyProfiles, setCompanyProfiles] = useState();
  const [companyProfilePermissions, setCompanyProfilePermissions] = useState();
  const [profileInfo, setProfileInfo] = useState();
  const excludeArray = ["custom_merge_fields", "Manage_Proposal_Settings", "integrations"];

  useEffect(() => {
    if (profileId && !profilePermissionInfo) {
      dispatch(getCompanyProfileInfo(profileId));
    }
  }, [profileId]);

  useEffect(() => {
    if (profilePermissionInfo) {
      setCompanyProfilePermissions(profilePermissionInfo?.config);
    }
  }, [profilePermissionInfo]);

  useEffect(() => {
    if (currentUserInfo && currentUserInfo.role === COMPANY_SUPERADMIN) {
      if (userProfiles) {
        const updatedProfiles = userProfiles.map(({ _id, ...rest }) => ({
          id: _id,
          slug: rest.name?.toLowerCase().replace(/\s+/g, "_"),
          ...rest,
        }));
        setCompanyProfiles(updatedProfiles);
        if (profileId) {
          setProfileInfo(updatedProfiles.find(resp => resp.id === profileId));
        }
      } else dispatch(getCompanyProfileList());
    }
  }, [userProfiles, currentUserInfo]);

  useLayoutEffect(() => {
    if (profileName) {
      setProfileInfo({
        id: profileId,
        name: profileName,
        slug: profileName.name?.toLowerCase().replace(/\s+/g, "_"),
      });
    }
  }, [profileName]);

  const generateProfilePermissions = useMemo(() => {
    if (!companyProfilePermissions || !profileInfo) return;

    // Check if profilePermissions are already in localStorage
    // const storedPermissions = localStorage.getItem(`profilePermissions_${profileId}`);
    // if (storedPermissions) {
    //   return JSON.parse(storedPermissions);
    // }

    // If not in localStorage, generate and store it
    const permissions = companyProfilePermissions?.map(profileGroup => {
      // Determine if this group should be enabled based on the profile
      let isEnabled = false;
      let profileName = profileInfo?.name?.toLowerCase().replace(/\s+/g, "_");

      if (profileName === "super_admin") {
        isEnabled = true; // Enable all permissions
      } else if (profileName === "admin") {
        isEnabled = profileGroup.permission_slug !== "super_admin_permissions"; // Disable Super Admin Permissions
      } else if (profileName === "standard") {
        isEnabled = profileGroup.permission_slug === "general_permissions"; // Only General Permissions enabled
      }

      // Map the permissions and add the enabled field
      return {
        ...profileGroup,
        permissions: profileGroup.permissions
          .filter(permission => !excludeArray.includes(permission.module)) // Exclude specified modules
          .map(permission => ({
            ...permission,
            enabled: isEnabled,
          })),
      };
    });

    // Store in localStorage
    localStorage.setItem(`profilePermissions_${profileId}`, JSON.stringify(permissions));

    return permissions;
  }, [profileInfo, companyProfilePermissions, profileId]);

  return { profilePermissions: generateProfilePermissions, companyProfiles: companyProfiles, profileInfo: profileInfo };
};

export default useCompanyProfiles;
