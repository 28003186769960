import { fileDownload, getImgDimensionFromUrl } from "../../_helpers/utils";
import { STREAMING_FAILED, STREAMING_SUCCESS } from "../../constants/socket";
import {
  ADD_TO_QUEUE_LIST,
  UPDATE_QUEUE_LIST,
  QUEUE_FETCH_SUCCESS,
  QUEUE_READ_SUCCESS,
  READ_QUEUE_SYNC,
} from "../actions/actionTypes";
import _ from "lodash";

const initialState = { downloads: [] };

export const downloadQueueReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_QUEUE_LIST: {
      const storedThumbQueue = JSON.parse(localStorage.getItem("downloadQueueThumb")) || [];
      const thumbQueue = new Map(storedThumbQueue.map(item => [item.eventId, item]));

      // Update the entry
      thumbQueue.set(action.payload.eventId, {
        eventId: action.payload.eventId,
        thumbContext: action.payload.thumbContext,
        createdOn: action.payload.createdOn
      });

      // Store updated data in localStorage
      localStorage.setItem("downloadQueueThumb", JSON.stringify([...thumbQueue.values()]));

      return { ...state, downloads: [action.payload, ...state.downloads] };
    }

    case UPDATE_QUEUE_LIST: {
      const downloads = state.downloads.map(rec => {
        if (rec.eventId === action.payload?.eventId) {
          if (action.payload?.status === "success") {
            let queue = JSON.parse(localStorage.getItem("downloadQueue")) || [];
            if (queue.includes(action.payload?.eventId)) {
              // Remove the eventId before download is triggered
              queue = queue.filter(id => id !== action.payload?.eventId);
              localStorage.setItem("downloadQueue", JSON.stringify(queue));

              // Auto start Download
              fileDownload(
                action.payload?.downloadFile,
                `${rec.entryName.trim().replace(/[^a-zA-Z0-9 ]/g, "")}.${rec.fileType}`
              );
            }

            if (action?.payload?.thumb) {
              let storedThumbQueue = JSON.parse(localStorage.getItem("downloadQueueThumb")) || [];
              getImgDimensionFromUrl(action.payload.thumb).then(res => {
                if (res.width > 0 && res.height > 0) {
                  storedThumbQueue = storedThumbQueue.filter(dQT => dQT.eventId !== action.payload?.eventId);
                  localStorage.setItem("downloadQueueThumb", JSON.stringify(storedThumbQueue));
                }
              });
            }

            return { ...rec, ...action.payload, status: "Click to Download", read: false, type: STREAMING_SUCCESS };
          }
          if (action.payload?.status === "failed") {
            return { ...rec, ...action.payload, status: "Download Failed", read: false, type: STREAMING_FAILED };
          }
        }
        return rec;
      });

      return { ...state, downloads };
    }

    case QUEUE_FETCH_SUCCESS: {
      const allStatus = {
        success: "Click to Download",
        "in-progress": "Download In Progress",
        failed: "Download Failed",
      };
      const downloads = action.result.map(rec => ({
        ...rec,
        status: allStatus[rec.status] || rec.status,
        read: rec.status === "in-progress" ? true : rec.read,
      }));

      return _.isEqual(state.downloads, downloads) ? state : { ...state, downloads };
    }

    case QUEUE_READ_SUCCESS:
    case READ_QUEUE_SYNC: {
      const downloads = state.downloads.map(rec => ({
        ...rec,
        read:
          action?.eventId?.includes(rec.eventId) || action?.payload?.eventId?.includes(rec.eventId) ? true : rec.read,
      }));
      return { ...state, downloads };
    }

    default:
      return state;
  }
};
