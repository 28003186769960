import { useContext } from "react";
import { ROTATE, TEXT, TEXT_FRAME } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import { getCssTransformObj, getWidgetAndParentsDomReference, widgetQuery } from "../_helpers/utils";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import useCollaborativeSelector from "./useCollaborativeSelector";
import useGroupUngroup from "./useGroupUngroup";
import useSnaptoGrid from "./useSnaptoGrid";
import useWidgetHandler from "./useWidgetHandler";
import useFindHighlighter from "./useFindHighlighter";

const useRotatable = () => {
  const { widgets, updateWidgets, dimension } = useContext(EditorContext);
  const updateCollaborativeWidgetSelectionStyle = useCollaborativeSelector();
  const updateFindHighlighter = useFindHighlighter();

  const {
    toggleEventHandlers: toggleWidgetEventHandlers,
    toggleTooltip: toggleWidgetEventTooltip,
    updateEventCursors: updateWidgetEventCursors,
    toggleEventCursorToDefault: toggleWidgetEventCursorToDefault,
  } = useWidgetHandler();

  const { group: initWidgetGroup, ungroup: initWidgetUngroup } = useGroupUngroup();

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const { showSnappedGridLines, hideGrideLines } = useSnaptoGrid();

  const rotatable = {
    meta: false,

    updateReactDom: ({ client }) => {
      const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(false, client.current.transform, false, client.id);

      // bugfix ~ In edit mode, after rotation STOP, old innerHTML is showing for text & text frame
      const updatedInnerHtml = [TEXT, TEXT_FRAME].includes(client.assetType)
        ? { innerHTML: document.getElementById(client.id).innerHTML }
        : {};

      // bugfix ~ In edit mode, after rotation STOP, old height is showing for text
      const updatedStyle =
        client.assetType === TEXT
          ? { height: document.getElementById(client.id)?.querySelector(".dhp-widget-inner")?.offsetHeight }
          : {};

      updateWidgets(
        widgets.map((widget, idx) =>
          idx === client.idx
            ? {
                ...widget,
                style: {
                  ...widget.style,
                  ...updatedStyle,
                  transform: client.current.transform,
                },
                data: {
                  ...widget.data,
                  "data-x-allignment": x_al,
                  "data-y-allignment": y_al,
                  "data-value": client.current.theta,
                },
                ...updatedInnerHtml,
              }
            : widget
        )
      );
    },

    start: (data, e) => {
      const { isSingleWidget, isMultiWidget, data: domRef } = widgetQuery(data.widgetId);

      // isSingleSelected => for single and group widgets that are selected single
      // isGroupSelected => for single and group widgets that are selected multiple

      let widgetIdx = false;
      let widgetQueryData = domRef;

      if (isSingleWidget) {
        widgetIdx = widgets.findIndex(w => w.id === widgetQueryData.widget.id);
      }

      if (isMultiWidget) {
        const runtimeGroupId = initWidgetGroup({ isRuntimeOperation: true });
        widgetQueryData = getWidgetAndParentsDomReference(runtimeGroupId);
      }

      const {
        widget: {
          id: widgetId,
          node: widgetNode,
          css: widgetStyle,
          data: widgetData,
          rect: widgetRect,
          isLocked: isWidgetLocked,
          isGroupWidget,
        },
      } = widgetQueryData;

      if (isWidgetLocked) {
        return;
      }

      let props = {
        isRotating: true,
        isFirstEvent: true,
        isMultiOperation: isMultiWidget,
        client: {
          id: widgetId,
          idx: widgetIdx,
          node: widgetNode,
          assetType: widgetData.assetType,
          initial: {
            center: {
              x: widgetRect.left + widgetRect.width / 2,
              y: widgetRect.top + widgetRect.height / 2,
            },
            transform: widgetStyle.transform,
          },
          current: {
            transform: false,
            theta: false,
          },
        },
        rotator: {
          node: e.target,
        },
        handler: document.querySelector("#dhp-widget-handler"),
      };

      rotatable.meta = props;
      document.addEventListener("mousemove", rotatable.rotate);
      document.addEventListener("mouseup", rotatable.stop);
    },

    rotate: e => {
      let meta = rotatable.meta;
      if (meta?.isRotating) {
        const { clientX: x2, clientY: y2 } = e;
        const { x: x1, y: y1 } = meta.client.initial.center;
        const x = x2 - x1;
        const y = y2 - y1;
        const rotatorAngleInRad = Math.PI / 2; // NOTE:::: bug-fix (for jerk issue on rotation start) | rotator default angle to widget handler is 90° (i.e. Math.PI / 2) so that need to be considered.

        const angleInRad = Math.atan2(y, x) + rotatorAngleInRad;
        const angleInDeg = angleInRad * (180 / Math.PI);
        const theta = Math.floor(angleInDeg < 0 ? 360 + angleInDeg : angleInDeg);

        const widgetTransformStr = getCssTransformObj({
          rotateAngle: theta + "deg",
          transform: meta.client.initial.transform,
          returnStr: true,
        });

        const handlerTransformStr = getCssTransformObj({
          rotateAngle: theta + "deg",
          transform: meta.handler.style.transform,
          returnStr: true,
        });

        meta.client.node.style.transform = widgetTransformStr;
        meta.client.current.transform = widgetTransformStr;
        meta.client.current.theta = theta;
        meta.handler.style.transform = handlerTransformStr;

        if (meta.isFirstEvent) {
          toggleWidgetEventHandlers({ action: "HIDE" });
          meta.isFirstEvent = false;
          localStorage.setItem("widget.event.isActive", "true");
        }
        toggleWidgetEventTooltip({
          action: "SHOW",
          event: ROTATE,
          tooltip: `${theta}<sup>°</sup>`,
          theta: theta,
        });

        updateFindHighlighter();
        updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom });
        updateWidgetEventCursors({ theta: theta });
        toggleWidgetEventCursorToDefault({ action: "SHOW", node: meta.rotator.node });
        showSnappedGridLines(meta.client.node);
      }
    },

    stop: () => {
      let meta = rotatable.meta;
      if (meta?.isRotating) {
        toggleWidgetEventHandlers({ action: "SHOW" });
        toggleWidgetEventTooltip({ action: "HIDE", event: ROTATE });
        toggleWidgetEventCursorToDefault({ action: "HIDE" });

        if (!meta.isFirstEvent && !meta.isMultiOperation) {
          rotatable.updateReactDom({ ...meta });
        }

        if (meta.isMultiOperation) {
          sessionStorage.setItem("retainRotation", true)
          initWidgetUngroup({ isRuntimeOperation: true, groupId: meta.client.id });
        }

        document.removeEventListener("mousemove", rotatable.rotate);
        document.removeEventListener("mouseup", rotatable.stop);
        meta = false;
        hideGrideLines();
        localStorage.setItem("widget.event.isActive", "false");
      }
    },
  };

  return { start: rotatable.start };
};

export default useRotatable;
