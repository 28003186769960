// socket events
export const APP_HEARTBEAT = "dh:heartbeat";
export const JOIN_COMPANY_ROOM = "dh:company:join";
export const SESSION_TERMINATE = "dh:auth:session:terminate";
export const SESSION_TERMINATE_AFTER = "dh:auth:session:terminate:after";

export const THIRDPARTY_TOKEN_STATUS = "dh:thirdParty:token:status";
export const THIRDPARTY_UPLOAD_START = "dh:thirdParty:upload";
export const THIRDPARTY_UPLOAD_FAILED = "dh:thirdParty:upload:failed";
export const THIRDPARTY_UPLOAD_PROGRESS = "dh:thirdParty:upload:progress";
export const THIRDPARTY_UPLOAD_SUCCESS = "dh:thirdParty:upload:success";

export const PUBLISH_DOCUMENT = "dh:company:editor:publish";
export const PUBLISH_DOCUMENT_AFTER = "dh:company:editor:publish:after";
export const PUBLISH_DOCUMENT_DOWNLOAD = "dh:company:editor:publish:download";
export const PUBLISH_DOCUMENT_DOWNLOAD_AFTER = "dh:company:editor:publish:download:after";
export const PUBLISH_DOCUMENT_PASSWORD = "dh:company:editor:publish:password";
export const PUBLISH_DOCUMENT_PASSWORD_AFTER = "dh:company:editor:publish:password:after";
export const UNPUBLISH_DOCUMENT = "dh:company:editor:unpublish";
export const UNPUBLISH_DOCUMENT_AFTER = "dh:company:editor:unpublish:after";

export const COMPANY_SWITCH = "dh:auth:company:switch";
export const COMPANY_SWITCH_AFTER = "dh:auth:company:switch:after";
export const COMPANY_DELETE = "dh:auth:company:delete";
export const COMPANY_DELETE_AFTER = "dh:auth:company:delete:after";

export const USER_DELETE = "dh:auth:user:delete";
export const USER_DELETE_AFTER = "dh:auth:user:delete:after";
export const USER_DEACTIVATE = "dh:auth:user:deactivate";
export const USER_DEACTIVATE_AFTER = "dh:auth:user:deactivate:after";

export const STREAMING_START = "dh:streaming:start";
export const STREAMING_PROGRESS = "dh:streaming:progress";
export const STREAMING_FAILED = "dh:streaming:failed";
export const STREAMING_SUCCESS = "dh:streaming:success";
export const STREAMING_COMPLETE = "dh:streaming:complete"

export const DOCUMENT_SAVE = "dh:company:editor:save";
export const DOCUMENT_SAVE_AFTER = "dh:company:editor:save:after";

export const GENERATE_DOCUMENT_SCREENSHOT = "dh:company:editor:screenshot";
export const GENERATE_DOCUMENT_SCREENSHOT_AFTER = "dh:company:editor:screenshot:after";

export const DOCUMENT_SAVE_PATH = "/document-save";

export const CONN_DOC_SAVE = "document-save";
export const CONN_DEFAULT = "default";

export const JOIN_COLLABORATION_ROOM = "dh:company:editor:collaboration:joining";
export const EXIT_COLLABORATION_ROOM = "dh:company:editor:collaboration:exit";
export const COLLABORATION_STATUS = "dh:company:editor:collaboration:status";
export const COLLABORATION_SEND = "dh:company:editor:collaboration";
export const COLLABORATION_RECEIVE = "dh:company:editor:collaboration:after";
export const ACTIVE_WIDGET_CHANGE = "dh:company:editor:collaboration:activeWidgetChange";

export const USER_INFO_UPDATE = "dh:user:info:update";
export const USER_INFO_UPDATE_AFTER = "dh:user:info:update:after";

export const EXTERNAL_STREAMING_START = "dh:external:streaming:start";
export const EXTERNAL_STREAMING_PROGRESS = "dh:external:streaming:progress";
export const EXTERNAL_STREAMING_FAILED = "dh:external:streaming:failed";
export const EXTERNAL_STREAMING_SUCCESS = "dh:external:streaming:success";

export const CUSTOM_FONTS_UPDATE = "dh:resources:uploaded:fonts:update";
export const CUSTOM_FONTS_UPDATE_AFTER  = "dh:resources:uploaded:fonts:update:after";

export const DOCUMENT_VERSION_RESTORE = "dh:document:version:restore";
export const DOCUMENT_VERSION_RESTORE_AFTER = "dh:document:version:restore:after";

export const SYNC_QUEUE = "dh:sync:queue";
export const SYNC_QUEUE_AFTER = "dh:sync:queue:after";
export const SYNC_READ_QUEUE = "dh:sync:queue:read";
export const SYNC_READ_QUEUE_AFTER = "dh:sync:queue:read:after";

export const PUBLISH_TEMPLATE_STREAMING_START = "dh:publish:template:streaming:start";
export const PUBLISH_TEMPLATE_STREAMING_PROGRESS = "dh:publish:template:streaming:progress";
export const PUBLISH_TEMPLATE_STREAMING_FAILED = "dh:publish:template:streaming:failed";
export const PUBLISH_TEMPLATE_STREAMING_SUCCESS = "dh:publish:template:streaming:success";