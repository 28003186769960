import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import _ from "lodash";

import EditorPanel from "./EditorPanel";
import VideoTimeline from "./VideoTimeline";
import EditorZoomNavigationPannel from "./EditorZoomNavigationPannel";
import { EditorContext } from "./EditorLayout";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../../constants/editor";
import { Icon } from "../../components/ui/icon";
import global from "../../scss/dhp.scss";
import parserLoaderSvg from "../../assets/images/dh-loader.svg";
import useFitToScreen from "../../hooks/useFitToScreen";

let style = Object.assign({}, global);

export const EditorWrapperContext = React.createContext({});

const EditorWrapper = props => {
  const { documentType, isDocumentReady, isTimeLineViewOpen, setIsTimeLineViewOpen, audios, metadata, updateMetadata } =
    useContext(EditorContext);
  const userInfo = useSelector(state => state?.auth?.user);
  const documentDetails = useSelector(state => state?.document?.documentDetails?.data);

  const isDocumentParsable = !(!documentDetails?.version?.rawdata || documentDetails?.version?.rawdata?.contexts);

  const pagePlayObj = {
    action: null,
    pageId: null,
    pageIdx: null,
    pageTimer: null,
    pageProgress: 0,
    pageDurationMs: 0,
  };

  const documentPlayObj = {
    action: null,
    pageId: null,
    pageIdx: null,
    documentTimer: null,
    documentProgress: 0,
    documentDurationMs: 0,
    pageArray: [],
  };

  const findAndReplaceEventData = {
    isActive: false,
    origin: false,
    curState: false,
  };

  const { fitOnScreenMethod } = useFitToScreen();


  const [whichPagePlaying, setWhichPagePlaying] = useState({});
  const [pagePlayData, setPagePlayData] = useState(pagePlayObj);
  const [documentPlayData, setDocumentPlayData] = useState(documentPlayObj);
  const [playingPageIdx, setPlayingPageIdx] = useState(null);
  const [ignoreNextScrollEvent, setIgnoreNextScrollEvent] = useState(false);
  const [socketState, setSocketState] = useState({ activeUsers: {} });
  const [changeBySocket, setChangeBySocket] = useState(false);
  const [showFindAndReplace, setShowFindAndReplace] = useState(findAndReplaceEventData);

  useEffect(() => {
    document.querySelectorAll(".disable-while-video-editor-playing")?.forEach(element => {
      if (playingPageIdx !== null) element.classList.add("disable");
      else element.classList.remove("disable");
    });
  }, [playingPageIdx]);

  return (
    <EditorWrapperContext.Provider
      value={{
        whichPagePlaying,
        setWhichPagePlaying: data => {
          if (!_.isEqual(whichPagePlaying, data)) setWhichPagePlaying(data);
        },
        pagePlayData,
        setPagePlayData: data => {
          if (!_.isEqual(pagePlayData, data)) setPagePlayData(data);
        },
        documentPlayData,
        setDocumentPlayData: data => {
          if (!_.isEqual(documentPlayData, data)) setDocumentPlayData(data);
        },
        playingPageIdx,
        setPlayingPageIdx: data => {
          if (playingPageIdx !== data) setPlayingPageIdx(data);
        },
        ignoreNextScrollEvent,
        updateScrollEvent: data => {
          if (ignoreNextScrollEvent !== data) setIgnoreNextScrollEvent(data);
        },
        socketState,
        updateSocketState: data => {
          if (!_.isEqual(socketState, data)) setSocketState(data);
        },
        changeBySocket,
        setChangeBySocket: data => {
          if (changeBySocket !== data) setChangeBySocket(data);
        },
        showFindAndReplace,
        setShowFindAndReplace: data => {
          if (showFindAndReplace !== data) setShowFindAndReplace(data);
        },
      }}>
      <div
        className={cx(style["dhp-contentArea"], style["clearfix"], style["overflow-hidden"], {
          [style["open-timeline-wrap"]]: isTimeLineViewOpen,
          [style["hide-timeline-wrap"]]: !isTimeLineViewOpen,
          [style["pe-none"]]: !isDocumentReady,
          [style["small-document-time-line"]]:
            (audios?.length === 0 || !audios) && isTimeLineViewOpen === "document-timeline",
          [style["video-enable"]]: userInfo.video_enabled && ![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType),
        })}>
        <div className={style["editor-wrapper"]}>
          <EditorPanel toggle={props.toggle}  />

          <EditorZoomNavigationPannel  />

          {audios?.length > 0 && !isTimeLineViewOpen && (
            <div
              className={cx(style["editor-audio"], style["rounded"], {
                [style["disable"]]: playingPageIdx !== null,
              })}
              onClick={() => {
                updateMetadata({
                  ...metadata,
                  activeWidgetId: false,
                  activeWidgetType: false,
                });
                setIsTimeLineViewOpen("document-timeline");
                document.querySelector(".canvas-controller").style.display = "none";
                if (document.getElementById("active-page-selctor"))
                  document.getElementById("active-page-selctor").style.display = "none";

                fitOnScreenMethod();

                setTimeout(() => {
                  updateMetadata({
                    ...metadata,
                    activeWidgetId: false,
                    activeWidgetType: false,
                    pageController: {
                      ...metadata.pageController,
                      style: { ...metadata.pageController.style, top: 0 + "px" },
                    },
                  });
                  document.querySelector(".canvas-controller").style.display = "block";
                  if (document.getElementById("active-page-selctor"))
                    document.getElementById("active-page-selctor").style.display = "block";
                }, 500);
              }}>
              <div className={style["action"]}>
                <Icon icon="ui-audio"></Icon>
                <span className={style["filter-count"]}>{audios?.length}</span>
              </div>
            </div>
          )}

          {/* Display parsing time loader */}
          {!isDocumentReady && isDocumentParsable && (
            <div className={style["parser-loader"]}>
              <img src={parserLoaderSvg} />
            </div>
          )}
        </div>

        {![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && userInfo.video_enabled && (
          <VideoTimeline  toggle={props.toggle} />
        )}
      </div>
    </EditorWrapperContext.Provider>
  );
};

export default EditorWrapper;
