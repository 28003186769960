import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { Icon } from "../ui/icon";
import { alignSvg, debounceTrailing, displayAbstractShapes, formatTime, getTemplateInfo } from "../../_helpers/utils";
import CanvasPageHtmlGenaretor from "../Editor/CanvasPageHtmlGenaretor";

import global from "../../scss/dhp.scss";
import useFullScreen from "../../hooks/useFullScreen";
import useHTMLPlayer from "../../hooks/useHTMLPlayer";
import { useContextualUpgrade } from "../../hooks/useContextualUpgrade";
import { useDocumentCreatePermission } from "../../hooks/useDocumentCreatePermission";
import { useCheckCompanyPlanInfo } from "../../hooks/useCheckCompanyPlanInfo";
import { HTML_PLAYER_PADDING, HTML_PLAYER_PADDING_FULL_SCREEN } from "../../constants/previewPresent";

let style = Object.assign({}, global, Icon);

const HTMLPlayer = ({ docPagesMeta, dimension, origin, type, ...props }) => {
  const [fullScreen, toggleFullScreen] = useFullScreen(false, false, false, false, "play");
  const playerContent = useRef(null);
  const [adjustment, setAdjustment] = useState(HTML_PLAYER_PADDING);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [showControlls, setShowControlls] = useState(true);
  const [showVolumeRange, setShowVolumeRange] = useState(false);
  const [volume, setVolume] = useState(1);
  const [showClose, setShowClose] = useState(false);
  const history = useHistory();

  // useContextualUpgrade Hook to show update modal
  const showUpgrade = useContextualUpgrade();

  // Custom hook to check document create permission
  const [checkToDocumentCreate, isCreate] = useDocumentCreatePermission();

  // check is this company is paid or not
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);

  const {
    togglePlayPause,
    documentPlayData,
    isPlaying,
    getDocumentData,
    handleSeek,
    handleArrowKeys,
    handleMouseDown,
    handleMouseUpOrLeave,
    controllVolume,
  } = useHTMLPlayer({ docPagesMeta });

  const resizePreview = () => {
    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight - (origin === "preview" && !fullScreen ? 70 : 0);
    let docWidth = parseInt(dimension.width) + adjustment;
    let docHeight = parseInt(dimension.height) + adjustment;

    let scaleVal =
      adjustment > 0
        ? Math.min(screenWidth / docWidth, screenHeight / docHeight).toFixed(3)
        : Math.ceil(Math.min(screenWidth / docWidth, screenHeight / docHeight) * 100) / 100;
    if (playerContent.current) {
      playerContent.current.style.transform = `scale(${scaleVal}) translateX(0)`;
      playerContent.current.style.willChange = "transform"; // resolved background line issue in present
      playerContent.current.classList.remove("d-none");
      document.querySelectorAll(".dhp-widgets").forEach(wz => {
        wz.style.backfaceVisibility = "";
        wz.querySelectorAll("img").forEach(img => img.setAttribute("draggable", false));
      });
      let playerWrapper = document.getElementById("pagePlay");
      if (playerWrapper) {
        playerWrapper.style.height = `${dimension.height * scaleVal}px`;
        playerWrapper.style.width = `${dimension.width * scaleVal}px`;
      }
    }
  };
  const handlePlayToggle = debounceTrailing(() => {
    setShowControlls(true);
    togglePlayPause(isPlaying);
  }, 100);

  const handleMuteToggle = debounceTrailing(() => {
    setShowControlls(true);
    document.getElementById("mute").click();
  }, 200);

  const handleFullScreenToggle = debounceTrailing(() => {
    setShowControlls(true);
    toggleFullScreen(fullScreen);
  }, 200);

  const handleVolumeSeek = debounceTrailing(event => {
    setShowControlls(true);
    setShowVolumeRange(true);
    let newVolume;
    if (event.key === "ArrowUp") {
      newVolume = parseFloat((parseFloat(volume) + 0.1).toFixed(1));
      setVolume((newVolume >= 1 ? 1 : newVolume).toString());
      controllVolume((newVolume >= 1 ? 1 : newVolume).toString());
    }
    if (event.key === "ArrowDown") {
      newVolume = parseFloat((parseFloat(volume) - 0.1).toFixed(1));
      setVolume((newVolume <= 0 ? 0 : newVolume).toString());
      controllVolume((newVolume <= 0 ? 0 : newVolume).toString());
    }
  }, 200);

  const handleKeyUp = event => {
    if (["ArrowRight", "ArrowLeft"].includes(event.key)) {
      // Call the function on key release
      handleMouseUpOrLeave();
    }
  };

  const handleArrowSeek = debounceTrailing(event => {
    setShowControlls(true);
    handleArrowKeys(event, documentPlayData.documentProgress);
  }, 100);

  const handleKeyDown = event => {
    switch (event.key) {
      case "ArrowLeft":
      case "ArrowRight":
        handleArrowSeek(event);
        break;
      case "ArrowUp":
      case "ArrowDown":
        handleVolumeSeek(event);
        break;
      case "f":
        handleFullScreenToggle();
        break;
      case "m":
        handleMuteToggle();
        break;
      case " ":
        handlePlayToggle();
        break;
      default:
        break;
    }
  };

  const handleClick = event => {
    const excludedClasses = [".center-play-btn", "#documentProgress", ".custom-tooltip", ".fullscreen"];
    if (event.target.closest(".present-page") && !excludedClasses.some(cls => event.target.closest(cls)))
      handlePlayToggle();
  };

  const handleMouseEnter = () => {
    setShowControlls(true);
  };
  const handleMouseLeave = () => {
    if (isPlaying) setShowControlls(false);
  };

  const onExit = () => {
    if (fullScreen) toggleFullScreen(fullScreen);
    if (window.history.length > 1) {
      window.history.back();
      return false;
    } else window.close();
  };

  const dynamicRouting = () => {
    if (type === "documents") {
      if (fullScreen) toggleFullScreen(fullScreen);
      if (isPlaying) togglePlayPause();
      let recordId = props?.recordDetails?.data?.id;
      history.push(`/editor/${recordId}`);
    }

    if (type === "templates" && paidCompanyInfo?.active_user_count) {
      const croppedThumb = props?.recordDetails?.data?.thumb?.replace("/screenshot.png", "/screenshot-t.png") ?? "";
      if (
        props?.recordDetails?.data?.premium &&
        paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN &&
        !paidCompanyInfo?.isPaid
      ) {
        // Call showUpgrade function to show contexttual upgrade modal based on your condition
        showUpgrade("premiumTemplate", croppedThumb);
      } else {
        checkToDocumentCreate();
      }
    }

    if (type === "company-templates") {
      history.push({
        pathname: "/documents/create",
        state: getTemplateInfo({
          ...props?.recordDetails?.data,
          thumb: props?.recordDetails?.data?.thumb?.replace("/screenshot-t.png", "/screenshot-c.png") ?? "",
          ref: "company-template",
        }),
      });
    }
  };

  useEffect(() => {
    if (!dimension || !docPagesMeta) return;
    resizePreview();
    alignSvg();
    displayAbstractShapes();
    window.addEventListener("resize", resizePreview);
    return () => {
      window.removeEventListener("resize", resizePreview);
      document.getElementsByTagName("body")[0].style.userSelect = "";
    };
  }, [adjustment, dimension, docPagesMeta]);

  useEffect(() => {
    setIsPlayerReady(true);
  }, [docPagesMeta]);

  useEffect(() => {
    if (fullScreen) {
      setAdjustment(HTML_PLAYER_PADDING_FULL_SCREEN);
      if (origin === "preview") {
        document.querySelector(".preview-header").classList.add("d-none");
        document.querySelector(".preview-content").style.height = "100vh";
      }
    } else {
      setAdjustment(HTML_PLAYER_PADDING);
      if (origin === "preview") {
        document.querySelector(".preview-header").classList.remove("d-none");
        document.querySelector(".preview-content").style.height = "";
      }
    }
  }, [fullScreen]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("click", handleClick);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("click", handleClick);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [togglePlayPause]);

  useEffect(() => {
    if (!isPlaying) setShowControlls(true);
  }, [isPlaying]);

  useEffect(() => {
    if ((origin === "preview" && fullScreen) || ["present", "play"].includes(origin)) {
      setShowClose(true);
    }
    if (origin === "preview" && !fullScreen) {
      setShowClose(false);
    }
  }, [origin, fullScreen]);

  // Redirect to next action if document create permission ok
  useEffect(() => {
    if (isCreate) {
      if (fullScreen) toggleFullScreen(fullScreen);
      if (isPlaying) togglePlayPause();
      history.push({
        pathname: "/documents/create",
        state: getTemplateInfo({
          ...props?.recordDetails?.data,
          thumb: props?.recordDetails?.data?.thumb?.replace("/screenshot.png", "/screenshot-c.png") ?? "",
        }),
      });
    }
  }, [isCreate]);

  return (
    <>
      <div
        className={cx(style["present-content-wrapper"], style["video-plyer-wrapper"])}
        style={{
          backgroundColor: fullScreen ? "#000000" : "#f2f2f2",
          height: origin === "preview" && !fullScreen ? "calc(100vh - 70px)" : "",
        }}>
        {!props?.published && (
          <div className={style["topRight-action"]}>
            <ul className={cx(style["d-flex"], style["align-items-center"])}>
              {origin === "preview" && fullScreen && (
                <li>
                  <span
                    className={cx(style["custom-tooltip"], style["rounded"])}
                    onClick={() => dynamicRouting()}
                    style={{ backgroundColor: fullScreen ? "rgb(250 250 250 / 35%)" : "" }}>
                    <Icon
                      icon={type === "documents" ? "ui-pencil" : "ui-cursor"}
                      style={{ color: fullScreen ? "#ffffff" : "" }}
                    />
                    <div className={cx(style["custom-tooltip-content"], style["bottom"], style["mt-5"])}>
                      {type === "documents" ? "Go to Editor" : "Use this Template"}
                    </div>
                  </span>
                </li>
              )}
              {showClose && (
                <li>
                  <span
                    className={cx(style["custom-tooltip"], style["rounded"], style["closed"])}
                    onClick={() => onExit()}
                    style={{ backgroundColor: fullScreen ? "rgb(250 250 250 / 35%)" : "" }}>
                    <Icon icon={`ui-close`} style={{ color: fullScreen ? "#ffffff" : "" }} />
                    <div className={cx(style["custom-tooltip-content"], style["bottom"], style["mt-5"])}>Close</div>
                  </span>
                </li>
              )}
            </ul>
          </div>
        )}

        <div id="pagePlay" className={style["pagePlay"]}>
          <div
            className={cx(style["present-content"], style["d-none"])}
            ref={playerContent}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {docPagesMeta?.pageNodes?.map((pageMeta, i) => (
              <CanvasPageHtmlGenaretor
                figureWidth={parseFloat(dimension?.width)}
                key={`preview-page-${i}`}
                id={`dhp-page-${i}`}
                className={cx(style["present-page"], { [style["d-none"]]: i !== 0 })}
                pageNode={pageMeta}
                docPagesMeta={docPagesMeta}
                isPresent={true}
              />
            ))}
          </div>
          {docPagesMeta?.audios?.map((audio, idx) => (
            <audio id={`player-${audio.id}`} key={idx} src={audio.src} preload="auto" data-audio-id={audio.id}></audio>
          ))}
          {/* Play controller in normal mode */}
          {isPlayerReady && !fullScreen && (
            <Controller
              documentPlayData={documentPlayData}
              handleSeek={handleSeek}
              handleMouseDown={handleMouseDown}
              handleMouseUpOrLeave={handleMouseUpOrLeave}
              togglePlayPause={togglePlayPause}
              isPlaying={isPlaying}
              getDocumentData={getDocumentData}
              fullScreen={fullScreen}
              toggleFullScreen={toggleFullScreen}
              controllVolume={controllVolume}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              showControlls={showControlls}
              showVolumeRange={showVolumeRange}
              setShowVolumeRange={setShowVolumeRange}
              volume={volume}
              setVolume={setVolume}
            />
          )}
        </div>
      </div>
      {/* Play controller in FullScrren Mode */}
      {isPlayerReady && fullScreen && (
        <Controller
          documentPlayData={documentPlayData}
          handleSeek={handleSeek}
          handleMouseDown={handleMouseDown}
          handleMouseUpOrLeave={handleMouseUpOrLeave}
          togglePlayPause={togglePlayPause}
          isPlaying={isPlaying}
          getDocumentData={getDocumentData}
          fullScreen={fullScreen}
          toggleFullScreen={toggleFullScreen}
          controllVolume={controllVolume}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          showControlls={showControlls}
          showVolumeRange={showVolumeRange}
          setShowVolumeRange={setShowVolumeRange}
          volume={volume}
          setVolume={setVolume}
        />
      )}
    </>
  );
};

export default HTMLPlayer;

HTMLPlayer.propTypes = {
  published: PropTypes.bool,
  setShowPublishedPresent: PropTypes.func,
  isEmbed: PropTypes.bool,
};

const Controller = ({
  documentPlayData,
  handleSeek,
  handleMouseDown,
  handleMouseUpOrLeave,
  togglePlayPause,
  isPlaying,
  getDocumentData,
  fullScreen,
  toggleFullScreen,
  controllVolume,
  handleMouseEnter,
  handleMouseLeave,
  showControlls,
  showVolumeRange,
  setShowVolumeRange,
  volume,
  setVolume,
}) => {
  const [isMuted, setIsMuted] = useState(false);
  const volumeRef = useRef(volume);

  const handleVolumeChange = e => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    controllVolume(newVolume);
    setIsMuted(newVolume === "0");
    volumeRef.current = newVolume;
  };

  const toggleMute = () => {
    setVolume(isMuted ? volumeRef.current : 0);
    setIsMuted(!isMuted);
    controllVolume(isMuted ? volumeRef.current : 0);
  };

  useEffect(() => {
    if (volume) {
      if (!isMuted) volumeRef.current = volume;
      setIsMuted(volume === "0" || volume <= 0);
    }
  }, [volume]);

  useEffect(()=> {
    // keep muted in auto play
    setIsMuted(true);
  },[])

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ opacity: showControlls ? 1 : 0 }}>
      <div className={style["center-play-btn"]} onClick={() => togglePlayPause(isPlaying)}>
        <Icon icon={`ui-${isPlaying ? "pause" : "play"}`} />
      </div>
      <div className={cx(style["plyer-controls"], style["white-range"], style["d-flex"], style["flex-column"])}>
        <input
          type="range"
          min="0"
          max="100"
          step={"any"}
          value={documentPlayData?.documentProgress}
          onClick={e => (handleMouseDown(), handleSeek(e))}
          onChange={e => handleSeek(e)}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUpOrLeave}
          onMouseLeave={handleMouseUpOrLeave}
          style={{ transition: "none" }}
          id="documentProgress"
        />
        <div
          className={cx(
            style["d-flex"],
            style["player-info"],
            style["my-2"],
            style["align-items-center"],
            style["justify-content-between"]
          )}
          onMouseLeave={() => setShowVolumeRange(false)}>
          <ul className={cx(style["d-flex"], style["player-info-left"])}>
            <li>
              <span
                className={cx(style["custom-tooltip"], style["rounded"], style["m-0"])}
                onClick={() => togglePlayPause(isPlaying)}>
                <Icon icon={`ui-${isPlaying ? "pause" : "play"}`} />
                <div className={cx(style["custom-tooltip-content"], style["top"])}>{isPlaying ? "Pause" : "Play"}</div>
              </span>
            </li>
            <li className={cx(style["pl-3"], style["pr-2"])} onMouseEnter={() => setShowVolumeRange(true)}>
              <span
                className={cx(style["custom-tooltip"], style["rounded"], style["m-0"])}
                onClick={toggleMute}
                id="mute">
                <Icon icon={`ui-${isMuted ? "volume-mute" : "volume"}`} />
                <div className={cx(style["custom-tooltip-content"], style["top"])}>{isMuted ? "Unmute" : "Mute"}</div>
              </span>
            </li>

            {showVolumeRange && (
              <li className={style["mt-1"]}>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step={0.1}
                  value={volume}
                  onChange={handleVolumeChange}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUpOrLeave}
                  onMouseLeave={handleMouseUpOrLeave}
                />
              </li>
            )}
          </ul>

          <ul className={cx(style["player-info-right"], style["d-flex"])}>
            <li className={style["d-flex"]}>
              {formatTime((documentPlayData?.documentTimer / 1000).toFixed(1))} /{" "}
              {formatTime((getDocumentData().documentDurationMs / 1000).toFixed(1))}
            </li>

            <li className={cx(style["custom-tooltip"], style["ml-3"], style["fullscreen"])}>
              <div className={cx(style["link-white"])} onClick={() => toggleFullScreen(fullScreen)}>
                <Icon icon={`${fullScreen ? "contract" : "ui-expand"}`} />
              </div>
              <div className={cx(style["custom-tooltip-content"], style["top"])}>
                {fullScreen ? "Exit Fullscreen" : "Fullscreen"}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
