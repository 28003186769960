import qs from "qs";
import {
  FETCH_TEXT_TO_IMAGE_LIST_START,
  FETCH_TEXT_TO_IMAGE_LIST_SUCCESS,
  FETCH_TEXT_TO_IMAGE_LIST_FAILED,
  FETCH_TEXT_TO_IMAGE_STYLE_START,
  FETCH_TEXT_TO_IMAGE_STYLE_SUCCESS,
  FETCH_TEXT_TO_IMAGE_STYLE_FAILED,
  CREATE_TEXT_TO_IMAGE_COLLECTION_START,
  CREATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS,
  CREATE_TEXT_TO_IMAGE_COLLECTION_FAILED,
  FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_START,
  FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_SUCCESS,
  FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_FAILED,
  UPDATE_TEXT_TO_IMAGE_COLLECTION_START,
  UPDATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS,
  UPDATE_TEXT_TO_IMAGE_COLLECTION_FAILED,
  DELETE_TEXT_TO_IMAGE_COLLECTION_START,
  DELETE_TEXT_TO_IMAGE_COLLECTION_SUCCESS,
  DELETE_TEXT_TO_IMAGE_COLLECTION_FAILED,
  CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_START,
  CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS,
  CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED,
  UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_START,
  UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS,
  UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED,
  FETCH_AI_WRITER_TONE_START,
  FETCH_AI_WRITER_TONE_SUCCESS,
  FETCH_AI_WRITER_TONE_FAILED,
  CREATE_AI_WRITER_TEXT_COMPLETION_START,
  CREATE_AI_WRITER_TEXT_COMPLETION_SUCCESS,
  CREATE_AI_WRITER_TEXT_COMPLETION_FAILED,
  UPDATE_AI_WRITER_TEXT_COMPLETION_START,
  UPDATE_AI_WRITER_TEXT_COMPLETION_SUCCESS,
  UPDATE_AI_WRITER_TEXT_COMPLETION_FAILED,
  FETCH_AI_WRITER_TEXT_COMPLETION_LIST_START,
  FETCH_AI_WRITER_TEXT_COMPLETION_LIST_SUCCESS,
  FETCH_AI_WRITER_TEXT_COMPLETION_LIST_FAILED,
  FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_START,
  FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_SUCCESS,
  FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_FAILED,
  DELETE_AI_WRITER_TEXT_COMPLETION_START,
  DELETE_AI_WRITER_TEXT_COMPLETION_SUCCESS,
  DELETE_AI_WRITER_TEXT_COMPLETION_FAILED,
  FETCH_AI_WRITER_EXTEND_START,
  FETCH_AI_WRITER_EXTEND_SUCCESS,
  FETCH_AI_WRITER_EXTEND_FAILED,
  AI_TRANSLATE_START,
  AI_TRANSLATE_SUCCESS,
  AI_TRANSLATE_FAILED,
  FETCH_AI_TRANSLATE_USAGE_START,
  FETCH_AI_TRANSLATE_USAGE_SUCCESS,
  FETCH_AI_TRANSLATE_USAGE_FAILED,
  FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_START,
  FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_SUCCESS,
  FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_FAILED
} from "./actionTypes";
import { aiRequestManager as API } from "../../_helpers/requestManager";

const generatePayload = (type, params) => {
  if (type === "text2img") {
    const { promt, samples, type, category, quality } = params;
    const maxSize = quality === "SD" ? "512" : "1024";
    const payload = { promt, samples, width: maxSize, height: maxSize };
    if (type) payload.type = type;
    if (category) payload.category = category;
    return payload;
  }

  if (type === "aiwriter") {
    const { promt, tone } = params;
    const payload = { promt, tone, top_p: 1, temperature: 1 };
    return payload;
  }
};

const fetchTextToImageListStart = () => {
  return { type: FETCH_TEXT_TO_IMAGE_LIST_START };
};
const fetchTextToImageListSuccess = result => {
  return { type: FETCH_TEXT_TO_IMAGE_LIST_SUCCESS, result };
};
const fetchTextToImageListFailed = error => {
  return { type: FETCH_TEXT_TO_IMAGE_LIST_FAILED, error };
};

const fetchTextToImageStyleStart = () => {
  return { type: FETCH_TEXT_TO_IMAGE_STYLE_START };
};
const fetchTextToImageStyleSuccess = result => {
  return { type: FETCH_TEXT_TO_IMAGE_STYLE_SUCCESS, result };
};
const fetchTextToImageStyleFailed = error => {
  return { type: FETCH_TEXT_TO_IMAGE_STYLE_FAILED, error };
};

const createTextToImageCollectionStart = () => {
  return { type: CREATE_TEXT_TO_IMAGE_COLLECTION_START };
};
const createTextToImageCollectionSuccess = result => {
  return { type: CREATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS, result };
};
const createTextToImageCollectionFailed = error => {
  return { type: CREATE_TEXT_TO_IMAGE_COLLECTION_FAILED, error };
};

const fetchTextToImageCollectionDetailStart = () => {
  return { type: FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_START };
};
const fetchTextToImageCollectionDetailSuccess = result => {
  return { type: FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_SUCCESS, result };
};
const fetchTextToImageCollectionDetailFailed = error => {
  return { type: FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_FAILED, error };
};

const updateTextToImageCollectionStart = () => {
  return { type: UPDATE_TEXT_TO_IMAGE_COLLECTION_START };
};
const updateTextToImageCollectionSuccess = result => {
  return { type: UPDATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS, result };
};
const updateTextToImageCollectionFailed = error => {
  return { type: UPDATE_TEXT_TO_IMAGE_COLLECTION_FAILED, error };
};

const deleteTextToImageCollectionStart = () => {
  return { type: DELETE_TEXT_TO_IMAGE_COLLECTION_START };
};
const deleteTextToImageCollectionSuccess = result => {
  return { type: DELETE_TEXT_TO_IMAGE_COLLECTION_SUCCESS, result };
};
const deleteTextToImageCollectionFailed = error => {
  return { type: DELETE_TEXT_TO_IMAGE_COLLECTION_FAILED, error };
};

const createTextToImageEtaCollectionStart = () => {
  return { type: CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_START };
};
const createTextToImageEtaCollectionSuccess = result => {
  return { type: CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS, result };
};
const createTextToImageEtaCollectionFailed = error => {
  return { type: CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED, error };
};

const updateTextToImageEtaCollectionStart = () => {
  return { type: UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_START };
};
const updateTextToImageEtaCollectionSuccess = result => {
  return { type: UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS, result };
};
const updateTextToImageEtaCollectionFailed = error => {
  return { type: UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED, error };
};

const fetchAiWriterToneStart = () => {
  return { type: FETCH_AI_WRITER_TONE_START };
};
const fetchAiWriterToneSuccess = result => {
  return { type: FETCH_AI_WRITER_TONE_SUCCESS, result };
};
const fetchAiWriterToneFailed = error => {
  return { type: FETCH_AI_WRITER_TONE_FAILED, error };
};

const createAiWriterTextCompletionStart = () => {
  return { type: CREATE_AI_WRITER_TEXT_COMPLETION_START };
};
const createAiWriterTextCompletionSuccess = result => {
  return { type: CREATE_AI_WRITER_TEXT_COMPLETION_SUCCESS, result };
};
const createAiWriterTextCompletionFailed = error => {
  return { type: CREATE_AI_WRITER_TEXT_COMPLETION_FAILED, error };
};

const updateAiWriterTextCompletionStart = () => {
  return { type: UPDATE_AI_WRITER_TEXT_COMPLETION_START };
};
const updateAiWriterTextCompletionSuccess = result => {
  return { type: UPDATE_AI_WRITER_TEXT_COMPLETION_SUCCESS, result };
};
const updateAiWriterTextCompletionFailed = error => {
  return { type: UPDATE_AI_WRITER_TEXT_COMPLETION_FAILED, error };
};

const fetchAiWriterTextCompletionListStart = () => {
  return { type: FETCH_AI_WRITER_TEXT_COMPLETION_LIST_START };
};
const fetchAiWriterTextCompletionListSuccess = result => {
  return { type: FETCH_AI_WRITER_TEXT_COMPLETION_LIST_SUCCESS, result };
};
const fetchAiWriterTextCompletionListFailed = error => {
  return { type: FETCH_AI_WRITER_TEXT_COMPLETION_LIST_FAILED, error };
};

const fetchAiWriterTextCompletionDetailsStart = () => {
  return { type: FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_START };
};
const fetchAiWriterTextCompletionDetailsSuccess = result => {
  return { type: FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_SUCCESS, result };
};
const fetchAiWriterTextCompletionDetailsFailed = error => {
  return { type: FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_FAILED, error };
};

const deleteAiWriterTextCompletionStart = () => {
  return { type: DELETE_AI_WRITER_TEXT_COMPLETION_START };
};
const deleteAiWriterTextCompletionSuccess = result => {
  return { type: DELETE_AI_WRITER_TEXT_COMPLETION_SUCCESS, result };
};
const deleteAiWriterTextCompletionFailed = error => {
  return { type: DELETE_AI_WRITER_TEXT_COMPLETION_FAILED, error };
};

const fetchAiWriterExtendStart = () => {
  return { type: FETCH_AI_WRITER_EXTEND_START };
};
const fetchAiWriterExtendSuccess = result => {
  return { type: FETCH_AI_WRITER_EXTEND_SUCCESS, result };
};
const fetchAiWriterExtendFailed = error => {
  return { type: FETCH_AI_WRITER_EXTEND_FAILED, error };
};

const fetchAiTranslateSupportedLanguagesStart = () => {
  return { type: FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_START };
};
const fetchAiTranslateSupportedLanguagesSuccess = result => {
  return { type: FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_SUCCESS, result };
};
const fetchAiTranslateSupportedLanguagesFailed = error => {
  return { type: FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_FAILED, error };
};

const fetchAiTranslateUsageStart = () => {
  return { type: FETCH_AI_TRANSLATE_USAGE_START };
};
const fetchAiTranslateUsageSuccess = result => {
  return { type: FETCH_AI_TRANSLATE_USAGE_SUCCESS, result };
};
const fetchAiTranslateUsageFailed = error => {
  return { type: FETCH_AI_TRANSLATE_USAGE_FAILED, error };
};

const aiTranslateStart = () => {
  return { type: AI_TRANSLATE_START };
};
const aiTranslateSuccess = result => {
  return { type: AI_TRANSLATE_SUCCESS, result };
};
const aiTranslateFailed = error => {
  return { type: AI_TRANSLATE_FAILED, error };
};

export const fetchTextToImageList = ({ page, limit, keyword }) => {
  const payload = { page, limit };
  if (keyword !== "") payload.keyword = keyword;

  return dispatch => {
    // dispatch(fetchTextToImageListStart());

    return API.get(`/text2img?${qs.stringify(payload)}`)
      // .then(r => dispatch(fetchTextToImageListSuccess(r.data)))
      // .catch(e => dispatch(fetchTextToImageListFailed(e)));
  };
};

export const fetchTextToImageStyle = () => {
  return dispatch => {
    // dispatch(fetchTextToImageStyleStart());

    return API.get(`/style?module=text2image`)
      // .then(r => dispatch(fetchTextToImageStyleSuccess(r.data.data)))
      // .catch(e => dispatch(fetchTextToImageStyleFailed(e)));
  };
};

export const createTextToImageCollection = params => {
  const payload = generatePayload("text2img", params);

  return dispatch => {
    // dispatch(createTextToImageCollectionStart());

    return API.post(`/text2img`, payload)
      // .then(r => dispatch(createTextToImageCollectionSuccess(r.data.data)))
      // .catch(e => dispatch(createTextToImageCollectionFailed(e)));
  };
};

export const fetchTextToImageCollectionDetail = entry_id => {
  return dispatch => {
    // dispatch(fetchTextToImageCollectionDetailStart());

    return API.get(`/text2img/${entry_id}`)
      // .then(r => dispatch(fetchTextToImageCollectionDetailSuccess(r.data.data)))
      // .catch(e => dispatch(fetchTextToImageCollectionDetailFailed(e)));
  };
};

export const updateTextToImageCollection = (entry_id, params) => {
  const payload = generatePayload("text2img", params);

  return dispatch => {
    // dispatch(updateTextToImageCollectionStart());

    return API.put(`/text2img/${entry_id}`, payload)
      // .then(r => dispatch(updateTextToImageCollectionSuccess(r.data.data)))
      // .catch(e => dispatch(updateTextToImageCollectionFailed(e)));
  };
};

export const deleteTextToImageCollection = entry_id => {
  return dispatch => {
    // dispatch(deleteTextToImageCollectionStart());

    return API.delete(`/text2img/${entry_id}`)
      // .then(r => dispatch(deleteTextToImageCollectionSuccess(r.data.data)))
      // .catch(e => dispatch(deleteTextToImageCollectionFailed(e)));
  };
};

export const createTextToImageEtaCollection = payload => {
  return dispatch => {
    // dispatch(createTextToImageEtaCollectionStart());

    return API.post(`/text2img/fetch`, payload)
      // .then(r => dispatch(createTextToImageEtaCollectionSuccess(r.data.data)))
      // .catch(e => dispatch(createTextToImageEtaCollectionFailed(e)));
  };
};

export const updateTextToImageEtaCollection = (entry_id, payload) => {
  return dispatch => {
    // dispatch(updateTextToImageEtaCollectionStart());

    return API.put(`/text2img/${entry_id}/fetch`, payload)
      // .then(r => dispatch(updateTextToImageEtaCollectionSuccess(r.data.data)))
      // .catch(e => dispatch(updateTextToImageEtaCollectionFailed(e)));
  };
};

export const fetchAiWriterTone = () => {
  return dispatch => {
    // dispatch(fetchAiWriterToneStart());

    return API.get(`/style?module=aiwriter`)
      // .then(r => dispatch(fetchAiWriterToneSuccess(r.data)))
      // .catch(e => dispatch(fetchAiWriterToneFailed(e)));
  };
};

export const createAiWriterTextCompletion = params => {
  const payload = generatePayload("aiwriter", params);

  return dispatch => {
    // dispatch(createAiWriterTextCompletionStart());

    return API.post(`/aiwriter`, payload)
      // .then(r => dispatch(createAiWriterTextCompletionSuccess(r.data.data)))
      // .catch(e => dispatch(createAiWriterTextCompletionFailed(e)));
  };
};

export const updateAiWriterTextCompletion = (entry_id, params) => {
  const payload = generatePayload("aiwriter", params);

  return dispatch => {
    // dispatch(updateAiWriterTextCompletionStart());

    return API.put(`/aiwriter/${entry_id}`, payload)
      // .then(r => dispatch(updateAiWriterTextCompletionSuccess(r.data.data)))
      // .catch(e => dispatch(updateAiWriterTextCompletionFailed(e)));
  };
};

export const fetchAiWriterTextCompletionList = ({ page, limit, keyword }) => {
  const payload = { page, limit };
  if (keyword !== "") payload.keyword = keyword;

  return dispatch => {
    // dispatch(fetchAiWriterTextCompletionListStart());

    return API.get(`/aiwriter?${qs.stringify(payload)}`)
      // .then(r => dispatch(fetchAiWriterTextCompletionListSuccess(r.data)))
      // .catch(e => dispatch(fetchAiWriterTextCompletionListFailed(e)));
  };
};

export const fetchAiWriterTextCompletionDetails = entry_id => {
  return dispatch => {
    // dispatch(fetchAiWriterTextCompletionDetailsStart());

    return API.get(`/aiwriter/${entry_id}`)
      // .then(r => dispatch(fetchAiWriterTextCompletionDetailsSuccess(r.data.data)))
      // .catch(e => dispatch(fetchAiWriterTextCompletionDetailsFailed(e)));
  };
};

export const deleteAiWriterTextCompletion = entry_id => {
  return dispatch => {
    // dispatch(deleteAiWriterTextCompletionStart());

    return API.delete(`/aiwriter/${entry_id}`)
      // .then(r => dispatch(deleteAiWriterTextCompletionSuccess(r.data.data)))
      // .catch(e => dispatch(deleteAiWriterTextCompletionFailed(e)));
  };
};

export const fetchAiWriterExtend = params => {
  const payload = generatePayload("aiwriter", params);

  return dispatch => {
    dispatch(fetchAiWriterExtendStart());

    return API.post(`/aiwriter/result/extend`, payload)
      .then(r => dispatch(fetchAiWriterExtendSuccess(r.data)))
      .catch(e => dispatch(fetchAiWriterExtendFailed(e)));
  };
};


export const fetchAiTranslateLanguages = params => {
  return dispatch => {
    // dispatch(fetchAiTranslateSupportedLanguagesStart());

    return API.get(`/translate/languages`, params)
      // .then(r => dispatch(fetchAiTranslateSupportedLanguagesSuccess(r.data)))
      // .catch(e => dispatch(fetchAiTranslateSupportedLanguagesFailed(e)));
  };
};

export const fetchAiTranslateUsage = params => {
  return dispatch => {
    dispatch(fetchAiTranslateUsageStart());

    return API.get(`/translate`, params)
      .then(r => dispatch(fetchAiTranslateUsageSuccess(r.data)))
      .catch(e => dispatch(fetchAiTranslateUsageFailed(e)));
  };
};

export const aiTranslate = params => {
  return dispatch => {
    dispatch(aiTranslateStart());

    return API.post(`/translate`, params)
      .then(r => dispatch(aiTranslateSuccess(r.data)))
      .catch(e => dispatch(aiTranslateFailed(e)));
  };
};
