import React, { useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useCloneWidget from "../../../hooks/useCloneWidget";
import { EditorPanelContext } from "../../../containers/editor/EditorPanel";

let style = Object.assign({}, global);

const Clone = props => {
  let { metadata, rightContextMenu, updateRightContextMenu } = useContext(EditorContext);
  let { keyBoardEvent, updateKeyBoardEvent } = useContext(EditorPanelContext);

  const { cloneWidget, cloneAudio } = useCloneWidget();

  const cloneMethod = () => {
    // clone widget
    if (metadata.activeWidgetId) {
      updateKeyBoardEvent({ ...keyBoardEvent, clone: true });
      cloneWidget();
    }
    // clone audio
    else if (metadata.activeAudioId) {
      cloneAudio();
    }

    if (props.rightContext)
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
  };

  return (
    <>
      {metadata.activeWidgetId.length > 0 && (
        <li
          className={cx({
            [style["custom-tooltip"]]: !props.rightContext,
          })}>
          <span className={style["toolset-action"]} onClick={cloneMethod}>
            <Icon icon="ui-copy" />
            {props.rightContext && (
              <>
                Clone <span>Ctrl+D</span>
              </>
            )}
          </span>
          {!props.rightContext && <div className={style["custom-tooltip-content"]}>Clone</div>}
        </li>
      )}

      {metadata.activeAudioId && (
        <li className={cx(style["toolset-group"])}>
          <div className={cx(style["toolset-item"], style["clone-wrap"])} id="clone-wrap">
            <span
              className={cx(style["toolset-action"], {
                [style["custom-tooltip"]]: !props.rightContext,
              })}
              onClick={cloneMethod}>
              <Icon icon="ui-copy" />

              {props.rightContext && (
                <>
                  Clone <span>Ctrl+D</span>
                </>
              )}

              {!props.rightContext && <div className={cx(style["custom-tooltip-content"], style["top"])}>Clone</div>}
            </span>
          </div>
        </li>
      )}
    </>
  );
};

Clone.propTypes = {
  rightContext: PropTypes.bool,
};

export default Clone;
