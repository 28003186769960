import React from "react";

import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const EditorMapInfoTooltip = () => {

  return (
    <div id ="editor-map-tooltip">
      <strong className={style["d-block"]} id ="areaTitle"></strong>
      <ul id="caption"></ul>
    </div>
  );
};

export default EditorMapInfoTooltip;
