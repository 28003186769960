import React from "react";
import cx from "classnames";

import {
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input as RInput,
  Form,
  UncontrolledDropdown,
} from "reactstrap";

import { Icon } from "../../ui/icon";

import global from "../../../scss/dhp.scss";

let style = Object.assign({}, global);

const RoleHierarchy = ({ role, addNew, actionButton = true }) => {
  const editRole = () => {
    // Edit Role
    console.log("Edit Role");
  };
  const deleteRole = () => {
    // Delete Role
    console.log("Delete Role");
  };
  return (
    <>
      {role && (
        <div className={style["tree-view-wrap"]}>
          <div
            className={cx(style["d-flex"], style["item-container"], {
              [style["remove-border"]]: role?.name === "Manager",
            })}>
            <div
              className={cx(style["d-flex"], style["item-info"], {
                [style["manager-item-info"]]: role?.name === "Manager",
              })}>
              {/* <Icon icon="ui-roles" additionalclass="mr-2" /> */}
              <div className={cx(style["custom-control"], style["custom-radio"])}>
                <RInput
                  type="radio"
                  // id={`stockPhotoFilterOrientation${i}`}
                  name="stockPhotoFilterOrientation"
                  className={style["custom-control-input"]}
                  // defaultChecked={orientation === props.filters.orientation}
                  // value={orientation}
                  // onChange={() => updatePayload("orientation", orientation)}
                />
                <label className={style["custom-control-label"]}>
                  
                </label>
              </div>
              <h6>{role?.name}</h6>

              {actionButton && (
                <div className={cx(style["ml-3"], style["float-action"])}>
                  <div
                    className={style["cursor-pointer"]}
                    onClick={() => addNew(role.id, { id: Date.now(), name: "New Role" })}>
                    <Icon icon="ui-plus" additionalclass="mr-2" />
                    New
                  </div>
                  <div className={style["cursor-pointer"]} onClick={editRole}>
                    <Icon icon="ui-edit" additionalclass="mr-2" />
                    Edit
                  </div>
                  {role?.name !== "Manager" && (
                    <div className={style["cursor-pointer"]} onClick={deleteRole}>
                      <Icon icon="ui-trash" additionalclass="mr-2" />
                      Delete
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {role?.subordinates && role?.subordinates?.length > 0 && (
            <ul>
              {role?.subordinates?.map(subordinate => (
                <li key={subordinate.id}>
                  <RoleHierarchy role={subordinate} addNew={addNew} actionButton={actionButton} />
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default RoleHierarchy;
