import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cx from "classnames";
import { v4 as uuid4 } from 'uuid';
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap";

import { Input } from "../../ui/input";
import { useDispatch, useSelector } from "react-redux";
import { TEMPLATE_TYPE_PRESENTATION } from "../../../constants/pageSizeModal";
import { fetchDocumentDetails } from "../../../store/actions/documentActions";
import { useSocket } from "../../../hooks/useSocket";
import Progressbar from "../../ui/progressbar";
import { ALLOWED_DIMENSION, DPI_RATIO, SUPPORT_EMAIL } from "../../../constants/env";
import { Icon } from "../../ui/icon";
import { CONN_DOC_SAVE, DOCUMENT_SAVE, DOCUMENT_SAVE_AFTER, STREAMING_START, SYNC_QUEUE } from "../../../constants/socket";

import global from "../../../scss/dhp.scss";
import Sparkle from "../../../assets/images/sparkle.svg";
import Exclamation from "../../../assets/images/exclamation-mark.svg";
import Crown from "../../../assets/images/ui-crown.svg";
import { useContextualUpgrade } from "../../../hooks/useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../../constants/company";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import CanvasPageHtmlGenaretor from "../../Editor/CanvasPageHtmlGenaretor";
import useDocumentParser from "../../../hooks/useDocumentParser";
import { addToQueue } from "../../../store/actions/downloadQueueActions";
import { getCompanyInfo } from "../../../store/actions/companyActions";
import { sendMail } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const DownloadModal = ({ document, _helpers, setDocumentParsing }) => {
  let { pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType } = useContext(EditorContext);

  const dispatch = useDispatch();
  const webSocket = useSocket();
  const eventId = uuid4();

  const form = {
    type: {
      name: "File Type",
      value: {
        name: "PNG",
        value: "png",
      },
      options: [
        {
          name: "JPG",
          value: "jpg",
          details: "Small file size",
          premium: false,
          download_quality_available: true,
        },
        {
          name: "PNG",
          value: "png",
          details: "Ideal for web design",
          premium: false,
          download_quality_available: true,
        },
        {
          name: "PNG Transparent",
          value: "png_transparent",
          details: "No background",
          premium: true,
          download_quality_available: true,
        },
        {
          name: "PDF",
          value: "pdf",
          details: "Ideal for printing",
          premium: true,
          download_quality_available: true,
        },
        {
          name: "MP4",
          value: "mp4",
          details: "High quality video",
          premium: true,
          download_quality_available: false,
        },
        {
          name: "GIF",
          value: "gif",
          details: "Ideal for animation",
          premium: true,
          download_quality_available: false,
        },
      ],
    },
    quality: {
      name: "Quality",
      value: {
        name: "Normal",
        value: "normal",
      },
      options: [
        {
          name: "Normal",
          value: "normal",
          details: "For standard screen",
          premium: false,
        },
        {
          name: "Medium",
          value: "medium",
          details: "For retina screen",
          premium: true,
        },
        {
          name: "High",
          value: "high",
          details: "For print",
          premium: true,
        },
      ],
      disabled: false,
    },
  };

  // useContextualUpgrade Hook to show update modal
  const showUpgrade = useContextualUpgrade();

  // check is this company is paid or not
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);

  const { start: initDocumentParser } = useDocumentParser();

  // Socket Connection for save document
  const saveDocSocket = useSocket(CONN_DOC_SAVE);

  const [formData, setFormData] = useState(form);
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [downloadError, setDownloadError] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);
  const [selectedPages, setSelectedPages] = useState([1]);
  const [isInNewVersion, setIsInNewVersion] = useState(true);
  const [initDownloadNewVersion, setInitDownloadNewVersion] = useState();
  const [disableQuality, setDisableQuality] = useState();
  const [selectionPreferences, setSelectionPreferences] = useState(false);
  const [disableDownloadGIF, setDisableDownloadGIF] = useState(false);

  const availableTemplateGroups = useSelector(state => state?.template?.templateGroups);

  const updateForm = (option, field) => {
    if (option.premium && paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
      // Call showUpgrade function to show contexttual upgrade modal based on your condition
      showUpgrade("downloadQuality");
      if (_helpers?.modal?.toggle) _helpers.modal.toggle();
      return;
    }
    let updatedForm = { ...formData };
    updatedForm[field].value = {
      name: option.name,
      value: option.value,
    };
    if (field === "type") updatedForm.quality.disabled = !option.download_quality_available;
    setFormData(updatedForm);
  };

  const documentDetails = useSelector(state => state?.document?.documentDetails);
  const { companyId, userId, region } = useSelector(state => {
    const auth = state?.auth || {};
    return {
      companyId: auth?.user?.company?.id || null,
      userId: auth?.uid || null,
      region: state?.auth?.company?.cluster_code
        ? state.auth.company.cluster_code.toLowerCase()
        : null,
    };
  });

  const type = documentDetails?.data?.template_info?.type?.toLowerCase();

  const pages = pageNodes ? pageNodes?.map((page, index) => {
    return { id: index + 1, page };
  }) : documentDetails?.data?.version?.rawdata?.contexts?.pageNodes ?
    documentDetails?.data?.version?.rawdata?.contexts?.pageNodes?.map((page, index) => {
      return { id: index + 1, page };
    }) : documentDetails?.data?.version?.rawdata?.html?.map((page, index) => {
      return { id: index + 1, html: page };
    });

  const docPagesMeta = (pageNodes) ? { blockNodes, pageNodes, backgroundColors, backgroundImages, widgets, dimension, documentType } : { ...documentDetails?.data?.version?.rawdata?.contexts };

  const multiSelectPlaceholder = `Select ${type === TEMPLATE_TYPE_PRESENTATION ? "Slides" : "Pages"}`;
  const multiSelectLabel = type === TEMPLATE_TYPE_PRESENTATION ? "Slides" : "Pages";

  const quality = !formData.quality.disabled ? formData.quality.value.value : "";
  const fileType = formData.type.value.value === "png_transparent" ? "png" : formData.type.value.value;
  const transparent = formData.type.value.value === "png_transparent";

  const actionType = fileType === "pdf" ? "pdf" : ["png", "jpg"].includes(fileType) ? "image" : "media";

  const download = () => {
    if (!pageNodes && documentDetails?.data?.id === document?.id && !documentDetails?.data?.version?.rawdata?.contexts) {
      setIsInNewVersion(false);
      setDocumentParsing(true);
      prepareDataForSaveDocument().then(data => {
        saveDocument(data);
      })
    }
    else {
      if (webSocket?.readyState === 1 && isInNewVersion) {
        initDownload();
      }
    };
  }

  const getDocumentName = () => {
    const isEditorPage = window.location.pathname.includes("/editor/");
    
    if (isEditorPage) {
      const docElement = window.document.getElementById("document-name");
      return docElement ? docElement.innerText.trim() : documentDetails?.data?.document_name;
    }
  
    return documentDetails?.data?.document_name;
  };

  const initDownload = () => {
    const subscribe = {
      type: STREAMING_START,
      companyId,
      entryId: document?.id,
      quality,
      transparent,
      fileType,
      favourites: selectedPages,
      thumbIndex: (selectionPreferences[0] ?? 1) - 1,
      fileName: getDocumentName(),
      actionType,
      eventId,
      region,
      userId
    };
    webSocket.send(JSON.stringify(subscribe));
    addQueue();
  }

  const prepareDataForSaveDocument = async () => {
    // setDownloadProgress(true);
    const contexts = await initDocumentParser({ data: documentDetails?.data })
    if (contexts?.pageNodes && availableTemplateGroups) {
      let templateGroupId = documentDetails?.data?.template_info?.group_id;
      const templateType = documentDetails?.data?.template_info?.type?.toLowerCase();
      contexts.documentType = templateType;
      availableTemplateGroups.template_groups.map(availableTemplateGroup => {
        if (availableTemplateGroup.id === templateGroupId)
          contexts.templateGroupName = availableTemplateGroup.slug;
      })
      contexts.pageMargin = contexts.margin;
      delete contexts.margin;
      delete contexts.version;
      const metaData = JSON.parse(JSON.stringify(documentDetails?.data?.version?.rawdata?.metadata));
      metaData.version = process.env.APP_VERSION;
      metaData.metaInfo = {
        template_type_id: documentDetails?.data?.template_info?.type_id,
        template_type: templateType,
      };
      // setDownloadProgress(10);
      return {
        metadata: metaData, contexts
      }
    }
  }

  const saveDocument = (data) => {
    if (data && saveDocSocket.readyState === 1) {
      const subscribe = {
        type: DOCUMENT_SAVE,
        companyId: companyId,
        entryId: document?.id,
        rawdata: JSON.stringify(data),
      };
      saveDocSocket.send(JSON.stringify(subscribe));
    }
  }

  const checkDimensions = (pageWidth, pageHeight) => {
    const disallowedDPI = [];

    for (const [dpiName, ratio] of Object.entries(DPI_RATIO)) {
      if (dpiName === 'normal') {
        continue; // Skip the normal DPI check
      }
      const scaledWidth = pageWidth * ratio;
      const scaledHeight = pageHeight * ratio;

      if (scaledWidth > ALLOWED_DIMENSION.width || scaledHeight > ALLOWED_DIMENSION.height) {
        disallowedDPI.push(dpiName);
      }
    }

    return disallowedDPI;
  }

  const onMessageDocSave = useCallback(message => {
    const response = JSON.parse(message?.data);
    if (response?.document_id === document?.id)
      switch (response.type) {
        case DOCUMENT_SAVE_AFTER:
          // setDownloadProgress(20);
          setDocumentParsing(false);
          setInitDownloadNewVersion(true);
          break;

        default:
          break;
      }
  }, []);

  useEffect(() => {
    if (saveDocSocket?.readyState === 1) saveDocSocket.addEventListener("message", onMessageDocSave);

    return () => {
      saveDocSocket?.removeEventListener("message", onMessageDocSave)
    };

  }, [webSocket?.readyState, saveDocSocket?.readyState]);

  useEffect(() => {
    if (!documentDetails || documentDetails?.data?.id !== document?.id) dispatch(fetchDocumentDetails(document?.id));
  }, [document]);


  useEffect(() => {
    if (initDownloadNewVersion) initDownload();
  }, [initDownloadNewVersion])

  useEffect(() => {
    if (docPagesMeta?.dimension?.height && docPagesMeta?.dimension?.width) {
      setDisableQuality(checkDimensions(parseFloat(docPagesMeta.dimension.width), parseFloat(docPagesMeta.dimension.height)))
    }
  }, [docPagesMeta?.dimension])

  // fetch region if not available
  useEffect(() => {
    if (!region) dispatch(getCompanyInfo(companyId));
  }, [region])

  // generate preffered page contex
  const getThumbContext = index => {
    if (pageNodes || docPagesMeta?.pageNodes) {
      let activeBlock = docPagesMeta.blockNodes[index];
      if (["proposal", "infographic"].includes(documentType)) {
        return docPagesMeta;
      }
      return {
        ...docPagesMeta,
        blockNodes: [activeBlock],
        pageNodes: docPagesMeta.pageNodes?.filter(rec => rec.pageIdx === activeBlock.blockIdx),
        backgroundColors: docPagesMeta.backgroundColors.filter(rec => rec.blockId === activeBlock.blockId),
        backgroundImages: docPagesMeta.backgroundImages.filter(rec => rec.blockId === activeBlock.blockId),
        widgets: docPagesMeta.widgets.filter(rec => rec.blockId === activeBlock.blockId),
      };
    }
  };

  // add eventId to localStorage
  const addToLocalStorage = (eventId) => {
    let queue = JSON.parse(localStorage.getItem("downloadQueue")) || [];
    if (!queue.includes(eventId)) {
      queue.push(eventId);
      localStorage.setItem("downloadQueue", JSON.stringify(queue));
    }
  };

  // Add to Queue
  const addQueue = () => {
    addToLocalStorage(eventId);
    let payload = {
      type: STREAMING_START,
      eventId,
      entryName: documentDetails?.data?.document_name,
      createdOn: parseInt(Date.now() / 1000),
      status: "Download In Progress",
      read: true,
      fileType,
      transparent
    };

    if (pageNodes || docPagesMeta?.pageNodes)
     { // set HTML thumb for react js document
      let thumbContext =  getThumbContext((selectionPreferences[0] ?? 1) - 1);
      payload.thumbContext = thumbContext;
      
      // Keep ThumbContext in localStorage
      let docThumbMeta = {thumbContext, eventId, createdOn: parseInt(Date.now() / 1000)};
      let downloadQueueThumb = JSON.parse(localStorage.getItem("downloadQueueThumb")) || [];
      downloadQueueThumb.push(docThumbMeta);
      localStorage.setItem("downloadQueueThumb", JSON.stringify(downloadQueueThumb));
    }
    else payload.thumb = `${documentDetails?.data?.image.tiny}?hash=${Date.now()}`; // set available thumb for angular js document

    // add rec to redux
    dispatch(addToQueue(payload));

    // SYNC_QUEUE  to other tabs/ windows //
    const subscribe = {
      ...payload,
      type: SYNC_QUEUE,
      companyId,
      userId,
    };
    webSocket.send(JSON.stringify(subscribe));

    window.document.getElementById("notification-list").click();
    if (_helpers?.modal?.toggle) _helpers.modal.toggle();
  }

  useEffect(() => {
    if (selectedPages?.length > 0 && pages?.length > 0) {
      const selectedPagesDuration = pages.reduce(
        (total, pn, index) =>
          selectedPages.includes(index + 1) ? total + parseFloat(pn.page?.pageDuration ?? 0) : total,
        0
      );

      setDisableDownloadGIF(selectedPagesDuration > 30 || selectedPages.length > 5);
    }
  }, [selectedPages, fileType]);

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Download</h4>
      <Link to="#" className={cx(style["cross-modal"], style["rounded"], { [style["disable"]]: !isInNewVersion && downloadProgress < 20 })} onClick={_helpers?.modal?.toggle}>
        <Icon icon="ui-close" />
      </Link>
      <div
        className={cx(style["download-select"], style["download-steps"], {
          [style["d-none"]]: downloadError || downloadProgress,
        })}>
        <div className={style["left"]}>
          {pages && <>
            <div className={style["drop-list"]}>
              <div className={style["form-group"]}>
                <h6 className={cx(style["fw-7"])}>File Type</h6>
                <UncontrolledDropdown>
                  <DropdownToggle caret tag="a">
                    <span className={cx(style["selected-item"], style["mr-1"])}>{formData.type.value.name}</span>
                    <Icon icon="ui-arrow-down" />
                  </DropdownToggle>
                  <DropdownMenu tag="div" className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                    <div className={cx(style["customScroll"], style["scroll-Y"])}>
                      {formData.type.options.map(option => (
                        <div className={style["position-relative"]} key={option.value}>
                        <DropdownItem
                          tag="a"
                          className={cx({ [style["current-item"]]: option.value === formData.type.value.value }, {[style["disable"]]: option.value === "gif" && disableDownloadGIF})}
                          onClick={() => updateForm(option, "type")}>
                          <div className={cx(style["align-items-center"], style["d-flex"])}>
                            <span className={cx(style["mr-2"], style["font-15"])}>{option.name}</span>
                            {option.premium && <img alt="premium" src={Crown} className={cx(style["img-fluid"])} />}
                            
                          </div>
                          <span className={cx(style["text-muted"], style["font-14"])}>
                            {option.details}
                          </span>
                        </DropdownItem>
                        {option.value === "gif" && (
                          <div
                            className={cx(style["custom-tooltip"], style["gif-tooltip"])} id="gif-tooltip">
                            <Icon icon={"ui-info"} additionalclass="font-14" />
                            <UncontrolledTooltip
                              placement="bottom"
                              target="gif-tooltip"
                              trigger="hover"
                              boundariesElement={window.document.getElementById("app")}>
                              GIF must not exceed 30 <br/>
                              seconds or 5 pages
                            </UncontrolledTooltip>
                          </div>
                        )}
                        </div>
                      ))}
                    </div>
                  </DropdownMenu>

                </UncontrolledDropdown>
              </div>

              {pages?.length > 1 && (
                <div className={cx(style["form-group"], style["mb-0"])}>
                  {pages?.length !== 1 && <h6 className={cx(style["fw-7"])}>{multiSelectLabel}</h6>}
                  <Input
                    cssModule={style}
                    returnType="formGroup"
                    type="multi_select_page"
                    placeholder={multiSelectPlaceholder}
                    options={pages}
                    optionValue={"id"}
                    optionImage={"image"}
                    updateState={e => setSelectedPages(e)}
                    state={selectedPages}
                    maxLimit={pages?.length + 1}
                    isContextData={!!pages[0]?.page}
                    docPagesMeta={docPagesMeta}
                    selectionPreferences={selectionPreferences}
                    setSelectionPreferences={setSelectionPreferences}
                  />
                </div>
              )}

              <div className={cx(style["form-group"], { [style["disabled"]]: formData.quality.disabled })}>
                <h6 className={cx(style["fw-7"])}>Quality</h6>
                <UncontrolledDropdown disabled={formData.quality.disabled}>
                  <DropdownToggle caret tag="a">
                    <span className={cx(style["selected-item"], style["mr-1"])}>{formData.quality.value.name}</span>
                    <Icon icon="ui-arrow-down" />
                  </DropdownToggle>
                  <DropdownMenu tag="div" className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                    {formData.quality.options.map(option => (
                      <DropdownItem
                        tag="a"
                        key={option.value}
                        className={cx({ [style["current-item"]]: option.value === formData.quality.value.value }, { [style["disable"]]: disableQuality?.includes(option.value) })}
                        onClick={() => updateForm(option, "quality")}>
                        <div className={cx(style["align-items-center"], style["d-flex"])}>
                          <span className={cx(style["mr-2"])}>{option.name}</span>
                          {option.premium && <img alt="premium" src={Crown} className={cx(style["img-fluid"])} />}
                        </div>
                        <span className={cx(style["text-muted"])}>
                          <small>{option.details}</small>
                        </span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
            <Button
              type="submit"
              color="primary"
              cssModule={style}
              disabled={selectedPages.length <= 0 || (fileType === "gif" && disableDownloadGIF)}
              onClick={download}>
              Download
            </Button>
          </>}
          {!pages &&
            <div className={style["drop-list"]}>
              <div className={cx(style["form-group"], style["skeleton-loader-area"], style["align-items-start"], style["flex-column"])}>
                <div className={cx(style["loader-item"], style["w-50"], style["mb-2"])} style={{ borderRadius: "0.25rem" }}></div>
                <div className={cx(style["loader-item"], style["w-100"])} style={{ height: `44px`, borderRadius: "0.25rem" }}></div>
              </div>
              <div className={cx(style["form-group"], style["skeleton-loader-area"], style["align-items-start"], style["flex-column"])}>
                <div className={cx(style["loader-item"], style["w-50"], style["mb-2"])} style={{ borderRadius: "0.25rem" }}></div>
                <div className={cx(style["loader-item"], style["w-100"])} style={{ height: `44px`, borderRadius: "0.25rem" }}></div>
              </div>
            </div>
          }
        </div>
        <div className={style["right"]}>
          <span className={style["download-thumb"]}>
            {docPagesMeta?.pageNodes && (
              <CanvasPageHtmlGenaretor
                figureWidth={210}
                pageNode={docPagesMeta.pageNodes[(selectionPreferences[0] ?? 1) - 1]}
                docPagesMeta = {docPagesMeta}
              />
            )}
            {/* In masonry grid view Angular JS Documents*/}
            {!docPagesMeta?.pageNodes && !pageNodes && <img alt={document.document_name} src={document.compiled_thumb} className={style["img-fluid"]} />}
          </span>
        </div>
      </div>

      <div
        className={cx(style["download-link"], style["download-steps"], style["text-center"], {
          [style["d-none"]]: downloadError || !downloadLink,
        })}>
        <img src={Sparkle} width="70" alt="" />
        <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}>Your document is ready for download!</h6>
        <p className={style["mb-0"]}>
          Your download should have started automatically. If not <br />
          <a href={downloadLink ? downloadLink : undefined} >download here</a>
        </p>
      </div>

      <div
        className={cx(style["download-error"], style["download-steps"], style["text-center"], {
          [style["d-none"]]: !downloadError,
        })}>
        <img src={Exclamation} width="70" alt="" />
        <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}> We could not download your document!</h6>
        <p className={style["mb-0"]}>
          DocHipo experienced a technical issue. Please try <br />
          after some time. If the issue continues, please <br />
          write to us at{" "}
          <Link to="" onClick={sendMail}>
            {SUPPORT_EMAIL}
          </Link>
        </p>
      </div>

      <div
        className={cx(style["download-loader"], style["download-steps"], style["text-center"], {
          [style["d-none"]]: !downloadProgress || downloadError || downloadLink,
        })}>
        <div className={cx(style["progress"], style["download-progressbar"])}>
          <Progressbar progressbarInfo={downloadProgress} />
        </div>
        <h6 className={cx(style["fw-7"], style["pb-1"], style["mt-4"])}>Please Wait</h6>
        <p className={style["mb-0"]}> Preparing your document for download</p>
      </div>
    </React.Fragment>
  );
};

DownloadModal.propTypes = {
  document: PropTypes.object.isRequired,
  _helpers: PropTypes.object.isRequired,
  setDocumentParsing: PropTypes.func
};

export default DownloadModal;
