import { useEffect, useLayoutEffect, useState } from "react";
import {
  customRound,
  floatToFloatSec,
  getHMSToSec,
  operateStorageData,
  playMedia,
  stringTocamelCase,
} from "../_helpers/utils";
import {
  BRAND,
  COLLAGE,
  DOCUMENT_PLAY_DATA_STORAGE_KEY,
  PAGE_PLAY_DATA_STORAGE_KEY,
  STOCK,
  UPLOAD_VIDEO,
  VIDEO,
} from "../constants/editor";
import { FADE_EFFECT_OBJ, FADE_INTERVEL_OBJ } from "../constants/previewPresent";

const useHTMLPlayer = ({ docPagesMeta }) => {
  let intervalId = false;
  let audioIntervalId = false;
  let animationSpeedToDurationMsObj = {
    Fast: 500,
    Medium: 1000,
    Slow: 2000,
  };
  const documentPlayObj = {
    action: null,
    pageId: null,
    pageIdx: null,
    documentTimer: null,
    documentProgress: 0,
    documentDurationMs: 0,
    pageArray: [],
  };
  const pagePlayObj = {
    action: null,
    pageId: null,
    pageIdx: null,
    pageTimer: null,
    pageProgress: 0,
    pageDurationMs: 0,
  };
  const [pageAction, setPageAction] = useState(null);
  const [documentPlayData, setDocumentPlayData] = useState(documentPlayObj);
  const [nextPageToPlay, setNextPageToPlay] = useState({
    pageId: null,
    pageIdx: null,
    nextPageObj: null,
  });
  const [pagePlayData, setPagePlayData] = useState(pagePlayObj);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playerVolume, setPlayerVolume] = useState(100);

  const playerHelper = {
    // prepare Data
    getDocumentData: () => {
      let pageArray = [];
      let documentDurationMs = 0;

      docPagesMeta?.pageNodes.forEach((pageObj, pageIdx) => {
        const blockObj = docPagesMeta?.blockNodes[pageIdx];
        const pageDurationMs = parseFloat(pageObj.pageDuration) * 1000;
        const pageStartTimeMs = documentDurationMs;
        const pageStopTimeMs = pageStartTimeMs + pageDurationMs;
        const prevPageIdx = docPagesMeta?.pageNodes?.[pageIdx - 1]?.pageIdx;
        const nextPageIdx = docPagesMeta?.pageNodes?.[pageIdx + 1]?.pageIdx;

        documentDurationMs += pageDurationMs;
        pageArray = [
          ...pageArray,
          {
            pageId: pageObj.pageId,
            pageIdx: pageIdx,
            blockId: blockObj.blockId,
            blockIdx: pageIdx,
            pageDurationMs,
            pageStartTimeMs,
            pageStopTimeMs,
            prevPageIdx: prevPageIdx ?? null,
            nextPageIdx: nextPageIdx ?? null,
          },
        ];
      });

      return {
        documentDurationMs,
        firstPageObj: pageArray[0],
        pageArray,
      };
    },

    getPageData: () => {
      let storage_data;
      if (!pagePlayData.pageId) {
        storage_data = operateStorageData({
          type: "session",
          action: "get",
          key: PAGE_PLAY_DATA_STORAGE_KEY,
          isParseable: true,
          defaultData: {},
        });
      }
      const pageId = pagePlayData.pageId ?? storage_data.pageId;
      const pageIdx = parseInt(pagePlayData.pageIdx ?? storage_data.pageIdx);
      const pageObj = docPagesMeta?.pageNodes[pageIdx];
      const pageDurationMs = parseFloat(pageObj?.pageDuration ?? 0) * 1000;

      return { pageId, pageIdx, pageObj, pageDurationMs };
    },

    getWidgetData: ({ widgetObj, pageDurationMs }) => {
      const { startTime = 0, endTime = pageDurationMs } = widgetObj?.duration ?? {};
      const startTimeMs = parseFloat(startTime) * 1000;
      const endTimeMs = parseFloat(endTime) * 1000;
      const stopTimeMs = endTimeMs > pageDurationMs ? pageDurationMs : endTimeMs;
      const widgetNode = document.getElementById(widgetObj.id);
      const assetType = widgetObj.data["data-asset-type"];
      const source = widgetObj.data["data-source"];

      return { startTimeMs, stopTimeMs, widgetNode, assetType, source };
    },

    getVideoNodeAndDataRef: ({ widgetId, widgetNode, videoNode }) => {
      const widget = widgetId ? document.getElementById(widgetId) : widgetNode;
      const assetTypeCollage = widget.dataset.assetType === COLLAGE;
      const video = assetTypeCollage ? videoNode : widget?.querySelector("video");
      const { duration: durationInHMS, volume, trim, loop } = assetTypeCollage ? video?.dataset : widget?.dataset;
      const durationInSec = getHMSToSec({ hms: durationInHMS });
      const trimArray = trim ? trim.split(",")?.map(e => parseFloat(e)) : null;
      const isTrimmed = Boolean(trimArray?.length === 2);
      const trimStart = isTrimmed ? trimArray?.[0] : 0;
      const trimEnd = isTrimmed ? trimArray?.[1] : durationInSec;

      return {
        widget,
        video,
        src: video.src,
        poster: video.poster,
        volume: ((volume ? parseInt(volume) : 100) * playerVolume)/100,
        durationInSec,
        durationInHMS,
        trimStart,
        trimEnd,
        isMuted: Boolean(video.getAttribute("muted")),
        isLooped: Boolean(loop === "true"),
        isTrimmed: Boolean(trimStart > 0 || trimEnd < durationInSec),
      };
    },

    // display only active page
    showSlides: slideNo => {
      let slides = document.getElementsByClassName("present-page");
      [...slides].forEach((element, i) => {
        element.classList.add("d-none");
        element.classList.remove("page-playing");
      });
      slides[slideNo]?.classList.remove("d-none");
      slides[slideNo]?.classList.add("page-playing");
    },

    // Handle Seek
    setPageTransitionCurTime: ({ pageObj, pageDurationMs, timer }) => {
      let wrapperNode;
      let animationNode;
      const pageNode = document.getElementById(`dhp-page-${pageObj.pageIdx}`);
      const blockNode = pageNode.getElementsByTagName("figure")[0];
      const showEntryAnimation = pageObj.pageTransition.enabled;
      const entryAnimationSpeed = pageObj.pageTransition.speed;
      const entryAnimationType = pageObj.pageTransition.type;
      const entryAnimationDurationMs = showEntryAnimation && animationSpeedToDurationMsObj[entryAnimationSpeed];
      const entryAnimationStopTime = showEntryAnimation && entryAnimationDurationMs;
      const entryAnimationClassName = showEntryAnimation && stringTocamelCase(entryAnimationType);

      if (!showEntryAnimation) return;

      if (timer > 0 && timer < pageDurationMs) {
        // when page is within playable time range
        // detecting wrapper/animation node
        if (!blockNode.parentNode.parentNode.classList.contains("wrapper-node")) {
          animationNode = document.createElement("div");
          animationNode.classList.add("animation-node");

          wrapperNode = document.createElement("div");
          wrapperNode.classList.add("wrapper-node");
          wrapperNode.style.overflow = "hidden";
          wrapperNode.style.height = "100%";
          wrapperNode.style.width = "100%";
          wrapperNode.appendChild(animationNode);

          blockNode.replaceWith(wrapperNode);
          wrapperNode.childNodes[0].appendChild(blockNode);
        } else {
          wrapperNode = pageNode.querySelector(".wrapper-node");
          animationNode = pageNode.querySelector(".animation-node");
        }

        // set page transition current time
        // page transition playable range
        if (showEntryAnimation && timer > 0 && timer < entryAnimationStopTime) {
          const currentTimerDurationMs = timer - 0;
          const animationPlayedDurationMs = parseInt(
            animationNode.getAttribute("data-page-transition-played-duration") ?? 0
          );

          animationNode.classList.add("play-animation");
          animationNode.classList.add(entryAnimationClassName);
          animationNode.style.animationDuration = `${entryAnimationDurationMs}ms`;
          animationNode.style.animationPlayState = "paused";
          animationNode.style.animationDelay =
            currentTimerDurationMs > animationPlayedDurationMs
              ? `-${currentTimerDurationMs - animationPlayedDurationMs}ms`
              : `${animationPlayedDurationMs - currentTimerDurationMs}ms`;
        }
        // removing page transition props outside playable range
        else {
          animationNode.classList.remove("play-animation");
          animationNode.classList.remove(entryAnimationClassName);
          animationNode.style.animationDuration = "";
          animationNode.style.animationPlayState = "";
          animationNode.style.animationDelay = "";
          animationNode.removeAttribute("data-page-transition-played-duration");
        }
      } else {
        if (blockNode.parentNode.parentNode.classList.contains("wrapper-node")) {
          // when page is outside playable time range but page transition props were added on last preview
          wrapperNode = pageNode.querySelector(".wrapper-node");

          // removing wrapper/animation node (replacing with original node)
          wrapperNode.replaceWith(blockNode);
        }
      }
    },

    setDomPlayEnvToCustomTime: ({ pageId, pageObj, pageDurationMs, timer, meta }) => {
      const newPagePlayData = {
        pageId,
        pageIdx: meta.pageIdx,
        action: "pause",
      };

      setDocumentPlayData({
        ...documentPlayData,
        action: "pause",
        pageId,
        pageIdx: meta.pageIdx,
        documentProgress: meta.documentProgress,
        documentTimer: meta.documentTimer,
      });

      operateStorageData({
        type: "session",
        action: "set",
        key: DOCUMENT_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });
      setPagePlayData({
        ...pagePlayData,
        action: "pause",
        pageId,
        pageIdx: meta.pageIdx,
        pageTimer: meta.targetPageTimer,
        pageProgress: meta.pageProgress,
      });

      playerHelper.setPageTransitionCurTime({ pageObj, pageDurationMs, timer });

      docPagesMeta?.widgets.forEach(w => {
        if (w.pageId === pageId && !w.data["data-layer-hidden"]) {
          const { startTimeMs, stopTimeMs, widgetNode, assetType, source } = playerHelper.getWidgetData({
            widgetObj: w,
            pageDurationMs,
          });

          widgetNode.style.display = timer >= startTimeMs && timer <= stopTimeMs ? "block" : "none";

          if (assetType) {
            playerHelper.setAnimationCurTime({ widgetNode, widgetObj: w, startTimeMs, stopTimeMs, timer });
          }

          if (assetType === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(source)) {
            playerHelper.setVideoCurTime({ widgetNode, timer, startTimeMs, stopTimeMs });
          }

          if (assetType === COLLAGE) {
            widgetNode.querySelectorAll("video").forEach(videoNode => {
              playerHelper.setVideoCurTime({ widgetNode, videoNode, timer, startTimeMs, stopTimeMs });
            });
          }
        }
      });

      playerHelper.setAudioCurTime({ timer: meta.documentTimer });
    },

    setVideoCurTime: ({ widgetNode, videoNode, timer, startTimeMs, stopTimeMs }) => {
      const {
        video,
        trimStart: trimStartTime,
        trimEnd: trimStopTime,
      } = playerHelper.getVideoNodeAndDataRef({ widgetNode, videoNode });

      if (timer > startTimeMs && timer < stopTimeMs) {
        // when widget is within playable time range
        const widgetPlayedDurationMs = timer - startTimeMs;
        const videoTrimmedDurationMs = (trimStopTime - trimStartTime) * 1000;
        const videoCurTimeWithinPlayableRange =
          (videoTrimmedDurationMs > widgetPlayedDurationMs
            ? widgetPlayedDurationMs
            : widgetPlayedDurationMs % videoTrimmedDurationMs) / 1000;

        video.currentTime = trimStartTime + videoCurTimeWithinPlayableRange;
      } else {
        video.currentTime = trimStartTime;
        // video.currentTime = startTimeMs;
      }
      video.pause();
    },

    setAudioCurTime: ({ timer }) => {
      docPagesMeta?.audios?.forEach(audioObj => {
        const { audio, startTimeMs, stopTimeMs, trimStartTimeMs, trimStopTimeMs } = playerHelper.getAudioData(audioObj);

        if (audio) {
          if (timer > startTimeMs && timer < stopTimeMs) {
            // when widget is within playable time range
            const widgetPlayedDurationMs = timer - startTimeMs;
            const audioTrimmedDurationMs = trimStopTimeMs - trimStartTimeMs;
            const audioCurTimeWithinPlayableRange =
              (audioTrimmedDurationMs > widgetPlayedDurationMs
                ? widgetPlayedDurationMs
                : widgetPlayedDurationMs % audioTrimmedDurationMs) / 1000;
            audio.currentTime = trimStartTimeMs / 1000 + audioCurTimeWithinPlayableRange;
          } else {
            audio.currentTime = trimStartTimeMs / 1000;
            // audio.currentTime = startTimeMs;
          }
          audio.pause();
        }
      });
    },

    setAnimationCurTime: ({ widgetNode, widgetObj, startTimeMs, stopTimeMs, timer }) => {
      const showEntryAnimation = widgetObj.data["data-entrance-animation"];
      const entryAnimationSpeed = widgetObj.data["data-entrance-animation-speed"];
      const entryAnimationType = widgetObj.data["data-entrance-animation-type"];
      const entryAnimationDurationMs = showEntryAnimation && animationSpeedToDurationMsObj[entryAnimationSpeed];
      const entryAnimationStopTime = showEntryAnimation && startTimeMs + entryAnimationDurationMs;
      const entryAnimationClassName = showEntryAnimation && stringTocamelCase(entryAnimationType);

      const showExitAnimation = widgetObj.data["data-exit-animation"];
      const exitAnimationSpeed = widgetObj.data["data-exit-animation-speed"];
      const exitAnimationType = widgetObj.data["data-exit-animation-type"];
      const exitAnimationDurationMs = showExitAnimation && animationSpeedToDurationMsObj[exitAnimationSpeed];
      const exitAnimationClassName = showExitAnimation && stringTocamelCase(exitAnimationType);
      const exitAnimationStartTime = showExitAnimation && stopTimeMs - exitAnimationDurationMs;

      if (!showEntryAnimation && !showExitAnimation) return;

      // detecting animation node
      let animationNode = widgetNode.querySelector(".animation-node");
      let widgetChildNodes = animationNode?.childNodes ?? widgetNode.childNodes;

      if (timer > startTimeMs && timer < stopTimeMs) {
        // when widget is within playable time range
        if (!animationNode) {
          // create animation node if not exists (scenario: playing from beginning)
          animationNode = document.createElement("div");
          animationNode.classList.add("animation-node");
          animationNode.style.height = "100%";
          animationNode.style.width = "100%";
          [...widgetChildNodes].forEach(child => animationNode.append(child));
          widgetNode.innerHTML = "";
          widgetNode.append(animationNode);
        }

        // set animation current time
        // entry animation playable range
        if (showEntryAnimation && timer >= startTimeMs && timer < entryAnimationStopTime) {
          const currentTimerDurationMs = timer - startTimeMs;
          const animationPlayedDurationMs = parseInt(
            animationNode.getAttribute("data-entry-animation-played-duration") ?? 0
          );

          // adding entry animation props
          animationNode.classList.add("play-animation");
          animationNode.classList.add(entryAnimationClassName);
          animationNode.style.animationDuration = `${entryAnimationDurationMs}ms`;
          animationNode.style.animationPlayState = "paused";
          animationNode.style.animationDelay =
            currentTimerDurationMs > animationPlayedDurationMs
              ? `-${currentTimerDurationMs - animationPlayedDurationMs}ms`
              : `${animationPlayedDurationMs - currentTimerDurationMs}ms`;

          // removing exit animation props
          animationNode.classList.remove(exitAnimationClassName);
          animationNode.removeAttribute("data-exit-animation-played-duration");
        }
        // exit animation playable range
        else if (showExitAnimation && timer >= exitAnimationStartTime && timer < stopTimeMs) {
          const currentTimerDurationMs = timer - exitAnimationStartTime;
          const animationPlayedDurationMs = parseInt(
            animationNode.getAttribute("data-exit-animation-played-duration") ?? 0
          );

          // adding exit animation props
          animationNode.classList.add("play-animation");
          animationNode.classList.add(exitAnimationClassName);
          animationNode.style.animationDuration = `${exitAnimationDurationMs}ms`;
          animationNode.style.animationPlayState = "paused";
          animationNode.style.animationDelay =
            currentTimerDurationMs > animationPlayedDurationMs
              ? `-${currentTimerDurationMs - animationPlayedDurationMs}ms`
              : `${animationPlayedDurationMs - currentTimerDurationMs}ms`;

          // removing entry animation props
          animationNode.classList.remove(entryAnimationClassName);
          animationNode.removeAttribute("data-entry-animation-played-duration");
        }
        // removing entry and exit animation props outside playable range
        else {
          animationNode.classList.remove("play-animation");
          animationNode.classList.remove(entryAnimationClassName);
          animationNode.classList.remove(exitAnimationClassName);
          animationNode.style.animationDuration = "";
          animationNode.style.animationPlayState = "";
          animationNode.style.animationDelay = "";
          animationNode.removeAttribute("data-entry-animation-played-duration");
          animationNode.removeAttribute("data-exit-animation-played-duration");
        }
      } else {
        if (animationNode) {
          // when widget is outside playable time range but animation props were added on last preview
          // re-assigning widgetChildNodes value as not working when force_stop
          widgetChildNodes = animationNode.childNodes;

          // replacing animation node with original node(s)
          widgetNode.innerHTML = "";
          [...widgetChildNodes].forEach(child => widgetNode.append(child));
        }
      }
    },

    handleSeek: e => {
      const documentProgress = e.target ? e.target.value : e;
      if (documentProgress >= 100) {
        return;
      }
      const { documentDurationMs, pageArray } = playerHelper.getDocumentData();
      const documentTimer = (documentProgress * documentDurationMs) / 100;
      const targetPageObj = pageArray.find(
        p => documentTimer >= p.pageStartTimeMs && documentTimer <= p.pageStopTimeMs
      );
      let targetPageTimer = documentTimer - targetPageObj.pageStartTimeMs;
      targetPageTimer = parseInt(targetPageTimer - (targetPageTimer % 20));
      const targetPageProgress = targetPageTimer * (100 / targetPageObj.pageDurationMs);
      let adjustedDocumentTimer = parseInt(documentTimer - (documentTimer % 20));
      let adjustedDocumentprogress = (adjustedDocumentTimer / documentDurationMs) * 100;

      playerHelper.showSlides(targetPageObj.pageIdx);
      playerHelper.setDomPlayEnvToCustomTime({
        pageId: targetPageObj.pageId,
        pageObj: docPagesMeta.pageNodes[targetPageObj.pageIdx],
        pageDurationMs: targetPageObj.pageDurationMs,
        timer: targetPageTimer,
        meta: {
          pageIdx: targetPageObj.pageIdx,
          targetPageTimer,
          pageProgress: targetPageProgress,
          documentProgress: adjustedDocumentprogress,
          documentTimer: adjustedDocumentTimer,
        },
      });
    },

    handleMouseDown: () => {
      setPagePlayData({ ...pagePlayData, action: "pause" });
      setDocumentPlayData({ ...documentPlayData, action: "pause" });
    },

    handleMouseUpOrLeave: () => {
      if (isPlaying) {
        const idValue = document.querySelector(".page-playing")?.getAttribute("id");
        const curPageIdx = parseInt(idValue.match(/\d+/)[0]);
        let curPageId = docPagesMeta?.pageNodes[curPageIdx].pageId;
        setPagePlayData({ ...pagePlayData, action: "play" });
        setDocumentPlayData({ ...documentPlayData, action: "play" });
        const newPagePlayData = {
          pageId: curPageId,
          pageIdx: curPageIdx,
          action: "play",
        };

        operateStorageData({
          type: "session",
          action: "set",
          key: DOCUMENT_PLAY_DATA_STORAGE_KEY,
          isParseable: true,
          defaultData: newPagePlayData,
        });
      }
    },

    handleArrowKeys: (event, documentProgress) => {
      const { documentDurationMs } = playerHelper.getDocumentData(); // Get the total document duration
      const seekStepMs = 5000; // 5 seconds step in milliseconds
      const progressStep = (seekStepMs / documentDurationMs) * 100; // Convert 5 seconds to a percentage of progress

      let newDocumentProgress = documentProgress;

      if (event.key === "ArrowRight") {
        // Seek forward 5 seconds
        newDocumentProgress = Math.min(99.7, newDocumentProgress + progressStep);
      } else if (event.key === "ArrowLeft") {
        // Seek backward 5 seconds
        newDocumentProgress = Math.max(0, newDocumentProgress - progressStep);
      }
      playerHelper.handleSeek(newDocumentProgress);
    },

    // Play page/widgets
    pagePlay: ({ timer, pageDurationMs, pageObj, pageId, pageIdx }) => {
      const pageNode = document.getElementById(`dhp-page-${pageIdx}`);
      const progress = customRound((100 / pageDurationMs) * timer, 2);
      const storage_data = operateStorageData({
        type: "session",
        action: "get",
        key: PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: {
          action: "play",
        },
      });

      setPagePlayData({
        action: timer === pageDurationMs ? "natural_stop" : storage_data.action,
        pageId: pageId ?? storage_data.pageId,
        pageIdx: pageIdx ?? storage_data.pageIdx,
        pageTimer: timer,
        pageProgress: progress,
        pageDurationMs: pageDurationMs,
      });

      if (timer === 0) {
        // page play start
        pageNode.classList.add("page-playing");
        playerHelper.pageTransitionPlay({ timer, pageDurationMs, pageObj });
      }

      if (timer === pageDurationMs || parseInt(progress) >= 100) {
        // page play stop
        pageNode.classList.remove("page-playing");
        playerHelper.pageTransitionPlay({ timer, pageDurationMs, pageObj });
      }
    },

    pageTransitionPlay: ({ pageObj, pageDurationMs, timer }) => {
      const pageNode = document.getElementById(`dhp-page-${pageObj.pageIdx}`);
      const blockNode = pageNode.getElementsByTagName("figure")[0];
      const showEntryAnimation = pageObj.pageTransition.enabled;
      const entryAnimationSpeed = pageObj.pageTransition.speed;
      const entryAnimationType = pageObj.pageTransition.type;
      const entryAnimationDurationMs = showEntryAnimation && animationSpeedToDurationMsObj[entryAnimationSpeed];
      const entryAnimationStopTime = showEntryAnimation && entryAnimationDurationMs;
      const entryAnimationClassName = showEntryAnimation && stringTocamelCase(entryAnimationType);

      if (!showEntryAnimation) return;

      // detecting wrapper/animation node
      let wrapperNode;
      let animationNode;

      if (!blockNode.parentNode.parentNode.classList.contains("wrapper-node")) {
        animationNode = document.createElement("div");
        animationNode.classList.add("animation-node");

        wrapperNode = document.createElement("div");
        wrapperNode.classList.add("wrapper-node");
        wrapperNode.style.overflow = "hidden";
        wrapperNode.style.height = "100%";
        wrapperNode.style.width = "100%";
        wrapperNode.appendChild(animationNode);

        blockNode.replaceWith(wrapperNode);
        wrapperNode.childNodes[0].appendChild(blockNode);
      } else {
        wrapperNode = pageNode.querySelector(".wrapper-node");
        animationNode = pageNode.querySelector(".animation-node");
      }

      // play / pause animations
      if (showEntryAnimation && timer === 0) {
        // page transition start
        animationNode.classList.add("play-animation");
        animationNode.classList.add(entryAnimationClassName);
        animationNode.style.animationDuration = `${entryAnimationDurationMs}ms`;
      }

      if (showEntryAnimation && timer > 0 && timer < entryAnimationStopTime) {
        animationNode.setAttribute("data-page-transition-played-duration", timer);
      }

      if (showEntryAnimation && timer === entryAnimationStopTime) {
        // page transition stop
        animationNode.classList.remove("play-animation");
        animationNode.classList.remove(entryAnimationClassName);
        animationNode.style.animationDuration = "";
        animationNode.style.animationPlayState = "";
        animationNode.style.animationDelay = "";
        animationNode.removeAttribute("data-page-transition-played-duration");
      }

      if (showEntryAnimation && timer === pageDurationMs) {
        // page duration end
        // removing wrapper/animation node (replacing with original node)
        wrapperNode.replaceWith(blockNode);
      }
    },

    widgetPlay: ({ timer, pageDurationMs, pageId }) => {
      // widget play-pause
      docPagesMeta?.widgets?.forEach(w => {
        if (w.pageId === pageId && !w.data["data-layer-hidden"]) {
          const widgetNode = document.getElementById(w.id);
          const { startTimeMs, stopTimeMs, assetType, source } = playerHelper.getWidgetData({
            widgetObj: w,
            pageDurationMs,
          });

          if (timer === startTimeMs) {
            widgetNode.style.display = "block";
          }

          if (assetType) {
            playerHelper.animationPlay({ widgetNode, widgetObj: w, startTimeMs, stopTimeMs, timer });
          }

          if (assetType === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(source)) {
            playerHelper.videoPlay({ widgetNode, timer, startTimeMs, stopTimeMs });
          }

          if (assetType === COLLAGE) {
            widgetNode.querySelectorAll("video").forEach(videoNode => {
              playerHelper.videoPlay({ widgetNode, videoNode, timer, startTimeMs, stopTimeMs });
            });
          }
          if (timer === stopTimeMs) {
            widgetNode.style.display = "none";
          }

          if (timer === pageDurationMs) {
            // page duration end
            widgetNode.style.display = "block";
          }
        }
      });
    },

    videoPlay: ({ widgetNode, videoNode, timer, startTimeMs, stopTimeMs }) => {
      const {
        video,
        volume,
        trimStart: trimStartTime,
        trimEnd: trimStopTime,
        isLooped,
      } = playerHelper.getVideoNodeAndDataRef({ widgetNode, videoNode });
      const videoCurTime = floatToFloatSec(video.currentTime);

      if (timer === startTimeMs) {
        if (!(videoCurTime >= trimStartTime && videoCurTime <= trimStopTime)) video.currentTime = trimStartTime;
        video.muted = false;
        video.volume = volume / 100;
        video.controls = false;
        playMedia(video);
        video.setAttribute("data-playing", "true");
      }

      if (timer > startTimeMs && timer < stopTimeMs) {
        if (!(videoCurTime >= trimStartTime && videoCurTime <= trimStopTime)) {
          // when video is outside playable time range
          video.currentTime = trimStartTime;
          video.muted = false;
          video.volume = volume / 100;
          if (isLooped) {
            if (video.paused) playMedia(video);
          } else {
            video.pause();
          }
        } else {
          video.muted = false;
          video.volume = volume / 100;
          if (video.paused) playMedia(video);
        }
      }

      if (timer === stopTimeMs) {
        video.pause();
        video.muted = true;
        video.removeAttribute("data-playing");
        video.currentTime = trimStartTime;
      }
    },

    animationPlay: ({ widgetNode, widgetObj, startTimeMs, stopTimeMs, timer }) => {
      const showEntryAnimation = widgetObj.data["data-entrance-animation"];
      const entryAnimationSpeed = widgetObj.data["data-entrance-animation-speed"];
      const entryAnimationType = widgetObj.data["data-entrance-animation-type"];
      const entryAnimationDurationMs = showEntryAnimation && animationSpeedToDurationMsObj[entryAnimationSpeed];
      const entryAnimationStopTime = showEntryAnimation && startTimeMs + entryAnimationDurationMs;
      const entryAnimationClassName = showEntryAnimation && stringTocamelCase(entryAnimationType);

      const showExitAnimation = widgetObj.data["data-exit-animation"];
      const exitAnimationSpeed = widgetObj.data["data-exit-animation-speed"];
      const exitAnimationType = widgetObj.data["data-exit-animation-type"];
      const exitAnimationDurationMs = showExitAnimation && animationSpeedToDurationMsObj[exitAnimationSpeed];
      const exitAnimationClassName = showExitAnimation && stringTocamelCase(exitAnimationType);
      const exitAnimationStartTime = showExitAnimation && stopTimeMs - exitAnimationDurationMs;

      if (!showEntryAnimation && !showExitAnimation) return;

      // detecting animation node
      let animationNode = widgetNode.querySelector(".animation-node");
      let widgetChildNodes = animationNode?.childNodes ?? widgetNode.childNodes;

      if (!animationNode) {
        // create animation node if not exists (scenario: playing from beginning)
        animationNode = document.createElement("div");
        animationNode.classList.add("animation-node");
        animationNode.style.height = "100%";
        animationNode.style.width = "100%";
        [...widgetChildNodes].forEach(child => animationNode.append(child));
        widgetNode.innerHTML = "";
        widgetNode.append(animationNode);
      }

      // play / pause animations
      if (showEntryAnimation && timer === startTimeMs) {
        // entry animation start
        animationNode.classList.add("play-animation");
        animationNode.classList.add(entryAnimationClassName);
        animationNode.style.animationDuration = `${entryAnimationDurationMs}ms`;
      }

      if (showEntryAnimation && timer > startTimeMs && timer < entryAnimationStopTime) {
        animationNode.setAttribute("data-entry-animation-played-duration", timer);
      }

      if (showEntryAnimation && timer === entryAnimationStopTime) {
        // entry animation stop
        animationNode.classList.remove("play-animation");
        animationNode.classList.remove(entryAnimationClassName);
        animationNode.style.animationDuration = "";
        animationNode.style.animationPlayState = "";
        animationNode.style.animationDelay = "";
        animationNode.removeAttribute("data-entry-animation-played-duration");
      }

      if (showExitAnimation && timer === exitAnimationStartTime) {
        // exit animation start
        animationNode.classList.add("play-animation");
        animationNode.classList.add(exitAnimationClassName);
        animationNode.style.animationDuration = `${exitAnimationDurationMs}ms`;
      }

      if (showExitAnimation && timer > exitAnimationStartTime && timer < stopTimeMs) {
        animationNode.setAttribute("data-exit-animation-played-duration", timer - exitAnimationStartTime);
      }

      if (showExitAnimation && timer === stopTimeMs) {
        // exit animation stop
        animationNode.classList.remove("play-animation");
        animationNode.classList.remove(exitAnimationClassName);
        animationNode.style.animationDuration = "";
        animationNode.style.animationPlayState = "";
        animationNode.style.animationDelay = "";
        animationNode.removeAttribute("data-exit-animation-played-duration");
      }

      if (timer === stopTimeMs) {
        // widget/page duration end
        // re-assigning widgetChildNodes value as not working when force_stop
        widgetChildNodes = animationNode.childNodes;

        // replacing animation node with original node(s)
        widgetNode.innerHTML = "";
        [...widgetChildNodes].forEach(child => widgetNode.append(child));
      }
    },

    // pause/stop
    stopDomPlay: () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
      document.querySelectorAll(".play-animation").forEach(animationNode => {
        animationNode.style.animationPlayState = "paused";
      });
      document.querySelectorAll('video[data-playing="true"]').forEach(videoNode => {
        videoNode.pause();
      });

      if (audioIntervalId) {
        clearInterval(audioIntervalId);
        audioIntervalId = null;
      }
      document.querySelectorAll('audio[data-playing="true"]').forEach(audioNode => {
        audioNode.pause();
      });
    },

    pauseDocumentPlay: () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
      if (audioIntervalId) {
        clearInterval(audioIntervalId);
        audioIntervalId = null;
      }
    },

    stopDocumentPlay: () => {
      const { firstPageObj } = playerHelper.getDocumentData();
      playerHelper.showSlides(firstPageObj.pageIdx);
      setPagePlayData({
        ...pagePlayData,
        pageId: firstPageObj.pageId,
        pageIdx: firstPageObj.pageIdx,
        pageTimer: 0,
        pageProgress: 0,
      });
      setDocumentPlayData({
        action: null,
        pageId: null,
        pageIdx: null,
        documentTimer: null,
        documentProgress: 0,
        documentDurationMs: 0,
        pageArray: [],
      });
      setNextPageToPlay({
        pageId: null,
        pageIdx: null,
        nextPageObj: null,
      });
      setIsPlaying(!isPlaying);
      // stop audio
      if (audioIntervalId) {
        clearInterval(audioIntervalId);
        audioIntervalId = null;
      }
      docPagesMeta?.audios?.forEach(audioObj => {
        const { trimStartTimeMs } = playerHelper.getAudioData(audioObj);
        const audio = document.getElementById(`player-${audioObj.id}`);
        if (audio) {
          audio.pause();
          audio.currentTime = trimStartTimeMs / 1000;
          audio.removeAttribute("data-playing");
        }
      });
      document.querySelectorAll("video").forEach(videoNode => {
        videoNode.pause();
      });
    },
    resumeDomPlayEnv: ({ timer, pageDurationMs, pageId }) => {
      // setting environment for resuming page play where left off (widget show/hide)
      docPagesMeta?.widgets.forEach(w => {
        if (w.pageId === pageId && !w.data["data-layer-hidden"]) {
          const { startTimeMs, stopTimeMs, widgetNode } = playerHelper.getWidgetData({ widgetObj: w, pageDurationMs });
          widgetNode.style.display = timer >= startTimeMs && timer <= stopTimeMs ? "block" : "none";
        }
      });

      // setting environment for resuming page play where left off (page transition + widget animation)
      document.querySelectorAll(".play-animation").forEach(element => {
        element.style.animationPlayState = "";
      });
    },

    togglePlayPause: isPlaying => {
      setIsPlaying(!isPlaying);
      // play DOM
      const newDocumentPlayData = {
        action: !isPlaying ? "play" : "pause",
      };
      setDocumentPlayData({
        ...documentPlayData,
        ...newDocumentPlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: DOCUMENT_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newDocumentPlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newDocumentPlayData,
      });
    },

    // play Audio

    getAudioData: audioObj => {
      let fadeData = {};
      const audio = document.getElementById(`player-${audioObj.id}`);
      const durationInHMS = audioObj.data["data-duration"];
      const durationInSec = getHMSToSec({ hms: durationInHMS });

      const fadeInValue = audioObj.data["data-fade-in-value"];
      const fadeOutValue = audioObj.data["data-fade-out-value"];
      const fadeInMs = parseInt(Object.keys(FADE_EFFECT_OBJ).find(k => FADE_EFFECT_OBJ[k].includes(fadeInValue)));
      const fadeOutMs = parseInt(Object.keys(FADE_EFFECT_OBJ).find(k => FADE_EFFECT_OBJ[k].includes(fadeOutValue)));

      const trim = audioObj.data["data-trim"];
      const trimArray = trim ? trim.split(",")?.map(e => parseFloat(e)) : null;
      const isTrimmed = Boolean(trimArray?.length === 2);
      const trimStart = isTrimmed ? trimArray?.[0] : 0;
      const trimStop = isTrimmed ? trimArray?.[1] : durationInSec;

      const volume = parseInt(audioObj.data["data-volume"] ?? 100);
      const startTimeMs = parseFloat(audioObj.startTime) * 1000;
      const stopTimeMs = parseFloat(audioObj.endTime) * 1000;
      const trimStartTimeMs = trimStart * 1000;
      const trimStopTimeMs = trimStop * 1000;
      const trimmedDurationMs = trimStopTimeMs - trimStartTimeMs;

      const fadeInStartTimeMs = startTimeMs;
      const fadeInStopTimeMs = startTimeMs + fadeInMs;
      const fadeOutStartTimeMs = stopTimeMs - fadeOutMs;
      const fadeOutStopTimeMs = stopTimeMs;

      if (fadeInMs > 0 || fadeOutMs > 0) {
        fadeData = playerHelper.getFadeData({ volume, fadeInStartTimeMs, fadeInMs, fadeOutStartTimeMs, fadeOutMs });
      }

      return {
        audio,
        audioCurTimeMs: floatToFloatSec(audio.currentTime) * 1000,
        volume: ((volume ? parseInt(volume) : 100) * playerVolume)/100,
        startTimeMs,
        stopTimeMs,
        trimStartTimeMs,
        trimStopTimeMs,
        trimmedDurationMs,
        fadeInStartTimeMs,
        fadeInStopTimeMs,
        fadeOutStartTimeMs,
        fadeOutStopTimeMs,
        ...fadeData,
        isMuted: Boolean(playerVolume === 0),
        isLooped: Boolean(stopTimeMs > trimStopTimeMs),
        isTrimmed: Boolean(trimStartTimeMs > 0 || trimStopTimeMs < stopTimeMs),
        isFadeIn: Boolean(fadeInMs > 0),
        isFadeOut: Boolean(fadeOutMs > 0),
      };
    },

    getFadeData: ({ volume, fadeInStartTimeMs, fadeInMs, fadeOutStartTimeMs, fadeOutMs }) => {
      let fadeInIntervals = [];
      let fadeInVolumes = [];
      let fadeOutIntervals = [];
      let fadeOutVolumes = [];

      if (fadeInMs > 0) {
        const intervals = FADE_INTERVEL_OBJ[fadeInMs];
        const volumeFactor = volume / (intervals.length - 1);

        for (let i = 0; i < intervals.length; i++) {
          fadeInIntervals = [...fadeInIntervals, fadeInStartTimeMs + intervals[i]];

          if (i === 0) fadeInVolumes = [...fadeInVolumes, 0];
          if (i > 0 && i < intervals.length - 1) fadeInVolumes = [...fadeInVolumes, parseInt(i * volumeFactor)];
          if (i === intervals.length - 1) fadeInVolumes = [...fadeInVolumes, volume];
        }
      }

      if (fadeOutMs > 0) {
        const intervalObj = JSON.parse(JSON.stringify(FADE_INTERVEL_OBJ[fadeOutMs]));
        const intervals = intervalObj.reverse();
        const volumeFactor = volume / (intervals.length - 1);

        for (let i = intervals.length - 1; i >= 0; i--) {
          fadeOutIntervals = [...fadeOutIntervals, fadeOutStartTimeMs + intervals[i]];

          if (i === intervals.length - 1) fadeOutVolumes = [...fadeOutVolumes, volume];
          if (i > 0 && i < intervals.length - 1) fadeOutVolumes = [...fadeOutVolumes, parseInt(i * volumeFactor)];
          if (i === 0) fadeOutVolumes = [...fadeOutVolumes, 0];
        }
      }

      return { fadeInIntervals, fadeInVolumes, fadeOutIntervals, fadeOutVolumes };
    },

    getPageStartTimes: () => {
      let pageStartTimes = [];
      let documentDurationMs = 0;

      docPagesMeta?.pageNodes.forEach(pageObj => {
        const pageDurationMs = parseFloat(pageObj.pageDuration) * 1000;
        const pageStartTimeMs = documentDurationMs;

        documentDurationMs += pageDurationMs;
        pageStartTimes = [...pageStartTimes, pageStartTimeMs];
      });

      return pageStartTimes;
    },

    audioPlay: ({ timer }) => {
      docPagesMeta?.audios?.forEach(audioObj => {
        const {
          audio,
          audioCurTimeMs,
          volume,
          startTimeMs,
          stopTimeMs,
          trimStartTimeMs,
          trimStopTimeMs,
          fadeInIntervals,
          fadeInVolumes,
          fadeOutIntervals,
          fadeOutVolumes,
          isMuted,
          isLooped,
          isFadeIn,
          isFadeOut,
        } = playerHelper.getAudioData(audioObj);

        if (audio) {
          if (timer === startTimeMs) {
            if (isMuted) audio.muted = false;
            if (!isFadeIn) audio.volume = volume / 100;
            audio.currentTime = trimStartTimeMs / 1000;
            playMedia(audio);
            audio.setAttribute("data-playing", "true");
          }

          // fadeIn audio effect
          if (isFadeIn && fadeInIntervals.includes(timer)) {
            const idx = fadeInIntervals.indexOf(timer);
            audio.volume = fadeInVolumes[idx] / 100 * volume / 100;
          }

          // fadeOut audio effect
          if (isFadeOut && fadeOutIntervals.includes(timer)) {
            const idx = fadeOutIntervals.indexOf(timer);
            audio.volume = fadeOutVolumes[idx] / 100 * volume / 100;
          }

          if (timer > startTimeMs && timer < stopTimeMs) {
            if (!(audioCurTimeMs >= trimStartTimeMs && audioCurTimeMs <= trimStopTimeMs)) {
              // when audio is outside playable time range
              audio.currentTime = trimStartTimeMs / 1000;
              // audio.volume = volume / 100;
              if (isLooped) {
                if (audio.paused) {
                  playMedia(audio);
                  audio.setAttribute("data-playing", "true");
                }
              } else {
                audio.pause();
              }
            } else {
              if (audio.paused) {
                audio.volume = volume / 100;
                // playerHelper.setAudioCurTime({ timer }); // only for download
                playMedia(audio);
                audio.setAttribute("data-playing", "true");
              }
            }
          }

          if (timer === stopTimeMs) {
            audio.pause();
            if (isMuted) audio.muted = true;
            audio.removeAttribute("data-playing");
            audio.currentTime = trimStartTimeMs / 1000;
          }
        }
      });
    },

    controllVolume: vol => {
      setPlayerVolume(parseFloat(vol) * 100);
      if (audioIntervalId) {
        clearInterval(audioIntervalId);
        audioIntervalId = null;
      }
    },
  };

  useEffect(() => {
    if (documentPlayData.action === "play") {
      if (!documentPlayData.documentTimer) {
        // start document/page play from begining
        const { firstPageObj } = playerHelper.getDocumentData();
        const newPagePlayData = {
          action: "play",
          pageId: firstPageObj.pageId,
          pageIdx: firstPageObj.pageIdx,
        };
        setPagePlayData({
          ...pagePlayData,
          ...newPagePlayData,
        });
        operateStorageData({
          type: "session",
          action: "set",
          key: PAGE_PLAY_DATA_STORAGE_KEY,
          isParseable: true,
          defaultData: newPagePlayData,
        });
      } else {
        // resume document/page play where it left off
        const newPagePlayData = {
          action: "play",
          pageId: documentPlayData.pageId,
          pageIdx: documentPlayData.pageIdx,
        };
        setPagePlayData({
          ...pagePlayData,
          ...newPagePlayData,
        });
        operateStorageData({
          type: "session",
          action: "set",
          key: PAGE_PLAY_DATA_STORAGE_KEY,
          isParseable: true,
          defaultData: newPagePlayData,
        });
      }
    }

    if (documentPlayData.action === "pause") {
      const newPagePlayData = {
        action: "pause",
        pageId: documentPlayData.pageId,
        pageIdx: documentPlayData.pageIdx,
      };
      setPagePlayData({
        ...pagePlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });
    }

    if (documentPlayData.action === "natural_stop") {
      playerHelper.stopDocumentPlay();
    }

    return () => {
      playerHelper.pauseDocumentPlay();
    };
  }, [documentPlayData.action]);

  useEffect(() => {
    if (nextPageToPlay.pageId) {
      const { pageId, pageIdx } = nextPageToPlay;
      const newPagePlayData = { action: "play", pageId, pageIdx };

      // set page play instructions
      setPagePlayData({
        ...pagePlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: PAGE_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });

      setDocumentPlayData({
        ...documentPlayData,
        ...newPagePlayData,
      });
      operateStorageData({
        type: "session",
        action: "set",
        key: DOCUMENT_PLAY_DATA_STORAGE_KEY,
        isParseable: true,
        defaultData: newPagePlayData,
      });
    }
  }, [nextPageToPlay]);
  // }, [nextPageToPlay.pageId]);

  useEffect(() => {
    if (documentPlayData.action && pagePlayData.pageTimer) {
      const { documentDurationMs, pageArray } = playerHelper.getDocumentData();
      const prevPageIdx = pageArray?.[pagePlayData.pageIdx]?.prevPageIdx;
      const nextPageIdx = pageArray?.[pagePlayData.pageIdx]?.nextPageIdx;
      const nextPageObj = pageArray?.[nextPageIdx];

      if (pagePlayData.pageTimer > 0 && pagePlayData.pageTimer <= pagePlayData.pageDurationMs) {
        // update document timer and progress on each page play interval of 100ms
        let timer = 0;
        let progress = 0;

        pageArray.forEach((pageObj, idx) => {
          if (prevPageIdx >= 0 && idx < pagePlayData.pageIdx) timer += pageObj.pageDurationMs;
        });

        timer += pagePlayData.pageTimer;
        progress = customRound((100 / documentDurationMs) * timer, 2);
        setDocumentPlayData({
          ...documentPlayData,
          pageId: pagePlayData.pageId,
          pageIdx: pagePlayData.pageIdx,
          documentTimer: timer,
          documentProgress: progress,
          documentDurationMs,
          pageArray,
        });
      }
      if (pagePlayData.pageTimer === pagePlayData.pageDurationMs) {
        // at end of each page duration
        if (nextPageIdx) {
          playerHelper.showSlides(nextPageIdx);
          setTimeout(() => {
            setNextPageToPlay({
              pageId: nextPageObj.pageId,
              pageIdx: nextPageObj.pageIdx,
              nextPageObj,
            });
          }, 10);
        } else {
          // natural end of document play
          setDocumentPlayData({
            ...documentPlayData,
            action: "natural_stop",
          });
        }
      }
    }
  }, [pagePlayData.pageTimer]);

  const getactvePageStartTime = ({ pageId }) => {
    let activePageOriginalIndex = docPagesMeta?.allPageNodes?.findIndex(pt => pt.pageId === pageId);
    let timeNow = 0;
    docPagesMeta?.allPageNodes?.forEach(pn => {
      if (pn.pageIdx < activePageOriginalIndex) {
        timeNow += parseFloat(pn.pageDuration);
      }
    });

    return timeNow * 1000;
  };

  useEffect(() => {
    if (pageAction === "play" && !intervalId && pagePlayData.pageProgress <= 100) {
      let timer = pagePlayData?.pageTimer ?? 0;
      const { pageId, pageIdx, pageObj, pageDurationMs } = playerHelper.getPageData();
      if (!pageId) {
        console.log("failure", pageIdx);
        return;
      }

      playerHelper.resumeDomPlayEnv({ timer, pageDurationMs, pageId });

      if (timer === 0) {
        playerHelper.pagePlay({ timer: 0, pageDurationMs, pageObj, pageId, pageIdx });
        playerHelper.widgetPlay({ timer: 0, pageDurationMs, pageId });
      }

      intervalId = setInterval(() => {
        timer += 20;
        playerHelper.widgetPlay({ timer, pageDurationMs, pageId });
        playerHelper.pagePlay({ timer, pageDurationMs, pageObj, pageId, pageIdx });
      }, 20);

      if (!audioIntervalId) {
        // let timer = getactvePageStartTime({ pageId }); // only for download
        let timer = documentPlayData?.documentTimer ?? 0;

        if (timer === 0) {
          playerHelper.audioPlay({ timer });
        }

        audioIntervalId = setInterval(() => {
          timer += 20;
          playerHelper.audioPlay({ timer });
        }, 20);
      }
    }

    if (pageAction === "pause") {
      playerHelper.stopDomPlay();
    }

    if (pageAction === "natural_stop") {
      playerHelper.stopDomPlay();
      setPagePlayData({
        action: null,
        pageId: null,
        pageIdx: null,
        pageTimer: null,
        pageProgress: 0,
        pageDurationMs: 0,
      });
    }

    return () => {
      playerHelper.stopDomPlay();
    };
  }, [pageAction, playerVolume]);

  useEffect(() => {
    setPageAction(pagePlayData.action);
  }, [pagePlayData.action]);

  // Set video initial screen on first load
  useLayoutEffect(() => {
    const { pageArray } = playerHelper.getDocumentData();
    if (pageArray?.length > 0) {
      let targetPageObj = pageArray[0];
      playerHelper.setDomPlayEnvToCustomTime({
        pageId: targetPageObj.pageId,
        pageObj: docPagesMeta.pageNodes[0],
        pageDurationMs: targetPageObj.pageDurationMs,
        timer: 0,
        meta: {
          pageIdx: targetPageObj.pageIdx,
          targetPageTimer: 0,
          pageProgress: 0,
          documentProgress: 0,
          documentTimer: 0,
        },
      });

      // Handle muted Auto Play
      playerHelper.togglePlayPause(isPlaying);
      playerHelper.controllVolume(0);
    }
  }, [docPagesMeta]);

  return {
    documentPlayData,
    isPlaying,
    togglePlayPause: playerHelper.togglePlayPause,
    getDocumentData: playerHelper.getDocumentData,
    handleSeek: playerHelper.handleSeek,
    handleArrowKeys: playerHelper.handleArrowKeys,
    handleMouseDown: playerHelper.handleMouseDown,
    handleMouseUpOrLeave: playerHelper.handleMouseUpOrLeave,
    controllVolume: playerHelper.controllVolume,
  };
};

export default useHTMLPlayer;
