import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "./useSocket";

import { fetchTypeformForms, getConnectedAccounts } from "../store/actions/companyActions";
import { THIRDPARTY_TOKEN_STATUS } from "../constants/socket";

const socketEndpoint = `${process.env.SOCKET_URL}`;

const useTypeform = (userId, companyId, assetName) => {
  const { thirdPartyAccounts } = useSelector(state => state.company);

  const webSocket = useSocket();
  const dispatch = useDispatch();

  const [forms, setForms] = useState(null);
  const [isCurrentFormAvailable, setIsCurrentFormAvailable] = useState(false);
  const [isSectionReady, setIsSectionReady] = useState(false);
  const [thirdPartyForms, setThirdPartyForms] = useState();

  let typeformOauthWindow = useRef(null);

  // Typeform login if not loggedin previously
  const login = async () => {
    let params = `width=500,height=700,left=400,top=150`;
    typeformOauthWindow.current = window.open(
      `${socketEndpoint}/auth/form/typeform/${companyId}/${userId}/`,
      "Typeform Login",
      params
    );
  };

  const onMessage = useCallback(message => {
    const response = JSON.parse(message?.data);
    if (response.status === 200 && response.type === THIRDPARTY_TOKEN_STATUS) {
      if (typeformOauthWindow.current) {
        typeformOauthWindow.current.close();
        dispatch(getConnectedAccounts());
        setIsCurrentFormAvailable(true);
      }
    }
  });

  /**
   * Websocket connection
   * To check latest socket msg
   */
  useEffect(() => {
    if (webSocket?.readyState === 1) {
      webSocket.addEventListener("message", onMessage);

      return () => {
        webSocket.removeEventListener("message", onMessage);
      };
    }
  }, [webSocket, onMessage]);

  // Check is current asset type form present in redux
  useEffect(() => {
    if (thirdPartyForms?.[assetName]) {
      setForms(thirdPartyForms[assetName].items);
    }
  }, [thirdPartyForms]);

  /**
   * Check current thirdparty app already integrated or not
   * If present then set ready to fetch available public forms
   */
  useEffect(() => {
    if (thirdPartyAccounts) {
      let isAvailable = thirdPartyAccounts?.details?.find(item => item.provider === assetName);
      if (isAvailable) setIsCurrentFormAvailable(true);
      setIsSectionReady(true);
    }
  }, [thirdPartyAccounts]);

  // If loggedin the fetch form list
  useEffect(() => {
    if (isCurrentFormAvailable) {
      dispatch(fetchTypeformForms(assetName)).then((resp) => {
        setThirdPartyForms({ [assetName]: resp.data.details });
      })
    };
  }, [isCurrentFormAvailable]);

  return { forms, isLoggedin: isCurrentFormAvailable, login, isSectionReady };
};

export default useTypeform;
