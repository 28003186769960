// import { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
// import { EditorContext } from "../containers/editor/EditorLayout";
// import useSetActivePageBlock from "./useSetActivePageBlock";
// import useTextFocusOut from "./useTextFocusOut";
// import { EditorWrapperContext } from "../containers/editor/EditorWrapper";

// const useBlockActiveOnscroll = (activeBlockId, ignoreNextScrollEvent, isResizing) => {
//   let { blockNodes, pageNodes, rightContextMenu, updateRightContextMenu, isDocumentReady, isTimeLineViewOpen } =
//     useContext(EditorContext);
//   const { updateScrollEvent } = useContext(EditorWrapperContext);

//   const [intersecting, setIntersecting] = useState();
//   const [currentTop, setCurrentTop] = useState(); // Using this state to update top of page block controller. if only set state then controlller scroller will work
//   const [isScrolling, setIsScrolling] = useState(false);
//   const [elementList, setElementList] = useState([]);
//   const [disableAutoScroll, setDisableAutoScroll] = useState(false);
//   const scroller = document.getElementById("canvas-panel-area");

//   let y = scroller?.scrollTop;
//   const offsetTop = 330;
//   const offsetBottom = 400;
//   let timeoutId;

//   // Activate Page Bloc
//   useSetActivePageBlock(intersecting, isResizing);
//   const { handleFocusOut } = useTextFocusOut();

//   const getTargetPageBlock = pageNum => {
//     setCurrentTop(y);
//     let filteredNode = blockNodes.find(elem => elem.blockId === activeBlockId);

//     /**
//      * Below this condition "filteredNode.blockIdx + pageNum !== blockNodes.length"
//      * To check next target page block is available or not
//      * If not available then auto active should not work
//      */
//     if (filteredNode && filteredNode.blockIdx + pageNum !== blockNodes.length) {
//       let activePageIdx = pageNodes?.find(page => page.pageId === filteredNode.pageId)?.pageIdx;
//       setIntersecting({
//         targetBlockIdx: filteredNode.blockIdx + pageNum < 0 ? 0 : filteredNode.blockIdx + pageNum,
//         targetPageIdx: activePageIdx + pageNum < 0 ? 0 : activePageIdx + pageNum,
//         disableAutoScroll: true,
//       });
//     }
//   };

//   if (ignoreNextScrollEvent) {
//     setTimeout(function () {
//       updateScrollEvent(false);
//     }, 700);
//   }

//   const isInViewport = elem => {
//     const bounding = elem.getBoundingClientRect();
//     const topInView = bounding.bottom >= offsetTop && bounding.top <= offsetBottom;
//     return topInView;
//   };

//   const handleNavigation = useCallback(
//     event => {
//       // Apply scroll active function if only canvas panel area scrolling
//       if (event.target !== scroller) return;

//       // Focusout triggerd if any text or text frame widget is contenteditable
//       if (document.querySelector(".dhp-content-editable-true-text")) handleFocusOut();
//       // disable context menu when scroll active
//       if (rightContextMenu.enable)
//         updateRightContextMenu({
//           ...rightContextMenu,
//           enable: false,
//         });

//       if (!activeBlockId) return;

//       if (ignoreNextScrollEvent) {
//         setDisableAutoScroll(true);
//         return;
//       }

//       let currentRect = document.querySelector(`#${activeBlockId}`)?.getBoundingClientRect();
//       if (!currentRect) return;

//       if (y > scroller?.scrollTop) {
//         if (currentRect.top > offsetTop) {
//           getTargetPageBlock(-1); // Set active top page
//         }
//       } else if (y < scroller?.scrollTop) {
//         // previous value 230
//         if (currentRect.bottom < offsetBottom) {
//           getTargetPageBlock(1); // Set active Bottom page
//         }
//       }
//       y = scroller?.scrollTop;

//       // Check is scroll stopped
//       setIsScrolling(true);
//       clearTimeout(timeoutId);
//       timeoutId = setTimeout(() => setIsScrolling(false), 800);
//     },
//     [y, activeBlockId, ignoreNextScrollEvent]
//   );

//   /**
//    * Below code to check is activeBlockId is present is view port or not
//    * If not then check which block is present inview port and activate this block
//    */
//   useLayoutEffect(() => {
//     if (!isScrolling && activeBlockId && !disableAutoScroll) {
//       let currentElem = document.getElementById(activeBlockId);

//       // Return if this is activeblock is already active
//       if (isInViewport(currentElem)) return;

//       // To check each element position in the viewport
//       elementList.forEach(element => {
//         if (isInViewport(element)) {
//           let targetBlockId = element?.getAttribute("data-block-idx");
//           let targetPageId = element?.parentNode?.getAttribute("data-page-idx");

//           if (targetBlockId && targetPageId) {
//             setIntersecting({
//               targetBlockIdx: targetBlockId,
//               targetPageIdx: targetPageId,
//               disableAutoScroll: true,
//             });
//           }
//         }
//       });
//     }
//   }, [isScrolling]);

//   useEffect(() => {
//     if (disableAutoScroll) {
//       setTimeout(function () {
//         setDisableAutoScroll(false);
//       }, 1500);
//     }
//   }, [disableAutoScroll]);

//   // Fetch current page block node list
//   useEffect(() => {
//     if (isDocumentReady) {
//       let elements = document.querySelectorAll(".dhp-page-block");
//       setElementList(elements);
//     }
//   }, [blockNodes, isDocumentReady]);

//   useEffect(() => {
//     if (!isTimeLineViewOpen) window.addEventListener("scroll", handleNavigation, true);
//     return () => {
//       window.removeEventListener("scroll", handleNavigation, true);
//     };
//   }, [handleNavigation, isTimeLineViewOpen]);
// };

// export default useBlockActiveOnscroll;

import { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import useSetActivePageBlock from "./useSetActivePageBlock";
import useTextFocusOut from "./useTextFocusOut";
import { EditorWrapperContext } from "../containers/editor/EditorWrapper";

const useBlockActiveOnscroll = (activeBlockId, ignoreNextScrollEvent, isResizing) => {
  let { blockNodes, rightContextMenu, updateRightContextMenu, isDocumentReady, isTimeLineViewOpen } =
    useContext(EditorContext);
  const { updateScrollEvent } = useContext(EditorWrapperContext);

  const [intersecting, setIntersecting] = useState();
  const [maxVisibleElement, setMaxVisibleElement] = useState(null);
  const [visibleParentElement, setVisibleParentElement] = useState(null);
  const [elementList, setElementList] = useState([]);
  const [defaultActive, setDefaultActive] = useState(false);
  const [resetActive, setResetActive] = useState(false);

  const offsetTop = 330;
  const offsetBottom = 400;

  const scrollCanvasArea = document.getElementById("canvas-panel-area");

  // Activate Page Block
  useSetActivePageBlock(intersecting, isResizing);
  const { handleFocusOut } = useTextFocusOut();

  // Check if this Block is present between offsetTop && offsetBottom
  const isInViewport = elem => {
    const bounding = elem.getBoundingClientRect();
    const topInView = bounding.bottom >= offsetTop && bounding.top <= offsetBottom;
    return topInView;
  };

  if (ignoreNextScrollEvent) {
    setTimeout(function () {
      updateScrollEvent(false);
    }, 1000);
  }

  const handleNavigation = useCallback(
    event => {
      // Apply scroll active function if only canvas panel area scrolling
      if (event.target !== scrollCanvasArea) return;

      // Focusout triggerd if any text or text frame widget is contenteditable
      if (document.querySelector(".dhp-content-editable-true-text")) handleFocusOut();

      // disable context menu when scroll active
      if (rightContextMenu.enable) {
        updateRightContextMenu({
          ...rightContextMenu,
          enable: false,
        });
      }

      if (!activeBlockId) return;
      if (ignoreNextScrollEvent) return;

      let maxArea = 0;
      let maxElement = null;

      // To check each element position in the viewport
      elementList.forEach(element => {
        if (isInViewport(element)) {
          const rect = element.getBoundingClientRect();
          const area = rect.width * rect.height;
          if (area > maxArea) {
            maxArea = area;
            maxElement = element;
          }
        }
      });

      // if active blockId is not same as maximum visible area element id then set resetactive true
      if (activeBlockId !== maxElement?.id) setResetActive(true);
      // setVisibleParentElement(maxElement?.parentNode?.parentNode);
      setVisibleParentElement(maxElement?.closest(".dhp-page-canvas"));
      setMaxVisibleElement(maxElement);
    },
    [elementList, ignoreNextScrollEvent, activeBlockId]
  );

  useEffect(() => {
    if (!isTimeLineViewOpen) window.addEventListener("scrollend", handleNavigation, true);
    return () => {
      window.removeEventListener("scrollend", handleNavigation, true);
    };
  }, [handleNavigation, isTimeLineViewOpen]);

  // Fetch current page block node list
  useEffect(() => {
    if (isDocumentReady) {
      let elements = document.querySelectorAll(".dhp-page-block");
      setElementList(elements);
    }

    // Set default active on canvas load
    if (activeBlockId && !defaultActive) {
      let currActiveBlock = document.getElementById(`${activeBlockId}`);
      if (currActiveBlock) {
        setVisibleParentElement(currActiveBlock?.closest(".dhp-page-canvas"));
        setMaxVisibleElement(currActiveBlock);
        setDefaultActive(true);
      }
    }
  }, [isDocumentReady, blockNodes]);

  // Set active page block based on visible position in the page
  useLayoutEffect(() => {
    let targetBlockId = maxVisibleElement?.getAttribute("data-block-idx");
    let targetPageId = visibleParentElement?.getAttribute("data-page-idx");

    if (targetBlockId && targetPageId) {
      setResetActive(false);

      setIntersecting({
        targetBlockIdx: targetBlockId,
        targetPageIdx: targetPageId,
        disableAutoScroll: true,
      });
    }
  }, [maxVisibleElement, resetActive]);
};

export default useBlockActiveOnscroll;
