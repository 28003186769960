import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Input, DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import ColorPicker from "../../ui/colorPicker";
import { contextualConfig } from "../editor_config";
import { colorHexCodeValidation } from "../../../_helpers/utils";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { TEXT } from "../../../constants/editor";
import { EditorPanelContext } from "../../../containers/editor/EditorPanel";

let style = Object.assign({}, global);

const ColorBucket = props => {
  let { metadata } = useContext(EditorContext);
  let { doubleClickActive } = useContext(EditorPanelContext);

  const [actualColorValue, setActualColorValue] = useState();

  const handleBlur = e => {
    let isHex = colorHexCodeValidation(e.target.value);
    if (isHex === false || props.color.length > 7) props.setColor(actualColorValue);
  };

  const isShownGradient = () => {
    // do not show gradient option if text has background color applied
    if (
      metadata.activeWidgetType[0] === TEXT &&
      props.setColorPickerGradient &&
      !props.isGradientActive &&
      !doubleClickActive
    ) {
      if (
        document
          .querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`)
          .classList.contains("text-background-active")
      ) {
        props.setColorPickerGradient(false);
      } else props.setColorPickerGradient(true);
    }
  };

  useEffect(() => {
    let isHex = colorHexCodeValidation(props.color);
    if (isHex && props.color.length <= 7) setActualColorValue(props.color);
  }, [props.color]);

  return (
    <UncontrolledDropdown
      setActiveFromChild
      className={cx(style["color-section-block"], style["color-picker-dropdown"])}>
      <DropdownToggle
        tag="a"
        className={cx(style["p-0"], style["custom-tooltip"])}
        onClick={() => {
          isShownGradient(), props?.setLoadCurrentFormatting && props?.setLoadCurrentFormatting(false);
        }}>
        <Icon
          additionalclass={props.iconBucketColor}
          icon="ui-fill-color2"
          style={
            props.isGradientActive
              ? { backgroundImage: props.colorPickerGradient ? props.colorPickerGradient : "red" }
              : props.color === "transparent"
              ? {
                  backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                  backgroundSize: "cover",
                }
              : props.color === "Mixed Colors"
              ? {
                  backgroundImage: `url(${contextualConfig.colorUrl.mixedColor})`,
                  backgroundSize: "cover",
                }
              : { backgroundColor: props.color }
          }></Icon>
        <div className={cx(style["custom-tooltip-content"], style["top"])}>Color</div>
      </DropdownToggle>
      <DropdownMenu className={cx(style["border-0"], style["p-0"])}>
        {props.colorPickerGradient && (
          <ColorPicker
            color={props.color}
            setColor={props.setColor}
            loadCurrentFormatting={props.loadCurrentFormatting}
            setLoadCurrentFormatting={props.setLoadCurrentFormatting}
            setUpdateContext={props.setUpdateContext}
            eyedropperColor={props.eyedropperColor}
            setEyedropperColor={props.setEyedropperColor}
            colorPickerGradient={props.colorPickerGradient}
            setColorPickerGradient={props.setColorPickerGradient}
            isGradientActive={props.isGradientActive}
            setIsGradientActive={props.setIsGradientActive}
            setTextSelectedAreaColor={props.setTextSelectedAreaColor}
            previousSelection={props.previousSelection}
          />
        )}
        {!props.colorPickerGradient && (
          <ColorPicker
            color={props.color}
            setColor={props.setColor}
            setUpdateContext={props.setUpdateContext}
            eyedropperColor={props.eyedropperColor}
            setEyedropperColor={props.setEyedropperColor}
            setTextSelectedAreaColor={props.setTextSelectedAreaColor}
            previousSelection={props.previousSelection}
            isHideTransparentOpt={props.isHideTransparentOpt}
          />
        )}
      </DropdownMenu>

      {props.position === "leftPane" && (
        <Input
          type="text"
          className={style["form-control"]}
          value={props.color}
          onChange={e => {
            props.setColor(e.target.value), props.setUpdateContext(true);
          }}
          onBlur={e => handleBlur(e)}
        />
      )}
    </UncontrolledDropdown>
  );
};
//Props type validation
ColorBucket.propTypes = {
  color: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  setColor: PropTypes.func.isRequired,
  iconBucketColor: PropTypes.string,
  position: PropTypes.string.isRequired,
  setLoadCurrentFormatting: PropTypes.func,
  setUpdateContext: PropTypes.func,
  eyedropperColor: PropTypes.string,
  setEyedropperColor: PropTypes.func,
  colorPickerGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setColorPickerGradient: PropTypes.func,
  isGradientActive: PropTypes.bool,
  setIsGradientActive: PropTypes.func,
  loadCurrentFormatting: PropTypes.bool,
};

export default ColorBucket;
