import React, { useEffect, useRef, useState, useContext, useLayoutEffect } from "react";
import cx from "classnames";
import { Button, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from "reactstrap";
import Select, { components } from "react-select";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon } from "../../ui/icon";
import global from "../../../scss/dhp.scss";
import { COMPANY_SUPERADMIN } from "../../../constants/company";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import Crown from "../../../assets/images/ui-crown.svg";
import Modal from "../../ui/modal";
import fontUploadModal from "../Modals/fontUploadModal";
import { AppContext } from "../../../contexts";
import BrandSetup from "../Widgets/BrandSetup";
import BrandCompanyList from "../Widgets/BrandCompanyList";
import { useSelector } from "react-redux";
import AssetsLoader from "../../ui/loader/assetsLoader";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useAccessControl from "../../../hooks/useAccessControl";

let style = Object.assign({}, global);

const { Control } = components;
let availableFontsSchemeTypes = ["all", "preset", "custom", "brand"];

const FontFamilySchemes = ({ defaultValue, updateState, ...props }) => {
  availableFontsSchemeTypes = props.isHideBrand ? ["all", "preset", "custom"] : ["all", "preset", "custom", "brand"];
  const [value, setValue] = useState(defaultValue);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const toggle = e => {
    if (e.target.closest(".custom-font-upload")) setDropdownOpen(true);
    else setDropdownOpen(prevState => !prevState);
  };

  const onSelectChange = value => {
    setValue(value);
    updateState(value, props.name);
    setDropdownOpen(false);
  };

  // For reposition dropdown list on user input when position is top
  const onSearchChange = e => {
    let optionPosition = e.target.closest(".searchList-area");
    const transform = optionPosition.style.transform;
    const re = /translate3d\((?<x>.*?)px, (?<y>.*?)px, (?<z>.*?)px/;
    const results = re.exec(transform);
    if (results.groups.y < 0) {
      setTimeout(() => {
        optionPosition.style.transform = `translate3d(0px, -${optionPosition.offsetHeight + 8}px, 0px)`;
      }, 3);
    }
  };

  const handleMenuOpen = () => {
    setTimeout(() => {
      const currentDropdown = document.querySelectorAll(".searchList-area.show")[0];
      if (currentDropdown) {
        const selectedEl = currentDropdown.getElementsByClassName("select__option--is-selected")[0];
        if (selectedEl) {
          selectedEl.scrollIntoView({ block: "nearest", inline: "start" });
        }
      }
    }, 15);
  };

  useEffect(() => {
    if (dropdownOpen) handleMenuOpen(); // To fix reset dropdown scrolled position
  }, [dropdownOpen, activeTab]);

  useLayoutEffect(() => {
    setValue(defaultValue);
    setDropdownOpen(false);
  }, [defaultValue]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={style["custom_select2"]}>
      <DropdownToggle className={cx(style["w-100"], style["custom-tooltip"])}>
        <div> {value?.name} </div>
        <Icon icon="ui-arrow-down" />
        {!props.hideTooltip && <span className={cx(style["custom-tooltip-content"], style["top"])}>Font Family</span>}
      </DropdownToggle>
      <DropdownMenu className={`searchList-area p-0 border-0`}>
        <FontFamilySchemeTypeNav
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onSelectChange={onSelectChange}
          onSearchChange={onSearchChange}
          defaultValue={value}
          setDropdownOpen={setDropdownOpen}
          {...props}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

FontFamilySchemes.propTypes = {
  defaultValue: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
  name: PropTypes.string,
  hideTooltip: PropTypes.bool,
  isHideBrand: PropTypes.bool,
};

export default FontFamilySchemes;

const FontFamilySchemeTypeNav = ({ activeTab, setActiveTab, ...props }) => {
  const updateTabTypeScheme = index => {
    if (activeTab !== index) setActiveTab(index);
  };

  useEffect(() => {
    return () => {
      setActiveTab(0);
    };
  }, []);

  return (
    <>
      {availableFontsSchemeTypes && (
        <>
          <Nav tabs className={cx(style["d-flex"], style["font-tab"])}>
            {availableFontsSchemeTypes?.map((availableFontsSchemeType, index) => (
              <NavItem key={availableFontsSchemeType}>
                <NavLink className={activeTab === index ? "active" : ""} onClick={() => updateTabTypeScheme(index)}>
                  {availableFontsSchemeType.charAt(0).toUpperCase() + availableFontsSchemeType.slice(1)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <FontFamilyScheme type={availableFontsSchemeTypes[activeTab]} {...props} />
        </>
      )}
    </>
  );
};

FontFamilySchemeTypeNav.propTypes = {
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

const FontFamilyScheme = ({ type, ...props }) => {
  let familyScheme;
  switch (type) {
    case "all":
      familyScheme = <FamilyList {...props} type="All" />;
      break;
    case "preset":
      familyScheme = <FamilyList {...props} type="Google" />;
      break;
    case "custom":
      familyScheme = <FamilyList {...props} type="Custom" />;
      break;
    case "brand":
      familyScheme = <BrandList {...props} type="Brand" />;
      break;
    default:
      familyScheme = <FamilyList {...props} type="All" />;
      break;
  }

  return familyScheme;
};

const selectStyles = {
  control: provided => ({
    ...provided,
    minWidth: 240,
    margin: 8,
    flexDirection: "row-reverse",
    paddingLeft: "5px",
  }),
};

const FamilyList = ({ options, ...props }) => {
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);
  const [showCustomSection, setShowCustomSection] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const selectRef = useRef();
  const fontList = options?.filter(
    font =>
      (font.enabled || font.name === props?.defaultValue.name) && (props?.type === "All" || font.source === props?.type)
  );

  useEffect(() => {
    if (props?.type === "Custom" && fontList?.length === 0) setShowCustomSection(true);
    else setShowCustomSection(false);
  }, [paidCompanyInfo, props?.type, fontList]);

  useEffect(() => {
    selectRef.current?.focus();
  });

  return (
    <>
      <div style={props?.type !== "Custom" || fontList?.length > 0 ? {} : { minHeight: "210px" }}>
        <UploadCustomFonts
          paidCompanyInfo={paidCompanyInfo}
          showCustomSection={showCustomSection}
          showUploadModal={showUploadModal}
          setShowUploadModal={setShowUploadModal}
        />
      </div>
      {!showCustomSection && fontList?.length > 0 && (
        <Select
          classNamePrefix="select"
          backspaceRemovesValue={false}
          components={{ DropdownIndicator: FontSearchIndicator, IndicatorSeparator: null, Control: CustomControl }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onKeyDown={e => props?.onSearchChange(e)}
          onChange={props?.onSelectChange}
          placeholder=""
          styles={{ ...selectStyles }}
          tabSelectsValue={false}
          options={fontList}
          menuShouldScrollIntoView={false}
          noOptionsMessage={() => "No results found"}
          fontType={props?.type}
          setShowUploadModal={setShowUploadModal}
          paidCompanyInfo={paidCompanyInfo}
          ref={selectRef}
          {...props}
        />
      )}

      {showUploadModal && (
        <Modal
          showModal={showUploadModal}
          setShowModal={setShowUploadModal}
          component={fontUploadModal}
          modalClassName={"custom-font-upload"}
          backdrop="static"
          origin={"editor"}
        />
      )}
    </>
  );
};

FamilyList.propTypes = {
  options: PropTypes.array.isRequired,
  setDropdownOpen: PropTypes.func.isRequired,
  defaultValue: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
};

const UploadCustomFonts = ({ paidCompanyInfo, showCustomSection, setShowUploadModal }) => {
  const history = useHistory();

  const { checkAccess } = useAccessControl();
  let hasCustomFoAccess = checkAccess("custom_fonts");

  const [buttonText, setButtonText] = useState(false);
  useEffect(() => {
    if ((paidCompanyInfo?.companyRole !== COMPANY_SUPERADMIN && !hasCustomFoAccess) && paidCompanyInfo?.isPaid)
      setButtonText("Only the Admin can upload");
    else setButtonText("Upload Custom Fonts");
  }, [paidCompanyInfo, showCustomSection, hasCustomFoAccess]);

  const handleOnclick = type => {
    if (type.includes("Upload Custom Fonts")) {
      if (paidCompanyInfo?.isPaid) setShowUploadModal(true);
      else history.push("/companies/settings/fonts");
    } else return;
  };

  return (
    <>
      <div
        className={cx(style["not-found-wrap"], style["mb-3"], style["pt-3"], {
          [style["d-none"]]: !showCustomSection,
        })}>
        <Icon icon="ui-custom-fonts" />
        <span className={cx(style["mb-2"], style["fw-6"], style["font-15"])}>Use Custom Fonts</span>
        <span className={cx(style["mb-2"], style["mb-3"], style["font-15"])}>
          Make your design unique
          <br />
          with custom fonts.
        </span>
        <Button
          color="secondary"
          onClick={() => handleOnclick(buttonText)}
          style={{
            maxWidth: "fit-content",
            cursor: buttonText === "Only the Admin can upload" ? "default" : "pointer",
          }}>
          {buttonText}
          {buttonText === "Upload Custom Fonts" && (
            <img alt="premium" width={20} src={Crown} className={cx(style["ml-2"], style["mr-0"])} />
          )}
        </Button>
      </div>
    </>
  );
};

UploadCustomFonts.propTypes = {
  paidCompanyInfo: PropTypes.object.isRequired,
  showCustomSection: PropTypes.bool.isRequired,
  setShowUploadModal: PropTypes.func.isRequired,
};

const FontSearchIndicator = () => <Icon icon="ui-search" />;

const CustomControl = ({ children, selectProps, ...props }) => {
  const { checkAccess } = useAccessControl();
  let hasCustomFoAccess = checkAccess("custom_fonts");

  return (
    <>
      {selectProps?.fontType === "Custom" && (
        <div
          className={cx(
            style["d-flex"],
            style["px-3"],
            style["pb-1"],
            style["align-items-center"],
            style["justify-content-between"]
          )}
          style={{ paddingTop: ".75rem" }}>
          <Control {...props} className={cx(style["w-75"], style["m-0"], style["custom-font-search"])}>
            {children}
          </Control>
          {(selectProps?.paidCompanyInfo.companyRole === COMPANY_SUPERADMIN || hasCustomFoAccess) && (
            <div
              className={cx(
                style["custom-tooltip"],
                style["badge-secondary"],
                style["custom-font-upload-btn"],
                style["cursor-pointer"]
              )}
              onClick={() => selectProps.setShowUploadModal(true)}>
              <Icon icon="ui-upload" />
              <div className={cx(style["custom-tooltip-content"], style["bottom"], style["mt-5"])}>Upload Font</div>
            </div>
          )}
          {(selectProps?.paidCompanyInfo.companyRole !== COMPANY_SUPERADMIN && !hasCustomFoAccess) && (
            <div className={cx(style["custom-tooltip"], style["badge-secondary"], style["custom-font-upload-btn"])}>
              <Icon icon="ui-info"  additionalclass="font-base" />
              <div className={cx(style["custom-tooltip-content"], style["bottom"], style["mt-5"])}>
                Only Admin can upload Font
              </div>
            </div>
          )}
        </div>
      )}
      {selectProps?.fontType !== "Custom" && <Control {...props}>{children}</Control>}
    </>
  );
};

CustomControl.propTypes = {
  children: PropTypes.array.isRequired,
  selectProps: PropTypes.object.isRequired,
  setShowUploadModal: PropTypes.func,
};

const BrandList = props => {
  const { brandInfo } = useContext(AppContext);
  let { metadata } = useContext(EditorContext);

  const { loading } = useSelector(state => state.company);

  const [isBrandkit, setIsBrandkit] = useState();
  const [isFetchDone, setIsFetchDone] = useState(false);
  const [brandFontList, setBrandFontList] = useState();
  const [activeBrandFont, setActiveBrandFont] = useState();

  const upadteActiveTextFont = fontFamily => {
    let index = props.options.findIndex(
      font => font.name.toLowerCase() === fontFamily.replace(/["]+/g, "").toLowerCase()
    );

    props?.onSelectChange(props.options[index]);
  };

  useEffect(() => {
    if (brandInfo?.brands.length >= 0) {
      setIsFetchDone(true);
      let brandFontArray = [];

      if (brandInfo?.brandDetails) {
        // set brand fonts array
        brandFontArray.push(brandInfo?.brandDetails?.heading?.font_family || "Open Sans");
        brandFontArray.push(brandInfo?.brandDetails?.subheading?.font_family || "Open Sans");
        brandFontArray.push(brandInfo?.brandDetails?.body_text?.font_family || "Open Sans");

        brandFontArray = [...new Set(brandFontArray)]; // remove duplicate value from array
      }

      setBrandFontList(brandFontArray);
    }
    if (brandInfo?.brands.length > 0) setIsBrandkit(true);
  }, [brandInfo]);

  useEffect(() => {
    setActiveBrandFont(
      document
        .querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner")
        ?.style.fontFamily.replace(/['"]+/g, "")
    );
  }, []);

  return (
    <>
      {!props.isHideBrand && (
        <div className={style["brand-fonts"]}>
          {isFetchDone && !isBrandkit && (
            <BrandSetup
              icon="ui-brand-fonts"
              heading="Use Brand Fonts"
              content="Make your design consistent with your brand fonts."
              buttonLabel="Set up Brand Fonts"
            />
          )}

          {isFetchDone && isBrandkit && (
            <div className={cx(style["px-3"], style["py-2"], style["my-1"])}>
              <BrandCompanyList />

              {loading && !brandInfo.brandDetails && (
                <ul
                  className={cx(
                    style["color-list"],
                    style["row"],
                    style["asset-brand-font-loading"],
                    style["row-cols-1"]
                  )}>
                  <AssetsLoader count={3} />
                </ul>
              )}

              {!loading && brandInfo?.brandDetails && (
                <ul className={cx(style["brand-fontFamily-list"], style["mx-n3"])}>
                  {brandFontList.map((brandFont, index) => (
                    <React.Fragment key={index}>
                      <li
                        className={cx(style["text-capitalize"], {
                          [style["active"]]: activeBrandFont === brandFont,
                        })}
                        style={{ fontFamily: brandFont }}
                        onClick={() => upadteActiveTextFont(brandFont)}>
                        {brandFont}
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
BrandList.propTypes = {
  options: PropTypes.array.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  isHideBrand: PropTypes.bool,
};
