import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { AUDIO, BRAND, STOCK, UPLOAD_VIDEO, VIDEO } from "../../../constants/editor";
import { Icon } from "../../ui/icon";
import Modal from "../../ui/modal";
import TrimModal from "../Modals/TrimModal";
import useVideo from "../../../hooks/useVideo";
import useAudio from "../../../hooks/useAudio";
import global from "../../../scss/dhp.scss";
import { EditorPanelContext } from "../../../containers/editor/EditorPanel";

let style = Object.assign({}, global);

const Trim = props => {
  const config = {
    [VIDEO]: {
      name: "Trim",
      icon: "ui-scissors2",
    },
    [AUDIO]: {
      name: "Adjust",
      icon: "ui-adjust",
    },
  };
  const { widgets, audios, metadata, rightContextMenu, updateRightContextMenu } = useContext(EditorContext);
  const { togglePlayButton, updateTogglePlayButton } = useContext(EditorPanelContext);

  const { fetchTrim: fetchVideoTrim, videoPlayer } = useVideo();
  const { fetchTrim: fetchAudioTrim } = useAudio();

  const [showModal, setShowModal] = useState(false);
  const [isTrimApplied, setIsTrimApplied] = useState();
  const [fileType, setFileType] = useState();
  const [data, setData] = useState();

  const widgetId = metadata.activeWidgetId[0];
  const widgetType = metadata.activeWidgetType[0];
  const widgetObj = widgets?.find(w => w.id === widgetId);

  const audioId = metadata.activeAudioId;
  const audioObj = audios?.find(a => a.id === audioId);

  const toggleModal = () => {
    if (props.rightContext) {
      document.querySelector(".trim-action-toolbar").click();
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
    } else setShowModal(!showModal);
  };

  const pauseActiveVideo = () => {
    if (togglePlayButton) {
      updateTogglePlayButton(false);
      videoPlayer({ event: "PAUSE", widgetId });
    }
  };

  // Load current formatting (get trim data) | reset when drag-and-replace | re-calculate when toggle modal [[FOR VIDEO]]
  useEffect(() => {
    const source = document.getElementById(widgetId)?.dataset?.source;
    if (widgetId && widgetType === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(source)) {
      const trimData = fetchVideoTrim({ widgetId });
      setIsTrimApplied(trimData.isTrimmed);
      setFileType(VIDEO);
      setData({ ...trimData, widgetId });
      pauseActiveVideo();
    }
  }, [widgetId, widgetType, widgetObj?.data]);

  //Load current formatting (get trim data) [[FOR AUDIO]]
  useEffect(() => {
    if (audioId && audioObj) {
      const trimData = fetchAudioTrim({ audioObj });
      setIsTrimApplied(trimData.isTrimmed);
      setFileType(AUDIO);
      setData({ ...trimData, audioId });
    }
  }, [audioId, audioObj?.data]);

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: isTrimApplied,
      })}>
      <div className={style["toolset-item"]}>
        <span
          className={cx(style["toolset-action"], style["custom-tooltip"], {
            ["trim-action-toolbar"]: !props.rightContext,
          })}
          onClick={toggleModal}>
          <Icon icon={config[fileType]?.icon} />
          {props.rightContext && config[fileType]?.name}
          {!props.rightContext && (
            <div className={cx(style["custom-tooltip-content"], style["top"])}>{config[fileType]?.name}</div>
          )}
        </span>
        {showModal && (
          <Modal
            data={data}
            fileType={fileType}
            component={TrimModal}
            showModal={showModal}
            setShowModal={setShowModal}
            modalClassName={`trim-modal ${fileType === AUDIO && "audio"}`}
            className="modal-lg"
            backdrop="static"
          />
        )}
      </div>
    </li>
  );
};

Trim.propTypes = {
  rightContext: PropTypes.bool,
};

export default Trim;
