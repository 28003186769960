import * as actionTypes from "../actions/actionTypes";

const initialState = {
  availableWidgetLists: JSON.parse(localStorage.getItem("widget_list")),
  availableassetLists: [],
};

export const editorReducer = (originalState = initialState, action) => {
  const state = {
    ...originalState,
    type: action.type,
    flashMessage: null,
    status: action?.error?.response?.status ?? null,
  };

  switch (action.type) {
    case actionTypes.WIDGET_FETCH_SUCCESS:
      localStorage.setItem("widget_list", JSON.stringify(action.widgetLists.data));
      return { ...state, availableWidgetLists: action.widgetLists.data };

    case actionTypes.WIDGET_FETCH_FAILED:
      return { ...state, error: { message: action.error } };

    case actionTypes.ASSET_FETCH_START:
      return { ...state, loading: true };

    case actionTypes.ASSET_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        totalAssetCount: action.assetLists.total,
        availableassetLists: action.assetLists.data.value,
        categories: action.assetLists.categories,
        schemeTypes: {
          [action.assetLists.data.key]: action.assetLists.types,
        },
        defaultSchemeType: action.assetLists.data.default_scheme_type,
        dataKey: action.assetLists.data.key,
      };

    case actionTypes.ASSET_FETCH_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case actionTypes.ASSET_FILTER_START:
      return { ...state, loading: true };

    case actionTypes.ASSET_FILTER_SUCCESS: {
      let updatedState = state;

      return {
        ...state,
        loading: false,
        totalAssetCount: action.assetLists.total,
        availableassetLists:
          action.pageNumber > 1
            ? [...updatedState.availableassetLists, ...action.assetLists.data]
            : action.assetLists.data,
      };
    }
    case actionTypes.ASSET_FILTER_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case actionTypes.VIDEO_FETCH_START: 
      return {
        ...state,
        loading: true,
        stockAvailableVideos:
          action.payload.pageNumber === 1 && action.payload.source === "YouTube" ? null : state.stockAvailableVideos,
        youtubeAvailableVideos:
        action.payload.pageNumber === 1 && action.payload.source === "Stock" ? null : state.youtubeAvailableVideos,
      };

    case actionTypes.VIDEO_FETCH_SUCCESS: {
      let updatedState = state;

      return {
        ...state,
        loading: false,
        hasMoreVideos: action.videoLists.data.videos.length > 0 ? true : false,
        pageToken: action.videoLists.data.page_token,
        stockAvailableVideos:
          action.source === "Stock"
            ? action.pageNumber > 1
              ? [...updatedState.stockAvailableVideos, ...action.videoLists.data.videos]
              : action.videoLists.data.videos
            : updatedState.stockAvailableVideos,

        youtubeAvailableVideos:
          action.source === "YouTube"
            ? action.pageNumber > 1
              ? [...updatedState.youtubeAvailableVideos, ...action.videoLists.data.videos]
              : action.videoLists.data.videos
            : updatedState.youtubeAvailableVideos,
      };
    }
    case actionTypes.VIDEO_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        stockAvailableVideos: [],
        youtubeAvailableVideos: [],
      };

    case actionTypes.UPLOAD_ASSET_START:
    case actionTypes.UPLOAD_FACEBOOK_IMAGE_START: {
      let updateState = state;

      return {
        ...state,
        totalUploadedFiles:
          updateState.notUploadedFilesNumber === 0
            ? 1
            : updateState.totalUploadedFiles
            ? updateState.totalUploadedFiles + 1
            : 1,
        notUploadedFilesNumber:
          updateState.notUploadedFilesNumber === 0
            ? 1
            : updateState.totalUploadedFiles
            ? updateState.totalUploadedFiles + 1
            : 1,
        uploadedFilesNumber: 0,
        progressInfo: 0,
        errorCount: 0,
        isUploadDone: false,
        assetId: null,
      };
    }
    case actionTypes.UPLOAD_ASSET_SUCCESS:
    case actionTypes.UPLOAD_FACEBOOK_IMAGE_SUCCESS: {
      let newUploadedList = { ...state };

      newUploadedList.notUploadedFilesNumber = newUploadedList.notUploadedFilesNumber - 1;
      newUploadedList.uploadedFilesNumber = newUploadedList.uploadedFilesNumber + 1;

      let updatedImageList = action.previousList ? [...action.previousList] : [];
      let updatedVideoList = action.previousList ? [...action.previousList] : [];
      let updatedAudioList = [...action.previousList] ?? [];

      // Check the fileType and update the appropriate list
      if (action.fileType === "video") {
        action.previousList.unshift({
          id: action.uploadedAsset.upload.id,
          filename: action.uploadedAsset.upload.filename,
          url: action.uploadedAsset.upload.url,
          thumb: action.uploadedAsset.upload.thumb,
          duration: action.uploadedAsset.upload.duration,
        });
        updatedVideoList.unshift({
          id: action.uploadedAsset.upload.id,
          filename: action.uploadedAsset.upload.filename,
          url: action.uploadedAsset.upload.url,
          thumb: action.uploadedAsset.upload.thumb,
          duration: action.uploadedAsset.upload.duration,
        });
      } else if (action.fileType === "audio") {
        action.previousList.unshift({
          id: action.uploadedAsset.upload.id,
          filename: action.uploadedAsset.upload.filename,
          url: action.uploadedAsset.upload.url,
          duration: action.uploadedAsset.upload.duration,
          audio_filename: action.uploadedAsset.upload.audio_filename,
        });
        updatedAudioList.unshift({
          id: action.uploadedAsset.upload.id,
          filename: action.uploadedAsset.upload.filename,
          url: action.uploadedAsset.upload.url,
          duration: action.uploadedAsset.upload.duration,
          audio_filename: action.uploadedAsset.upload.audio_filename,
        });
      } else {
        action.previousList.unshift({
          id: action.uploadedAsset.upload.id,
          filename: action.uploadedAsset.upload.filename,
          url: action.uploadedAsset.upload.url,
          duration: action.uploadedAsset.upload.duration,
        });
        updatedImageList.unshift({
          id: action.uploadedAsset.upload.id,
          filename: action.uploadedAsset.upload.filename,
          url: action.uploadedAsset.upload.url,
          duration: action.uploadedAsset.upload.duration,
        });
      }

      return {
        ...state,
        assetId: action.uploadedAsset.upload.id,
        isUploadDone: newUploadedList.notUploadedFilesNumber === 0,
        totalUploadedFiles: newUploadedList.totalUploadedFiles,
        notUploadedFilesNumber: newUploadedList.notUploadedFilesNumber,
        uploadedFilesNumber: newUploadedList.uploadedFilesNumber,
        progressInfo: (newUploadedList.uploadedFilesNumber / newUploadedList.totalUploadedFiles) * 100,
        uploadLocation: action.location,
        ...(action.fileType === "video"
          ? { availableUploadVideos: updatedVideoList }
          : action.fileType === "audio"
          ? { availableUploadAudios: updatedAudioList }
          : { availableUploadImages: updatedImageList }),
      };
    }
    case actionTypes.UPLOAD_ASSET_FAILED:
    case actionTypes.UPLOAD_FACEBOOK_IMAGE_FAILED: {
      let currentState = { ...state };

      return {
        ...state,
        errorCount: currentState.errorCount + 1,
        // notUploadedFilesNumber: currentState.notUploadedFilesNumber - 1,
        error: { message: action.error },
      };
    }

    case actionTypes.UPLOAD_ASSET_FETCH_START:
      return { ...state, loading: true, preLoader: true };

    case actionTypes.UPLOAD_ASSET_FETCH_SUCCESS: {
      let updatedState = state;
      const isVideo = action.fileType === "video";
      const isAudio = action.fileType === "audio";
      return {
        ...state,
        loading: false,
        preLoader: false,
        totalUploadImageCount:
          isVideo || isAudio ? state.totalUploadImageCount || 0 : action.uploadAssetLists.data.count,
        totalUploadVideoCount: isVideo ? action.uploadAssetLists.data.count : state.totalUploadVideoCount || 0,
        totalUploadAudioCount: isAudio ? action.uploadAssetLists.data.count : state.totalUploadAudioCount || 0,

        availableUploadImages:
          isVideo || isAudio
            ? state.availableUploadImages || [] // Empty array if undefined
            : action.pageNumber > 1
            ? [...updatedState.availableUploadImages, ...action.uploadAssetLists.data.assets]
            : action.uploadAssetLists.data.assets,

        availableUploadVideos: isVideo
          ? action.pageNumber > 1
            ? [...state.availableUploadVideos, ...action.uploadAssetLists.data.assets]
            : action.uploadAssetLists.data.assets
          : state.availableUploadVideos || [], // Empty array if undefined

        availableUploadAudios: isAudio
          ? action.pageNumber > 1
            ? [...state.availableUploadAudios, ...action.uploadAssetLists.data.assets]
            : action.uploadAssetLists.data.assets
          : state.availableUploadAudios || [], // Empty array if undefined
      };
    }
    case actionTypes.UPLOAD_ASSET_FETCH_FAILED:
      return { ...state, loading: false, preLoader: false, error: { message: action.error } };

    case actionTypes.UPLOAD_ASSET_DELETE_START:
      return { ...state, loading: true, deleteImage: false };

    case actionTypes.UPLOAD_ASSET_DELETE_SUCCESS: {
      let updatedState = state;

      if (action.fileType === "video") {
        updatedState?.availableUploadVideos?.splice(action.deletedRecordIndex, 1);
      } else if (action.fileType === "audio") {
        updatedState.availableUploadAudios.splice(action.deletedRecordIndex, 1);
      } else {
        updatedState?.availableUploadImages?.splice(action.deletedRecordIndex, 1);
      }

      return {
        ...state,
        loading: false,
        deleteImage: true,
        ...(action.fileType === "video"
          ? { availableUploadVideos: updatedState.availableUploadVideos }
          : action.fileType === "audio"
          ? { availableUploadAudios: updatedState.availableUploadAudios }
          : { availableUploadImages: updatedState.availableUploadImages }),
      };
    }
    case actionTypes.UPLOAD_ASSET_DELETE_FAILED:
      return { ...state, loading: false, deleteImage: false, error: { message: action.error } };

    case actionTypes.STOCK_PHOTO_FETCH_START:
      return { ...state, loading: true };

    case actionTypes.STOCK_PHOTO_FETCH_SUCCESS: {
      let updatedState = { ...state };
      let newPhotos = action.result?.data?.photos ?? [];
      let total = action.result?.data?.summary?.total ?? 0;
      let lastPage = Math.floor(total % action.limit === 0 ? total / action.limit : total / action.limit + 1);

      return {
        ...state,
        loading: false,
        hasMorePhotos: action.page < lastPage,
        availablePhotos: action.page > 1 ? [...updatedState.availablePhotos, ...newPhotos] : newPhotos,
      };
    }

    case actionTypes.STOCK_PHOTO_FETCH_FAILED:
      return { ...state, loading: false, error: { message: action.error }, availablePhotos: [] };

    case actionTypes.REMOVE_BACKGROUND_SUCCESS:
      return { ...state, bgRemovedData: action.data };

    case actionTypes.REMOVE_BACKGROUND_FAILED:
      return { ...state, bgRemovedData: action.e };

    case actionTypes.REGION_FETCH_START:
      return { ...state, regionLoading: true };

    case actionTypes.REGION_FETCH_SUCCESS:
      return {
        ...state,
        regionLoading: false,
        availableRegionLists: action.regions,
      };

    case actionTypes.REGION_FETCH_FAILED:
      return { ...state, regionLoading: false, error: { message: action.error } };

    case actionTypes.COUNTRY_FETCH_START:
      return { ...state, countryLoading: true };

    case actionTypes.COUNTRY_FETCH_SUCCESS:
      return {
        ...state,
        countryLoading: false,
        availableCountryLists: action.countries,
      };

    case actionTypes.COUNTRY_FETCH_FAILED:
      return { ...state, countryLoading: false, error: { message: action.error } };

    case actionTypes.UPDATE_EDITOR_REDUCER:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
