import React, { useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";

let style = Object.assign({}, global);

const Qr = props => {
  // handle click on qr code from toolbar
  const handleQrIconClick = () => {
    if (document.getElementById("editorBody").classList.contains("hide-secondAside")) props.toggle();
  };

  return (
    <li className={cx(style["toolset-group"])}>
      <div className={cx(style["toolset-item"], style["qr-wrap"])}>
        <span
          className={cx(style["toolset-action"], style["custom-tooltip"])}
          id="qr-toolbar"
          onClick={handleQrIconClick}>
          <Icon icon="ui-qr-code" />

          <div className={cx(style["custom-tooltip-content"], style["top"])}>Update QR Code</div>
        </span>
      </div>
    </li>
  );
};
Qr.propTypes = {
  toggle: PropTypes.func,
};

export default Qr;
