import cx from "classnames";
import React, { useContext, useEffect } from "react";
import { DropdownMenu, DropdownToggle, Input as RInput, UncontrolledDropdown } from "reactstrap";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { Input } from "../../ui/input";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { preventFormSubmitOnEnter } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const PageGrid = () => {
  return (
    <li className={cx(style["custom-tooltip"], style["dropdown"])}>
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className={cx(style["p-0"], style["show-grid"])} id="gridAction-Dropdown">
          <Icon icon="ui-grid" />
        </DropdownToggle>

        <DropdownMenu
          className={cx(
            style["border-0"],
            style["shadow"],
            style["mt-2"],
            style["rounded"],
            style["gridAction-DropdownMenu"]
          )}>
          <PageGridForm />
        </DropdownMenu>
      </UncontrolledDropdown>

      <div className={cx(style["custom-tooltip-content"], style["right"])}>Grid</div>
    </li>
  );
};

const PageGridForm = () => {
  let { isTimeLineViewOpen, grid, updateGrid } = useContext(EditorContext);

  const gridColors = ["black", "red", "yellow", "green", "blue", "white"];

  const updateGridSize = e => {
    if (e.target?.validity.patternMismatch) return;

    if (e.type === "change")
      updateGrid({
        ...grid,
        size: parseInt(e.target.value) < 1 ? 1 : parseInt(e.target.value) > 128 ? 128 : parseInt(e.target.value),
      });

    if (e.type === "blur")
      updateGrid({
        ...grid,
        size: e.target.value === "" ? 1 : parseInt(e.target.value),
      });
  };

  // When the timeline is open and if I try to enable the Grid line, then a part of the grid modal is going behind the timeline : issue solved
  const calculateGridBoxTop = status => {
    if (status && isTimeLineViewOpen) {
      setTimeout(() => {
        let gridBoxRect = document.querySelector(".gridAction-DropdownMenu").getBoundingClientRect();
        let timelineRect = document.querySelector(".timeline-wraper").getBoundingClientRect();

        if (gridBoxRect.bottom > timelineRect.top) {
          document.querySelector(".gridAction-DropdownMenu").style.top = `-${
            gridBoxRect.bottom - timelineRect.top + 5
          }px`;
        }
      }, 100);
    }
  };

  useEffect(() => {
    // When the timeline is open, then a part of the grid modal is going behind the topbar : issue solved
    if (grid.enabled && isTimeLineViewOpen) {
      setTimeout(() => {
        let gridBoxRectTop = document.querySelector(".gridAction-DropdownMenu").getBoundingClientRect().top;
        let topbarRect = document.querySelector(".editor-topbar").getBoundingClientRect();

        if (gridBoxRectTop < topbarRect.bottom) {
          document.querySelector(".gridAction-DropdownMenu").style.top = `0 px`;
        }
      }, 100);
    }
  }, [grid.enabled]);

  return (
    <form>
      <div className={cx(style["custom-control"], style["custom-switch"], style["mb-3"])}>
        <RInput
          type="checkbox"
          className={style["custom-control-input"]}
          defaultChecked={grid.enabled}
          id="customSwitchForGrid"
          value={grid.enabled}
          onChange={() => {
            calculateGridBoxTop(!grid.enabled);
            updateGrid({ ...grid, enabled: !grid.enabled });
          }}
        />
        <label className={style["custom-control-label"]} htmlFor="customSwitchForGrid">
          Show Grid
        </label>
      </div>

      {grid.enabled && (
        <>
          <div className={cx(style["form-group"], style["clearfix"], style["mb-3"])}>
            <label>Size</label>
            <div className={cx(style["pull-left"], style["grid-size-spinner"])}>
              <Input
                returnType="formGroup"
                id="pageGridSize"
                type="spinner"
                className="custom-width"
                spinnerClass="input-spinner"
                pattern="[0-9]*"
                value={grid.size}
                max="128"
                min="1"
                required={false}
                onChange={updateGridSize}
                onBlur={updateGridSize}
                onKeyDown={e => preventFormSubmitOnEnter(e)}
                onIncrease={() => updateGrid({ ...grid, size: grid.size < 128 ? parseInt(grid.size) + 1 : grid.size })}
                onDecrease={() => updateGrid({ ...grid, size: grid.size > 1 ? parseInt(grid.size) - 1 : grid.size })}
              />
            </div>
          </div>
          <div className={cx(style["form-group"], style["clearfix"], style["mb-0"], style["pt-1"])}>
            <label>Color</label>
            <div className={cx(style["grid-colors"], style["color-radio"], style["pull-left"])}>
              {gridColors.map((gridColor, index) => (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className={cx(
                      style["custom-control"],
                      style["custom-radio"],
                      style["custom-control-inline"],
                      style[`color-${gridColor}`]
                    )}>
                    <RInput
                      type="radio"
                      className={style["custom-control-input"]}
                      name="customRadioInline"
                      checked={gridColor === grid?.color}
                      id={`customGridColor${index}`}
                      value={gridColor}
                      onChange={() => updateGrid({ ...grid, color: gridColor })}
                    />
                    <label className={style["custom-control-label"]} htmlFor={`customGridColor${index}`}></label>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      )}
    </form>
  );
};

export default PageGrid;
