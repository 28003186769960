import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { Icon } from "../../ui/icon";
import Modal from "../../ui/modal";
import RemoveBackgroundModal from "../Modals/RemoveBackgroundModal";
import { EditorContext } from "../../../containers/editor/EditorLayout";

import global from "../../../scss/dhp.scss";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../../constants/company";
import { useContextualUpgrade } from "../../../hooks/useContextualUpgrade";
import { EditorWrapperContext } from "../../../containers/editor/EditorWrapper";

let style = Object.assign({}, global);

const RemoveBackground = props => {
  let { metadata, widgets } = useContext(EditorContext);
  let { changeBySocket } = useContext(EditorWrapperContext);
  const [showModal, setShowModal] = useState(false);
  const [disableAction, setDisableAction] = useState(false);

  let id = metadata.activeWidgetId[0];
  let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
  let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  // useContextualUpgrade Hook to show update modal
  const showUpgrade = useContextualUpgrade();

  // check is this company is paid or not
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);

  const removeBGAction = () => {
    if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
      // Call showUpgrade function to show contexttual upgrade modal based on your condition
      showUpgrade("removeBG");
      return;
    }
    setShowModal(!showModal);
  };

  const fetchRemovedBgStatusFromActiveWidget = () => {
    if (metadata.activeWidgetId) {
      let currentImage = document.getElementById(`${metadata.activeWidgetId[0]}`).getElementsByTagName("img")[0]?.src;
      if (currentImage?.includes("transparent-bg")) setDisableAction(true);
      else setDisableAction(false);
    }
  }

  useEffect(() => {
    fetchRemovedBgStatusFromActiveWidget()
  }, [metadata.activeWidgetId]);

  //check filter is applied or not for collaboration
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]?.innerHTML) fetchRemovedBgStatusFromActiveWidget();
  }, [changeBySocket, widgets[targetWidgetIndex]?.innerHTML])

  return (
    <li className={cx(style["toolset-group"], { [style["disable"]]: disableAction })}>
      <div className={cx(style["toolset-item"], style["remove-background-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={removeBGAction}>
          <Icon icon="ui-remove-bg" />
          {props.rightContext && "Remove Background"}
          {!props.rightContext && (
            <div className={cx(style["custom-tooltip-content"], style["top"])}>Remove Background</div>
          )}
        </span>
        {showModal && (
          <Modal
            component={RemoveBackgroundModal}
            backdrop="static"
            showModal={showModal}
            setShowModal={setShowModal}
            setDisableAction={setDisableAction}
            modalTitle={"Remove Background"}
            modalClassName={"remove-bg-modal"}
            className={"modal-lg"}
          />
        )}
      </div>
    </li>
  );
};

RemoveBackground.propTypes = {
  rightContext: PropTypes.bool,
};

export default RemoveBackground;
