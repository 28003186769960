import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

import { Icon } from "../../components/ui/icon";
import MoreActions from "../../components/Editor/MoreActions";
import { AppContext } from "../../contexts";
import { EditorContext } from "./EditorLayout";
import DocumentName from "../../components/Editor/DocumentName";
import DocumentComments from "../../components/Editor/DocumentComments";
import CollaborativeUsers from "../../components/Editor/CollaborativeUsers";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../../constants/editor";
import { calculateNewZoomValue, convertToSlug, getKeyBySlug, getSecToHMS, getZoomedValue } from "../../_helpers/utils";
import useSetDimension from "../../hooks/useSetDimension";
import useEditorCollaboration from "../../hooks/useEditorCollaboration";
import useWidgetHandler from "../../hooks/useWidgetHandler";
import { updateDocument } from "../../store/actions/documentActions";
import { useLocalStorage } from "../../hooks/useStorage";
import useCheckPlayButtonDisable from "../../hooks/useCheckPlayButtonDisable";
import useGetTotalDocDuration from "../../hooks/useGetTotalDocDuration";
import { formatUrl } from "../../components/Document/_utils";
import { EditorWrapperContext } from "./EditorWrapper";

const style = Object.assign({}, global);

const EditorTitlebar = props => {
  const { isSaveInProgress } = useContext(AppContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    redo,
    undo,
    HistoryList,
    currentPointerPos,
    metadata,
    updateMetadata,
    updateDimension,
    dimension,
    documentType,
    blockNodes,
    pageNodes,
    updateDocumentType,
    audios,
    widgets,
    isTimeLineViewOpen,
  } = useContext(EditorContext);
  const { socketState, showFindAndReplace, setShowFindAndReplace } = useContext(EditorWrapperContext);

  const documentDetails = useSelector(state => state?.document?.documentDetails?.data);
  const currentUserId = useSelector(state => state?.auth?.uid);
  const documentId = useSelector(state => state.document?.documentDetails?.data?.id);
  const userInfo = useSelector(state => state?.auth?.user);

  const [isUndoClicked, setIsUndoClicked] = useState();
  const [isRedoClicked, setIsRedoClicked] = useState();
  const [isShowDocumentPlay, setIsShowDocumentPlay] = useState();
  const [totalDocumentDuration, setTotalDocumentDuration] = useState();

  const { setTotalHeight } = useSetDimension();
  const { hide: hide_WH } = useWidgetHandler();
  const [templateSizes] = useLocalStorage("templateSizes");
  const { checkIsVideoTypePage } = useCheckPlayButtonDisable();
  const { calculatedocDuration } = useGetTotalDocDuration();
  useEditorCollaboration(); // sync editor in collaborative environment

  // update dimension and set fit to screen after undo-redo when page resized
  useEffect(() => {
    if (isUndoClicked || isRedoClicked) {
      let isUpdateDimension;
      let index = HistoryList[currentPointerPos]?.activeBlockIndex
        ? HistoryList[currentPointerPos]?.activeBlockIndex
        : 0;
      const { blockId, blockIdx } = blockNodes[index];
      const { pageId, pageIdx } = [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
        ? pageNodes[0]
        : pageNodes[index];

      if (isUndoClicked) isUpdateDimension = HistoryList[currentPointerPos + 1].resizedStatus;
      if (isRedoClicked) isUpdateDimension = HistoryList[currentPointerPos].resizedStatus;

      setIsUndoClicked(false);
      setIsRedoClicked(false);

      if (
        parseInt(metadata.activeBlockIdx) === parseInt(blockNodes.length) &&
        ![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
      ) {
        updateMetadata({
          ...metadata,
          activeAudioId: false,
          activeWidgetId: false,
          activeWidgetType: false,
          activePageId: pageNodes[parseInt(metadata.activeBlockIdx) - 1].pageId,
          activePageIdx: parseInt(metadata.activeBlockIdx) - 1,
          activeBlockId: blockNodes[parseInt(metadata.activeBlockIdx) - 1].blockId,
          activeBlockIdx: parseInt(metadata.activeBlockIdx) - 1,
        });
      } else if (
        parseInt(metadata.activeBlockIdx) === parseInt(blockNodes.length) &&
        [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
      ) {
        updateMetadata({
          ...metadata,
          activeAudioId: false,
          activeWidgetId: false,
          activeWidgetType: false,
          activeBlockId: blockNodes[parseInt(metadata.activeBlockIdx) - 1].blockId,
          activeBlockIdx: parseInt(metadata.activeBlockIdx) - 1,
        });
      } else {
        updateMetadata({
          ...metadata,
          activeAudioId: false,
          activeWidgetId: false,
          activeWidgetType: false,
        });
      }

      // set total page height for infograpgic
      if ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType))
        setTotalHeight(HistoryList[currentPointerPos].blockNodes);

      if (isUpdateDimension) {
        if (![TYPE_INFOGRAPHIC].includes(documentType)) {
          updateDimension({
            ...dimension,
            ...HistoryList[currentPointerPos].currentDimension,
            zoom: calculateNewZoomValue(
              HistoryList[currentPointerPos].currentDimension.width,
              HistoryList[currentPointerPos].currentDimension.height,
              false,
              userInfo?.video_enabled
            ),
          });
        } else {
          updateDimension({
            ...dimension,
            ...HistoryList[currentPointerPos].currentDimension,
          });
        }

        setTimeout(() => {
          if (![TYPE_INFOGRAPHIC].includes(documentType)) {
            // apply adjustment fot firefox and safari start
            let userAgent = navigator.userAgent;
            let isFirefox = userAgent.match(/firefox|fxios/i);
            let isSafari = !userAgent.match(/chrome/i) && userAgent.match(/safari/i);
            let curEditorWrap = document.getElementById("generic-editor-wrap");
            if (curEditorWrap && (isFirefox || isSafari)) {
              curEditorWrap.style.width = `${
                HistoryList[currentPointerPos].currentDimension.width *
                (HistoryList[currentPointerPos].currentDimension.zoom / 100)
              }px`;
              // curEditorWrap.style.overflow = `hidden`;
            }
            // Update template type in DB
            if (HistoryList[currentPointerPos].currentDocumentType) {
              let templateTypeId = getKeyBySlug(
                templateSizes,
                convertToSlug(HistoryList[currentPointerPos].currentDimension.label)
              );
              if (templateTypeId)
                dispatch(
                  updateDocument(documentId, {
                    template_type: HistoryList[currentPointerPos].currentDimension.label,
                    template_type_id: templateTypeId,
                  })
                ).then(() => {
                  updateDocumentType(HistoryList[currentPointerPos].currentDocumentType);
                });
            }
          } else {
            document.querySelector(".generic-editor-wrap").style.width = `${parseFloat(
              HistoryList[currentPointerPos].currentDimension.width
            )}px`;
            // document.querySelector(".canvas-block").style.width = `${getZoomedValue(
            //   parseFloat(HistoryList[currentPointerPos].currentDimension.width),
            //   HistoryList[currentPointerPos].currentDimension.zoom
            // )}px`;
            let editorouterWrap = document.querySelector(".editor-outer-wrap");
            editorouterWrap.style.width = `${getZoomedValue(
              parseFloat(HistoryList[currentPointerPos].currentDimension.width),
              HistoryList[currentPointerPos].currentDimension.zoom
            )}px`;
            editorouterWrap.style.height = `${
              getZoomedValue(
                parseFloat(HistoryList[currentPointerPos].currentDimension.height),
                HistoryList[currentPointerPos].currentDimension.zoom
              ) + 80
            }px`;
          }
        }, 1);
      }
    }
  }, [currentPointerPos, isUndoClicked, isRedoClicked]);

  // show collaborative users
  useEffect(() => {
    if (socketState?.activeUsers) {
      let activeUserIds = [];
      for (let key in socketState?.activeUsers) {
        if (socketState?.currentUser !== key && socketState?.activeUsers[key]?.userId !== currentUserId)
          activeUserIds.push(socketState?.activeUsers[key]?.userId);
      }
      activeUserIds = [...new Set(activeUserIds)];
    }
  }, [socketState?.activeUsers]);

  // check document play option will display or not
  useEffect(() => {
    setTimeout(() => {
      let allPageVideoPlayCheckList = [];

      pageNodes.forEach(pageNode => {
        // check all conditions for individual page play option keep disable or not
        let isVideoTypePage = checkIsVideoTypePage(pageNode.pageId, pageNode.pageIdx, "document-paly");
        allPageVideoPlayCheckList.push(isVideoTypePage ? "true" : "false");
      });

      if (allPageVideoPlayCheckList.includes("true")) setIsShowDocumentPlay(true);
      else setIsShowDocumentPlay(false);
    }, 300);
  }, [pageNodes, audios, widgets]);

  // set total document duration time
  useEffect(() => {
    let docDuration = calculatedocDuration();
    setTotalDocumentDuration(getSecToHMS({ sec: docDuration }));
  }, [pageNodes]);

  // check document play option will display or not on page load
  useEffect(() => {
    setTimeout(() => {
      let allPageVideoPlayCheckList = [];

      document.querySelectorAll(".page-play-icon").forEach(element => {
        allPageVideoPlayCheckList.push(element.classList.contains("disabled") ? "false" : "true");
      });

      if (allPageVideoPlayCheckList.includes("true")) setIsShowDocumentPlay(true);
      else setIsShowDocumentPlay(false);
    }, 300);
  }, []);

  const redirectToPlay = () => {
    let url = formatUrl(
      {
        icon: "",
        name: "Play",
        path: "/documents/{documentId}/play",
        search: "?ref=editor",
        tooltip: false,
        type: "url",
        value: "play",
      },
      documentDetails
    );

    history.push(url);
  };

  return (
    <div className={cx(style["titlebar"], { [style["video-titlebar"]]: process.env.IS_VIDEO_ENABLED })}>
      <div className={cx(style["d-flex"], style["align-items-center"])}>
        <DocumentName />
      </div>
      <ul className={style["toolbar-pageview"]}>
        <CollaborativeUsers />
        <li className={cx(style["custom-tooltip"], style["d-flex"], style["save"])}>
          <Icon
            icon={isSaveInProgress ? "ui-cloud-loading" : "ui-cloud-done"}
            additionalclass={`font-22 ${!isSaveInProgress && "disabled"}`}
          />
          <div className={cx(style["custom-tooltip-content"], style["bottom"])}>
            {isSaveInProgress ? "Saving" : "Saved"}
          </div>
        </li>

        <li
          className={cx(style["undo"], style["custom-tooltip"], {
            [style["disabled"]]: currentPointerPos === 1,
          })}>
          <span
            onClick={() => (undo(), setIsUndoClicked(true), hide_WH())}
            className={cx(style["cursor-pointer"], style["d-flex"])}>
            <Icon icon="ui-undo" />
          </span>
          <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Undo</div>
        </li>
        <li
          className={cx(style["redo"], style["custom-tooltip"], {
            [style["disabled"]]: HistoryList.length - 1 === currentPointerPos,
          })}>
          <span
            onClick={() => (redo(), setIsRedoClicked(true), hide_WH())}
            className={cx(style["cursor-pointer"], style["d-flex"])}>
            <Icon icon="ui-redo" />
          </span>

          <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Redo</div>
        </li>
        <li className={cx(style["custom-tooltip"], { [style["d-none"]]: isTimeLineViewOpen })}>
          <span
            className={cx(style["cursor-pointer"], style["d-flex"])}
            onClick={() => setShowFindAndReplace({ isActive: !showFindAndReplace.isActive, origin: "titlebar" })}>
            <Icon icon="ui-search" />
          </span>
          <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Find & Replace</div>
        </li>
        <li className={cx(style["dropdown"], style["comment-area"])}>
          <DocumentComments />
        </li>
        {isShowDocumentPlay && ![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && userInfo.video_enabled && (
          <li>
            <span className={cx(style["cursor-pointer"], style["d-flex"], style["align-items-center"])}>
              <span className={style["custom-tooltip"]}>
                <Icon icon="ui-play" additionalclass="mr-1 d-flex" onClick={redirectToPlay} />
                <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Play</div>
              </span>
              <span className={style["custom-tooltip"]}>
                <label className={cx(style["duration"], style["m-0"])}>{totalDocumentDuration}</label>
                <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Document Duration</div>
              </span>
            </span>
          </li>
        )}
        {documentDetails && <MoreActions id={"more-options"} documentdetails={documentDetails} />}
      </ul>
    </div>
  );
};

export default EditorTitlebar;
