import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";

import { Icon } from "./icon";

import global from "../../scss/dhp.scss";
import Modal from "./modal";
import RoleHierarchy from "../Company/RoleProfile/RoleHierarchy";
import { companyRoleData } from "../Company/CompanyConfig";
import CompanyRoleModal from "../Company/Modal/CompanyRoleModal";

let style = Object.assign({}, global);

const LookupField = ({ props, readOnly = true }) => {
  const [showModal, setShowModal] = useState(false);
  const [roleData, setRoleData] = useState();

  const toggle = () => setShowModal(prevState => !prevState);

  const openLookupModule = () => {
    setShowModal(true);
  };

  useEffect(() => {
    setRoleData(companyRoleData);
  }, []);

  return (
    <>
      <InputGroup
        cssModule={style}
        className={cx(style["icon-right"], { [style["disable-field"]]: props?.readOnly })}
        style={{ backgroundColor: "white" }}>
        <Input
          autoComplete="off"
          type={"text"}
          readOnly={readOnly}
          cssModule={style}
          className={"custom-lookup-field"}
          {...props}
          onClick={openLookupModule}
        />
        <InputGroupAddon addonType="append" cssModule={style} className={cx(style["input-group-addon"])}>
          <Icon icon="ui-roles" />
        </InputGroupAddon>
      </InputGroup>
      {showModal && (
        <Modal
          showModal={showModal}
          title="Choose a Role"
          setShowModal={setShowModal}
          component={CompanyRoleModal}
          actionButton={false}
          toggle={toggle}
          role={roleData}
          // successFunc={updateBrandName}
          // isInprogress={isInprogress}
        />
      )}
    </>
  );
};

export default LookupField;
