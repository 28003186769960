import React, { useContext, useEffect, useState } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Nav, NavItem, NavLink, TabContent } from "reactstrap";
import cx from "classnames";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";

import { EditorContext } from "../../../containers/editor/EditorLayout";
import { widgetConfig } from "../editor_config";
import LoadToolbarComponent from "./LoadToolbarComponent";
import {
  ANIMATION,
  GIF,
  PICTURE,
  QR_CODE,
  SHAPE,
  STOCK,
  SVG,
  TEXT,
  TYPE_FORM,
  JOT_FORM,
  UPLOAD,
  VIDEO,
  BRAND,
  UPLOAD_VIDEO,
} from "../../../constants/editor";
import { RGBToHex, isOnlyTextInGroup } from "../../../_helpers/utils";
import { EditorWrapperContext } from "../../../containers/editor/EditorWrapper";

let style = Object.assign({}, global);

const Effect = () => {
  let { metadata, widgets } = useContext(EditorContext);
  let { changeBySocket } = useContext(EditorWrapperContext);

  // let targetWidgetIndex = widgets.findIndex(widget => widget.id === metadata.activeWidgetId[0]);
  let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
  let targetId = isGroupWidget
    ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
    : metadata.activeWidgetId[0];
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

  const [activeTab, setActiveTab] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [effectContextualMenubarList, setEffectContextualMenubarList] = useState(null);
  const [isShadowApplied, setIsShadowApplied] = useState();
  const [isBorderApplied, setIsBorderApplied] = useState();
  const [isOutLineApplied, setIsOutLineApplied] = useState();

  const iconClassName = {
    Shadow: "shadow",
    Border: "border",
    Outline: "text-shadow",
  };

  const toggleDropDown = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggle2 = () => setDropdownOpen(prevState => !prevState);

  const fetchEffectFromActiveWidget = () => {
    let currentElem = document.getElementById(metadata.activeWidgetId[0]);
    let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
    let isOnlyTextInGroupVar = isGroupWidget
      ? isOnlyTextInGroup(metadata.activeWidgetType[0], metadata.activeWidgetId[0])
      : true;

    let flipableAvailable =
      metadata.activeWidgetType[0] === PICTURE ||
      metadata.activeWidgetType[0] === ANIMATION ||
      (metadata.activeWidgetType[0] === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(currentElem.dataset.source)) ||
      (metadata.activeWidgetType[0] === UPLOAD && currentElem.dataset.fileType !== SVG);

    // check shadow is applied or not
    let targetElem1 = flipableAvailable
      ? currentElem.querySelector(".flippable")
      : currentElem.querySelector(".dhp-widget-inner");
    let filterValue = targetElem1.style.filter;
    if (filterValue && filterValue !== "none") setIsShadowApplied(true);
    else setIsShadowApplied(false);

    // check border and text background color is applied or not
    let targetElem2 = flipableAvailable
      ? currentElem.querySelector(".flippable").style
      : [TYPE_FORM, JOT_FORM].includes(metadata.activeWidgetType[0])
      ? currentElem.querySelector(".customapp-frame")?.style
      : currentElem.querySelector(".dhp-widget-inner").style;

    if (
      (targetElem2?.borderStyle && targetElem2.borderStyle !== "none") ||
      (targetElem2?.backgroundColor &&
        metadata.activeWidgetType[0] !== QR_CODE &&
        RGBToHex(targetElem2?.backgroundColor) !== "transparent")
    ) {
      if (metadata.activeWidgetType[0] === TEXT && !isOnlyTextInGroupVar) setIsBorderApplied(false);
      else setIsBorderApplied(true);
    } else if (
      currentElem.getAttribute("data-border-style") &&
      currentElem.getAttribute("data-border-style") !== "none"
    ) {
      setIsBorderApplied(true);
    } else setIsBorderApplied(false);

    // check outline is applied or not
    let strokeValue = currentElem.querySelector(".dhp-widget-inner").style?.webkitTextStroke;
    if (strokeValue) setIsOutLineApplied(true);
    else setIsOutLineApplied(false);
  };

  useEffect(() => {
    if (dropdownOpen) {
      let currentElem = document.getElementById(metadata.activeWidgetId[0]);
      let cropped = currentElem.querySelector(`.dhp-widget-inner`).style.clipPath === "" ? false : true;
      let currentImage = currentElem.getElementsByTagName("img")[0]?.src.includes("transparent-bg");
      let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
      let isOnlyTextInGroupVar = isGroupWidget
        ? isOnlyTextInGroup(metadata.activeWidgetType[0], metadata.activeWidgetId[0])
        : true;

      // Remove Border from effect menu if image or video is cropped or background is removed or some category of shapes
      if (
        cropped ||
        currentImage ||
        (metadata.activeWidgetType[0] === SHAPE &&
          ["Abstract", "Trendy", "Ornamental", "Equations", "Arrows"].includes(
            currentElem.getAttribute("data-category")
          )) ||
        (metadata.activeWidgetType[0] === SHAPE && parseInt(currentElem.getAttribute("data-version")) < 3)
      ) {
        setEffectContextualMenubarList(["Shadow"]);
        setActiveTab(0);
      }
      // set default effect menu from editor config
      else {
        let fileType = currentElem?.dataset?.fileType;

        if (metadata.activeWidgetType[0] === UPLOAD && (fileType === SVG || fileType === GIF)) {
          setEffectContextualMenubarList(widgetConfig[metadata.activeWidgetType[0]][fileType]?.contextualMenu?.effect);
        } else if (metadata.activeWidgetType[0] === VIDEO) {
          setEffectContextualMenubarList(
            widgetConfig[metadata.activeWidgetType[0]][currentElem?.dataset?.source]?.contextualMenu?.effect
          );
        } else if (metadata.activeWidgetType[0] === TEXT && isGroupWidget && !isOnlyTextInGroupVar) {
          setEffectContextualMenubarList(["Shadow", "Outline"]);
        } else {
          setEffectContextualMenubarList(widgetConfig[metadata.activeWidgetType[0]]?.contextualMenu?.effect);
        }
      }
    }
    return () => setEffectContextualMenubarList(null);
  }, [dropdownOpen]);

  // check effect is applied or not on active widget change
  useEffect(() => {
    if (metadata.activeWidgetId[0]) {
      fetchEffectFromActiveWidget();
      setActiveTab(0);
    }
  }, [metadata.activeWidgetId[0], dropdownOpen]);

  // set border status false after image crop or background remove
  useEffect(() => {
    let currentElem = document.getElementById(metadata.activeWidgetId[0]);

    if (
      currentElem &&
      (metadata.activeWidgetType[0] === PICTURE ||
        (metadata.activeWidgetType[0] === VIDEO && [STOCK, BRAND, UPLOAD_VIDEO].includes(currentElem.dataset.source)) ||
        (metadata.activeWidgetType[0] === UPLOAD && currentElem.dataset.fileType !== SVG))
    ) {
      let currentImage = currentElem.getElementsByTagName("img")[0]?.src.includes("transparent-bg"); // check picture background is removed or not

      if (currentElem.getAttribute("data-crop-to-shape") === "true" || currentImage) {
        setIsBorderApplied(false);
      }
    }
  }, [widgets[targetWidgetIndex]?.innerHTML]);

  //check Effect is applied or not for collaboration
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]?.innerHTML) fetchEffectFromActiveWidget();
  }, [changeBySocket, widgets[targetWidgetIndex]?.innerHTML]);

  return (
    <li
      className={cx(style["toolset-group"], {
        [style["active"]]: isShadowApplied || isBorderApplied || isOutLineApplied,
      })}>
      <UncontrolledDropdown
        className={cx(style["toolset-item"], style["effect-wrap"])}
        isOpen={dropdownOpen}
        toggle={toggleDropDown}>
        <DropdownToggle tag="a" className={cx(style["toolset-action"], style["custom-tooltip"])}>
          <Icon icon="ui-effects" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Effects</div>
        </DropdownToggle>
        <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
          <Nav pills>
            {effectContextualMenubarList?.map((contextMenue, idx) => (
              <NavItem key={idx}>
                <NavLink
                  className={cx({ active: activeTab === idx })}
                  onClick={() => {
                    toggle(idx);
                  }}>
                  <Icon icon={`ui-${iconClassName[contextMenue]}`} />
                  {contextMenue}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab} className={style["mt-3"]}>
            {effectContextualMenubarList?.map((contextMenue, idx) => (
              <LoadToolbarComponent
                key={idx}
                component={contextMenue}
                dropdownOpen={dropdownOpen}
                toggle2={toggle2}
                idx={idx}
                setIsShadowApplied={setIsShadowApplied}
                setIsBorderApplied={setIsBorderApplied}
                setIsOutLineApplied={setIsOutLineApplied}
              />
            ))}
          </TabContent>
        </DropdownMenu>
      </UncontrolledDropdown>
    </li>
  );
};

export default Effect;
