// USER
export const USER_STATUS_JOINED = "JOINED";
export const USER_STATUS_DEACTIVATED = "DEACTIVATED";
export const USER_STATUS_INVITED = "INVITED";
export const USER_MAX_LIMIT_REACHED = "User limit reached";
export const USER_EXIST_SIGNUP = "There is an existing account with this email";

// Change email
export const INVALID_CURRENT_PASSWORD = "Invalid current password";
export const USER_EXIST = "User exist with this email";
export const USER_EXIST_MSG_CHANGE_PASSWORD = "The new email is associated with another DocHipo account";
export const VALID_CURRENT_GOOGLE_EMAIL = "You have successfully changed the Email";

export const INVALID_OTP = "Invalid OTP";
export const INVALID_OTP_MSG = "Invalid verification code";

// Tour const
export const TR_ALL_TEMPLATE = "tr_all_template";
export const TR_CREATE_DOCUMENT = "tr_create_document";
export const TR_PREVIEW = "tr_preview";
export const TR_TEMPLATE_TYPE = "tr_template_type";
export const TR_EDITOR_STEP_1 = "tr_editor_step_1";
export const TR_EDITOR_STEP_2 = "tr_editor_step_2";
export const TR_EDITOR_STEP_3 = "tr_editor_step_3";

//SignIn
export const INVALID_SIGNIN = "Invalid signin method";
export const INVALID_SIGNIN_MSG = "The sign in method used is incorrect for this account";

//SignIn Method
export const INVALID_GOOGLE_ACCOUNT_EMAIL = "Invalid email address";
export const INVALID_GOOGLE_ACCOUNT_EMAIL_MSG = "The Google account email does not match with the existing email";
export const VALID_GOOGLE_ACCOUNT_EMAIL = "You have successfully changed the Sign in method";

export const USER_ALREADY_EXISTS = "User exist with this email";
export const USER_ALREADY_EXISTS_MSG = "The new email is associated with another DocHipo account";
export const USER_GOOGLE_EMAIL_MISMATCH = "The Google account email does not match with the existing email";

// Provider Name
export const GOOGLE = "Google";
export const DOCHIPO = "DocHipo";
export const EMAIL = "email";

// Google auth mod
export const SIGNIN = "signin";
export const SIGNUP = "signup";
export const CHANGE_EMAIL = "change-email";
export const JOIN_COMPANY = "join-company";
export const SIGNIN_METHOD = "signin-method";

// Name field validation
export const VALID_NAME_PATTERN = "^[a-zA-Z0-9\u00C0-\u024F\u4E00-\u9FFF\u002C-\u002E ]*$";

// Profile
export const SUPER_ADMIN = "Super Admin";