import { useContext } from "react";
import { widgetConfig as widgetConf } from "../components/Editor/editor_config";
import { RESIZE, N, S, W, E, NW, NE, SW, SE, LINE, TEXT, TEXT_FRAME } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  getCssTransformObj,
  getUnScaledValue,
  getWidgetAndParentsDomReference,
  getZoomedValue,
  widgetQuery,
} from "../_helpers/utils";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import useCheckWidgetPosition from "./useCheckWidgetPosition";
import useDeleteWidget from "./useDeleteWidget";
import useGroupUngroup from "./useGroupUngroup";
import useResizableSideEffects from "./useResizableSideEffects";
import useSnaptoGrid from "./useSnaptoGrid";
import useWidgetHandler from "./useWidgetHandler";
import useCollaborativeSelector from "./useCollaborativeSelector";
import useFindHighlighter from "./useFindHighlighter";
import { EditorPanelContext } from "../containers/editor/EditorPanel";

const useResizable = () => {
  const { dimension, widgets, updateWidgets, metadata } = useContext(EditorContext);
  const { updateTogglePlayButton } = useContext(EditorPanelContext);

  const {
    toggleEventHandlers: toggleWidgetEventHandlers,
    toggleTooltip: toggleWidgetEventTooltip,
    toggleEventCursorToDefault: toggleWidgetEventCursorToDefault,
  } = useWidgetHandler();

  const { start: sideEffectStart, resize: sideEffectResize, stop: sideEffectStop } = useResizableSideEffects();
  const { group: initWidgetGroup, ungroup: initWidgetUngroup } = useGroupUngroup();

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const { showSnappedGridLines, hideGrideLines } = useSnaptoGrid();
  const deleteWidgetIfOutside = useCheckWidgetPosition();
  const setDeleteWidget = useDeleteWidget();
  const updateCollaborativeWidgetSelectionStyle = useCollaborativeSelector();
  const updateFindHighlighter = useFindHighlighter();

  const resizable = {
    meta: false,
    config: {
      resizeQuadrants: {
        Q1: [315, 44],
        Q2: [45, 134],
        Q3: [135, 224],
        Q4: [225, 314],
      },
    },
    domUpdateRestrictedWidgets: [LINE],

    updateReactDom: ({ client }) => {
      const isDletable = deleteWidgetIfOutside(client.id);

      // Check widget position during resize, if it is outside canvas area delete the widget else upadte new position
      if (isDletable) setDeleteWidget(true);
      else {
        const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
          client.current.height,
          client.current.transform,
          client.current.width
        );

        // bugfix ~ In edit mode, after resize STOP, old innerHTML is showing for text & text frame
        const updatedInnerHtml = [TEXT, TEXT_FRAME].includes(client.assetType)
          ? { innerHTML: document.getElementById(client.id).innerHTML }
          : {};

        updateWidgets(
          widgets.map((widget, idx) => {
            if (idx === client.idx) {
              const updatedCss = client.current;

              return {
                ...widget,
                style: {
                  ...widget.style,
                  ...updatedCss,
                },
                data: {
                  ...widget.data,
                  "data-x-allignment": x_al,
                  "data-y-allignment": y_al,
                },
                ...updatedInnerHtml,
              };
            } else {
              return widget;
            }
          })
        );
      }
    },

    getResizeQuadrant: ({ theta, returnStr = "" }) => {
      const resizeQuadrants = resizable.config.resizeQuadrants;
      Object.keys(resizeQuadrants).every(key => {
        let angle = theta;
        let [r1, r2] = resizeQuadrants[key];

        if (r1 > r2) {
          r2 += 360;
          if (angle >= 0 && angle <= 44) angle += 360;
        }

        if (angle >= r1 && angle <= r2) {
          returnStr = key;
          return false;
        }
        return true;
      });
      return returnStr;
    },

    getRelativePosition: (meta, resizeData) => {
      if (meta.client.initial.theta > 0) {
        let pos = {
          left: resizeData.left,
          top: resizeData.top,
        };

        let nw = resizeData.width;
        let nh = resizeData.height;

        let c1, c2, npos;
        let angle = meta.client.initial.angleInRad;
        let sinTheta = Math.sin(angle);
        let cosTheta = Math.cos(angle);
        let { x, y } = meta.client.initial.center;

        if ([NW, SE, N, S, W, E].includes(meta.resizer.type)) {
          c1 = {
            x: getUnScaledValue(-x, dimension.zoom),
            y: getUnScaledValue(y, dimension.zoom),
          };
          c2 = {
            x: -nw / 2,
            y: nh / 2,
          };
        } else if ([NE, SW].includes(meta.resizer.type)) {
          c1 = {
            x: getUnScaledValue(x, dimension.zoom),
            y: getUnScaledValue(y, dimension.zoom),
          };
          c2 = {
            x: nw / 2,
            y: nh / 2,
          };
        }

        // get position after rotation with original size
        let nc1 = {
          x: c1.y * sinTheta + c1.x * cosTheta,
          y: c1.y * cosTheta - c1.x * sinTheta,
        };
        let d1 = {
          left: nc1.x - c1.x,
          top: nc1.y - c1.y,
        };

        // get position after rotation with new size
        let nc2 = {
          x: c2.y * sinTheta + c2.x * cosTheta,
          y: c2.y * cosTheta - c2.x * sinTheta,
        };
        let d2 = {
          left: nc2.x - c2.x,
          top: nc2.y - c2.y,
        };

        // get the difference between the two position
        let offset = {
          left: d2.left - d1.left,
          top: d2.top - d1.top,
        };

        // calculate the relative new position
        if ([NW, NE, N, W].includes(meta.resizer.type)) {
          npos = {
            left: pos.left + offset.left,
            top: pos.top - offset.top,
          };
        } else if ([SW, SE, S, E].includes(meta.resizer.type)) {
          npos = {
            left: pos.left - offset.left,
            top: pos.top + offset.top,
          };
        }
        return npos;
      } else {
        let pos = {
          left: resizeData.left,
          top: resizeData.top,
        };

        return pos;
      }
    },

    start: (data, e) => {
      const { isSingleWidget, isMultiWidget, data: domRef } = widgetQuery(data.widgetId);

      // isSingleSelected => for single and group widgets that are selected single
      // isGroupSelected => for single and group widgets that are selected multiple

      let widgetIdx = false;
      let widgetQueryData = domRef;

      if (isSingleWidget) {
        widgetIdx = widgets.findIndex(w => w.id === widgetQueryData.widget.id);
      }

      if (isMultiWidget) {
        sessionStorage.setItem("retainRotation", true); // to keep handler rotation for shift selectd widgets
        const runtimeGroupId = initWidgetGroup({ isRuntimeOperation: true });
        widgetQueryData = getWidgetAndParentsDomReference(runtimeGroupId);

        // stop video when resize
        if (metadata.activeWidgetType.includes("videos")) {
          updateTogglePlayButton(false);
        }
      }

      const {
        widget: { id: widgetId, node: widgetNode, css: widgetStyle, data: widgetData, isLocked: isWidgetLocked },
      } = widgetQueryData;

      if (isWidgetLocked) return;

      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: widgetStyle.transform,
      });

      const widgetRect = {
        height: getZoomedValue(parseFloat(widgetStyle.height), dimension.zoom),
        width: getZoomedValue(parseFloat(widgetStyle.width), dimension.zoom),
      };

      const widgetConfig = widgetConf[widgetData.assetType];
      const aspectRatio = widgetConfig.aspectRatio ? widgetRect.width / widgetRect.height : false;
      const isSideEffectsEnabled = widgetConfig.eventSideEffects.includes(RESIZE);
      const resizeQuadrant = resizable.getResizeQuadrant({ theta: parseFloat(widgetTheta) });
      const minWidth = getZoomedValue(widgetConfig.minWidth, dimension.zoom);
      const minHeight = getZoomedValue(widgetConfig.minHeight, dimension.zoom);

      let props = {
        isResizing: true,
        isFirstEvent: true,
        isMultiOperation: isMultiWidget,
        resizeQuadrant: resizeQuadrant,
        client: {
          id: widgetId,
          idx: widgetIdx,
          node: widgetNode,
          assetType: widgetData.assetType,
          minWidth: minWidth,
          minHeight: minHeight || minWidth / aspectRatio,
          aspectRatio: aspectRatio,
          isSideEffectsEnabled: isSideEffectsEnabled,
          dataset: widgetData,
          initial: {
            width: widgetRect.width,
            height: widgetRect.height,
            left: getZoomedValue(widgetTransX, dimension.zoom),
            top: getZoomedValue(widgetTransY, dimension.zoom),
            theta: parseFloat(widgetTheta),
            angleInRad: parseFloat(widgetTheta) * (Math.PI / 180),
            center: {
              x: widgetRect.width / 2,
              y: widgetRect.height / 2,
            },
            transform: widgetStyle.transform,
          },
          current: {
            width: false,
            height: false,
            transform: false,
          },
        },
        resizer: {
          node: e.target,
          type: data.resizer,
        },
        mouse: {
          initial: {
            x: e.clientX,
            y: e.clientY,
          },
        },
        handler: document.querySelector("#dhp-widget-handler"),
      };

      resizable.meta = props;
      document.addEventListener("mousemove", resizable.resize);
      document.addEventListener("mouseup", resizable.stop);

      // NOTE:::: resizable sideEffects hook 'START' event
      if (isSideEffectsEnabled) sideEffectStart(props);
    },

    resize: e => {
      let meta = resizable.meta;
      if (meta?.isResizing) {
        let left, top, width, height;
        let resizeData = { ...meta.client.initial };
        const dX = e.clientX - meta.mouse.initial.x;
        const dY = e.clientY - meta.mouse.initial.y;

        // NOTE for meta.resizeQuadrant
        // "Q1" | base value = 0° | range: 315° to 44°
        // "Q2" | base value = 90° | range: 45° to 134°
        // "Q3" | base value = 180° | range: 135° to 224°
        // "Q4" | base value = 270° | range: 225° to 314°

        // North Resizer | Top
        if (meta.resizer.type === N) {
          const config = {
            Q1: {
              top: dY,
              height: -dY,
            },
            Q2: {
              top: -dX,
              height: dX,
            },
            Q3: {
              top: -dY,
              height: dY,
            },
            Q4: {
              top: dX,
              height: -dX,
            },
          };

          const delta = config[meta.resizeQuadrant];
          height = meta.client.initial.height + delta.height;

          if (height >= meta.client.minHeight) {
            top = meta.client.initial.top + delta.top;
            resizeData = { ...resizeData, height: height, top: top };
          } else {
            top = meta.client.initial.top + (meta.client.initial.height - meta.client.minHeight);
            resizeData = { ...resizeData, height: meta.client.minHeight, top: top };
          }
        }

        // South Resizer | Bottom
        else if (meta.resizer.type === S) {
          const config = {
            Q1: {
              height: dY,
            },
            Q2: {
              height: -dX,
            },
            Q3: {
              height: -dY,
            },
            Q4: {
              height: dX,
            },
          };

          const delta = config[meta.resizeQuadrant];
          height = meta.client.initial.height + delta.height;

          if (height >= meta.client.minHeight) {
            resizeData = { ...resizeData, height: height };
          } else {
            resizeData = { ...resizeData, height: meta.client.minHeight };
          }
        }

        // West Resizer | Left
        else if (meta.resizer.type === W) {
          const config = {
            Q1: {
              left: dX,
              width: -dX,
            },
            Q2: {
              left: dY,
              width: -dY,
            },
            Q3: {
              left: -dX,
              width: dX,
            },
            Q4: {
              left: -dY,
              width: dY,
            },
          };

          const delta = config[meta.resizeQuadrant];
          width = meta.client.initial.width + delta.width;

          if (width >= meta.client.minWidth) {
            left = meta.client.initial.left + delta.left;
            resizeData = { ...resizeData, width: width, left: left };
          } else {
            left = meta.client.initial.left + (meta.client.initial.width - meta.client.minWidth);
            resizeData = { ...resizeData, width: meta.client.minWidth, left: left };
          }
        }

        // East Resizer | Right
        else if (meta.resizer.type === E) {
          const config = {
            Q1: {
              width: dX,
            },
            Q2: {
              width: dY,
            },
            Q3: {
              width: -dX,
            },
            Q4: {
              width: -dY,
            },
          };

          const delta = config[meta.resizeQuadrant];
          width = meta.client.initial.width + delta.width;

          if (width >= meta.client.minWidth) {
            resizeData = { ...resizeData, width: width };
          } else {
            resizeData = { ...resizeData, width: meta.client.minWidth };
          }
        }

        // North-West Resizer | Top-Left
        else if (meta.resizer.type === NW) {
          const config = {
            Q1: {
              left: dX,
              top: dY,
              width: -dX,
              height: -dY,
            },
            Q2: {
              left: dY,
              top: -dX,
              width: -dY,
              height: dX,
            },
            Q3: {
              left: -dX,
              top: -dY,
              width: dX,
              height: dY,
            },
            Q4: {
              left: -dY,
              top: dX,
              width: dY,
              height: -dX,
            },
          };

          const delta = config[meta.resizeQuadrant];
          width = meta.client.initial.width + delta.width;
          height = meta.client.aspectRatio
            ? width / meta.client.aspectRatio
            : meta.client.initial.height + delta.height;

          if (width >= meta.client.minWidth) {
            if ((meta.client.aspectRatio && height >= meta.client.minHeight) || !meta.client.aspectRatio) {
              left = meta.client.initial.left + delta.left;
              resizeData = { ...resizeData, width: width, left: left };
            }
          } else {
            left = meta.client.initial.left + (meta.client.initial.width - meta.client.minWidth);
            resizeData = { ...resizeData, width: meta.client.minWidth, left: left };
          }

          if (height >= meta.client.minHeight) {
            top = meta.client.aspectRatio
              ? meta.client.initial.top - (height - meta.client.initial.height)
              : meta.client.initial.top + delta.top;

            resizeData = { ...resizeData, height: height, top: top };
          } else {
            top = meta.client.initial.top + (meta.client.initial.height - meta.client.minHeight);
            resizeData = { ...resizeData, height: meta.client.minHeight, top: top };
          }
        }

        // North-East Resizer | Top-Right
        else if (meta.resizer.type === NE) {
          const config = {
            Q1: {
              top: dY,
              width: dX,
              height: -dY,
            },
            Q2: {
              top: -dX,
              width: dY,
              height: -dX,
            },
            Q3: {
              top: -dY,
              width: -dX,
              height: dY,
            },
            Q4: {
              top: dX,
              width: -dY,
              height: dX,
            },
          };

          const delta = config[meta.resizeQuadrant];
          width = meta.client.initial.width + delta.width;
          height = meta.client.aspectRatio
            ? width / meta.client.aspectRatio
            : meta.client.initial.height + delta.height;

          if (width >= meta.client.minWidth) {
            if ((meta.client.aspectRatio && height >= meta.client.minHeight) || !meta.client.aspectRatio) {
              resizeData = { ...resizeData, width: width };
            }
          } else {
            resizeData = { ...resizeData, width: meta.client.minWidth };
          }

          if (height >= meta.client.minHeight) {
            top = meta.client.aspectRatio
              ? meta.client.initial.top - (height - meta.client.initial.height)
              : meta.client.initial.top + delta.top;

            resizeData = { ...resizeData, height: height, top: top };
          } else {
            top = meta.client.initial.top + (meta.client.initial.height - meta.client.minHeight);
            resizeData = { ...resizeData, height: meta.client.minHeight, top: top };
          }
        }

        // South-West Resizer | Bottom-Left
        else if (meta.resizer.type === SW) {
          const config = {
            Q1: {
              left: dX,
              width: -dX,
              height: dY,
            },
            Q2: {
              left: dY,
              width: -dY,
              height: dX,
            },
            Q3: {
              left: -dX,
              width: dX,
              height: -dY,
            },
            Q4: {
              left: -dY,
              width: dY,
              height: -dX,
            },
          };

          const delta = config[meta.resizeQuadrant];
          width = meta.client.initial.width + delta.width;
          height = meta.client.aspectRatio
            ? width / meta.client.aspectRatio
            : meta.client.initial.height + delta.height;

          if (width >= meta.client.minWidth) {
            if ((meta.client.aspectRatio && height >= meta.client.minHeight) || !meta.client.aspectRatio) {
              left = meta.client.initial.left + delta.left;
              resizeData = { ...resizeData, width: width, left: left };
            }
          } else {
            left = meta.client.initial.left + (meta.client.initial.width - meta.client.minWidth);
            resizeData = { ...resizeData, width: meta.client.minWidth, left: left };
          }

          if (height >= meta.client.minHeight) {
            resizeData = { ...resizeData, height: height };
          } else {
            resizeData = { ...resizeData, height: meta.client.minHeight };
          }
        }

        // South-East Resizer | Bottom-Right
        else if (meta.resizer.type === SE) {
          const config = {
            Q1: {
              width: dX,
              height: dY,
            },
            Q2: {
              width: -dX,
              height: dY,
            },
            Q3: {
              width: -dX,
              height: -dY,
            },
            Q4: {
              width: dX,
              height: -dY,
            },
          };

          const delta = config[meta.resizeQuadrant];
          width = meta.client.initial.width + delta.width;
          height = meta.client.aspectRatio
            ? width / meta.client.aspectRatio
            : meta.client.initial.height + delta.height;

          if (width >= meta.client.minWidth) {
            if ((meta.client.aspectRatio && height >= meta.client.minHeight) || !meta.client.aspectRatio) {
              resizeData = { ...resizeData, width: width };
            }
          } else {
            resizeData = { ...resizeData, width: meta.client.minWidth };
          }

          if (height >= meta.client.minHeight) {
            resizeData = { ...resizeData, height: height };
          } else {
            resizeData = { ...resizeData, height: meta.client.minHeight };
          }
        }

        // NOTE:::: processing resize data
        if (Object.keys(resizeData).length > 0) {
          const data = {
            widget: { pos: {}, dim: {} },
            handler: { pos: {}, dim: {} },
          };

          resizeData = {
            ...resizeData,
            left: getUnScaledValue(resizeData.left, dimension.zoom),
            top: getUnScaledValue(resizeData.top, dimension.zoom),
            width: getUnScaledValue(resizeData.width, dimension.zoom),
            height: getUnScaledValue(resizeData.height, dimension.zoom),
          };

          // NOTE: relative position is required for transform rotate applied elements (if not applied, displacement issue will occur)
          const relativePos = resizable.getRelativePosition(meta, resizeData);

          if (relativePos.left) {
            data.widget.pos.translateX = relativePos.left + "px";
            data.handler.pos.translateX = getZoomedValue(relativePos.left, dimension.zoom) + "px";
          }
          if (relativePos.top) {
            data.widget.pos.translateY = relativePos.top + "px";
            data.handler.pos.translateY = getZoomedValue(relativePos.top, dimension.zoom) + "px";
          }
          if (resizeData.width) {
            data.widget.dim.width = resizeData.width + "px";
            data.handler.dim.width = getZoomedValue(resizeData.width, dimension.zoom) + "px";
          }
          if (resizeData.height) {
            data.widget.dim.height = resizeData.height + "px";
            data.handler.dim.height = getZoomedValue(resizeData.height, dimension.zoom) + "px";
          }

          if (relativePos.left || relativePos.top) {
            const widgetTransformStr = getCssTransformObj({
              ...data.widget.pos,
              transform: meta.client.initial.transform,
              returnStr: true,
            });

            const handlerTransformStr = getCssTransformObj({
              ...data.handler.pos,
              transform: meta.handler.style.transform,
              returnStr: true,
            });

            if (!resizable.domUpdateRestrictedWidgets.includes(meta.client.assetType)) {
              meta.client.node.style.transform = widgetTransformStr;
              meta.handler.style.transform = handlerTransformStr;
            }
            meta.client.current.transform = widgetTransformStr;
          }

          if (resizeData.width) {
            if (!resizable.domUpdateRestrictedWidgets.includes(meta.client.assetType)) {
              meta.client.node.style.width = data.widget.dim.width;
              meta.handler.style.width = data.handler.dim.width;
            }
            meta.client.current.width = data.widget.dim.width;
          }

          if (resizeData.height) {
            if (!resizable.domUpdateRestrictedWidgets.includes(meta.client.assetType)) {
              meta.client.node.style.height = data.widget.dim.height;
              meta.handler.style.height = data.handler.dim.height;
            }
            meta.client.current.height = data.widget.dim.height;
          }

          if (meta.isFirstEvent) {
            toggleWidgetEventHandlers({ action: "HIDE" });
            meta.isFirstEvent = false;
            localStorage.setItem("widget.event.isActive", "true");
          }

          toggleWidgetEventTooltip({
            action: "SHOW",
            event: RESIZE,
            tooltip: `W: ${Math.floor(parseFloat(meta.client.node.style.width))} H: ${Math.floor(
              parseFloat(meta.client.node.style.height)
            )}`,
            theta: meta.client.initial.theta,
          });

          updateFindHighlighter();
          updateCollaborativeWidgetSelectionStyle({ zoom: dimension.zoom });
          toggleWidgetEventCursorToDefault({ action: "SHOW", node: meta.resizer.node });
          showSnappedGridLines(meta.client.node);

          // NOTE:::: resizable sideEffects hook 'RESIZE' event
          if (meta.client.isSideEffectsEnabled) {
            const parsedData = {
              left: parseFloat(data.widget.pos.translateX),
              top: parseFloat(data.widget.pos.translateY),
              width: parseFloat(data.widget.dim.width),
              height: parseFloat(data.widget.dim.height),
            };
            sideEffectResize(parsedData);
          }
        }
      }
    },

    stop: () => {
      let meta = resizable.meta;
      if (meta?.isResizing) {
        toggleWidgetEventHandlers({ action: "SHOW" });
        toggleWidgetEventTooltip({ action: "HIDE", event: RESIZE });
        toggleWidgetEventCursorToDefault({ action: "HIDE" });

        // bugfix - in-case transform 'false', update from dom
        if (!meta.client.current.transform) {
          meta.client.current.transform = meta.client.node.style.transform;
        }

        if (!meta.isFirstEvent && !meta.isMultiOperation) {
          // NOTE:::: resizable sideEffects hook 'STOP' event
          if (meta.client.isSideEffectsEnabled) sideEffectStop();
          else resizable.updateReactDom({ ...meta });
        }

        if (meta.isMultiOperation) {
          initWidgetUngroup({ isRuntimeOperation: true, groupId: meta.client.id });
        }

        document.removeEventListener("mousemove", resizable.resize);
        document.removeEventListener("mouseup", resizable.stop);
        meta = false;
        hideGrideLines();
        localStorage.setItem("widget.event.isActive", "false");
      }
    },
  };

  return { start: resizable.start };
};

export default useResizable;
