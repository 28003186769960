import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ChromePicker } from "react-color";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink } from "reactstrap";

import global from "../../scss/dhp.scss";

import { Icon } from "./icon";
import { colorHexCodeValidation, hexToRGB } from "../../_helpers/utils";
import { contextualConfig } from "../Editor/editor_config";
import { EditorContext } from "../../containers/editor/EditorLayout";
import { MAP, TEXT } from "../../constants/editor";
import DoubleRangeSlider from "../Editor/DoubleRangeSlider";
import SliderControll from "../Editor/SliderControll";
import { AppContext } from "../../contexts";
import BrandSetup from "../Editor/Widgets/BrandSetup";
import BrandCompanyList from "../Editor/Widgets/BrandCompanyList";
import { useSelector } from "react-redux";
import AssetsLoader from "./loader/assetsLoader";
// import { EditorPanelContext } from "../../containers/editor/EditorPanel";

let style = Object.assign({}, global);

const ColorList = props => {
  return (
    <ul>
      {props.availableColorList.colors.map(color => (
        <li className={color.class} key={color.code}>
          <Link
            to="#"
            style={{ backgroundColor: color.code }}
            onClick={e => {
              e.preventDefault();
              props.changeColorValue(color.code, props.colorClass);
              props.setUpdateContext && props.setUpdateContext(true);
            }}></Link>
        </li>
      ))}
    </ul>
  );
};
//Props type validation
ColorList.propTypes = {
  availableColorList: PropTypes.object.isRequired,
  changeColorValue: PropTypes.func.isRequired,
  color: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  colorClass: PropTypes.string,
  setUpdateContext: PropTypes.func,
};

const BrandColors = props => {
  return (
    <li
      key={props.mkey}
      style={{
        backgroundColor: props.color,
        width: "30px",
        height: "30px",
        borderRadius: "6px",
      }}
      onClick={e => {
        e.preventDefault();
        props.changeColorValue(props.color, props.colorClass);
        props.setUpdateContext && props.setUpdateContext(true);
      }}
    />
  );
};
//Props type validation
BrandColors.propTypes = {
  mkey: PropTypes.number,
  changeColorValue: PropTypes.func.isRequired,
  color: PropTypes.string,
  colorClass: PropTypes.string,
  setUpdateContext: PropTypes.func,
};

const ColorPicker = props => {
  const { metadata } = useContext(EditorContext);
  const { setPasteColorActive, pasteColorActive, setIsEyedropperActive, brandInfo } = useContext(AppContext);

  const availableColorLists = [
    {
      group: 1,
      colors: [
        {
          code: "#ffffff",
          class: "primary-shade",
        },
        {
          code: "#f2f2f2",
          class: "",
        },
        {
          code: "#d8d8d8",
          class: "",
        },
        {
          code: "#bfbfbf",
          class: "",
        },
        {
          code: "#a5a5a5",
          class: "",
        },
        {
          code: "#7f7f7f",
          class: "last",
        },
      ],
    },
    {
      group: 2,
      colors: [
        {
          code: "#000000",
          class: "primary-shade",
        },
        {
          code: "#7f7f7f",
          class: "",
        },
        {
          code: "#595959",
          class: "",
        },
        {
          code: "#3f3f3f",
          class: "",
        },
        {
          code: "#262626",
          class: "",
        },
        {
          code: "#0c0c0c",
          class: "last",
        },
      ],
    },
    {
      group: 3,
      colors: [
        {
          code: "#e7e6e6",
          class: "primary-shade",
        },
        {
          code: "#d0cece",
          class: "",
        },
        {
          code: "#aeabab",
          class: "",
        },
        {
          code: "#757070",
          class: "",
        },
        {
          code: "#3a3838",
          class: "",
        },
        {
          code: "#171616",
          class: "last",
        },
      ],
    },
    {
      group: 4,
      colors: [
        {
          code: "#455469",
          class: "primary-shade",
        },
        {
          code: "#d6dce4",
          class: "",
        },
        {
          code: "#adb9c9",
          class: "",
        },
        {
          code: "#8596af",
          class: "",
        },
        {
          code: "#323f4e",
          class: "",
        },
        {
          code: "#222a35",
          class: "last",
        },
      ],
    },
    {
      group: 5,
      colors: [
        {
          code: "#4674c1",
          class: "primary-shade",
        },
        {
          code: "#d9e2f2",
          class: "",
        },
        {
          code: "#b5c7e6",
          class: "",
        },
        {
          code: "#8596af",
          class: "",
        },
        {
          code: "#323f4e",
          class: "",
        },
        {
          code: "#222a35",
          class: "last",
        },
      ],
    },
    {
      group: 6,
      colors: [
        {
          code: "#eb7d3c",
          class: "primary-shade",
        },
        {
          code: "#fae5d6",
          class: "",
        },
        {
          code: "#f6cbae",
          class: "",
        },
        {
          code: "#f2b187",
          class: "",
        },
        {
          code: "#c35a20",
          class: "",
        },
        {
          code: "#823c14",
          class: "last",
        },
      ],
    },
    {
      group: 7,
      colors: [
        {
          code: "#a5a5a5",
          class: "primary-shade",
        },
        {
          code: "#ededed",
          class: "",
        },
        {
          code: "#dbdbdb",
          class: "",
        },
        {
          code: "#c9c9c9",
          class: "",
        },
        {
          code: "#7b7b7b",
          class: "",
        },
        {
          code: "#525252",
          class: "last",
        },
      ],
    },
    {
      group: 8,
      colors: [
        {
          code: "#fdbf2d",
          class: "primary-shade",
        },
        {
          code: "#fff1ce",
          class: "",
        },
        {
          code: "#fde49d",
          class: "",
        },
        {
          code: "#fed86e",
          class: "",
        },
        {
          code: "#be8f1f",
          class: "",
        },
        {
          code: "#7e5f11",
          class: "last",
        },
      ],
    },
    {
      group: 9,
      colors: [
        {
          code: "#5e9cd3",
          class: "primary-shade",
        },
        {
          code: "#deebf5",
          class: "",
        },
        {
          code: "#bed7ed",
          class: "",
        },
        {
          code: "#9dc3e3",
          class: "",
        },
        {
          code: "#3276b3",
          class: "",
        },
        {
          code: "#214f77",
          class: "last",
        },
      ],
    },
    {
      group: 10,
      colors: [
        {
          code: "#72ac4d",
          class: "primary-shade",
        },
        {
          code: "#e2efda",
          class: "",
        },
        {
          code: "#c6dfb5",
          class: "",
        },
        {
          code: "#9ec884",
          class: "",
        },
        {
          code: "#548039",
          class: "",
        },
        {
          code: "#385526",
          class: "last",
        },
      ],
    },
  ];
  const availableCustomDropdownOptions = ["Solid Color", "Gradient Color"];

  const [colorInputFieldValue, setColorInputFieldValue] = useState(props.color);
  const [activeTab, setActiveTab] = useState(0);
  const [isBrowserAllowedEyedropper, setIsBrowserAllowedEyedropper] = useState();
  const [custopmDropdownValue, setCustopmDropdownValue] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [gradientAngle, setGradientAngle] = useState();
  const [gradientAngleInputValue, setGradientAngleInputValue] = useState();
  const [isBrandkit, setIsBrandkit] = useState();
  const [isFetchDone, setIsFetchDone] = useState(false);
  const [mapcolor, setMapcolor] = useState();

  const { loading } = useSelector(state => state.company);

  const toggle2 = () => setDropdownOpen(prevState => !prevState);

  // set new color value in props on color change
  const changeColorValue = (colorValue, colorClass, setInput) => {
    if (!setInput) setColorInputFieldValue(colorValue);

    if (props.multiColorDatas) {
      props.setPreviousColor(colorClass);
      props.setCurrentColor(colorValue);

      let newArray = Object.assign([...props.multiColorDatas], {
        [props.idx]: { ...props.multiColorDatas[props.idx], color: colorValue },
      });
      let newArray1 = Object.assign([...props.multiColors], {
        [props.idx]: hexToRGB(colorValue),
      });

      props.setColor(newArray);
      props.setMultiColors(newArray1);
    } else {
      setMapcolor(colorValue); // Map table color is not changing for custom color section (****** Hack applied******)
      props.setColor(colorValue);

      // add previous range for safari
      if (metadata?.activeWidgetType[0] === TEXT && document.querySelector(`.dhp-content-editable-true-text`)) {
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let sel = window.getSelection();
        if (sel.rangeCount === 0 && isSafari && !pasteColorActive) {
          const range = document.createRange();
          range.setStart(props.previousSelection.startContainer, props.previousSelection.start);
          range.setEnd(props.previousSelection.endContainer, props.previousSelection.end);
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);
        }
      }
    }
  };

  const handleKeyUP = e => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    const isMacOS = /Macintosh|MacIntel|MacPPC|Mac68K/.test(platform);

    e = e || window.event; // Event object 'ev'
    let key = e.which || e.keyCode; // Detecting keyCode
    let ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false; // Detecting Ctrl
    let command = e.metaKey; // Detecting Command

    if (
      (key === 86 && (ctrl || command)) ||
      (key === 67 && (ctrl || command)) ||
      (key === 65 && (ctrl || command)) ||
      ctrl ||
      command ||
      key === 67 ||
      (isMacOS && [91, 93, 224].includes(key))
    )
      return; // return for paste in input field

    let isHex = colorHexCodeValidation(e.target.value);

    if (isHex) {
      if (props.setUpdateContext) props.setUpdateContext(true);
      changeColorValue(e.target.value, props.colorClass);
    }
  };

  const handlePaste = value => {
    if (value.indexOf("#") === 0) value = value;
    else value = `#${value}`;
    let isHex = colorHexCodeValidation(value);

    if (isHex) {
      if (props.setUpdateContext) props.setUpdateContext(true);
      setPasteColorActive(true);
      changeColorValue(value, props.colorClass, "setInput");

      // add previous range if text is contenteditable after paste color code
      if (document.querySelector(`.dhp-content-editable-true-text`)) {
        // changeColorValue(value, props.colorClass);
        window.getSelection().removeAllRanges();
        document.getElementById("Color-Input").blur();
        setTimeout(() => {
          document.querySelector(".dhp-content-editable-true-text .dhp-widget-inner").focus();
          const range = document.createRange();
          range.setStart(props.previousSelection.startContainer, props.previousSelection.start);
          range.setEnd(props.previousSelection.endContainer, props.previousSelection.end);
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);
          props.setTextSelectedAreaColor(value);
        }, 100);
      }
      // else changeColorValue(value, props.colorClass, "setInput");
    }
  };

  const selectColorByEyeDropper = () => {
    if (!window.EyeDropper) {
      return;
    }

    setIsEyedropperActive(true);
    const eyeDropper = new EyeDropper();

    eyeDropper
      .open()
      .then(result => {
        if (props.multiColorDatas) {
          if (props.setUpdateContext) {
            setTimeout(() => {
              props.setUpdateContext(true);
            }, 10);
          }
          changeColorValue(result.sRGBHex, props.colorClass);
        } else {
          setColorInputFieldValue(result.sRGBHex);
          props.setEyedropperColor(result.sRGBHex);
        }
        setIsEyedropperActive(false);
      })
      .catch(e => {
        console.log(e);
      });
  };

  // Map table color is not changing for custom color section (****** Hack applied******)
  useEffect(() => {
    if (mapcolor) {
      props.setColor(mapcolor);
      props.setUpdateContext && props.setUpdateContext(true);
    }
  }, [mapcolor]);

  useEffect(() => {
    if (brandInfo?.brands.length >= 0) setIsFetchDone(true);
    if (brandInfo?.brands.length > 0) setIsBrandkit(true);
  }, [brandInfo]);

  useEffect(() => {
    if (custopmDropdownValue) {
      if (custopmDropdownValue === "Gradient Color" && !props.isGradientActive) {
        props.setIsGradientActive(true);
        props.setUpdateContext(true);
      } else if (custopmDropdownValue === "Solid Color" && props.isGradientActive) {
        props.setColorPickerGradient(true);
        props.setIsGradientActive(false);
        props.setUpdateContext(true);
        changeColorValue(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-grad-scolor"));
      }
    }
  }, [custopmDropdownValue]);

  useEffect(() => {
    setColorInputFieldValue(props.color);
  }, [props.color]);

  useEffect(() => {
    if (!window.EyeDropper) setIsBrowserAllowedEyedropper(false);
    else setIsBrowserAllowedEyedropper(true);

    // load Current formating for the widget which has gradient color property and gradient is allready applied
    if (props.colorPickerGradient && props.isGradientActive) {
      setActiveTab(1);
      setCustopmDropdownValue("Gradient Color");
      setGradientAngle(parseInt(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-grad-degree")));
    }
    // load Current formating for the widget which has gradient color property but gradient is not applied yet
    else if (props.colorPickerGradient) {
      setActiveTab(0);
      setCustopmDropdownValue("Solid Color");
      setGradientAngle(0);
    }
    // load Current formating for the widget which has no gradient color property
    else setActiveTab(0);
  }, []);

  return (
    <React.Fragment>
      <div className={cx(style["colorpicker"], style["shadow-sm"])}>
        <div className={cx(style["current-color"])}>
          <span
            style={
              props.color === "transparent"
                ? {
                    backgroundImage: `url(${contextualConfig.colorUrl.midium})`,
                    backgroundSize: "cover",
                  }
                : props.color === "Mixed Colors"
                ? {
                    backgroundImage: `url(${contextualConfig.colorUrl.mixedColor})`,
                    backgroundSize: "cover",
                  }
                : { backgroundColor: props.color }
            }></span>
          <Input
            id="Color-Input"
            type="text"
            value={colorInputFieldValue}
            onChange={e => setColorInputFieldValue(e.target.value)}
            onKeyUp={e => handleKeyUP(e)}
            onPaste={e => handlePaste(e.clipboardData.getData("Text"))}
          />
          {!props.isHideTransparentOpt && (
            <div className={cx(style["custom-color"], style["custom-tooltip"])}>
              <div to="#" className={cx(style["transparent-color"], style["normal-remove"])}>
                <Icon
                  icon="ui-no-color"
                  onClick={() => {
                    changeColorValue("transparent", props.colorClass);
                    props.setUpdateContext && props.setUpdateContext(true);
                  }}></Icon>
              </div>
              <div className={cx(style["custom-tooltip-content"], style["bottom"])}>No Color</div>
            </div>
          )}
          <div
            className={cx(style["custom-color"], style["custom-tooltip"], style["pick"], {
              [style["disabled"]]: !isBrowserAllowedEyedropper,
            })}>
            <Link to="#" className={style["open-custom-color"]} onClick={selectColorByEyeDropper}>
              <Icon icon="ui-color-dropper"></Icon>
            </Link>
            <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Pick a Color</div>
          </div>
        </div>

        <Nav tabs className={style["mb-0"]}>
          <NavItem className={cx(style["m-0"], style["p-0"])}>
            <NavLink className={cx({ [style["active"]]: activeTab === 0 })} onClick={() => setActiveTab(0)}>
              Preset
            </NavLink>
          </NavItem>
          <NavItem className={cx(style["m-0"], style["p-0"])}>
            <NavLink className={cx({ [style["active"]]: activeTab === 1 })} onClick={() => setActiveTab(1)}>
              Custom
            </NavLink>
          </NavItem>
          {!props.isHideBrand && (
            <NavItem className={cx(style["m-0"], style["p-0"])}>
              <NavLink className={cx({ [style["active"]]: activeTab === 2 })} onClick={() => setActiveTab(2)}>
                Brand
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <div className={cx(style["theme-color"], style["mt-1"], { [style["active"]]: activeTab === 0 })}>
          <div className={style["palette"]}>
            {availableColorLists.map((availableColorList, idx) => (
              <ColorList
                key={idx}
                availableColorList={availableColorList}
                changeColorValue={changeColorValue}
                color={props.color}
                colorClass={props.colorClass}
                setUpdateContext={props.setUpdateContext}
              />
            ))}
          </div>
        </div>

        <div className={cx(style["picker"], { [style["active"]]: activeTab === 1 })}>
          {props.colorPickerGradient && (
            <div className={cx(style["mx-2"], style["px-1"], style["mt-1"])}>
              <Dropdown className={style["custom_select2"]} isOpen={dropdownOpen} toggle={toggle2}>
                <DropdownToggle className={cx(style["text-capitalize"], style["w-100"])}>
                  {custopmDropdownValue}
                  <Icon icon="ui-arrow-down" />
                </DropdownToggle>
                <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                  {availableCustomDropdownOptions.map(availableCustomDropdownOption => (
                    <React.Fragment key={availableCustomDropdownOption}>
                      <DropdownItem
                        className={cx(style["text-capitalize"], {
                          [style["active"]]: availableCustomDropdownOption === custopmDropdownValue,
                        })}
                        tag="a"
                        onClick={() => {
                          setCustopmDropdownValue(availableCustomDropdownOption);
                        }}>
                        {availableCustomDropdownOption}
                      </DropdownItem>
                    </React.Fragment>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}

          {custopmDropdownValue === "Gradient Color" && (
            <DoubleRangeSlider
              color={props.color}
              setColor={props.setColor}
              degree={gradientAngle}
              setColorPickerGradient={props.setColorPickerGradient}
              setUpdateContext={props.setUpdateContext}
              loadCurrentFormatting={props.loadCurrentFormatting}
              setLoadCurrentFormatting={props.setLoadCurrentFormatting}
            />
          )}

          <ChromePicker
            color={props.color}
            disableAlpha={true}
            onChangeComplete={() => props.setUpdateContext && props.setUpdateContext(true)}
            onChange={e => changeColorValue(e.hex, props.colorClass)}
          />

          {custopmDropdownValue === "Gradient Color" && (
            <div className={cx(style["line-controls-wrap"], style["with-input"], style["mx-2"])}>
              <SliderControll
                Slider={gradientAngle}
                setSlider={setGradientAngle}
                SliderInputValue={gradientAngleInputValue}
                setSliderInputValue={setGradientAngleInputValue}
                sliderMin={0}
                sliderMax={359}
                sliderLabel={"Angle"}
                setUpdateContext={props.setUpdateContext}
                // setLoadCurrentFormating={props.setLoadCurrentFormating}
              />
            </div>
          )}
        </div>

        {!props.isHideBrand && (
          <div
            className={cx(style["brand-color"], style["mt-1"], style["mx-2"], style["px-1"], {
              [style["active"]]: activeTab === 2,
            })}>
            {isFetchDone && !isBrandkit && (
              <BrandSetup
                icon="ui-brand-color"
                heading="Use Brand Colors"
                content="Keep your design consistent with your brand colors."
                buttonLabel="Set up Brand Colors"
              />
            )}

            {isFetchDone && isBrandkit && (
              <>
                <BrandCompanyList />

                {loading && !brandInfo?.brandDetails && (
                  <ul
                    className={cx(
                      style["color-list"],
                      style["row"],
                      style["asset-theme-color-loading"],
                      style["row-cols-1"]
                    )}>
                    <AssetsLoader count={3} />
                  </ul>
                )}

                {!loading && brandInfo.brandDetails && (
                  <>
                    {brandInfo.brandDetails?.primary_color?.length > 0 && (
                      <>
                        <div className={cx(style["color-title"])}>Primary</div>
                        <ul className={cx(style["d-flex"], style["align-items-center"], style["color-list-picker"])}>
                          {brandInfo.brandDetails.primary_color.map((pColor, colorIndex) => (
                            <BrandColors
                              key={colorIndex}
                              mkey={colorIndex}
                              changeColorValue={changeColorValue}
                              color={pColor}
                              colorClass={props.colorClass}
                              setUpdateContext={props.setUpdateContext}
                            />
                          ))}
                        </ul>
                      </>
                    )}
                    {brandInfo.brandDetails?.secondary_color?.length > 0 && (
                      <>
                        <div className={cx(style["color-title"])}>Secondary</div>
                        <ul className={cx(style["d-flex"], style["align-items-center"], style["color-list-picker"])}>
                          {brandInfo.brandDetails.secondary_color.map((sColor, colorIndex) => (
                            <BrandColors
                              key={colorIndex}
                              mkey={colorIndex}
                              changeColorValue={changeColorValue}
                              color={sColor}
                              colorClass={props.colorClass}
                              setUpdateContext={props.setUpdateContext}
                            />
                          ))}
                        </ul>
                      </>
                    )}
                    {brandInfo.brandDetails?.other_color?.length > 0 && (
                      <>
                        <div className={cx(style["color-title"])}>Others</div>
                        <ul className={cx(style["d-flex"], style["align-items-center"], style["color-list-picker"])}>
                          {brandInfo.brandDetails.other_color.map((oColor, colorIndex) => (
                            <BrandColors
                              key={colorIndex}
                              mkey={colorIndex}
                              changeColorValue={changeColorValue}
                              color={oColor}
                              colorClass={props.colorClass}
                              setUpdateContext={props.setUpdateContext}
                            />
                          ))}
                        </ul>
                      </>
                    )}
                    {(!brandInfo.brandDetails?.primary_color || brandInfo.brandDetails?.primary_color.length === 0) &&
                      (!brandInfo.brandDetails?.secondary_color ||
                        brandInfo.brandDetails?.secondary_color.length === 0) &&
                      (!brandInfo.brandDetails?.other_color || brandInfo.brandDetails?.other_color.length === 0) && (
                        <div className={cx(style["text-center"], style["font-14"], style["mt-5"], style["pt-3"])}>
                          No brand colors yet
                        </div>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
//Props type validation
ColorPicker.propTypes = {
  color: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  setColor: PropTypes.func.isRequired,
  multiColorDatas: PropTypes.array,
  multiColors: PropTypes.array,
  setMultiColors: PropTypes.func,
  idx: PropTypes.number,
  setPreviousColor: PropTypes.func,
  setCurrentColor: PropTypes.func,
  colorClass: PropTypes.string,
  isHideTransparentOpt: PropTypes.bool,
  setUpdateContext: PropTypes.func,
  eyedropperColor: PropTypes.string,
  setEyedropperColor: PropTypes.func,
  colorPickerGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setColorPickerGradient: PropTypes.func,
  isGradientActive: PropTypes.bool,
  setIsGradientActive: PropTypes.func,
  setLoadCurrentFormatting: PropTypes.func,
  loadCurrentFormatting: PropTypes.bool,
  isHideBrand: PropTypes.bool,
};
export default ColorPicker;
