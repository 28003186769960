import { useContext } from "react";

import { EditorContext } from "../containers/editor/EditorLayout";
import {
  calculateNewPositionOnRotatedObjectResize,
  getCssTransformObj,
  getUpdatedGroupInnerWidgetPos,
  getZoomedValue,
  isOnlyTextInGroup,
  updateHeightAndTopForInactiveGroupChildren,
} from "../_helpers/utils";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import { AppContext } from "../contexts";
import { EditorPanelContext } from "../containers/editor/EditorPanel";

const useFontFormatting = () => {
  let { metadata, widgets, updateWidgets, dimension } = useContext(EditorContext);
  let { textSelectedAreaColor, setTextSelectedAreaColor } = useContext(EditorPanelContext);
  const { setPasteColorActive } = useContext(AppContext);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();

  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const updateFontWeight = isBold => {
    let newArray;
    let id = metadata.activeWidgetId[0];
    let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
    let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
    let groupWidgetInitHeight = isGroupWidget && parseFloat(isGroupWidget.style.height);
    let activeNode = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner");
    let currentFontFamily = activeNode.style.fontFamily;
    let index = fonts.findIndex(
      font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
    );
    let normalWiight = parseInt(fonts[index].normal_weight);

    document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.fontWeight = isBold
      ? normalWiight
      : 700;

    //If there is any textnode replace that text node with div elem
    activeNode.childNodes.forEach((childNode, idx) => {
      if (childNode.nodeName === "#text") {
        let newElm = document.createElement("div");
        newElm.innerText = childNode.textContent;
        activeNode.replaceChild(newElm, activeNode.childNodes[idx]);
      }
    });

    // remove formatting from all child div and apply main formatting
    activeNode.querySelectorAll("*").forEach(node => {
      node.style.fontWeight = isBold ? normalWiight : 700;
    });

    // if the text is under a group widget
    if (isGroupWidget) {
      // Get group updated height and inner widget updated top and height according group
      let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
        targetId,
        id,
        document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
      );

      // update DOM for active widget and group handler
      document.getElementById(targetId).style.height = `${groupDivHeight}px`;
      document.getElementById(id).style.height = parcentHeight;
      document.getElementById(id).style.top = parcentTop;
      document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
        groupDivHeight,
        dimension.zoom
      )}px`;

      // update height and top for inactive child widgets
      updateHeightAndTopForInactiveGroupChildren({
        group: isGroupWidget,
        groupOldHeight: groupWidgetInitHeight,
        groupNewHeight: groupDivHeight,
        activeChildId: id,
      });

      // create context obj
      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgets[targetWidgetIndex],
          style: {
            ...widgets[targetWidgetIndex].style,
            height: `${groupDivHeight}px`,
          },
          innerHTML: document.getElementById(targetId).innerHTML,
        },
      });
    }
    // if the text is a main widget
    else {
      const newHeight = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight;
      const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(newHeight);
      const activeWidgetDOM = document.getElementById(metadata.activeWidgetId[0]);

      const widgetObject = widgets[targetWidgetIndex];
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: widgetObject.style.transform,
      });
      // calculate new transform
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(widgetObject.style.width),
        parseFloat(newHeight),
        parseFloat(widgetObject.style.width),
        parseFloat(widgetObject.style.height),
        parseFloat(widgetTheta)
      );
      const widgetTransformStr = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      // set handler
      const handler = document.getElementById("dhp-widget-handler");
      const handlerTransformStr = getCssTransformObj({
        translateX: `${getZoomedValue(left, dimension.zoom)}px`,
        translateY: `${getZoomedValue(top, dimension.zoom)}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      // update handler and widget height in dom
      activeWidgetDOM.style.height = `${newHeight}px`;
      activeWidgetDOM.style.transform = widgetTransformStr;
      handler.style.height = `${getZoomedValue(newHeight, dimension.zoom)}px`;
      handler.style.transform = handlerTransformStr;

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgetObject,
          style: {
            ...widgetObject.style,
            height: `${newHeight}px`,
            transform: widgetTransformStr,
          },
          data: {
            ...widgetObject.data,
            "data-x-allignment": x_al,
            "data-y-allignment": y_al,
          },
          innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
        },
      });
    }

    updateWidgets(newArray);
  };

  const updateFontStyle = isItalic => {
    let newArray;
    let id = metadata.activeWidgetId[0];
    let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
    let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
    let groupWidgetInitHeight = isGroupWidget && parseFloat(isGroupWidget.style.height);
    let isOnlyTextInGroupVar = isGroupWidget ? isOnlyTextInGroup(metadata.activeWidgetType[0], id) : true;
    let activeNode = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner");

    // set italic for other widget
    document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.fontStyle = isItalic
      ? "normal"
      : "italic";

    //If there is any textnode replace that text node with div elem
    activeNode.childNodes.forEach((childNode, idx) => {
      if (childNode.nodeName === "#text") {
        let newElm = document.createElement("div");
        newElm.innerText = childNode.textContent;
        activeNode.replaceChild(newElm, activeNode.childNodes[idx]);
      }
    });

    // remove formatting from all child div and apply main formatting
    activeNode.querySelectorAll("*").forEach(node => {
      node.style.fontStyle = isItalic ? "normal" : "italic";
    });

    // if the text is under a group widget
    if (isGroupWidget) {
      if (!isOnlyTextInGroupVar) {
        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      } else {
        // Get group updated height and inner widget updated top and height according group
        let { groupDivHeight, parcentHeight, parcentTop } = getUpdatedGroupInnerWidgetPos(
          targetId,
          id,
          document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight
        );

        // update DOM for active widget and group handler
        document.getElementById(targetId).style.height = `${groupDivHeight}px`;
        document.getElementById(id).style.height = parcentHeight;
        document.getElementById(id).style.top = parcentTop;
        document.getElementById("dhp-widget-handler").style.height = `${getZoomedValue(
          groupDivHeight,
          dimension.zoom
        )}px`;

        // update height and top for inactive child widgets
        updateHeightAndTopForInactiveGroupChildren({
          group: isGroupWidget,
          groupOldHeight: groupWidgetInitHeight,
          groupNewHeight: groupDivHeight,
          activeChildId: id,
        });

        newArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            style: {
              ...widgets[targetWidgetIndex].style,
              height: `${groupDivHeight}px`,
            },
            innerHTML: document.getElementById(targetId).innerHTML,
          },
        });
      }
    }
    // if the text is a main widget
    else {
      const newHeight = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").offsetHeight;

      const widgetObject = widgets[targetWidgetIndex];
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: widgetObject.style.transform,
      });
      // calculate new transform
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(widgetObject.style.width),
        parseFloat(newHeight),
        parseFloat(widgetObject.style.width),
        parseFloat(widgetObject.style.height),
        parseFloat(widgetTheta)
      );
      const widgetTransformStr = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgetObject,
          style: {
            ...widgetObject.style,
            height: `${newHeight}px`,
            transform: widgetTransformStr,
          },
          innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
        },
      });
    }

    updateWidgets(newArray);
  };

  const updateTextDecoration = () => {
    let id = metadata.activeWidgetId[0];
    let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
    let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);
    let textDecorationValue = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style
      .textDecoration;
    let activeNode = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner");
    let gradActive = document.getElementById(id).getAttribute("data-grad-scolor");

    //Support text decoration for gradient text
    if (gradActive) {
      textDecorationValue = textDecorationValue.split(" ")[0];

      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.textDecoration =
        textDecorationValue === "underline" ? "none" : "underline";

      if (textDecorationValue !== "underline")
        document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.textDecorationColor =
          document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.color;
    } else {
      document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.textDecoration =
        textDecorationValue === "underline" ? "none" : "underline";
    }

    // document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.textDecorationColor =
    //   document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style.color;

    //If there is any textnode replace that text node with div elem
    activeNode.childNodes.forEach((childNode, idx) => {
      if (childNode.nodeName === "#text") {
        let newElm = document.createElement("div");
        newElm.innerText = childNode.textContent;
        activeNode.replaceChild(newElm, activeNode.childNodes[idx]);
      }
    });

    // remove formatting from all child div and apply main formatting
    activeNode.querySelectorAll("*").forEach(node => {
      node.style.textDecoration = textDecorationValue === "underline" ? "none" : "underline";
    });

    let newArray = Object.assign([...widgets], {
      [targetWidgetIndex]: {
        ...widgets[targetWidgetIndex],
        innerHTML: document.getElementById(targetId).innerHTML,
      },
    });

    updateWidgets(newArray);
  };

  const selectedPartFontFormatting = fontFormat => {
    let sel, range, node, html, activeHtml, selectedPart, parentEl, nodeColor;
    let { isBoldSelPart, isaItalicSelPart, isUnderLineSelPart } = checkSelectedPartFormat();
    let currentFontFamily = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner").style
      .fontFamily;
    let index = fonts.findIndex(
      font => font.name.toLowerCase() === currentFontFamily.replace(/["]+/g, "").toLowerCase()
    );
    let normalWiight = parseInt(fonts[index].normal_weight);
    let fontWeight = isBoldSelPart ? normalWiight : 700;
    let fontStyle = isaItalicSelPart ? "normal" : "italic";
    let fontDecoration = isUnderLineSelPart ? "none" : "underline";
    let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");

    if (window.getSelection) {
      sel = window.getSelection();

      // get parent elemet of selected part
      if (window.getSelection) {
        if (sel.rangeCount) {
          parentEl = sel.getRangeAt(0).commonAncestorContainer;
          if (parentEl.nodeType != 1) {
            parentEl = parentEl.parentNode;
          }
        }
      }

      // get selected part as a HTML format
      if (sel.rangeCount) {
        let container = document.createElement("div");
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          container.appendChild(sel.getRangeAt(i).cloneContents());
        }
        activeHtml = container.innerHTML;
        selectedPart = document.createElement("span");
        selectedPart.innerHTML = activeHtml;
      }

      if (sel.getRangeAt && sel.rangeCount) {
        range = window.getSelection().getRangeAt(0);

        // remove extra li when selected part formatting apply in a bullet text (remove blank li from selection)
        selectedPart.querySelectorAll("*").forEach(node => {
          if ((node.tagName === "LI" || node.tagName === "OL") && node.innerHTML === "") {
            node.remove();
          }
        });

        // apply bold on selected part
        if (fontFormat === "bold") {
          if (parentEl?.style.color) nodeColor = parentEl.style.color;

          selectedPart.querySelectorAll("*").forEach(node => {
            if (node.style?.fontWeight) {
              node.style.fontWeight = fontWeight;
            }
          });

          if (nodeColor)
            html = `<span style="font-weight:${fontWeight}; color:${nodeColor};">${selectedPart.innerHTML}</span>`;
          else html = `<span style="font-weight:${fontWeight};">${selectedPart.innerHTML}</span>`;
        }
        // apply italic on selected part
        if (fontFormat === "italic") {
          if (parentEl?.style.color) nodeColor = parentEl.style.color;

          selectedPart.querySelectorAll("*").forEach(node => {
            if (node.style?.fontStyle) {
              node.style.fontStyle = fontStyle;
            }
          });

          if (nodeColor)
            html = `<span style="font-style:${fontStyle}; color:${nodeColor};">${selectedPart.innerHTML}</span>`;
          else html = `<span style="font-style:${fontStyle};">${selectedPart.innerHTML}</span>`;
        }
        // apply underline on selected part
        if (fontFormat === "underline") {
          let activeNode = document.querySelector(".dhp-content-editable-true-text .dhp-widget-inner");

          selectedPart.querySelectorAll("*").forEach(node => {
            if (node.style?.textDecoration || node.style?.color) {
              node.style.textDecoration = "none";
              if (node.style?.color && selectedPart.textContent === node.textContent) nodeColor = node.style?.color;
            }
          });

          // if (parentEl.style?.textDecoration && fontDecoration === "none")
          //   parentEl.style.textDecoration = fontDecoration;

          document.querySelectorAll(".dhp-content-editable-true-text span").forEach(element => {
            if (
              element.style?.textDecoration &&
              element.style?.textDecoration === "underline" &&
              fontDecoration === "none"
            ) {
              element.style.textDecoration = fontDecoration;
            }
          });

          if (nodeColor)
            html = `<span style="text-decoration:${fontDecoration}; color:${nodeColor};">${selectedPart.innerHTML}</span>`;
          else html = `<span style="text-decoration:${fontDecoration};">${selectedPart.innerHTML}</span>`;

          activeNode.style.textDecoration = "none";
        }
        // apply color on selected part
        if (fontFormat === "color") {
          if (selectedPart.textContent === "") return; // return if there is no content selected

          selectedPart.querySelectorAll("*").forEach(node => {
            node.style.color = textSelectedAreaColor;
          });

          if (parentEl.style.color && parentEl.style.textDecoration === "underline")
            parentEl.style.color = textSelectedAreaColor;

          html = `<span style="color:${textSelectedAreaColor}; ">${selectedPart.innerHTML}</span>`;

          setTextSelectedAreaColor(false);
          setPasteColorActive(false);
        }

        range.deleteContents();

        let el = document.createElement("div");
        el.innerHTML = html;
        let frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }

        range.insertNode(frag);

        // update handler and widget height in dom
        if (isGroupWidget) {
          let targetDiv = document.querySelector(".dhp-content-editable-true-text .child-selected .dhp-widget-inner");
          let id = metadata.activeWidgetId[0];
          let targetId = document.getElementById(id).closest(".dhp-root-widget").getAttribute("id");
          let { groupDivHeight } = getUpdatedGroupInnerWidgetPos(targetId, id, targetDiv.offsetHeight, dimension.zoom);

          // update DOM and group handler
          document.querySelector(`.dhp-content-editable-true-text .child-selected`).style.outline = "0px solid #00a4ef";
          document.querySelector(`.dhp-content-editable-true-text .child-selected .dhp-widget-inner`).style.outline =
            "2px solid #00a4ef";
          document.getElementById("dhp-widget-handler").style.height = `${groupDivHeight}px`;
        } else {
          let targetDiv = document.querySelector(".dhp-content-editable-true-text .dhp-widget-inner");

          // remove extra li when selected part formatting apply in a bullet text (remove blank li which was replaced)
          targetDiv.querySelectorAll("*").forEach(node => {
            if (
              (node.tagName === "LI" || node.tagName === "OL") &&
              (node.innerText === "" || node.innerText === "\n")
            ) {
              node.remove();
            }
          });

          document.querySelector(".dhp-content-editable-true-text").style.height = `${targetDiv.offsetHeight}px`;
          document.getElementById("dhp-widget-handler").style.height = `${targetDiv.offsetHeight}px`;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      range.collapse(false);
      range.pasteHTML(html);
    }
  };

  const checkSelectedPartFormat = () => {
    let isBoldSelPart, isaItalicSelPart, isUnderLineSelPart;

    if (document.queryCommandState) {
      isBoldSelPart = document.queryCommandState("bold");
      isaItalicSelPart = document.queryCommandState("italic");
      isUnderLineSelPart = document.queryCommandState("underline");
    }

    return { isBoldSelPart, isaItalicSelPart, isUnderLineSelPart };
  };

  return {
    updateFontWeight,
    updateFontStyle,
    updateTextDecoration,
    selectedPartFontFormatting,
    checkSelectedPartFormat,
  };
};

export default useFontFormatting;
