import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

import { EditorContext } from "./EditorLayout";
import { EditorPanelContext } from "./EditorPanel";
import PageSize from "../../components/Editor/Toolbar/PageSize";
import BackgroundColor from "../../components/Editor/ContextualMenu/BackgroundColor";
import ContextualMenubar from "./ContextualMenubar";
import CollageActions from "../../components/Editor/ContextualMenu/CollageActions";
import AudioContextualMenubar from "./AudioContextualMenubar";

let style = Object.assign({}, global);

const EditorToolbar = props => {
  let { metadata } = useContext(EditorContext);
  const { collageData } = useContext(EditorPanelContext);

  // Stop any played video on active widget change or deselect
  useEffect(() => {
    document.querySelectorAll(".dhp-page-widget[data-playing='true']").forEach(widget => {
      if (widget.id !== metadata.activeWidgetId[0] || metadata.activeWidgetId?.length > 1) {
        widget.setAttribute("data-playing", "false");
        widget.querySelector("video").pause();
      }
    });
  }, [metadata.activeWidgetId]);

  return (
    <div className={style["toolbar"]}>
      {!metadata.activeWidgetId && !collageData.isActive && (
        <ul className={style["toolbar-group"]}>
          {metadata.activeAudioId && <AudioContextualMenubar />}

          {!metadata.activeAudioId && metadata.activeOutSideCanvasArea && <PageSize />}

          {!metadata.activeAudioId && !metadata.activeOutSideCanvasArea && <BackgroundColor toggle={props.toggle} />}
        </ul>
      )}

      {!metadata.activeWidgetId && collageData.isActive && <CollageActions />}

      {metadata.activeWidgetId && <ContextualMenubar toggle={props.toggle} />}
    </div>
  );
};

EditorToolbar.propTypes = {
  toggle: PropTypes.func,
};

export default EditorToolbar;
