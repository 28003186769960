import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import Modal from "../../ui/modal";
import CropToShapeModal from "../Modals/CropToShapeModal";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import { EditorWrapperContext } from "../../../containers/editor/EditorWrapper";

let style = Object.assign({}, global);

const Crop = props => {
  let { metadata, widgets } = useContext(EditorContext);
  let { changeBySocket } = useContext(EditorWrapperContext);

  let isGroupWidget = document.getElementById(metadata.activeWidgetId[0])?.closest(".dhp-page-group");
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === metadata.activeWidgetId[0]);

  const [showModal, setShowModal] = useState(false);
  const [isCropApplied, setIsCropApplied] = useState();

  const fetchCropFromActiveWidget = () => {
    if (metadata.activeWidgetId) {
      let cropped = document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-crop-to-shape");
      if (cropped === "true") setIsCropApplied(true);
      else setIsCropApplied(false);
    }
  }

  // check crop is applied or not on active widget change
  useEffect(() => {
    fetchCropFromActiveWidget()
  }, [metadata.activeWidgetId[0], showModal]);

  //Fetch Crop from active widget on collaboration
  useEffect(() => {
    if (changeBySocket && widgets[targetWidgetIndex]?.innerHTML) {
      fetchCropFromActiveWidget();
    }
  }, [changeBySocket, widgets[targetWidgetIndex]?.innerHTML])

  return (
    <li
      className={cx(
        style["toolset-group"],
        {
          [style["disabled"]]: isGroupWidget,
        },
        {
          [style["active"]]: isCropApplied,
        }
      )}>
      <div className={cx(style["toolset-item"], style["crop-wrap"])}>
        <span className={cx(style["toolset-action"], style["custom-tooltip"])} onClick={() => setShowModal(!showModal)}>
          <Icon icon="ui-crop" />
          {props.rightContext && "Crop to Shape"}
          {!props.rightContext && (
            <div className={cx(style["custom-tooltip-content"], style["top"])}>Crop to Shape</div>
          )}
        </span>
        {showModal && (
          <Modal
            component={CropToShapeModal}
            backdrop="static"
            showModal={showModal}
            setShowModal={setShowModal}
            modalClassName={"crop-modal modal-with-leftpane"}
          />
        )}
      </div>
    </li>
  );
};

Crop.propTypes = {
  rightContext: PropTypes.bool,
};

export default Crop;
