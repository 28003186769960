import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import global from "../../../../scss/dhp.scss";

import { Icon } from "../../../ui/icon";
import Crown from "../../../../assets/images/ui-crown.svg";

import { fetchAssetsList, filterAssetsList } from "../../../../store/actions/editorActions";
import AssetsLoader from "../../../ui/loader/assetsLoader";
import useAddWidget from "../../../../hooks/useAddWidget";
import useElementInnerHtml from "../../../../hooks/useElementInnerHtml";
import useAlignment from "../../../../hooks/useAlignment";
import { widgetConfig } from "../../editor_config";
import { EditorContext } from "../../../../containers/editor/EditorLayout";
import * as constant from "../../../../constants/editor";
import { stopAllResourceBuffering, getSvgContentFromUrl, getClassListToString } from "../../../../_helpers/utils";
import useDragDropWidget from "../../../../hooks/useDragDropWidget";
import { useContextualUpgrade } from "../../../../hooks/useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "../../../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../../../constants/company";
import useSetSvgColorOnWidgetInsertion from "../../../../hooks/useSetSvgColorOnWidgetInsertion";
import { DotLoader } from "../../../ui/loader/dotLoader";
import useTextFocusOut from "../../../../hooks/useTextFocusOut";
import useThemeColor from "../../../../hooks/useThemeColor";
import MultiColorList from "../../../ui/MultiColorList";
import { Tooltip } from "reactstrap";
import { EditorSidebarContext } from "../../../../containers/editor/EditorSidebar";

let style = Object.assign({}, global);

//Set 1st li icon and action for Background and picture type widget
const SetIcons = props => {
  let { metadata, backgroundImages, updateBackgroundImages } = useContext(EditorContext);

  const removeBackgroundImage = () => {
    let i = backgroundImages.findIndex(backgroundImage => backgroundImage.blockId === metadata.activeBlockId);
    let updatedBackgroundImageObj = [...backgroundImages];

    updatedBackgroundImageObj.splice(i, 1);
    updateBackgroundImages(updatedBackgroundImageObj);
  };

  return (
    <React.Fragment>
      {props.assetType === constant.BACKGROUND && (
        <div className={cx(style["asset-item"], style["asset-item-icon"])} onClick={() => removeBackgroundImage()}>
          <div className={style["item-holder"]}>
            <Icon icon="ui-no-image" additionalclass="custom-tooltip">
              <div className={style["custom-tooltip-content"]}>Remove Image</div>
            </Icon>
          </div>
        </div>
      )}
      {props.assetType === constant.PICTURE && (
        <div
          className={cx(style["asset-item"], style["asset-item-icon"])}
          onClick={() => props.handleWidgetAction("upload", "Uploads", "uploads", 1)}>
          <div className={style["item-holder"]}>
            <Icon icon="ui-plus" additionalclass="custom-tooltip">
              <div className={style["custom-tooltip-content"]}>Add Your Picture</div>
            </Icon>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
//Props type validation
SetIcons.propTypes = {
  assetType: PropTypes.string.isRequired,
  handleWidgetAction: PropTypes.func,
};

//Fetch assets for selected widget component
const FetchedAssets = ({
  id,
  thumb,
  length,
  lastAssetElement,
  assetType,
  index,
  handleWidgetAction,
  premiumAsset,
  assetCategory,
  assetSchemeType,
  setOpacity,
  setOpacityInputValue,
  premium,
  source,
  duration,
}) => {
  let { metadata, backgroundImages, updateBackgroundImages } = useContext(EditorContext);
  let svgPreviewClassIcluded = ["text_frames", "lines", "shapes", "icons"];
  let className = svgPreviewClassIcluded.includes(assetType)
    ? "svg-preview"
    : assetType === constant.ILLUSTRATION && assetSchemeType === constant.SCENE
    ? "illustration-scene"
    : assetType === "stickers"
    ? "svg-preview-sticker"
    : assetType === "pictures"
    ? "preview-picture"
    : assetType === "backgrounds"
    ? "preview-background"
    : "";

  const [isAssetLoad, setIsAssetLoad] = useState(false);
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [dataparam, setDataParam] = useState();
  const [widgetWidth, setWidgetWidth] = useState(widgetConfig[assetType].width);
  const [widgetHeight, setWidgetHeight] = useState(widgetConfig[assetType].height);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const showUpgrade = useContextualUpgrade(); // useContextualUpgrade Hook to show update modal
  const paidCompanyInfo = useCheckCompanyPlanInfo(true); // check is this company is paid or not
  const { start: initDragDropWidget } = useDragDropWidget();
  const setTextFocusOut = useTextFocusOut();
  const { postion: getPosition } = useAlignment(addWidgetClicked, widgetWidth, widgetHeight, "middle-center");
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    assetType,
    dataparam,
    getPosition,
    widgetWidth,
    widgetHeight
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataparam,
    getNewWidgetObject
  );
  const { setSvgColorOnWidgetInsertion } = useSetSvgColorOnWidgetInsertion();

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const insertElem = async (url, assetCategory, assetSchemeType, duration) => {
    let innerContent,
      paramObj = {};

    //Set dynamic data attributes in inserted widgets
    if ([constant.SHAPE, constant.LINE].includes(assetType)) paramObj["data-category"] = assetCategory;
    if ([constant.SHAPE, constant.ICON].includes(assetType)) paramObj["data-scheme"] = assetSchemeType;
    if ([constant.PICTURE].includes(assetType)) paramObj["data-source"] = source;
    if ([constant.ANIMATION].includes(assetType)) paramObj["data-duration"] = duration;

    //Set inner content for inserted widgets
    if ([constant.PICTURE, constant.ANIMATION].includes(assetType)) innerContent = url;
    else {
      const getTrimmedSVG = ![constant.SHAPE, constant.LINE].includes(assetType); // avoid svg trim for LINES,SHAPES
      innerContent = await getSvgContentFromUrl(url, getTrimmedSVG);
    }

    //Set svg viewbox width height assets
    if (assetType === constant.LINE) {
      setWidgetWidth(innerContent.viewBox.baseVal.width);
      setWidgetHeight(innerContent.viewBox.baseVal.height);
    } else if (assetType === constant.SHAPE) {
      let svgRatio = innerContent.viewBox.baseVal.width / innerContent.viewBox.baseVal.height;
      let svgHeight = widgetWidth / svgRatio;
      setWidgetHeight(svgHeight);
    } else if (assetType !== constant.ANIMATION && assetType !== constant.PICTURE) {
      let svgRatio = innerContent?.viewBox?.baseVal?.height / innerContent?.viewBox?.baseVal?.width;
      let svgWidth = widgetHeight / svgRatio;
      setWidgetWidth(svgWidth);
    }

    innerContent = setSvgColorOnWidgetInsertion(assetType, innerContent); // set color on svg
    setAssetInnerContent(innerContent);
    setDataParam({ ...widgetConfig[assetType].dataAttr, ...paramObj });
    setAddWidgetClicked(true);
  };

  const insertBackground = url => {
    let i = backgroundImages.findIndex(backgroundImage => backgroundImage.blockId === metadata.activeBlockId);

    let updatedBackgroundImageObj = {
      blockId: metadata.activeBlockId,
      pageId: metadata.activePageId,
      style: { ...widgetConfig[assetType].image.style, backgroundImage: `url('${url}')` },
    };

    //for update
    if (i > -1) {
      let newArray = Object.assign([...backgroundImages], {
        [i]: { ...backgroundImages[i], style: { ...backgroundImages[i].style, backgroundImage: `url('${url}')` } },
      });
      updateBackgroundImages(newArray);
    } else {
      updateBackgroundImages([...backgroundImages, updatedBackgroundImageObj]);
      setOpacity(100);
      setOpacityInputValue(100);
    }
  };

  const insert = (url, assetCategory, assetSchemeType, premium, duration) => {
    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);

    if (premium && paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
      // Call showUpgrade function to show contexttual upgrade modal based on your condition
      showUpgrade("premiumAsset", url);
    } else {
      if (constant.BACKGROUND === assetType) insertBackground(url);
      else insertElem(url, assetCategory, assetSchemeType, duration);
    }
  };

  const scrollLeftPane = () => {
    setTooltipOpen(false);
  };

  // Replace .png extension to .gif for Animations only
  if (assetType === constant.ANIMATION) thumb = thumb.replace(".png", ".gif");

  useEffect(() => {
    document.querySelector(".assets-wrapper").addEventListener("scroll", scrollLeftPane);

    return () => {
      document.querySelector(".assets-wrapper")?.removeEventListener("scroll", scrollLeftPane);
    };
  }, []);

  return (
    <React.Fragment>
      {length === index + 1 && (
        <li key={id} ref={lastAssetElement} className={cx(style["col"], style["skeleton-loader-area"])}>
          <div
            id={`asset-image-${index}`}
            className={cx(style["asset-item"])}
            onClick={() => insert(thumb, assetCategory, assetSchemeType, premium, duration)}
            onMouseDown={e =>
              initDragDropWidget(
                {
                  asset: assetType,
                  value: thumb,
                  category: assetCategory,
                  scheme: assetSchemeType,
                  premium: premium,
                  source: source,
                  duration: duration,
                },
                e
              )
            }>
            <div className={style["item-holder"]}>
              {!isAssetLoad && <span className={style["loader-item"]}></span>}

              <img
                src={thumb}
                onLoad={() => setIsAssetLoad(true)}
                className={cx({ [style["d-none"]]: !isAssetLoad }, style["icon"])}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = thumb;
                }}
              />

              {premiumAsset && (
                <span className={style["crown-icon"]}>
                  <img
                    src={Crown}
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = Crown;
                    }}
                    className={style["img-fluid"]}
                    alt="crown"
                  />
                </span>
              )}
            </div>
          </div>

          {isAssetLoad && (
            <Tooltip
              className="asset-hover-tooltip"
              placement="right"
              isOpen={tooltipOpen}
              target={`asset-image-${index}`}
              toggle={toggle}
              boundariesElement={document.getElementById("app")}>
              <div className={getClassListToString(["image-preview", className])}>
                <img src={thumb} alt="" className={style["img-fluid"]} />
              </div>
            </Tooltip>
          )}
        </li>
      )}

      {length !== index + 1 && index === 0 && (assetType === constant.BACKGROUND || assetType === constant.PICTURE) && (
        <li key={id} className={cx(style["col"])}>
          <SetIcons assetType={assetType} handleWidgetAction={handleWidgetAction} />
        </li>
      )}

      {length !== index + 1 &&
        (index !== 0 || (index === 0 && (assetType !== constant.BACKGROUND || assetType !== constant.PICTURE))) && (
          <>
            <li key={id} className={cx(style["col"], style["skeleton-loader-area"])}>
              <div
                id={`asset-image-${index}`}
                className={cx(style["asset-item"])}
                onClick={() => insert(thumb, assetCategory, assetSchemeType, premium, duration)}
                onMouseDown={e =>
                  initDragDropWidget(
                    {
                      asset: assetType,
                      value: thumb,
                      category: assetCategory,
                      scheme: assetSchemeType,
                      premium: premium,
                      source: source,
                      duration: duration,
                    },
                    e
                  )
                }>
                <div className={style["item-holder"]}>
                  {!isAssetLoad && <span className={style["loader-item"]}></span>}
                  <img
                    src={thumb}
                    onLoad={() => setIsAssetLoad(true)}
                    className={cx({ [style["d-none"]]: !isAssetLoad }, style["icon"])}
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = thumb;
                    }}
                  />

                  {premiumAsset && (
                    <span className={style["crown-icon"]}>
                      <img
                        src={Crown}
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = Crown;
                        }}
                        className={style["img-fluid"]}
                        alt="crown"
                      />
                    </span>
                  )}
                </div>
              </div>

              {isAssetLoad && (
                <Tooltip
                  className="asset-hover-tooltip"
                  placement="right"
                  isOpen={tooltipOpen}
                  target={`asset-image-${index}`}
                  toggle={toggle}
                  boundariesElement={document.getElementById("app")}>
                  <div className={getClassListToString(["image-preview", className])}>
                    <img src={thumb} alt="" className={style["img-fluid"]} />
                  </div>
                </Tooltip>
              )}
            </li>
          </>
        )}
    </React.Fragment>
  );
};

//Props type validation
FetchedAssets.propTypes = {
  id: PropTypes.string,
  thumb: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  lastAssetElement: PropTypes.func.isRequired,
  assetType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleWidgetAction: PropTypes.func,
  premiumAsset: PropTypes.bool,
  assetCategory: PropTypes.string,
  assetSchemeType: PropTypes.string,
  setOpacity: PropTypes.func,
  setOpacityInputValue: PropTypes.func,
  premium: PropTypes.bool,
  source: PropTypes.string,
  duration: PropTypes.string,
};

const AssetLists = props => {
  const dispatch = useDispatch();
  const observer = useRef();
  const assetListRef = useRef();
  const assetListPgaeRef = useRef();
  const showUpgrade = useContextualUpgrade(); // useContextualUpgrade Hook to show update modal
  const paidCompanyInfo = useCheckCompanyPlanInfo(true); // check is this company is paid or not
  const { changeColorTheme } = useThemeColor();

  const limit = 50;

  const [filterApplied, setfilterApplied] = useState(false);
  const [availableAssetLists, setAvailableAssetLists] = useState([]);
  const [totalAssetCount, setTotalAssetCount] = useState();

  let hasMoreAsstes;
  let { metadata, pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType } =
    useContext(EditorContext);
  let { setActiveColorThemePageIdx } = useContext(EditorSidebarContext);

  if (totalAssetCount && totalAssetCount > 0) {
    let lastPage = Math.floor(totalAssetCount % limit === 0 ? totalAssetCount / limit : totalAssetCount / limit + 1);
    hasMoreAsstes = props.pageNumber < lastPage;
  }

  //Fetch assets api for the 1st type insert in the widget
  useEffect(() => {
    let payLoad = {
      assetType: props.assetType,
      pageNumber: 1,
      limit: limit,
    };
    props.setAsstesLoading(true);
    dispatch(fetchAssetsList(payLoad)).then(r => {
      setAvailableAssetLists(r.data.data.value);
      props.setAsstesLoading(false);
      setTotalAssetCount(r.data.total);
      if (props.setAvailableAssetCategories) props.setAvailableAssetCategories(r.data.categories);
    });
    setfilterApplied(true);
  }, []);

  useEffect(() => {
    assetListPgaeRef.current = props.pageNumber;
  }, [props.pageNumber]);

  //Observe the last node for pagination and update page number on scroll
  const lastAssetElement = useCallback(
    node => {
      if (props.asstesLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreAsstes) props.setPageNumber(assetListPgaeRef.current + 1);
      });
      if (node) observer.current.observe(node);
    },
    [props.asstesLoading, hasMoreAsstes]
  );

  const applyTheme = availableAssetList => {
    // Call showUpgrade function to show contexttual upgrade modal based on your condition
    if (availableAssetList.premium && paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
      showUpgrade("premiumThemes", {
        docPagesMeta: { pageNodes, blockNodes, backgroundColors, backgroundImages, widgets, dimension, documentType },
        colors: availableAssetList.colors,
      });
    }
    // if theme is not premium then apply the theme
    else {
      props.setActiveThemeIndex(availableAssetList["_id"]);
      setActiveColorThemePageIdx(metadata.activePageIdx);
      changeColorTheme(availableAssetList["_id"], availableAssetList.colors);
    }
  };

  //Fetch assets api for the 2nd time onwards(applied filter, pagination)
  useEffect(() => {
    if (filterApplied) {
      let payLoad = {
        assetType: props.assetType,
        pageNumber: props.pageNumber,
        limit: limit,
      };
      if (props.keyword) payLoad.keyword = props.keyword;
      if (props.assetCategory && !props.keyword) payLoad.assetCategory = props.assetCategory;
      if (props.assetSchemeType && props.assetType !== constant.THEME) payLoad.assetSchemeType = props.assetSchemeType;

      if (props.pageNumber === 1) {
        assetListRef.current.scrollTop = 0;
        stopAllResourceBuffering(assetListRef.current, "img");
      }

      props.setAsstesLoading(true);
      dispatch(filterAssetsList(payLoad)).then(r => {
        setAvailableAssetLists(props.pageNumber > 1 ? [...availableAssetLists, ...r.data.data] : r.data.data);
        props.setAsstesLoading(false);
        setTotalAssetCount(r.data.total);
      });
    }
  }, [props.pageNumber, props.assetCategory, props.keyword, props.assetSchemeType]);

  useEffect(() => {
    if (availableAssetLists) props.setIsAssetFetched(true);
  }, [availableAssetLists]);

  useEffect(() => {
    props.setIsAssetFetched(false);
    return () => {
      stopAllResourceBuffering(assetListRef.current, "img");
    };
  }, []);

  return (
    <div
      className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}
      ref={assetListRef}
      onContextMenu={e => e.preventDefault()}>
      {/* Show Background asset list if "Color" tab is active */}
      {props.assetType === constant.BACKGROUND && props.assetSchemeType === "color" && (
        <ul
          className={cx(
            style["row"],
            {
              [style["row-cols-2"]]: props.asstesLoading,
            },
            { [style["row-cols-4"]]: !props.asstesLoading }
          )}>
          {props.asstesLoading && props.pageNumber === 1 && <AssetsLoader count={40} />}

          {!props.asstesLoading &&
            props.availableColors.map((availableColor, index) => (
              <React.Fragment key={index}>
                <li>
                  <div
                    className={style["rounded"]}
                    style={{ backgroundColor: availableColor }}
                    onMouseDown={() => {
                      props.setColor(availableColor), props.setUpdateContext(true);
                    }}></div>
                </li>
              </React.Fragment>
            ))}
        </ul>
      )}

      {/* Show Theme asset list if "Preset" tab is active */}
      {props.assetType === constant.THEME && props.assetSchemeType === "Preset" && (
        <>
          <ul
            className={cx(
              style["color-list"],
              style["row"],
              style["asset-theme-color-loading"],
              {
                [style["row-cols-1"]]: props.asstesLoading,
              },
              { [style["row-cols-1"]]: !props.asstesLoading }
            )}>
            {props.asstesLoading && props.pageNumber === 1 && <AssetsLoader count={40} />}

            {availableAssetLists?.length > 0 &&
              props.isAssetFetched &&
              availableAssetLists?.map((availableAssetList, index) => (
                <React.Fragment key={index}>
                  <li
                    className={cx(style["color-list-item"], style["custom-tooltip"], {
                      [style["active"]]: props.activeThemeIndex === availableAssetList["_id"],
                    })}
                    ref={lastAssetElement}
                    onClick={() => applyTheme(availableAssetList)}>
                    <MultiColorList colors={availableAssetList.colors} />

                    {availableAssetList.premium && (
                      <span className={style["crown-icon"]}>
                        <img
                          src={Crown}
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = Crown;
                          }}
                          className={style["img-fluid"]}
                          alt="crown"
                        />
                      </span>
                    )}

                    <div className={cx(style["custom-tooltip-content"], style["bottom"], style["mt-1"])}>
                      {availableAssetList["category"]}
                    </div>

                    <div className={style["shuffle"]}>
                      <Icon icon="ui-shuffle" additionalclass="" />
                    </div>
                  </li>
                </React.Fragment>
              ))}
          </ul>
          {props.asstesLoading && props.pageNumber > 1 && <DotLoader />}
        </>
      )}

      {/* Show Rest of asset types list */}
      {(props.assetType !== constant.BACKGROUND ||
        (props.assetType === constant.BACKGROUND && props.assetSchemeType !== "color")) &&
        props.assetType !== constant.THEME && (
          <>
            <ul
              className={cx(
                style["row"],
                (props.assetType === constant.ILLUSTRATION && props.assetSchemeType === constant.SCENE) ||
                  (!props.asstesLoading && availableAssetLists?.length === 0)
                  ? "row-cols-1"
                  : "row-cols-2"
              )}>
              {props.asstesLoading && props.pageNumber === 1 && <AssetsLoader count={20} />}

              {availableAssetLists?.length > 0 &&
                props.isAssetFetched &&
                availableAssetLists.map((availableAssetList, index) => (
                  <FetchedAssets
                    key={index}
                    thumb={availableAssetList.thumb}
                    length={availableAssetLists.length}
                    lastAssetElement={lastAssetElement}
                    assetType={props.assetType}
                    index={index}
                    handleWidgetAction={props.handleWidgetAction}
                    premiumAsset={availableAssetList.premium}
                    assetCategory={availableAssetList.category}
                    assetSchemeType={availableAssetList.scheme_type}
                    setOpacity={props.setOpacity}
                    setOpacityInputValue={props.setOpacityInputValue}
                    premium={availableAssetList.premium}
                    source={props.source}
                    duration={availableAssetList.duration}
                  />
                ))}

              {!props.asstesLoading && props.isAssetFetched && availableAssetLists?.length === 0 && (
                <li className={cx(style["col"])}>
                  <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>
                    No matching {props.assetName.toLowerCase()} found
                  </div>
                </li>
              )}
            </ul>
            {props.asstesLoading && props.pageNumber > 1 && <DotLoader />}
          </>
        )}
    </div>
  );
};

//Props type validation
AssetLists.propTypes = {
  setPageNumber: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  keyword: PropTypes.string,
  assetCategory: PropTypes.string,
  assetSchemeType: PropTypes.string,
  handleWidgetAction: PropTypes.func,
  assetType: PropTypes.string.isRequired,
  assetName: PropTypes.string.isRequired,
  isAssetFetched: PropTypes.bool,
  setIsAssetFetched: PropTypes.func.isRequired,
  setOpacity: PropTypes.func,
  setOpacityInputValue: PropTypes.func,
  source: PropTypes.string,
  setColor: PropTypes.func,
  availableColors: PropTypes.array,
  setUpdateContext: PropTypes.func,
  activeThemeIndex: PropTypes.bool,
  setActiveThemeIndex: PropTypes.func,
  setAvailableAssetCategories: PropTypes.func,
  asstesLoading: PropTypes.bool,
  setAsstesLoading: PropTypes.func,
};

export default AssetLists;
